import styled from "styled-components";
import { ResizableModalWrapper } from "../styles";

export const StyledCreatePersonalBudgeting = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`;
