import styled from "styled-components";
import { ResizableModalWrapper } from "../styles";
import { theme } from "../../../global/theme";
import { mainScrollbar } from "../../../global/theme/reuseable/mixins";

export const CreatePersonalBudgetingModal = styled(ResizableModalWrapper)`
    & h2 {
        text-transform: capitalize;
    }

    & .modal-header__heading {
        justify-content: space-between;
        margin-right: 20px;

        & > button {
            padding-inline: 24px;
        }
    }

    & .create-pb-modal {
        &__form {
            & .input-fields {
                display: grid;
                gap: 8px 16px;
                grid-template-columns: 220px 280px;

                & > * {
                    width: 100%;
                }

                & .inputWrapper {
                    & input {
                        width: 100%;
                    }
                }

                &__name {
                    grid-area: 2/1 / span 1 / span 1;

                    &.full-col {
                        grid-column: 1/-1;
                    }
                }

                &__default-names {
                    grid-area: 2/2 / span 1 / span 1;
                }

                &__often {
                    grid-area: 3/1 / span 1 / span 1;
                }

                &__amount {
                    grid-area: 3/2 / span 1 / span 1;
                }
            }

            & .sub-total {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-block: 16px;
            }
        }
    }

    & .modal-btns {
        & > span {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 12px;
            color: ${theme?.colors.error};
            font-weight: 300;
        }
    }
`;

export const CreateCreditorInfoModal = styled(ResizableModalWrapper)`
    width: calc(100vw - 60px);
    max-width: 460px;

    & .modal-header__heading {
        justify-content: space-between;
        margin-right: 20px;

        & > button {
            padding-inline: 24px;
        }
    }

    & .create-ci-modal {
        &__form {
            & > .input-fields {
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin-bottom: 32px;
            }

            & input {
                width: 100%;
            }
        }
    }
`;

export const AddCardDetailsModal = styled(ResizableModalWrapper)`
    & .add-card-details {
        &__ctn {
            display: flex;
            flex-direction: column;
        }
    }

    & .wpwl-container {
        max-width: 480px;
    }

    & .wpwl-form.wpwl-form-card.wpwl-clearfix {
        margin: 0;
        padding: 0;
        background: transparent;
        box-shadow: none;
        width: inherit;
        margin-top: 12px;
        border: none;
        max-width: none;

        & input {
            &:placeholder {
                font-size: 12px !important;
            }
        }
    }

    & .wpwl-label {
        font-size: 12px;
        text-align: left;
    }
    & .wpwl-button {
        background-color: ${theme.colors.purple};
        border: none;
    }

    & input {
        height: 35px;
        padding-inline: 6px;
        &::placeholder {
            font-size: 16px;
            font-family: "Arial";
        }
    }
`;
