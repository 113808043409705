import { BusinessAccountancyContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";

const AccountsInfo: React.FC<BusinessAccountancyContainerProps> = ({
    formData,
    onInputChange,
}) => {
    return (
        <HeaderContainer title="Accounts Information:" ctnClassName="col-2">
            <Input
                type="date"
                label="Date of Incorporation:"
                value={formData?.date_of_incorporation || ""}
                onChange={onInputChange}
                name="date_of_incorporation"
            />
            <Input
                type="date"
                label="Confirmation Statement Date:"
                value={formData?.confirmation_statment_date || ""}
                onChange={onInputChange}
                name="confirmation_statment_date"
            />
            <Input
                type="text"
                label="Account Reference Number:"
                value={formData?.accounts_ref_number || ""}
                onChange={onInputChange}
                name="accounts_ref_number"
            />
            <Input
                type="date"
                label="Accounts Year End Date:"
                value={formData?.accounts_year_end_date || ""}
                onChange={onInputChange}
                name="accounts_year_end_date"
            />
            <Input
                type="date"
                label="Submission Date:"
                value={formData?.submission_date || ""}
                onChange={onInputChange}
                name="submission_date"
            />
            <Input
                type="text"
                label="PAYE Reference Number:"
                value={formData?.paye_ref_number || ""}
                onChange={onInputChange}
                name="paye_ref_number"
            />
        </HeaderContainer>
    );
};

export default AccountsInfo;
