import React, { useState, SetStateAction } from "react";
import { useParams } from "react-router-dom";

import useFetchData from "../../../../global/hooks/useFetchData";
import ModalHeader from "../../components/ModalHeader";
import StyledEditLiability from "./styles";
import LiabilityMain from "./LiabilityMain";
import DropdownActions from "./DropdownActions";
import ChangeCreditor from "./ChangeCreditor";
import AlternativePayment from "./AlternativePayment";
import { LoadingSpinner } from "../../../Button/styles";
import UploadCa from "./UploadCA";
import SendFullFinal from "./SendFullFinal";
import SendToCCR from "./SwitchToCCR";

interface EditLiabilityProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    onSuccess?: (...args: any[]) => void;
    id: number;
}

export interface EditLiabilityFormProps {
    setCurrentForm: React.Dispatch<SetStateAction<string>>;
    onClose?: (...args: any[]) => void;
    onSuccess?: (...args: any[]) => void;
    data: { [key: string]: any };
    setLiabilityDetails: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
    setIsSuccess: React.Dispatch<SetStateAction<boolean>>;
    id: number;
    liabilityList: Array<{ [key: string]: any }>;
}

const EditLiability: React.FC<EditLiabilityProps> = ({
    isOpen,
    onClose,
    onSuccess = () => {},
    id,
}) => {
    const { clientId } = useParams();
    const [currentForm, setCurrentForm] = useState<string>("");
    const [isSuccess, setIsSuccess] = useState(false);
    const getLiabilityDetails = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/case-debts/${id}`,
        },
        {
            fetchOnLoad: true,
        }
    );
    const getLiabilityList = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/liability-list/${clientId}`,
        },
        {
            fetchOnLoad: true,
        }
    );
    const isLoading =
        getLiabilityDetails.isLoading || getLiabilityList.isLoading;
    const isFetched =
        getLiabilityDetails.isSuccess && getLiabilityList.isSuccess;
    const liabilityList = getLiabilityList?.data?.data || [];
    const formProps: EditLiabilityFormProps = {
        setCurrentForm,
        onClose,
        data: getLiabilityDetails.data?.data,
        setLiabilityDetails: getLiabilityDetails.setData,
        id,
        onSuccess,
        setIsSuccess,
        liabilityList,
    };
    const closeHandler = () => {
        setIsSuccess(false);
        onClose();
    };

    return (
        <StyledEditLiability
            show={isOpen}
            className="edit-liability"
            width="540px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={closeHandler}>
                Edit Liability
                {!isSuccess && <DropdownActions {...formProps} />}
            </ModalHeader>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner size={40} />
                </div>
            )}
            {isFetched && (
                <>
                    {currentForm === "" && <LiabilityMain {...formProps} />}
                    {currentForm === "change-creditor" && (
                        <ChangeCreditor key="current" {...formProps} />
                    )}
                    {currentForm === "change-original-creditor" && (
                        <ChangeCreditor
                            key="original"
                            {...formProps}
                            creditorType="original"
                        />
                    )}
                    {currentForm === "alternative-payment" && (
                        <AlternativePayment {...formProps} />
                    )}
                    {currentForm === "upload-ca" && <UploadCa {...formProps} />}
                    {currentForm === "send-full-final" && (
                        <SendFullFinal {...formProps} />
                    )}
                    {currentForm === "send-to-ccr" && (
                        <SendToCCR {...formProps} />
                    )}
                </>
            )}
        </StyledEditLiability>
    );
};

export default EditLiability;
