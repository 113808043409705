import React, { useState, useEffect } from "react";
import { Button, ModalContainer } from "../../../components";
import ChangePasswordModal from "../../../components/Modals/ChangePassword";
import { ProfileMenuWrapper } from "../../../pages/UserPages/Settings/styles";
import useReduxUser from "../../../store/hooks/useReduxUser";
import {
    getOperatingSystem,
    getPublicIPAddress,
} from "../../../global/utils/helpers";

const UserProfile: React.FC = () => {
    const { logoutUser, user, device, setDeviceByKey } = useReduxUser();
    const [passwordModal, setPasswordModal] = useState(false);

    useEffect(() => {
        getPublicIPAddress().then((ip) => {
            setDeviceByKey("ip", ip ?? "");
        });
        setDeviceByKey("os", getOperatingSystem());
    }, []);
    const handleChangePassword = () => {
        setPasswordModal(true);
    };
    const handleSignOut = () => {
        logoutUser();
    };

    return (
        <>
            <ProfileMenuWrapper>
                <ModalContainer
                    isVisible={passwordModal}
                    children={
                        <ChangePasswordModal
                            setPasswordModal={setPasswordModal}
                            open={passwordModal}
                        />
                    }
                />
                <h4>{user?.name || "N/A"}</h4>
                <div className="details">
                    <span>
                        <label htmlFor="">Email:</label>
                        <p>{user?.email || "N/A"}</p>
                    </span>
                    <span>
                        <label htmlFor="">DDI:</label>
                        <p>{user?.ddi || "N/A"}</p>
                    </span>
                    <span>
                        <label htmlFor="">User Type:</label>
                        <p>{user?.type || "N/A"}</p>
                    </span>
                    <span>
                        <label htmlFor="">Department:</label>
                        <p>{user?.dprtmnt || "N/A"}</p>
                    </span>
                    <span>
                        <label htmlFor="">Started:</label>
                        <p>{user?.when || "N/A"}</p>
                    </span>
                </div>
                <div className="buttons">
                    <Button
                        btnText="Change Password"
                        onClick={handleChangePassword}
                        style="red"
                    />
                    <Button
                        btnText="Sign Out"
                        onClick={handleSignOut}
                        style="red"
                    />
                </div>
                <div className="serverInfo">
                    <h5>Server Stats</h5>
                    <span>
                        <p>YOUR OS:</p>
                        <p>{device?.os || "-"}</p>
                    </span>
                    <span>
                        <p>YOUR IP:</p>
                        <p>{device?.ip || "-"}</p>
                    </span>
                    <span>
                        <p>PHP VERSION:</p>
                        <p>{ user?.php || null}</p>
                    </span>
                    <span>
                        <p>CLIENT MASTER VERSION:</p>
                        <p>{ user?.product_version || null}</p>
                    </span>
                </div>
            </ProfileMenuWrapper>
        </>
    );
};

export default UserProfile;
