import { useState } from "react";
import { Link } from "react-router-dom";
import { icons } from "../../assets/icons";
import { ReportHeaderWrapper } from "./styles";
import { iReportHeaderNew } from "./types";
import DeleteReportModal from "../../components/Modals/DeleteReportModal";
import { ModalContainer } from "../../components";
const ReportHeader = ({ name, id, index }: iReportHeaderNew) => {
    const [isDeleteing, setIsDeleteing] = useState(false);

    return (
        <ReportHeaderWrapper light={index % 2 === 0}>
            <p>{name}</p>
            <div>
                <Link
                    className="report-header__btn"
                    to={`/reports/${id}_${name.toLowerCase()}`}
                >
                    <img src={icons?.purpleTarget} alt="Select Report" />
                </Link>
                <ModalContainer
                    isVisible={isDeleteing}
                    children={
                        <DeleteReportModal
                            isOpen={isDeleteing}
                            setIsOpen={setIsDeleteing}
                            reportId={id}
                            reportName={name}
                        />
                    }
                />
                <button
                    className="report-header__btn"
                    type="button"
                    onClick={setIsDeleteing.bind(null, true)}
                >
                    <img src={icons?.deleteBin} alt="Delete Report" />
                </button>
            </div>
        </ReportHeaderWrapper>
    );
};

export default ReportHeader;
