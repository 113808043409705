import React, { useState } from "react";
import { TableRowProps } from "..";
import { ColorBlock } from "../styles";
import { Modal } from "../../..";
import EditLeadStatusModal from "../../../Modals/Settings/LeadStatus/EditLeadStatusModal";
import { formatTimeString } from "../../../../global/utils/helpers";

const LeadStatusRow: React.FC<TableRowProps> = ({ data }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <>
            <Modal
                isVisible={isModalOpen}
                children={
                    <EditLeadStatusModal
                        closeModal={() => setIsModalOpen(false)}
                        open={isModalOpen}
                        data={data}
                    />
                }
            />
            <tr role="button" onClick={() => setIsModalOpen(true)}>
                <td>{data?.system_case_status_name ?? "-"}</td>
                <ColorBlock color={data?.color} />
                <td>
                    {formatTimeString(data?.system_case_status_edited ?? "")}
                </td>
            </tr>
        </>
    );
};
export default LeadStatusRow;
