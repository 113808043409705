import React from "react";
import { icons } from "../../assets/icons";
import { FormStepHeaderWrapper } from "./styles";

export interface iFormStepHeader {
  title: string;
}

const FormStepContainerHeader = ({ title }: iFormStepHeader) => {
  return (
    <FormStepHeaderWrapper>
      <h6>{title}</h6>

      <img src={icons?.whiteDownArrow} alt="" />
    </FormStepHeaderWrapper>
  );
};

export default FormStepContainerHeader;
