import styled from "styled-components";
import { theme } from "../../global/theme";
import { centeredRowMixin } from "../../global/theme/reuseable/mixins";
import { iSCActive } from "../../global/theme/types";

export const navBarHeight = "45px";
export const toolbarHeight = "60px";

interface HeaderContaierProps {
    hasNavBar?: boolean;
}

export const HeaderContainer = styled.div<HeaderContaierProps>`
    background: ${theme?.colors?.background};
    z-index: 3;
    /* ;
    position: sticky;
    top: ${({ hasNavBar }) =>
        hasNavBar ? `calc(${toolbarHeight} + ${navBarHeight})` : toolbarHeight};
    z-index: 5; */
    a {
        text-decoration: none;
    }

    & .header {
        &__nav {
            display: flex;
            justify-content: center;
            gap: 50px;
            padding: 10px 30px;
            background-color: ${theme?.colors.white};
        }

        &__link {
            color: ${theme?.colors.text};
        }
        &__header {
            padding: 4px 0;
            margin-left: 30px;
            margin-right: 30px;
            display: flex;
            text-transform: uppercase;
            align-items: center;
            border-bottom: 0.6px solid ${theme?.colors?.boxStroke};
            gap: 16px;
            min-height: 72px;
        }
        &__prev {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__title {
            font-size: 20px;
        }

        &__content {
            flex: 1;
            display: flex;
            text-transform: initial;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;

            & > .button {
                height: 40px;
                padding-inline: 16px;
            }
        }
    }
`;

export const SubLink = styled.h6<iSCActive>`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${({ active }) =>
        !active ? `${theme?.colors?.text}` : `${theme?.colors?.purple}`};

    :hover {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #583bd8;
    }
`;

export const NavBarCtn = styled.nav`
    position: sticky;
    top: ${toolbarHeight};
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: clamp(16px, 2vw, 40px);
    padding: 0 30px;
    height: ${navBarHeight};
    background-color: ${theme?.colors.white};
    font-size: 14px;
    line-height: 105%;

    & .nav-bar {
        &__link {
            color: ${theme?.colors.text};
            word-break: break-word;
        }
    }
`;
