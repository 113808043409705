import Input from "../../../Input";
import { StepTwoWrapper } from "./styles";
import { iStep } from "./types";

const StepTwo = ({ handleChange, newCreditor, setNewCreditor }: iStep) => {
    return (
        <StepTwoWrapper>
            <Input
                name="accountName"
                onChange={handleChange}
                type="text"
                value={newCreditor?.accountName}
                label="Account Name:"
            />
            <Input
                name="accountNumber"
                onChange={handleChange}
                type="text"
                inputType="numString"
                value={newCreditor?.accountNumber}
                label="Account Number:"
            />
            <Input
                name="sortCode"
                value={newCreditor?.sortCode}
                onChange={handleChange}
                type="text"
                label="Account Sort Code:"
                placeholder="e.g. 11-22-33"
            />
        </StepTwoWrapper>
    );
};

export default StepTwo;
