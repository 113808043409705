import { BusinessAccountancyContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";

const VAT: React.FC<BusinessAccountancyContainerProps> = ({
    formData,
    onInputChange,
}) => {
    // vat_number: "",
    // vat_period_start: "",
    // vat_period_end: "",
    // vat_payment_due: "",
    // vat_payment_receive_date: "",
    return (
        <HeaderContainer title="VAT:" ctnClassName="col-2">
            <Input
                type="text"
                label="VAT Number:"
                value={formData?.vat_number || ""}
                onChange={onInputChange}
                name="vat_number"
                className="full-col"
            />
            <Input
                type="date"
                label="Start of VAT Period:"
                value={formData?.vat_period_start || ""}
                onChange={onInputChange}
                name="vat_period_start"
            />
            <Input
                type="date"
                label="End of VAT Period:"
                value={formData?.vat_period_end || ""}
                onChange={onInputChange}
                name="vat_period_end"
            />
            <Input
                type="date"
                label="VAT Payment Due By:"
                value={formData?.vat_payment_due || ""}
                onChange={onInputChange}
                name="vat_payment_due"
            />
            <Input
                type="date"
                label="VAT Payment Received:"
                value={formData?.vat_payment_receive_date || ""}
                onChange={onInputChange}
                name="vat_payment_receive_date"
            />
        </HeaderContainer>
    );
};

export default VAT;
