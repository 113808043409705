import styled from "styled-components";
import { theme } from "../../global/theme";

export const PaymentDashboardStatWrapper = styled.div`
    height: 91px;
    background: ${theme?.colors?.white};
    border-radius: 15px;
    padding-left: 17px;
    padding-top: 10px;
    padding-bottom: 19px;

    p {
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        color: #474747;
    }
`;
