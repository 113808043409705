import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import { TablePageContent } from "../../../pages/styles";
import DataTable from "../../../components/DataTable";
import {
    StyledListControls,
    StyledListHeader,
    StyledListSearchRow,
} from "./styles";
import { Button, TextArea } from "../../../components";
import useFetchData from "../../../global/hooks/useFetchData";
import ListRow from "../../../components/DataTable/TableRows/Correspondence/ListRow";
import { Modal } from "../../../components";
import CreateCorresNotes from "../../../components/Modals/Correspondence/CreateCorresNotes";
import CreateCorresCall from "../../../components/Modals/Correspondence/CreateCorresCall";
import CreateCorresSMS from "../../../components/Modals/Correspondence/CreateCorresSMS";
import CreateCorresEmail from "../../../components/Modals/Correspondence/CreateCorresEmail";
import CreateCorresDocuments from "../../../components/Modals/Correspondence/CreateCorresDocument";
import CreateCorresUpload from "../../../components/Modals/Correspondence/CreateCorresUpload";
import useReduxClientCorrespondence from "../../../store/hooks/useReduxClientCorrespondence";

const List = () => {
    const { clientId } = useParams();
    const { list, setList } = useReduxClientCorrespondence();
    const [searchTerm, setSearchTerm] = useState("");
    const [modalName, setModalName] = useState("");
    const modals = {
        Notes: CreateCorresNotes,
        Call: CreateCorresCall,
        SMS: CreateCorresSMS,
        Email: CreateCorresEmail,
        Document: CreateCorresDocuments,
        Upload: CreateCorresUpload,
    };

    const getListsData = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/correspondence-list/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setList(data?.data);
            },
        }
    );

    const downloadDPA = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/download-dpa/${clientId}`,
    });

    const dpaHandler = () => {
        downloadDPA.fetchData();
    };

    const hdrHandler = () => {
        alert("HDR form");
    };

    const complaintHandler = () => {
        alert("Complaint");
    };
    console.log(list);
    return (
        <>
            {Object.entries(modals).map(([key, ListModal]) => (
                <Modal isVisible={modalName === key} key={key}>
                    <ListModal
                        isOpen={modalName === key}
                        onClose={() => setModalName("")}
                    />
                </Modal>
            ))}
            <StyledListHeader title="List" hasNavBarAbove isSubPath hasPrevPath>
                <TextArea
                    label="Backbook Notes:(Read Only)"
                    value={list?.backbook_notes || ""}
                    readOnly
                    rows={2}
                />
            </StyledListHeader>
            <TablePageContent>
                <StyledListSearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="search by id, description or debt name..."
                >
                    <StyledListControls>
                        <div className="buttons">
                            {Object.keys(modals).map((name) => (
                                <Button
                                    btnText={name}
                                    type="button"
                                    onClick={() => setModalName(name)}
                                    style="white"
                                    key={name}
                                />
                            ))}
                            <Button
                                btnText="DPA"
                                type="button"
                                onClick={dpaHandler}
                                style="purple"
                                loading={downloadDPA.isLoading}
                            />
                            <Button
                                btnText="HDR Claim Form"
                                type="button"
                                onClick={hdrHandler}
                                style="purple"
                            />
                            <Button
                                btnText="Complaint"
                                type="button"
                                onClick={complaintHandler}
                                style="purple"
                            />
                        </div>
                    </StyledListControls>
                </StyledListSearchRow>
                <DataTable
                    data={list?.list || []}
                    labels={[
                        "ID",
                        "Type",
                        "Description",
                        "Debt Name",
                        "Created By",
                        "Create At",
                    ]}
                    keys={[
                        "id",
                        "type",
                        "dscrptn",
                        "debt_name",
                        "created",
                        "created_at",
                    ]}
                    searchKeys={["debt_name", "dscrptn", "id"]}
                    searchTerm={searchTerm}
                    tableRow={ListRow}
                    formatKeys={{
                        dscrptn: "html",
                    }}
                />
            </TablePageContent>
        </>
    );
};

export default List;
