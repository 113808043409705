import React, { useState } from "react";
import useFetchData from "../../../../global/hooks/useFetchData";
import { capitalize } from "lodash";
import Button from "../../../Button";
import ModalHeader from "../../components/ModalHeader";
import StyledCorresDetails from "./styles";
import { formatTimeString } from "../../../../global/utils/helpers";
import DropdownSelect from "../../../DropdownSelect";
import SuccessContent from "../../components/SuccessContent";
import TextArea from "../../../Input/TextArea";
import ModalButtons from "../../components/ModalButtons";
import TypeDetails from "./TypeDetails";

interface CorresDetailsProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    data: { [key: string]: any };
}

const CorresDetails: React.FC<CorresDetailsProps> = ({
    isOpen,
    onClose,
    data,
}) => {
    const [debtId, setdebtId] = useState<number | null>(data?.debt_id ?? null);
    const [description, setDescription] = useState(data?.dscrptn ?? "");
    const [newData, setNewData] = useState<{ [key: string]: any }>(data || {});

    const getDebtList = useFetchData({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_API}/debt/${data?.id}`,
    });
    const updateDetails = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/correspondence/${data?.id}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) {
                }
            },
        }
    );

    const deleteDetails = useFetchData(
        {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_API}/correspondence/${data?.id}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) {
                }
            },
        }
    );
    const isSuccess = updateDetails.isSuccess || deleteDetails.isSuccess;

    const deleteHandler = () => {
        if (data?.id) deleteDetails.fetchData({ id: data?.id });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (data?.id && data?.type) {
            const requestData = {
                type: data?.type,
                debt_id: debtId,
                description,
                data,
            };
            updateDetails.fetchData(requestData);
        }
    };

    return (
        <StyledCorresDetails className="corres-details" show={isOpen}>
            <ModalHeader onClose={onClose}>
                View {data?.type || ""}
                <Button
                    type="button"
                    style="red"
                    btnText="Delete"
                    disabled={updateDetails.isLoading}
                    loading={deleteDetails.isLoading}
                    onClick={deleteHandler}
                />
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    onClose={onClose}
                    message={
                        updateDetails.isSuccess
                            ? "List details has been updated."
                            : "List item has been deteled."
                    }
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <div className="form-content">
                        <div className="summary">
                            <ul className="data-list">
                                <li>
                                    ID:<span>{data?.id ?? "-"}</span>
                                </li>
                                <li>
                                    Direction:
                                    <span>
                                        {capitalize(data?.drctn ?? "-")}
                                    </span>
                                </li>
                            </ul>
                            <DropdownSelect
                                label="Debt:"
                                options={getDebtList?.data?.data}
                                isLoading={getDebtList.isLoading}
                                optionKey="id"
                                labelKeys={["name"]}
                                onSelect={(option) => setdebtId(option.id)}
                            />
                            <TextArea
                                label="Description:"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <ul className="data-list">
                                <li>
                                    Created By:
                                    <span>{data?.created || ""}</span>
                                </li>
                                <li>
                                    Created At:
                                    <span>
                                        {formatTimeString(
                                            data?.created_at || ""
                                        )}
                                    </span>
                                </li>
                                <li>
                                    Updated By:
                                    <span>{data?.updated_by ?? "-"}</span>
                                </li>
                                <li>
                                    Updated At:
                                    <span>
                                        {formatTimeString(
                                            data?.updated_at || ""
                                        )}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className="details" data-type={data?.type}>
                            <h3>{data?.type || ""}</h3>
                            <TypeDetails
                                data={newData}
                                setData={setNewData}
                                type={data?.type || ""}
                            />
                        </div>
                    </div>

                    <ModalButtons>
                        <Button
                            type="submit"
                            btnText="Save Changes"
                            style="purple"
                            disabled={deleteDetails.isLoading}
                            loading={updateDetails.isLoading}
                        ></Button>
                    </ModalButtons>
                </form>
            )}
        </StyledCorresDetails>
    );
};

export default CorresDetails;
