import styled from "styled-components";
import { ResizableModalWrapper } from "../styles";

export const StyledCreateAddress = styled(ResizableModalWrapper)`
    width: min(100%, 500px);

    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;

        & > p {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }
`;
