import { TableRowProps } from "..";
import moment from "moment";
import ViewMailUpload from "../../actions/ViewMailUpload";
const MailUploadRow: React.FC<TableRowProps> = ({ data }) => {
    const formattedCreateAt = moment(data?.created_at).format(
        "DD-MM-YYYY HH:mm:ss"
    );
    const status = data?.status === 0
    ? 'Converting to JPG'
    : data?.status === 1
      ? 'New'
      : data?.status === 3
        ? 'Error'
        :data?.status===4
        ? 'In Procress'
        :data?.status===5
        ? 'Completed'
        :data?.status===6
        ? 'Deleted'
        : 'Unknown Status';
    return (
        <tr>
            <td>{data?.id ?? "-"}</td>
            <td>{ data?.hash ?? "-"}</td>
            <td>{ data?.page_total ?? "-"}</td>
            <td>{formattedCreateAt ?? "-"}</td>
            <td>{ status ?? "-"}</td>
            <td><ViewMailUpload data={data}/></td>
        </tr>
    );
};
export default MailUploadRow;
