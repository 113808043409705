import { StyledTableLink } from "./styles";
import { ReactComponent as ViewIcon } from "../../../assets/icons/eye.svg";

const ViewMailUpload: React.FC<{ data: { [key: string]: any } }> = ({
    data,
}) => {
    return (
        <StyledTableLink to={`${data?.id}`} btncolor="transparent">
            <ViewIcon />
        </StyledTableLink>
    );
};

export default ViewMailUpload;
