import { Outlet } from "react-router-dom";
import NavBar from "../../../layout/Header/NavBar";
import { useLocation } from "react-router-dom";

const Client = () => {
    const { pathname } = useLocation();

    console.log();

    return (
        <>
            <NavBar subListName="client" />
            <Outlet />
        </>
    );
};

export default Client;
