import admin from "./admin.svg";
import administration from "./administration.svg";
import client from "./client.svg";
import creditor from "./creditor.svg";
import dashboard from "./dashboard.svg";
import diary from "./diary.svg";
import introducer from "./introducer.svg";
import reports from "./reports.svg";
import payments from "./payments.svg";
import settings from "./settings.svg";
import search from "./search.svg";
import whiteDownArrow from "./whiteDownArrow.svg";
import leftArrow from "./whiteLeftArrow.svg";
import bell from "./bell.svg";
import purpleDownArrow from "./purpleDownArrow.svg";
import profileSettings from "./profileSettings.svg";
import notificationsBackground from "./notificationsBackground.svg";
import logout from "./logout.svg";
import bellHead from "./bellHead.svg";
import bellClapper from "./bellClapper.svg";
import csLogo from "./csLogo.svg";
import arrowCompass from "./arrowCompass.svg";
import whiteX from "./whiteX.svg";
import x from "./x.svg";
import whitePlus from "./whitePlus.svg";
import whiteMinus from "./whiteMinus.svg";
import calendar from "./calendar.svg";
import purpleLeftArrow from "./purpleLeftArrow.svg";
import purpleBell from "./purpleBell.svg";
import blackDownArrow from "./blackDownArrow.svg";
import loader from "./loader.svg";
import chart from "./chart.svg";
import deleteBin from "./deleteBin.svg";
import eye from "./eye.svg";
import globe from "./globe.svg";
import key from "./key.svg";
import reload from "./reload.svg";
import exportIcon from "./export.svg";
import circlePlus from "./circlePlus.svg";
import paymentRun from "./paymentRun.svg";
import updateBankPayments from "./updateBankPayments.svg";
import close from "./close.svg";
import greyLeftArrow from "./greyLeftArrow.svg";
import whiteRightArrow from "./whiteRightArrow.svg";
import purpleTarget from "./purpleTarget.svg";
import aws from "./aws.svg";
import document from "./document.svg";
import email from "./email.svg";
import globalSettings from "./globalSettings.svg";
import income from "./income.svg";
import leadStatus from "./leadStatus.svg";
import paymentCard from "./paymentCard.svg";
import printer from "./printer.svg";
import signature from "./signature.svg";
import sms from "./sms.svg";
import tv from "./tv.svg";
import workflow from "./workFlow.svg";
import edit from "./edit.svg";
import paperAirplane from "./paperAirplane.svg";

import purpleArrow from "./purpleArrow.svg";
import signable from "./signable.svg";
import colouredAws from "./colouredAws.svg";

import worldPay from "./worldPay.svg";
import twilio from './twilio.svg';
import vonage from './vonage.svg';

import greenTick from "./greenTick.svg";
import redCross from "./redCross.svg";
import redTv from "./redTv.svg";
import greenTv from "./greenTv.svg";

import greyLayout from "./greyLayout.svg";
import greyGlobe from "./greyGlobe.svg";

import blueClose from "./blueClose.svg";
import redClose from "./redClose.svg";

import undo from './undo.svg'
import redo from './redo.svg'

export interface Types {
    [key: string]: string;
}

const icons: Types = {
    admin,
    administration,
    arrowCompass,
    aws,
    bell,
    bellClapper,
    bellHead,
    blackDownArrow,
    blueClose,
    calendar,
    chart,
    circlePlus,
    client,
    close,
    colouredAws,
    creditor,
    csLogo,
    dashboard,
    deleteBin,
    diary,
    document,
    edit,
    email,
    exportIcon,
    eye,
    globalSettings,
    globe,
    greenTick,
    greenTv,
    greyGlobe,
    greyLayout,
    greyLeftArrow,
    income,
    introducer,
    key,
    leadStatus,
    leftArrow,
    loader,
    logout,
    notificationsBackground,
    paperAirplane,
    paymentCard,
    payments,
    printer,
    profileSettings,
    purpleArrow,
    purpleBell,
    purpleDownArrow,
    purpleLeftArrow,
    purpleTarget,
    redClose,
    redCross,
    redTv,
    reload,
    reports,
    search,
    settings,
    signable,
    signature,
    sms,
    tv,
    whiteMinus,
    whitePlus,
    whiteRightArrow,
    whiteX,
    workflow,
    worldPay,
    x,
    whiteDownArrow,
    paymentRun,
    updateBankPayments,
    undo,
    redo,
    twilio,
    vonage
};

export { icons };
