import React, { ChangeEvent, useEffect, useState } from "react";

import Header from "../../../../layout/Header";
import { Button } from "../../../../components";
import { TablePageContent } from "../../../../pages/styles";
import SearchRow from "../../../../components/SearchRow";
import DataTable from "../../../../components/DataTable";
import WorkflowTemplateRow from "../../../../components/DataTable/TableRows/Settings/WorkflowTemplateRow";
import { Modal } from "../../../../components";
import EditWorkflowTemplate from "../../../../components/Modals/Settings/WorkflowTemplates/EditWorkflowTemplate";
import useFetchData from "../../../../global/hooks/useFetchData";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";

const Workflows: React.FC = () => {
    const { setTemplate, templates } = useReduxSettings();
    const [isCreating, setIsCreating] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const getWorkflowList = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/templates-workflow`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTemplate("workflow", data?.data);
            },
            fetchOnLoad: true,
        }
    );

    const testData = templates.workflow || [];

    useEffect(() => {
        setTemplate("workflow", testData);
    }, []);

    return (
        <>
            <Modal isVisible={isCreating}>
                <EditWorkflowTemplate
                    isOpen={isCreating}
                    handleModal={() => setIsCreating(false)}
                    mode="create"
                />
            </Modal>
            <Header
                title="workflow template"
                prevPath="/settings"
                isSubPath
                hasNavBarAbove
            />
            <TablePageContent>
                <SearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by name or area..."
                >
                    <Button
                        btnText="+ Create"
                        style="mint"
                        onClick={() => setIsCreating(true)}
                        role="create"
                    />
                </SearchRow>
                <DataTable
                    labels={["Name", "Area"]}
                    keys={["name", "area"]}
                    data={testData || []}
                    hasAction
                    tableRow={WorkflowTemplateRow}
                    columnWidths={["50%", "50%", ""]}
                    searchTerm={searchTerm}
                    searchKeys={["name", "area"]}
                    isLoading={getWorkflowList.isLoading}
                />
            </TablePageContent>
        </>
    );
};

export default Workflows;
