import styled from "styled-components";
import { PageContent, TablePageContent } from "../../../pages/styles";
import { theme } from "../../../global/theme";
import Header from "../../../layout/Header";
import DetailCard from "../../../components/Cards/DetailCard";

export const LedgerPaymentsLandingHeader = styled(Header)`
    & .header__content {
        justify-content: flex-start;
    }
`;

const StyledLedgerPaymentsLandingContent = styled(PageContent)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;

    & .right-ctn {
        display: flex;
        align-items: flex-start;
        align-self: flex-start;
        flex-wrap: wrap;
        gap: 24px;
        flex: 1 1 0;
        & .detail-card {
            width: 340px;
            height: 150px;
        }
    }

    & .detail-card {
        align-items: initial;
        padding: 16px;
        &__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 24px;

            & > a {
                border: 1px solid ${theme.colors.purple};
                color: ${theme.colors.purple};
                border-radius: 6px;
                font-size: 12px;
                padding: 6px 12px;
            }
        }

        & ul {
            list-style: none;
            margin-top: 12px;
        }

        & li {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 300;

            &:not(:first-child) {
                margin-top: 4px;
            }
        }
    }
`;

export const StyledLedgerCard = styled(DetailCard)`
    width: 260px;
    padding: 24px;
    padding-top: 16px;
    display: inline-block;

    & h3 {
        font-size: 12px;
        margin-block: 8px;
    }

    & .btns {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
`;

export const StyledLedgerContent = styled(PageContent)`
    flex-direction: row;
    gap: 24px;

    & .table-col {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 24px;
        overflow-x: auto;
    }

    & .ip-workflow {
        margin-top: 16px;
        padding: 24px;
        padding-top: 16px;
        align-items: initial;

        & h2 {
            margin-bottom: 12px;
        }

        & form {
            display: flex;
            flex-direction: column;
            gap: 12px;

            & > button {
                margin-top: 8px;
            }
        }
    }
`;

export const StyledPaymentsContent = styled(TablePageContent)`
    & .search-row {
        align-items: flex-end;
        justify-content: space-between;
        &__content {
            height: auto;
        }
    }

    & .detail-card {
        min-width: 300px;
        padding: 12px 16px;

        &__title {
            margin-bottom: 0;
        }
    }
`;

export const StyledPaymentsScheduleContent = styled(TablePageContent)`
    & .search-row {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        align-items: flex-end;

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }

        & h2 {
            align-self: flex-start;
            font-size: 16px;
        }

        &__content {
            height: auto;
        }
        & .detail-card {
            grid-area: 1/2/-1/-1;
        }
    }

    & .data-list {
        list-style: none;
        min-width: 270px;

        & li {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 300;
            &:not(:first-child) {
                margin-top: 4px;
            }
        }
    }
`;

export default StyledLedgerPaymentsLandingContent;
