import styled from "styled-components";
import { Link } from "react-router-dom";

import { theme } from "../../../global/theme";

const StyledDetailCard = styled.div`
    padding: 16px;
    background-color: ${theme?.colors.white};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & .detail-card {
        &__title {
            font-size: 14px;
            margin-bottom: 6px;
        }

        &__count {
            font-size: 32px;
            font-weight: 600;
            background-image: ${theme?.gradients.skyBlue};
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
        }

        &__content {
            flex: 1;
        }

        &__list {
            list-style: none;
            min-width: 180px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            & > li {
                display: flex;
                justify-content: space-between;
                font-weight: 300;
                font-size: 12px;
            }
        }

        &__btns {
            margin-top: 16px;
            display: flex;
            gap: 8px;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        &__spinner {
            flex: 1 1 0;
            position: relative;
            min-height: 60px;

            & div {
                position: absolute;
                top: calc(50% - 16px);
                left: calc(50% - 16px);
            }
        }
    }
`;

const detailCardClickable = `
padding: 6px 16px;
border-radius: 6px;
border: 1px solid ${theme?.colors.purple};
font-size: 12px;`;

export const StyledDetailCardButton = styled.button`
    ${detailCardClickable};
    color: ${theme?.colors.white};
    background-color: ${theme?.colors.purple};
`;

export const StyledDetailCardLink = styled(Link)`
    ${detailCardClickable};
    color: ${theme?.colors.purple};
`;

export default StyledDetailCard;
