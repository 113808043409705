import styled from "styled-components";
import { PageContent } from "../../../../pages/styles";
import { theme } from "../../../../global/theme";
import { mainScrollbar } from "../../../../global/theme/reuseable/mixins";

export const MailUploadPreviewContent = styled(PageContent)`
    display: flex;
    flex-direction: row;
    gap: 32px;

    & .controls {
        display: flex;
        flex-direction: column;

        &-wrapper {
            flex-grow: 1;
            flex-basis: 0;
            overflow: auto;
            ${mainScrollbar(16)}
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }
`;

export const StyledPreviewMail = styled.div`
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    border: 0.6px solid ${theme?.colors.boxStroke};
    border-radius: 8px;
    background-color: #45454585;
    max-height: calc(100vh - 250px);

    & .preview-mail {
        &__wrapper {
            flex-grow: 1;
            flex-basis: 0;
            padding: 8px 0;

            overflow: auto;
            ${mainScrollbar(16)};
            display: flex;
            justify-content: center;
        }

        &__scale {
            position: absolute;
            top: 8px;
            right: 16px;
            background-color: ${theme?.colors.white};
            padding: 8px;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            width: 200px;
            border: 0.6px solid ${theme?.colors.boxStroke};
            z-index: 2;
            & input {
                width: 100px;
            }
        }
    }
`;

export const SideDetailsWrapper = styled.div`
    .header {
        background: linear-gradient(123.76deg, #4d9eff 4.28%, #686fff 103.7%);
        padding: 12px 24px;
        border-radius: 10px 10px 0 0;

        h4 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
        }
    }

    .body {
        background: #fbfdff;
        padding: 24px;
        border-radius: 0 0 10px 10px;
    }
`;

export const StyledPageCounter = styled(SideDetailsWrapper)`
    & .header {
        position: relative;

        & > div:last-child {
            position: absolute;
            top: 10px;
            right: 12px;

            & > button {
                border: none;
                height: 28px;
                width: 36px;
                font-size: 20px;
                font-weight: 300;
            }
        }
    }
    .body {
        display: grid;
        grid-template-columns: 160px 1fr;
        gap: 8px 16px;
        min-width: 460px;

        .buttonInputWrapper button {
            width: 60px;
        }

        & > form {
            grid-column: 1/-1;
            display: flex;
            flex-direction: column;
            row-gap: 12px;

            & textarea {
                height: 72px;
            }

            & > .btns {
                align-self: flex-end;
                display: flex;
                column-gap: 8px;

                button {
                    width: 120px;
                }
            }
        }
    }
`;
export const UploadDetailsWrapper = styled(SideDetailsWrapper)`
    .body {
        display: flex;
        flex-direction: column;
        gap: 8px;

        & hr {
            border: 0.6px solid ${theme?.colors.boxStroke};
            margin-block: 8px;
        }

        & .detail {
            display: grid;
            grid-template-columns: 140px 1fr;
            font-size: 12px;
            font-weight: 300;

            & span {
                display: flex;
                gap: 8px;

                & button {
                    width: 120px;
                    height: 36px;
                }
            }
        }
    }
`;
