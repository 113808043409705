import React, { useState, useEffect } from "react";
import { StyledPersonalInfo } from "./styles";
import Applicant from "./Applicant";
import Other from "./Other";
import useReduxCreateClient from "../../../../../store/hooks/useReduxCreateClient";
import useFetchData from "../../../../../global/hooks/useFetchData";
import { useParams } from "react-router-dom";
import { ClientFormStepProps } from "../ClientForm";
import ClientFormStepButtons from "../ClientFormStepButtons";

const PersonalInfo: React.FC<ClientFormStepProps> = ({
    setCurrentStep,
    isFirstStep,
    isLastStep,
}) => {
    const { clientId } = useParams();
    const { personal_info, setDataByKey } = useReduxCreateClient();
    const [applicant1, setapplicant1] = useState<{ [key: string]: any } | null>(
        {}
    );
    const [applicant2, setapplicant2] = useState<{ [key: string]: any } | null>(
        {}
    );
    const { fetchData, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client`,
        },
        {
            onSuccess: (data) => {
                setCurrentStep((prev) => prev + 1);
                setDataByKey("personal_info", data?.data?.personal_info);
            },
            notifyOnSuccess: true,
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (clientId) {
            const data = {
                id: +clientId,
                tab: "personal_info",
                data: [{ applicant1 }, { applicant2 }],
            };
            fetchData(data);
        }
    };

    useEffect(() => {
        const applicant1 = personal_info?.applicant1;
        const applicant2 = personal_info?.applicant2;

        setapplicant1(applicant1 || {});
        setapplicant2(applicant2 || {});
    }, [personal_info]);

    return (
        <StyledPersonalInfo
            className="client-form__content personal-info"
            onSubmit={submitHandler}
        >
            <Applicant primary data={applicant1} setData={setapplicant1} />
            <Applicant
                primary={false}
                data={applicant2}
                setData={setapplicant2}
            />
            <Other data={applicant1} setData={setapplicant1} />
            <ClientFormStepButtons
                submitDisabled={!applicant1?.title}
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                isSubmitting={isLoading}
            />
        </StyledPersonalInfo>
    );
};

export default PersonalInfo;
