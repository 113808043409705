import styled from "styled-components";
import { PageContent } from "../../styles";

const StyledIncomeExpense = styled(PageContent)`
    & > h2 {
        margin-top: -8px;
        margin-bottom: 12px;
        font-size: 20px;
        margin-left: auto;
    }

    & .search-row {
        justify-content: space-between;
    }

    & .searchInput {
        order: 2;
    }
`;

export default StyledIncomeExpense;
