import React, { ChangeEvent, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";
import { StyledAdminLedgerModal } from "./styles";
import ModalButtons from "../components/ModalButtons";
import Input from "../../Input";
import Button from "../../Button";
import ModalHeader from "../components/ModalHeader";
import DropdownSelect from "../../DropdownSelect";
import {
    generateTestOptions,
    ledgerTypeOptions,
} from "../../DropdownSelect/options";
import TextArea from "../../Input/TextArea";
import SuccessContent from "../components/SuccessContent";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxClientLedgerPayment from "../../../store/hooks/useReduxClientLedgerPayment";

interface CreateLedgerModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
    onSuccess?: (data: { [key: string]: any }) => void;
}

const CreateLedgerModal: React.FC<CreateLedgerModalProps> = ({
    isOpen,
    setIsOpen,
    onSuccess = () => {},
}) => {
    const { setDataByKey } = useReduxClientLedgerPayment();
    const { clientId } = useParams();
    const [ledgerData, setLedgerData] = useState<{ [key: string]: any }>({
        payment_ref: "",
        reason: "",
        amount: 0,
        type: null,
        notes: "",
    });
    const isFormValid = !!ledgerData?.payment_ref && !!ledgerData?.type;

    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/ledger-create/${clientId}`,
        },
        {
            onSuccess: (data) => {
                onSuccess(data);
                if (data?.data) setDataByKey("ledger", data.data);
            },
        }
    );

    const closeModal = () => setIsOpen(false);

    const selectHandler = (key: string, value: number) => {
        if (key in ledgerData)
            setLedgerData((prev) => {
                return { ...prev, [key]: value };
            });
    };

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type } = e.target;
        setLedgerData((prev) => {
            return { ...prev, [name]: type === "number" ? +value : value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        //console.log(ledgerData);
        fetchData({ data: ledgerData });
    };

    return (
        <StyledAdminLedgerModal
            show={isOpen}
            className="update-ledger"
            width="500px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={closeModal}>+ Create Ledger</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="Ledger has been created."
                    onClose={closeModal}
                />
            ) : (
                <form className="update-ledger__form" onSubmit={submitHandler}>
                    <div className="update-ledger__fields">
                        <Input
                            type="text"
                            label="Payment Refernece:"
                            name="payment_ref"
                            value={ledgerData?.payment_ref}
                            onChange={handleChange}
                            placeholder="Enter payment reference..."
                        />

                        <DropdownSelect
                            label="Type"
                            options={ledgerTypeOptions}
                            value={ledgerData?.type || null}
                            onSelect={(data) =>
                                selectHandler("type", data.value)
                            }
                            className="update-ledger__type"
                            enableInput
                        />
                        <Input
                            type="number"
                            label="Amount:"
                            name="amount"
                            value={ledgerData?.amount.toString()}
                            onChange={handleChange}
                        />
                        <TextArea
                            label="Reason:"
                            name="reason"
                            value={ledgerData?.reason}
                            onChange={handleChange}
                        />

                        <TextArea
                            label="Notes:"
                            name="notes"
                            value={ledgerData?.notes}
                            onChange={handleChange}
                        />
                    </div>

                    <ModalButtons>
                        <Button
                            style="purple"
                            btnText={"Create"}
                            type="submit"
                            loading={isLoading}
                            disabled={!isFormValid}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledAdminLedgerModal>
    );
};

export default CreateLedgerModal;
