const productsData = [
    {
        id: 5,
        title: "PBS",
        subTitle: "Personal Budgeting",
        benefits: [
            "Save Money on Utilities",
            "Save Money on Shopping List",
            "Income & Expenditure",
            "Manage your Creditors",
            " ",
        ],
    },
    {
        id: 101,
        title: "PA",
        subTitle: "Peronsal Accountancy",
        benefits: [
            "Book Keeping",
            "Registering for Self Assesments",
            "Mileage Log",
            "Check your Current Employment Status with HMRC",
            "Register Self Employed Online",
        ],
    },
    {
        id: 102,
        title: "BA",
        subTitle: "Business Accountancy",
        benefits: [
            "Book Keeping",
            "Set up as a Sale Trader",
            "Register your Company",
            "Information on VAT",
            "Register for Corporation Tax",
        ],
    },
];
export default productsData