import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from ".";
import { reportsActions } from "../slices/reportsSlice";

const useReduxReports = () => {
    const { list } = useAppSelector((state) => state.reports);
    const dispatch = useAppDispatch();

    const setList = (data: {[key: string]: any} | null) => {
        dispatch(reportsActions.setList(data))
    };

    return {
        list,
        setList
    };
};

export default useReduxReports;
