import styled from "styled-components";
import { theme } from "../../../global/theme";
import {
    hideScrollbar,
    mainScrollbar,
} from "../../../global/theme/reuseable/mixins";
import { PageContent, pageInlinePadding } from "../../styles";

const gap = "25px";

export const DashboardContent = styled(PageContent)`
    flex: 1 1 0;
    overflow-y: hidden;
    padding: 0;
`;

export const DashboardLayout = styled.section`
    display: flex;
    gap: ${gap};
    flex: 1 1 0;
    overflow: hidden;
    padding-block: ${pageInlinePadding};

    .left {
        overflow-y: hidden;
        position: relative;
        width: clamp(240px, 25%, 340px);
        display: flex;
        flex-direction: column;
        padding-left: ${pageInlinePadding};

        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;
            ${mainScrollbar(12)};
            overflow-y: auto;
            width: 100%;

            /* & > div {
                width: 100%;
            } */
        }
    }

    label {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: ${theme?.colors?.darkText};
    }
`;

export const Break = styled.span`
    border: solid grey 0.5px;
`;

export const Right = styled.div`
    flex: 1 1 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    & .right-ctn {
        flex: 1 1 0;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 20px;
        ${mainScrollbar(12)};
        padding-right: ${pageInlinePadding};
    }

    & h2 {
        font-weight: 400;
        font-size: 14px;
        color: ${theme.colors.darkText};
        margin-bottom: 20px;
    }

    & h3 {
        font-size: 12px;
        color: ${theme.colors.darkText};
    }

    & .dashboard-layout__swiper {
    }

    & .dashboard-layout__slide {
    }

    & .swiper-pagination {
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 50px;
        flex-wrap: wrap;
        row-gap: 2px;
        position: initial;
        min-height: 36px;
    }

    & .swiper-pagination-bullet {
        max-width: 24px;
        width: 2.5%;
        min-width: 10px;
        height: 10px;
        border-radius: 4px;
        background-color: ${theme.colors.shade};
    }

    & .swiper-pagination-bullet-active {
        background-color: ${theme?.colors?.purple};
    }

    & .swiper-button-prev,
    & .swiper-button-next {
        bottom: 0;
        top: initial;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 24px;
        &::after {
            font-size: 18px;
            font-weight: 600;
            line-height: 100%;
            color: ${theme?.colors?.purple};
            z-index: 4;
        }
    }
`;
export const RightTop = styled.div``;

export const RightBottom = styled.div``;

export const RightBottomColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > div:first-child {
        display: flex;
        justify-content: space-between;
    }

    & > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`;

export const StyledDashboardEdit = styled.div`
    position: fixed;
    right: -300px;
    bottom: 0;
    top: 60px;
    z-index: 10;
    background: ${theme.colors.white};
    padding: 24px;
    transition: all 0.3s ease;
    pointer-events: none;
    border-left: 0.6px solid ${theme.colors.shade};
    display: flex;
    flex-direction: column;
    width: 300px;

    &[aria-expanded="true"] {
        right: 0;
        pointer-events: initial;

        & .dashboard-edit {
            &__close {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    & .dashboard-edit {
        &__close {
            position: absolute;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            left: 0;
            transform: translateX(-100%);
            transition: all 0.3s ease;
            opacity: 0;
            visibility: hidden;

            & svg {
                transform: rotate(180deg) scale(1);
                margin-left: 1px;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
        }

        &__title {
            font-size: 16px;
        }

        &__save {
            padding-inline: 16px;
        }

        &__dropdown {
            margin-block: 24px;
        }

        &__sections {
            flex: 1 1 0;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            ${hideScrollbar};

            & h3 {
                width: 100%;
                background-color: ${theme.colors.white};
                font-size: 14px;
                position: sticky;
                top: 0;
                z-index: 1;
            }
        }
    }
`;

export const StyledEditDropdown = styled.div`
    margin-top: 8px;
    &[aria-expanded="true"].edit-dropdown {
        & .edit-dropdown {
            &__ctn {
                grid-template-rows: 1fr;
            }

            &__row {
                & > svg {
                    transform: rotate(90deg);
                }
            }
        }
    }

    & .edit-dropdown {
        &__row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            padding-block: 4px;
            border: none;
            background-color: transparent;
            margin-bottom: 4px;
            color: ${theme.colors.text};
            & > svg {
                transition: all 0.3s ease;
                margin-right: 8px;
                transform: rotate(-90deg);
            }
        }
        &__ctn {
            display: grid;
            grid-template-rows: 0fr;
            transition: all 0.3s ease;
        }

        &__list {
            list-style: none;
            width: 100%;
            overflow: hidden;
            /* margin-inline: -24px;
            width: calc(100% + 48px);
            padding: 0 24px; */
        }

        &__item {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            border-radius: 4px;
            font-size: 11px;
            font-weight: 300;
            padding-left: 8px;
            background-color: ${theme.colors.background};
            margin-bottom: 8px;

            & > button {
                height: 30px;
                width: 30px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }

            &--all {
                background-color: transparent;
                background-image: ${theme.gradients.mintGreen};
                font-weight: 400;
                color: ${theme.colors.white};
                & > button {
                    background-image: none;
                    background-color: transparent;
                }
            }
        }
    }
`;
