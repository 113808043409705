import React, { Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useReduxUser from "./store/hooks/useReduxUser";
import useFetchData from "./global/hooks/useFetchData";
import useReduxUI from "./store/hooks/useReduxUI";
import { Notifications, UserSideNav } from "./layout";
import { Outlet } from "react-router-dom";
import { LoadingSpinner } from "./components/Button/styles";
import { PageMain } from "./pages/styles";
import ToolBar from "./layout/ToolBar";
import ClientToolBar from "./layout/ToolBar/ClientToolBar";

const AppMain: React.FC = () => {
    const { setIsSignedIn, setUser, isSignedIn, logoutUser } = useReduxUser();
    const { sideMenu } = useReduxUI();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const hasToken = !!localStorage.getItem("ClearStartAccessToken");
    const isInLoginPage = pathname.split("/")[1] === "login";
    const isClientCase =
        pathname.split("/")[1] === "client-case" && !!pathname.split("/")[2];
    const { isLoading } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/get-by-token`,
        },
        {
            requiresToken: true,
            fetchOnLoad: hasToken,
            onSuccess: (data) => {
                localStorage.setItem("ClearStartAccessToken", data.token);
                setIsSignedIn(true);
                setUser(data.data);
            },
            onError: (error) => logoutUser(),
        }
    );

    useEffect(() => {
        if (!isSignedIn) navigate("/login");
    }, [isSignedIn]);

    return (
        <Suspense fallback={<LoadingSpinner color="#0c7cd7" />}>
            <div className="App">
                {!isInLoginPage ? (
                    <>
                        <UserSideNav isClientCase={isClientCase} />
                        <Notifications open={sideMenu === "notifications"} />
                        <PageMain>
                            <div className="page-wrapper">
                                {isClientCase ? <ClientToolBar /> : <ToolBar />}
                                <Outlet />
                            </div>
                        </PageMain>
                    </>
                ) : (
                    <Outlet />
                )}
            </div>
        </Suspense>
    );
};

export default AppMain;
