import styled from "styled-components";

const StyledCircularMeter = styled.div`
    height: 120px;
    aspect-ratio: 1/1;
    & .circular-meter {
        &__meter {
            height: inherit;
            transition: all 0.2s ease;
        }
    }
`;

export default StyledCircularMeter;
