import { SetStateAction } from "react";
import { FormContainerWrapper } from "../../../../../containers/User/Client/styles";
import FormStepContainerHeader from "../../../../../atoms/FormStepContainerHeader";
import DropdownSelect from "../../../../../components/DropdownSelect";
import {
    cardCapturedOptions,
    paymentDayOptions,
    paymentFrequencyOptions,
    paymentMethodOptions,
} from "../../../../../components/DropdownSelect/options";
import { formatDateString } from "../../../../../global/utils/helpers";
import { Input } from "../../../../../components";

interface PaymentInfoProps {
    paymentInfo: { [key: string]: any };
    setPaymentInfo: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
}

const PaymentInfo: React.FC<PaymentInfoProps> = ({
    paymentInfo,
    setPaymentInfo,
}) => {
    const handlePaymentSelect = (key: string, value: string) => {
        setPaymentInfo((prev) => {
            return { ...prev, [key]: value };
        });
    };
    const handlePaymentInputs = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPaymentInfo((prev) => {
            return { ...prev, [name]: value };
        });
    };
    return (
        <FormContainerWrapper>
            <FormStepContainerHeader title="Payment Information" />
            <div className="content">
                <DropdownSelect
                    label="Payment Frequency:"
                    options={paymentFrequencyOptions}
                    value={paymentInfo?.pmntin_frqncy ?? null}
                    onSelect={(data) =>
                        handlePaymentSelect("pmntin_frqncy", data.value)
                    }
                />
                <Input
                    label="Next Payment Date:"
                    type="date"
                    name="next_pmnt_in_dt"
                    value={
                        formatDateString(
                            paymentInfo?.next_pmnt_in_dt,
                            "YYYY-MM-DD HH:mm:ss",
                            "YYYY-MM-DD"
                        ) || "1970-01-01"
                    }
                    onChange={handlePaymentInputs}
                />
                <DropdownSelect
                    label="Payment Day:"
                    options={paymentDayOptions}
                    value={paymentInfo?.pmnt_in_day ?? null}
                    onSelect={(data) =>
                        handlePaymentSelect("pmnt_in_day", data.value)
                    }
                    enableInput
                />
                <DropdownSelect
                    label="Payment Method:"
                    options={paymentMethodOptions}
                    value={paymentInfo?.dmppmnt_in_pmnt_mthd ?? null}
                    onSelect={(data) =>
                        handlePaymentSelect("dmppmnt_in_pmnt_mthd", data.value)
                    }
                />
                <DropdownSelect
                    label="Card Capture:"
                    options={cardCapturedOptions}
                    value={paymentInfo?.crd_cptrd ?? null}
                    onSelect={(data) =>
                        handlePaymentSelect("crd_cptrd", data.value)
                    }
                />

                {/* <Button
            btnText="Add Card Details"
            onClick={() => setIsSavingCard(true)}
            style="purple"
            type="button"
        /> */}
            </div>
        </FormContainerWrapper>
    );
};

export default PaymentInfo;
