import React, { useState } from "react";
import { EditLiabilityFormProps } from ".";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import DropdownSelect from "../../../DropdownSelect";
import { generateTestOptions } from "../../../DropdownSelect/options";
import useFetchData from "../../../../global/hooks/useFetchData";
import TextArea from "../../../Input/TextArea";

const SendToCCR: React.FC<EditLiabilityFormProps> = ({
    onClose,
    data,
    setCurrentForm,
    onSuccess = () => {},
    setLiabilityDetails,
}) => {
    const [serviceId, setServiceId] = useState<number | null>(
        data?.ccr?.service_id ?? null
    );
    const [notes, setNotes] = useState(data?.ccr?.notes ?? "");
    const isFormValid = !!serviceId;

    const getTransferList = useFetchData({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/creditors-list/${data?.id}`,
    },{

        fetchOnLoad: true,
    });

    const sendToCCR = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/liability-action/${data.id}`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Liability has been transferred",
            onSuccess: (data) => {
                // navigate to main form (LiabilityMain.tsx)
                setCurrentForm("");
                // update table data
                onSuccess();
                // udpate liability details in index.tsx
                if (data?.data) setLiabilityDetails(data.data);
            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (data?.id) {
            let temp={
                action_type : "ccr-2-mellor",
                service_id: serviceId,
                notes,
            }
            return sendToCCR.fetchData({
               data:temp
            });
        }
    };

    return (
        <>
            <form onSubmit={submitHandler} className="change-creditor">
                <DropdownSelect
                    label="Transfer To:"
                    options={
                        getTransferList?.data?.data
                    }

                    optionKey="value"
                    labelKeys={["name"]}
                    value={serviceId}
                    onSelect={(option) => setServiceId(option.value)}
                />
                <TextArea
                    label="Notes:"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
                <ModalButtons>
                    <Button
                        type="submit"
                        style="purple"
                        btnText="Send"
                        disabled={!isFormValid}
                        loading={sendToCCR.isLoading}
                    />
                    <Button
                        type="button"
                        style="cancel"
                        btnText="Back"
                        onClick={() => setCurrentForm("")}
                    />
                </ModalButtons>
            </form>
        </>
    );
};

export default SendToCCR;
