import React, { useId } from "react";
import ReactDatePicker from "react-datepicker";
import { StyledDateTimePicker } from "./styles";
import { DateTimeInputProps } from "./useDateTimePicker";
import moment from "moment";

interface DatePickerProps extends DateTimeInputProps {
    className?: string;
    label?: string;
    hasReset?: boolean;
    isLayoutVertical?: boolean;
}

const dateDisplay = {
    dateTime: {
        dateFormat: "dd/MM/yyyy   HH:mm",
        placeholderText: "dd/mm/yyyy   --:--",
    },
    time: {
        dateFormat: "HH:mm",
        placeholderText: "--:--",
    },
    date: {
        dateFormat: "dd/MM/yyyy",
        placeholderText: "dd/mm/yyyy",
    },
};

const DateTimePicker: React.FC<DatePickerProps> = ({
    className = "",
    label = "",
    required,
    error,
    selected,
    minDate,
    maxDate,
    minTime,
    maxTime,
    mode,
    dateHandler,
    disabled,
    isLayoutVertical = true,
    hasReset = true,
}) => {
    const id = useId();

    const isMinSameDayToValue = selected
        ? moment(minDate).isSame(selected, "day")
        : true;
    const isMaxSameDayToValue = selected
        ? moment(maxDate).isSame(selected, "day")
        : true;
    const inputMinTime = () => {
        if (mode === "time" && minTime)
            return moment(minTime, "HH:mm").toDate();
        if (isMinSameDayToValue) return moment(minDate).toDate();
        if (isMaxSameDayToValue) return moment(maxDate).startOf("day").toDate();
        return undefined;
    };
    const inputMaxTime = () => {
        if (mode === "time" && maxTime)
            return moment(maxTime, "HH:mm").toDate();
        if (mode === "date") return undefined;
        if (isMinSameDayToValue) return moment(minDate).endOf("day").toDate();
        if (isMaxSameDayToValue) return moment(maxDate).toDate();
        return undefined;
    };

    return (
        <StyledDateTimePicker
            width={dateDisplay[mode].dateFormat.length}
            className={`date-picker${className ? ` ${className}` : ""}`}
            isLayoutVertical={isLayoutVertical}
        >
            {label && (
                <label htmlFor={id}>
                    {label}:{error && <span>{error}</span>}
                </label>
            )}
            <ReactDatePicker
                id={id}
                selected={selected}
                onChange={dateHandler}
                dateFormat={dateDisplay[mode].dateFormat}
                showTimeSelect={mode === "dateTime" || mode === "time"}
                showTimeSelectOnly={mode === "time"}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText={dateDisplay[mode].placeholderText}
                isClearable={!required && hasReset}
                ariaInvalid={error ? "true" : "false"}
                timeIntervals={15}
                minTime={inputMinTime()}
                maxTime={inputMaxTime()}
                disabled={disabled}
            />
        </StyledDateTimePicker>
    );
};

export default DateTimePicker;
