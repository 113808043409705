import React, { useState } from "react";
import { EditLiabilityFormProps } from ".";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import useFetchData from "../../../../global/hooks/useFetchData";
import Input from "../../../Input";
import TextArea from "../../../Input/TextArea";

const AlternativePayment: React.FC<EditLiabilityFormProps> = ({
    onClose,
    data,
    setCurrentForm,
    id,
    onSuccess = () => {},
    setLiabilityDetails,
}) => {
    const altPayment = data?.alternative_paymment || {};
    const [amount, setAmount] = useState<number | null>(
        altPayment?.amount ?? null
    );
    const [reason, setReason] = useState(altPayment?.reason ?? "");

    const updatePayment = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/update-liability/${data.id}`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Alternative payment has been updated",
            onSuccess: (data) => {
                // navigate to main form (LiabilityMain.tsx)
                setCurrentForm("");
                // update table data
                onSuccess();
                // udpate liability details in index.tsx
                if (data) setLiabilityDetails(data);
            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (data?.id) {
            let temp = {
                alternative_payment: {
                    amount,
                    reason,
                },
            };
            updatePayment.fetchData({ data: temp });
        }
    };

    return (
        <>
            <form onSubmit={submitHandler} className="alt-payment">
                <h3>Alternative Payment</h3>
                <Input
                    label="Amount:"
                    type="number"
                    value={`${amount ?? ""}`}
                    onChange={(e) => setAmount(+e.target.value)}
                />
                <TextArea
                    label="Reason:"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                />
                <ModalButtons>
                    <Button
                        type="submit"
                        style="purple"
                        btnText="Save Payment"
                        loading={updatePayment.isLoading}
                    />
                    <Button
                        type="button"
                        style="cancel"
                        btnText="Back"
                        onClick={() => setCurrentForm("")}
                    />
                </ModalButtons>
            </form>
        </>
    );
};

export default AlternativePayment;
