import styled, { css } from "styled-components";
import { theme } from "../../../global/theme";
import {
    centeredRowMixin,
    mainScrollbar,
} from "../../../global/theme/reuseable/mixins";
import { SlideInMenuMixin } from "../DateSearch/styles";

export interface iNotificationMenuWrapper {
    open: boolean;
}
export const NotificationMenuWrapper = styled.div<iNotificationMenuWrapper>`
    ${SlideInMenuMixin};
    padding: 32px;
    width: 480px;
    transition: all 0.3s;
    transform: translateX(${(props) => (props.open ? "0" : "100%")});
    display: flex;
    flex-direction: column;
    z-index: 15;

    & .modal-header {
        &__heading {
            display: flex;
            gap: 12px;
            align-items: center;

            & path {
                fill: ${theme?.colors.purple};
            }
        }
    }

    & .page-tabs {
        padding: 24px 0;

        &__list {
            gap: 12px;
        }
        &__item {
            flex-grow: 1;
            flex-basis: auto;
            flex-shrink: 0;
        }

        &__btn {
            width: 100%;
            height: 40px;
            border-radius: 6px;
        }
    }

    & .no-result {
        text-align: center;
        color: ${theme?.colors.shade};
        font-weight: 300;
        padding: 12px 0;
    }

    & .notification {
        &__content {
            flex: 1;
            overflow: hidden;
            width: calc(100% + 12px);

            & > ul {
                display: flex;
                flex-direction: column;
                list-style: none;
                gap: 8px;
            }
        }

        &__cards {
            & > div {
                scrollbar-gutter: stable;

                ${mainScrollbar(12)}
            }
        }

        &__alert-count {
            display: flex;
            list-style: none;
            align-self: flex-end;
            gap: 8px;
            margin-bottom: 12px;

            & > li {
                color: ${theme?.colors.white};
                background-image: ${theme?.gradients.burntOrange};
                padding: 4px 12px;
                border-radius: 4px;
                font-size: 14px;

                &:last-child {
                    background-image: ${theme?.gradients.mintGreen};
                }
            }
        }

        &__diary {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-right: 12px;
            &__row {
                display: flex;
                align-items: center;
                gap: 4px;
                margin: 12px 0;

                & > span {
                    flex: 1;
                    text-align: center;
                }

                & > button {
                    &:last-of-type {
                        & img {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            & .time-table {
                flex: 1;
                overflow: auto;
                ${mainScrollbar(12)};
                scrollbar-gutter: stable;
            }
        }
    }
`;
