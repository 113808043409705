import styled from "styled-components";
import { buttonMixin, ButtonWrapper } from "../../../components/Button/styles";
import { theme } from "../../../global/theme";
import {
    diaryStatus,
    diaryStatusText,
    mainScrollbar,
} from "../../../global/theme/reuseable/mixins";
import { toolbarHeight, navBarHeight } from "../../../layout/Header/styles";

export const CalendarWrapper = styled.div`
    /* width: 100%; */
    overflow: hidden;
    overflow-x: auto;
    width: 100%;

    ${mainScrollbar()};
    flex: 1 1 0;
    display: flex;
    flex-direction: column;

    .calendar {
        flex: 1 1 0;
        overflow: auto;
        width: inherit;
        ${mainScrollbar()};
    }

    .headers {
        position: sticky;
        top: 0;
        z-index: 2;
    }

    .headers,
    .dates {
        display: grid;
        min-width: 1024px;
        grid-template-columns: repeat(7, 1fr);
    }

    .dates {
        grid-template-rows: minmax(140px, 1fr);
        grid-auto-rows: minmax(140px, 1fr);
        border: 0.3px solid ${theme.colors.shade};
        height: calc(100% - 44px);
    }

    .dates.loading {
        pointer-events: none;
        opacity: 0.6;
    }
`;
export const DayHeader = styled.div`
    background: #e5efff;
    padding: 10px;

    h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #212121;
    }
`;
export interface iSCDiaryDay {
    currentMonth: boolean;
}
export const DayWrapper = styled.div<iSCDiaryDay>`
    color: white;
    padding: 6px 0;
    position: relative;
    background-color: ${theme.colors.white};
    border: 0.3px solid ${theme.colors.shade};

    &[aria-current="false"] {
        background: #f2f2f2;
    }

    &[data-today="true"] {
        background: ${theme.colors.formBlue};
    }

    .date {
        position: absolute;
        z-index: 1;
        top: 4px;
        right: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: ${theme?.colors?.darkText};
        padding: 4px;
        border-radius: 2px;
        background: inherit;
        border: 0.6px solid ${theme.colors.shade};
        /* background-color: ${theme.colors.white}; */
        width: 26px;
        text-align: center;
    }

    .meetings {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 18px;
        grid-auto-rows: 18px;
        align-items: center;
        overflow-y: auto;
        overflow-x: hidden;
        padding-inline: 8px;
        ${mainScrollbar(8)}
        /* gap: 2px; */

        & > p {
            font-size: 12px;
            font-weight: 300;
            color: ${theme?.colors?.darkText};
        }
    }

    :hover {
        border-color: ${theme.colors.primaryBlue};
        background-color: ${theme.colors.highlightBlue};

        cursor: pointer;
    }
`;

export const StyledMeetingCard = styled.div<{ status?: number }>`
    font-size: 10px;
    height: 100%;
    /* padding: 0px 4px; */
    border-radius: 4px;
    font-weight: 300;
    color: ${theme?.colors?.darkText};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    /* border-style: solid;
    border-width: 0.6px;
    ${(props) => diaryStatus(props.status)} */

    & > strong {
        font-weight: 600;
        color: ${(props) => diaryStatusText(props.status)};
        min-width: 36px;
        display: inline-block;
    }
`;

export const FilterMenuWrapper = styled.section`
    background: #e5efff;
    padding: 24px;
    width: 300px;
    overflow-y: hidden;
    height: calc(100vh - ${toolbarHeight} - ${navBarHeight});
    overflow: auto;
    ${mainScrollbar()}
`;

export const DiaryWrapper = styled(FilterMenuWrapper)`
    input {
        width: 100%;
        height: 30px;
    }

    .select > div {
        width: 100%;
    }

    label {
        margin-top: 13px;
    }

    & .page-filter {
        &__btns {
            display: flex;
            width: 100%;
            gap: 4px;
            margin-top: 24px;
            font-size: 12px;

            & > button {
                flex: 1;
            }
        }
        &__status-btns {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 4px;
            margin-top: 24px;

            & > p {
                font-size: 12px;
                font-weight: 300;
            }
        }
    }
`;

export const StyledStatusButton = styled.button<{ status?: number }>`
    border: none;
    border-radius: 4px;
    border: 0.6px solid transparent;
    padding: 6px;
    font-size: 12px;
    opacity: 0.42;
    color: ${(props) => {
        if (props.status === 1) return theme.colors.text;
        return theme.colors.white;
    }};
    ${(props) => diaryStatus(props.status, true)};

    &:first-of-type {
        grid-column: 1/-1;
        background-color: ${theme.colors.purple};
    }

    &[data-selected="true"] {
        filter: brightness(100%);
        opacity: 1;
        border: 0.6px solid ${theme.colors.boxStroke};
    }
`;
export const ViewUserWrapper = styled(FilterMenuWrapper)`
    h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        color: #5e5e5e;
    }

    p {
        font-family: "Poppins";
        font-style: normal;

        text-transform: capitalize;
        color: #5e5e5e;
    }
    > p {
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
    }

    div {
        margin-top: 24px;
        margin-bottom: 47px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 15px;
        label,
        p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 11px;
            line-height: 16px;
            color: #212121;
        }
    }

    button {
        margin-bottom: 14px;
    }
`;

export const SideMenuButton = styled(ButtonWrapper)`
    width: 100%;
    height: 40px !important;
    ${buttonMixin};
    background: ${({ color }) =>
        color === "orange"
            ? `linear-gradient(156.78deg, #FFC24D 15.32%, #FF7A68 85.25%)`
            : color === "red"
            ? `linear-gradient(124.94deg, #FF7A68 -8.84%, #C8311D 107.02%);`
            : color === "green"
            ? `linear-gradient(156.78deg, #8CEEDD 15.32%, #1ACDAD 85.25%);`
            : ""};
    border: 0.4px solid #082a52;
    border-radius: 5px;
`;

export const HoursSection = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    /* overflow-y: scroll; */
`;

export const TableWrapper = styled.table`
    width: 100%;
    border-collapse: collapse;
    * {
        font-family: ${theme?.fontFamily?.primary};
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        white-space: nowrap;
    }
    input[type="checkbox"] {
        accent-color: ${theme?.colors?.purple};
        height: 23.68px;
        width: 23.68px;
    }
    thead {
        background-color: ${theme?.colors?.formBlue};

        tr {
            th {
                padding: 10px 20px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: ${theme?.colors?.darkText};
            }
            th:first-child {
                /* padding-left: 0; */
            }
        }
    }
    tbody {
        tr:nth-child(even) {
            background: #fbfbfb;
        }
        tr:nth-child(odd) {
            background: ${theme?.colors?.white};
        }
        tr td {
            padding: 10px 20px;
            border-left: 0.5px solid ${theme?.colors?.formBlue};
            border-right: 0.5px solid ${theme?.colors?.formBlue};
            max-width: 500px;
            overflow-x: scroll;

            * {
                text-align: left;
            }
            input[type="checkbox"] {
                margin: auto;
            }
            img {
                cursor: pointer;
            }
        }
    }
    td:first-child {
        /* padding-left: 0; */
    }
    tfoot {
    }
`;

export const DayDiaryTable = styled(TableWrapper)`
    tbody tr td {
        vertical-align: top;

        :first-child {
            width: 30%;
            label {
                display: grid;
                grid-template-columns: 1fr 1fr;
                font-family: "Poppins";
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #5e5e5e;

                p {
                    font-weight: 300;
                }
            }
        }
    }
`;
