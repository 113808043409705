import styled from "styled-components";
import { theme } from "../../../global/theme";
import { mainScrollbar } from "../../../global/theme/reuseable/mixins";
import { PageContent } from "../../styles";

export const ReportWrapper = styled(PageContent)`
    display: flex;
    flex-direction: column;
    gap: 24px;

    & > div:nth-child(2) {
        flex-grow: 1;
        flex-basis: 0;
        overflow: hidden;
    }
`;

export const ReportColumn = styled.ul`
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    width: 33%;
    height: 100%;
    position: relative;
    overflow: auto;
    ${mainScrollbar(12)}

    .header {
        background: #e5efff;
        border-radius: 10px 10px 0px 0px;
        padding-block: 10px;
        top: 0;
        position: sticky;

        h4 {
            text-align: center;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: ${theme?.colors?.darkText};
        }
    }
`;
