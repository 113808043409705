import styled from "styled-components";
import { PageContent } from "../../styles";

const StyledSummaryContent = styled(PageContent)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 24px;

    & > div {
        flex: 1;
        min-width: 300px;
        min-height: 230px;
    }
`;

export default StyledSummaryContent;
