import React, { useState } from "react";
import { icons } from "../../assets/icons";
import { CardEditWrapper } from "./styles";
import { iCardEdit } from "./types";

const CardEdit = ({ type, onClick }: iCardEdit) => {
  const [hover, setHover] = useState<boolean>(false);

  const icon = type === "delete" ? icons?.x : icons?.arrowCompass;
  return type === "delete" ? (
    <CardEditWrapper
      type={type}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <img src={hover ? icons?.whiteX : icon} alt="Clear Start" />

      <span></span>
    </CardEditWrapper>
  ) : (
    <CardEditWrapper type={type}>
      <img src={icon} alt="Clear Start" />
      <span></span>
    </CardEditWrapper>
  );
};

export default CardEdit;
