import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import ModalHeader from "../components/ModalHeader";
import useFetchData from "../../../global/hooks/useFetchData";
import { AddCardDetailsModal } from "./styles";

interface AddCardDetailsProps {
    isOpen: boolean;
    setIsOpen: (...args: any[]) => void;
    cardDetails?: { [key: string]: any }[];
    redirectTo?: string;
}

const AddCardDetails = ({
    setIsOpen,
    isOpen,
    redirectTo,
}: AddCardDetailsProps) => {
    const location = useLocation();
    const { clientId } = useParams();
    const closeModal = () => {
        setIsOpen(false);
    };

    const getCheckoutId = useFetchData({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_API}/card-processing/${clientId}`,
    });
    const checkoutId = getCheckoutId.data?.data?.token || "";

    useEffect(() => {
        if (isOpen) getCheckoutId.fetchData();
    }, [isOpen]);

    useEffect(() => {
        if (checkoutId) {
            // Remove any existing script tags
            const existingScript = document.getElementById("paymentScript");
            const existingOptionScript =
                document.getElementById("optionScript");
            if (existingScript) {
                existingScript.remove();
            }
            if (existingOptionScript) {
                existingOptionScript.remove();
            }

            // Append a new script tag
            const optionScript = document.createElement("script");
            optionScript.text = `var wpwlOptions = { iframeStyles: {
                'card-number-placeholder': {
                    'font-size': '12px',
                    'font-family': 'Arial',
                    'text-align': 'left',
                },
                    'cvv-placeholder': {
                        'font-size': '12px',
                        'font-family': 'Arial',
                        'text-align': 'left',
                }
            } }`;
            document.body.appendChild(optionScript);
            const script = document.createElement("script");
            script.id = "paymentScript";
            script.async = true;
            script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
            //script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
            document.body.appendChild(script);
        }
    }, [checkoutId]);

    return (
        <AddCardDetailsModal
            show={isOpen}
            className="add-card-details"
            width="520px"
        >
            <ModalHeader onClose={closeModal}>Card Details</ModalHeader>
            <div className="add-card-details__ctn">
                <form
                    action={`${redirectTo ?? window.location.href}`}
                    className="paymentWidgets"
                    data-brands="VISA MASTER AMEX"
                ></form>
            </div>
        </AddCardDetailsModal>
    );
};

export default AddCardDetails;
