import styled, { keyframes } from "styled-components";

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
  `;

export const ring = keyframes`

  10% { -webkit-transform: rotateZ(-15deg); }
  15% { -webkit-transform: rotateZ(6deg); }


  20% { -webkit-transform: rotateZ(-15deg); }
  25% { -webkit-transform: rotateZ(6deg); }


  50% { -webkit-transform: rotateZ(-15deg); }
  55% { -webkit-transform: rotateZ(6deg); }

  70% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
  `;

export const leaveTop = keyframes`
  100% {
    top: -150%;
  }
`;
export const enterTop = keyframes`
  0% { 
    top: -125%;
  }
  100% {
    top: 0%;
  }
`;

export const leaveRight = keyframes`
  0% { 
    right: 0%;
  }
  100% {
    right: -150%;
  }
`;

export const enterRight = keyframes`
  0% { 
    right: -150%
  }
  100% {
    right: 0%;
  }
`;

export const leaveLeft = keyframes`
  100% {
    left: -150%;
  }
`;

export const enterLeft = keyframes`
  100% {
    left: 0%;
  }
`;

export const leaveBottom = keyframes`
  100% {
    bottom: -150%;
  }
`;

export const enterBottom = keyframes`
  100% {
    bottom: 0%;
  }
`;

export const fadeIn = (opacity: string) => keyframes`
0% { 
  opacity: 0;
}
  100% {
    opacity: ${opacity};
  }
`;

export const slideIn = keyframes`
  100% { transform: translateX(0%); }
`;

// export const slideInLEft = keyframes`
//   100% { transform: translateX(0%); }
// `;
