import { ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Button";
import { StyledCreateReportModal } from "./styles";
import ModalHeader from "../../components/ModalHeader";
import useFetchData from "../../../../global/hooks/useFetchData";
import ModalButtons from "../../components/ModalButtons";
import DropdownSelect from "../../../DropdownSelect";
import CheckboxGroup from "../../../CheckboxGroup";
import Input from "../../../Input";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";

interface ReportColumn {
    has_modifier: 1 | 0;
    column_id: number;
    column_name: string;
}

interface CreateReportModalProps {
    isOpen: boolean;
    modalHandler: (...args: any[]) => void;
    mode?: "create" | "edit";
}

const generateInitReportColumn: () => ReportColumn = () => {
    return {
        has_modifier: 0,
        column_id: 0,
        column_name: "",
    };
};

const CreateReportModal = ({
    isOpen,
    modalHandler,
    mode = "create",
}: CreateReportModalProps) => {
    const navigate = useNavigate();
    const reportTypes = ["Back Office", "Sales", "Management"];
    const { setReport, report } = useReduxSettings();

    const initReportName = report?.name || "";
    const initReportType = report?.type || reportTypes[0];
    const initColumns = report?.columns || [];
    const [name, setName] = useState(initReportName);
    const [reportType, setReportType] = useState(initReportType);
    const [columns, setColumns] =
        useState<Array<{ [key: string]: any }>>(initColumns);
    const closeModal = () => modalHandler("");
    const getReportColumns = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/report-columns`,
        },
        { fetchOnLoad: true }
    );
    const reportColumns = getReportColumns?.data?.data || [];
    const selectedColumnIds = columns.map((col) => col.column_id);
    const isAllOptionChosen = columns.length === reportColumns.length;
    const isIdsValid = selectedColumnIds.every((id) => !!id);
    const isFormValid = !!name && columns.length > 0 && isIdsValid;

    const createReport = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/templates/reports`,
    });

    const updateReport = useFetchData({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_API}/templates/report`,
    });

    const addColumnHandler = () => {
        setColumns((prev) => [...prev, generateInitReportColumn()]);
    };

    const selectColumnHandler = (
        id: number,
        option: { [key: string]: any }
    ) => {
        setColumns((prev) =>
            prev.map((c) =>
                c.column_id === id
                    ? { ...c, column_id: option.id, column_name: option.name }
                    : c
            )
        );
    };

    const deleteHandler = (id: number) => {
        setColumns((prev) => prev.filter((col) => col.column_id !== id));
    };

    const modifierHandler = (value: any, id: number) => {
        setColumns((prev) =>
            prev.map((c) =>
                c.column_id === id ? { ...c, has_modifier: value } : c
            )
        );
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const requestData = {
            name: name,
            type: reportType,
            columns: columns,
        };
        setReport(requestData);
        closeModal();

        if (mode === "create") navigate("create");
    };

    return (
        <StyledCreateReportModal
            show={isOpen}
            className="create-report"
            width="600px"
        >
            <ModalHeader onClose={closeModal}>
                {mode === "create" ? "Create New Report" : "Edit New Report"}
            </ModalHeader>
            <form onSubmit={submitHandler}>
                <Input
                    label="Report name:"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    name="title"
                    type="text"
                    required
                />
                <CheckboxGroup
                    legend="Type of Report"
                    labels={reportTypes}
                    values={reportTypes}
                    selectedValues={[reportType]}
                    onChange={setReportType}
                />
                <ul className="columns">
                    {columns?.length ? (
                        columns.map((col) => (
                            <li className="column" key={col.column_id}>
                                <DropdownSelect
                                    label="Column:"
                                    options={reportColumns || []}
                                    onSelect={(option: {
                                        [key: string]: any;
                                    }) =>
                                        selectColumnHandler(
                                            col.column_id,
                                            option
                                        )
                                    }
                                    value={col?.column_id || ""}
                                    hideValues={selectedColumnIds || []}
                                    optionKey="id"
                                    labelKeys={["name"]}
                                    enableInput
                                    autoScrollOnMenuOpen
                                    isInvalid={
                                        selectedColumnIds.filter(
                                            (id) => col?.column_id === id
                                        )?.length > 1
                                    }
                                />
                                <button
                                    className="column__delete"
                                    onClick={deleteHandler.bind(
                                        null,
                                        col.column_id
                                    )}
                                >
                                    <DeleteIcon />
                                </button>
                                <CheckboxGroup
                                    legend="Modifier?"
                                    labels={["Yes", "No"]}
                                    values={[1, 0]}
                                    selectedValues={[col.has_modifier]}
                                    onChange={(value) =>
                                        modifierHandler(value, col.column_id)
                                    }
                                />
                            </li>
                        ))
                    ) : (
                        <p>No Column Added.</p>
                    )}
                </ul>
                {!isAllOptionChosen && (
                    <button
                        className="add-column"
                        type="button"
                        onClick={addColumnHandler}
                        disabled={!isIdsValid}
                    >
                        + Add Column
                    </button>
                )}

                <ModalButtons>
                    <Button
                        type="submit"
                        btnText={
                            mode === "create" ? "Create report" : "Save Changes"
                        }
                        style="purple"
                        disabled={!isFormValid}
                    />
                </ModalButtons>
            </form>
        </StyledCreateReportModal>
    );
};

export default CreateReportModal;
