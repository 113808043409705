import { btnStyle } from "../../../components/Button/types";
import { SearchRangeT } from "./types";
import moment from "moment";


export interface iDateButton { 

    btnText: SearchRangeT;
    style: btnStyle
}


export const dateFormat = "YYYY-MM-DD";
const now = moment()
const todayDateString = now.format(dateFormat)

export interface DateSearchOption {
    from: string;
    to: string;
    displayText: string;
}

export const allDatesSearchOptions: {[key: string]: DateSearchOption} = {
    all: {
        from: "",
        to: "",
        displayText: "All Dates",
    },
    today: {
        from: moment().format(dateFormat),
        to: moment().format(dateFormat),
        displayText: "Today",
    },
    yesterday: {
        from: moment().subtract(1, 'day').format(dateFormat),
        to: moment().subtract(1, 'day').format(dateFormat),
        displayText: "Yesterday",
    },
    tomorrow: {
        from: moment().add(1, 'day').format(dateFormat),
        to: moment().add(1, 'day').format(dateFormat),
        displayText: "Tomorrow",
    },
    last7days:{
        from: moment().subtract(7, "day").format(dateFormat),
        to: moment().format(dateFormat),
        displayText: "Last 7 Days",
    },
    next7days:{
        from: moment().format(dateFormat),
        to: moment().add(7, "day").format(dateFormat),
        displayText: "Next 7 Days",
    },
    thisMonth: {
        from: moment().startOf("month").format(dateFormat),
        to: moment().endOf("month").format(dateFormat),
        displayText: "Current Month",
    },
    lastMonth: {
        from: moment().subtract(1, "month").startOf("month").format(dateFormat),
        to: moment().subtract(1, "month").endOf("month").format(dateFormat),
        displayText: "Last Month",
    },
    nextMonth: {
        from: moment().startOf("month").add(1, "month").format(dateFormat),
        to: moment().add(1, "month").endOf("month").format(dateFormat),
        displayText: "Next Month",
    },
    thisYear: {
        from: moment().startOf("year").format(dateFormat),
        to: moment().endOf("year").format(dateFormat),
        displayText: "Current Year",
    },
    lastYear: {
        from: moment().subtract(1, "year").startOf("year").format(dateFormat),
        to: moment().subtract(1, "year").endOf("year").format(dateFormat),
        displayText: "Last Year",
    },
    nextYear: {
        from: moment().startOf("year").add(1, "year").format(dateFormat),
        to: moment().add(1, "year").endOf("year").format(dateFormat),
        displayText: "Next Year",
    },
}

const {all, today, yesterday, tomorrow, last7days, next7days,thisMonth, lastMonth, nextMonth, thisYear, lastYear, nextYear}  = allDatesSearchOptions

export const dateSearchOptions1= [

]

export const generateDateSearchOptions = (keyArr: Array<keyof typeof allDatesSearchOptions>) => {
    const options = keyArr.map(key => allDatesSearchOptions[key])
    return options
}

export const defaultDateSearchOptions = [today, last7days, thisMonth, lastMonth, nextMonth, all]
export const tableDateSearchOptions = [today, yesterday, tomorrow, last7days, thisMonth, lastMonth, thisYear]
export const dashboardDateSearchOptions = [today, last7days, thisMonth, nextMonth]

export const dateSearchOptions = [

    {
        from: "",
        to: "",
        displayText: "All Dates",
    },
    {
        from: moment().format(dateFormat),
        to: moment().format(dateFormat),
        displayText: "Today",
    },
    {
        from: moment().subtract(7, "day").format(dateFormat),
        to: moment().format(dateFormat),
        displayText: "Last 7 Days",
    },
    {
        from: moment().startOf("month").format(dateFormat),
        to: moment().endOf("month").format(dateFormat),
        displayText: "Current Month",
    },
    {
        from: moment().startOf("month").add(1, "month").format(dateFormat),
        to: moment().add(1, "month").endOf("month").format(dateFormat),
        displayText: "Next Month",
    },
];

