import { AgreementDetailsContainerProps } from ".";
import DropdownSelect from "../../../../components/DropdownSelect";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import moment from "moment";
import useFetchData from "../../../../global/hooks/useFetchData";
import {
    cardCapturedOptions,
    paymentDayOptions,
    paymentFrequencyOptions,
    paymentMethodOptions,
} from "../../../../components/DropdownSelect/options";
import { formatDateString } from "../../../../global/utils/helpers";

const PaymentDetails: React.FC<AgreementDetailsContainerProps> = ({
    formData,
    onSelect,
    onInputChange,
    handlerList,
}) => {

        const dateFormat = "YYYY-MM-DD";
    console.log(formData?.standing_order_sent, formatDateString(formData?.standing_order_sent || ""))

    return (
        <HeaderContainer
            title="Payment Details"
            headerStyle="gradientBlue"
            className="payment-details"
        >
            <Input
                type="date"
                label="Standing Order Sent:"
                value={formatDateString(formData?.standing_order_sent || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="standing_order_sent"
            />
            <DropdownSelect
                label="Payment Frequency:"
                options={paymentFrequencyOptions}
                value={formData?.payment_frequency ?? null}
                onSelect={(option) =>
                    onSelect("payment_frequency", option.value)
                }
            />
            <DropdownSelect
                label="Payment Day:"
                options={paymentDayOptions}
                value={formData?.payment_day ?? null}
                onSelect={(option) =>
                    onSelect("payment_payment_day", option.value)
                }
                enableInput
            />
            <DropdownSelect
                label="Payment Method:"
                options={paymentMethodOptions}
                value={formData?.payment_method ?? null}
                onSelect={(option) => onSelect("payment_method", option.value)}
            />
            <DropdownSelect
                label="Card Captured:"
                options={cardCapturedOptions}
                value={formData?.card_captured ?? null}
                onSelect={(option) => onSelect("card_captured", option.value)}
            />
        </HeaderContainer>
    );
};

export default PaymentDetails;
