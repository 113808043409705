import styled from "styled-components";
import { PageContent, pageInlinePadding } from "../../styles";
import Header from "../../../layout/Header";
import SearchRow from "../../../components/SearchRow";

export const AdminstrationLandingContent = styled(PageContent)`
    & > .cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 24px;
    }
`;

export const MailShotWrapper = styled.section`
    .searchInput {
        margin-bottom: 16px;
    }
`;

export const UploadWrapper = styled.section`
    .nav {
        padding-block: 19px;
        gap: 39px;
        .searchInput,
        button {
            height: 40px;
        }
        .searchInput {
            width: 303px;
        }
        button {
            width: 153px;
            background: linear-gradient(
                156.78deg,
                #8ceedd 15.32%,
                #1acdad 85.25%
            );
        }
    }
`;

export const CreditWrapper = styled.section`
    display: flex;
    flex-direction: column;
    padding-left: ${pageInlinePadding};
    div:first-of-type {
        margin-bottom: 25px;
        button {
            height: 50px;
            :first-child {
                width: 197px;
            }
            :last-child {
                width: 193px;
            }
        }
    }
    .searchInput {
        margin-bottom: 20px;
    }
`;

export const UploadEye = styled.span`
    width: 28px;
    height: 28px;
    background: white;
    display: grid;
    place-items: center;

    :hover {
        background: #fbfbfb;
        cursor: pointer;
    }
`;

export const SearchHeader = styled(Header)`
    & .header {
        &__content {
            align-items: flex-end;

            & > button {
                height: 30px;
            }
        }
    }
`;

export const MailShotSearchRow = styled(SearchRow)`
    & .dropdown-select {
        position: relative;
        &__label {
            position: absolute;
            top: -22px;
            left: 0;
        }
        &__field {
            height: 40px;
            width: 240px;
        }
    }
`;
