import styled from "styled-components";
import { theme } from "../../global/theme";
import { mainScrollbar } from "../../global/theme/reuseable/mixins";

const StyledCardDetails = styled.div`
    display: flex;
    flex-direction: column;
    & .card-details {
        &__cards {
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: initial;
            gap: 4px;
            max-height: calc(100vh - 490px);
            overflow: auto;
            ${mainScrollbar(12)};
            margin-bottom: 12px;
        }

        &__card {
            background-color: ${theme?.colors.white};
            border: 0.6px solid ${theme?.colors.shade};
            border-radius: 4px;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: 1fr 1fr;
            gap: 2px 12px;
            padding: 6px 12px;

            & > p {
                flex: 1;
                text-align: left;
                font-size: 11px;
                align-self: flex-start;

                & > span {
                    font-weight: 300;
                }
            }
        }

        &__empty {
            font-weight: 300;
            text-align: center;
            padding-bottom: 12px;
            color: ${theme?.colors.shade};
        }

        & .wpwl-container {
        }

        & .wpwl-form.wpwl-form-card.wpwl-clearfix {
            margin: 0;
            padding: 0;
            background: transparent;
            box-shadow: none;
            width: inherit;
            margin-top: 12px;
            border: none;
            max-width: none;

            & input {
                &:placeholder {
                    font-size: 12px !important;
                }
            }
        }

        & .wpwl-label {
            font-size: 12px;
        }
        & .wpwl-button {
            background-color: ${theme.colors.purple};
            border: none;
        }

        & input {
            height: 35px;
            padding-inline: 6px;
            &::placeholder {
                font-size: 16px;
                font-family: "Arial";
            }
        }
    }
`;

export default StyledCardDetails;
