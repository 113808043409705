import { SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { CreateCreditorInfoModal } from "./styles";
import ModalHeader from "../components/ModalHeader";
import Button from "../../Button";
import DropdownSelect from "../../DropdownSelect";
import {
    accountHolderOptions,
    creditTypeOptions,
} from "../../DropdownSelect/options";
import Input from "../../Input";
import ModalButtons from "../components/ModalButtons";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxCreateClient from "../../../store/hooks/useReduxCreateClient";

interface CreateCreditorInfoProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
    mode?: "create" | "edit";
    data?: { [key: string]: any };
}

interface CreditorInfoData {
    cedtr_id: number | null;
    accntRfrnce: string;
    estmtdBlnce: number;
    rspnsblty: number;
    crdtTpe: number;
}

const CreateCreditorInfo: React.FC<CreateCreditorInfoProps> = ({
    isOpen,
    setIsOpen,
    mode = "create",
    data,
}) => {
    const { createDebt, updateDebt, deleteDebt } = useReduxCreateClient();
    const { clientId } = useParams();
    const [formData, setFormData] = useState<CreditorInfoData>({
        cedtr_id: data?.cedtr_id || null,
        accntRfrnce: data?.accntRfrnce || "",
        estmtdBlnce: data?.estmtdBlnce ?? 0,
        rspnsblty: data?.rspnsblty || 1,
        crdtTpe: data?.crdtTpe || 0,
    });
    const isFormValid = !!formData.cedtr_id && !!formData.accntRfrnce;

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = e.target;
        setFormData((prev) => {
            return {
                ...prev,
                [name]:
                    type === "text"
                        ? value.replace(/[^0-9]/, "").trim()
                        : +value,
            };
        });
    };

    const handleSelect = (key: string, value: number) => {
        setFormData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const creditorList = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/get-creditors`,
        },
        {
            fetchOnLoad: true,
        }
    );
    const creditorListData = creditorList?.data?.data || [];

    const postRecord = useFetchData(
        {
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_API}/creditor-info`,
        },
        {
            onSuccess: (newData) => {
                const dataObj = newData?.data?.[0];
                if (dataObj) {
                    if (mode === "create") {
                        createDebt(dataObj);
                    }
                    if (mode === "edit") {
                        updateDebt(dataObj, dataObj?.id);
                    }
                    setIsOpen(false);
                }
            },
            notifyOnSuccess: true,
            notifySuccessMessage: `Record ${mode} success.`,
        }
    );

    const deleteRecord = useFetchData(
        {
            method: "DELETE",
            url: `${process.env.REACT_APP_SERVER_API}/creditor-info/${data?.id}`,
        },
        {
            onSuccess: () => {
                if (data?.id) {
                    deleteDebt(data.id);
                }
                setIsOpen(false);
            },
            notifyOnSuccess: true,
            notifySuccessMessage: `Record Deleted.`,
        }
    );

    const deleteHandler = () => {
        deleteRecord.fetchData();
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const requestData = {
            tab: "creditor_info",
            id: clientId,
            data: {
                id: data?.id || null,
                case_id: clientId,
                ...formData,
            },
        };
        postRecord.fetchData(requestData);
    };

    return (
        <CreateCreditorInfoModal show={isOpen} className="create-ci-modal">
            <ModalHeader setIsOpen={setIsOpen}>
                {mode === "create" ? "Create New Debt" : "Edit Debt"}
                {mode === "edit" && (
                    <Button
                        type="button"
                        onClick={deleteHandler}
                        style="red"
                        btnText="Delete"
                    />
                )}
            </ModalHeader>
            <form onSubmit={submitHandler} className="create-ci-modal__form">
                <div className="input-fields">
                    <DropdownSelect
                        className="input-fields__type"
                        label="Creditor Name:"
                        value={formData.cedtr_id}
                        options={creditorListData}
                        onSelect={(data) => handleSelect("cedtr_id", data.id)}
                        optionKey="id"
                        labelKeys={["name"]}
                    />

                    <Input
                        className={`create-ci-modal__ref`}
                        type="text"
                        label="Account Reference:"
                        placeholder={`Enter Reference here...`}
                        name="accntRfrnce"
                        value={formData.accntRfrnce}
                        onChange={handleInput}
                        autoComplete="off"
                    ></Input>
                    <Input
                        className={`create-ci-modal__amount`}
                        type="number"
                        label="Debt Amount:"
                        placeholder={`Enter amount here...`}
                        name="estmtdBlnce"
                        value={formData.estmtdBlnce.toString()}
                        onChange={handleInput}
                        autoComplete="off"
                        min={0}
                    ></Input>
                    <DropdownSelect
                        className="create-ci-modal__holder"
                        label="Account Holder:"
                        value={formData.rspnsblty}
                        options={accountHolderOptions}
                        onSelect={(data) =>
                            handleSelect("rspnsblty", data.value)
                        }
                    />
                    <DropdownSelect
                        className="create-ci-modal__credit-type"
                        label="Credit Type:"
                        value={formData.crdtTpe}
                        options={creditTypeOptions}
                        onSelect={(data) => handleSelect("crdtTpe", data.value)}
                    />
                </div>
                <ModalButtons>
                    <Button
                        style="purple"
                        btnText={mode === "create" ? "Create" : "Save Changes"}
                        type="submit"
                        loading={postRecord.isLoading}
                        disabled={!isFormValid}
                    />
                </ModalButtons>
            </form>
        </CreateCreditorInfoModal>
    );
};

export default CreateCreditorInfo;
