import styled from "styled-components";
import { PageContent } from "../../../../pages/styles";
import React, { Component } from "react";
import { mainScrollbar } from "../../../../global/theme/reuseable/mixins";
import { theme } from "../../../../global/theme";

const SendMessagePageContent = styled.div`
    flex: 1 1 0;
    display: flex;
    gap: 32px;
    width: min(100%, 1440px);
    margin-inline: auto;
    overflow-x: auto;
    ${mainScrollbar(16)}

    & .message-template {
        &__col {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 16px;

            & .search-row {
                justify-content: space-between;
            }

            &:first-child {
                flex-grow: 1;
                min-width: 600px;
            }

            &:last-child {
                min-width: 500px;
                max-width: 500px;
            }
        }

        &__subject {
            font-size: 12px;

            & > span {
                margin-left: 8px;
                font-weight: 300;
            }
        }

        &__preview {
            padding: 8px;
            border-radius: 8px;
            border: 0.6px solid ${theme.colors.boxStroke};
            font-size: 75%;
        }

        &__list {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 4px;
            flex: 1 1 0;
            overflow: auto;
            ${mainScrollbar(12)}
        }

        &__item {
        }
    }
`;

export default SendMessagePageContent;
