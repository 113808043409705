import React from "react";
import { SelectButtonWrapper } from "./styles";
import { iSelectButton } from "./types";

const SelectButton = ({
    btnText,
    onClick,
    selected,
    notifications,
    type,
    className = "",
}: iSelectButton) => {
    return (
        <SelectButtonWrapper
            className={`select-btn ${className ? ` ${className}` : ""}`}
            selected={selected}
            onClick={onClick}
            data-notifications={notifications}
            type={type}
        >
            <p>{btnText}</p>
        </SelectButtonWrapper>
    );
};

export default SelectButton;
