import React, { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";

import FormStepContainerHeader from "../../../../../atoms/FormStepContainerHeader";
import { Input, TextArea } from "../../../../../components";
import { FormContainerWrapper } from "../../../../../containers/User/Client/styles";
import { ClientFormStepProps } from "../ClientForm";
import useReduxCreateClient from "../../../../../store/hooks/useReduxCreateClient";
import { StyledPersonalAccountancy } from "./styles";
import ClientFormStepButtons from "../ClientFormStepButtons";
import useFetchData from "../../../../../global/hooks/useFetchData";

const PersonalAccountancy: React.FC<ClientFormStepProps> = ({
    setCurrentStep,
    isFirstStep,
    isLastStep,
    currentStep,
}) => {
    const { personal_accountancy, setDataByKey } = useReduxCreateClient();
    const { clientId } = useParams();
    const [formData, setFormData] = useState({
        trdngnme: personal_accountancy?.["trdngnme"] || "",
        NI: personal_accountancy?.["NI"] || "",
        UTR: personal_accountancy?.["UTR"] || "",
        TrdAddrss: personal_accountancy?.["TrdAddrss"] || "",
        GG_ID: personal_accountancy?.["GG_ID"] || "",
        start_self: personal_accountancy?.["start_self"] || "",
        FilingDte: personal_accountancy?.["FilingDte"] || "",
        GG_Pass: personal_accountancy?.["GG_Pass"] || "",
        Sge_ref: personal_accountancy?.["Sge_ref"] || "",
    });

    const { fetchData, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/personal-accountancy`,
        },
        {
            onSuccess: (data) => {
                setCurrentStep((prev) => prev + 1);
                setDataByKey("personal_accountancy", data?.data);
            },
            notifyOnSuccess: true,
        }
    );

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (clientId) {
            const requestData = {
                id: +clientId,
                tab: "personal-accountancy",
                data: {
                    id: +clientId || null,
                    ...formData,
                },
            };
            console.log(requestData);
            fetchData(requestData);
        }
    };

    return (
        <StyledPersonalAccountancy
            onSubmit={submitHandler}
            className="personal-accountancy"
        >
            <FormContainerWrapper single className="client-form__content">
                <FormStepContainerHeader title="Personal Accountancy" />
                <div className="content">
                    <Input
                        className="input--trading-name"
                        label="Trading Name (if applicable):"
                        type="text"
                        name="trdngnme"
                        value={formData.trdngnme}
                        onChange={handleChange}
                    />
                    <Input
                        className="input--ni-number"
                        label="National Insurance Number:"
                        type="text"
                        name="NI"
                        value={formData["NI"]}
                        onChange={handleChange}
                    />
                    <Input
                        className="input--utr-number"
                        label="UTR Number:"
                        type="text"
                        name="UTR"
                        value={formData["UTR"]}
                        onChange={handleChange}
                    />
                    <TextArea
                        className="input--address"
                        name="TrdAddrss"
                        value={formData["TrdAddrss"]}
                        label="Trading Address (if applicable)"
                        onChange={handleChange}
                    />
                    <Input
                        className="input--start"
                        label="Start of Self Employment"
                        type="date"
                        name="start_self"
                        value={formData["start_self"]}
                        onChange={handleChange}
                    />
                    <Input
                        className="input--filing"
                        label="Filing Date"
                        type="date"
                        name="FilingDte"
                        value={formData["FilingDte"]}
                        onChange={handleChange}
                    />
                    <Input
                        className="input--gateway-username"
                        label="Government gateway Username"
                        type="text"
                        name="GG_ID"
                        value={formData["GG_ID"]}
                        onChange={handleChange}
                    />
                    <Input
                        className="input--gateway-password"
                        label="Government Gateway Password"
                        type="password"
                        autoComplete="off"
                        name="GG_Pass"
                        value={formData["GG_Pass"]}
                        onChange={handleChange}
                        hasShowPassword
                    />
                    <Input
                        className="input--sage-ref"
                        label="Sage Reference Number"
                        type="number"
                        name="Sge_ref"
                        value={formData["Sge_ref"]}
                        onChange={handleChange}
                    />
                </div>
            </FormContainerWrapper>
            <ClientFormStepButtons
                onPrevClick={() => setCurrentStep((prev) => prev - 1)}
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                isSubmitting={isLoading}
            />
        </StyledPersonalAccountancy>
    );
};

export default PersonalAccountancy;
