import { formatTimeString } from "../../../global/utils/helpers";
import HeaderContainer from "../../HeaderContainter";
import { StyledUserDoc } from "./styles";

interface UserDocProps {
    data: { [key: string]: any };
    elementRef: React.RefObject<HTMLDivElement>;
}

const UserDoc: React.FC<UserDocProps> = ({ data, elementRef }) => {
    return (
        <StyledUserDoc ref={elementRef} className="user-doc">
            <h1>ClearStart Accountant - Admin Details</h1>
            <hr />
            <HeaderContainer title="Basic Information" headerStyle="lightBlue">
                <ul className="data-list">
                    <li>
                        ID: <span>{data?.id || "-"}</span>
                    </li>
                    <li>
                        Name: <span>{data?.name || "-"}</span>
                    </li>
                    <li>
                        Job Role: <span>{data?.jb_title || "-"}</span>
                    </li>
                    <li>
                        DDI: <span>{data?.ddi || "-"}</span>
                    </li>
                    <li>
                        Phone Line(EXTN): <span>{data?.phone_line || "-"}</span>
                    </li>
                    <li>
                        Email Address: <span>{data?.email || "-"}</span>
                    </li>
                </ul>
            </HeaderContainer>
            <HeaderContainer title="User Settings" headerStyle="lightBlue">
                <ul className="data-list">
                    <li>
                        Department: <span>{data?.dprt_name || "-"}</span>
                    </li>
                    <li>
                        User Type: <span>{data?.user_type || "-"}</span>
                    </li>
                    <li>
                        Access Group: <span>{data?.access_group || "-"}</span>
                    </li>
                    <li>
                        Disabled:{" "}
                        <span>
                            {data?.disabled === 1
                                ? "Yes"
                                : data?.disabled === 0
                                ? "No"
                                : "-"}
                        </span>
                    </li>
                </ul>
            </HeaderContainer>
            <HeaderContainer title="Other Information" headerStyle="lightBlue">
                <ul className="data-list">
                    <li>
                        IP Address: <span>{data?.ipaddress || "-"}</span>
                    </li>
                    <li>
                        Created At:{" "}
                        <span>{formatTimeString(data?.created_at || "")}</span>
                    </li>
                </ul>
            </HeaderContainer>
        </StyledUserDoc>
    );
};

export default UserDoc;
