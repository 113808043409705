import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from ".";
import { ClientCase, initialUserState, userActions } from "../slices/userSlice";

const useReduxUser = () => {
    const { isSignedIn, user, clientCase, device } = useAppSelector(
        (state) => state.user
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const setIsSignedIn = (data: boolean) => {
        dispatch(userActions.setIsSignedIn(data));
    };

    const setUser = (data: { [key: string]: any }) => {
        dispatch(userActions.setUser(data));
    };

    const setClientCase = (data: ClientCase) => {
        dispatch(userActions.setClientCase(data));
    };

    const setClientCaseByKey = (
        key: keyof ClientCase,
        data: { [key: string]: any } | null
    ) => {
        dispatch(userActions.setClientCaseByKey({ key, data }));
    };

    const setDeviceByKey = (
        key: keyof typeof initialUserState.device,
        value: string
    ) => {
        dispatch(userActions.setDeviceByKey({ key, value }));
    };

    const logoutUser = () => {
        localStorage.removeItem("ClearStartAccessToken");
        dispatch(userActions.userLogout());
        setIsSignedIn(false);
        navigate("/login");
    };

    return {
        isSignedIn,
        user,
        setIsSignedIn,
        setUser,
        logoutUser,
        clientCase,
        setClientCase,
        setClientCaseByKey,
        device,
        setDeviceByKey,
    };
};

export default useReduxUser;
