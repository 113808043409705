import styled from "styled-components";
import { PageContent } from "../../styles";

const StyledClientPortalContent = styled(PageContent)`
    & .client-portal {
        &__controls {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 12px;
            justify-content: flex-start;

            & > button {
                flex: 1;
                font-size: 14px;
                padding: 12px 24px;
                height: auto;
            }
        }

        &__tables {
            flex: 1 1 0;
            display: flex;
            gap: 24px;
            margin-top: 24px;

            @media screen and (max-width: 1024px) {
                flex-direction: column;
                flex-grow: initial;
            }
        }

        &__table {
            flex: 1 1 0;
            display: flex;
            flex-direction: column;
        }

        &__row {
            display: flex;
            gap: 8px;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            & > h2 {
                font-size: 16px;
            }

            & > button {
                padding-inline: 16px;
            }
        }
    }

    & .data-table {
        @media screen and (max-width: 1024px) {
            flex: initial;
        }
        &__wrapper {
            overflow-x: initial;
            @media screen and (max-width: 1024px) {
                max-height: 400px;
            }
        }

        &-pagination {
            position: initial;
            margin-bottom: 16px;
        }
    }
`;

export default StyledClientPortalContent;
