import { StepProps } from ".";
import DropdownSelect from "../../../DropdownSelect";
import { userTypeOptions } from "../../../DropdownSelect/options";
import ButtonInput from "../../../Input/ButtonInput";
import { StepWrapper } from "./styles";
import { generateTestOptions } from "../../../DropdownSelect/options";

const StepTwo: React.FC<StepProps> = ({
    handleSelect,
    handleChange,
    select,
    adminDetails,
    setAdminDetails,
}) => {
    const ipAddresshandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setAdminDetails((prev) => {
            return { ...prev, [name]: value.replace(/[^0-9\\.]/g, "") };
        });
    };

    return (
        <StepWrapper>
            <DropdownSelect
                label="User Type:"
                labelKeys={["name"]}
                optionKey="id"
                options={select?.types}
                value={adminDetails?.type}
                onSelect={(option) => {
                    setAdminDetails((prev) => {
                        return {
                            ...prev,
                            user_type: option.name,
                            type: option.id,
                        };
                    });
                }}
            />
            <DropdownSelect
                label="Access Group:"
                labelKeys={["name"]}
                optionKey="id"
                options={select?.roles_group}
                value={adminDetails?.role_id}
                onSelect={(option) => {
                    setAdminDetails((prev) => {
                        return {
                            ...prev,
                            access_group: option.name,
                            role_id: option.id,
                        };
                    });
                }}
            />
            <ButtonInput
                btnText="148.252.213.66"
                label="IP Address:"
                type="text"
                name="ipaddress"
                value={adminDetails.ipaddress}
                onClick={() => false}
                onChange={ipAddresshandler}
            />
        </StepWrapper>
    );
};

export default StepTwo;
