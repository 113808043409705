interface SumRowProps {
    data: Array<{ [key: string]: any }>;
    pageData: Array<{ [key: string]: any }>;
    keys: Array<string>;
    sumKeys: Array<string>;
    hasCheckbox: boolean;
    hasAction: boolean;
}

const SumRow: React.FC<SumRowProps> = ({
    data,
    pageData,
    keys,
    sumKeys,
    hasCheckbox,
    hasAction,
}) => {
    const calculateSum = (
        k: string,
        data: Array<{ [key: string]: any }>
    ): string => {
        const valueArr = data.map((d: { [key: string]: any }) => {
            if (typeof d?.[k] === "number") return d?.[k];
            return 0;
        });
        const sum = valueArr.reduce((a, c) => a + c, 0);
        return `${sum}`;
    };

    return (
        <tr className="sum-row">
            {hasCheckbox && <td className="td--empty"></td>}
            {keys.map((k, i) => (
                <td key={i} data-empty={!sumKeys.includes(k)}>
                    {sumKeys.includes(k) ? calculateSum(k, data) : ""}
                </td>
            ))}
            {hasAction && <td className="td--empty"></td>}
        </tr>
    );
};

export default SumRow;
