import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
    CreateClientCard,
    CreateReportModal,
    ModalContainer,
} from "../../../../components";

import Header from "../../../../layout/Header";
import { Row } from "../../../../global/theme/reuseable/components";
import NewReport from "./NewReport";
import { iReports } from "./types";
import { TablePageContent } from "../../../../pages/styles";

const Reports = ({ setHeadingName }: iReports) => {
    const [reportModal, setReportModal] = useState("");
    const { pathname } = useLocation();
    const prevPath =
        pathname.split("/").length >= 5
            ? "/settings/templates/reports"
            : "/settings";

    return (
        <>
            <Header
                title="report template"
                prevPath={prevPath}
                isSubPath
                hasNavBarAbove
            />
            <TablePageContent>
                <Routes>
                    <Route
                        index
                        element={
                            <Row justify-content="center">
                                <CreateClientCard
                                    title="Create New Report"
                                    onClick={() => setReportModal("Create")}
                                />
                            </Row>
                        }
                    />
                    <Route
                        path="create"
                        element={
                            <NewReport
                                setReportModal={setReportModal}
                                reportModal={reportModal}
                            />
                        }
                    />
                </Routes>
                <ModalContainer
                    isVisible={reportModal === "Create"}
                    children={
                        <CreateReportModal
                            modalHandler={setReportModal}
                            isOpen={reportModal === "Create"}
                        />
                    }
                />
            </TablePageContent>
        </>
    );
};

export default Reports;
