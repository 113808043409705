import styled from "styled-components";
import { TablePageContent } from "../../styles";

const SearchPageContent = styled(TablePageContent)`
    & .data-table-pagination {
        position: initial;
        margin-bottom: 12px;
    }
`;

export default SearchPageContent;
