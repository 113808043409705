import React from "react";
import { NavLink } from "react-router-dom";
import { iSubNav } from "../SideNavs/types";
import useReduxUI from "../../store/hooks/useReduxUI";
import { theme } from "../../global/theme";
import { NavBarCtn } from "./styles";

export type NavBarProps = {
    subList?: iSubNav[];
    subListName?: string;
    isSubPath?: boolean;
};

const NavBar: React.FC<NavBarProps> = ({
    subList,
    subListName = "",
    isSubPath = true,
}) => {
    const { getSubNavList } = useReduxUI();
    const subLinks = subList || getSubNavList(subListName) || [];

    return (
        <>
            {subLinks.length ? (
                <NavBarCtn className="nav-bar">
                    {subLinks.map((subLink: iSubNav) => (
                        <NavLink
                            className="nav-bar__link"
                            key={subLink?.name}
                            to={
                                isSubPath
                                    ? subLink?.path.replace(/^\//, "")
                                    : subLink?.path
                            }
                            style={({ isActive }) => {
                                return {
                                    color: isActive ? theme?.colors.purple : "",
                                };
                            }}
                        >
                            {subLink?.name}
                        </NavLink>
                    ))}
                </NavBarCtn>
            ) : null}
        </>
    );
};

export default NavBar;
