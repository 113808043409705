import React, { SetStateAction } from "react";
import { icons } from "../../../assets/icons";
import Label from "../../../components/Label";
import { DateMenuHeaderWrapper } from "./styles";
import useReduxUI from "../../../store/hooks/useReduxUI";

interface DateMenuHeaderProps {
    isCustomMode: boolean;
    setIsCustomMode: React.Dispatch<SetStateAction<boolean>>;
    displayText?: string;
}

const DateMenuHeader: React.FC<DateMenuHeaderProps> = ({
    isCustomMode,
    setIsCustomMode,
    displayText = "",
}) => {
    const { setSideMenu } = useReduxUI();

    const renderedHead = isCustomMode ? (
        <DateMenuHeaderWrapper>
            <h1>
                <button
                    type="button"
                    onClick={setIsCustomMode.bind(null, false)}
                    className="header-btn header-btn--back"
                >
                    <img src={icons?.purpleLeftArrow} alt="" />
                </button>
                <img src={icons?.calendar} alt="" />
                Date search
                <Label label="Custome Range" />
            </h1>
            <button
                type="button"
                onClick={() => setSideMenu("")}
                className="header-btn"
            >
                <img src={icons?.x} alt="Close Menu" />
            </button>
        </DateMenuHeaderWrapper>
    ) : (
        <DateMenuHeaderWrapper>
            <h1>
                <img src={icons?.calendar} alt="" />
                Date search
            </h1>
            <img
                src={icons?.x}
                alt="Close Menu"
                onClick={() => setSideMenu("")}
            />
        </DateMenuHeaderWrapper>
    );
    return renderedHead;
};

export default DateMenuHeader;
