import React, { useRef, useState, useId } from "react";
import StyledFileUpload from "./styles";
import { LoadingSpinner } from "../Button/styles";
import { Notify } from "notiflix";

export interface FileData {
    file: File | null;
    url: string;
    name: string;
}

export interface FileUploadProps {
    className?: string;
    accept?: string;
    maxFileSize?: number;
    disabled?: boolean;
    onUploaded: (
        file: FileData,
        e: React.ChangeEvent<HTMLInputElement>
    ) => void;
    children: React.ReactNode;
    name?: string;
    isLoading?: boolean;
    showRequirement?: boolean;
    labelStyle?: React.CSSProperties;
    isLabelPreview?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
    className = "",
    accept = "*",
    maxFileSize = 5,
    disabled = false,
    onUploaded = () => {},
    children,
    isLoading = false,
    name,
    showRequirement = true,
    labelStyle,
    isLabelPreview = false,
}) => {
    const id = useId();
    const [isFileSizeValid, setIsFileSizeValid] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);

    function formatFileTypes(str: string) {
        if (str === "*") return "";
        return str
            .toUpperCase()
            .split(/\W/g)
            .filter((str) => !!str)
            .join(", ")
            .replace(/,\s(?=\w+$)/g, " or ");
    }

    const fileSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const fileData = target.files ? target.files[0] : null;
        const fileDataSize = fileData?.size || 0;
        const isFileDataSizeValid = fileDataSize <= maxFileSize * 1000 ** 2;
        if (!isFileDataSizeValid) {
            return Notify.failure(`max. file size exceed(${maxFileSize}MB)`);
        }
        if (fileData) {
            const fileDataURL = URL.createObjectURL(fileData);
            setIsFileSizeValid(true);
            onUploaded(
                {
                    file: fileData,
                    url: fileDataURL,
                    name: fileData.name,
                },
                e
            );
        }
    };

    const labelClickHandler = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    };

    return (
        <StyledFileUpload
            className={`file-upload${className ? ` ${className}` : ""}`}
            role={isLabelPreview ? "preview-upload" : undefined}
        >
            <button type="button" className="file-upload__upload">
                <input
                    type="file"
                    className="file-upload__upload__input"
                    id={id}
                    name={name || "file-upload"}
                    onChange={fileSelectHandler}
                    accept={accept}
                    ref={inputRef}
                ></input>
                <label
                    className="file-upload__upload__label"
                    htmlFor={id}
                    onClick={labelClickHandler}
                    aria-disabled={disabled}
                    style={labelStyle}
                >
                    {isLoading ? <LoadingSpinner /> : children}
                </label>
            </button>
            {!!formatFileTypes(accept) && showRequirement ? (
                <span className="file-upload__types">
                    {`${formatFileTypes(accept)}(max: ${maxFileSize}MB)`}
                </span>
            ) : null}
        </StyledFileUpload>
    );
};

export default FileUpload;
