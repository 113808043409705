import { useState, useEffect } from "react";
import { EditLiabilityFormProps } from ".";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import StepTabs from "../../../StepTabs";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import LiabilityDetails from "./LiabilityDetails";
import LiabilityKeyEvents from "./LiabilityKeyEvents";
import LiabilityContact from "./LiabilityContact";
import LiabilityMisc from "./LiabilityMisc";
import LiabilityHistory from "./LiabilityHistory";
import useReduxClientBalanceSheet from "../../../../store/hooks/useReduxClientBalanceSheet";

const tabs = ["Details", "Key Events", "Contact", "Misc", "History"];

const LiabilityMain: React.FC<EditLiabilityFormProps> = ({
    onClose,
    data,
    setCurrentForm,
    onSuccess = () => {},
    setIsSuccess,
    id,
}) => {
    const { setDebtList, setNotes, setTableData } =
        useReduxClientBalanceSheet();
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [details, setDetails] = useState<{ [key: string]: any }>(
        data?.details ?? {}
    );
    const [keyEvents, setKeyEvents] = useState<{ [key: string]: any }>(
        data?.key_events ?? {}
    );
    const [misc, setMisc] = useState<{ [key: string]: any }>(data?.misc ?? {});

    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/liability-details/${id}`,
        },
        {
            onSuccess: (data) => {
                setIsSuccess(true);
                if (data?.data) {
                    setTableData(data.data?.balance_sheet);
                    setNotes(data.data?.additional_balancesheet);
                    setDebtList(data.data?.debt);
                }
            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const requestData = {
            ...details,
            ...keyEvents,
            ...misc,
        };

        fetchData({ data: requestData });
    };

    useEffect(() => {
        if (data) {
            setDetails(data?.details);
            setKeyEvents(data?.key_events);
            setMisc(data?.misc);
        }
    }, [data]);

    return (
        <>
            {isSuccess ? (
                <SuccessContent
                    onClose={onClose}
                    message="Liability changes has been saved."
                />
            ) : (
                <div className="liability-main">
                    <StepTabs
                        selectedTabIndex={currentStepIndex}
                        setSelectedTabIndex={setCurrentStepIndex}
                        tabs={tabs}
                    />
                    <form onSubmit={submitHandler}>
                        {currentStepIndex === 0 && (
                            <LiabilityDetails
                                details={details}
                                setDetails={setDetails}
                                originalCreditor={data?.original_creditor || {}}
                                data={data}
                            />
                        )}
                        {currentStepIndex === 1 && (
                            <LiabilityKeyEvents
                                keyEvents={keyEvents}
                                setKeyEvents={setKeyEvents}
                            />
                        )}
                        {currentStepIndex === 2 && (
                            <LiabilityContact contact={data?.contact ?? {}} />
                        )}
                        {currentStepIndex === 3 && (
                            <LiabilityMisc misc={misc} setMisc={setMisc} />
                        )}
                        {currentStepIndex === 4 && (
                            <LiabilityHistory history={data?.history || []} />
                        )}
                        <ModalButtons>
                            {/* <Button
                                type="button"
                                style="cancel"
                                btnText="Cancel"
                                onClick={onClose}
                            /> */}
                            <Button
                                type="submit"
                                style="purple"
                                btnText="Save Changes"
                                loading={isLoading}
                            />
                        </ModalButtons>
                    </form>
                </div>
            )}
        </>
    );
};

export default LiabilityMain;
