import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SearchRow from "../../../components/SearchRow";
import Header from "../../../layout/Header";
import { TablePageContent } from "../../../pages/styles";
import DataTable from "../../../components/DataTable";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxClientCorrespondence from "../../../store/hooks/useReduxClientCorrespondence";
import { testSignableData } from "./testData";
import SignablesRow from "../../../components/DataTable/TableRows/Correspondence/SignablesRow";

const Signables = () => {
    const { clientId } = useParams();
    const { signables, setSignables } = useReduxClientCorrespondence();
    const [searchTerm, setSearchTerm] = useState("");

    const getSignablesData = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/correspondence-signables/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setSignables(data.data);
            },
        }
    );

    useEffect(() => {
        setSignables(testSignableData);
    }, []);

    return (
        <>
            <Header title="Signables" hasNavBarAbove isSubPath hasPrevPath />
            <TablePageContent>
                <SearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by document ID or title..."
                />
                <DataTable
                    data={signables || []}
                    labels={[
                        "Document ID",
                        "Document Title",
                        "Fingerprint",
                        "Sent At",
                        "Status",
                    ]}
                    searchTerm={searchTerm}
                    searchKeys={["id", "document_title"]}
                    keys={[
                        "id",
                        "template",
                        "sigbable_fingerprint",
                        "created_at",
                        "signable_status",
                    ]}
                    columnWidths={["50px", "", "", "", "30px"]}
                    hasAction
                    tableRow={SignablesRow}
                    isLoading={getSignablesData.isLoading}
                />
            </TablePageContent>
        </>
    );
};

export default Signables;
