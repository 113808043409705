import styled from "styled-components";
import { ResizableModalWrapper } from "../../styles";
import { theme } from "../../../../global/theme";
import { mainScrollbar } from "../../../../global/theme/reuseable/mixins";

const StyledEditLiability = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    & h3 {
        font-size: 14px;
    }

    & .empty {
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        color: ${theme.colors.shade};
    }

    & .spinner {
        height: 160px;
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
    }

    &.edit-liability {
        & .modal-header {
            gap: 12px;
            &__heading {
                justify-content: space-between;
            }
            & .dropdown-menu {
                padding: 7px 12px;
                & p {
                    font-size: 12px;
                }
                &__menu {
                    z-index: 2;
                }
            }
        }
    }

    & hr {
        border: none;
        border-top: 0.6px solid ${theme.colors.shade};
        margin-top: 8px;
    }

    & .data-row {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        font-size: 14px;
        grid-column: 1/-1;
        font-weight: 300;
        font-size: 12px;

        & > p {
            font-weight: 400;
            font-size: 12px;
        }
    }

    & .data-list {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 8px;

        & li {
            display: grid;
            grid-template-columns: 120px 1fr;
            font-size: 12px;
            gap: 24px;
            font-weight: 300;
            & > span {
                max-width: 300px;
                font-weight: 400;
            }
        }

        &--history {
            max-height: 400px;
            overflow-y: auto;
            ${mainScrollbar(12)};
            & li {
                grid-template-columns: 160px 1fr;
            }
        }
    }

    & .liability-main {
        flex: 1;
        display: flex;
        flex-direction: column;

        & > form {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
        &__content {
            padding-block: 16px;
            flex: 1;
        }
    }

    & .liability-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px 16px;

        & > :nth-child(2),
        & > :nth-child(3),
        & hr {
            grid-column: 1/-1;
        }
        & > :nth-child(2) {
            margin-top: 8px;
        }
    }

    & .liability-key-events {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px 16px;

        & hr {
            grid-column: 1/-1;
        }
    }

    & .liability-misc {
        & .text-area:last-of-type {
            margin-top: 12px;
        }
    }

    & .upload-ca {
        & .upload-ctn {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            align-items: center;
            margin-top: 12px;

            & p {
                word-wrap: break-word;
                font-size: 14px;
                font-weight: 300;
                & > span {
                    color: ${theme.colors.text};
                }
            }
        }
    }
`;

export default StyledEditLiability;
