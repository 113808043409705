import styled from "styled-components";

const StyledSearchRow = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;

    & .search-row {
        &__input {
        }

        &__content {
            display: flex;
            align-items: center;
            gap: 16px;
            height: 40px;

            & > button {
                height: 100%;
                border-radius: 8px;

                &[role="create"] {
                    padding-inline: 24px;
                }
            }
        }
    }
`;

export default StyledSearchRow;
