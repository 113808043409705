import React, { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    StyledCreateDocumentTemplate,
    StyledCreateDocumentTemplateHeader,
} from "./styles";
import useFetchData from "../../../../../global/hooks/useFetchData";
import { PageContent } from "../../../../../pages/styles";
import { formatDateString } from "../../../../../global/utils/helpers";
import GeneralSettings from "./Settings/GeneralSettings";
import PrintSettings from "./Settings/PrintSettings";
import EmailSettings from "./Settings/EmailSettings";
import AdHocSettings from "./Settings/AdHocSettings";
import ReqiurementsSettings from "./Settings/ReqirementsSettings";
import { Button } from "../../../../../components";
import { LoadingSpinner } from "../../../../../components/Button/styles";
import PageTabs from "../../../../../components/PageTabs/PageTabs";

export interface CreateDocumentTemplateFormProps {
    data?: { [key: string]: any };
    mode: "create" | "edit";
    documentData: { [key: string]: any };
    setDocumentData: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
    inputHandler: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    selectHandler: (key: string, value: any) => void;
    checkboxHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const initialDocumentData = {
    id: null,
    name: "",
    type: "",
    version: 1,
    file: null,
    saveas: "",
    printer_name: "",
    printer_pre: 0,
    saveasPDF: 0,
    sendto: "",
    signable_page: 0,
    email_subject: "",
    email_body: "",
    subject: "",
    body: "",
    debts_need: 0,
    iea_need: 0,
    loa_need: 0,
    preaddresss_need: 0,
    subject_need: 0,
    body_need: 0,
};

interface CreateDocumentTemplateProps {
    mode?: "create" | "edit";
}

const CreateDocumentTemplate: React.FC<CreateDocumentTemplateProps> = ({
    mode = "create",
}) => {
    const { documentId } = useParams();
    const navigate = useNavigate();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [documentData, setDocumentData] = useState<{ [key: string]: any }>(
        initialDocumentData
    );
    const isFormValid = documentData?.name.trim() !== "";
    const getDocumentTemplate = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/templates/${documentId}`,
        },
        {
            fetchOnLoad: mode === "edit",
            onSuccess: (data) => {
                const dataObj = data?.data?.result;
                setDocumentData(dataObj);
            },
        }
    );
    const downloadTemplate = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/templates-download/${documentId}`,
        },
        {
            onSuccess: (data) => {
                //console.log(data?.data);
                
                window.open(data?.data?.file_link);

            },
        }
    );

    const updateDocumentSettings = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/template-document-update/${documentId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data?.id)
                    navigate(`templates/document/${data?.data?.id}`);
            },
        }
    );

    const createDocumentTemplate = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/template-document-create`,
        },
        {
            onSuccess: (data) => {
                if (data?.data?.id)
                    navigate(`templates/document/${data?.data?.id}`);
            },
        }
    );

    const deleteTemplate = useFetchData(
        {
            method: "Delete",
            url: `${process.env.REACT_APP_SERVER_API}/templates-delete/${documentId}`,
        },
        {
            navigateOnSuccess: "/settings/templates/document",
        }
    );

    const headerTitle = () => {
        if (mode === "create") return "Create New Document Template";
        return (
            <>
                Edit {documentData?.name || "Template"}
                <span style={{ fontSize: "12px", marginInline: "8px" }}>
                    {documentData?.type || "Unknown Type"}
                </span>
            </>
        );
    };

    const inputHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value, name } = e.target;
        setDocumentData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setDocumentData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setDocumentData((prev) => {
            return { ...prev, [name]: checked ? 1 : 0 };
        });
    };

    const formProps: CreateDocumentTemplateFormProps = {
        mode,
        documentData,
        setDocumentData,
        inputHandler,
        selectHandler,
        checkboxHandler,
    };
    const formComponents = [
        GeneralSettings,
        PrintSettings,
        EmailSettings,
        AdHocSettings,
    ];
    const FormComponent = formComponents[selectedTabIndex];

    const downloadHandler = () => {
        downloadTemplate.fetchData();
    };

    const deleteHandler = () => {
        deleteTemplate.fetchData();
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const formData = new FormData();
        for (const p in initialDocumentData) {
            formData.append(p, documentData[p]);
        }
        console.log(initialDocumentData)
        console.log('lll')
        console.log(formData);
        if (mode === "create") {
            createDocumentTemplate.fetchData(formData);
        } else {
            updateDocumentSettings.fetchData(formData);
        }
    };

    if (getDocumentTemplate.isLoading) return <LoadingSpinner size={40} />;

    return (
        <>
            <StyledCreateDocumentTemplateHeader
                title={headerTitle()}
                prevPath="/settings/templates/document"
                isSubPath
                hasNavBarAbove
            >
                {mode === "edit" ? (
                    <ul>
                        <li>
                            Created by: <strong>Jake Kitchen</strong>
                            <span>{`(${formatDateString(
                                "2022-08-12 12:30:00",
                                "YYYY-MM-DD HH:mm:ss",
                                "DD/MM/YYYY HH:mm"
                            )})`}</span>
                        </li>
                        <li>
                            Updated by: <strong>Jake Kitchen</strong>
                            <span>{`(${formatDateString(
                                "2022-08-12 12:30:00",
                                "YYYY-MM-DD HH:mm:ss",
                                "DD/MM/YYYY HH:mm"
                            )})`}</span>
                        </li>
                    </ul>
                ) : null}
            </StyledCreateDocumentTemplateHeader>
            <PageContent>
                <StyledCreateDocumentTemplate
                    className="create-document-template"
                    onSubmit={submitHandler}
                >
                    <div className="create-document-template__column">
                        <PageTabs
                            tabs={[
                                "General",
                                "Printer & Paper",
                                "Email",
                                "Ad-Hoc",
                            ]}
                            selectedTabIndex={selectedTabIndex}
                            setSelectedTabIndex={setSelectedTabIndex}
                        />
                        <FormComponent {...formProps} />
                    </div>
                    <div className="create-document-template__column">
                        <ReqiurementsSettings {...formProps} />
                        <Button
                            style="mint"
                            type="submit"
                            btnText={`${
                                mode === "create" ? "Create" : "Save"
                            } Template`}
                            loading={
                                updateDocumentSettings.isLoading ||
                                createDocumentTemplate.isLoading
                            }
                            disabled={!isFormValid}
                        />
                        {mode === "edit" && (
                            <>
                                <Button
                                    style="purple"
                                    type="button"
                                    btnText="Download Latest Verison"
                                    onClick={downloadHandler}
                                    loading={downloadTemplate.isLoading}
                                />
                                <Button
                                    style="red"
                                    type="button"
                                    btnText="Delete Template"
                                    onClick={deleteHandler}
                                    loading={deleteTemplate.isLoading}
                                />
                            </>
                        )}
                    </div>
                </StyledCreateDocumentTemplate>
            </PageContent>
        </>
    );
};

export default CreateDocumentTemplate;
