import React, { useState } from "react";
import DataTable from "../../../../components/DataTable";
import LoginLogsRow from "../../../../components/DataTable/TableRows/Accounts/LoginLogsRow";
import useFetchData from "../../../../global/hooks/useFetchData";
const LoginLogs = (userId: any) => {
    const [userLogs, setAccessLogs] = useState<Array<{ [key: string]: any }>>(
        []
    );
    const id = userId;

    const { data, fetchData, isLoading } = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/logs/${id.userId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) {
                    setAccessLogs(data?.data);
                }
            },
        }
    );
    console.log(userLogs);
    const tableKeyHeadings = {
        datetime: "When",
        ip: "IP Address",
        status: "Status",
    };

    return (
        <DataTable
            labels={Object.values(tableKeyHeadings)}
            keys={Object.keys(tableKeyHeadings)}
            data={userLogs}
            tableRow={LoginLogsRow}
        />
    );
};

export default LoginLogs;
