import React, { SetStateAction } from "react";

import DataTable from "../../../../components/DataTable";
import OpenCase from "../../../../components/DataTable/actions/OpenCase";

interface PaymentsTablesProps {
    headings: string[][];
    bodies: { [key: string]: any }[][];
    selectedTableIndex: number;
    selectedIds: number[];
    setSelectedIds: React.Dispatch<SetStateAction<number[]>>;
    searchTerm: string;
    isLoading: boolean;
}

const PaymentsTables: React.FC<PaymentsTablesProps> = ({
    headings,
    bodies,
    selectedTableIndex,
    selectedIds,
    setSelectedIds,
    searchTerm,
    isLoading,
}) => {
    return (
        <DataTable
            key={selectedTableIndex}
            data={bodies[selectedTableIndex] || []}
            dataKey={selectedTableIndex > 1 ? "m_id" : "Case Id"}
            hasCheckbox={
                selectedTableIndex < 2
                    ? (data) => !!data?.["Number Of Trys"]
                    : false
            }
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            isLoading={isLoading}
            minWidth={"1900px"}
            action={OpenCase}
            searchTerm={searchTerm}
            searchKeys={["Case Id", "CaseID", "Customer Name", "Customer"]}
            hideColums={selectedTableIndex > 1 ? [0] : []}
            formatKeys={{
                "Pack Back": "time",
                IP1: "date",
                IP2: "date",
                IP3: "date",
                IP4: "date",
                IP5: "date",
                "Last Run (When)": "time",
                "Next Payment Date": "date",
                When: "time",
            }}
        />
    );
};

export default PaymentsTables;
