import { AgreementDetailsContainerProps } from ".";
import DropdownSelect from "../../../../components/DropdownSelect";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import { formatDateString } from "../../../../global/utils/helpers";

const CCR: React.FC<AgreementDetailsContainerProps> = ({
    formData,
    onSelect,
    onInputChange,
    handlerList,
}) => {
        //formatDateString(formData?.ccr_migrated_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')

    return (
        <>
            <HeaderContainer
                title="CCR Filp"
                headerStyle="gradientBlue"
                className="ccr-filp col-2"
            >
                <DropdownSelect
                    label="Agent:"
                    options={handlerList?.case_handler}
                    value={formData?.ccr_filp_agent_id ?? null}
                    onSelect={(option) =>
                        onSelect("ccr_filp_agent_id", option.id)
                    }
                    optionKey="id"
                    labelKeys={["name"]}
                    enableInput
                />
                <Input
                    type="date"
                    label="Date Filpped:"
                    value={formatDateString(formData?.ccr_filp_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                    onChange={onInputChange}
                    name="ccr_filp_date"
                />
                <Input
                    type="number"
                    label="Amount Filpped (£):"
                    value={`${formData?.ccr_filp_amount ?? ""}`}
                    onChange={onInputChange}
                    name="ccr_filp_amount"
                    step={0.01}
                />
                <Input
                    type="text"
                    label="Additional Notes:"
                    value={`${formData?.ccr_filp_notes ?? ""}`}
                    onChange={onInputChange}
                    name="ccr_filp_notes"
                />
            </HeaderContainer>
            <HeaderContainer
                title="CCR Migrated"
                headerStyle="gradientBlue"
                className="ccr-migrated col-2"
            >
                <DropdownSelect
                    label="Agent:"
                    options={handlerList?.case_handler}
                    value={formData?.ccr_migrated_agent_id ?? null}
                    onSelect={(option) =>
                        onSelect("ccr_migrated_agent_id", option.id)
                    }
                    optionKey="id"
                    labelKeys={["name"]}
                    enableInput
                />
                <Input
                    type="date"
                    label="When:"
                    value={formatDateString(formData?.ccr_migrated_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                    onChange={onInputChange}
                    name="ccr_migrated_date"
                />
            </HeaderContainer>
        </>
    );
};

export default CCR;
