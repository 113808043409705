import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal } from "../../../components";
import { StyledFilesContent } from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxClientCorrespondence from "../../../store/hooks/useReduxClientCorrespondence";
import UploadPreview from "../../../components/UploadPreview";
import UploadFiles from "../../../components/Modals/Correspondence/UploadFiles";

const fileTypeCatagories = {
    document: "Document",
    ID: "ID",
    upload: "Uploads",
    selfuploads: "Self Uploads",
    LOA: "LOAs",
    signable: "Signables",
};

const Files = () => {
    const { files, setFiles } = useReduxClientCorrespondence();
    const { clientId } = useParams();
    const [isUploading, setIsUploading] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");

    const filteredFiles = () => {
        if (selectedOption === "") return files;
        return files.filter(
            (file) =>
                selectedOption in fileTypeCatagories &&
                file?.type === selectedOption
        );
    };

    const fileTypeCounts = Object.keys(fileTypeCatagories).map(
        (key) => files.filter((file) => file?.type === key).length
    );

    const getFilesData = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/correspondence-file/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setFiles(data.data);
            },
        }
    );

    return (
        <>
            <Modal isVisible={isUploading}>
                <UploadFiles
                    isOpen={isUploading}
                    onClose={() => setIsUploading(false)}
                />
            </Modal>
            <StyledFilesContent
                className="corres--file"
                panelTitle="Document Type:"
                panelCounts={fileTypeCounts}
                panelOptions={Object.keys(fileTypeCatagories)}
                optionLabels={Object.values(fileTypeCatagories)}
                panelElement={
                    <Button
                        style="purple"
                        onClick={() => setIsUploading(true)}
                        btnText="Upload Files"
                    />
                }
                headerTitle="Files"
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                isLoading={getFilesData.isLoading}
            >
                {filteredFiles()?.length ? (
                    <ul className="file-list">
                        {filteredFiles().map((file) => (
                            <li className="file-item" key={file?.id}>
                                <UploadPreview
                                    filePath={file?.s3Path}
                                    fileName={file?.file_name}
                                    fileSize={file?.file_size}
                                    fileType={file?.file_extn}
                                    date={file?.created_at}
                                />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="no-upload">No File Uploaded.</p>
                )}
            </StyledFilesContent>
        </>
    );
};

export default Files;
