import styled from "styled-components";
import { theme } from "../../../global/theme";

const StyledBodyVariablesSelect = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 8px;
    & .body-variables-select {
        &__select {
            flex: 1;
        }

        &__copy {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            background-color: ${theme.colors.purple};
            border-radius: 4px;
            transition: all 0.2s ease;

            &[data-copied="true"] {
                background-color: #16da5e;
            }

            & > svg {
                height: 12px;
                width: 12px;
            }
        }
    }
`;

export default StyledBodyVariablesSelect;
