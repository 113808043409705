import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { Button } from "../../../components";
import { CreditFilter } from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";
import DropdownSelect from "../../../components/DropdownSelect";
import { generateTestOptions } from "../../../components/DropdownSelect/options";
import {
    DateSearchOption,
    dateSearchOptions,
} from "../../../layout/SlideInMenus/DateSearch/data";
import "react-datepicker/dist/react-datepicker.css";

interface AdminMailShotFilterProps {
    selectedClientIds: number[];
}

console.log(dateSearchOptions.slice(1));

const AdminMailShotFilter = ({
    selectedClientIds,
}: AdminMailShotFilterProps) => {
    const [searchRange, setSearchRange] = useState<DateSearchOption | null>(
        null
    );
    const [templateId, setTemplateId] = useState<any>();
    const clientCount = selectedClientIds?.length || 0;
    const dateTypeOptions = [
        ...dateSearchOptions.slice(1),
        { displayText: "Custom" },
    ];

    const documentTemplates = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/document-templates`,
        },
        {
            // fetchOnLoad: true,
        }
    );

    const dateTypeHandler = (option: { [key: string]: any }) => {
        setSearchRange((prev) => {
            return { ...prev, ...option } as DateSearchOption;
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(selectedClientIds);
    };

    return (
        <CreditFilter>
            <form onSubmit={submitHandler}>
                <h3>
                    Filter <label htmlFor="">{clientCount} Selected</label>
                </h3>
                <DropdownSelect
                    label="Field For Date"
                    options={dateTypeOptions}
                    value={searchRange?.displayText}
                    onSelect={dateTypeHandler}
                    labelKeys={["displayText"]}
                    optionKey="displayText"
                />
                <div className="">
                    <ReactDatePicker
                        selected={moment(
                            searchRange?.from || moment().format("YYYY-MM-DD"),
                            "YYYY-MM-DD"
                        ).toDate()}
                        onChange={(date) =>
                            setSearchRange((prev) => {
                                return {
                                    ...prev,
                                    from: moment(date).format("YYYY-MM-DD"),
                                } as DateSearchOption;
                            })
                        }
                    />
                    <ReactDatePicker
                        selected={moment(
                            searchRange?.to || moment().format("YYYY-MM-DD"),
                            "YYYY-MM-DD"
                        ).toDate()}
                        onChange={(date) =>
                            setSearchRange((prev) => {
                                return {
                                    ...prev,
                                    to: moment(date).format("YYYY-MM-DD"),
                                } as DateSearchOption;
                            })
                        }
                    />
                </div>

                <DropdownSelect
                    label="Document Template:"
                    options={generateTestOptions(5)}
                    value={templateId}
                    onSelect={(option) => setTemplateId(option.value)}
                    // labelKeys={["name"]}
                    // optionKey="id"
                />

                <Button
                    btnText="Submit"
                    style="purple"
                    type="submit"
                    loading={false}
                />
            </form>
        </CreditFilter>
    );
};

export default AdminMailShotFilter;
