import { Notify } from "notiflix";
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import Button from "../Button";
import Input from "../Input";
import useReduxUser from "../../store/hooks/useReduxUser";
import { ChangePasswordModalWrapper } from "./styles";
import useFetchData from "../../global/hooks/useFetchData";
import ModalHeader from "./components/ModalHeader";
import SuccessContent from "./components/SuccessContent";
import ModalButtons from "./components/ModalButtons";

export interface iChangePasswordModal {
    open: boolean;
    setPasswordModal: Dispatch<SetStateAction<boolean>>;
}

export interface iNewPassword {
    new: string;
    retyped: string;
}
const ChangePasswordModal = ({
    setPasswordModal,
    open,
}: iChangePasswordModal) => {
    const { user } = useReduxUser();
    const [passwords, setPasswords] = useState<iNewPassword>({
        new: "",
        retyped: "",
    });
    const isNewPasswordValid = passwords.new.trim() !== "";
    const isPasswordsMatch = passwords.new === passwords.retyped;
    const isPasswordsValid = isNewPasswordValid && isPasswordsMatch;

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        if (name === "new" || name === "retyped") {
            setPasswords((passwords) => ({
                ...passwords,
                [name]: value,
            }));
        }
    };

    const { fetchData, isLoading, isSuccess } = useFetchData({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_API}/change-password`,
    });

    const handleSave = (e: React.FormEvent) => {
        e.preventDefault();

        if (!isPasswordsMatch)
            return Notify.failure("Password does not match.");
        if (isPasswordsValid && user?.id) {
            const requestData = {
                password: passwords?.new,
                password_confirm: passwords?.retyped,
                id: user.id,
            };
            fetchData(requestData);
        }
    };

    const closeModal = () => setPasswordModal(false);

    return (
        <ChangePasswordModalWrapper show={open} width="400px">
            <ModalHeader onClose={closeModal}>Change Password</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    onClose={closeModal}
                    message="Password Updated Successfully"
                />
            ) : (
                <form onSubmit={handleSave}>
                    <Input
                        type="password"
                        label="New Password"
                        onChange={handleChange}
                        name="new"
                        value={passwords.new}
                        hasShowPassword
                    />
                    <Input
                        type="password"
                        label="Retype Password"
                        onChange={handleChange}
                        name="retyped"
                        value={passwords.retyped}
                    />
                    <ModalButtons>
                        <Button
                            btnText="Save"
                            onClick={handleSave}
                            style="purple"
                            loading={isLoading}
                            disabled={!isPasswordsValid}
                        />
                    </ModalButtons>
                </form>
            )}
        </ChangePasswordModalWrapper>
    );
};

export default ChangePasswordModal;
