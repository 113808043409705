import styled from "styled-components";
import { buttonMixin } from "../../../components/Button/styles";
import { theme } from "../../../global/theme";
import { hideScrollbar } from "../../../global/theme/reuseable/mixins";
import { PageFilterContent, PageHeader, PageNavWrapper } from "../../styles";
import { PageFilterPanel } from "../../styles";

export const SettingsPageLayout = styled.section`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .headContainer {
        flex: 1;
        overflow: auto;
        display: grid;
        max-width: 100%;
        grid-template-columns: 1fr 300px;
        grid-template-rows: 100px auto;

        & > :nth-child(2) {
            grid-area: 1/2/-1/-1;
            width: auto;
        }
    }
`;

export const SettingsLandingContent = styled(PageFilterContent)`
    display: flex;
    flex-direction: column;
    gap: 24px;

    h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #474747;
    }

    & .page-tabs {
        &__btn {
            width: 132px;
        }
    }

    .subNav {
        width: 100%;
        background: #f1f1f1;
        border-radius: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        padding: 16px;
        margin-bottom: 16px;
        max-width: 50vw;
    }

    .basic-info {
        &__form {
            display: grid;
            grid-template-rows: repeat(auto-fit, auto);
            grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
            gap: 16px 24px;
            max-width: 600px;

            @media screen and (max-width: 1024px) {
                grid-template-columns: 1fr;
                max-width: 300px;
            }

            /* & > .inputWrapper {
                width: 100%;
            } */
        }
        h2 {
            margin-bottom: 12px;
        }

        input {
            width: 100%;
            height: 40px;
        }

        button {
            margin-top: 12px;
            width: 117px;
            height: 40px;
            grid-column: 1/-1;
        }
    }
`;

export const ProfileMenuWrapper = styled.div`
    background: #e5efff;
    padding: 24px;
    width: 360px;
    height: 100%;

    h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        color: #5e5e5e;
        margin-bottom: 30px;
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 15px;
        span {
            width: 100%;
            display: flex;

            label,
            p {
                width: 50%;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 11px;
                line-height: 16px;
                color: ${theme?.colors?.darkText};
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        margin-top: 22px;
        margin-bottom: 27px;
        gap: 14px;
        button {
            width: 100%;
            height: 40px;
        }
        button:first-child {
            background: linear-gradient(
                156.78deg,
                #ffc24d 15.32%,
                #ff7a68 85.25%
            );
            border: 0.4px solid #082a52;
            border-radius: 5px;
        }
        :last-child {
            background: linear-gradient(
                124.94deg,
                #ff7a68 -8.84%,
                #c8311d 107.02%
            );
            /* Box Stroke */

            border: 0.4px solid #082a52;
            border-radius: 5px;
        }
    }

    .serverInfo {
        h5 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            text-transform: capitalize;
            color: #5e5e5e;
            margin-bottom: 16px;
        }

        span {
            border: 0.4px solid rgba(8, 42, 82, 0.3);
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            padding: 17px 18.5px 16px 17px;
            margin-bottom: 15px;
        }
        span:nth-child(even) {
            background-color: white;
        }
        span:nth-child(odd) {
            background: rgba(255, 255, 255, 0.5);
        }
    }
`;

export const PageReturn = styled.button`
    width: 28px;
    height: 28px;
    ${buttonMixin};
    background: #f5f5f5;
    border-radius: 4.66667px;
    display: grid;
    place-items: center;
    margin-right: 14px;
    :hover {
        background: #eeeeee;

        cursor: pointer;
    }
`;

export const SettingPageFilterPanel = styled(PageFilterPanel)`
    height: calc(100vh - 110px);
    overflow-y: auto;
    ${hideScrollbar}
`;
