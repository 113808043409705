import styled from "styled-components";
import { theme } from "../../../global/theme";
import { mainScrollbar } from "../../../global/theme/reuseable/mixins";

export const StyledTicketContainer = styled.div`
    overflow: hidden;
    ${mainScrollbar(12)};
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledTicketContent = styled.div`
    width: 660px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    overflow: auto;
    ${mainScrollbar(12)};

    & .ticket-content {
        &__info {
            display: flex;
            align-items: flex-end;
            gap: 12px;
        }
        &__ids {
            flex: 1;
            margin-right: 16px;
            & > li {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
            }
        }

        &__selects {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-auto-rows: auto;
            gap: 20px 12px;

            & > :first-child,
            & > :last-child {
                grid-column: 1/-1;
            }
        }

        &__dates {
            & > :last-child {
                margin-top: 8px;
            }
        }
    }
`;

export const StyledEditNotes = styled.section`
    width: 460px;
    min-height: 280px;
    display: flex;
    flex-direction: column;

    & .edit-notes {
        &__create {
            margin: 8px 0;
            background-color: transparent;
            border: none;
            background-image: ${theme?.gradients.skyBlue};
            -webkit-background-clip: text;
            background-clip: text;
            background-repeat: no-repeat;
            background-size: cover;
            color: transparent;
            margin-right: auto;
            padding: 4px;
            align-self: flex-start;
        }

        &__cards {
            list-style: none;
        }

        &__card {
            background-color: ${theme?.colors.containerBackground};
            border-radius: 8px;
            border: 0.6px solid ${theme?.colors.boxStroke};
            padding: 8px;
            display: flex;
            flex-direction: column;

            & > h3 {
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 4px;
            }

            & > p {
                text-align: left;
                width: 100%;
                font-size: 12px;
                font-weight: 300;
            }

            &:not(:first-child) {
                margin-top: 8px;
            }
        }

        &__form {
            flex: 1;
            display: flex;
            flex-direction: column;
            & > :nth-child(2) {
                flex: 1;
                margin-top: 12px;
                display: flex;
                flex-direction: column;

                & > textarea {
                    flex: 1;
                }
            }
        }
    }
`;
