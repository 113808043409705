import styled from "styled-components";
import { theme } from "../../global/theme";

interface StyledDateTimePickerProps {
    width: number;
    isLayoutVertical: boolean;
}

export const StyledDateTimePicker = styled.div<StyledDateTimePickerProps>`
    display: flex;
    align-items: center;
    flex-direction: ${(props) => (props.isLayoutVertical ? "column" : "row")};
    align-items: ${(props) =>
        props.isLayoutVertical ? "flex-start" : "initial"};

    & > label {
        flex-grow: 1;
        text-align: left;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 4px;

        & > span {
            font-size: 10px;
            color: #e21d1d;
            font-weight: 300;
        }
    }

    .date-picker {
        &__clear {
            background: ${theme?.colors.purple};
            padding: 0 12px;
            color: ${theme?.colors.white};
            border: none;
            border-radius: 4px;
            font-size: 12px;
        }
    }

    & .react-datepicker-wrapper {
        height: 30px;
        width: ${(props) => (props.isLayoutVertical ? "100%" : "initial")};
    }

    & .react-datepicker {
        &__input-container {
            position: relative;
            & input {
                height: 30px;
                padding-left: 1ch;
                border: 0.6px solid ${theme?.colors.shade};
                border-radius: 6px;
                font-size: 12px;
                font-family: ${theme?.fontFamily.mono};
                width: ${(props) =>
                    props.isLayoutVertical
                        ? "inherit"
                        : `${props.width + 5}ch`};

                /* width: 142px; */

                &::placeholder {
                    font-size: 12px;
                }

                &:focus {
                    outline: none;
                    border-color: ${theme?.colors.boxStroke};
                }

                &[aria-invalid="true"] {
                    border-color: #e21d1d;
                }
            }
        }

        &__close-icon {
            pointer-events: none;
            position: absolute;
            right: 0;
            &::after {
                height: 12px;
                width: 12px;
                font-size: 10px;
                padding: 0;
                background-color: ${theme?.colors.purple};
                pointer-events: initial;
            }
        }
    }
`;
