import { useParams } from "react-router-dom";
import { Button } from "../../../components";
import Header from "../../../layout/Header";
import { StyledIdAmlContent } from "./styles";
import CircularMeter from "../../../components/CircularMeter";
import DataTable from "../../../components/DataTable";
import { formatDateString } from "../../../global/utils/helpers";
import { StyledBaseDetailsSubmitCtn } from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";
import VerificationTypeRow from "../../../components/DataTable/TableRows/BaseDetails/VerificationTypeRow";

const IdAml = () => {
    const { clientId } = useParams();

    const getIdAmlData = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/id-aml-checks/${clientId}`,
        },
        {
            fetchOnLoad: true,
        }
    );

    const sendRequest = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/send-aml-id-request/${clientId}`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Your request has been sent.",
        }
    );

    const testVerificationTypeData = [
        {
            id: 1,
            name: "Verication name 1",
            status: "completed",
            created_at: "2022-08-02",
        },
        {
            id: 2,
            name: "Verication name 2",
            status: "failed",
            created_at: "2022-08-01",
        },
    ];

    const testAuditTrailData = [
        {
            id: 1,
            name: "Verication name 2",
            created_at: "2022-08-01",
        },
        {
            id: 2,
            name: "Verication name 2",
            created_at: "2022-09-01",
        },
    ];

    const sendRequestHandler = () => {
        sendRequest.fetchData();
    };

    return (
        <>
            <Header title="ID & AML" hasNavBarAbove hasPrevPath />
            <StyledIdAmlContent>
                <div className="containers">
                    <div className="container">
                        <h2>ID Verification Check</h2>
                        <ul>
                            <li>
                                Request ID:<span>{"1234" ?? "-"}</span>
                            </li>
                            <li>
                                Created At:
                                <span>
                                    {formatDateString("2022-08-08") || "-"}
                                </span>
                            </li>
                            <li>
                                Partially Completed At:
                                <span>
                                    {formatDateString("2022-08-08") || "-"}
                                </span>
                            </li>
                            <li>
                                Completed:
                                <span>
                                    {formatDateString("2022-08-08") || "-"}
                                </span>
                            </li>
                            <li>
                                Verified:
                                <span>
                                    {formatDateString("2022-08-08") || "-"}
                                </span>
                            </li>
                            <li>
                                Clean Watchlist:<span>{"1234" ?? "-"}</span>
                            </li>
                            <li>
                                Source df Request:
                                <span>{"Fashbox" ?? "-"}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="container">
                        <h2>ID Verification Check</h2>
                        <CircularMeter percentage={50} />
                    </div>
                </div>

                <div className="table-container">
                    <h2>Verification Type:</h2>
                    <DataTable
                        labels={["Name", "Completion Status", "Created At"]}
                        keys={["name", "status", "created_at"]}
                        data={testVerificationTypeData}
                        tableRow={VerificationTypeRow}
                    />
                </div>
                <div className="table-container">
                    <h2>Audit Trail:</h2>
                    <DataTable
                        labels={["Name", "Created At"]}
                        keys={["name", "created_at"]}
                        data={testAuditTrailData}
                        formatKeys={{
                            created_at: "date",
                        }}
                    />
                </div>
                <StyledBaseDetailsSubmitCtn>
                    <Button
                        className="send-request-btn"
                        type="button"
                        btnText="Send Request"
                        style="white"
                        loading={sendRequest.isLoading}
                        onClick={sendRequestHandler}
                    />
                </StyledBaseDetailsSubmitCtn>
            </StyledIdAmlContent>
        </>
    );
};

export default IdAml;
