import styled from "styled-components";
import HeaderContainer from "../../../../../../containers/HeaderContainter";
import { theme } from "../../../../../../global/theme";

export const StyledGeneralSettings = styled(HeaderContainer)`
    & .header-container {
        &__ctn {
            padding: 16px;
        }
    }

    & .info {
        & > li {
            grid-template-columns: auto 1fr;
        }
    }
`;

export const StyledDocumentTemplateForm = styled.div`
    border-radius: 12px;
    padding: 24px;
    background-color: ${theme.colors.white};
    border: 0.6px solid ${theme.colors.shade};
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    &.message-form {
        grid-template-columns: 1fr auto;
    }

    & .text-editor {
        height: calc(100vh - 420px);
        min-height: 340px;
        grid-column: 1/-1;
    }

    & .submit-btn {
        margin-top: 8px;
        padding-inline: 24px;
        margin-left: auto;
    }
`;
export const StyledRequirmentsSettings = styled(StyledGeneralSettings)`
    &.checkboxes {
        align-items: flex-start;
    }
`;
