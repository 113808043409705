import React, { useState } from "react";
import Header from "../../../layout/Header";
import { useParams } from "react-router-dom";
import { TablePageContent } from "../../../pages/styles";
import SearchRow from "../../../components/SearchRow";
import useReduxClientManagement from "../../../store/hooks/useReduxClientManagement";
import { testAuditLogsData } from "./testData";
import DataTable from "../../../components/DataTable";
import { Modal } from "../../../components";
import AuditLogDetails from "../../../components/Modals/Management/AuditLogDetails";
import useFetchData from "../../../global/hooks/useFetchData";

const AuditLogs = () => {
    const { auditLogs,setAuditLogs } = useReduxClientManagement();
    const [selectedData, setSelectedData] = useState<{
        [key: string]: any;
    } | null>(null);
    const [searchTerm, setSearchTerm] = useState("");

    const { clientId } = useParams();
    const rowClickHandler = (data: { [key: string]: any }) => {
        //console.log(data); commented for not in figma
        //setSelectedData(data);
    };
    const getManagement = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/management-logs/${clientId}`,
        },
        {   fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setAuditLogs(data.data);
            },
        }
    );
    return (
        <>
            <Modal isVisible={!!selectedData}>
                <AuditLogDetails
                    isOpen={!!selectedData}
                    onClose={() => setSelectedData(null)}
                    data={selectedData || {}}
                />
            </Modal>
            <Header title="Audit Logs" hasNavBarAbove hasPrevPath isSubPath />
            <TablePageContent>
                <SearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by description or created by..."
                />
                <DataTable
                    labels={["Description", "Created By", "Created When"]}
                    keys={["dscrptn", "name", "created_at"]}
                    data={auditLogs}
                    searchKeys={["dscrptn", "name"]}
                    searchTerm={searchTerm}
                    onRowClick={rowClickHandler}
                    formatKeys={{
                        created_when: "time",
                    }}
                />
            </TablePageContent>
        </>
    );
};

export default AuditLogs;
