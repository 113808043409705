import styled from "styled-components";
import { PageContent } from "../../../pages/styles";
import { theme } from "../../../global/theme";
import {
    hideScrollbar,
    mainScrollbar,
} from "../../../global/theme/reuseable/mixins";

const StyledProductsLandingContent = styled(PageContent)`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    flex-direction: row;
    align-items: flex-start;

    & .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        margin-bottom: 16px;

        & > h2 {
            font-size: 14px;
        }

        & > a {
            border-radius: 6px;
            border: 1px solid ${theme.colors.purple};
            color: ${theme.colors.purple};
            padding: 6px 12px;
            background-color: ${theme.colors.white};
            font-size: 12px;
        }
    }

    & .cards {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    & .detail-card {
        flex-direction: column-reverse;

        &__title {
            font-weight: 300;
            margin: 0;
            font-size: 12px;
        }

        &:nth-child(1) {
            & .detail-card__count {
                background-image: ${theme.gradients.good};
            }
        }
        &:nth-child(2) {
            & .detail-card__count {
                background-image: ${theme.gradients.dangerRed};
            }
        }
    }

    & .container {
        border-radius: 10px;
        background-color: ${theme.colors.white};
        padding: 16px;
        min-height: 250px;

        &--paye {
            padding-top: 20px;
            & .btns {
                display: flex;
                flex-direction: column;
                gap: 8px;

                & > button {
                    width: 200px;
                    font-size: 12px;
                }
            }
        }
    }

    & .data-list {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 8px;

        & li {
            display: grid;
            grid-template-columns: 180px 220px;
            gap: 12px;
            font-weight: 300;
            font-size: 12px;
        }
    }
`;

export const StyledPersonalBudgetingIEContent = styled(PageContent)`
    flex-direction: row;
    gap: 24px;
    overflow: hidden;

    & .table-col {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1 1 0;
        overflow: hidden;

        & .search-row {
            justify-content: space-between;
        }

        & .searchInput {
            order: 2;
        }

        & h2 {
            font-size: 16px;
        }
    }

    & .right {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        ${hideScrollbar}
        max-height: calc(100vh - 200px);
    }

    & .control {
        background-color: ${theme.colors.white};
        border-radius: 10px;
        align-self: flex-start;
        padding: 16px 24px;
        width: 240px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1 1 0;
        max-height: 290px;

        & h3 {
            font-size: 16px;
            margin-bottom: 8px;
        }

        & > ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 4px;

            & li {
                display: flex;
                justify-content: space-between;
                font-weight: 300;
                gap: 12px;
                font-size: 11px;
            }
        }

        & > button {
            font-size: 12px;
        }

        & .sync-btn {
            margin-top: 12px;
        }
    }
`;

export const StyledWFHRebateContent = styled(PageContent)`
    & > form {
        display: grid;
        justify-content: center;
        grid-template-columns: min(100%, 1120px);
        gap: 24px;
    }
    & .wfh-ctn-content {
        & .ctn {
            display: flex;
            gap: 8px;
            justify-content: flex-start;
            align-items: flex-start;

            & > p {
                font-size: 12px;
                font-weight: 300;
                width: 500px;
                align-self: center;
            }

            &:not(:first-of-type) {
                margin-top: 24px;
            }
        }

        & .dropdown-menu {
            margin-right: 12px;
            padding: 4px 8px;

            & svg {
                display: none;
            }

            &__menu {
                z-index: 2;
                width: 300px;

                & h3 {
                    background-color: ${theme.colors.formBlue};
                    padding: 4px 8px;
                    font-weight: 500;
                }

                & p {
                    padding: 8px;
                    font-size: 12px;
                    font-weight: 300;
                }
            }
        }

        & .dropdown-select {
            flex: 1;

            &__field {
                width: 200px;
                margin-left: auto;
            }
        }
    }
`;

export const StyledPersonalAccountancyContent = styled(PageContent)`
    & > form {
        width: min(100%, 1120px);
        margin-inline: auto;
    }

    & .full-col {
        grid-column: 1/-1;
    }
`;

export default StyledProductsLandingContent;
