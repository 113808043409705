import React, { useState, useEffect, SetStateAction } from "react";
import { icons } from "../../../../assets/icons";
import { Button } from "../../../../components";
import { TablePageContent } from "../../../../pages/styles";
import Header from "../../../../layout/Header";
import DateButton from "../../../../components/Button/DateButton";
import useFetchData from "../../../../global/hooks/useFetchData";
import SearchRow from "../../../../components/SearchRow";
import { dateSearchOptions } from "../../../../layout/SlideInMenus/DateSearch/data";
import useDateSearch from "../../../../layout/SlideInMenus/DateSearch/useDateSearch";
import DataTable from "../../../../components/DataTable";
import { exportCsv } from "../../../../global/utils/helpers";

export interface PaymentTableProps {
    data: { [key: string]: any };
    isLoading?: boolean;
    selectedIds?: number[];
    setSelectedIds?: React.Dispatch<SetStateAction<number[]>>;
    searchTerm?: string;
}

const Refunds: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today");

    const { data, isLoading, fetchData } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-invoice`,
        },
        {
            params: {
                from: dateRange.from,
                to: dateRange.to,
                offset: 0,
                limit: 500,
            },
            onSuccess: onDateSearchSuccess,
            notifyOnSuccess: true,
        }
    );

    const invoicesData: { [key: string]: any } = data?.data || {};
    const invoiceData = Object.values(invoicesData);
    const invoiceBodies = invoiceData?.[0] ?? [];
    const invoiceHeadings = invoiceData?.[1] ?? [];

    useEffect(() => {
        fetchData();
    }, [dateRange]);

    const exportHandler = () => {
        exportCsv(
            [invoiceBodies],
            [invoiceHeadings],
            ["Invoice"],
            `Invoice_${dateRange.from}-${dateRange.to}`
        );
    };

    return (
        <>
            <Header title="Invoice" hasNavBarAbove hasPrevPath>
                <DateButton
                    isLoading={isLoading}
                    dateDisplay={dateDisplay}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                />
            </Header>
            <TablePageContent>
                <SearchRow
                    placeholder="Search by case id..."
                    value={searchTerm}
                    setValue={setSearchTerm}
                >
                    <Button
                        btnText=""
                        onClick={exportHandler}
                        style="red"
                        btnImg={icons?.exportIcon}
                    />
                </SearchRow>
                <DataTable
                    data={invoicesData?.body || []}
                    dataKey="id"
                    isLoading={isLoading}
                    searchTerm={searchTerm}
                    searchKeys={["Case Id"]}
                    hideColums={[0]}
                    hasAction={false}
                />
            </TablePageContent>
        </>
    );
};

export default Refunds;
