import StyledSwitch from "./styles";

interface SwitchProps {
    className?: string;
    label?: string;
    name?: string;
    value?: any;
    switchValue: any;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Switch: React.FC<SwitchProps> = ({
    className = "",
    label,
    name,
    value,
    switchValue,
    onClick,
}) => {
    const isActive = switchValue === value;
    return (
        <StyledSwitch className="switch">
            {label ? <p className="switch__label">{label}</p> : null}
            <button
                className="switch__btn"
                onClick={onClick}
                name={name}
                value={switchValue}
                data-checked={isActive}
            >
                <div className="switch__thumb" aria-checked={isActive}></div>
            </button>
        </StyledSwitch>
    );
};

export default Switch;
