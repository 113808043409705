import { LinearHeading } from "../../global/theme/reuseable/components";
import { LinearHeadingT } from "../../global/theme/types";
import { PaymentDashboardStatWrapper } from "./styles";

export type PaymentDashboardStatT = "Collected" | "Declined" | "Error";

export interface iPaymentDashboardStat {
    value: string | number;
    subTitle: PaymentDashboardStatT;
}

const PaymentDashboardStat = ({ value, subTitle }: iPaymentDashboardStat) => {
    const headingColour: LinearHeadingT =
        subTitle === "Collected"
            ? "green"
            : subTitle === "Declined"
            ? "red"
            : subTitle === "Error"
            ? "orange"
            : "green";
    return (
        <PaymentDashboardStatWrapper>
            <LinearHeading color={headingColour}>
                £{value.toLocaleString()}
            </LinearHeading>
            <p>{subTitle}</p>
        </PaymentDashboardStatWrapper>
    );
};

export default PaymentDashboardStat;
