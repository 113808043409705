import { ChangeEvent, useState } from "react";
import Button from "../../Button";
import Input from "../../Input";
import { ContinueClientModalWrapper } from "../styles";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxCreateClient from "../../../store/hooks/useReduxCreateClient";
import ModalHeader from "../components/ModalHeader";
import ModalButtons from "../components/ModalButtons";

export interface iContinueClientModal {
    open: boolean;
    setContinueClient: any;
}

const ContinueClientModal = ({
    setContinueClient,
    open,
}: iContinueClientModal) => {
    const { setData } = useReduxCreateClient();
    const [caseId, setCaseId] = useState(0);
    const closeModal = () => setContinueClient("");

    const { fetchData, isLoading } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client/${caseId}`,
        },
        {
            navigateOnSuccess: `${caseId}`,
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData();
    };

    return (
        <ContinueClientModalWrapper show={open} width="400px">
            <ModalHeader onClose={closeModal}>Continue Client</ModalHeader>
            <form onSubmit={submitHandler}>
                <Input
                    name="caseId"
                    type="number"
                    label="Case ID:"
                    placeholder="Please enter..."
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setCaseId(e.target.valueAsNumber)
                    }
                />
                <hr />
                <ModalButtons>
                    <Button
                        style="purple"
                        btnText="Submit"
                        type="submit"
                        loading={isLoading}
                        disabled={!caseId}
                    />
                </ModalButtons>
            </form>
        </ContinueClientModalWrapper>
    );
};

export default ContinueClientModal;
