import React from "react";
import { CreateDocumentTemplateFormProps } from "../CreateDocumentTemplate";
import { StyledDocumentTemplateForm } from "./styles";
import { Input } from "../../../../../../components";
import TextEditor from "../../../../../../components/TextEditor";
import BodyVariablesSelect from "../../../../../../components/DropdownSelect/BodyVariablesSelect";

const AdHocSettings: React.FC<CreateDocumentTemplateFormProps> = ({
    documentData,
    setDocumentData,
    inputHandler,
}) => {
    const textEditorHandler = (key: string, content: string) => {
        setDocumentData((prev) => {
            return { ...prev, [key]: content };
        });
    };

    return (
        <StyledDocumentTemplateForm className="message-form">
            <Input
                label="Subject:"
                type="text"
                value={documentData?.subject}
                name="subject"
                onChange={inputHandler}
            />
            <BodyVariablesSelect />
            <TextEditor
                label="Body:"
                value={documentData?.body}
                onChange={(content) => textEditorHandler("body", content)}
                name="body"
            />
        </StyledDocumentTemplateForm>
    );
};

export default AdHocSettings;
