import { useAppSelector, useAppDispatch } from ".";
import { clientCorrespondenceActions } from "../slices/clientCorrespondenceSlice";

const useReduxClientCorrespondence = () => {
    const { list, signables, sms, email, files } = useAppSelector(
        (state) => state["client-correspondence"]
    );
    const dispatch = useAppDispatch();

    const setList = (data: { [key: string]: any }) => {
        dispatch(clientCorrespondenceActions.setList(data));
    };
    const setSignables = (data: Array<{ [key: string]: any }>) => {
        dispatch(clientCorrespondenceActions.setSignables(data));
    };
    const setSms = (data: { [key: string]: any }) => {
        dispatch(clientCorrespondenceActions.setSms(data));
    };
    const setEmail = (data: { [key: string]: any }) => {
        dispatch(clientCorrespondenceActions.setEmail(data));
    };
    const setFiles = (data: Array<{ [key: string]: any }>) => {
        dispatch(clientCorrespondenceActions.setFiles(data));
    };

    const resetSlice = () => {
        dispatch(clientCorrespondenceActions.resetSlice());
    };

    return {
        list,
        setList,
        signables,
        setSignables,
        resetSlice,
        sms,
        setSms,
        email,
        setEmail,
        files,
        setFiles,
    };
};

export default useReduxClientCorrespondence;
