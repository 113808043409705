import React, { useState, SetStateAction } from "react";
import StyledCreditorSettings from "./styles";
import Header from "../../../layout/Header";
import { PageContent } from "../../../pages/styles";
import PageTabs from "../../../components/PageTabs/PageTabs";
import { Button } from "../../../components";
import CreditorDetails from "./CreditorDetails";
import CreditorKeyEvents from "./CreditorKeyEvents";
import CreditorBankDetails from "./CreditorBankDetails";
import CreditorClientList from "./CreditorClientList";
import useFetchData from "../../../global/hooks/useFetchData";
import { useParams } from "react-router-dom";
import DeleteCreditor from "../../../components/Modals/Creditor/DeleteCreditor";
import SendClientLetters from "../../../components/Modals/Creditor/SendClientLetters";
import TransferDebts from "../../../components/Modals/Creditor/TransferDebts";
import { LoadingSpinner } from "../../../components/Button/styles";
import useReduxCreditors from "../../../store/hooks/useReduxCreditors";

export interface CreditorFormProps {
    creditorData: { [key: string]: any };
    setCreditorData: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
}

const CreditorSettings = () => {
    const { creditorId } = useParams();
    const { setList, list } = useReduxCreditors();
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [currentModal, setCurrentModal] = useState("");
    const [creditorData, setCreditorData] = useState<{ [key: string]: any }>(
        {}
    );
    const [clientList, setClientList] = useState<Array<{ [ket: string]: any }>>(
        []
    );
    const [creditorSelect, setCreditorSelect] = useState<
        Array<{ [ket: string]: any }>
    >([]);
    const [documents, setDocuments] = useState<Array<{ [ket: string]: any }>>(
        []
    );

    const creditorCount = clientList?.length || 0;

    const creditorPageTabs = [
        "Details",
        "Key Events",
        "Bank Details",
        "Client List",
    ];

    const getCreditor = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/creditor/${creditorId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data?.creditors) {
                    const { client, ...formData } =
                        data?.data?.creditors[0] || {};
                    setCreditorData(formData);
                    console.log(data);
                    setClientList(data?.data?.creditors[0].client || []);
                    setCreditorSelect(data?.data?.creditors[0].creditors || []);
                    setDocuments(data?.data?.creditors[0].documents || []);
                }
            },
        }
    );

    const creditorFormProps = {
        creditorData,
        setCreditorData,
    };
    const openModalHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        setCurrentModal(e.currentTarget.name);
    };

    //console.log(creditorData);

    const updateCreditor = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/creditor/${creditorId}`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Creditor Details Updated Sucessfully",
            onSuccess: (data) => {
                if (data?.data?.creditors) {
                    const { client, ...formData } =
                        data?.data?.creditors[0] || {};
                    setCreditorData(formData);
                    setClientList(client || []);
                    setList(client || []);
                }
            },
        }
    );
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const {
            updatedBy,
            updated_at,
            updated_by,
            createdBy,
            created_at,
            ...formData
        } = creditorData;
        console.log(formData);
        updateCreditor.fetchData(formData);
    };

    return (
        <>
            <DeleteCreditor
                isOpen={currentModal === "delete"}
                setCurrentModal={setCurrentModal}
                creditorId={creditorId}
            />
            <SendClientLetters
                isOpen={currentModal === "client-letters"}
                setCurrentModal={setCurrentModal}
                data={documents}
            />
            <TransferDebts
                isOpen={currentModal === "transfer-debts"}
                setCurrentModal={setCurrentModal}
                data={creditorSelect}
                id={creditorId}
            />
            <Header
                title="Creditor Settings"
                isSubPath
                prevPath="/creditor/creditors"
            />
            <PageContent>
                <StyledCreditorSettings className="creditor-settings">
                    <div className="creditor-settings__info">
                        <div className="creditor-settings__tabs">
                            <PageTabs
                                tabs={creditorPageTabs}
                                notifications={[
                                    null,
                                    null,
                                    null,
                                    creditorCount,
                                ]}
                                selectedTabIndex={currentTabIndex}
                                setSelectedTabIndex={setCurrentTabIndex}
                            />
                        </div>
                        {getCreditor.isLoading ? (
                            <LoadingSpinner size={40} />
                        ) : (
                            <div className="creditor-settings__content">
                                {currentTabIndex === 0 && (
                                    <CreditorDetails {...creditorFormProps} />
                                )}
                                {currentTabIndex === 1 && (
                                    <CreditorKeyEvents {...creditorFormProps} />
                                )}
                                {currentTabIndex === 2 && (
                                    <CreditorBankDetails
                                        {...creditorFormProps}
                                    />
                                )}
                                {currentTabIndex === 3 && (
                                    <CreditorClientList
                                        clientList={clientList}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="creditor-settings__actions">
                        <form
                            className="creditor-settings__actions__form"
                            onSubmit={submitHandler}
                        >
                            <h4>Creditor</h4>
                            <p>
                                {creditorData
                                    ? creditorData["Creditor Name"]
                                    : ""}
                            </p>
                            <div className="creditor-settings__actions__btns">
                                <Button
                                    className="creditor-settings__actions__btn"
                                    style="purple"
                                    type="submit"
                                    btnText="Save"
                                    loading={updateCreditor.isLoading}
                                />
                                <button
                                    className="creditor-settings__actions__btn"
                                    type="button"
                                    name="client-letters"
                                    onClick={openModalHandler}
                                >
                                    Client Letters
                                </button>
                                <button
                                    className="creditor-settings__actions__btn"
                                    type="button"
                                    name="transfer-debts"
                                    onClick={openModalHandler}
                                >
                                    Transfer Debts
                                </button>
                                <button
                                    className="creditor-settings__actions__btn"
                                    type="button"
                                    name="delete"
                                    onClick={openModalHandler}
                                >
                                    Delete
                                </button>
                            </div>
                        </form>
                    </div>
                </StyledCreditorSettings>
            </PageContent>
        </>
    );
};

export default CreditorSettings;
