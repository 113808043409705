import DropdownMenu from "../../../components/DropdownMenu";
import DropdownMeunItem from "../../../components/DropdownMenu/DropdownMenuItem";
import useReduxUser from "../../../store/hooks/useReduxUser";
import testJPG from "../../../assets/images/testJPG.jpg";
import { viewUploadOnNewWindow } from "../../../global/utils/helpers";
import useFetchData from "../../../global/hooks/useFetchData";
import { useParams } from "react-router-dom";
interface ViewUploadProps {}
const ViewUpload: React.FC<ViewUploadProps> = () => {
    const { user, clientCase } = useReduxUser();
    const testUpload = {
        loa: testJPG,
        client_loa: testJPG,
        wet_loa: "",
        solicitor_loa: "",
        death_cert: "",
    };
    const clientCaseUploads = { ...testUpload };

    const hasUpload = Object.values(testUpload).some((v) => !!v);

    const itemTitles = (key: string) => {
        if (key === "loa") return "Digital LOA";
        if (key === "client_loa") return "Client ID";
        if (key === "wet_loa") return "WET LOA";
        if (key === "solicitor_loa") return "Solicitor LOA";
        if (key === "death_cert") return "Death Certificate";
        return "";
    };

    const { clientId } = useParams();
    const updateApplicants = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/view-upload/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) {
                    //viewUploadOnNewWindow(decodeURIComponent(data?.data) as string)
                    viewUploadOnNewWindow(data?.data as string);
                }
            },
        }
    );
    const clickEventHandler = (key: any) => {
        updateApplicants.fetchData({ data: key });
        //viewUploadOnNewWindow(key as string)
    };
    return (
        <DropdownMenu title="View" color="mint" isHidden={!hasUpload}>
            {Object.entries(clientCaseUploads).map(([key, value]) =>
                !!value ? (
                    <DropdownMeunItem
                        key={key}
                        elementType="button"
                        title={itemTitles(key)}
                        iconName="image"
                        //onClick={() => viewUploadOnNewWindow(value as string)}
                        onClick={() => clickEventHandler(key as string)}
                        colorMode="odd"
                    />
                ) : null
            )}
        </DropdownMenu>
    );
};

export default ViewUpload;
