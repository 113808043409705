import React, { useEffect, useState } from "react";
import { TableRowProps } from "..";
import { ColoredBlock } from "../styles";
import { StyledActionIconBtn } from "../../styles";
import { Modal } from "../../..";
import { ReactComponent as ViewIcon } from "../../../../assets/icons/viewCase.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/purpleUpload2.svg";
import SignableDetails from "../../../Modals/Correspondence/SignableDetails";
import useFetchData from "../../../../global/hooks/useFetchData";
import { formatTimeString } from "../../../../global/utils/helpers";
import { Loading } from "notiflix";

const SignablesRow: React.FC<TableRowProps> = ({ data }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const statusColor = (value: string | number) => {
        if (value === "uploaded") return "green";
        if (value === "cancelled") return "red";
        return "";
    };

    const uploadData = useFetchData(
        {
            method: "post",
            url: `${
                process.env.REACT_APP_SERVER_API
            }/client-case/signable-upload/${data?.id || ""}`,
        },
        {
            onSuccess: (data) => {},
        }
    );
    const uploadHandler = () => {
        uploadData.fetchData();
    };

    useEffect(() => {
        if (uploadData.isLoading) {
            Loading.standard();
        } else {
            Loading.remove();
        }
    }, [uploadData.isLoading]);

    return (
        <>
            <Modal isVisible={isModalOpen}>
                <SignableDetails
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    data={data}
                />
            </Modal>
            <tr>
                <td>{data?.id ?? "-"}</td>
                <td>{data?.document_title ?? "-"}</td>
                <td>{data?.signable_fingerprint ?? "-"}</td>
                <td>{formatTimeString(data?.created_at || "")}</td>
                <ColoredBlock color={statusColor(data?.signable_status) || ""}>
                    {data?.signable_status ?? "-"}
                </ColoredBlock>
                <td className="td--action">
                    <div>
                        <StyledActionIconBtn
                            type="button"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <ViewIcon />
                        </StyledActionIconBtn>
                        <StyledActionIconBtn
                            type="button"
                            onClick={uploadHandler}
                        >
                            <UploadIcon />
                        </StyledActionIconBtn>
                    </div>
                </td>
            </tr>
        </>
    );
};
export default SignablesRow;
