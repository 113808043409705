import { DropdownSelectValue } from "."

export class DropdownSelectOption {
    value: number | string | undefined ;
    label: string;
    isGroupHeader?: boolean
    constructor(value: number | string | undefined, label: string, isGroupHeader: boolean = false) {
        this.value = value;
        this.label = label;
        this.isGroupHeader = isGroupHeader;
    }
}

class TimePeriodOption extends DropdownSelectOption {
    multiplier: number;
    constructor(value: number | string | undefined, label: string, multiplier: number, isGroupHeader: boolean = false) {
        super(value, label, isGroupHeader);
        this.multiplier = multiplier;
    }
}

export const valueArrayToOptions = (arr: (number | string)[]) => {
    const output: DropdownSelectOption[] = []
    arr.forEach(value => output.push(new DropdownSelectOption(value, value.toString())))
    return output
}

export const generateTestOptions = (count: number = 3) => {
    return Array(count).fill('').map((_, i) => new DropdownSelectOption(i+1, `Option ${i+1}`))
}

export const statusOptions = [
    new DropdownSelectOption(undefined, 'All'), 
    new DropdownSelectOption(0, 'New'), 
    new DropdownSelectOption(1, 'In Process'),
    new DropdownSelectOption(2, 'Completed'),
    new DropdownSelectOption(3, 'Canceled')]

export const importanceOptions = [
    new DropdownSelectOption(1, 'Low'),
    new DropdownSelectOption(2, 'Medium'),
    new DropdownSelectOption(3, 'High')]

export const titleOptions = [
        new DropdownSelectOption('Mr', 'Mr'),
        new DropdownSelectOption('Mrs', 'Mrs'),
        new DropdownSelectOption('Ms', 'Ms'),
        new DropdownSelectOption('Miss', 'Miss'),
        new DropdownSelectOption('Dr.', 'Dr.'),
        new DropdownSelectOption('Rev.', 'Rev.'),
        new DropdownSelectOption('Mx', 'Mx'),
]

export const preferredContactMethodOptions = [
    new DropdownSelectOption('ALL', 'All'),
    new DropdownSelectOption('Phone', 'Phone'),
    new DropdownSelectOption('SMS', 'SMS'),
    new DropdownSelectOption('EMAIL', 'Email'),
]

export const employmentStatusOptions = [
    new DropdownSelectOption('unemployed', 'Unemployed'),
    new DropdownSelectOption('self-emplpoyed', 'Self Emplpoyed'),
    new DropdownSelectOption('employed', 'Employed'),
    new DropdownSelectOption('limited', 'Limited Company'),
]

export const martialStatusOptions = [
    new DropdownSelectOption(1, 'Single'),
    new DropdownSelectOption(2, 'Married'),
    new DropdownSelectOption(3, 'Living Together'),
    new DropdownSelectOption(4, 'Living Apart'),
    new DropdownSelectOption(5, 'Divorced'),
    new DropdownSelectOption(6, 'Widow'),
    new DropdownSelectOption(99, 'Unknown'),
]

export const partnerUnawareOptions = [
    new DropdownSelectOption(1, 'Unaware'),
    new DropdownSelectOption(0, 'Aware'),
]

export const personalBudgetingTypeOptions = [
    new DropdownSelectOption("I", 'Income'),
    new DropdownSelectOption("E", 'Expenditure'),
    // new DropdownSelectOption("A", 'Invoice & Receipts'),
]

export const incomeExpenditureTypeOptions = [
    new DropdownSelectOption("I", 'Income'),
    new DropdownSelectOption("E", 'Expenditure'),
]

export const timePeriodOptions = [
    new TimePeriodOption('daily', 'Daily', 1/30.44),
    new TimePeriodOption('weekly', 'Weekly', 7/30.44),
    new TimePeriodOption('fortnightly', 'Fortnightly', 14/30.44),
    new TimePeriodOption('four-Weekly', 'Four-Weekly', 28/30.44),
    new TimePeriodOption('monthly', 'Monthly', 1),
    new TimePeriodOption('quarterly', 'Quarterly', 3),
    new TimePeriodOption('six-Monthly', 'Six-Monthly', 6),
    new TimePeriodOption('yearly', 'Yearly', 12),
]

export const incomeNameOptions = valueArrayToOptions([
    'Wages', 
    "Partner salary",
    "Part-time wages",
    "Self Employed",
    "Universal Credit",
    "Jobseeker's Allowance (JSA)",
    "Child benefits",
    "Child tax credit",
    "Working tax credit",
    "Child Maintenance",
    "Maternity benefit",
    "Personal Independence Payment(PIP)",
    "Carers Allowance",
    "Income support",
    "State pension",
    "Private pension",
    "Employment & Support Allowance (ESA)", 
    "Child Support (CSA)",
    "Disability Living Allowance (DLA)",
    "Housing Benefit"
])

export const expenditureNameOptions = [
    new DropdownSelectOption("HOUSING & UTILITY BILLS", 'HOUSING & UTILITY BILLS', true),
    ...valueArrayToOptions([
    'Rent',
    'Mortgage', 
    'Secured loan (other than your mortgage)', 
    'subs',
    'Council tax',
    'Gas',
    'Electricity',
    'Mortgage endowment premium',
    'Service charge or ground rent'
]),
    new DropdownSelectOption("HOUSEHOLD SERVICES", 'HOUSEHOLD SERVICES', true),
    new DropdownSelectOption('Buildings & contents insurance', 'Buildings & contents insurance'),
    new DropdownSelectOption('Telephone and internet', 'Telephone and internet'),
    new DropdownSelectOption('TV licence', 'TV licence'),
    new DropdownSelectOption('Satellite or cable TV', 'Satellite or cable TV'),
    new DropdownSelectOption('Repairs, service contracts', 'Repairs, service contracts'),
    new DropdownSelectOption('Appliance rental', 'Appliance rental'),
    new DropdownSelectOption('Child support', 'Child support (paid by you)'),
    new DropdownSelectOption('Childcare', 'Childcare'),
    new DropdownSelectOption('Life insurance', 'Life insurance'),
    new DropdownSelectOption('private pension', 'private pension'),
    new DropdownSelectOption('Medical or accident insurance', 'Medical or accident insurance'),
    new DropdownSelectOption("TRAVEL", 'TRAVEL', true),
    new DropdownSelectOption('Spares & servicing', 'Spares & servicing'),
    new DropdownSelectOption('Road tax', 'Road tax'),
    new DropdownSelectOption('Car insurance', 'Car insurance'),
    new DropdownSelectOption('Breakdown cover', 'Breakdown cover'),
    new DropdownSelectOption('Fuel & parking', 'Fuel & parking'),
    new DropdownSelectOption('Public transport', 'Public transport'),
    new DropdownSelectOption("FOOD & HOUSEKEEPING", 'FOOD & HOUSEKEEPING', true),
    new DropdownSelectOption('Food, toiletries & cleaning products', 'Food, toiletries & cleaning products'),
    new DropdownSelectOption('School meals', 'School meals'),
    new DropdownSelectOption('Meals at work', 'Meals at work'),
    new DropdownSelectOption('Pets', 'Pets (food & insurance)'),
    new DropdownSelectOption('Tobacco', 'Tobacco'),
    new DropdownSelectOption('Clothing & footwear', 'Clothing & footwear'),
    new DropdownSelectOption("OTHER SERVICES", 'OTHER SERVICES', true),
    new DropdownSelectOption('School trips & activities', 'School trips & activities'),
    new DropdownSelectOption('Medicines & prescriptions', 'Medicines & prescriptions'),
    new DropdownSelectOption('Dentist & opticians', 'Dentist & opticians'),
    new DropdownSelectOption('Hairdressing', 'Hairdressing'),
    new DropdownSelectOption('Professional or union fees', 'Professional or union fees'),
    new DropdownSelectOption('Education fees', 'Education fees'),
    new DropdownSelectOption('Laundry or dry cleaning', 'Laundry or dry cleaning'),
    new DropdownSelectOption("PERSONAL & LEISURE", 'PERSONAL & LEISURE', true),
    new DropdownSelectOption('Newspapers & magazines', 'Newspapers & magazines'),
    new DropdownSelectOption('Sports, hobbies & entertainment', 'Sports, hobbies & entertainment'),
    new DropdownSelectOption("Children's pocket money", "Children's pocket money"),
    new DropdownSelectOption('Church or charity donations', 'Church or charity donations'),
    new DropdownSelectOption('Sundries & emergencies', 'Sundries & emergencies'),
]

export const accountHolderOptions = [
    new DropdownSelectOption(1, 'APP1'),
    new DropdownSelectOption(2, 'APP2'),
    new DropdownSelectOption(3, 'Joint'),
]

export const creditTypeOptions = [
    new DropdownSelectOption(0, 'Unspecified'),
    new DropdownSelectOption(1, 'Credit card'),
    new DropdownSelectOption(2, 'Unsecured loan'),
    new DropdownSelectOption(3, 'Store card'),
    new DropdownSelectOption(4, 'HP'),
    new DropdownSelectOption(5, 'Arrears'),
    new DropdownSelectOption(7, 'Catalog'),
    new DropdownSelectOption(8, 'Solicitor'),
    new DropdownSelectOption(9, 'Debt collector'),
    new DropdownSelectOption(10, 'Mobile phone'),
    new DropdownSelectOption(11, 'Overdraft'),
    new DropdownSelectOption(6, 'Other'),
]

export const paymentFrequencyOptions = [
    new DropdownSelectOption(0, 'Monthly'),
    new DropdownSelectOption(1, 'Weekly'),
    new DropdownSelectOption(2, 'Fortnightly'),
    new DropdownSelectOption(3, 'Four weekly'),
    new DropdownSelectOption(4, 'Last Working Day'),
]

export const paymentDayOptions = Array(31).fill('').map((_, i) => new DropdownSelectOption(i+1, `${i+1}`),)

export const paymentMethodOptions = [
    new DropdownSelectOption(5, 'Debit Card'),
    new DropdownSelectOption(13, 'Direct Debit/Bank Transfer'),
]

export const cardCapturedOptions = [
    new DropdownSelectOption(0, 'No'),
    new DropdownSelectOption(1, 'Yes'),
]

export const preferCorMethodOptions = [
    new DropdownSelectOption(1, 'POST'),
    new DropdownSelectOption(2, 'EMAIL'),
]

export const requireWetLOAOptions = [
    new DropdownSelectOption(0, 'No'),
    new DropdownSelectOption(1, 'Yes'),
]

export const creditorStatusOptions = [
    new DropdownSelectOption(0, 'Unverified'),
    new DropdownSelectOption(1, 'Verified'),
    new DropdownSelectOption(2, 'DO NOT USE'),
]

export const userDepartmentOptions = [
    new DropdownSelectOption(1.0, 'Sales'),
    new DropdownSelectOption(2.0, 'Back Office'),
    new DropdownSelectOption(2.1, 'Back Office - IP2'),
    new DropdownSelectOption(2.2, 'Back Office - IP3'),
    new DropdownSelectOption(2.3, 'Back Office - IP4'),
    new DropdownSelectOption(2.4, 'Back Office - IP4 & IP5'),
    new DropdownSelectOption(2.5, 'Back Office - APF'),
    new DropdownSelectOption(2.6, 'Back Office - Credit Control'),
    new DropdownSelectOption(2.7, 'Back Office - FAF'),
    new DropdownSelectOption(3.0, 'Managment'),
]

export const userTypeOptions = [
    new DropdownSelectOption(1, 'Adminstrator'),
    new DropdownSelectOption(2, 'Staff'),
    new DropdownSelectOption(3, 'Introducer'),
]

export const userDisableOptions = [
    new DropdownSelectOption(1, 'Adminstrator'),
    new DropdownSelectOption(2, 'Staff'),
    new DropdownSelectOption(3, 'Introducer'),
]

export const fieldForDateOptions = [
    new DropdownSelectOption('next_pmnt_in_dt', 'Next Payment Date'),
    new DropdownSelectOption('keFrstPmntIn', 'IP1 Paid'),
    new DropdownSelectOption('keScndPmntIn', 'IP2 Paid'),
    new DropdownSelectOption('keAdmnFeeIn', 'IP3 Paid'),
    new DropdownSelectOption('kedmpFee4tkn', 'IP4 Paid'),
    new DropdownSelectOption('kedmpFee5tkn', 'IP5 Paid'),
]

export const departmentOptions = [
    new DropdownSelectOption('ALL', 'All'),
    new DropdownSelectOption('SALES', 'Sales'),
    new DropdownSelectOption('BACKOFFICE', 'Back Office'),
]

export const mailUploadActionOptions = [
    new DropdownSelectOption('mark-inactive', 'Mark Inactive'),
    new DropdownSelectOption('Withheld-Payments', 'Withheld Payments'),
    new DropdownSelectOption('ProposalPaymentAccptd', 'Proposal Payment Accepted'),
    new DropdownSelectOption('clntRspnsble', 'Client Responsible'),
    new DropdownSelectOption('confirm-balances', 'Confirmed Balances'),
    new DropdownSelectOption('accepted-proposal', 'Accepted Proposal'),
    new DropdownSelectOption('declined-proposal', 'Declined Proposal'),
    new DropdownSelectOption('Full-and-final-accepted', 'Accepted Full&Final'),
    new DropdownSelectOption('Accepted-CCR', 'CCR Accepted'),
    new DropdownSelectOption('Transfer-debt-to-original-creditor', 'Transfer To original creditor'),



]

export const workflowAreaOptions = [
    new DropdownSelectOption('case', 'Case'),
    new DropdownSelectOption('create', 'Create'),
    new DropdownSelectOption('debt', 'Debt'),
    new DropdownSelectOption('ledger', 'Ledger'),
]

export const documentTypeOptions = [
    new DropdownSelectOption('client', 'Client'),
    new DropdownSelectOption('debt', 'Single Debt'),
    new DropdownSelectOption('debts', 'Debts'),
]

export const documentSendToOptions = [
    new DropdownSelectOption('save', 'Save Only'),
    new DropdownSelectOption('email', 'Email'),
    new DropdownSelectOption('printer', 'Printer'),
    new DropdownSelectOption('signable', 'Signable'),
]

export const documentSignablePageOptions = [
    new DropdownSelectOption(0, 'Nones'),
    ...valueArrayToOptions(Array(15).fill('').map((el, i) => i+1))
]

export const serviceActiveOptions = [
    new DropdownSelectOption(0, 'Not Active'),
    new DropdownSelectOption(1, 'Active'),
]

export const paymentGatewayModeOptions = [
    new DropdownSelectOption('secure', 'Live'),
    new DropdownSelectOption('secure-test', 'Test'),
]

export const leadStatusGroupOptions = [
    new DropdownSelectOption(1, 'New'),
    new DropdownSelectOption(2, 'Converted'),
    new DropdownSelectOption(3, 'Call Back'),
    new DropdownSelectOption(4, 'Closed'),
]

export const pbsOptions = [
    new DropdownSelectOption(0, 'No'),
    new DropdownSelectOption(1, 'Yes'),
]

export const isActiveOptions = [
    new DropdownSelectOption(0, 'No'),
    new DropdownSelectOption(1, 'Yes'),
]

export const tvActiveOptions = [
    new DropdownSelectOption(0, 'No'),
    new DropdownSelectOption(1, 'Yes'),
]

export const clientCaseStatusOptions = [
    new DropdownSelectOption(-1, 'Inactive'),
    new DropdownSelectOption(0, 'Introducer lead'),
    new DropdownSelectOption(1, 'Enquiry'),
    new DropdownSelectOption(2, 'Signing-up'),
    new DropdownSelectOption(3, 'Client'),
    new DropdownSelectOption(4, 'OverDue Payment'),
    new DropdownSelectOption(5, 'Dead'),
    new DropdownSelectOption(6, 'Cancelled'),
    new DropdownSelectOption(7, 'Completed'),
    new DropdownSelectOption(8, 'So Pack Sent'),
    new DropdownSelectOption(10, 'Account On Hold'),
    new DropdownSelectOption(11, 'Credit Control'),
    new DropdownSelectOption(50, 'FAF Control'),
    new DropdownSelectOption(12, 'AOH - COVID 19'),
    new DropdownSelectOption(13, 'Pending Cancellation'),
]

export const clientCaseSubStatusOptions = valueArrayToOptions(['Paid', 'Canceled'])

export const caseActionsSendToDurationOptions = [
    new DropdownSelectOption(12, '12 Months'),
    new DropdownSelectOption(15, '15 Months'),
    new DropdownSelectOption(18, '18 Months'),
]

export const reasonForCancellationOptions = valueArrayToOptions([
    'Dealing Direct (Themselves)', 
    'Family Help', 
    'Gone to Free Service',
    'Unable to Afford',
    'Complaint',
    'Old DC Client',
    'Credit Control',
    'Poor Customer Service',
    "Wasn't Aware of Fees",
    'IVA - External',
    'Old Account',
    'Issue with Fee/Plan',
    'DRO Bankruptcy',
    'Out of colling off period',
    'Gone to Solicitors',
    'Transferred to AFA',
    'Gone To A Different Provider'

])

export const cancellationFeeTypeOptions = valueArrayToOptions(['Full', 'Partial'])

export const clientServiceOptions = [
    new DropdownSelectOption(101, 'Personal Accountancy'),
    new DropdownSelectOption(102, 'Business Accountancy'),
    new DropdownSelectOption(5, 'Personal Budgeting Service'),
    new DropdownSelectOption(4, 'Creditor Claims Review'),
    new DropdownSelectOption(2, 'IVA'),
]

export const clientPlanOptions = [
    new DropdownSelectOption(10, 'PBS'),
    new DropdownSelectOption(11, 'APF'),
    new DropdownSelectOption(12, 'CCR'),
    new DropdownSelectOption(13, 'Review'),
]

export const clientPBSTypeOptions = valueArrayToOptions([
    'Gas', 
    'Electricity', 
    'Life Insurance',
    'Car Insurance',
    'Home Insurance',
    'TV Package',
    'Home Phone & Broadband',
    'Mobile Phone',
    "Pet Insurance",
    'Funeral care insurance',
    'Private Health Care insurance'
])

export const payeRebateStatusOptions = valueArrayToOptions([
    'Pack Out', 
    'Pack Back', 
    'Awaiting UTR',
    'In Preparation',
    'Claim Submitted',
    'Claim Completed',
    'Claim Completed And Funds Submitted',
    'Awaiting Further Information',
])

export const yesNoValueOptions = valueArrayToOptions(['Yes', 'No'])

export const ledgerTypeOptions = [
    new DropdownSelectOption(0, 'Initial fee (IP1)'),
    new DropdownSelectOption(1, 'Full and final paid'),
    new DropdownSelectOption(2, 'Fund for full and final'),
    new DropdownSelectOption(3, 'Ad-hoc payment for client (eg N245)'),
    new DropdownSelectOption(4, 'Other payment received'),
    new DropdownSelectOption(5, 'Other payment made'),
    new DropdownSelectOption(6, 'Partial Initial Payment'),
    new DropdownSelectOption(7, 'Management fee'),
    new DropdownSelectOption(8, 'Write-off fund'),
    new DropdownSelectOption(9, 'Bond'),
    new DropdownSelectOption(10, 'Creditor setup fee'),
    new DropdownSelectOption(11, 'Token payment counter booking'),
    new DropdownSelectOption(12, 'Fund for token payment'),
    new DropdownSelectOption(13, 'Second Initial Payment (IP2)'),
    new DropdownSelectOption(14, 'Annual review fee'),
    new DropdownSelectOption(15, 'Third Initial Payment (IP3)'),
    new DropdownSelectOption(16, 'Set-up fee instalment'),
    new DropdownSelectOption(17, 'Fourth Initial Payment (IP4)'),
    new DropdownSelectOption(18, 'Fifth Initial Payment (IP5)'),
    new DropdownSelectOption(19, 'FAF Payment'),
    new DropdownSelectOption(20, 'Add Creditor Payment'),
    new DropdownSelectOption(21, 'APF Payment'),
    new DropdownSelectOption(22, 'PAYE Rebate Refund'),
    new DropdownSelectOption(23, 'Rebate HMRC Funds In'),
    new DropdownSelectOption(24, 'Rebate Fee'),
]

export const ipWorkflowOptions = [
    new DropdownSelectOption('ldgr.ip1.payed', 'IP1 Workflow'),
    new DropdownSelectOption('ldgr.ip2.payed', 'IP2 Workflow'),
    new DropdownSelectOption('ldgr.ip3.payed', 'IP3 Workflow'),
    new DropdownSelectOption('ldgr.ip4.payed', 'IP4 Workflow'),
    new DropdownSelectOption('ldgr.ip5.payed', 'IP5 Workflow'),
    new DropdownSelectOption('ldgr.APF.payed', 'APF Workflow'),
    new DropdownSelectOption('ldgr.r2.payed', 'Review Workflow'),
]

export const correspondenceDirectionOptions = [
    new DropdownSelectOption('in', 'In'),
    new DropdownSelectOption('out', 'Out'),
]

export const correspondenceDocTypeOptions = [
    new DropdownSelectOption('client', 'Client'),
    new DropdownSelectOption('debt', 'Single / Multiple Debt'),
    new DropdownSelectOption('debts', 'All Debts'),
]

export const introducerTypeOptions = [
    new DropdownSelectOption(1, 'Internet (Our site)'),
    new DropdownSelectOption(3, 'Hotkey'),
    new DropdownSelectOption(4, 'Loan Decline'),
    new DropdownSelectOption(5, 'Web Lead'),
    new DropdownSelectOption(6, 'Other'),    
]







