import React from "react";
import { CreateDocumentTemplateFormProps } from "../CreateDocumentTemplate";
import { StyledGeneralSettings } from "./styles";
import { Input } from "../../../../../../components";

const RequirementsSettings: React.FC<CreateDocumentTemplateFormProps> = ({
    documentData,
    checkboxHandler,
}) => {
    return (
        <StyledGeneralSettings
            title="Document Requirements"
            headerStyle="gradientBlue"
            headingElement="h2"
        >
            <div>
                <ul className="info">
                    <li className="checkboxes">
                        Include:
                        <div className="checkbox-ctn">
                            <Input
                                className="checkbox"
                                type="checkbox"
                                label="Debts"
                                checked={!!documentData?.debts_need}
                                onChange={checkboxHandler}
                                name="debts_need"
                            />
                            <Input
                                className="checkbox"
                                type="checkbox"
                                label="Income & Expeenditure"
                                checked={!!documentData?.iea_need}
                                onChange={checkboxHandler}
                                name="iea_need"
                            />
                            <Input
                                className="checkbox"
                                type="checkbox"
                                label="Client Authority Form"
                                checked={!!documentData?.loa_need}
                                onChange={checkboxHandler}
                                name="loa_need"
                            />
                            <Input
                                className="checkbox"
                                type="checkbox"
                                label="Pre-Address List"
                                checked={!!documentData?.preaddresss_need}
                                onChange={checkboxHandler}
                                name="preaddresss_need"
                            />
                            <Input
                                className="checkbox"
                                type="checkbox"
                                label="Save Document as PDF"
                                checked={!!documentData.saveasPDF}
                                onChange={checkboxHandler}
                                name="saveasPDF"
                            />
                        </div>
                    </li>
                    <hr />
                    <li className="checkboxes">
                        AD-HOC Documents:
                        <div className="checkbox-ctn">
                            <Input
                                className="checkbox"
                                type="checkbox"
                                label="Subject"
                                checked={!!documentData?.subject_need}
                                onChange={checkboxHandler}
                                name="subject_need"
                            />
                            <Input
                                className="checkbox"
                                type="checkbox"
                                label="Body"
                                checked={!!documentData?.body_need}
                                onChange={checkboxHandler}
                                name="body_need"
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </StyledGeneralSettings>
    );
};

export default RequirementsSettings;
