import React, {
    useState,
    useMemo,
    SetStateAction,
    useEffect,
    useDeferredValue,
    useId,
    useRef,
} from "react";
import { LoadingSpinner } from "../Button/styles";
import StyledDataTable from "./styles";
import SortButton from "./SortButton";
import { orderBy } from "lodash";
import DefaultTableRow, { TableRowProps } from "./TableRows";
import { filterObjectArrayByKeys } from "../../global/utils/helpers";
import { ErrorBoundary } from "react-error-boundary";
import TableError from "./TableError";
import Pagination from "./Pagination";
import SumRow from "./TableRows/SumRow";

export type SortMode = "asc" | "desc" | "none";
export type FormatType = "date" | "time" | "currency" | "html";
export const sortModes: SortMode[] = ["none", "asc", "desc"];
export type HasCheckBoxFunc = (data: { [key: string]: any }) => boolean;

interface DataTableProps {
    className?: string;
    labels?: string[];
    keys?: string[];
    data: { [key: string]: any }[];
    dataKey?: string;
    hideColums?: number[];
    tableRow?: React.FC<TableRowProps>;
    columnWidths?: string[];
    hasCheckbox?: boolean | HasCheckBoxFunc;
    isLoading?: boolean;
    enableSorting?: boolean;
    selectedIds?: number[];
    setSelectedIds?: React.Dispatch<SetStateAction<number[]>>;
    minWidth?: number | string;
    hasAction?: boolean;
    action?: React.FC<any>;
    onActionSuccess?: (...args: any[]) => void;
    searchTerm?: string;
    searchKeys?: string[];
    onRowClick?: (data: { [key: string]: any }) => void;
    dataPerPage?: number;
    formatKeys?: { [key: string]: FormatType };
    sumKeys?: Array<string>;
}

const DataTable: React.FC<DataTableProps> = ({
    className = "",
    hasCheckbox = false,
    labels = [],
    keys = [],
    data,
    dataKey = "id",
    hideColums = [],
    tableRow = DefaultTableRow,
    columnWidths = [],
    isLoading = false,
    enableSorting = true,
    selectedIds = [],
    setSelectedIds = () => {},
    minWidth,
    hasAction = false,
    action,
    onActionSuccess,
    searchTerm = "",
    searchKeys = [],
    onRowClick,
    dataPerPage = 200,
    formatKeys = {},
    sumKeys = [],
}) => {
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const [sortMode, setSortMode] = useState<SortMode>(sortModes[0]);
    const [currentSortKey, setCurrentSortKey] = useState<string | null>(null);
    const [checkedIds, setCheckedIds] = useState<number[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const TableRow = tableRow;
    const mainRef = useRef<HTMLDivElement>(null);
    const id = useId();
    const displayLabels =
        (labels?.length
            ? labels
            : Object.keys(data?.length ? data[0] || {} : {})) || [];

    const checkAllHandler = () => {
        setIsCheckedAll((prev) => !prev);
    };

    const displayDefaultOrder =
        !data || sortMode === "none" || currentSortKey === null;
    const sortedData = useMemo(
        () =>
            displayDefaultOrder
                ? data
                : orderBy(data, `${currentSortKey}`, sortMode),
        [displayDefaultOrder, data, currentSortKey, sortMode]
    );

    const filteredData = useMemo(
        () => filterObjectArrayByKeys(sortedData || [], searchKeys, searchTerm),
        [sortedData, searchKeys, searchTerm]
    );
    const deferredFilteredData = useDeferredValue(filteredData);
    const isDisplayingTable = filteredData?.length;
    const objKeys = Object.keys(data?.length ? data[0] || {} : {});
    const pageData = [...deferredFilteredData].splice(
        (currentPage - 1) * dataPerPage,
        dataPerPage
    );

    useEffect(() => {
        if (checkedIds?.length === 0) setIsCheckedAll(false);
    }, [checkedIds?.length]);

    useEffect(() => {
        setCheckedIds(isCheckedAll ? data.map((obj) => obj[dataKey]) : []);
        if (!isCheckedAll) setSelectedIds([]);
    }, [isCheckedAll]);

    const filteredIdsArr = deferredFilteredData.map((data) => data[dataKey]);
    const filteredIds = checkedIds.filter((id) => filteredIdsArr.includes(id));
    const filteredIdsArrStr = filteredIds.join(",");

    useEffect(() => {
        setSelectedIds(filteredIds);
    }, [filteredIdsArrStr]);

    useEffect(() => {
        if (mainRef?.current) mainRef.current.scrollTo({ top: 0 });
    }, [currentPage]);

    return (
        <ErrorBoundary fallback={<TableError />}>
            <StyledDataTable
                className={`data-table${className ? ` ${className}` : ""}`}
                minWidth={isDisplayingTable ? minWidth : 0}
                hasCheckbox={!!hasCheckbox}
                id={`data-table-${id}`}
                ref={mainRef}
                data-loading={isLoading}
            >
                <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    deferredFilteredData={deferredFilteredData}
                    dataPerPage={dataPerPage}
                    searchTerm={searchTerm}
                />
                <div className="data-table__wrapper">
                    <table className="data-table__table">
                        <thead>
                            <tr>
                                {!filteredData?.length ? (
                                    <th className="th-empty"></th>
                                ) : (
                                    <>
                                        {hasCheckbox ? (
                                            <th
                                                className="table-checkbox"
                                                scope="col"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={isCheckedAll}
                                                    onChange={checkAllHandler}
                                                ></input>
                                            </th>
                                        ) : null}
                                        {displayLabels.map((label, i) => {
                                            return hideColums.includes(
                                                i
                                            ) ? null : (
                                                <th
                                                    key={`${label}-${i}`}
                                                    style={{
                                                        minWidth:
                                                            columnWidths[i],
                                                        maxWidth:
                                                            columnWidths[i],
                                                    }}
                                                >
                                                    <div className="th-ctn">
                                                        {label}
                                                        {enableSorting && (
                                                            <SortButton
                                                                sortKey={
                                                                    keys[i] ||
                                                                    objKeys[i]
                                                                }
                                                                currentSortKey={
                                                                    currentSortKey
                                                                }
                                                                setCurrentSortKey={
                                                                    setCurrentSortKey
                                                                }
                                                                setSortMode={
                                                                    setSortMode
                                                                }
                                                                sortMode={
                                                                    sortMode
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </th>
                                            );
                                        })}
                                        {!!action || hasAction ? (
                                            <th
                                                style={{
                                                    minWidth: "10px",
                                                }}
                                            >
                                                Action
                                            </th>
                                        ) : null}
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td
                                        colSpan={999}
                                        align="center"
                                        className="data-table-row--full"
                                    >
                                        <LoadingSpinner size={40} />
                                    </td>
                                </tr>
                            ) : !!filteredData?.length ? (
                                pageData.map((obj, i) => (
                                    <TableRow
                                        key={obj?.[dataKey] ?? i}
                                        data={obj}
                                        keys={keys}
                                        dataKey={dataKey}
                                        isCheckedAll={isCheckedAll}
                                        selectedIds={checkedIds}
                                        setSelectedIds={setCheckedIds}
                                        hasCheckbox={hasCheckbox}
                                        action={action}
                                        hideColumns={hideColums}
                                        onRowClick={onRowClick}
                                        formatKeys={formatKeys}
                                        onActionSuccess={onActionSuccess}
                                        columnWidths={columnWidths}
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={999}
                                        align="center"
                                        className="data-table-row--full"
                                    >
                                        No data found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        {!!isDisplayingTable && (
                            <tfoot>
                                {!!sumKeys?.length && (
                                    <SumRow
                                        keys={keys}
                                        data={data}
                                        pageData={pageData}
                                        sumKeys={sumKeys}
                                        hasCheckbox={!!hasCheckbox}
                                        hasAction={hasAction}
                                    />
                                )}
                            </tfoot>
                        )}
                    </table>
                </div>
            </StyledDataTable>
        </ErrorBoundary>
    );
};

export default DataTable;
