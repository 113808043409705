import styled from "styled-components";
import { theme } from "../../global/theme";

const StyledUploadPreview = styled.div<{ size: "m" | "s" }>`
    border-radius: ${(props) => (props.size === "s" ? "6px" : "10px")};
    border: 0.6px solid ${theme.colors.shade};
    background-color: ${theme.colors.white};
    padding: ${(props) => (props.size === "s" ? "6px" : "10px")};
    width: ${(props) => (props.size === "s" ? "128px" : "180px")};
    height: ${(props) => (props.size === "s" ? "128px" : "180px")};
    display: flex;
    flex-direction: column;
    & .upload-preview {
        &__file {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            border-radius: ${(props) => (props.size === "s" ? "5px" : "8px")};

            &:hover {
                & .upload-preview__download {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &__image {
            position: absolute;
            inset: 0;
            overflow: hidden;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: inherit;
        }

        &__download {
            position: absolute;
            inset: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0;
            visibility: hidden;
            font-size: 12px;
            backdrop-filter: brightness(70%) blur(2px) contrast(50%);
            border-radius: inherit;
            color: ${theme.colors.white};
            transition: all 0.15s ease-in-out;
            font-weight: 600;
        }

        &__icon {
            height: ${(props) => (props.size === "s" ? "48px" : "")};
            &[data-type="pdf"] {
                & stop {
                    &:nth-of-type(1) {
                        stop-color: #e56f6f;
                    }

                    &:nth-of-type(2) {
                        stop-color: #cb2727;
                    }
                }
            }
        }

        &__type {
            text-transform: uppercase;
            background-clip: text;
            background-size: cover;
            color: transparent;
            -webkit-background-clip: text;
            background-image: ${theme.gradients.skyBlue};
            letter-spacing: 0.02em;
            font-weight: 600;
            margin-top: -2px;
            font-size: ${(props) => (props.size === "s" ? "12px" : "")};

            &[data-type="jpg"],
            &[data-type="jpeg"],
            &[data-type="png"] {
                background-image: ${theme.gradients.burntOrange};
            }
            &[data-type="pdf"] {
                background-image: ${theme.gradients.dangerRed};
            }
        }

        &__info {
            margin-top: 4px;
            display: grid;
            grid-template-columns: 1fr auto;
            font-size: ${(props) => (props.size === "s" ? "9px" : "11px")};
            font-weight: 300;
            gap: 2px 8px;
        }

        &__filename {
            grid-column: 1/-1;
            font-weight: 400;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__size {
        }

        &__date {
        }
    }
`;

export default StyledUploadPreview;
