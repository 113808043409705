import { TableRowProps } from "../";
import parse from "html-react-parser";
import { StyledDebtStatusTd } from "./styles";
import { formatDateString } from "../../../../global/utils/helpers";

const ClientListRow: React.FC<TableRowProps> = ({ data }) => {
    const formattedStatus = parse(data?.["Debt Status"] || "");

    return (
        <tr>
            <td>{data?.["Client Id"] ?? "-"}</td>
            <td>{data?.["Client Name"] ?? "-"}</td>
            <td>{data?.["Wet LOA"] ?? "-"}</td>
            <td>{data?.["Telephone Number"] ?? "-"}</td>
            <td>{data?.["Reference"] ?? "-"}</td>
            <td>{data?.["Debt Level"] ?? "-"}</td>
            <td>{data?.["RePayment Amount"] ?? "-"}</td>
            <td>{data?.["Case Status"] ?? "-"}</td>
            <td>{data?.["PI Status"] ?? "-"}</td>
            <td>{formatDateString(data?.["Review Date"] || "")}</td>
            <StyledDebtStatusTd>{formattedStatus ?? "-"}</StyledDebtStatusTd>
        </tr>
    );
};
export default ClientListRow;
