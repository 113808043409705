import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import { StyledLoaIdsContent } from "./styles";
import HeaderContainer from "../../../containers/HeaderContainter";
import { Button, Input, Modal } from "../../../components";
import useFetchData from "../../../global/hooks/useFetchData";
import CaseActionsUpload from "../../../components/Modals/ClientCase/CaseActions/CaseActionsUpload";
import testJPG from "../../../assets/images/testJPG.jpg";
import {
    formatDateString,
    viewUploadOnNewWindow,
} from "../../../global/utils/helpers";
import useReduxUser from "../../../store/hooks/useReduxUser";
import { StyledBaseDetailsSubmitCtn } from "./styles";
import useReduxClientBaseDetails from "../../../store/hooks/useReduxClientBaseDetails";

const LoaIds = () => {
    const { clientId } = useParams();
    const [uploadType, setUploadType] = useState("");
    const { clientCase } = useReduxUser();
    const { loaIds, setBaseDetailsbyKey } = useReduxClientBaseDetails();
    const [receviedDates, setReceivedDates] = useState<{ [key: string]: any }>({
        digital_loa: "",
        wet_loa: "",
        solicitor_loa: "",
        client_id: "",
    });

    const uploadTypes = [
        {
            title: "Digital LOA: Signables",
            type: "loa_file",
        },
        {
            title: "Wet LOA: Pen",
            type: "wet_loa",
        },
        {
            title: "Solicitor LOA",
            type: "solicitor_loa",
        },
        {
            title: "ID",
            type: "client_loa",
        },
        {
            title: "Death Certificate",
            type: "death_certificate",
        },
    ];

    const getLoaId = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/loa-id/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) {
                    setBaseDetailsbyKey("loaIds", data.data);
                    setReceivedDates(data.data);
                }
            },
        }
    );
    const uploadedFilesURL = getLoaId.data?.data?.uploads;

    const updateLOADates = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/loa-id/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setReceivedDates(data.data);
            },
        }
    );

    useEffect(() => {
        setReceivedDates(loaIds || receviedDates);
    }, [loaIds]);

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setReceivedDates((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(receviedDates);
        updateLOADates.fetchData(receviedDates);
    };
    return (
        <>
            <Modal isVisible={!!uploadType}>
                <CaseActionsUpload
                    type={uploadType}
                    page="loa-ids"
                    isOpen={!!uploadType}
                    onClose={() => setUploadType("")}
                />
            </Modal>
            <Header title="LOA & Id" hasNavBarAbove hasPrevPath />
            <StyledLoaIdsContent>
                <form onSubmit={submitHandler}>
                    {uploadTypes.map((type, i) => (
                        <HeaderContainer
                            title={type.title}
                            headerStyle="gradientBlue"
                            key={i}
                        >
                            <Input
                                type="date"
                                label="Received on:"
                                name={type.type}
                                onChange={changeHandler}
                                value={formatDateString(
                                    receviedDates?.[type.type] || "",
                                    "YYYY-MM-DD HH:mm:ss",
                                    "YYYY-MM-DD"
                                )}
                            />
                            <div className="btns">
                                <Button
                                    style="purple"
                                    btnText="Upload"
                                    type="button"
                                    onClick={() => setUploadType(type.type)}
                                />
                                <Button
                                    style="white"
                                    btnText={"View Upload"}
                                    onClick={() =>
                                        viewUploadOnNewWindow(
                                            uploadedFilesURL?.[type.type] ||
                                                testJPG
                                        )
                                    }
                                    disabled={!uploadedFilesURL?.[type.type]}
                                    type="button"
                                />
                            </div>
                        </HeaderContainer>
                    ))}
                    <StyledBaseDetailsSubmitCtn>
                        <Button
                            type="submit"
                            btnText="Save LOA Receiving Dates"
                            style="purple"
                            loading={updateLOADates.isLoading}
                        />
                    </StyledBaseDetailsSubmitCtn>
                </form>
            </StyledLoaIdsContent>
        </>
    );
};

export default LoaIds;
