import React from "react";

import DataTable from "../../../../components/DataTable";
import RefundCase from "../../../../components/DataTable/actions/RefundCase";
interface RefundsTablesProps {
    data: { [key: string]: any };
    selectedTable: string;
    searchTerm: string;
    isLoading: boolean;
    reloadTables: (...args: any[]) => void;
}

const RefundsTables: React.FC<RefundsTablesProps> = ({
    data,
    selectedTable,
    searchTerm,
    isLoading,
    reloadTables,
}) => {
    // if (selectedTable === "Approved Requests") {
    //     return (
    //         <DataTable
    //             key="approved-requests"
    //             labels={data?.["Approved Requests"]?.headings}
    //             data={data?.["Approved Requests"]?.data}
    //             dataKey="#"
    //             isLoading={isLoading}
    //             minWidth={"1900px"}
    //             action={RefundCase}
    //             searchTerm={searchTerm}
    //             searchKeys={["Case Id", "Customer Name"]}
    //         />
    //     );
    // }
    return (
        <DataTable
            key={selectedTable}
            labels={data?.[selectedTable]?.headings || []}
            data={data?.[selectedTable]?.data || []}
            dataKey="#"
            isLoading={isLoading}
            minWidth={"1900px"}
            action={RefundCase}
            searchTerm={searchTerm}
            searchKeys={["Case Id", "Customer Name"]}
            onActionSuccess={reloadTables}
            formatKeys={{
                "Approved When": "date",
                "Request Reported": "date",
            }}
        />
    );
};

export default RefundsTables;
