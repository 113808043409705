import { PageContent } from "../../../pages/styles";
import { ClientOverview } from "./styles";
import Header from "../../../layout/Header";
import DateButton from "../../../components/Button/DateButton";
import { testClientOverviewData } from "./data";
import DetailCard from "../../../components/Cards/DetailCard";
import {
    StyledDetailCardButton,
    StyledDetailCardLink,
} from "../../../components/Cards/DetailCard/styles";
import useDateSearch from "../../../layout/SlideInMenus/DateSearch/useDateSearch";
import { dateSearchOptions } from "../../../layout/SlideInMenus/DateSearch/data";
import useFetchData from "../../../global/hooks/useFetchData";

const ClientLanding = () => {
    const { top, mid, bottom } = testClientOverviewData;
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today");

    const pushReportHandler = () => {
        alert("Push Report");
    };

    return (
        <>
            <Header title="client dashboard" hasNavBarAbove>
                <DateButton
                    isLoading={false}
                    dateDisplay={dateDisplay}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                />
            </Header>
            <PageContent>
                <ClientOverview className="client-overview">
                    <ul className="client-overview__list">
                        {top.map((data, i) => (
                            <DetailCard
                                key={i}
                                title={data?.title}
                                count={data?.count}
                                buttons={
                                    <StyledDetailCardLink to={data?.path}>
                                        View Details
                                    </StyledDetailCardLink>
                                }
                            ></DetailCard>
                        ))}
                    </ul>
                    {/* <ul className="client-overview__list">
                        {mid.map((data, i) => (
                            <DetailCard
                                key={i}
                                title={data?.title}
                                buttons={
                                    <StyledDetailCardLink to={data?.path}>
                                        View Details
                                    </StyledDetailCardLink>
                                }
                            >
                                <ul className="detail-card__list">
                                    {data?.text && <li>{data?.text}</li>}
                                    <li>
                                        Client Number:{" "}
                                        <span>{data?.card_number ?? "--"}</span>
                                    </li>
                                    <li>
                                        Cash:{" "}
                                        <span>{`£${data?.cash ?? 0}`}</span>
                                    </li>
                                </ul>
                            </DetailCard>
                        ))}
                    </ul> */}
                    <ul className="client-overview__list">
                        {bottom.map((data, i) => (
                            <DetailCard
                                key={i}
                                title={data?.title}
                                buttons={
                                    <>
                                        {data?.can_report ? (
                                            <StyledDetailCardButton
                                                type="button"
                                                onClick={pushReportHandler}
                                            >
                                                Push To Report
                                            </StyledDetailCardButton>
                                        ) : null}
                                        <StyledDetailCardLink to={data?.path}>
                                            View Details
                                        </StyledDetailCardLink>
                                    </>
                                }
                            ></DetailCard>
                        ))}
                    </ul>
                </ClientOverview>
            </PageContent>
        </>
    );
};

export default ClientLanding;
