import styled from "styled-components";

interface iSCLineBreak {
    width?: string;
}

export const LineBreakWrapper = styled.div<iSCLineBreak>`
    display: flex;
    align-items: center;
    gap: 14px;

    width: ${(props) => (props.width ? `${props.width}` : "")};
    margin-block: 8px;

    p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #5e5e5e;
        white-space: nowrap;
    }
    .line {
        flex: 1;
        border: 0.5px solid rgba(8, 42, 82, 0.3);
    }
`;
