import { StyledTableButton } from "./styles";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { Modal } from "../..";
import { useState } from "react";
import { StyledActionIconBtn } from "../styles";
import CreatePersonalBudgeting from "../../Modals/ClientProducts/CreatePersonalBudgeting";
import DeletePersonalBudgeting from "../../Modals/ClientProducts/DeletePersonalBudgeting";

const PersonalBudgetingActions: React.FC<{ data: { [key: string]: any } }> = ({
    data,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    return (
        <>
            <Modal isVisible={isEditing}>
                <CreatePersonalBudgeting
                    isOpen={isEditing}
                    mode="edit"
                    onClose={() => setIsEditing(false)}
                    data={data}
                />
            </Modal>
            <Modal isVisible={isDeleting}>
                <DeletePersonalBudgeting
                    isOpen={isDeleting}
                    onClose={() => setIsDeleting(false)}
                    id={data?.id ?? 0}
                />
            </Modal>
            <StyledActionIconBtn
                onClick={() => setIsEditing(true)}
                type="button"
            >
                <EditIcon />
            </StyledActionIconBtn>
            <StyledActionIconBtn
                onClick={() => setIsDeleting(true)}
                type="button"
            >
                <DeleteIcon />
            </StyledActionIconBtn>
        </>
    );
};

export default PersonalBudgetingActions;
