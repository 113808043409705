import { AgreementDetailsContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import { formatDateString } from "../../../../global/utils/helpers";

const SetupFeePayments: React.FC<AgreementDetailsContainerProps> = ({
    formData,
    onInputChange,
}) => {
    //formatDateString(formData?.r2_fee_taken || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')
    return (
        <HeaderContainer
            title="Setup Fee Payments"
            headerStyle="gradientBlue"
            className="setup-fee col-2"
        >
            <Input
                type="number"
                label="Accountancy Setup Fee 1 Amount:"
                value={`${formData?.setup_fee_1_amount ?? ""}`}
                onChange={onInputChange}
                name="setup_fee_1_amount"
            />
            <Input
                type="date"
                label="Accountancy Setup Fee 1 Taken:"
                value={formatDateString(formData?.setup_fee_1_taken || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="setup_fee_1_taken"
            />
            <Input
                type="number"
                label="Accountancy Setup Fee 2 Amount:"
                value={`${formData?.setup_fee_2_amount ?? ""}`}
                onChange={onInputChange}
                name="setup_fee_2_amount"
            />
            <Input
                type="date"
                label="Accountancy Setup Fee 2 Taken:"
                value={formatDateString(formData?.setup_fee_2_taken || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="setup_fee_2_taken"
            />
            <Input
                type="number"
                label="CC Fee 1 Amount:"
                value={`${formData?.cc_fee_1_amount ?? ""}`}
                onChange={onInputChange}
                name="cc_fee_1_amount"
            />
            <Input
                type="date"
                label="CC Fee 1 Taken:"
                value={formatDateString(formData?.cc_fee_1_taken || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="cc_fee_1_taken"
            />
            <Input
                type="number"
                label="CC Fee 2 Amount:"
                value={`${formData?.cc_fee_2_amount ?? ""}`}
                onChange={onInputChange}
                name="cc_fee_2_amount"
            />
            <Input
                type="date"
                label="CC Fee 2 Taken:"
                value={formatDateString(formData?.cc_fee_2_taken || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="cc_fee_2_taken"
            />
            <Input
                type="number"
                label="CC Fee 3 Amount:"
                value={`${formData?.cc_fee_3_amount ?? ""}`}
                onChange={onInputChange}
                name="cc_fee_3_amount"
            />
            <Input
                type="date"
                label="CC Fee 3 Taken:"
                value={formatDateString(formData?.cc_fee_3_taken || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="cc_fee_3_taken"
            />
            <Input
                type="number"
                label="R1 Fee Amount:"
                value={`${formData?.r1_fee_amount ?? ""}`}
                onChange={onInputChange}
                name="r1_fee_amount"
            />
            <Input
                type="date"
                label="R1 Fee Taken:"
                value={formatDateString(formData?.r1_fee_taken || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="r1_fee_taken"
            />
            <Input
                type="number"
                label="R2 Fee Amount:"
                value={`${formData?.r2_fee_amount ?? ""}`}
                onChange={onInputChange}
                name="r2_fee_amount"
            />
            <Input
                type="date"
                label="R2 Fee Taken:"
                value={formatDateString(formData?.r2_fee_taken || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="r2_fee_taken"
            />
        </HeaderContainer>
    );
};

export default SetupFeePayments;
