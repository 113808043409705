import styled from "styled-components";
import { ResizableModalWrapper } from "../styles";
import { theme } from "../../../global/theme";

export const StyledAuditLogDetails = styled(ResizableModalWrapper)`
    width: min(100%, 660px);

    & ul {
        list-style: none;
    }

    & li {
        font-weight: 300;
        font-size: 14px;
        display: flex;
        justify-content: space-between;

        & > span {
            font-weight: 400;
        }
    }

    & .audit-log-details {
        &__list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
            gap: 4px 24px;
        }

        &__data {
            grid-column: 1/-1;
            align-items: flex-start;
            margin-top: 16px;
            gap: 24px;
            &__list {
                flex: 1;
                max-width: 400px;
                padding: 8px;
                border: 0.6px solid ${theme.colors.boxStroke};
                border-radius: 8px;
                background-color: ${theme.colors.white};
            }

            &__item {
                flex: 1;
            }
        }
    }
`;
