import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "../../../layout/Header";
import StyledCorrespondenceLandingContent from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";
import DetailCard from "../../../components/Cards/DetailCard";
import FileUpload, { FileData } from "../../../components/FileUpload";
import UploadPreview from "../../../components/UploadPreview";

import { ReactComponent as InboxIcon } from "../../../assets/icons/purpleSend.svg";
import { ReactComponent as SMSIcon } from "../../../assets/icons/purpleSMS.svg";
import { ReactComponent as SignablesIcon } from "../../../assets/icons/purpleSign.svg";
import { ReactComponent as FilesIcon } from "../../../assets/icons/prupleUpload.svg";

import testJPG from "../../../assets/images/testJPG.jpg";
import testPDF from "../../../assets/pdfs/testPDF.pdf";
import Checkbox from "../../../components/Input/Checkbox";
import { Button, Modal } from "../../../components";

const CorrespondenceLanding = () => {
    const { clientId } = useParams();
    const [contactMethod, setContactMedthod] = useState({
        email: 0,
        sms: 0,
        post: 0,
    });

    const testData = {
        new_inbox_count: 2,
        new_sms_count: 3,
        signables_counts: {
            sent: 42,
            pending: 21,
            unsent: 2,
        },
        latest_uploads: [
            {
                id: 1,
                url: testJPG,
                filename: "testJPG.jpg",
                size: 12341234,
                upload_date: "2022-08-08",
            },
            {
                id: 2,
                url: testPDF,
                filename: "testPDF.pdf",
                size: 12341234,
                upload_date: "2022-08-08",
            },
        ],
        preferred_contact_method: {
            email: 1,
            sms: 1,
            post: 0,
        },
    };

    const getCorrespondenceDashboard = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/correspondence/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data?.preferred_contact_method)
                    setContactMedthod(data.data.preferred_contact_method);
            },
        }
    );

    const uploadFile = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/upload-file/${clientId}`,
    });
    const updateContactMethod = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/contact-method/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data)
                    setContactMedthod(data.data?.preferred_contact_method);
            },
        }
    );

    const landingData = getCorrespondenceDashboard?.data?.data || {};

    // useEffect(() => {
    //     setContactMedthod(testData.preferred_contact_method);
    // }, []);

    const uploadHandler = (fileData: FileData) => {
        if (fileData.file) {
            const formData = new FormData();
            formData.append("file", fileData.file);
            uploadFile.fetchData(formData);
            // formData.append('filename', file.name)
        }
    };

    const submitContactMethodHandler = (e: React.FormEvent) => {
        e.preventDefault();
        updateContactMethod.fetchData(contactMethod);
    };

    const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        const requestData = { [name]: checked ? 1 : 0 };
        console.log(requestData);
        updateContactMethod.fetchData(requestData);
    };
    console.log(contactMethod);
    return (
        <>
            <Header title="Correspondence" hasNavBarAbove />
            <StyledCorrespondenceLandingContent className="corres-landing">
                <div className="corres-landing__cards">
                    <div className="small-cards">
                        <div className="corres-landing__card">
                            <div className="corres-landing__card__row">
                                <InboxIcon />
                                <h2>Inbox</h2>
                                <Link to="email">View Inbox</Link>
                            </div>
                            <div className="corres-landing__card__count">
                                <p>{landingData?.inbox ?? 0}</p>
                                <h3>New Message</h3>
                            </div>
                        </div>
                        <div className="corres-landing__card">
                            <div className="corres-landing__card__row">
                                <SMSIcon />
                                <h2>SMS Messages</h2>
                                <Link to="sms">View SMS</Link>
                            </div>
                            <div className="corres-landing__card__count">
                                <p>{landingData?.new_sms_count ?? 0}</p>
                                <h3>New Message</h3>
                            </div>
                        </div>
                    </div>
                    <div className="corres-landing__card">
                        <div className="corres-landing__card__row">
                            <SignablesIcon />
                            <h2>Signables</h2>
                            <Link to="signables">View Documents</Link>
                        </div>
                        <div className="corres-landing__card__count">
                            <p>{landingData?.sms ?? 0}</p>
                            <h3>New Message</h3>
                        </div>
                    </div>
                    <div className="corres-landing__card">
                        <div className="corres-landing__card__row">
                            <SignablesIcon />
                            <h2>Signables</h2>
                            <Link to="signables">View Documents</Link>
                        </div>
                        <div className="corres-landing__card__count">
                            <p>{landingData?.signables?.sent ?? "-"}</p>
                            <h3>Sent</h3>
                        </div>
                        <div className="corres-landing__card__count">
                            <p data-color="orange">
                                {landingData?.signables?.pending ?? "-"}
                            </p>
                            <h3>Pending</h3>
                        </div>
                        <div className="corres-landing__card__count">
                            <p data-color="red">
                                {landingData?.signables?.unsent ?? "-"}
                            </p>
                            <h3>Unsent</h3>
                        </div>
                    </div>
                    <div className="corres-landing__card">
                        <div className="corres-landing__card__row">
                            <FilesIcon />
                            <h2>Files</h2>
                            <Link to="files">View Files</Link>
                        </div>
                        <h3>Latest Files Uploaded</h3>
                        <ul className="corres-landing__card__uploads">
                            {landingData?.files ? (
                                landingData.files.map(
                                    (data: { [key: string]: any }) => (
                                        <li
                                            className="corres-landing__card_upload"
                                            key={data.id}
                                        >
                                            <UploadPreview
                                                filePath={data?.s3Path || ""}
                                                fileName={data?.file_name}
                                                fileSize={data?.file_size || 0}
                                                fileType={data?.file_extn}
                                                date={data?.created_at || ""}
                                                size="s"
                                            />
                                        </li>
                                    )
                                )
                            ) : (
                                <p>No File Uploaded.</p>
                            )}
                        </ul>

                        <FileUpload
                            onUploaded={uploadHandler}
                            showRequirement={false}
                            accept="image/jpeg,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        >
                            Upload File
                        </FileUpload>
                    </div>
                    <div className="corres-landing__card corres-landing__card--settings">
                        <div className="corres-landing__card__row">
                            <h2>Client Correspondence Settings</h2>
                        </div>
                        <h3>Preferred Contact Method</h3>
                        <div
                            onSubmit={submitContactMethodHandler}
                            className="corres-landing__card__checkboxes"
                        >
                            <Checkbox
                                label="Email"
                                checked={!!contactMethod?.email}
                                onChange={checkboxHandler}
                                name="email"
                            />
                            <Checkbox
                                label="SMS"
                                checked={!!contactMethod?.sms}
                                onChange={checkboxHandler}
                                name="sms"
                            />
                            <Checkbox
                                label="Post"
                                checked={!!contactMethod?.post}
                                onChange={checkboxHandler}
                                name="post"
                            />
                        </div>
                    </div>
                </div>
            </StyledCorrespondenceLandingContent>
        </>
    );
};

export default CorrespondenceLanding;
