import { ProductWrapper } from "./styles";

interface ProductProps {
    title: string;
    subTitle: string;
    benefits: string[];
    active: boolean;
    onClick?: (...args: any[]) => void;
}

const Product = ({
    title,
    subTitle,
    benefits,
    onClick,
    active,
}: ProductProps) => {
    return (
        <ProductWrapper
            active={active}
            onClick={onClick}
            className="product-card"
        >
            <div className="header">
                <h3>{title}</h3>
                <h4>{subTitle}</h4>
            </div>
            {benefits.map((ben: string, index: number) => (
                <p key={index}>{ben}</p>
            ))}
        </ProductWrapper>
    );
};

export default Product;
