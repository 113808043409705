import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import useReduxAccounts from "../../../store/hooks/useReduxAccounts";
import { SideMenuButton } from "../Diary/styles";
import { StyledViewUser } from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxUser from "../../../store/hooks/useReduxUser";
import DeleteAccount from "../../../components/Modals/Accounts/DeleteAccountModal";

interface ViewUserProps {
    onPrint?: (...args: any[]) => void;
}

const ViewUser: React.FC<ViewUserProps> = ({ onPrint = () => {} }) => {
    const { userData } = useReduxAccounts();
    const { user } = useReduxUser();
    const { userId } = useParams();
    const [isDeleting, setIsDeleting] = useState(false);
    const passwordRequest = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/send-password-request/${userId}`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Password Request Sent",
        }
    );

    const passwordRequestHandler = () => {
        passwordRequest.fetchData();
    };
    const handleDeleteAccount = () => {
        setIsDeleting(true);
    };

    return (
        <>
            <DeleteAccount isOpen={isDeleting} setIsOpen={setIsDeleting} />

            <StyledViewUser className="view-user">
                <div className="view-user__info">
                    <h3 className="view-user__name">{userData?.users?.name}</h3>
                    <h4 className="view-user__role">
                        {userData?.users?.jb_title}
                    </h4>
                    <ul className="view-user__list">
                        <li>
                            Email: <span>{userData?.users?.email}</span>
                        </li>
                        <li>
                            DDI: <span>{userData?.users?.ddi}</span>
                        </li>
                        <li>
                            User Type: <span>{userData?.users?.user_type}</span>
                        </li>
                        <li>
                            Department:{" "}
                            <span>{userData?.users?.dprt_name}</span>
                        </li>
                        <li>
                            Started: <span>{userData?.users?.created_at}</span>
                        </li>
                    </ul>
                </div>
                <SideMenuButton onClick={passwordRequestHandler} color="orange">
                    Send Password Request
                </SideMenuButton>
                {`${user?.id}` !== userId && (
                    <SideMenuButton onClick={handleDeleteAccount} color="red">
                        Delete Account
                    </SideMenuButton>
                )}
                <SideMenuButton onClick={onPrint} color="green">
                    Print User Details
                </SideMenuButton>
            </StyledViewUser>
        </>
    );
};

export default ViewUser;
