import { useState } from "react";
import { Button, GroupSettingCard, ModalContainer } from "../../../components";
import { GroupSettingsCardsWrapper } from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxAccounts from "../../../store/hooks/useReduxAccounts";
import GroupPermissionsSettings from "../../../components/Modals/Accounts/GroupPermissionsSettings";

const GroupSettings = () => {
    const { groupSettings, setGroupSettings } = useReduxAccounts();
    const [selectedData, setSelectedData] = useState<{
        [key: string]: any;
    } | null>(null);
    const { data, fetchData, isLoading } = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/admin`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) {
                    setGroupSettings(data?.data);
                }
            },
        }
    );
    return (
        <>
            <ModalContainer
                isVisible={!!selectedData}
                children={
                    <GroupPermissionsSettings
                        isOpen={!!selectedData}
                        onClose={() => setSelectedData(null)}
                        data={selectedData || undefined}
                        mode="edit"
                    />
                }
            />
            <GroupSettingsCardsWrapper>
                <div className="cards">
                    {groupSettings.length
                        ? groupSettings.map((data) => {
                              return (
                                  <GroupSettingCard
                                      key={data?.id}
                                      data={data}
                                      onClick={(data) => setSelectedData(data)}
                                  />
                              );
                          })
                        : null}
                </div>
            </GroupSettingsCardsWrapper>
        </>
    );
};

export default GroupSettings;
