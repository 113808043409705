import React, { useState } from "react";
import DataTable from "../../../DataTable";
import { ReactComponent as TickIcon } from "../../../../assets/icons/greenTick.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/redCross.svg";
import { formatTimeString } from "../../../../global/utils/helpers";
import { theme } from "../../../../global/theme";
import { capitalize } from "lodash";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import DropdownSelect from "../../../DropdownSelect";

interface SignableContentProps {
    currentTabIndex: number;
    data: { [key: string]: any };
}
const SignableContent: React.FC<SignableContentProps> = ({
    currentTabIndex,
    data,
}) => {
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const statusColor = () => {
        if (data?.status === "sent") return theme.colors.success;
        if (data?.status === "cancelled") return theme.colors.error;
        return "";
    };

    const loadSuccessHandler = ({ numPages }: { numPages: number }) => {
        setTotalPages(numPages);
    };

    if (currentTabIndex === 0)
        return (
            <ul className="data-list">
                <li>
                    Document Title:<span>{data?.template || "-"}</span>
                </li>
                <li>
                    Signable Fingerprint:
                    <span>{data?.signable_fingerprint || "-"}</span>
                </li>
                <li>
                    Signable Status:
                    <span style={{ color: statusColor() }}>
                        {capitalize(data?.signable_status || "-")}
                    </span>
                </li>
                <li>
                    Sent At:
                    <span>{formatTimeString(data?.created_at || "")}</span>
                </li>
                <li>
                    Sent By:<span>{data?.created_by || "-"}</span>
                </li>
                <li>
                    Signable Proccessed:
                    <span>
                        {data?.is_proccessed ? <TickIcon /> : <CrossIcon />}
                    </span>
                </li>
            </ul>
        );

    if (currentTabIndex === 1)
        return (
            <DataTable
                key="parties"
                data={data?.parties || []}
                labels={["Name", "Email", "Status"]}
                keys={["name", "email", "status"]}
                enableSorting={false}
            />
        );

    if (currentTabIndex === 2)
        return (
            <DataTable
                key="history"
                data={data?.history || []}
                labels={["Details", "Date / Time", "From IP"]}
                keys={["details", "date_time", "ip"]}
                enableSorting={false}
                formatKeys={{
                    date_time: "time",
                }}
                columnWidths={["200px", "", "50px"]}
            />
        );

    return (
        <div className="document-viewer">
            <DropdownSelect
                className="document-viewer__select"
                label="Select Page:"
                value={currentPage}
                options={Array(totalPages)
                    .fill("")
                    .map((el, i) => {
                        return {
                            value: i + 1,
                            label: `${i + 1}`,
                        };
                    })}
                onSelect={(option) => setCurrentPage(option.value)}
                enableInput
                placeholder={`Total: ${totalPages}`}
            />
            <div className="document-viewer__viewer">
                <Document
                    file={data?.s3Path || ""}
                    onLoadSuccess={loadSuccessHandler}
                >
                    <Page
                        pageNumber={currentPage}
                        renderTextLayer={false}
                        width={400}
                    />
                </Document>
            </div>
        </div>
    );
};

export default SignableContent;
