import styled from "styled-components";
import { ResizableModalWrapper } from "../../styles";
import { mainScrollbar } from "../../../../global/theme/reuseable/mixins";

export const StyledDeleteWorkflowTemplate = styled(ResizableModalWrapper)`
    width: min(100%, 400px);

    & p {
        text-align: center;
        padding-top: 8px;
        padding-bottom: 8px;
    }
`;

export const StyledEditWorkflowTemplate = styled(ResizableModalWrapper)`
    width: min(100%, 660px);

    & .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        padding-bottom: 8px;
    }

    & .info {
        list-style: none;

        & > li {
            display: flex;
            justify-content: space-between;
            font-weight: 300;
            line-height: 120%;

            & > span {
                font-weight: 400;
                margin-bottom: 8px;
            }
        }
    }

    & .groups {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-block: 24px;
        overflow-y: auto;
        max-height: calc(100vh - 360px);
        ${mainScrollbar(12)}
    }
`;

export const StyledWorkflowGroup = styled.div`
    & .workflow-group {
    }

    & .workflow-group__ctn .header-container {
        &__header {
            flex-direction: row-reverse;
            justify-content: flex-end;
            gap: 12px;
            padding-inline: 12px;
            position: initial;
            top: initial;
            & > h2 {
                font-size: 16px;
            }
        }
        &__header-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__ctn {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 16px;
            padding: 12px;
            flex: initial;
            overflow-y: visible;
            overflow-x: visible;
        }
    }
`;
