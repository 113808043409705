import { SetStateAction } from "react";
import { StyledWorkflowGroup } from "./styles";
import HeaderContainer from "../../../../containers/HeaderContainter";
import DropdownSelect, { DropdownSelectProps } from "../../../DropdownSelect";
import Input from "../../../Input";

interface WorkflowGroupProps extends DropdownSelectProps {
    name: string;
    dataKey: string;
    setWorkflow: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
    workflow: { [key: string]: any };
    isInput?: boolean;
}

const WorkflowGroup: React.FC<WorkflowGroupProps> = ({
    name,
    dataKey,
    setWorkflow,
    workflow,
    options,
    labelKeys,
    optionKey,
    isInput = false,
}) => {
    const groupValues = workflow?.[dataKey]?.values || [];
    const isGroupActive = workflow?.[dataKey]?.is_active;

    const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWorkflow((prev) => {
            return {
                ...prev,
                [dataKey]: { ...prev[dataKey], is_active: e.target.checked },
            };
        });
    };

    const changeHandler = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        setWorkflow((prev) => {
            return {
                ...prev,
                [dataKey]: {
                    ...prev[dataKey],
                    values: prev[dataKey].values.map((v: unknown, i: number) =>
                        i === index ? e.target.value : v
                    ),
                },
            };
        });
    };

    const selectHandler = (value: unknown, index: number) => {
        setWorkflow((prev) => {
            return {
                ...prev,
                [dataKey]: {
                    ...prev[dataKey],
                    values: prev[dataKey].values.map((v: unknown, i: number) =>
                        i === index ? value : v
                    ),
                },
            };
        });
    };

    return (
        <StyledWorkflowGroup className="workflow-group">
            <HeaderContainer
                className="workflow-group__ctn"
                title={name}
                headingElement="h2"
                headerContent={
                    <input
                        type="checkbox"
                        checked={isGroupActive}
                        onChange={checkboxHandler}
                    ></input>
                }
            >
                {groupValues.map((value: any, i: number) =>
                    isInput ? (
                        <Input
                            className="workflow-group__input"
                            key={i}
                            type="text"
                            value={value || ""}
                            onChange={(e) => changeHandler(e, i)}
                            disabled={!isGroupActive}
                        />
                    ) : (
                        <DropdownSelect
                            key={i}
                            options={options}
                            labelKeys={labelKeys}
                            optionKey={optionKey}
                            value={value}
                            onSelect={(option) =>
                                selectHandler(option.value, i)
                            }
                            disabled={!isGroupActive}
                        />
                    )
                )}
            </HeaderContainer>
        </StyledWorkflowGroup>
    );
};

export default WorkflowGroup;
