import { StepProps } from ".";
import DropdownSelect from "../../../DropdownSelect";
import Input from "../../../Input";
import { StepWrapper } from "./styles";

const StepOne: React.FC<StepProps> = ({
    handleChange,
    handleSelect,
    select,
    adminDetails,
    setAdminDetails,
}) => {
    return (
        <StepWrapper>
            <Input
                name="name"
                type="text"
                value={adminDetails.name}
                onChange={handleChange}
                label="Name: (*)"
                gap="13px"
                required
            />
            <Input
                name="email"
                type="email"
                value={adminDetails.email}
                onChange={handleChange}
                label="Email Address: (*)"
                gap="13px"
                required
            />
            <Input
                name="ddi"
                type="text"
                value={adminDetails.ddi}
                onChange={handleChange}
                label="DDI:"
                gap="13px"
            />
            <DropdownSelect
                label="Job Role: (*)"
                labelKeys={["name"]}
                optionKey="id"
                options={select?.job_titles}
                value={adminDetails?.job_title_id}
                onSelect={(option) => {
                    setAdminDetails((prev) => {
                        return {
                            ...prev,
                            job_title: option.name,
                            job_title_id: option.id,
                        };
                    });
                }}
                enableInput
            />
            <DropdownSelect
                label="Department: (*)"
                labelKeys={["name"]}
                optionKey="id"
                options={select?.department}
                value={adminDetails?.dprtmnt}
                onSelect={(option) => {
                    setAdminDetails((prev) => {
                        return {
                            ...prev,
                            department: option.name,
                            dprtmnt: option.id,
                        };
                    });
                }}
                enableInput
            />
        </StepWrapper>
    );
};

export default StepOne;
