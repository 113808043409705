import styled from "styled-components";
import { ResizableModalWrapper } from "../styles";
import { theme } from "../../../global/theme";

export const StyledAgentPopUp = styled(ResizableModalWrapper)`
    width: min(100%, 460px);
    & .agent-pop-up {
        &__content {
            & > p {
                color: ${theme?.colors.primaryBlue};
                margin-bottom: 8px;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
        }

        &__item {
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: space-between;
            padding-top: 8px;
            padding-bottom: 8px;

            &:not(:first-child) {
                border-top: 0.6px solid ${theme.colors.boxStroke};
            }

            & > p {
                display: flex;
                flex-direction: column;
                & > span {
                    font-size: 12px;
                    font-weight: 300;
                }
            }

            & .file-upload {
                &__types {
                    transform: none;
                    left: initial;
                    right: 0;
                    font-size: 10px;
                    top: -14px;
                }

                &__upload {
                    &__label {
                        height: 34px;
                        padding-inline: 12px;
                        background-image: none;
                        background-color: ${theme.colors.white};
                        border: 0.6px solid ${theme.colors.purple};
                        color: ${theme.colors.purple};
                        font-size: 12px;
                    }
                }
            }
        }
    }
`;
