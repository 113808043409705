import styled from "styled-components";
import { icons } from "../../assets/icons";
import { theme } from "../../global/theme";
import { ring } from "../../global/theme/reuseable/animations";
import { pageInlinePadding } from "../../pages/styles";

export const ToolBarCtn = styled.div`
    /* position: sticky;
    top: 0; */
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #eeeeee;
    height: 60px;
    gap: 30px;
    padding-inline: ${pageInlinePadding};
`;

export const StyledClientToolBar = styled(ToolBarCtn)`
    justify-content: space-between;
    gap: 8px;

    & .client-toolbar {
        &__ctn {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    & .status,
    & .rating {
        padding: 8px 12px;
        font-size: 12px;
        border-radius: 6px;
        color: ${theme.colors.white};
    }

    & .status {
        color: ${theme.colors.white};
    }

    & .rating {
        display: flex;
        align-items: center;
        gap: 8px;
    }
`;

export const StyledClientStatus = styled.div<{ status: string }>`
    background: ${({ status }) => {
        if (status === "IP2-In Progress") return theme.gradients.burntOrange;
        if (status === "Cancelled" || status === "Pending Cancellation")
            return theme.gradients.dangerRed;
        if (status === "Completed") return theme.gradients.mintGreen;
        if (status === "Signing-up") return theme.gradients.yellow;
        if (status === "Credit Control" || status === "FAF Control")
            return theme.gradients.skyBlue;
        if (status === "Inactive" || status === "Dead")
            return theme.colors.cancel;
        return theme.colors.purple;
    }};
    color: ${({ status }) => {
        if (status === "Inactive" || status === "Dead")
            return theme.colors.text;
        return "";
    }};
`;

export const Notifications = styled.span<{ isLoading: boolean }>`
    height: 36px;
    width: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    :hover {
        cursor: pointer;
        background: #f5f5f5;

        .clapper {
            animation: ${ring} 1s ease-in-out infinite;
            transform-origin: top center;
        }
    }
    &[data-notifications] {
        position: relative;
    }

    &[data-notifications]:after {
        content: attr(data-notifications);
        position: absolute;

        height: 22px;
        min-width: 22px;

        right: 0;
        top: 0;
        padding: 2px;
        background-image: ${(props) =>
            props.isLoading
                ? "linear-gradient(135deg, #b6b6b6, #b6b6b6)"
                : "linear-gradient(135deg, #ea2434, #dd0404)"};
        border: 1.6px solid ${theme?.colors?.background};
        border-radius: 50px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #f2f2f2;

        font-size: 10px;
        font-weight: 600;
    }
`;
