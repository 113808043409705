import styled from "styled-components";
import { TablePageContent } from "../../../../../pages/styles";
import Header from "../../../../../layout/Header";
import { theme } from "../../../../../global/theme";
import { mainScrollbar } from "../../../../../global/theme/reuseable/mixins";

const StyledDocumentTemplates = styled(TablePageContent)``;

export const StyledCreateDocumentTemplateHeader = styled(Header)`
    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    li {
        font-size: 12px;
        font-weight: 300;
        & > strong {
            margin-inline: 4px;
            font-weight: 400;
        }
    }
`;

export const StyledCreateDocumentTemplate = styled.form`
    display: grid;
    grid-template-columns: 1fr clamp(300px, 33%, 460px);
    gap: 24px;
    flex: 1 1 0;
    overflow: hidden;
    width: min(100%, 1200px);
    margin-inline: auto;

    & hr {
        border: none;
        border-top: 0.6px solid ${theme.colors.boxStroke};
    }

    & .page-tabs {
        position: sticky;
        top: 0;
        background-color: ${theme.colors.background};
        padding-bottom: 12px;
    }

    /* @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
        overflow: auto;
        ${mainScrollbar(12)}
    } */
    .create-document-template {
        &__column {
            overflow-y: auto;
            overflow-x: hidden;
            ${mainScrollbar(12)}
            display: flex;
            flex-direction: column;
            gap: 12px;

            @media screen and (max-width: 1200px) {
                overflow: initial;
            }
        }
    }

    & .info {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 16px;

        & > li {
            display: grid;
            grid-template-columns: 1fr minmax(200px, 66%);
            align-items: center;
            gap: 8px;
            font-size: 12px;
            font-weight: 300;

            & > *:last-child {
                text-align: right;
            }

            &.upload {
                padding-top: 16px;

                & > *:first-child {
                    word-break: break-all;
                    overflow: hidden;
                }

                & .file-upload {
                    &__upload__label {
                        font-size: 13px;
                        padding: 10px 16px;
                        height: auto;
                    }

                    &__types {
                        left: initial;
                        right: 0;
                        transform: none;
                    }
                }
            }

            &.checkboxes {
                align-items: flex-start;
            }

            & .checkbox {
                flex-direction: row;
                align-items: center;
                gap: 12px;

                & label {
                    flex: 1;
                }

                & .inputWrapper__ctn {
                    width: auto;
                }

                & input {
                    height: 18px;
                    width: 18px;
                    min-height: none;
                    background-color: transparent !important;
                }
            }
        }
    }
`;

export default StyledDocumentTemplates;
