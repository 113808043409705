import { useState } from "react";
import { StyledEditWorkflowTemplate } from "./styles";
import ModalHeader from "../../components/ModalHeader";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import WorkflowGroup from "./WorkflowGroup";
import LineBreak from "../../../../atoms/LineBreak";
import {
    generateTestOptions,
    workflowAreaOptions,
} from "../../../DropdownSelect/options";
import Input from "../../../Input";
import DropdownSelect from "../../../DropdownSelect";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";

interface EditWorkflowProps {
    isOpen: boolean;
    handleModal: (...args: any[]) => void;
    data?: { [key: string]: any };
    mode?: "edit" | "create";
}

const initGroupObject = {
    is_active: 0,
    values: [null, null, null, null, null],
};

const EditWorkflowTemplate = ({
    isOpen,
    handleModal,
    data,
    mode = "edit",
}: EditWorkflowProps) => {
    const { setTemplate } = useReduxSettings();
    console.log(data);
    const [workflow, setWorkflow] = useState<{ [key: string]: any }>({
        area: data?.area || "",
        name: data?.name || "",
        documents: data?.document || initGroupObject,
        sms: data?.sms || initGroupObject,
        email: data?.email || initGroupObject,
        notes: data?.notes || initGroupObject,
        time_stamps: data?.timestamp || initGroupObject,
        is_active: true,
    });

    const updateWorkflow = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/templates/workflow/${data?.id}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTemplate("workflow", data.data);
            },
        }
    );
    const createWorkflow = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/templates/workflow`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTemplate("workflow", data.data);
            },
        }
    );
    const isSubmitSuccess =
        updateWorkflow.isSuccess || createWorkflow.isSuccess;
    const isLoading = updateWorkflow.isLoading || createWorkflow.isLoading;
    const isFormValid = workflow.name.trim() !== "" && !!workflow.area;

    const closeModal = () => handleModal("");

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setWorkflow((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setWorkflow((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (mode === "edit") {
            console.log();
            if (data?.id) updateWorkflow.fetchData(workflow);
        } else {
            createWorkflow.fetchData(workflow);
        }
    };

    return (
        <StyledEditWorkflowTemplate show={isOpen}>
            <ModalHeader onClose={closeModal}>
                {mode === "edit" ? "Edit Workflow" : "Create Workflow"}
            </ModalHeader>
            {isSubmitSuccess ? (
                <SuccessContent
                    onClose={closeModal}
                    message={
                        mode === "edit"
                            ? "Workflow changes has been saved."
                            : "New workflow has been created."
                    }
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <div className="fields">
                        <Input
                            label="Name:"
                            type="text"
                            value={workflow.name}
                            onChange={inputHandler}
                            name="name"
                            autoComplete="off"
                        />
                        <DropdownSelect
                            value={workflow.area}
                            options={workflowAreaOptions}
                            label="Area:"
                            onSelect={(option) =>
                                selectHandler("area", option.value)
                            }
                        />
                    </div>
                    <LineBreak title="Actions" width="100%" />
                    <div className="groups">
                        <WorkflowGroup
                            name="Documents"
                            dataKey="documents"
                            workflow={workflow}
                            setWorkflow={setWorkflow}
                            options={generateTestOptions(10)}
                        />
                        <WorkflowGroup
                            name="SMS Messages"
                            dataKey="sms"
                            workflow={workflow}
                            setWorkflow={setWorkflow}
                            options={generateTestOptions(10)}
                        />
                        <WorkflowGroup
                            name="Email"
                            dataKey="email"
                            workflow={workflow}
                            setWorkflow={setWorkflow}
                            options={generateTestOptions(10)}
                        />
                        <WorkflowGroup
                            name="Notes"
                            dataKey="notes"
                            workflow={workflow}
                            setWorkflow={setWorkflow}
                            isInput
                        />
                        <WorkflowGroup
                            name="Time Stamps"
                            dataKey="time_stamps"
                            workflow={workflow}
                            setWorkflow={setWorkflow}
                            options={generateTestOptions(10)}
                        />
                    </div>
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText={
                                mode === "edit" ? "Save Changes" : "Create"
                            }
                            loading={isLoading}
                            disabled={!isFormValid}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledEditWorkflowTemplate>
    );
};

export default EditWorkflowTemplate;
