import { Link } from "react-router-dom";
import { StyledDropdownMenuItem } from "./styles";
import DropdownMenuIcons from "./icons";

interface DropdownMenuitemProps {
    title: string;
    name?: string;
    iconName?: keyof typeof DropdownMenuIcons;
    onClick?: (...args: any[]) => void;
    elementType?: "a" | "button" | "link" | "div";
    elementProps?: { [key: string]: any };
    to?: string;
    colorMode?: "odd" | "even";
}

const DropdownMeunItem: React.FC<DropdownMenuitemProps> = ({
    title,
    name,
    iconName,
    elementType = "button",
    elementProps = {},
    onClick = () => {},
    to = "",
    colorMode,
}) => {
    const ElementType = elementType === "link" ? Link : elementType;
    const Icon = iconName ? DropdownMenuIcons[iconName] : null;

    return (
        <ElementType
            className={`dropdown-menu__item${
                colorMode ? ` ${colorMode}-colored` : ""
            }`}
            to={to}
            onClick={() => onClick(name)}
            name={name}
            {...elementProps}
        >
            {Icon && <Icon />}
            {title}
        </ElementType>
    );
};

export default DropdownMeunItem;
