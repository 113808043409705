import { PayeRebateContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";

const PersonalInformation: React.FC<PayeRebateContainerProps> = ({
    onInputChange,
    payeRebate,
}) => {
    return (
        <HeaderContainer
            title="Personal Information:"
            headerStyle="gradientBlue"
            ctnClassName="col-2"
        >
            <Input
                type="text"
                label="National Insurance Number:"
                value={payeRebate?.ni || ""}
                name="ni"
                onChange={onInputChange}
            />
            <Input
                type="text"
                label="UTR Number:"
                value={payeRebate?.utr || ""}
                name="utr"
                onChange={onInputChange}
            />
        </HeaderContainer>
    );
};

export default PersonalInformation;
