import { SetStateAction, useState } from "react";
import moment from "moment";
import StyledGroupPermissionsSetting from "./styles";
import ModalHeader from "../../components/ModalHeader";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import Input from "../../../Input";
import useReduxAccounts from "../../../../store/hooks/useReduxAccounts";
import DropdownSelect from "../../../DropdownSelect";
import PermissionCheckBoxes from "../../../CheckboxGroup/PermissionCheckboxes";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import ModalContent from "../../components/ModalContent";
import { Notify } from "notiflix";

interface GroupPermissionsSettingsProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    mode: "create" | "edit";
    data?: { [key: string]: any } | null;
}

const GroupPermissionsSettings: React.FC<GroupPermissionsSettingsProps> = ({
    isOpen,
    onClose,
    mode = "create",
    data,
}) => {
    const [name, setName] = useState(data?.name || "");
    const [description, setDescription] = useState(data?.description || "");
    const [userIds, setUserIds] = useState<number[]>(data?.user_ids || []);
    const [permissionIds, setPermissionIds] = useState<Array<number>>(
        data?.permission_ids || []
    );
    const { setGroupSettings } = useReduxAccounts();
    const [permissionList, setPermissionList] = useState<
        Array<{ [key: string]: any }>
    >([]);
    const [usersList, setUsersList] = useState<Array<{ [key: string]: any }>>(
        []
    );

    const listSelectAccount = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/admin/${
                data?.id || "null"
            }`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                setPermissionList(data?.data?.permission_list);
                setPermissionIds(data?.data?.permissions);
                setUsersList(data?.data?.role_user);
                setUserIds(data?.data?.user_list || []);
            },
        }
    );
    const createGroupPermission = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/permission-admin`,
        },
        {
            onSuccess: (data) => {
                Notify.success("Permission Created Successfully");
                setGroupSettings(data?.data);
            },
        }
    );

    const updateGroupPermission = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/permission-admin/${data?.id}`,
        },
        {
            onSuccess: (data) => {
                setGroupSettings(data?.data);
                Notify.success("Permission Updated Successfully");
            },
        }
    );
    const isSubmitSuccess =
        createGroupPermission.isSuccess || updateGroupPermission.isSuccess;
    const isFormValid = name.trim() !== "";

    const headerContent = () => {
        if (mode === "create") return "Create New Permission";
        if (isSubmitSuccess && mode === "edit")
            return "Group Permissions Settings";
        return (
            <>
                Group Permissions Settings{" "}
                <span>
                    Updated by {data?.updated_by || "Unknown User"} -{" "}
                    {moment(data?.updated_at).format("YYYY-MM-DD HH:mm") ||
                        "Unknown Time"}
                </span>
            </>
        );
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const requestData = {
            name,
            description,
            user_ids: userIds,
            permission_ids: permissionIds,
        };
        console.log(requestData);
        if (mode === "create")
            return createGroupPermission.fetchData(requestData);
        return updateGroupPermission.fetchData({
            ...requestData,
            id: data?.id,
        });
    };

    return (
        <StyledGroupPermissionsSetting
            show={isOpen}
            className="group-permissions-settings"
            width="800px"
            isSuccess={isSubmitSuccess}
        >
            <ModalHeader onClose={onClose}>{headerContent()}</ModalHeader>
            {isSubmitSuccess ? (
                <SuccessContent
                    onClose={onClose}
                    message={
                        mode === "create"
                            ? "New permission has been created."
                            : "Group permission has been updated."
                    }
                />
            ) : (
                <form
                    onSubmit={submitHandler}
                    className="group-permissions-settings__form"
                >
                    <ModalContent>
                        <div className="group-permissions-settings__fields">
                            <Input
                                type="text"
                                label="Name:"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                name="name"
                            />
                            <Input
                                type="text"
                                label="Description:"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                name="description"
                            />
                            <DropdownSelect
                                label="Users:"
                                values={userIds}
                                setValues={setUserIds}
                                options={usersList}
                                labelKeys={["name"]}
                                optionKey="id"
                                enableInput
                            />
                        </div>
                        <PermissionCheckBoxes
                            permissionIds={permissionIds}
                            setPermissionIds={setPermissionIds}
                        />
                    </ModalContent>

                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText={
                                mode === "create" ? "Create" : "Save Changes"
                            }
                            disabled={!isFormValid}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledGroupPermissionsSetting>
    );
};
export default GroupPermissionsSettings;
