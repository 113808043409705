import React, { useState, SetStateAction } from "react";
import { Button, Modal } from "../../../../components";
import TvSettingsModal from "../../../../components/Modals/Settings/TVDashboard/TVDashboardModal";
import { LeadStatusWrapper } from "./styles";
import TVCard from "../../../../components/Cards/TVCard";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";
import useFetchData from "../../../../global/hooks/useFetchData";
import { LoadingSpinner } from "../../../../components/Button/styles";

interface TvDashboardProps {
    isCreatingNew: boolean;
    setIsCreatingNew: React.Dispatch<SetStateAction<boolean>>;
}

const TvDashboards: React.FC<TvDashboardProps> = ({
    isCreatingNew,
    setIsCreatingNew,
}) => {
    const { setOther, others } = useReduxSettings();
    const [selectedData, setSelectedData] = useState<{
        [key: string]: any;
    } | null>(null);
    const tvDashboardList = others.tvDashboards || [];

    const { isLoading } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/tv-dashboards`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setOther("tvDashboards", data.data);
            },
        }
    );

    return (
        <>
            <Modal isVisible={isCreatingNew}>
                <TvSettingsModal
                    isOpen={isCreatingNew}
                    onClose={() => setIsCreatingNew(false)}
                    mode="create"
                />
            </Modal>
            <Modal isVisible={!!selectedData}>
                <TvSettingsModal
                    isOpen={!!selectedData}
                    onClose={() => setSelectedData(null)}
                    mode="edit"
                    data={selectedData || {}}
                />
            </Modal>
            <LeadStatusWrapper>
                {isLoading ? (
                    <LoadingSpinner size={40} />
                ) : (
                    <ul className="list">
                        {tvDashboardList.map((data: any) => (
                            <li key={data?.id}>
                                <TVCard
                                    key={data?.id}
                                    data={data || {}}
                                    onClick={setSelectedData}
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </LeadStatusWrapper>
        </>
    );
};

export default TvDashboards;
