import React, { useState } from "react";
import { useParams } from "react-router-dom";
import SearchRow from "../../../components/SearchRow";
import { StyledCreditorClientList } from "./styles";
import { Button } from "../../../components";
import { icons } from "../../../assets/icons";
import { exportCsv, extractTextFromHTML } from "../../../global/utils/helpers";
import DataTable from "../../../components/DataTable";
import ClientListRow from "../../../components/DataTable/TableRows/Creditors/ClientListRow";
import useReduxCreditors from "../../../store/hooks/useReduxCreditors";

interface CreditorClientListProps {
    clientList: Array<{ [key: string]: any }>;
}

const CreditorClientList: React.FC<CreditorClientListProps> = ({
    clientList,
}) => {
    const { list } = useReduxCreditors();
    const [searchTerm, setSearchTerm] = useState("");
    const { creditorId } = useParams();
    const clientBodies: Array<any> = clientList || [];
    const clientHeadings: Array<any> = [
        "Client Id",
        "Client Name",
        "Wet LOA",
        "Telephone Number",
        "Reference",
        "Debt Level",
        "RePayment Amount",
        "Case Status",
        "IP Status",
        "Review Date",
        "Debt Status",
    ];

    const formattedClientBodies = clientBodies.map((data) => {
        const formattedObj = {
            ...data,
            "Debt Status": extractTextFromHTML(data["Debt Status"]),
        };
        delete formattedObj.key;
        return formattedObj;
    });

    const exportHandler = () => {
        exportCsv(
            [formattedClientBodies] || [],
            [clientHeadings],
            ["My Clients"],
            `${creditorId}_Clients`
        );
    };

    return (
        <StyledCreditorClientList className="creditor-client-list">
            <SearchRow value={searchTerm} setValue={setSearchTerm}>
                <Button
                    btnText=""
                    onClick={exportHandler}
                    style="red"
                    btnImg={icons?.exportIcon}
                />
            </SearchRow>
            <DataTable
                data={clientBodies}
                dataKey="key"
                labels={clientHeadings}
                searchTerm={searchTerm}
                searchKeys={["Client Id"]}
                tableRow={ClientListRow}
            />
        </StyledCreditorClientList>
    );
};

export default CreditorClientList;
