import React, {
    useId,
    useState,
    useRef,
    useEffect,
    SetStateAction,
    useTransition,
    useDeferredValue,
    useMemo,
} from "react";
import StyledDropdownSelect from "./styles";
import useClickOutside from "../../global/hooks/useClickOutside";
import { ReactComponent as ArrowIcon } from "../../assets/icons/blackDownArrow.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/x.svg";
import { LoadingSpinner } from "../Button/styles";

export type DropdownSelectValue = string | number | null;

export interface DropdownSelectProps {
    className?: string;
    label?: string;
    options?: { [key: string]: any }[];
    optionKey?: string;
    labelKeys?: string[];
    value?: any;
    values?: any[];
    setValues?: React.Dispatch<SetStateAction<number[]>>;
    onSelect?: (value: any) => void;
    onMultiSelect?: (value: any) => void;
    onSearchTermDedounce?: (...args: any[]) => void;
    placeholder?: string;
    enableInput?: boolean;
    isLoading?: boolean;
    required?: boolean;
    isInvalid?: boolean;
    hasDefaultValue?: boolean;
    defaultValueLabel?: string;
    size?: "s" | "m";
    disabled?: boolean;
    name?: string;
    isOptionInGroups?: boolean;
    autoScrollOnMenuOpen?: boolean;
    hideValues?: any[];
    maxOptions?: number | "infinite";
}

const DropdownSelect: React.FC<DropdownSelectProps> = ({
    className = "",
    label = "",
    options = [],
    optionKey = "value",
    labelKeys = ["label"],
    value,
    values,
    setValues = () => {},
    onSelect = () => {},
    onSearchTermDedounce,
    placeholder,
    enableInput = false,
    isLoading = false,
    required = false,
    hasDefaultValue = false,
    defaultValueLabel = "",
    size = "s",
    disabled,
    name,
    isOptionInGroups = false,
    autoScrollOnMenuOpen = false,
    isInvalid,
    hideValues = [],
    maxOptions = 1000,
}) => {
    const initialLabel =
        options.find((opt) => opt[optionKey] === value)?.[labelKeys[0]] || "";
    const id = useId();
    const ref = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLUListElement>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const deferredSearchTerm = useDeferredValue(searchTerm);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isFocusing, setIsFocusing] = useState(false);
    const [isPending, startTransition] = useTransition();
    const [selectedLabel, setSelectedLabel] = useState(initialLabel);

    useEffect(() => {
        setSelectedLabel(initialLabel);
    }, [initialLabel]);

    useEffect(() => {
        if (isMenuOpen && autoScrollOnMenuOpen && menuRef.current)
            menuRef.current.scrollIntoView();
    }, [isMenuOpen, autoScrollOnMenuOpen, menuRef.current]);

    const inputValue = () => {
        if (isLoading) return "Loading data...";
        if (enableInput && isMenuOpen) return searchTerm;
        if (selectedLabel || value !== undefined || values !== undefined)
            return selectedLabel;
        return defaultValueLabel;
    };

    const placeholderText = () => {
        if (isLoading) return "Loading data...";
        if (placeholder !== undefined) return placeholder;
        return `Please select ${enableInput ? "or search" : ""}...`;
    };

    const isOptionSelected = useMemo(() => {
        return (option: { [key: string]: any }) => {
            if (Array.isArray(values)) {
                return values.some(
                    (v) =>
                        v[optionKey] === option[optionKey] ||
                        v === option[optionKey]
                );
            }
            return (
                value?.[optionKey] === option?.[optionKey] ||
                value === option?.[optionKey] ||
                hideValues.includes(option?.[optionKey])
            );
        };
    }, [optionKey, value, values]);

    const filteredOptions = useMemo(() => {
        return options.filter(
            (option) =>
                (labelKeys
                    .map((key) => option[key])
                    .join("")
                    .toLowerCase()
                    .includes(deferredSearchTerm.toLowerCase().trim()) &&
                    !isOptionSelected(option)) ||
                option?.isGroupHeader === true
        );
    }, [deferredSearchTerm, options, labelKeys, isOptionSelected]);
    const sliceParams = maxOptions === "infinite" ? [0] : [0, maxOptions];
    const slicedOptions = filteredOptions.slice(...sliceParams);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (onSearchTermDedounce) onSearchTermDedounce();
        }, 500);
        return () => clearTimeout(timer);
    }, [deferredSearchTerm]);

    useEffect(() => {
        if (required && options?.length) {
            if (onSelect) onSelect(options[0]);
        }
    }, [required]);

    useClickOutside(ref, () => setIsMenuOpen(false));

    const searchTermHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const focusHandler = () => {
        if (enableInput) {
            setIsFocusing(true);
            setIsMenuOpen(true);
        }
    };

    const blurHandler = () => {
        setIsFocusing(false);
    };

    const selectHandler = (option: { [key: string]: any }) => {
        onSelect(option);
        if (setValues) {
            setValues((prev) => [...new Set([...prev, option[optionKey]])]);
        }
        setSelectedLabel(option[labelKeys[0]] || defaultValueLabel);
        setIsMenuOpen(false);
    };

    const selectEmptyHandler = () => {
        onSelect(undefined);
        setSelectedLabel(defaultValueLabel);
        setIsMenuOpen(false);
    };

    const removeHandler = (value: any) => {
        setValues((prev) => prev.filter((v) => v !== value));
    };

    return (
        <StyledDropdownSelect
            className={`dropdown-select${className ? ` ${className}` : ""}`}
            size={size}
        >
            {label ? (
                <label
                    className="dropdown-select__label"
                    htmlFor={`dropdown-select-${id}`}
                >
                    {label}
                </label>
            ) : null}
            <div
                className={`dropdown-select__field${
                    isFocusing ? " focus" : ""
                }`}
                ref={ref}
                aria-disabled={disabled}
                aria-expanded={isMenuOpen}
                aria-invalid={isInvalid}
            >
                <input
                    id={`dropdown-select-${id}`}
                    value={inputValue()}
                    readOnly={!enableInput}
                    className="dropdown-select__input"
                    placeholder={placeholderText()}
                    onClick={() => !enableInput && setIsMenuOpen(!isMenuOpen)}
                    onFocus={focusHandler}
                    onBlur={blurHandler}
                    onChange={searchTermHandler}
                    disabled={isLoading}
                    autoComplete="off"
                ></input>
                <button
                    className="dropdown-select__btn"
                    type="button"
                    disabled={isLoading}
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    {isLoading || isPending ? (
                        <LoadingSpinner size={12} />
                    ) : (
                        <ArrowIcon aria-expanded={isMenuOpen} />
                    )}
                </button>
                {isMenuOpen && (
                    <ul
                        className="dropdown-select__menu"
                        aria-hidden={!isMenuOpen}
                        ref={menuRef}
                    >
                        {!hasDefaultValue && !filteredOptions?.length ? (
                            <li className="dropdown-select__menu__item">
                                No result found.
                            </li>
                        ) : null}
                        {hasDefaultValue && !values ? (
                            <li
                                className="dropdown-select__menu__item"
                                onClick={selectEmptyHandler}
                                data-selected={value === undefined}
                            >
                                {defaultValueLabel || "---"}
                            </li>
                        ) : null}
                        {slicedOptions.map((option) => (
                            <li
                                className="dropdown-select__menu__item"
                                key={option[optionKey] ?? "default-option"}
                                onClick={
                                    option?.isGroupHeader
                                        ? () => {}
                                        : selectHandler.bind(null, option)
                                }
                                data-selected={
                                    option?.isGroupHeader
                                        ? undefined
                                        : isOptionSelected(option)
                                }
                                value={
                                    option?.isGroupHeader
                                        ? undefined
                                        : option?.value
                                }
                                role={
                                    option?.isGroupHeader
                                        ? "group-header"
                                        : "option"
                                }
                            >
                                {labelKeys.map((labelKey) => (
                                    <span key={labelKey}>
                                        {option[labelKey]}
                                    </span>
                                ))}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            {values?.length ? (
                <ul className="dropdown-select__tags">
                    {values.map((value) => (
                        <li
                            key={value ?? "option"}
                            className="dropdown-select__tag"
                        >
                            {options.find((obj) => obj[optionKey] === value)?.[
                                labelKeys[0]
                            ] || ""}
                            <button
                                onClick={removeHandler.bind(null, value)}
                                type="button"
                            >
                                <DeleteIcon />
                            </button>
                        </li>
                    ))}
                </ul>
            ) : null}
        </StyledDropdownSelect>
    );
};

export default DropdownSelect;
