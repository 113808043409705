import { useAppSelector, useAppDispatch } from ".";
import { PermissionsObject, accountsActions } from "../slices/accountsSlice";

const useReduxAccounts = () => {
    const { permissions, userData, groupSettings } = useAppSelector((state) => state.accounts);
    const dispatch = useAppDispatch();

    const setPermissions = (data: PermissionsObject | null) => {
        dispatch(accountsActions.setPermissions(data));
    };
    const setUserData = (data: { [key: string]: any } | null) => {
        dispatch(accountsActions.setUserData(data));
    };

    const setGroupSettings = (data: Array<{[key: string]: any}>)=> {
        dispatch(accountsActions.setGroupSettings(data));

    }

    return {
        permissions,
        setPermissions,
        userData,
        setUserData,
        groupSettings,
        setGroupSettings
    };
};

export default useReduxAccounts;
