import { useState } from "react";
import { TableRowProps } from "..";
import { StyledActionIconBtn } from "../../styles";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { Modal } from "../../..";
import EditWorkflowTemplate from "../../../Modals/Settings/WorkflowTemplates/EditWorkflowTemplate";
import DeleteWorkflowTemplate from "../../../Modals/Settings/WorkflowTemplates/DeleteWorkflowTemplate";

const WorkflowTemplateRow: React.FC<TableRowProps> = ({ data }) => {
    const [modalMode, setModalMode] = useState<"edit" | "delete" | "">("");

    return (
        <>
            <Modal isVisible={modalMode === "edit"}>
                <EditWorkflowTemplate
                    data={data}
                    isOpen={modalMode === "edit"}
                    handleModal={() => setModalMode("")}
                />
            </Modal>
            <Modal isVisible={modalMode === "delete"}>
                <DeleteWorkflowTemplate
                    id={data.id}
                    isOpen={modalMode === "delete"}
                    handleModal={() => setModalMode("")}
                />
            </Modal>
            <tr>
                <td>{data?.name || ""}</td>
                <td>{data?.area || ""}</td>
                <td className="td--action">
                    <div>
                        <StyledActionIconBtn
                            onClick={() => setModalMode("edit")}
                        >
                            <EditIcon />
                        </StyledActionIconBtn>
                        <StyledActionIconBtn
                            onClick={() => setModalMode("delete")}
                        >
                            <DeleteIcon />
                        </StyledActionIconBtn>
                    </div>
                </td>
            </tr>
        </>
    );
};

export default WorkflowTemplateRow;
