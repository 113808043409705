import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IntroducersState {
   types: {[key: string]: any}[];
   data: {[key: string]: any}[] | null;
}

const initialIntroducersState: IntroducersState = {
    types: [],
    data: []
}

export const introducersSlice = createSlice({
    name: 'introducers',
    initialState: initialIntroducersState,
    reducers: {
        setTypes: (state, action: PayloadAction<{[key: string]: any}[]>) => {
            state.types = action.payload
        },
        setData: (state, action: PayloadAction<{[key: string]: any}[] | null>) => {
            state.data = action.payload
        },
    }
})

export const introducersActions = introducersSlice.actions
export default introducersSlice