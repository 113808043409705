import React from "react";
import { StyledClientStatus, StyledClientToolBar } from "./styles";
import User from "./DropdownMenu/User";
import useReduxUser from "../../store/hooks/useReduxUser";
import CaseActions from "./DropdownMenu/CaseActions";
import ViewUpload from "./DropdownMenu/ViewUpload";
import ClientDetails from "./DropdownMenu/ClientDetails";
import { ReactComponent as StarIcon } from "../../assets/icons/star.svg";
import { calculateRatingGradient } from "../../global/utils/helpers";

const ClientToolBar: React.FC = () => {
    const { user } = useReduxUser();
    const testData = {
        status: "IP2-In Progress",
        rating: 3,
    };

    return (
        <>
            <StyledClientToolBar className="client-toolbar">
                <div className="client-toolbar__ctn">
                    <StyledClientStatus
                        className="status"
                        status={testData?.status || ""}
                    >
                        {testData?.status || "Unknown Status"}
                    </StyledClientStatus>
                    <div
                        className="rating"
                        style={{
                            backgroundImage: calculateRatingGradient(
                                testData?.rating ?? 3
                            ),
                        }}
                    >
                        <StarIcon />
                        {testData?.rating ?? "-"}
                    </div>
                </div>

                <div className="client-toolbar__ctn">
                    <ClientDetails />
                    <CaseActions />
                    <ViewUpload />
                    <User username={user?.name || "User"} />
                </div>
            </StyledClientToolBar>
        </>
    );
};

export default ClientToolBar;
