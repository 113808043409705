import { useAppSelector, useAppDispatch } from ".";
import { clientBalanceSheetActions } from "../slices/clientBalanceSheetSlice";

const useReduxClientBalanceSheet = () => {
    const { tableData, notes, debtList } = useAppSelector(
        (state) => state["client-balanceSheet"]
    );
    const dispatch = useAppDispatch();

    const setTableData = (data: Array<{ [key: string]: any }>) => {
        dispatch(clientBalanceSheetActions.setTableData(data));
    };
    const setNotes = (data: Array<{ [key: string]: any }>) => {
        dispatch(clientBalanceSheetActions.setNotes(data));
    };
    const setDebtList = (data: Array<{ [key: string]: any }>) => {
        dispatch(clientBalanceSheetActions.setDebtList(data));
    };

    const resetSlice = () => {
        dispatch(clientBalanceSheetActions.resetSlice());
    };

    return {
        tableData,
        setTableData,
        notes,
        setNotes,
        debtList,
        setDebtList,
        resetSlice,
    };
};

export default useReduxClientBalanceSheet;
