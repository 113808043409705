import styled from "styled-components";
import { theme } from "../../global/theme";
import {
    columnMixin,
    hideScrollbar,
} from "../../global/theme/reuseable/mixins";
import { iSCInnerLink, iSCNav } from "./types";

export const OuterNav = styled.section<iSCNav>`
    position: relative;
    width: "fit-content";
`;

export const WhiteBackgroundFade = styled.section<iSCNav>`
    position: fixed;
    height: 100vh;
    z-index: 10;
    width: ${(props) => (props.open ? "100%" : "0px")};
    opacity: ${(props) => (props.open ? "1" : "0")};
    visibility: ${(props) => (props.open ? "visible" : "hidden")};
    left: 0;
    backdrop-filter: ${(props) =>
        props.open
            ? "blur(3px) brightness(103%)"
            : "blur(0px) brightness(100%)"};
`;

export const NavWrapper = styled.nav<iSCNav>`
    position: fixed;
    left: 0;
    height: 100vh;
    background: ${theme?.gradients?.skyBlue};

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 24px;
    z-index: 11;

    a {
        color: #fafdff;
        text-decoration: none;
    }

    & .side-nav {
        &__links {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
            overflow: auto;
            ${hideScrollbar}
        }
    }
`;

export const OpenNavPanel = styled.div<iSCNav>`
    position: absolute;
    height: 100vw;

    top: 0;
    left: 100%;
    z-index: 10;

    height: 100vh;
    width: 27px;

    ${columnMixin}
    align-items: center;
    justify-content: center;

    background-color: #93c4ef;
    cursor: pointer;

    > img {
        height: 10px;
        width: 10px;
        transform: ${(props) =>
            props.open ? "rotate(0);" : "rotate(180deg);"};
        transition: all 0.3s linear;
    }
`;

export const InnerLink = styled.span<iSCInnerLink>`
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    border-radius: 10px;
    padding-right: ${({ open }) => (open ? "15px;" : "")};

    a {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        white-space: nowrap;
    }

    ${({ active }) =>
        active &&
        `
      a { 
        background: rgba(255, 255, 255, 0.1);
        border-radius: 8.88392px;
      }
      `};

    ${({ open, subLinks }) =>
        open && subLinks
            ? `
      a:hover { 
        border-radius: 8.88392px;
        padding-right: 10px;
        cursor: pointer;
      }
      `
            : null};

    ${({ open, subLinks }) =>
        open && subLinks
            ? `
      > div > div:first-child:hover {
        display: flex;
        align-items: center;
        border-radius: 8.88392px;
        background: rgba(255, 255, 255, 0.1);
      }`
            : null};
    div > div > img:last-child {
        padding-inline: 15px;
    }
`;

export const SubLinks = styled.div<iSCNav>`
    ${columnMixin}
    border-radius: 10px;
    margin-top: 10px;
    transition: all 0.2s ease-in-out;
    ${(props) =>
        props.open
            ? ` 
        max-height: 250px;
        `
            : `
        overflow: hidden;
        height: 0;
      `};

    a {
        padding: 10px;
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        :hover {
            background: rgba(255, 255, 255, 0.4) !important;
        }
    }

    a:first-child {
        border-radius: 10px 10px 0 0;
    }

    a:last-child {
        border-radius: 0 0 10px 10px;
    }

    a:nth-child(odd) {
        background: rgba(255, 255, 255, 0.1);
    }
    a:nth-child(even) {
        background: rgba(255, 255, 255, 0.15);
    }
`;

export const NavIcon = styled.span<iSCNav>`
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
        !props.open
            ? `
    :hover {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 6px;
  }`
            : null}
`;
