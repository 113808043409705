import React, { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { DiaryWrapper, StyledStatusButton } from "./styles";
import { Button, ButtonInput } from "../../../components";
import { FilterParams } from "../../../pages/UserPages/Diary/Diary";
import useFetchData from "../../../global/hooks/useFetchData";
import DropdownSelect from "../../../components/DropdownSelect";
import { listCountText } from "../../../components/DropdownSelect/functions";
import { statusOptions } from "../../../components/DropdownSelect/options";
import useReduxDiary from "../../../store/hooks/useReduxDiary";

interface iFilterDiaryProps {
    className?: string;
    month?: number;
    year?: number;
    onApplyFilter: (...args: any[]) => void;
    isLoading?: boolean;
    isClientCase?: boolean;
}

const FilterMenu = ({
    className = "",
    onApplyFilter,
    isLoading = false,
    isClientCase = false,
}: iFilterDiaryProps) => {
    const { clientId } = useParams();
    const { users } = useReduxDiary();
    const [filterParams, setFilterParams] = useState<FilterParams>({});
    const [selectedCaseId, setSelectedCaseId] = useState<number | null>(null);

    const getClientsList = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/client-search`,
        },
        {
            params: { q: `${filterParams?.case_id ?? ""}` },
        }
    );
    const clientList = getClientsList?.data?.data || [];

    // const whoList = useFetchData({
    //     method: "post",
    //     url: `${process.env.REACT_APP_SERVER_API}/diary-filter-case`,
    // });
    // const whoListData: { [key: string]: any }[] = whoList?.data?.data || [];

    const caseIdHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFilterParams((prev) => {
            return {
                ...prev,
                case_id: +e.target.value.replace(/[^0-9]/, "") || undefined,
            };
        });
    };

    const searchCaseHandler = () => {
        if (filterParams?.case_id) {
            getClientsList.fetchData();
        }
    };

    const updateCaseId = (id: number) => {
        if (id)
            setFilterParams((prev) => {
                return { ...prev, case_id: id };
            });
    };

    const selectHandler = (key: keyof FilterParams, value: any) => {
        setFilterParams((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const resetFilters = () => {
        setFilterParams({
            case_id: undefined,
            status: undefined,
            who: undefined,
        });
        setSelectedCaseId(null);
        getClientsList.setData([]);
    };

    return (
        <DiaryWrapper className="page-filter__panel">
            <h3>FILTER</h3>
            <ButtonInput
                type="text"
                inputType="numString"
                label="Case ID:"
                onChange={caseIdHandler}
                name="case_id"
                value={filterParams?.case_id || ""}
                placeholder="Case ID..."
                onClick={searchCaseHandler}
                btnText="Search"
                autoComplete="off"
                buttonDisabled={!filterParams?.case_id}
                isLoading={getClientsList.isLoading}
            />
            <DropdownSelect
                label="Select Case:"
                value={selectedCaseId ?? undefined}
                options={clientList}
                onSelect={(option) => {
                    selectHandler("case_id", option?.id ?? undefined);
                    updateCaseId(option?.id);
                    setSelectedCaseId(option?.id);
                }}
                enableInput
                optionKey="id"
                labelKeys={["customer_name", "id"]}
                placeholder={listCountText(clientList)}
                hasDefaultValue
                defaultValueLabel="All"
            />
            <DropdownSelect
                label="Who:"
                value={filterParams?.who}
                options={users}
                onSelect={(option) => {
                    selectHandler("who", option?.act_id);
                }}
                enableInput
                optionKey="act_id"
                labelKeys={["whm"]}
                hasDefaultValue
                defaultValueLabel="All"
                placeholder={listCountText(users)}
            />
            {/* <DropdownSelect
                label="Status:"
                value={filterParams?.status}
                options={statusOptions}
                onSelect={(value) => {
                    selectHandler("status", value?.value);
                }}
                hasDefaultValue
                optionKey="value"
                defaultValueLabel="All"
            /> */}
            <div className="page-filter__status-btns">
                <p>Status:</p>
                {statusOptions.map((option) => (
                    <StyledStatusButton
                        className="page-filter__status-btn"
                        key={option.label}
                        value={option.value}
                        onClick={() => {
                            selectHandler("status", option.value);
                        }}
                        status={option?.value as number}
                        data-selected={option.value === filterParams?.status}
                    >
                        {option.label}
                    </StyledStatusButton>
                ))}
            </div>
            <div className="page-filter__btns">
                <Button
                    style="purple"
                    btnText="Apply Filters"
                    onClick={() => {
                        onApplyFilter(filterParams);
                    }}
                    loading={isLoading}
                />
                <Button
                    style="white"
                    btnText="Clear Filters"
                    onClick={resetFilters}
                />
            </div>
        </DiaryWrapper>
    );
};

export default FilterMenu;
