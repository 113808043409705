import { SetStateAction, useEffect } from "react";
import { StyledTablePagination } from "./styles";
import Button from "../Button";

interface TablePaginationProps {
    currentPage: number;
    setCurrentPage: React.Dispatch<SetStateAction<number>>;
    deferredFilteredData: Array<{ [key: string]: any }>;
    searchTerm: string;
    dataPerPage: number;
}

const Pagination: React.FC<TablePaginationProps> = ({
    currentPage,
    setCurrentPage,
    deferredFilteredData,
    searchTerm,
    dataPerPage,
}) => {
    const dataCount = deferredFilteredData?.length || 0;
    const totalPage = Math.ceil(dataCount / dataPerPage);
    const resultRange = () => {
        const lowRange = (currentPage - 1) * dataPerPage;
        let highRange = currentPage * dataPerPage;
        if (highRange > dataCount) highRange = dataCount;
        return `${lowRange} - ${highRange} of ${dataCount}`;
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm]);

    const prevHandler = () => {
        setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
    };

    const nextHandler = () => {
        setCurrentPage((prev) => (prev < totalPage ? prev + 1 : prev));
    };

    if (totalPage > 1)
        return (
            <StyledTablePagination className="data-table-pagination">
                <p className="data-table-pagination__info">{resultRange()}</p>
                <div className="data-table-pagination__btns">
                    <Button
                        className="data-table-pagination__btn"
                        onClick={() => setCurrentPage(1)}
                        disabled={currentPage <= 1}
                        type="button"
                        btnText="<<"
                        style="purple"
                    />
                    <Button
                        className="data-table-pagination__btn"
                        onClick={prevHandler}
                        disabled={currentPage <= 1}
                        type="button"
                        btnText="-"
                        style="purple"
                    />
                    <span className="data-table-pagination__page">
                        {currentPage}/{totalPage}
                    </span>
                    <Button
                        className="data-table-pagination__btn"
                        onClick={nextHandler}
                        disabled={currentPage >= totalPage}
                        btnText="+"
                        style="purple"
                        type="button"
                    />
                    <Button
                        className="data-table-pagination__btn"
                        onClick={() => setCurrentPage(totalPage)}
                        disabled={currentPage >= totalPage}
                        type="button"
                        btnText=">>"
                        style="purple"
                    />
                </div>
            </StyledTablePagination>
        );
    return <></>;
};

export default Pagination;
