import styled from "styled-components";
import { ResizableModalWrapper } from "../styles";

export const StyledCreditorSettingsModal = styled(ResizableModalWrapper)`
    & form {
        min-width: 420px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        & > p {
            font-size: 14px;
            padding: 12px 0;
        }
    }
`;
