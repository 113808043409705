import styled from "styled-components";
import { theme } from "../../../global/theme";
import { mainScrollbar } from "../../../global/theme/reuseable/mixins";

const StyledNotificationCard = styled.div`
    border-radius: 8px;
    background-color: ${theme?.colors.white};
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 122px;

    & .notification-card {
        &__header {
            display: flex;
            align-items: center;
            gap: 8px;
            height: 24px;
            margin-bottom: 10px;
        }

        &__title {
            flex: 1;
            font-size: 14px;
            line-height: 115%;
        }

        &__time {
            background-image: ${theme?.gradients.skyBlue};
            color: ${theme?.colors.white};
            font-size: 11px;
            display: flex;
            align-items: center;
            border-radius: 4px;
            padding: 4px 8px;
        }

        &__link {
            height: 100%;
            width: 24px;

            & path {
                stroke: ${theme?.colors.purple};
            }
        }

        &__message {
            font-size: 11px;
            font-weight: 300;
            border-top: 0.6px solid ${theme?.colors.boxStroke};
            padding: 8px 0;
            flex: 1;
            overflow-y: auto;
            ${mainScrollbar(12)}
        }

        &__remark {
            font-size: 11px;
            align-self: flex-end;
        }
    }
`;

export default StyledNotificationCard;
