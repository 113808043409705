import { TableRowProps } from "../";
import { useNavigate } from "react-router-dom";
import { CreditorStatusBlock, StyledCreditorsRow } from "./styles";

const CreditorsRow: React.FC<TableRowProps> = ({ data, columnWidths }) => {
    const navigate = useNavigate();

    const rowClickHandler = () => {
        if (data?.Id) navigate(`/creditor/${data?.Id}`);
    };

    return (
        <StyledCreditorsRow onClick={rowClickHandler} role="button">
            <td>{data?.["Creditor Name"] ?? "-"}</td>
            <td>{data?.["Phone Number"] ?? "-"}</td>
            <td>{data?.["Address"] ?? "-"}</td>
            <td>{data?.["Preferred Correspondence"] ?? "-"}</td>
            <td>{data?.["No of Client"] ?? "-"}</td>
            <td>{data?.["WET LOA"] ?? "-"}</td>
            <CreditorStatusBlock
                value={data?.["Status"]}
                className="creditor-row-status"
            >
                {data?.Status ?? "-"}
            </CreditorStatusBlock>
        </StyledCreditorsRow>
    );
};
export default CreditorsRow;
