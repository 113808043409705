import React, {
    useState,
    SetStateAction,
    useEffect,
    useLayoutEffect,
} from "react";
import SearchRow from "../../components/SearchRow";
import StyledMessageViewer from "./styles";
import MessageItem from "./MessageItem";
import MessageDetails from "./MessageDetails";

interface MessageViewerProps {
    className?: string;
    messageType?: "email" | "sms";
    messageGroup?: string;
    data: Array<{ [key: string]: any }>;
    dataKey?: string;
    senderKey?: string;
    receiverKey?: string;
    dateKey?: string;
    subjectKey?: string;
    bodyKey?: string;
    selectedIds?: Array<number>;
    setSelectedIds?: React.Dispatch<SetStateAction<Array<number>>>;
    dataPerPage?: number;
    children?: React.ReactNode;
}

export interface MessageViewerComponentProps {
    messageType?: "email" | "sms";
    dataKey?: string;
    senderKey?: string;
    receiverKey?: string;
    dateKey?: string;
    subjectKey?: string;
    bodyKey?: string;
    selectedIds?: Array<number>;
    setSelectedIds?: React.Dispatch<SetStateAction<Array<number>>>;
    selectedId: number | null;
    setSelectedId: React.Dispatch<SetStateAction<number | null>>;
}

const MessageViewer: React.FC<MessageViewerProps> = ({
    className = "",
    messageType = "email",
    data = [],
    dataKey = "id",
    senderKey = "send_by",
    receiverKey = "send_frm",
    dateKey = "datetime",
    subjectKey = "subject",
    bodyKey = "body",
    messageGroup = "inbox",
    selectedIds,
    setSelectedIds,
    dataPerPage = 10,
    children,
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const selectedMessage =
        data.find((obj) => obj[dataKey] === selectedId) || {};
    const filteredData = data.filter((objData) => {
        if (searchTerm.trim() === "") return true;
        const lowerCaseSender = (objData?.[senderKey] || "").toLowerCase();
        const lowerCaseSubject = (objData?.[subjectKey] || "").toLowerCase();
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return (
            lowerCaseSender.includes(lowerCaseSearchTerm) ||
            lowerCaseSubject.includes(lowerCaseSearchTerm)
        );
    });
    const totalPages = Math.ceil(filteredData.length / dataPerPage);
    const isFirstPage = currentPage <= 1;
    const isLastPage = currentPage >= totalPages;
    const pageMinRange = (currentPage - 1) * dataPerPage;
    const pageMaxRange = currentPage * dataPerPage;
    const actualPageMaxRange =
        filteredData.length <= pageMaxRange
            ? filteredData.length
            : pageMaxRange;

    const currentPageDisplay = `${
        1 + pageMinRange
    } - ${actualPageMaxRange} of ${filteredData.length} `;
    const pageData = [...filteredData].slice(pageMinRange, pageMaxRange);

    const currentPageIds: Array<number> = pageData.map(
        (pageData) => pageData?.[dataKey]
    );
    const isCurrentPageAllChecked =
        currentPageIds.every((id) => selectedIds?.includes(id)) &&
        !!selectedIds?.length;

    const isCurrentPageChecked = currentPageIds.some(
        (id) => !!selectedIds && selectedIds.includes(id)
    );

    useEffect(() => {
        if (setSelectedIds) setSelectedIds([]);
    }, [searchTerm]);

    const checkAllHandler = () => {
        if (setSelectedIds)
            setSelectedIds((prev) =>
                isCurrentPageChecked
                    ? prev.filter((prevId) => !currentPageIds.includes(prevId))
                    : [...prev, ...currentPageIds].sort((a, b) => a - b)
            );
    };

    useLayoutEffect(() => {
        if (!!pageData?.length && !selectedId)
            setSelectedId(pageData[0][dataKey]);
    }, [!selectedId, pageData]);

    const prevPageHandler = () => {
        if (!isFirstPage) setCurrentPage((prev) => prev - 1);
    };

    const nextPageHandler = () => {
        if (!isLastPage) setCurrentPage((prev) => prev + 1);
    };

    const componentProps: MessageViewerComponentProps = {
        messageType,
        dataKey,
        senderKey,
        receiverKey,
        dateKey,
        subjectKey,
        bodyKey,
        selectedIds,
        setSelectedIds,
        selectedId,
        setSelectedId,
    };
    return (
        <StyledMessageViewer
            className={`message-viewer${className ? ` ${className}` : ""}`}
            hasCheckbox={!!selectedIds}
        >
            <SearchRow
                className="message-viewer__search"
                value={searchTerm}
                setValue={setSearchTerm}
                placeholder={`Search by sender${
                    messageType === "email" ? " or subject" : ""
                }...`}
            >
                {children}
            </SearchRow>
            <div className="message-viewer__table">
                <div
                    className="message-viewer__row message-viewer__row--head"
                    data-selected={!!selectedId}
                >
                    {pageData?.length ? (
                        <>
                            <div
                                className="message-viewer__th message-viewer__th--checkbox"
                                aria-hidden={!!selectedId}
                            >
                                {!!selectedIds && (
                                    <input
                                        className="message-viewer__checkbox"
                                        type="checkbox"
                                        checked={isCurrentPageAllChecked}
                                        onChange={checkAllHandler}
                                    />
                                )}
                            </div>

                            <div className="message-viewer__th message-viewer__th--group">
                                {messageGroup}{" "}
                                <span>
                                    <div className="message-viewer__pagination">
                                        {filteredData?.length
                                            ? currentPageDisplay
                                            : null}
                                        <button
                                            className="message-viewer__pagination__btn"
                                            type="button"
                                            onClick={prevPageHandler}
                                            disabled={isFirstPage}
                                        >{`<`}</button>
                                        <button
                                            className="message-viewer__pagination__btn"
                                            type="button"
                                            onClick={nextPageHandler}
                                            disabled={isLastPage}
                                        >{`>`}</button>
                                    </div>
                                </span>
                            </div>
                            <div className="message-viewer__th">Message</div>
                        </>
                    ) : (
                        <div className="message-viewer__th message-viewer__th--full-col">
                            &nbsp;
                        </div>
                    )}
                </div>
                {pageData?.length ? (
                    <div
                        className="message-viewer__row message-viewer__row--body"
                        data-selected={!!selectedId}
                    >
                        <ul className="message-viewer__list">
                            {pageData.map((data, i) => (
                                <MessageItem
                                    key={data?.[dataKey] ?? i}
                                    data={data}
                                    {...componentProps}
                                />
                            ))}
                        </ul>
                        <MessageDetails
                            selectedMessage={selectedMessage}
                            {...componentProps}
                        />
                    </div>
                ) : (
                    <div className="message-viewer__empty">
                        No {messageType === "email" ? "Mail" : "Message"} found.
                    </div>
                )}
            </div>
        </StyledMessageViewer>
    );
};

export default MessageViewer;
