import React, { SetStateAction } from "react";
import { useParams } from "react-router-dom";
import { Input, TextArea, Button } from "../../../components";
import DropdownSelect from "../../../components/DropdownSelect";
import { StyledBasicInfo } from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxIntroducers from "../../../store/hooks/useReduxIntroducers";

interface BasicInfoProps {
    introducer: { [key: string]: any };
    setIntroducer: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
    isLoading?: boolean;
}

const BasicInfo: React.FC<BasicInfoProps> = ({
    isLoading,
    introducer,
    setIntroducer,
}) => {
    const { setTypes, types } = useReduxIntroducers();
    const { introducerId } = useParams();

    const changeEventHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value, name } = e.target;
        setIntroducer((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setIntroducer((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const submitIntroducer = useFetchData(
        {
            method: "PUT",
            url: `${process.env.REACT_APP_SERVER_API}/introducer/${introducerId}`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Introducer Details Updated Sucessfully",
            onSuccess: (data) => {
                setIntroducer(data?.data);
            },
        }
    );
    const submitHandler = (e: any) => {
        e.preventDefault();
        const requestData = {
            nme: introducer?.nme,
            type: introducer?.type,
            address: introducer?.address,
            contact: introducer?.contact,
            tlphne1: introducer?.tlphne1,
            email: introducer?.email,
            act_name: introducer?.act_name,
            act_no: introducer?.act_no,
            sortcode: introducer?.sortcode,
        };
        submitIntroducer.fetchData(requestData);
    };
    return (
        <StyledBasicInfo className="basic-info">
            <form className="basic-info__form" onSubmit={submitHandler}>
                <div className="basic-info__container">
                    <h2>Basic Information</h2>
                    <Input
                        className="basic-info__input"
                        type="text"
                        label="Company Name:"
                        placeholder="Enter your name..."
                        name="nme"
                        value={introducer?.nme || ""}
                        onChange={changeEventHandler}
                        autoComplete="auto-off"
                        required
                    />
                    <TextArea
                        label="Address"
                        placeholder="Enter new address..."
                        name="address"
                        className="basic-info__text-area"
                        value={introducer?.address || ""}
                        onChange={changeEventHandler}
                    />
                    <DropdownSelect
                        label="Type:"
                        value={introducer?.type || null}
                        onSelect={(option) => selectHandler("type", option?.id)}
                        options={types}
                        optionKey="id"
                        labelKeys={["name"]}
                        className="basic-info__select"
                        isLoading={isLoading}
                    />
                </div>
                <div className="basic-info__container">
                    <h2>Contact Information</h2>
                    <Input
                        className="basic-info__input"
                        type="text"
                        label="Contact Name"
                        placeholder="Contact Name..."
                        onChange={changeEventHandler}
                        name="contact"
                        value={introducer?.contact || ""}
                        autoComplete="auto-off"
                        required
                    />
                    <Input
                        className="basic-info__input"
                        type="tel"
                        label="Telephone"
                        placeholder="Telephone..."
                        onChange={changeEventHandler}
                        value={introducer?.tlphne1}
                        name="tlphne1"
                        autoComplete="auto-off"
                    />
                    <Input
                        className="basic-info__input"
                        type="email"
                        label="Email Address"
                        placeholder="Email..."
                        onChange={changeEventHandler}
                        value={introducer?.email || ""}
                        name="email"
                        autoComplete="auto-off"
                        required
                    />
                </div>
                <div className="basic-info__container">
                    <h2>Accounting Information</h2>
                    <Input
                        className="basic-info__input"
                        type="text"
                        label="Account Name"
                        onChange={changeEventHandler}
                        value={introducer?.act_name}
                        placeholder="Account Name..."
                        name="act_name"
                    />
                    <Input
                        className="basic-info__input"
                        type="text"
                        inputType="numString"
                        label="Account Number"
                        value={introducer?.act_no}
                        onChange={changeEventHandler}
                        placeholder="Account Number..."
                        name="act_no"
                    />
                    <Input
                        className="basic-info__input"
                        type="text"
                        label="Sort Code"
                        inputType="sortCode"
                        placeholder="Sort Code..."
                        onChange={changeEventHandler}
                        onBlur={changeEventHandler}
                        value={introducer?.sortcode}
                        name="sortcode"
                    />
                </div>
                <Button
                    type="submit"
                    btnText="Submit"
                    style="mint"
                    loading={submitIntroducer.isLoading}
                />
            </form>
        </StyledBasicInfo>
    );
};

export default BasicInfo;
