import React, { useState } from "react";
import { capitalize } from "lodash";
import { EditLiabilityFormProps } from ".";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import useFetchData from "../../../../global/hooks/useFetchData";
import Input from "../../../Input";

const SendFullFinal: React.FC<EditLiabilityFormProps> = ({
    onClose,
    data,
    setCurrentForm,
    onSuccess = () => {},
    setLiabilityDetails,
}) => {
    const [amount, setAmount] = useState<number | null>(null);
    const isFormValid = amount !== null;

    const sendFullFinal = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/liability-action/${data.id}`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Full & Final proposal sent",
            onSuccess: (data) => {
                // navigate to main form (LiabilityMain.tsx)
                setCurrentForm("");
                // update table data
                onSuccess();
                // udpate liability details in index.tsx
                if (data?.data) setLiabilityDetails(data.data);
            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (data?.id) {
            let temp ={
                action_type : "Full-and-final-proposal",
                amount : amount
            }
            return sendFullFinal.fetchData({ data:temp });
        }
    };

    return (
        <>
            <form onSubmit={submitHandler} className="send-full-final">
                <Input
                    label="Full & Final Amount (£):"
                    type="number"
                    value={`${amount ?? ""}`}
                    onChange={(e) => setAmount(+e.target.value)}
                />
                <ModalButtons>
                    <Button
                        type="submit"
                        style="purple"
                        btnText="Send"
                        disabled={!isFormValid}
                        loading={sendFullFinal.isLoading}
                    />
                    <Button
                        type="button"
                        style="cancel"
                        btnText="Back"
                        onClick={() => setCurrentForm("")}
                    />
                </ModalButtons>
            </form>
        </>
    );
};

export default SendFullFinal;
