import React, { useState } from "react";
import moment from "moment";
import { Column, Row } from "../../../global/theme/reuseable/components";
import { ButtonContainer, DateSearchContainer } from "./styles";
import { Modal } from "../../../components/index";
import {
    Button,
    SelectButton,
    CalendarDateSearch,
    DateMenuHeader,
} from "../../../components/index";
import useReduxUI from "../../../store/hooks/useReduxUI";
import {
    dateFormat,
    DateSearchOption,
    defaultDateSearchOptions,
    allDatesSearchOptions,
} from "./data";

export const initDateRangedata: DateSearchOption = {
    from: "",
    to: "",
    displayText: "All Dates",
};

export interface DateSelectProps {
    open: boolean;
    isLoading?: boolean;
    initialDateRange?: DateSearchOption;
    onSubmit?: (data: DateSearchOption) => void;
    submitBtnText?: string;
    options: Array<DateSearchOption>;
}

const DateSearch = ({
    open,
    onSubmit = () => {},
    initialDateRange,
    isLoading,
    submitBtnText = "Submit",
    options = defaultDateSearchOptions,
}: DateSelectProps) => {
    const [isCustomMode, setIsCustomMode] = useState(false);
    const [dateRange, setDateRange] = useState<DateSearchOption>(
        initialDateRange || initDateRangedata
    );
    const { setSideMenu } = useReduxUI();

    const dateRangeNames = Object.values(allDatesSearchOptions).map(
        (opt) => opt.displayText
    );
    const isCustomRangeSelected = !dateRangeNames.includes(
        dateRange?.displayText
    );
    const dateStrToDate = (dateStr: string) => {
        return moment(
            dateStr || moment().format(dateFormat),
            dateFormat
        ).toDate();
    };
    const initialStartDate = dateStrToDate(initialDateRange?.from || "");
    const initialEndDate = dateStrToDate(initialDateRange?.to || "");

    const dateButtons = options.map((option) => {
        return (
            <SelectButton
                key={option.displayText}
                btnText={option.displayText}
                selected={dateRange.displayText === option.displayText}
                onClick={() => setDateRange(option)}
                type="button"
            />
        );
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(dateRange);
        // setSideMenu("");
    };

    return (
        <Modal isVisible={open}>
            <DateSearchContainer open={open} large={isCustomMode}>
                <DateMenuHeader
                    setIsCustomMode={setIsCustomMode}
                    isCustomMode={isCustomMode}
                    displayText={isCustomMode ? "Custom Range" : ""}
                />
                <Column gap="24px">
                    {isCustomMode ? (
                        <CalendarDateSearch
                            onDateChange={setDateRange}
                            initialStartDate={initialStartDate}
                            initialEndDate={initialEndDate}
                        />
                    ) : (
                        <>
                            <ButtonContainer show>
                                {dateButtons}
                                <SelectButton
                                    type="button"
                                    btnText="Custom Range"
                                    selected={isCustomRangeSelected}
                                    onClick={() => setIsCustomMode(true)}
                                />
                            </ButtonContainer>
                        </>
                    )}
                    <form onSubmit={handleSubmit}>
                        <Row gap="18px" className="submit-btns">
                            <Button
                                btnText={submitBtnText}
                                type="submit"
                                style="purple"
                                loading={isLoading}
                            />
                            <Button
                                btnText="Cancel"
                                type="button"
                                onClick={setSideMenu.bind(null, "")}
                                style="cancel"
                            />
                        </Row>
                    </form>
                </Column>
            </DateSearchContainer>
        </Modal>
    );
};

export default DateSearch;
