import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from ".";
import { creditorsActions } from "../slices/creditorsSlice";

const useReduxCreditors = () => {
    const { list } = useAppSelector((state) => state.creditors);
    const dispatch = useAppDispatch();

    const setList = (data: Array<{ [key: string]: any }>) => {
        dispatch(creditorsActions.setList(data));
    };

    return {
        list,
        setList,
    };
};

export default useReduxCreditors;
