import { useState } from "react";
import { useParams } from "react-router-dom";

import ModalHeader from "../../components/ModalHeader";
import { StyledCaseActionsSendTo } from "./styles";
import DropdownSelect from "../../../DropdownSelect";
import { caseActionsSendToDurationOptions } from "../../../DropdownSelect/options";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import useReduxUser from "../../../../store/hooks/useReduxUser";
import { CaseActionsModalProps } from "./types";

interface CaseActionsSendToProps extends CaseActionsModalProps {
    type: string;
}

const CaseActionsSendTo: React.FC<CaseActionsSendToProps> = ({
    isOpen,
    onClose,
    type,
}) => {
    const [sendTo, mode] = type.split("-");
    const generateDocuments = mode === "new";
    const { clientId } = useParams();
    const { setClientCase } = useReduxUser();
    const [duration, setDuration] = useState<number>(
        caseActionsSendToDurationOptions[0].value as number
    );
    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/send-to-action/${clientId}`,
        },
        {
            onSuccess: (data) => {
                // if (data?.data) setClientCase(data.data);
            },
        }
    );
    const sendToName =
        sendTo === "mellors" ? "Mellor CHUB system" : "Woodlands CHUB system";

    const sendToId = () => {
        if (sendTo === "mellors") {
            return generateDocuments ? 1 : 2;
        }
        return generateDocuments ? 3 : 4;
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({
            duration,
            // location: sendTo,
            // generate_doc: generateDocuments ? 1 : 0,
            send_to: sendToId(),
        });
    };

    return (
        <StyledCaseActionsSendTo
            show={isOpen}
            width="460px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={onClose}>Please Confirm...</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message={`Client details have been sent to ${sendToName}`}
                    onClose={onClose}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <p>
                        Send client details to {sendToName}
                        {generateDocuments
                            ? ""
                            : " with no documents generated"}
                        .
                    </p>
                    <DropdownSelect
                        label="Do you want to continue?"
                        options={caseActionsSendToDurationOptions}
                        value={duration}
                        name="status"
                        onSelect={(option) => setDuration(option.value)}
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Continue"
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledCaseActionsSendTo>
    );
};

export default CaseActionsSendTo;
