import React, { useState, useEffect, SetStateAction } from "react";
import { icons } from "../../../assets/icons";
import { Button } from "../../../components";
import { TablePageContent } from "../../../pages/styles";
import Header from "../../../layout/Header";
import DateButton from "../../../components/Button/DateButton";
import useFetchData from "../../../global/hooks/useFetchData";
import PageTabs from "../../../components/PageTabs/PageTabs";
import SearchRow from "../../../components/SearchRow";
import { dateSearchOptions } from "../../../layout/SlideInMenus/DateSearch/data";
import useDateSearch from "../../../layout/SlideInMenus/DateSearch/useDateSearch";
import { exportCsv } from "../../../global/utils/helpers";
import DataTable from "../../../components/DataTable";
import { EditIntroducerTable } from "./styles";
import { useParams } from "react-router-dom";

const UserAccounts = (userData: any) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [userDetails, setIntroducerDetails] = useState([]);
    const { introducerId } = useParams();
    const heading = [["name", "email"]];

    // const exportHandler = () => {
    //     exportCsv(
    //         invoiceBodies,
    //         heading,
    //         ["User Accounts"],
    //         `User Account Details`
    //     );
    // };

    const searchUserIntroducers = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/introducer-user-search`,
    });
    const introducersData = userData?.userData || [];
    const searchedIntroducersData =
        searchUserIntroducers?.data?.data?.users || [];
    const displayedData =
        searchTerm.length > 3 && !searchUserIntroducers.isLoading
            ? searchedIntroducersData
            : introducersData;

    const searchHandler = (value: string) => {
        if (value.length > 3)
            searchUserIntroducers.fetchData({ name: value, id: introducerId });
    };

    return (
        <EditIntroducerTable>
            <SearchRow
                placeholder="Search by case id..."
                value={searchTerm}
                setValue={setSearchTerm}
                onSearch={searchHandler}
                minCharacters={1}
            >
                {/* <Button
                    btnText=""
                    onClick={exportHandler}
                    style="red"
                    btnImg={icons?.exportIcon}
                /> */}
            </SearchRow>
            <DataTable
                labels={["Id", "Name", "Email"]}
                dataKey={"id"}
                hideColums={[0]}
                data={displayedData}
                isLoading={false}
            />
        </EditIntroducerTable>
    );
};

export default UserAccounts;
