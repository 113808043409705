import styled from "styled-components";
import { theme } from "../../global/theme";

interface StyledStepTabsProps {
    gap: number;
}

const StyledStepTabs = styled.nav<StyledStepTabsProps>`
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;

    & > ul {
        column-gap: ${(props) => props.gap}px;
    }
    & .step-tabs {
        &__tabs {
            display: flex;
            align-items: center;
            list-style: none;
            position: relative;
            margin-bottom: 12px;
        }

        &__tab {
        }

        &__btn {
            border: none;
            padding: 8px 16px;
            border-radius: 100px;
            cursor: pointer;
            background-color: transparent;
            transform: all 0.2s ease;
            font-size: 12px;

            &[data-selected="true"] {
                color: ${theme?.colors.purple};
                background-color: #eeeeee;
            }

            &:disabled {
                pointer-events: none;
            }
        }

        &__thumb {
            position: absolute;
            height: 2px;
            width: 40px;
            background-color: ${theme?.colors.purple};
            bottom: -8px;
            left: 0;
            transition: transform 0.2s ease;
        }
    }
`;

export default StyledStepTabs;
