import React, { useState, useLayoutEffect } from "react";
import { dateSearchOptions, DateSearchOption } from "./data";
import useReduxUI from "../../../store/hooks/useReduxUI";
import { allDatesSearchOptions } from "./data";

const useDateSearch = (
    initialDateOptionKey: keyof typeof allDatesSearchOptions = "all",
    autoSetDisplayText: boolean = false
) => {
    const initialDateRange = allDatesSearchOptions[initialDateOptionKey];
    const { setSideMenu } = useReduxUI();
    const [dateRange, setDateRange] =
        useState<DateSearchOption>(initialDateRange);
    const [dateDisplay, setDateDisplay] = useState("");

    useLayoutEffect(() => {
        if (autoSetDisplayText) setDateDisplay(dateRange.displayText);
    }, [autoSetDisplayText, dateRange.displayText]);

    const onDateSearchSuccess = () => {
        setDateDisplay(dateRange.displayText);
        setSideMenu("");
    };

    return {
        dateRange,
        setDateRange,
        dateDisplay,
        setDateDisplay,
        onDateSearchSuccess,
    };
};

export default useDateSearch;
