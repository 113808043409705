import React, { useEffect, useLayoutEffect, useState } from "react";
import { FormContainerWrapper } from "../../../../../containers/User/Client/styles";
import FormStepContainerHeader from "../../../../../atoms/FormStepContainerHeader";
import { useNavigate, useLocation } from "react-router-dom";
import useFetchData from "../../../../../global/hooks/useFetchData";
import { Button, Modal } from "../../../../../components";
import AddCardDetails from "../../../../../components/Modals/ClientCreate/AddCardDetails";
import useReduxCreateClient from "../../../../../store/hooks/useReduxCreateClient";

interface AddCardDetailsProps {
    onAddSuccess: (...args: any[]) => void;
}

const CardDetails = ({ onAddSuccess }: AddCardDetailsProps) => {
    const { payment } = useReduxCreateClient();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get("id");
    const resourcePath = searchParams.get("resourcePath");

    const sendId = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/successApi`,
            params: {
                id,
                resourcePath,
            },
        },
        {
            onSuccess: (data) => {
                navigate(pathname);
                onAddSuccess();
            },
        }
    );
    const cardsData = payment?.card_details || [];

    useLayoutEffect(() => {
        if (id) sendId.fetchData();
    }, [id]);

    return (
        <>
            <Modal isVisible={isModalOpen}>
                <AddCardDetails
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                />
            </Modal>
            <FormContainerWrapper className="card-details">
                <FormStepContainerHeader title="Card Details" />
                <div className="content">
                    {cardsData?.length ? (
                        <ul className="card-details__cards">
                            {cardsData.map((card: { [key: string]: any }) => (
                                <li
                                    key={card?.id}
                                    className="card-details__card"
                                >
                                    <p>
                                        Name: <span>{card?.nme || "--"}</span>
                                    </p>
                                    <p>
                                        Type: <span>{card?.type || "--"}</span>
                                    </p>
                                    <p>
                                        Expired End:{" "}
                                        <span>{`${card?.expry_m}/${card?.expry_y}`}</span>
                                    </p>
                                    <p>
                                        Last digits:{" "}
                                        <span>{card?.num || "---"}</span>
                                    </p>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="card-details__empty">No Card Created</p>
                    )}
                    <Button
                        btnText={`${
                            cardsData?.length ? "Change" : "Add"
                        } Card Details`}
                        onClick={() => setIsModalOpen(true)}
                        style="purple"
                        type="button"
                    />
                </div>
            </FormContainerWrapper>
        </>
    );
};

export default CardDetails;
