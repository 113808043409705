import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import ModalHeader from "../components/ModalHeader";
import { StyledCreateCorres } from "./styles";
import SuccessContent from "../components/SuccessContent";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import TextArea from "../../Input/TextArea";
import DropdownSelect from "../../DropdownSelect";
import { correspondenceDirectionOptions } from "../../DropdownSelect/options";
import Checkbox from "../../Input/Checkbox";
import useReduxClientCorrespondence from "../../../store/hooks/useReduxClientCorrespondence";

interface CreateCorreCallProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
}

const CreateCorresCall: React.FC<CreateCorreCallProps> = ({
    isOpen,
    onClose,
}) => {
    const { setList } = useReduxClientCorrespondence();
    const { clientId } = useParams();
    const [callData, setCallData] = useState<{ [key: string]: any }>({
        direction: "",
        debt_id: null,
        notes: "",
        courtesy_call: 0,
        welcome_call: 0,
        electricity: 0,
        insurance: 0,
        mobile: 0,
    });
    const isFormValid = !!callData.debt_id;

    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/create-call/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setList(data?.data);
            },
        }
    );

    const inputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setCallData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setCallData((prev) => {
            return { ...prev, [name]: checked ? 1 : 0 };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setCallData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const getDebtsList = useFetchData({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/get-debts/${clientId}`,
    },{

        fetchOnLoad: true,
    });
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({
            type: "call",
            data: callData,
        });
    };

    return (
        <StyledCreateCorres
            className="create-corres create-corres--call"
            show={isOpen}
            width="500px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={onClose}>
                Create Correspondence Call
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    onClose={onClose}
                    message="Note has been created."
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <DropdownSelect
                        label="Direction:"
                        options={correspondenceDirectionOptions}
                        value={callData.direction}
                        onSelect={(option) =>
                            selectHandler("direction", option.value ?? "")
                        }
                        hasDefaultValue
                    />
                    <DropdownSelect
                        label="Debt:"
                        options={getDebtsList?.data?.data?.debt || []}
                        isLoading={getDebtsList.isLoading}
                        enableInput
                        value={callData?.debt_id ?? null}
                        optionKey="id"
                        labelKeys={["name"]}
                        onSelect={(option) =>
                            selectHandler("debt_id", option.id)
                        }
                    />
                    <TextArea
                        label="Notes:"
                        value={callData.notes}
                        name="notes"
                        onChange={inputHandler}
                    />
                    <div className="checkbox-group">
                        <h3>Call Markers:</h3>
                        <div className="checkbox-group__checkboxes">
                            <Checkbox
                                label="Courtesy Call"
                                checked={callData?.courtesy_call}
                                name="courtesy_call"
                                onChange={checkboxHandler}
                            />
                            <Checkbox
                                label="Welcome Call"
                                checked={callData?.welcome_call}
                                name="welcome_call"
                                onChange={checkboxHandler}
                            />
                        </div>
                    </div>
                    <div className="checkbox-group">
                        <h3>Personal Budgeting:</h3>
                        <div className="checkbox-group__checkboxes">
                            <Checkbox
                                label="Electricty / Gas"
                                checked={callData?.electricity}
                                name="electricity"
                                onChange={checkboxHandler}
                            />
                            <Checkbox
                                label="Insurance"
                                checked={callData?.insurance}
                                name="insurance"
                                onChange={checkboxHandler}
                            />
                            <Checkbox
                                label="Mobile"
                                checked={callData?.mobile}
                                name="mobile"
                                onChange={checkboxHandler}
                            />
                        </div>
                    </div>

                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Create"
                            disabled={!isFormValid}
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledCreateCorres>
    );
};

export default CreateCorresCall;
