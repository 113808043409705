import { ChangeEvent, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import FormStepContainerHeader from "../../../../../atoms/FormStepContainerHeader";
import { Input, TextArea } from "../../../../../components";
import { FormContainerWrapper } from "../../../../../containers/User/Client/styles";
import { StyledBusinessAccountancy } from "./styles";
import { ClientFormStepProps } from "../ClientForm";
import ClientFormStepButtons from "../ClientFormStepButtons";
import useFetchData from "../../../../../global/hooks/useFetchData";
import useReduxCreateClient from "../../../../../store/hooks/useReduxCreateClient";

const BusinessAccountancy: React.FC<ClientFormStepProps> = ({
    setCurrentStep,
    isFirstStep,
    isLastStep,
}) => {
    const { clientId } = useParams();
    const { setDataByKey, business_accountancy } = useReduxCreateClient();
    const [formData, setFormData] = useState({
        cnme: business_accountancy?.cnme || "",
        nmbr: business_accountancy?.nmbr || "",
        addrss: business_accountancy?.addrss || "",
        chac: business_accountancy?.chac || "",
        UTR: business_accountancy?.["UTR"] || "",
        nmbr_dir: business_accountancy?.nmbr_dir || 0,
        nmbr_empl: business_accountancy?.nmbr_empl || 0,
        gg_id: business_accountancy?.["gg_id"] || "",
        gg_pass: business_accountancy?.["gg_pass"] || "",
        sge_ref: business_accountancy?.["sge_ref"] || "",
        incrprtn: business_accountancy?.["incrprtn"] || "",
        confirm_stmt_date: business_accountancy?.["confirm_stmt_date"] || "",
        account_ref: business_accountancy?.["account_ref"] || "",
        a_yr_ed_date: business_accountancy?.["a_yr_ed_date"] || "",
        sub_date: business_accountancy?.["sub_date"] || "",
        PAYE_Ref: business_accountancy?.["PAYE_Ref"] || "",
        vat_id: business_accountancy?.["vat_id"] || "",
        vat_return: business_accountancy?.["vat_return"] || "",
    });

    const { fetchData, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/business-accountancy`,
        },
        {
            onSuccess: (data) => {
                setCurrentStep((prev) => prev + 1);
                setDataByKey("business_accountancy", data?.data);
            },
            notifyOnSuccess: true,
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (clientId) {
            const data = {
                id: +clientId || null,
                tab: "business-accountancy",
                data: { id: +clientId || null, ...formData },
            };
            console.log(data);
            fetchData(data);
        }
    };

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    return (
        <StyledBusinessAccountancy
            className="client-form__content"
            onSubmit={submitHandler}
        >
            <FormContainerWrapper>
                <FormStepContainerHeader title="Business Accountancy" />

                <div className="content content--business">
                    <Input
                        label="Name"
                        type="text"
                        name="cnme"
                        value={formData.cnme}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <Input
                        label="Company Number"
                        type="number"
                        name="nmbr"
                        value={formData.nmbr}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <TextArea
                        label="Address"
                        name="addrss"
                        value={formData.addrss}
                        onChange={handleChange}
                    />
                    <Input
                        label="Authentication Code"
                        type="number"
                        name="chac"
                        value={formData.chac}
                        onChange={handleChange}
                        autoComplete="utrNumber"
                    />
                    <Input
                        label="UTR Number"
                        type="number"
                        name="UTR"
                        value={formData["UTR"]}
                        onChange={handleChange}
                    />
                    <Input
                        label="Number of Directors"
                        type="number"
                        name="nmbr_dir"
                        value={formData.nmbr_dir.toString()}
                        onChange={handleChange}
                        step={1}
                    />
                    <Input
                        label="Number of Employees"
                        type="number"
                        name="nmbr_empl"
                        value={formData.nmbr_empl.toString()}
                        onChange={handleChange}
                        step={1}
                    />
                    <Input
                        label="Government Gateway Username"
                        type="text"
                        name="gg_id"
                        value={formData["gg_id"]}
                        onChange={handleChange}
                    />
                    <Input
                        label="Government Gateway Password"
                        type="password"
                        name="gg_pass"
                        value={formData["gg_pass"]}
                        onChange={handleChange}
                        hasShowPassword
                    />
                    <Input
                        label="Sage Reference Number"
                        type="text"
                        name="sge_ref"
                        value={formData["sge_ref"]}
                        onChange={handleChange}
                    />
                </div>
            </FormContainerWrapper>
            <FormContainerWrapper>
                <FormStepContainerHeader title="Accounts Information" />
                <div className="content content--account">
                    <Input
                        label="Date of Incorporation"
                        type="date"
                        name="incrprtn"
                        placeholder="e.g. 25-12-2022"
                        value={moment(
                            formData["incrprtn"],
                            "DD-MM-YYYY"
                        ).format("YYYY-MM-DD")}
                        onChange={handleChange}
                    />
                    <Input
                        label="Confirmation Statement Date"
                        type="date"
                        name="confirm_stmt_date"
                        value={formData.confirm_stmt_date}
                        onChange={handleChange}
                    />
                    <Input
                        label="Accounts Reference Number"
                        type="text"
                        name="account_ref"
                        value={formData.account_ref}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <Input
                        label="Account Year End"
                        type="date"
                        name="a_yr_ed_date"
                        value={formData.a_yr_ed_date}
                        onChange={handleChange}
                    />
                    <Input
                        label="Submission Date"
                        type="date"
                        name="sub_date"
                        value={formData.sub_date}
                        onChange={handleChange}
                    />
                    <Input
                        label="PAYE Reference Number"
                        type="number"
                        name="PAYE_Ref"
                        value={formData["PAYE_Ref"]}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <Input
                        label="VAT Number"
                        type="number"
                        name="vat_id"
                        value={formData["vat_id"]}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <Input
                        label="Period of Return"
                        type="number"
                        name="vat_return"
                        value={formData["vat_return"]}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                </div>
            </FormContainerWrapper>
            <ClientFormStepButtons
                onPrevClick={() => setCurrentStep((prev) => prev - 1)}
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                isSubmitting={isLoading}
            />
        </StyledBusinessAccountancy>
    );
};

export default BusinessAccountancy;
