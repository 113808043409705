import { useState } from "react";
import { useParams } from "react-router-dom";

import ModalHeader from "../../components/ModalHeader";
import { StyledRequestCancellation } from "./styles";
import DropdownSelect from "../../../DropdownSelect";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import useReduxUser from "../../../../store/hooks/useReduxUser";
import {
    cancellationFeeTypeOptions,
    clientCaseStatusOptions,
    reasonForCancellationOptions,
} from "../../../DropdownSelect/options";
import Input from "../../../Input";
import { CaseActionsModalProps } from "./types";
import TextArea from "../../../Input/TextArea";

const RequestCancellation: React.FC<CaseActionsModalProps> = ({
    isOpen,
    onClose,
}) => {
    const { clientId } = useParams();
    const { clientCase, setClientCase, user } = useReduxUser();
    const userId = user?.id;
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        reason: "",
        next_company: "",
        cancellation_fee_type: null,
        refund_amount: null,
        additional_info: "",
    });
    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/request-cancellation`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setClientCase(data.data);
            },
        }
    );

    const inputHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type } = e.target;
        setFormData((prev) => {
            return { ...prev, [name]: type === "number" ? +value : value };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setFormData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (clientId && userId) {
            const requestData = {
                client_id: +clientId,
                user_id: userId,
                ...formData,
            };
            console.log(requestData);
            fetchData(requestData);
        }
    };

    return (
        <StyledRequestCancellation
            show={isOpen}
            isSuccess={isSuccess}
            width="500px"
        >
            <ModalHeader onClose={onClose}>Request Cancellation</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="Your cancellation request has been sent."
                    onClose={onClose}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <p>
                        Client ID:<span>{clientId}</span>
                    </p>
                    <p>
                        Requested User ID:<span>{userId || "-"}</span>
                    </p>
                    <DropdownSelect
                        label="Reason for Cancellation:"
                        options={reasonForCancellationOptions}
                        value={formData?.reason || null}
                        onSelect={(option) =>
                            selectHandler("reason", option?.value)
                        }
                    />
                    <DropdownSelect
                        label="Has a cancellation fee been agreed upon? If yes, full or partial:"
                        options={cancellationFeeTypeOptions}
                        value={formData?.cancellation_fee_type || null}
                        onSelect={(option) =>
                            selectHandler(
                                "cancellation_fee_type",
                                option?.value || null
                            )
                        }
                        hasDefaultValue
                    />
                    <Input
                        label="Is the client going to another company? If so who?"
                        type="text"
                        value={formData?.next_company || ""}
                        name="next_company"
                        onChange={inputHandler}
                        placeholder=""
                    />
                    <Input
                        label="Are you requesting a refund? If so how much?"
                        type="number"
                        value={formData?.refund_amount || ""}
                        name="refund_amount"
                        onChange={inputHandler}
                        placeholder=""
                    />
                    <TextArea
                        label="Any additional information?"
                        value={formData?.additional_info || ""}
                        name="additional_info"
                        onChange={inputHandler}
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Submit"
                            loading={isLoading}
                            disabled={!clientId || !userId}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledRequestCancellation>
    );
};

export default RequestCancellation;
