import React, { useState } from "react";
import moment from "moment";
import { DayWrapper, StyledMeetingCard } from "./styles";
import { ModalContainer } from "../../../components";
import DayMeetings from "../../../components/Modals/Diary/DayMeetings";

export interface iDayTile {
    dayData: Array<{ [key: string]: any }>;
    date: string;
    currentMonth: boolean;
}
const DayTile = ({ dayData, date, currentMonth }: iDayTile) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <ModalContainer
                isVisible={showModal}
                children={
                    <DayMeetings
                        setShowModal={setShowModal}
                        open={showModal}
                        dayData={dayData}
                        date={date}
                    />
                }
            />
            <DayWrapper
                key={date}
                onClick={() => setShowModal(true)}
                currentMonth={currentMonth}
                aria-current={currentMonth}
                data-today={moment(date, "YYYY-MM-DD").isSame(moment(), "day")}
            >
                <p className="date">
                    {moment(date, "YYYY-MM-DD").format("DD")}
                </p>
                <div className="meetings">
                    {dayData?.length ? (
                        dayData!.map((data: { [key: string]: any }) => (
                            <StyledMeetingCard
                                key={data?.id}
                                status={data?.stts}
                            >
                                <strong>
                                    {moment(data?.whn).format("HH:mm")}
                                </strong>
                                {data?.smmry}
                            </StyledMeetingCard>
                        ))
                    ) : (
                        <p>No tasks</p>
                    )}
                </div>
            </DayWrapper>
        </>
    );
};

export default DayTile;
