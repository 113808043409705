import styled from "styled-components";
import { theme } from "../../global/theme";
import {
    columnMixin,
    mainScrollbar,
} from "../../global/theme/reuseable/mixins";
import { iSCOpen } from "../../global/theme/types";
import { buttonMixin, ButtonWrapper } from "../Button/styles";
import { iSCCard, iSCCardWrapper } from "./types";

export const cardMixin = `
  border: none;
  outline: none;
  border-radius: 10px;
  text-align: left;

  padding: 15px;
  align-items:left;
  font-family: arial;
  background-color: #fff;
  border-radius: 5px 0px 15px 15px;
`;
export const rightCardWidth = `
  width: clamp(250px, 33%, 100%);
`;

const detailsCard = `
border-radius: 10px;

.details{ 

  align-items:flex-start;

  div{ 

  }
}
  
`;

// Removable Card Styles

export const CardWrapper = styled.div<iSCCardWrapper>`
    ${cardMixin};
    height: clamp(125px, 153px, 160px);
    width: clamp(240px, 35%, 400px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${(props) => props.details && `${detailsCard}     `};

    h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: ${theme?.colors?.darkText};
    }

    button {
        width: 40%;
    }
`;

export const RemovableCardWrapper = styled(CardWrapper)<iSCCard>`
    position: relative;
    width: 100%;
    ${cardMixin};
    ${columnMixin};
    margin: 0;

    // Edit Mode
    ${(props) =>
        props.isEditing &&
        `
:hover {
  border: solid rgba(77, 158, 255, 1) 1px;
}
`}

    h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #212121;
    }

    button {
        width: auto;
        align-self: flex-start;
    }
    border-radius: 10px;
`;

export const EditContainer = styled.span`
    position: absolute;
    top: -1px;
    right: -35.5px;
    background-color: transparent;

    ${columnMixin}
    gap: 6.2px;
`;

// Accordion Card Styles

export const AccordionCardContainer = styled.div<iSCOpen>`
    ${cardMixin};
    border-radius: 10px;
    position: relative;
    z-index: 1;
    height: 100%;
    min-height: 160px;
    display: flex;
    flex-direction: column;

    span {
        height: 0.6px;
        background-color: rgba(8, 42, 82, 0.3);
        margin-bottom: 10px;
    }

    ul {
        border-top: 0.5px solid ${theme?.colors?.boxStroke};
        padding-top: 7.5px;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        min-height: 50px;
        max-height: 50px;
        ${mainScrollbar(12)}
        ${(props) => props.open && `max-height: 142px; overflow: auto;`};

        li {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 10px;
            line-height: 15px;
            ${theme?.colors?.darkText}
        }
    }
`;

export const ViewMore = styled.button`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    text-decoration-line: underline;
    border: none;
    background-color: transparent;
    color: ${theme?.colors?.purple};
    cursor: pointer;
    align-self: flex-end;
    transform: translateY(8px);
`;

// View Details Card Styles

export const ViewDetailsCardWrapper = styled.div`
    ${cardMixin}
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px;

    p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 15px;
        color: ${theme?.colors?.darkText};
    }

    button {
        width: auto;
        align-self: center;
        background-color: transparent;
        padding: 6px 10px;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid ${theme?.colors?.purple};
        color: ${theme?.colors?.purple};
    }
`;

// Create Client Card
export const CreateClientCardWrapper = styled.div`
    height: 203px;
    width: 302px;
    ${cardMixin};
    ${columnMixin};
    justify-content: space-between;
    padding-top: 66.9px;
    padding-bottom: 56px;
    border-radius: 15px;

    img {
        height: 38.2px;
        width: 38.2px;
        margin: 0 auto;
        margin-bottom: 17.9px;
    }

    h4 {
        font: 400 16px/24px "Poppins";
        text-align: center;
    }

    :hover {
        cursor: pointer;
        background: ${theme?.colors?.formBlue};
        border: solid rgba(104, 111, 255, 1) 1px;
        h4 {
            color: ${theme?.colors?.purple};
        }
    }
`;

// Payment Dashboard Card

export const PaymentDashboardCardWrapper = styled.button`
    ${cardMixin};
    ${columnMixin}
    width: 302px;
    height: 203px;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    padding-bottom: 56px;
    border-radius: 15px;

    img {
        max-height: 46px;
        max-width: 46px;
    }

    :hover {
        background: #e5efff;
        border: solid rgba(77, 158, 255, 1) 1px;
        cursor: pointer;
    }
`;

// Group Settings Card

export const GroupSettingWrapper = styled.figure`
    
    /* width: clamp(311px, 20%, 400px);
    height: clamp(217px, 250px, 300px); */

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme.colors.white};
    border-radius: 10px;
    padding: 24px;

    p {
        font-family: "Poppins";
        color: ${theme.colors.darkText};
    }

    > p:first-of-type {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }
    > p:nth-of-type(2) {
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
    }

    > p:last-of-type {
        font-weight: 275;
        font-size: 12px;
        line-height: 18px;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        /* padding: 12px 20px; */
        background: #f8f8f8;
        border-radius: 200px;
        margin-block: 25px;
        padding-inline: 12px;

        p {
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;
        }
    }

    .editButton {
        display: none;
    }

    :hover {
        cursor: pointer;
        background: linear-gradient(156.78deg, #4d9eff 15.32%, #686fff 85.25%);

        .editButton {
            position: absolute !important;
            top: 10px;
            right: 12px;
            display: flex !important;

            gap: 5.67px;
            ${buttonMixin};
            font-family: "Poppins";
            font-style: normal;
            font-weight: 275;
            font-size: 12px;
            line-height: 18px;
            color: #583bd8;
        }
    }
`;

export const EditButton = styled(ButtonWrapper)``;
