import React, { useEffect, useState } from "react";
import { StyledTemplateCard } from "./styles";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { extractTextFromHTML } from "../../../global/utils/helpers";

interface TemplateCardProps {
    className?: string;
    name?: string;
    type?: string;
    body?: string;
    date?: string;
    id: number;
    isMessageEmpty?: boolean;
    isSelected?: boolean;
    onSelect?: (id: number) => void;
    onEdit?: (id: number) => void;
    onApply?: (id: number) => void;
}

const TemplateCard: React.FC<TemplateCardProps> = ({
    className,
    name,
    type,
    body,
    date,
    id,
    isMessageEmpty,
    isSelected,
    onSelect = () => {},
    onApply = () => {},
    onEdit = () => {},
}) => {
    const [isConfirming, setIsComfirming] = useState(false);

    useEffect(() => {
        if (!isSelected) setIsComfirming(false);
    }, [isSelected]);

    const applyHandler = () => {
        if (!isMessageEmpty && !isConfirming) return setIsComfirming(true);
        onApply(id);
        setIsComfirming(false);
    };

    return (
        <StyledTemplateCard
            className={`template-card${className ? ` ${className}` : ""}`}
            onClick={() => onSelect(id)}
            aria-selected={isSelected}
        >
            <div className="template-card__info">
                <p className="template-card__name">
                    {isConfirming
                        ? "Override with saved template?"
                        : name || "-"}
                </p>
                {!isConfirming && (
                    <p className="template-card__type">{type || "-"}</p>
                )}
            </div>
            {isConfirming ? (
                <div className="template-card__btns">
                    <button
                        className="template-card__apply"
                        type="button"
                        onClick={applyHandler}
                    >
                        Confirm
                    </button>
                    <button
                        className="template-card__cancel"
                        type="button"
                        onClick={() => setIsComfirming(false)}
                    >
                        Cancel
                    </button>
                </div>
            ) : (
                <div className="template-card__btns">
                    <button
                        className="template-card__edit"
                        type="button"
                        onClick={() => onEdit(id)}
                    >
                        <EditIcon />
                        Edit
                    </button>
                    <button
                        className="template-card__apply"
                        type="button"
                        onClick={applyHandler}
                    >
                        Apply
                    </button>
                </div>
            )}
        </StyledTemplateCard>
    );
};

export default TemplateCard;
