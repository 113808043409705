import React, { useId } from "react";
import Label from "../Label";
import { InputWrapper } from "./styles";
import { iTextArea } from "./types";

const TextArea = ({
    onChange,
    name,
    placeholder,
    label,
    value,
    gap,
    className,
    disabled,
    readOnly = false,
    rows = 5,
}: iTextArea) => {
    const id = useId();
    return (
        <InputWrapper
            gap={gap}
            className={`text-area ${className ? ` ${className}` : ""}`}
        >
            <Label label={label} htmlFor={id} />
            <textarea
                onChange={onChange}
                placeholder={placeholder}
                name={name}
                value={value}
                id={id}
                disabled={disabled}
                readOnly={readOnly}
                rows={rows}
            />
        </InputWrapper>
    );
};

export default TextArea;
