import React, { useState } from "react";
import { Button, ModalContainer } from "../../../../components";
import CreateLeadStatusModal from "../../../../components/Modals/Settings/LeadStatus/CreateLeadStatusModal";
import SearchRow from "../../../../components/SearchRow";
import DataTable from "../../../../components/DataTable";
import LeadStatusRow from "../../../../components/DataTable/TableRows/Settings/LeadStatusRow";
import useFetchData from "../../../../global/hooks/useFetchData";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";
import { LoadingSpinner } from "../../../../components/Button/styles";

const LeadStatus = () => {
    const { others, setOther } = useReduxSettings();
    const [searchTerm, setSearchTerm] = useState("");
    const [createModal, setCreateModal] = useState(false);

    const getLeadStatus = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/settigs-lead-status`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setOther("leadStatus", data.data);
            },
        }
    );

    // useEffect(() => {
    //     setOther("leadStatus", testData);
    // }, []);

    return (
        <>
            <ModalContainer
                isVisible={createModal}
                children={
                    <CreateLeadStatusModal
                        closeModal={() => setCreateModal(false)}
                        open={createModal}
                    />
                }
            />
            <SearchRow
                value={searchTerm}
                setValue={setSearchTerm}
                placeholder="Search by name..."
                style={{ marginBottom: "24px" }}
            >
                <Button
                    btnText="+ Create"
                    onClick={() => setCreateModal(true)}
                    style="mint"
                    role="create"
                />
            </SearchRow>
            {getLeadStatus.isLoading ? (
                <LoadingSpinner size={40} />
            ) : (
                <DataTable
                    labels={["Name", "Color", "Edited When"]}
                    keys={[
                        "system_case_status_name",
                        "color",
                        "system_case_status_edited",
                    ]}
                    data={others.leadStatus || []}
                    searchTerm={searchTerm}
                    tableRow={LeadStatusRow}
                    searchKeys={["system_case_status_name"]}
                />
            )}
        </>
    );
};

export default LeadStatus;
