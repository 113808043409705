import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ModalContainer, SelectButton } from "../../../components";
import Header from "../../../layout/Header";
import { TablePageContent } from "../../styles";
import { userAdminButtonNav } from "./data";
import { AccountsWrapper, CreatePermissionButton } from "./styles";
import { iUserAdminNav } from "./types";
import GroupPermissionsSettings from "../../../components/Modals/Accounts/GroupPermissionsSettings";
import useReduxAccounts from "../../../store/hooks/useReduxAccounts";
import useFetchData from "../../../global/hooks/useFetchData";
import PageTabs from "../../../components/PageTabs/PageTabs";
import { capitalize } from "lodash";

const Accounts: React.FC = () => {
    const [newPermissionModal, setNewPermissionModal] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { setPermissions } = useReduxAccounts();

    const permissionGroups = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/permission`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setPermissions(data?.data);
            },
        }
    );

    const selectedTabName = (name: string) => {
        if (name === "group-settings") return "Group Settings";
        if (name === "access-logs") return "Access Logs";
        if (name === "api-keys") return "API Keys";
        return "User Accounts";
    };

    const tabClickHandler = (tab: string, index: number) => {
        navigate(index === 0 ? "/admin" : tab.toLowerCase().replace(" ", "-"));
    };

    return (
        <>
            {/* <ModalContainer
                isVisible={newPermissionModal}
                children={
                    <CreateNewPermissionModal
                        open={newPermissionModal}
                        setNewPermissionModal={setNewPermissionModal}
                    />
                }
            /> */}
            <ModalContainer
                isVisible={newPermissionModal}
                children={
                    <GroupPermissionsSettings
                        isOpen={newPermissionModal}
                        onClose={() => setNewPermissionModal(false)}
                        mode="create"
                    />
                }
            />
            <Header title="accounts">
                {location.pathname.includes("group-settings") ? (
                    <CreatePermissionButton
                        color="mint"
                        onClick={() => setNewPermissionModal(true)}
                    >
                        + CREATE NEW PERMISSION
                    </CreatePermissionButton>
                ) : null}
            </Header>
            <TablePageContent>
                <AccountsWrapper>
                    <PageTabs
                        tabs={[
                            "User Accounts",
                            "Group Settings",
                            "Access Logs",
                            "API Keys",
                        ]}
                        selectedTab={selectedTabName(
                            location.pathname.split("/")[2]
                        )}
                        onTabClick={tabClickHandler}
                    />
                </AccountsWrapper>
                <Outlet />
            </TablePageContent>
        </>
    );
};

export default Accounts;
