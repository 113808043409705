import React from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assets/icons";
import { CreateClientCardWrapper } from "./styles";
import { iCreateClientCard } from "./types";
import { LoadingSpinner } from "../Button/styles";

const CreateClientCard = ({
    title,
    onClick,
    isLoading = false,
}: iCreateClientCard) => {
    return (
        <CreateClientCardWrapper onClick={onClick}>
            {isLoading ? (
                <LoadingSpinner size={40} />
            ) : (
                <>
                    <img src={icons.circlePlus} alt="Create" />
                    <h4>{title}</h4>
                </>
            )}
        </CreateClientCardWrapper>
    );
};

export default CreateClientCard;
