import React from "react";
import { useParams } from "react-router-dom";
import { StyledLiabilityActions } from "./styles";
import DropdownMeunItem from "../../../components/DropdownMenu/DropdownMenuItem";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxClientBalanceSheet from "../../../store/hooks/useReduxClientBalanceSheet";

interface LiabilityActionsProps {
    selectedIds: number[];
    selectedIdsCount?: string;
    onSuccess?: (data: { [ley: string]: any }) => void;
}

const LiabilityActions: React.FC<LiabilityActionsProps> = ({
    selectedIds,
    selectedIdsCount,
    onSuccess = () => {},
}) => {
    const { clientId } = useParams();
    const { tableData, setTableData, notes, setNotes, resetSlice } =
        useReduxClientBalanceSheet();
    const postLiabilityAction = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/liability-action/${clientId}`,
        },
        {
            onSuccess: (data) => {
                onSuccess(data);
            },
            notifyOnSuccess: true,
            notifySuccessMessage: "Action Applied",
        }
    );

    const actionHandler = (actionType: string) => {
        const requestData = {
            action_type: actionType,
            liability_ids: selectedIds,
        };
        postLiabilityAction.fetchData({ data: requestData });
    };

    return (
        <>
            <StyledLiabilityActions
                title="Liability Actions"
                color="purple"
                className="liability-actions-menu"
                disabled={!selectedIds?.length}
            >
                <DropdownMeunItem
                    title="Pro-Rata Debt"
                    iconName="transfer"
                    name="pro-rata-debt"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Apply Standing Order"
                    iconName="standing"
                    name="So-Ready"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Witheld Payments"
                    iconName="coinCrossed"
                    name="Withheld-Payments"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Mark Debt Inactive"
                    iconName="inactive"
                    name="Debts-Inactive"
                    onClick={actionHandler}
                />
                <hr />
                <DropdownMeunItem
                    title="Remove Inactive Mark"
                    iconName="remove"
                    name="Remove-Inactive"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Remove Witheld Payments"
                    iconName="remove"
                    name="Remove-Withheld-Payments"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Remove Standing Order"
                    iconName="remove"
                    name="Remove-So-Ready"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Receive Credit Agreement"
                    iconName="remove"
                    name="mark-crdt-agrmnt-rcvds"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Remove Received Credit Agreement"
                    iconName="remove"
                    name="remove-crdt-agrmnt-rcvds"
                    onClick={actionHandler}
                />
                <hr />
                <DropdownMeunItem
                    title="Debt Confirmation"
                    iconName="tick"
                    name="Debt-Confrim"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Balance Request"
                    iconName="arrow"
                    name="Balance-Request"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Send First Proposal"
                    iconName="arrow"
                    name="Send-Proposal"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Accept Proposal"
                    iconName="tick"
                    name="Accept-Proposal"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Remove Proposal Marks"
                    iconName="remove"
                    name="Removed-Proposal"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Payday Loan Letter"
                    iconName="upload"
                    name="PaydayLoan-Letter"
                    onClick={actionHandler}
                />
                <hr />
                <DropdownMeunItem
                    title="CCF Switch to Mellor"
                    iconName="flip"
                    name="ccf-switch-to-mellor"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Send CCR Initial"
                    iconName="arrow"
                    name="ccr-2-mellor"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Send CCR Chase 1"
                    iconName="arrow"
                    name="Send-CCR-Letter"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Send CCR Chase 2"
                    iconName="arrow"
                    name="CCR-Chase-2"
                    onClick={actionHandler}
                />
                <DropdownMeunItem
                    title="Send Final Chase CCR"
                    iconName="arrow"
                    name="Accepted-CCR"
                    onClick={actionHandler}
                />

                <DropdownMeunItem
                    title="Remove From CCR"
                    iconName="remove"
                    name="Remove-CCR"
                    onClick={actionHandler}
                />
            </StyledLiabilityActions>
        </>
    );
};

export default LiabilityActions;
