import styled from "styled-components";
import { buttonMixin } from "../../../../components/Button/styles";
import { theme } from "../../../../global/theme";
import { Row } from "../../../../global/theme/reuseable/components";
import { transparentGradient } from "../../../../global/theme/reuseable/mixins";

export const StyledTopRow = styled(Row)`
    & > p {
        ${transparentGradient()}
    }
`;

export const StyledForms = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
`;

export const BlueBox = styled.div`
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e5efff;
    border-radius: 12px;
    height: 80px;
    padding-inline: 16px;

    a {
        ${buttonMixin};
        background: #edf4ff;
        border-radius: 5px;
        padding: 10px 15px;
        color: #ac01fd;
    }
`;
export const EditableSettingsWrapper = styled.section`
    width: clamp(720px, 65%, 900px);
    border-radius: 10px;

    &.send-test {
        width: min(100%, 360px);
    }

    .heading {
        border-radius: 10px 10px 0 0;
        background: linear-gradient(123.76deg, #4d9eff 4.28%, #686fff 103.7%);
        padding: 12px 24px;
        h2 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: ${theme?.colors.white};
        }
    }

    .content {
        border-radius: 0 0 10px 10px;
        padding: 24px;
        background: #fbfdff;
        display: flex;
        flex-direction: column;
        gap: 12px;

        & hr {
            border: none;
            border-top: 0.6px solid ${theme.colors.shade};
            margin-block: 8px;
        }

        & h3 {
            grid-column: 1/-1;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 15px;
            line-height: 22px;
            color: ${theme?.colors?.darkText};
        }

        & .row {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 12px 16px;

            & > .col-3 {
                grid-column-end: span 3;
            }

            & > .col-2 {
                grid-column-end: span 2;
            }
        }

        & .submit-btn {
            align-self: flex-end;
            padding-inline: 24px;
        }

        &--send-test {
            & .inputWrapper {
                grid-column: 1/-1;
            }

            & .submit-btn {
                align-self: center;
                width: min(100%, 240px);
                margin-top: 8px;
            }
        }
    }
`;
