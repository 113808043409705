import ChangeStatus from "../../../components/Modals/ClientCase/CaseActions/ChangeStatus";
import CaseComplete from "../../../components/Modals/ClientCase/CaseActions/CaseComplete";
import RequestCancellation from "../../../components/Modals/ClientCase/CaseActions/RequestCancellation";
import WelcomeCallComplete from "../../../components/Modals/ClientCase/CaseActions/WelcomeCallComplete";
import CaseCancel from "../../../components/Modals/ClientCase/CaseActions/CaseCancel";
import RemoveFromCreditControl from "../../../components/Modals/ClientCase/CaseActions/RemoveFromCreditControl";
import RemoveFromIntroducerView from "../../../components/Modals/ClientCase/CaseActions/RemoveFromIntroducerView";
import SendAMLIdRequest from "../../../components/Modals/ClientCase/CaseActions/SendAMLIdRequest";
import CarryOnToCCR from "../../../components/Modals/ClientCase/CaseActions/CarryOnToCCR";
import FlipClientToAPF from "../../../components/Modals/ClientCase/CaseActions/FlipClientToAPF";

export const caseActionsModals = {
    changeStatus: ChangeStatus,
    caseComplete: CaseComplete,
    welcomeCallComplete: WelcomeCallComplete,
    caseCancel: RequestCancellation,
    removeFromCreditControl: RemoveFromCreditControl,
    removeFromIntroducerView: RemoveFromIntroducerView,
    sendAmlIdRequest: SendAMLIdRequest,
    carryOnToCCR: CarryOnToCCR,
    flipClientToAPF: FlipClientToAPF,

};

export const uploadActions = [
    {
        title: "Upload LOA",
        name: "loa",
    },
    {
        title: "Upload Client LOA",
        name: "client_loa",
    },
    {
        title: "Upload Wet LOA",
        name: "wet_loa",
    },
    {
        title: "Upload Solicitor LOA",
        name: "solicitor_loa",
    },
    {
        title: "Upload Death Certificate",
        name: "death_certificate",
    },
];

export const sendToActions = [
    {
        title: "Send To Mellors CHUB System - New Cleint",
        name: "mellors-new",
    },
    {
        title: "Send To Mellors CHUB System - No Docs",
        name: "mellors-nodocs",
    },
    {
        title: "Send To Woodlands CHUB System - New Cleint",
        name: "woodlands-new",
    },
    {
        title: "Send To Woodlands CHUB System - No Docs",
        name: "woodlands-nodocs",
    },
];