import { Link } from "react-router-dom";
import StyledNotificationCard from "./styles";
import moment from "moment";

import { ReactComponent as LinkIcon } from "../../../assets/icons/external.svg";

type NotificationType = "portal" | "email" | "sms" | "alert";

interface NotificationCardProps {
    className?: string;
    title?: string;
    time?: string;
    message?: string;
    caseId?: number;
    remark?: string;
    type?: NotificationType;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
    className = "",
    title,
    time,
    message,
    caseId,
    remark,
    type = "portal",
}) => {
    const momentTime = moment(time, "YYYY-MM-DD HH:mm:ss");
    const timeDate = momentTime.isValid() ? momentTime.toDate() : null;

    const getTimeAgo = (date: Date | null): string => {
        if (!date) return "Unknown Time";
        const now = moment();
        const dateMoment = moment(date);
        const diffInMinutes = now.diff(dateMoment, "minutes");

        if (diffInMinutes < 1) {
            return "just now";
        } else {
            return dateMoment.fromNow();
        }
    };

    return (
        <StyledNotificationCard
            className={`notification-card ${className ? ` ${className}` : ""}`}
        >
            <div className="notification-card__header">
                <h3 className="notification-card__title">{title}</h3>
                <div className="notification-card__time">
                    {getTimeAgo(timeDate) || "Unknown Time"}
                </div>
                {caseId && (
                    <Link
                        className="notification-card__link"
                        to={`client-case/${caseId}/summary`}
                        target="_blank"
                        role="to-client-case"
                    >
                        <LinkIcon role="icon" />
                    </Link>
                )}
            </div>
            <p className="notification-card__message">
                {message || <span>No Message</span>}
            </p>
            {remark && (
                <p className="notification-card__remark">{`(${remark})`}</p>
            )}
        </StyledNotificationCard>
    );
};

export default NotificationCard;
