import React, { useEffect, useState } from "react";
import { icons } from "../../../../assets/icons";
import { Button } from "../../../../components";
import Header from "../../../../layout/Header";
import DateButton from "../../../../components/Button/DateButton";
import SearchRow from "../../../../components/SearchRow";
import { TablePageContent } from "../../../../pages/styles";
import PageTabs from "../../../../components/PageTabs/PageTabs";
import BackBookTables from "./BackBookTables";
import useDateSearch from "../../../../layout/SlideInMenus/DateSearch/useDateSearch";
import useFetchData from "../../../../global/hooks/useFetchData";
import { exportCsv } from "../../../../global/utils/helpers";
import { generateDateSearchOptions } from "../../../../layout/SlideInMenus/DateSearch/data";

const backBookTabs = [
    "IP1",
    "IP2",
    "IP3",
    "IP4",
    "IP5",
    "APF",
    "Credit Control",
    "FAF",
    "Reviews",
];

const BackBook = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("thisMonth");

    const { data, isLoading, fetchData } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/backbook`,
        },
        {
            params: { from: dateRange.from, to: dateRange.to },
            onSuccess: onDateSearchSuccess,
            notifyOnSuccess: true,
        }
    );

    useEffect(() => {
        fetchData();
    }, [dateRange.from, dateRange.to]);
    const backbookTablesData: { [key: string]: any } = data?.data || {};
    const backBookData = Object.values(backbookTablesData);
    const backBookBodies = Object.values(backBookData?.[1] || []);
    const backBookHeadings = backBookData?.[0] || [];

    const handleExport = () => {
        const allBackBookHeadings = backBookHeadings.map(
            (_: string[], i: number) => backBookHeadings
        );
        const backBookTabs = Object.keys(backbookTablesData?.result || {});
        exportCsv(
            backBookBodies,
            allBackBookHeadings,
            backBookTabs,
            `BackBook_${dateRange.from}-${dateRange.to}`
        );
    };
    return (
        <>
            <Header title="client back book" hasNavBarAbove hasPrevPath>
                <DateButton
                    isLoading={isLoading}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                    dateDisplay={dateDisplay}
                    options={generateDateSearchOptions([
                        "thisMonth",
                        "lastMonth",
                        "nextMonth",
                    ])}
                />
            </Header>
            <TablePageContent>
                <PageTabs
                    tabs={backBookTabs}
                    selectedTabIndex={selectedTabIndex}
                    setSelectedTabIndex={setSelectedTabIndex}
                    notifications={[
                        data?.data?.result?.IP1?.length || 0,
                        data?.data?.result?.IP2?.length || 0,
                        data?.data?.result?.IP3?.length || 0,
                        data?.data?.result?.IP4?.length || 0,
                        data?.data?.result?.IP5?.length || 0,
                        data?.data?.result?.APF?.length || 0,
                        data?.data?.result?.CreditControl?.length || 0,
                        data?.data?.result?.FAF?.length || 0,
                        data?.data?.result?.["Review"]?.length || 0,
                    ]}
                />
                <SearchRow value={searchTerm} setValue={setSearchTerm}>
                    {/* <Button
                        btnText="Filter"
                        onClick={() => alert("Filter")}
                        style="purple"
                        btnImg={icons.whiteDownArrow}
                    /> */}
                    <Button
                        btnText=""
                        onClick={handleExport}
                        style="red"
                        btnImg={icons.exportIcon}
                    />
                </SearchRow>
                <BackBookTables
                    selectedTableIndex={selectedTabIndex}
                    data={data?.data || []}
                    searchTerm={searchTerm}
                    isLoading={isLoading}
                />
            </TablePageContent>
        </>
    );
};

export default BackBook;
