import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import ModalHeader from "../components/ModalHeader";
import { StyledCreateLiability } from "./styles";
import SuccessContent from "../components/SuccessContent";
import DropdownSelect from "../../DropdownSelect";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import Input from "../../Input";
import {
    accountHolderOptions,
    creditTypeOptions,
} from "../../DropdownSelect/options";
import Checkbox from "../../Input/Checkbox";

interface CreateLiabilityProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
}

const CreateLiability: React.FC<CreateLiabilityProps> = ({
    isOpen,
    onClose,
}) => {
    const { clientId } = useParams();
    const [liability, setLiability] = useState([]);
    const [liabilityData, setLiabilityData] = useState<{ [key: string]: any }>({
        debt_id: null,
        account_reference: "",
        balance: 0,
        account_holder_id: accountHolderOptions[0].value,
        liability_type_id: creditTypeOptions[0].value,
        pro_rata: 0,
        send_balance_request: 0,
    });
    const { fetchData, isSuccess, isLoading } = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/create-liability/${clientId}`,
    });

    const getLiabilityNameList = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/liability-list/${clientId}`,
        },
        {
            fetchOnLoad: true,
        }
    );
    const isFormValid = !!liabilityData?.debt_id;

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        const inputValue = () => {
            if (type === "checkbox") return checked ? 1 : 0;
            if (type === "number") return +value;
            return value;
        };
        setLiabilityData((prev) => {
            return { ...prev, [name]: inputValue() };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setLiabilityData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(liabilityData);
        fetchData({ data: liabilityData });
    };

    return (
        <StyledCreateLiability
            show={isOpen}
            width="400px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={onClose}>Create New Liability</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="A new liability has been created."
                    onClose={onClose}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <DropdownSelect
                        label="Liability Name:"
                        value={liabilityData?.debt_id ?? null}
                        options={getLiabilityNameList?.data?.data || []}
                        placeholder="Search Debt..."
                        optionKey="id"
                        labelKeys={["name"]}
                        isLoading={getLiabilityNameList.isLoading}
                        onSelect={(option) =>
                            selectHandler("debt_id", option.id)
                        }
                        enableInput
                    />
                    <Input
                        type="text"
                        label="Account Reference:"
                        value={liabilityData?.account_reference}
                        name="account_reference"
                        onChange={inputHandler}
                    />
                    <Input
                        type="number"
                        label="balance:"
                        value={`${liabilityData?.balance}` ?? ""}
                        name="balance"
                        onChange={inputHandler}
                    />
                    <DropdownSelect
                        label="Account Holder:"
                        value={liabilityData?.account_holder_id}
                        options={accountHolderOptions}
                        onSelect={(option) =>
                            selectHandler("account_holder_id", option.value)
                        }
                    />
                    <DropdownSelect
                        label="Liability Type:"
                        value={liabilityData?.liability_type_id}
                        options={creditTypeOptions}
                        onSelect={(option) =>
                            selectHandler("liability_type_id", option.value)
                        }
                        enableInput
                    />
                    <div className="checkboxes">
                        <p>Actions:</p>
                        <div className="checkboxes__inputs">
                            <Checkbox
                                label="Pro-Rata"
                                checked={!!liabilityData?.pro_rata ?? false}
                                name="pro_rata"
                                onChange={inputHandler}
                            />
                            <Checkbox
                                label="Send Balance Request"
                                checked={
                                    !!liabilityData?.send_balance_request ??
                                    false
                                }
                                name="send_balance_request"
                                onChange={inputHandler}
                            />
                        </div>
                    </div>
                    <ModalButtons>
                        <Button
                            type="submit"
                            btnText="Create"
                            style="purple"
                            disabled={!isFormValid}
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledCreateLiability>
    );
};

export default CreateLiability;
