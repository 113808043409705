import { Loading, Notify } from "notiflix";
import { SetStateAction } from "react";

export async function validateData8(
    validationType: string,
    validation: string,
    body: object
) {
    const response = await fetch(
        `https://webservices.data-8.co.uk/${validationType}/${validation}.json?key=${process.env.REACT_APP_DATA8_KEY}`,

        {
            body: JSON.stringify(body),
            headers: { "content-type": "application/json; charset=utf-8" },
            method: "POST",
        }
    );
    if (!response.ok) {
        console.log("Res", response.statusText);
    }

    return await response.json();
}

export const phoneOptions = {
    RequiredCountry: "",
    AllowedPrefixes: "",
    BarredPrefixes: "",
    TreatUnavailableMobileAsInvalid: false,
    ExcludeUnlikelyNumbers: false,
    DefaultFormatType: "National",
    DifferentFormatCountries: 44,
    DifferentFormatType: "National",
    UseLocalFormatting: true,
    UseMobileValidation: true,
    UseLineValidation: true,
    UseUnavailableStatus: true,
    UseAmbiguousStatus: true,
};

export const predictAddress = async (
    postcode: string,
    setResponse: any,
    setIsLoading: React.Dispatch<SetStateAction<boolean>> = () => {}
): Promise<any> => {
    // Loading.standard();
    setIsLoading(true);
    if (postcode.length === 0) {
        setIsLoading(false);
        Notify.failure("Please enter a postcode");
    } else {
        window.predictiveAddress.search(
            "GB",
            postcode,
            "",
            "",
            [
                new window.data8.option("IncludeMR", "false"),
                new window.data8.option("IncludeNYB", "false"),
                new window.data8.option("PreferredLanguage", "en"),
            ],
            (res: any) => {
                console.log({ res });
                console.log("Phase 1");
                setIsLoading(false);

                // Loading.remove();

                if (res.Results.length === 0) {
                    console.log("Phase 2");

                    // Loading.remove();
                    setIsLoading(false);

                    Notify.failure("Please enter a valid postcode");
                } else {
                    // Loading.remove();
                    console.log("Phase 3", res.Results);
                    setResponse(res?.Results);
                    setIsLoading(false);

                    return res.Results;
                }
            }
        );
    }
};

export const getFullAddress = async (
    id: string,
    onSuccess: (doorNumber: string, address: string, postcode: string) => void,
    setIsLoading: React.Dispatch<SetStateAction<boolean>> = () => {}
): Promise<any> => {
    // Loading.standard();
    setIsLoading(true);
    if (id.length === 0) {
        setIsLoading(false);
        Notify.failure("Please enter a postcode");
    } else {
        window.predictiveAddress.retrieve(
            "GB",
            id,
            [
                new window.data8.option("PreferredLanguage", "en"),
                new window.data8.option("IncludeWhat3Words", "true"),
                new window.data8.option("UseFiveDigitZip", "false"),
                new window.data8.option("MaxLines", "6"),
                new window.data8.option("FixTownCounty", "true"),
                new window.data8.option("IncludeLocation", "true"),
            ],
            (res: any) => {
                const resAddressLines: string[] =
                    res?.Result?.Address?.Lines || [];
                if (resAddressLines) {
                    const doorNumber = resAddressLines[0].replace(
                        /[^0-9]+/,
                        ""
                    );
                    const address =
                        [...resAddressLines]
                            ?.slice(0, 6)
                            ?.filter((str: string) => str !== "")
                            ?.join(", ") || "";
                    const postcode =
                        resAddressLines[resAddressLines?.length - 1];
                    // console.log(doorNumber, address, postcode);
                    onSuccess(doorNumber, address, postcode);
                } else {
                    Notify.failure(res.Status.ErrorMessage);
                }
                setIsLoading(false);
            }
        );
    }
};
