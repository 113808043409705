import { KeyEventsContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import { formatDateString } from "../../../../global/utils/helpers";

const SetupFeePayments: React.FC<KeyEventsContainerProps> = ({
    formData,
    onInputChange,
    data,
}) => {
    // setup_fee_1_date: "",
    // setup_fee_2_date: "",
    // cc_fee_1_date: "",
    // cc_fee_2_date: "",
    // cc_fee_3_date: "",
    // r1_fee_date: "",
    // r2_fee_date: "",
    //formatDateString(formData?.r2_fee_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')
    return (
        <HeaderContainer
            title="Setup Fee Payments"
            headerStyle="gradientBlue"
            className="setup-fee"
        >
            <Input
                type="date"
                label={
                    <>
                        Accountancy Setup Fee 1:
                        <span>£{parseFloat(formData?.setup_fee_1 || 0).toFixed(2)}</span>
                    </>
                }
                value={formatDateString(formData?.setup_fee_1_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="setup_fee_1_date"
            />
            <Input
                type="date"
                label={
                    <>
                        Accountancy Setup Fee 2:
                        <span>£{parseFloat(formData?.setup_fee_2 || 0).toFixed(2)}</span>
                    </>
                }
                value={formatDateString(formData?.setup_fee_2_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="setup_fee_2_date"
            />
            <Input
                type="date"
                label={
                    <>
                        CC Fee 1:
                        <span>£{parseFloat(formData?.cc_fee_1 || 0).toFixed(2)}</span>
                    </>
                }
                value={formatDateString(formData?.cc_fee_1_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="cc_fee_1_date"
            />
            <Input
                type="date"
                label={
                    <>
                        CC Fee 2:
                        <span>£{parseFloat(data?.cc_fee_2 || 0).toFixed(2)}</span>
                    </>
                }
                value={formatDateString(formData?.cc_fee_2_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="cc_fee_2_date"
            />
            <Input
                type="date"
                label={
                    <>
                        CC Fee 3:
                        <span>£{parseFloat(formData?.cc_fee_3 || 0).toFixed(2)}</span>
                    </>
                }
                value={formatDateString(formData?.cc_fee_3_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="cc_fee_3_date"
            />
            <Input
                type="date"
                label={
                    <>
                        Review 1 Fee:
                        <span>£{parseFloat(formData?.r1_fee || 0).toFixed(2)}</span>
                    </>
                }
                value={formatDateString(formData?.r1_fee_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="r1_fee_date"
            />
            <Input
                type="date"
                label={
                    <>
                        Review 2 Fee:
                        <span>£{parseFloat(formData?.r2_fee || 0).toFixed(2)}</span>
                    </>
                }
                value={formatDateString(formData?.r2_fee_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="r2_fee_date"
            />
        </HeaderContainer>
    );
};

export default SetupFeePayments;
