import React from "react";
import { Route, Routes } from "react-router-dom";
import NavBar from "../../layout/Header/NavBar";
import LedgerPaymentsLanding from "../../subPages/ClientCase/LedgerPayments";
import Ledger from "../../subPages/ClientCase/LedgerPayments/Ledger";
import Payments from "../../subPages/ClientCase/LedgerPayments/Payments";
import PaymentsSchedule from "../../subPages/ClientCase/LedgerPayments/PaymentsSchedule";

const LedgerPayments: React.FC = () => {
    return (
        <>
            <NavBar subListName="ledger_payments" />
            <Routes>
                <Route index element={<LedgerPaymentsLanding />} />
                <Route path="ledger" element={<Ledger />} />
                <Route path="payments" element={<Payments />} />
                <Route path="payment-schedule" element={<PaymentsSchedule />} />
            </Routes>
        </>
    );
};

export default LedgerPayments;
