import testPDF from '../../../assets/pdfs/testPDF.pdf'
import testJPG from '../../../assets/images/testJPG.jpg'

export const testListData = [
    {
        id: 1,
        debt_name: "Name 1",
        debt_id: 1,
        description: "Here is some notes",
        direction: "out",
        type: 'notes',
        data: {
            notes: 'Here is some notes'
            
        },
        created_by: "Aris",
        created_at: "2023-03-02 12:40:33",
        updated_by: "Aris",
        updated_at: "2023-03-02 12:40:33",
    },
    {
        id: 2,
        debt_name: "Name 1",
        debt_id: 1,
        description: "Document description",
        direction: "out",
        type: 'document',
        data: {
              id: 1,
              filename: 'letter.pdf',
              size: 12341234,
              extn: 'pdf',
              template_name: 'Doc Template name',
              url: testPDF,
            
        },
        created_by: "Aris",
        created_at: "2023-03-02 12:40:33",
        updated_by: "Aris",
        updated_at: "2023-03-02 12:40:33",
    },
    {
        id: 3,
        debt_name: "Name 1",
        debt_id: 1,
        description: "Call description",
        direction: "in",
        type: 'call',
        data: {
              id: 1,
              courtesy_call: 1,
              welcome_call: 1,
              electricity: 1,
              insurance: 0,
              mobile: 1
            
        },
        created_by: "Aris",
        created_at: "2023-03-02 12:40:33",
        updated_by: "Aris",
        updated_at: "2023-03-02 12:40:33",
    },
    {
        id: 4,
        debt_name: "Name 1",
        debt_id: 1,
        description: "upload description",
        direction: '',
        type: 'upload',
        data: {
              id: 1,
              filename: 'doc.pdf',
              size: 12341234,
              extn: 'pdf',
              url: testPDF

        },
        created_by: "Aris",
        created_at: "2023-03-02 12:40:33",
        updated_by: "Aris",
        updated_at: "2023-03-02 12:40:33",
    },
        {
        id: 5,
        debt_name: "Name 1",
        debt_id: 1,
        description: "SMS description",
        direction: '',
        type: 'sms',
        data: {
              id: 1,
              recevier_id: 1,
              recevier_name: "Someone",
              message: 'Some message'

    
        },
        created_by: "Aris",
        created_at: "2023-03-02 12:40:33",
        updated_by: "Aris",
        updated_at: "2023-03-02 12:40:33",
    },
    {
        id: 6,
        debt_name: "Name 1",
        debt_id: 1,
        description: "Email description",
        direction: '',
        type: 'email',
        data: {
              id: 1,
              recevier_id: 1,
              recevier_name: "Someone",
              send_by: "Someone",
              send_at: "2022-03-03 12:33:33",
              subject: 'Subject',
              body: '<b>here is the body</b>'
            },
        created_by: "Aris",
        created_at: "2023-03-02 12:40:33",
        updated_by: "Aris",
        updated_at: "2023-03-02 12:40:33",
    },
]



export const testSignableData = [{
    id: 1,
    document_title: 'Personal Accountancy',
    document_url: testPDF,
    fingerprint: 'ajhsd237434232v2gj342y34g234',
    sent_at: '2022-09-09 12:20:00',
    is_processed: 1,
    status: 'sent',
    parties: [{
        id: 1,
        name: 'Testing name 1',
        email: 'test@gmail.com',
        status: 'In Progress'
     }
    ],
    history: [{
        id: 1,
        details: 'Signable Dara',
        date_time: '2022-09-09 12:30:00',
        ip: '127.0.0.1'
    }],
}, {
    id: 2,
    document_title: 'Business Accountancy',
    document_url: testPDF,
    fingerprint: 'ajhsd237434232v2gj342y3dd4g234',
    sent_at: '2022-09-09 12:20:00',
    is_processed: 1,
    status: 'cancelled',
    parties: [{
        id: 1,
        name: 'Testing name 1',
        email: 'test@gmail.com',
        status: 'In Progress'
     }
    ],
    history: [{
        id: 1,
        details: 'Signable Dara',
        date_time: '2022-09-09 12:30:00',
        ip: '127.0.0.1'
    }],
}]


export const testFilesData = [{
    id: 1,
    file_url: testJPG,
    filename: 'jpgtest.jpg',
    file_type: 'upload',
    size: 1234123,
    upload_date: '2022-02-08'
}, {
    id: 2,
    file_url: testPDF,
    filename: 'PDFtest.pdf',
    file_type: 'signable',
    size: 334123,
    upload_date: '2022-02-08'
}, {
    id: 3,
    file_url: testPDF,
    filename: 'doc.pdf',
    file_type: 'document',
    size: 1234123,
    upload_date: '2022-02-08'
}]


export const testEmailData = {
    inbox: [{
        id: 1,
        is_unread: 0,
        sent_by: 'Oliver Billnage Yhaksdj Assssisisisi',
        sent_at: '2022-08-08 16:30:30',
        sent_to: 'Hjsjdhd',
        subject: 'Here is the subject',
        body: '<p></p><p>Hi ${APP1},</p><br><p></p><p> We have been trying to contact you with regards to an assessment of your account and would like to request your initial credit agreements using our Creditor Review Service.<br><br>Once we receive the relevant information from each of your creditors we will work through the documents to determine the enforceability of the agreement and check that they have followed the correct procedure of collecting the outstanding balance from you.<br><br>We require you to contact us in order to discuss the matter further. We would like to receive your authorisation to carry out the Creditor Claims Review Service and review you Consumer Credit Agreements to look at potentially stopping creditors pursuing your debts.<br>&nbsp;<br>Please call us on <strong>0161 518 6795&nbsp;</strong>or email <font color="#0088cc"><b>cs@clearstartaccountants.co.uk</b></font><br><br>Yours sincerely &nbsp; <small></small><br></p>'
    }, {
        id: 2,
        is_unread: 1,
        sent_by: 'Oliver Billnage Yhaksdj Assssisisisi',
        sent_at: '2022-10-08 16:30:30',
        sent_to: 'Hjsjdhd',
        subject: 'Here is the subject 2',
        body: 
        "<p><b><u></u></b><b><u>Important Message</u></b></p><p><u><b></b></u><b><u></u></b>\r\n\r\n<br>Hi ${APP1}, <br><br>Your payment has declined! <br><br>Please contact me urgently to prevent contact. You can call me on ${AccountUserDDI}. <br><br>Thanks <br></p>"
    }, {
        id: 3,
        is_unread: 0,
        sent_by: 'Oliver Billnage Yhaksdj Assssisisisi',
        sent_at: '2022-08-08 16:30:00',
        sent_to: 'Hjsjdhd',
        subject: '',
        body: '<p><b><u>Important Message</u></b></p><p><b><u><br…y me on ${AccountUserDDI}. <br><br>Thanks<br></p>'
    }],
    sent: [{
        id: 8,
        sent_by: 'Oliver Billnage Yhaksdj Assssisisisi',
        sent_at: '2022-08-08 16:30:30',
        sent_to: 'Hjsjdhd',
        subject: 'Here is the subject',
        body: '<p></p><p>Hi ${APP1},</p><br><p></p><p> We have been trying to contact you with regards to an assessment of your account and would like to request your initial credit agreements using our Creditor Review Service.<br><br>Once we receive the relevant information from each of your creditors we will work through the documents to determine the enforceability of the agreement and check that they have followed the correct procedure of collecting the outstanding balance from you.<br><br>We require you to contact us in order to discuss the matter further. We would like to receive your authorisation to carry out the Creditor Claims Review Service and review you Consumer Credit Agreements to look at potentially stopping creditors pursuing your debts.<br>&nbsp;<br>Please call us on <strong>0161 518 6795&nbsp;</strong>or email <font color="#0088cc"><b>cs@clearstartaccountants.co.uk</b></font><br><br>Yours sincerely &nbsp; <small></small><br></p>'
    }, {
        id: 9,
        sent_by: 'Oliver Billnage Yhaksdj Assssisisisi',
        sent_at: '2022-10-08 16:30:30',
        sent_to: 'Hjsjdhd',
        subject: 'Here is the subject 2',
        body: 
        "<p><b><u></u></b><b><u>Important Message</u></b></p><p><u><b></b></u><b><u></u></b>\r\n\r\n<br>Hi ${APP1}, <br><br>Your payment has declined! <br><br>Please contact me urgently to prevent contact. You can call me on ${AccountUserDDI}. <br><br>Thanks <br></p>"
    }]
}