import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ClientComplaintsState {
    complaints: Array<{[key: string]: any}>
}

export const initialClientComplaintsState: ClientComplaintsState = {
    complaints: [],
}

export const clientComplaintsSlice = createSlice({
    name: 'client-complaints',
    initialState: initialClientComplaintsState,
    reducers: {
        setComplaints: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.complaints = action.payload
        },
        resetSlice: () => {
            return initialClientComplaintsState
        },
    }
})

export const clientComplaintsActions = clientComplaintsSlice.actions
export default clientComplaintsSlice