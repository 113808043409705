import React from "react";
import { ButtonWrapper, LoadingSpinner } from "./styles";
import { iButton } from "./types";

const Button = ({
    btnText,
    style,
    onClick,
    btnImg,
    disabled,
    loading,
    gap,
    type,
    paddingInline,
    className = "",
    role,
    children,
}: iButton) => {
    return (
        <ButtonWrapper
            onClick={onClick}
            disabled={loading || disabled}
            color={style}
            gap={gap}
            img={btnImg ? true : false}
            type={type}
            paddingInline={paddingInline}
            className={`button ${className ? ` ${className}` : ""}`}
            role={role}
        >
            <span aria-hidden={loading}>
                {children}
                {btnText}
                {btnImg ? <img src={btnImg} alt={btnText} /> : null}
            </span>
            {loading && (
                <LoadingSpinner className="abs-center"></LoadingSpinner>
            )}
        </ButtonWrapper>
    );
};

export default Button;
