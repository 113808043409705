import { ChangeEvent } from "react";
import { CreditorFormProps } from ".";
import { StyledCreditorBankDetails } from "./styles";
import { Input } from "../../../components";

const CreditorBankDetails: React.FC<CreditorFormProps> = ({
    creditorData,
    setCreditorData,
}) => {
    const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = e.target;
        setCreditorData((prev) => {
            return {
                ...prev,
                [name]: type === "number" ? +value : value,
            };
        });
    };

    return (
        <StyledCreditorBankDetails className="creditor-bank-details">
            <div className="creditor-settings__fields">
                <Input
                    className="creditor-settings__input"
                    placeholder="Enter account name..."
                    type="text"
                    label="Account Name:"
                    name="account_name"
                    value={creditorData ? creditorData.act_name : ""}
                    onChange={inputHandler}
                ></Input>
                <Input
                    className="creditor-settings__input"
                    placeholder="Enter company name..."
                    type="text"
                    inputType="numString"
                    label="Account Number:"
                    name="account_number"
                    value={+creditorData?.act_no || ""}
                    onChange={inputHandler}
                ></Input>

                <Input
                    className="creditor-settings__input"
                    placeholder="e.g. 11-22-33"
                    type="text"
                    inputType="sortCode"
                    label="Account Sort Code:"
                    name="sort_code"
                    value={creditorData?.srtcde || ""}
                    onChange={inputHandler}
                    onBlur={inputHandler}
                ></Input>
            </div>
        </StyledCreditorBankDetails>
    );
};

export default CreditorBankDetails;
