import { useState } from "react";
import MailUploadDetails from "./MailUploadDetails";
import { useParams } from "react-router-dom";
import PreviewMail from "./PreviewMail";
import Header from "../../../../layout/Header";
import { MailUploadPreviewContent } from "./styles";
import PageCounter from "./PageCounter";
import dummyPDF from "../../../../assets/pdfs/testPDF.pdf";
import useFetchData from "../../../../global/hooks/useFetchData";

const MailUploadPreview = () => {
    // const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const [imagePath, setImagePath] = useState<string>("");
    const [mailUploads, setMailUploads] = useState<
        Array<{ [key: string]: any }>
    >([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const { uploadId } = useParams();

    const { data, fetchData, isLoading } = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/mail-upload-file/${uploadId}`,
        },
        {
            fetchOnLoad: true,
            notifyOnSuccess: true,
            onSuccess: (data) => {
                setMailUploads(data?.data);
                setImagePath(data?.data?.file_path || null);
            },
        }
    );

    return (
        <>
            <Header
                title="view upload"
                prevPath="/administration/mail-upload"
                isSubPath
                hasNavBarAbove
            ></Header>
            <MailUploadPreviewContent>
                <PreviewMail
                    file={imagePath}
                    currentPage={currentPage}
                    setTotalPages={setTotalPages}
                />
                <div className="controls">
                    <div className="controls-wrapper">
                        <PageCounter
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                        {dummyPDF && (
                            <MailUploadDetails
                                file={imagePath}
                                data={mailUploads}
                            />
                        )}
                    </div>
                </div>
            </MailUploadPreviewContent>
        </>
    );
};

export default MailUploadPreview;
