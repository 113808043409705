import React, { ChangeEvent, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";
import { StyledAdminLedgerModal } from "./styles";
import ModalButtons from "../components/ModalButtons";
import Input from "../../Input";
import Button from "../../Button";
import ModalHeader from "../components/ModalHeader";
import DropdownSelect from "../../DropdownSelect";
import {
    generateTestOptions,
    ledgerTypeOptions,
} from "../../DropdownSelect/options";
import TextArea from "../../Input/TextArea";
import SuccessContent from "../components/SuccessContent";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxClientLedgerPayment from "../../../store/hooks/useReduxClientLedgerPayment";
import { formatDateString } from "../../../global/utils/helpers";
import { Notify } from "notiflix";
import BasicModal from "../BasicModal";

interface EditLedgerModalProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    onSuccess?: (data: { [key: string]: any }) => void;
    data?: { [key: string]: any };
}

const EditLedgerModal: React.FC<EditLedgerModalProps> = ({
    isOpen,
    onClose,
    onSuccess = () => {},
    data,
}) => {
    const { setDataByKey } = useReduxClientLedgerPayment();
    const { clientId } = useParams();
    const [isDeleting, setIsDeleting] = useState(false);
    const [ledgerData, setLedgerData] = useState<{ [key: string]: any }>({
        pmntRfrnce: data?.pmntRfrnce ?? "",
        rsn: data?.rsn ?? "",
        amnt: data?.amnt ?? 0,
        tpe: data?.tpe ?? null,
        nts: data?.nts ?? "",
        id: data?.id ?? "",
    });
    const isFormValid =
        ledgerData?.pmntRfrnce !== undefined && ledgerData?.tpe !== undefined;
    const formatTime = (str: string) =>
        formatDateString(str, "YYYY-MM-DD HH:mm:ss", "DD/MM/YYYY HH:mm A");

    const updateLedger = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/ledger-update/${clientId}`,
        },
        {
            onSuccess: (data) => {
                onSuccess(data);
                if (data?.data) setDataByKey("ledger", data?.data);
            },
        }
    );
    const deleteLedger = useFetchData(
        {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/ledger/${data?.id}/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setDataByKey("ledger", data.data);
                onClose();
            },
            notifyOnSuccess: true,
            notifySuccessMessage: "Ledger has been deleted",
        }
    );
    const isSuccess = updateLedger.isSuccess || deleteLedger.isSuccess;

    const selectHandler = (key: string, value: number) => {
        if (key in ledgerData)
            setLedgerData((prev) => {
                return { ...prev, [key]: value };
            });
    };

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type } = e.target;
        setLedgerData((prev) => {
            return { ...prev, [name]: type === "number" ? +value : value };
        });
    };

    const deleteHandler = (e: React.FormEvent) => {
        e.preventDefault();
        deleteLedger.fetchData();
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        updateLedger.fetchData({ data: ledgerData });
    };

    if (isDeleting)
        return (
            <BasicModal
                isSuccess={deleteLedger.isSuccess}
                isLoading={deleteLedger.isLoading}
                message="Are you sure you want to delete this ledger?"
                successMessage="Ledger has been deleted."
                submitBtnText="Delete"
                submitBtnColor="red"
                isOpen={isOpen}
                onClose={
                    deleteLedger.isSuccess
                        ? onClose
                        : () => setIsDeleting(false)
                }
                onSubmit={deleteHandler}
            />
        );

    return (
        <StyledAdminLedgerModal
            show={isOpen}
            className="update-ledger"
            width="500px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={onClose}>Edit Ledger</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message={`Ledger has been ${
                        updateLedger.isSuccess ? "updated" : "deleted"
                    }.`}
                    onClose={onClose}
                />
            ) : (
                <form className="update-ledger__form" onSubmit={submitHandler}>
                    <div className="update-ledger__fields">
                        <ul className="data-list">
                            <li>
                                Ledger ID:<span>{data?.id ?? "-"}</span>
                            </li>
                            <li>
                                Created By:
                                <span>{data?.created ?? "-"}</span>
                            </li>
                            <li>
                                Created At:
                                <span>
                                    {formatTime(data?.created_at) ?? "-"}
                                </span>
                            </li>
                            <li>
                                Updated By:
                                <span>{data?.updated ?? "-"}</span>
                            </li>
                            <li>
                                Updated At:
                                <span>
                                    {formatTime(data?.updated_at) ?? "-"}
                                </span>
                            </li>
                        </ul>
                        <Input
                            type="text"
                            label="Payment Refernece:"
                            name="pmntRfrnce"
                            value={ledgerData?.pmntRfrnce}
                            onChange={handleChange}
                            placeholder="Enter payment reference..."
                        />

                        <DropdownSelect
                            label="Type"
                            options={ledgerTypeOptions}
                            value={ledgerData?.tpe || null}
                            onSelect={(data) =>
                                selectHandler("tpe", data.value)
                            }
                            className="update-ledger__type"
                            enableInput
                        />
                        <Input
                            type="number"
                            label="Amount:"
                            name="amnt"
                            value={ledgerData?.amnt.toString()}
                            onChange={handleChange}
                        />
                        <TextArea
                            label="Reason:"
                            name="rsn"
                            value={ledgerData?.rsn}
                            onChange={handleChange}
                            placeholder="Enter reason..."
                        />

                        <TextArea
                            label="Notes:"
                            name="nts"
                            value={ledgerData?.nts}
                            onChange={handleChange}
                        />
                    </div>

                    <ModalButtons onDelete={() => setIsDeleting(true)}>
                        <Button
                            style="purple"
                            btnText={"Save Changes"}
                            type="submit"
                            loading={updateLedger.isLoading}
                            disabled={!isFormValid || deleteLedger.isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledAdminLedgerModal>
    );
};

export default EditLedgerModal;
