import React, { useState, useEffect, SetStateAction } from "react";
import { icons } from "../../../assets/icons";
import { Button } from "../../../components";
import { TablePageContent } from "../../../pages/styles";
import Header from "../../../layout/Header";
import DateButton from "../../../components/Button/DateButton";
import useFetchData from "../../../global/hooks/useFetchData";
import PageTabs from "../../../components/PageTabs/PageTabs";
import SearchRow from "../../../components/SearchRow";
import { dateSearchOptions } from "../../../layout/SlideInMenus/DateSearch/data";
import useDateSearch from "../../../layout/SlideInMenus/DateSearch/useDateSearch";
import { exportCsv } from "../../../global/utils/helpers";
import DataTable from "../../../components/DataTable";
import { EditIntroducerTable } from "./styles";
import { useParams } from "react-router-dom";

const LeadReport = (userData: any) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [userDetails, setIntroducerDetails] = useState([]);
    const { introducerId } = useParams();
    const heading = [["name", "email"]];

    const { data, isLoading, error } = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/introducer-leads-report/${introducerId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {},
        }
    );

    const exportHandler = () => {
        const invoicesData: { [key: string]: any } = data?.data || {};
        const invoiceData = Object.values(invoicesData);
        const invoiceBodies = invoiceData[1].map((obj: string) => obj || {});
        const invoiceHeadings = invoiceData[0].map((obj: string) => obj || "");
        console.log(invoiceHeadings, invoiceBodies);
        exportCsv(
            [[invoiceBodies]],
            [invoiceHeadings],
            ["Lead Report"],
            `leads_report_${introducerId}`
        );
    };

    const searchLeadsIntroducers = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/introducer-leads-search`,
    });
    const leadsData = data?.data.Leads || [];
    const searchedLeadData = searchLeadsIntroducers?.data?.data?.users || [];
    const displayedData =
        searchTerm.length > 3 && !searchLeadsIntroducers.isLoading
            ? searchedLeadData
            : leadsData;

    const searchHandler = (value: string) => {
        if (value.length > 3)
            searchLeadsIntroducers.fetchData({ name: value, id: introducerId });
    };

    return (
        <EditIntroducerTable>
            <SearchRow
                placeholder="Search by case id..."
                value={searchTerm}
                setValue={setSearchTerm}
                onSearch={searchHandler}
                minCharacters={1}
            >
                <Button
                    btnText=""
                    onClick={exportHandler}
                    style="red"
                    btnImg={icons?.exportIcon}
                />
            </SearchRow>
            <DataTable
                data={leadsData || []}
                dataKey="Case Id"
                isLoading={isLoading}
                searchTerm={searchTerm}
                searchKeys={["Case Id"]}
                hideColums={[0]}
                hasAction={false}
            />
        </EditIntroducerTable>
    );
};

export default LeadReport;
