import React, { SetStateAction } from "react";
import { StyledMessageItem } from "./styles";
import { formatDateString } from "../../global/utils/helpers";
import { extractTextFromHTML } from "../../global/utils/helpers";
import { MessageViewerComponentProps } from ".";

interface MessageViewerItemProps extends MessageViewerComponentProps {
    data: { [key: string]: any };
}

const MessageViewerItem: React.FC<MessageViewerItemProps> = ({
    selectedId,
    setSelectedId,
    selectedIds,
    setSelectedIds,
    data = {},
    messageType,
    senderKey = "",
    subjectKey = "",
    bodyKey = "",
    dataKey = "",
    dateKey = "",
}) => {
    const checkBoxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (setSelectedIds)
            setSelectedIds((prev) =>
                prev.includes(+value)
                    ? prev.filter((prevId) => prevId !== +value)
                    : [...prev, +value].sort((a, b) => a - b)
            );
    };

    const selectIdHandler = (value: any) => {
        if (typeof value === "number") setSelectedId(value);
    };
    return (
        <StyledMessageItem className="message-viewer-item">
            {!!selectedIds && (
                <div className="message-viewer-item__checkbox-ctn">
                    <input
                        type="checkbox"
                        className="message-viewer-item__checkbox"
                        checked={selectedIds.includes(data?.[dataKey])}
                        onChange={checkBoxHandler}
                        value={data?.[dataKey]}
                    />
                </div>
            )}
            <button
                data-selected={selectedId === data?.[dataKey]}
                className="message-viewer-item__info"
                type="button"
                onClick={() => selectIdHandler(data?.[dataKey])}
            >
                <div className="message-viewer-item__title">
                    <p className="message-viewer-item__sender">
                        {data?.[senderKey] || (
                            <span className="message-viewer__unknown">
                                Unknown Sender
                            </span>
                        )}
                    </p>
                    <span className="message-viewer-item__date">
                        {formatDateString(data?.[dateKey] || "")}
                    </span>
                </div>
                {messageType === "email" && (
                    <p className="message-viewer-item__subject">
                        {data?.[subjectKey] || <span>(No Subject)</span>}
                    </p>
                )}
                <p className="message-viewer-item__body">
                    {extractTextFromHTML(data?.[bodyKey] || "") || (
                        <span>(No Message)</span>
                    )}
                </p>
            </button>
        </StyledMessageItem>
    );
};

export default MessageViewerItem;
