import StyledPageTabs from "./styles";
import SelectButton from "../Button/SelectButton";
import { SetStateAction } from "react";

interface PageTabsProps {
    className?: string;
    tabs: string[];
    selectedTab?: string;
    setSelectedTab?: React.Dispatch<SetStateAction<any>>;
    selectedTabIndex?: number;
    setSelectedTabIndex?: React.Dispatch<SetStateAction<number>>;
    notifications?: (number | null)[];
    children?: React.ReactNode;
    setSearchTerm?: React.Dispatch<SetStateAction<any>>;
    onTabClick?: (...args: any[]) => void;
}

const PageTabs: React.FC<PageTabsProps> = ({
    tabs,
    selectedTab,
    setSelectedTab,
    selectedTabIndex,
    setSelectedTabIndex,
    notifications,
    children,
    className = "",
    setSearchTerm,
    onTabClick = (tab: string, index: number) => {},
}) => {
    const clickHandler = (tab: string, i: number) => {
        if (setSelectedTab) setSelectedTab(tab);
        if (setSelectedTabIndex) setSelectedTabIndex(i);
        if (setSearchTerm) setSearchTerm("");
        onTabClick(tab, i);
    };
    return (
        <StyledPageTabs
            className={`page-tabs${className ? ` ${className}` : ""}`}
        >
            <ul className="page-tabs__list">
                {tabs.map((tab, i) => (
                    <li key={i} className="page-tabs__item">
                        <SelectButton
                            btnText={tab}
                            type="button"
                            onClick={clickHandler.bind(null, tab, i)}
                            className="page-tabs__btn"
                            selected={
                                selectedTab !== undefined
                                    ? selectedTab === tab
                                    : selectedTabIndex === i
                            }
                            notifications={notifications?.[i] ?? undefined}
                        />
                    </li>
                ))}
            </ul>
            {children}
        </StyledPageTabs>
    );
};

export default PageTabs;
