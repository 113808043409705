import React, { useState, useEffect } from "react";
import moment, { Moment } from "moment";

import useFetchData from "../../../global/hooks/useFetchData";
import StepTwoContent from "../../../components/Modals/Diary/DayMeetings/StepTwoContent";
import ViewMeeting from "../../../components/Modals/Diary/ViewMeeting";
import DayMeetings from "../../../components/Modals/Diary/DayMeetings";
import TimeTable from "../../../components/TimeTable";
import { icons } from "../../../assets/icons";
import { Button, Modal } from "../../../components";
import { LoadingSpinner } from "../../../components/Button/styles";

interface NotificationDiaryProps {
    isOpen?: boolean;
}

const NotificationDiary: React.FC<NotificationDiaryProps> = ({
    isOpen = false,
}) => {
    const [currentDay, setCurrentDay] = useState<Moment>(moment());
    const [selectedMeeting, setSelectedMeeting] = useState<{
        [key: string]: any;
    } | null>(null);
    const [viewTicketModal, setViewTicketModal] = useState(false);
    const currentDayStr = currentDay.format("YYYY-MM-DD");
    const { fetchData, data, isLoading } = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/diary-task-day-filter`,
    });

    const dayData = data?.data || [];

    useEffect(() => {
        fetchData({ date: currentDayStr });
    }, [currentDayStr]);

    if (!isOpen) return null;
    const cardSelectHandler = (data: { [key: string]: any }) => {
        if (typeof data === "object") {
            setSelectedMeeting(data);
        }
    };
    return (
        <>
            <Modal isVisible={viewTicketModal}>
                <ViewMeeting
                    setViewTicketModal={setViewTicketModal}
                    open={viewTicketModal}
                    selectedMeeting={selectedMeeting}
                    setShowModal={setViewTicketModal}
                    setSelectedMeeting={setSelectedMeeting}
                    onUpdated={() => fetchData({ date: currentDayStr })}
                />
            </Modal>

            <div className="notification__diary">
                <div className="notification__diary__row">
                    <Button
                        btnText=""
                        btnImg={icons.greyLeftArrow}
                        onClick={() =>
                            setCurrentDay((prev) =>
                                moment(prev).subtract(1, "day")
                            )
                        }
                        style="cancel"
                    />
                    <span>{currentDay.format("DD MMMM, YYYY")}</span>
                    <Button
                        btnText=""
                        btnImg={icons.greyLeftArrow}
                        onClick={() =>
                            setCurrentDay((prev) => moment(prev).add(1, "day"))
                        }
                        style="cancel"
                    />
                </div>
                {!data ? (
                    <LoadingSpinner size={40} />
                ) : selectedMeeting ? (
                    <>
                        <StepTwoContent
                            setSelectedMeeting={setSelectedMeeting}
                            selectedMeeting={selectedMeeting}
                            setViewTicketModal={setViewTicketModal}
                            setShowModal={() => {}}
                            onSuccess={() => fetchData({ date: currentDayStr })}
                        />
                    </>
                ) : (
                    <TimeTable
                        data={dayData}
                        isLoading={isLoading}
                        onCardSelect={cardSelectHandler}
                    />
                )}
            </div>
        </>
    );
};

export default NotificationDiary;
