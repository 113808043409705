import styled from "styled-components";
import { theme } from "../../../global/theme";

export const ColoredBlock = styled.td<{ color?: string }>`
    font-weight: 600 !important;

    color: ${({ color }) => {
        if (color === "green") return "#09b33c";
        if (color === "red") return "#f21616";
        if (color === "yellow") return "#eec10c";
        return "";
    }};
    text-transform: capitalize;
`;

export const ExternalLinkBlock = styled.td`
    padding: 0 !important;

    & a {
        padding: 10px;
        width: 100%;
        display: block;

        &:hover {
            background-color: ${theme.colors.formBlue};
        }
    }
`;

export const IconBlock = styled.td<{ width?: string }>`
    font-weight: 600 !important;
    width: ${({ width }) => width || ""};
    position: relative;

    & svg {
        margin-inline: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const ColorBlock = styled.td<{ color?: string }>`
    width: 30px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        height: 80%;
        aspect-ratio: 1/1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: ${({ color }) => `${color} !important` || ""};
        border-radius: 4px;
    }
`;
