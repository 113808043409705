import styled from "styled-components";
import { theme } from "../../../global/theme";

export const CalendarDateSearchContainer = styled.div`
    width: 100%;
    min-height: 360px;

    & .rdrDateRangeWrapper {
        border-radius: 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    & .rdrMonthAndYearWrapper {
        padding-top: 0;
    }

    & .rdrMonthAndYearPickers {
        gap: 8px;
    }

    & .rdrMonthPicker,
    & .rdrYearPicker {
        margin: 0;
    }

    & .rdrMonth {
        background-color: ${theme?.colors?.white};
        border-right: 0.2px solid ${theme?.colors?.shade};

        &:last-child {
            margin-left: 0;
            border-right: none;
            border-left: 0.2px solid ${theme?.colors?.shade};
        }
    }

    & .rdrDateDisplayWrapper {
        order: 3;
        margin: 24px 15px 24px 15px;
        background-color: transparent;

        & > .rdrDateDisplay {
            margin: 0;
            display: flex;
            gap: 12px;

            & > span {
                margin: 0;
            }
        }
    }

    & .rdrDateInput {
        & > input {
            color: #4c7dd2;
            font-size: 16px;
        }
    }
`;
