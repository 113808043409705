import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../../layout/Header";
import StyledKeyEventsContent from "./styles";
import useFetchData from "../../../../global/hooks/useFetchData";
import Sales from "./Sales";
import IdLoa from "./IdLoa";
import Administration from "./Administration";
import SetupFeePayments from "./SetupFeePayments";
import CallMarkers from "./CallMarkers";
import { StyledBaseDetailsSubmitCtn } from "../styles";
import { Button } from "../../../../components";

export interface KeyEventsContainerProps {
    formData: { [key: string]: any };
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSelect: (key: string, value: any) => void;
    data: { [key: string]: any };
}

const KeyEvents = () => {
    const { clientId } = useParams();
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        contract_sent: "",
        contract_received: "",
        courtesy_call: "",
        scanned_id: "",
        wet_loa_sent: "",
        wet_loa_received: "",
        wet_loa_reminder: "",
        solicitor_loa_sent: "",
        standing_order_sent: "",
        welcome_call_due: "",
        welcome_call_completed: "",
        in_collection_date: "",
        cancel_date: "",
        cancel_status: null,
        setup_fee_1:null,
        setup_fee_1_date: "",
        setup_fee_2:null,
        setup_fee_2_date: "",
        cc_fee_1:"",
        cc_fee_1_date: "",
        cc_fee_2:"",
        cc_fee_2_date: "",
        cc_fee_3:"",
        cc_fee_3_date: "",
        r1_fee:"",
        r1_fee_date: "",
        r2_fee:"",
        r2_fee_date: "",
        electricity_marker: "",
        insurance_marker: "",
        mobile_marker: "",
    });

    const getKeyEvent = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/key-events/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {

                setFormData(data?.data);
            },
        }
    );
    const updateKeyEvent = useFetchData({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_API}/key-events/${clientId}`,
    });

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = e.target;
        setFormData((prev) => {
            return { ...prev, [name]: type === "number" ? +value : value };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setFormData((prev) => {
            return { ...prev, [key]: value };
        });
    };
    const updateKeyEvents = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/update-key-events/${clientId}`,
    },
    {
        onSuccess: (data) => {
            setFormData(data?.data);
        },
    });
    const containerProps: KeyEventsContainerProps = {
        formData,
        onInputChange: inputHandler,
        onSelect: selectHandler,
        data: getKeyEvent?.data?.data ?? {},
    };
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(formData);
        updateKeyEvents.fetchData({data:formData});
    };
    return (
        <>
            <Header title="Key Events" hasNavBarAbove hasPrevPath />
            <StyledKeyEventsContent>
                <form onSubmit={submitHandler}>
                    <Sales {...containerProps} />
                    <IdLoa {...containerProps} />
                    <Administration {...containerProps} />
                    <SetupFeePayments {...containerProps} />
                    <CallMarkers {...containerProps} />
                    <StyledBaseDetailsSubmitCtn>
                        <Button
                            type="submit"
                            btnText="Save Key Events"
                            style="purple"
                            loading={updateKeyEvent.isLoading}
                        />
                    </StyledBaseDetailsSubmitCtn>
                </form>
            </StyledKeyEventsContent>
        </>
    );
};

export default KeyEvents;
