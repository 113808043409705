import styled from "styled-components";
import { PageContent } from "../../../../pages/styles";

const StyledKeyEventsContent = styled(PageContent)`
    & > form {
        display: grid;
        grid-template-columns: min(100%, 1120px);
        justify-content: center;
        gap: 24px;

        & > .sales,
        & > .id-loa,
        & > .admin,
        & > .setup-fee {
            & .header-container__ctn {
                display: grid;
                gap: 12px 16px;
                grid-template-columns: 1fr 1fr;
            }
        }

        & > .setup-fee {
            & label {
                display: flex;
                justify-content: space-between;
                width: 100%;

                & > span {
                    font-weight: 400;
                }
            }
        }

        & > .call-markers {
            & .group {
                display: grid;
                gap: 12px 16px;
                grid-template-columns: 1fr 1fr;

                & h3 {
                    grid-column: 1/-1;
                    font-size: 14px;
                }
            }
        }
    }
`;

export default StyledKeyEventsContent;
