import { StyledModalContent } from "./styles";

interface ModalContentProps {
    children?: React.ReactNode;
    height?: string;
    width?: string;
}

const ModalContent: React.FC<ModalContentProps> = ({
    children,
    height = "",
    width = "",
}) => {
    return (
        <StyledModalContent
            height={height}
            width={width}
            className="modal-content"
        >
            {children}
        </StyledModalContent>
    );
};

export default ModalContent;
