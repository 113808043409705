import { AgreementDetailsContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";

const SecurityPassword: React.FC<AgreementDetailsContainerProps> = ({
    formData,
    onSelect,
    onInputChange,
    handlerList,
}) => {
    return (
        <HeaderContainer
            title="Security Password"
            headerStyle="gradientBlue"
            className="security-password"
        >
            <Input
                type="password"
                label="Security Password:"
                value={`${formData?.security_password ?? ""}`}
                onChange={onInputChange}
                name="security_password"
                hasShowPassword
            />
        </HeaderContainer>
    );
};

export default SecurityPassword;
