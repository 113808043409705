import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ReportsState {
   list: {[key: string]: any} | null;
}

const initialReportsState: ReportsState = {
    list: null,
}

export const reportsSlice = createSlice({
    name: 'reports',
    initialState: initialReportsState,
    reducers: {
        setList: (state, action: PayloadAction<{[key: string]: any} | null>) => {
            state.list = action.payload
        },
    }
})

export const reportsActions = reportsSlice.actions
export default reportsSlice