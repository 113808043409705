import { useState, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../../layout/Header";
import StyledBusinessAccountancyContent from "./styles";
import CompanyDetails from "./CompanyDetails";
import GovGateway from "./GovGateway";
import AccountsInfo from "./AccountsInfo";
import VAT from "./VAT";
import TaxFilerRef from "./TaxFilerRef";
import { Button } from "../../../../components";
import { StyledBaseDetailsSubmitCtn } from "../../BaseDetails/styles";
import useFetchData from "../../../../global/hooks/useFetchData";

export interface BusinessAccountancyContainerProps {
    formData: { [key: string]: any };
    setFormData: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
    onInputChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
}

const BusinessAccountancy = () => {
    const { clientId } = useParams();
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        company_number: "",
        company_name: "",
        company_address: "",
        auth_code: "",
        no_of_directors: 0,
        no_of_employees: 0,
        gov_gateway_username: "",
        gov_gateway_password: "",
        date_of_incorporation: "",
        confirmation_statment_date: "",
        accounts_ref_number: "",
        accounts_year_end_date: "",
        submission_date: "",
        paye_ref_number: "",
        vat_number: "",
        vat_period_start: "",
        vat_period_end: "",
        vat_payment_due: "",
        vat_payment_receive_date: "",
        tax_filer_ref_number: "",
    });

    const getBusinessAccountancy = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/business-accounts/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data)
                    setFormData(data.data?.business_accountancy || []);
            },
        }
    );

    const updateBusinessAccountancy = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/business-accounts/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setFormData(data?.data?.business_accountancy);
            },
            notifyOnSuccess: true,
            notifySuccessMessage: "Updated has been saved",
        }
    );

    const inputHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value, name, type } = e.target;
        const formattedValue = () => {
            if (name === "company_number") return value.replace(/[^0-9]/g, "");
            if (type === "number") return +value;
            return value;
        };
        setFormData((prev) => {
            return { ...prev, [name]: formattedValue() };
        });
    };

    const containerProps = {
        formData,
        setFormData,
        onInputChange: inputHandler,
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(formData);
        updateBusinessAccountancy.fetchData(formData);
    };

    return (
        <>
            <Header
                title="Business Accountancy"
                hasNavBarAbove
                isSubPath
                hasPrevPath
            />
            <StyledBusinessAccountancyContent>
                <form onSubmit={submitHandler}>
                    <CompanyDetails {...containerProps} />
                    <GovGateway {...containerProps} />
                    <AccountsInfo {...containerProps} />
                    <VAT {...containerProps} />
                    <TaxFilerRef {...containerProps} />
                    <StyledBaseDetailsSubmitCtn>
                        <Button
                            type="submit"
                            btnText="Save Business Accountancy"
                            loading={updateBusinessAccountancy.isLoading}
                            style="purple"
                        />
                    </StyledBaseDetailsSubmitCtn>
                </form>
            </StyledBusinessAccountancyContent>
        </>
    );
};

export default BusinessAccountancy;
