import { SetStateAction, useState, useEffect } from "react";
import { Input, Modal } from "../..";
import { StyledCreditorSettingsModal } from "./styles";
import ModalHeader from "../components/ModalHeader";
import ModalButtons from "../components/ModalButtons";
import { Button } from "../..";
import React from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import SuccessContent from "../components/SuccessContent";
import { useNavigate } from "react-router-dom";
import DropdownSelect from "../../DropdownSelect";
interface SendClientLettersProps {
    isOpen: boolean;
    data: { [key: string]: any };
    setCurrentModal: React.Dispatch<SetStateAction<string>>;
}

const SendClientLetters: React.FC<SendClientLettersProps> = ({
    isOpen,
    data,
    setCurrentModal,
}) => {
    const navigate = useNavigate();
    const { creditorId } = useParams();
    const [confirmText, setConfirmText] = useState("");
    const [letterId, setLetterId] = useState<number | null>(null);
    const { fetchData, isLoading, isSuccess } = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/client-sent-letter`,
    });

    const getLetters = useFetchData({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_API}`,
    });
    const lettersList: any = data || [];

    const isConfirmed = confirmText.toLowerCase().trim() === "confirm";

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        let temp ={

            letter_id:letterId,
            creditor_id:creditorId
        }
        fetchData(temp);
    };

    const closeHandler = () => {
        setCurrentModal("");
        if (isSuccess) navigate("/creditor/creditors");
    };

    useEffect(() => {
        setConfirmText("");
    }, [isOpen]);
    return (
        <Modal isVisible={isOpen}>
            <StyledCreditorSettingsModal
                show={isOpen}
                className="delete-creditor"
            >
                <ModalHeader onClose={closeHandler}>
                    Send Client Letters
                </ModalHeader>
                {isSuccess ? (
                    <SuccessContent
                        message="Letter successfully sent."
                        onClose={closeHandler}
                    />
                ) : (
                    <form
                        className="delete-creditor__form"
                        onSubmit={submitHandler}
                    >
                        <DropdownSelect
                            label="Pick an letter to send:"
                            value={letterId}
                            options={lettersList}
                            onSelect={(option) => setLetterId(option?.id)}
                            optionKey="id"
                            labelKeys={["name"]}
                            enableInput
                        ></DropdownSelect>
                        <Input
                            type="text"
                            label="Type 'confirm' to confirm:"
                            value={confirmText}
                            onChange={(e) => setConfirmText(e.target.value)}
                        ></Input>
                        <ModalButtons>
                            <Button
                                type="button"
                                onClick={closeHandler}
                                style="cancel"
                                btnText="Close"
                            />
                            <Button
                                style="purple"
                                btnText="Send"
                                type="submit"
                                loading={isLoading}
                                disabled={!isConfirmed || !letterId}
                            />
                        </ModalButtons>
                    </form>
                )}
            </StyledCreditorSettingsModal>
        </Modal>
    );
};

export default SendClientLetters;
