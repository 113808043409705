import { useState } from "react";
import HeaderContainer from "../../../containers/HeaderContainter";
import Header from "../../../layout/Header";
import { StyledWFHRebateContent } from "./styles";
import DropdownSelect from "../../../components/DropdownSelect";
import { yesNoValueOptions } from "../../../components/DropdownSelect/options";
import useFetchData from "../../../global/hooks/useFetchData";
import { useParams } from "react-router-dom";
import { StyledBaseDetailsSubmitCtn } from "../BaseDetails/styles";
import { Button } from "../../../components";
import DropdownMenu from "../../../components/DropdownMenu";

const WFHRebate = () => {
    const { clientId } = useParams();
    const [wfhRebate, setWfhRebate] = useState<{ [key: string]: any }>({
        claim_relief_wfh_only: null,
        to_complete_assessment: null,
    });

    const getWFHRebate = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/wfh-paye-rebate/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setWfhRebate(data?.data || {});
            },
        }
    );

    const updateWFHRebate = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/wfh-rebate/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setWfhRebate(data.data || {});
            },
        }
    );

    const selectHandler = (key: string, value: any) => {
        setWfhRebate((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(wfhRebate);
        updateWFHRebate.fetchData(wfhRebate);
    };

    return (
        <>
            <Header
                title="Work From Home Rebate"
                hasNavBarAbove
                isSubPath
                hasPrevPath
            />
            <StyledWFHRebateContent>
                <form onSubmit={submitHandler}>
                    <HeaderContainer
                        title="Work From Home"
                        headerStyle="gradientBlue"
                        ctnClassName="wfh-ctn-content"
                    >
                        <div className="ctn">
                            <p>
                                Are you only claiming tax relief on your
                                expenses for working from home?
                            </p>
                            <DropdownMenu title="?">
                                <article>
                                    <h3>Help</h3>
                                    <p>
                                        You can only claim for extra household
                                        costs to do with your work such as
                                        business phone calls and gas and
                                        electricity for your work area. You
                                        cannot claim for things that you use for
                                        both private and work purposes such as
                                        rent or broadband, or for equipment or
                                        furniture.
                                    </p>
                                </article>
                            </DropdownMenu>
                            <DropdownSelect
                                options={yesNoValueOptions}
                                hasDefaultValue
                                value={wfhRebate?.claim_relief_wfh_only || null}
                                onSelect={(option) =>
                                    selectHandler(
                                        "claim_relief_wfh_only",
                                        option.value
                                    )
                                }
                            />
                        </div>
                        <div className="ctn">
                            <p>
                                Are you going yo complete a Self Assessment
                                return for this tax year 2022-2023?
                            </p>
                            <DropdownMenu title="?">
                                <article>
                                    <h3>Help</h3>
                                    <p>
                                        You must complete a Self Assessment tax
                                        return if, in the last tax year (6 April
                                        to 5 April), you were: self-employed as
                                        a 'sole trader' and earned more than
                                        £1,000 a partner in a business
                                        partnership You will not usually need to
                                        send a return if your only income is
                                        from your wages or pension. But you may
                                        need to send one if you have any other
                                        untaxed income, such as: money from
                                        renting out a property tips and
                                        commission, income from savings,
                                        investments and dividends, foreign
                                        income
                                    </p>
                                </article>
                            </DropdownMenu>
                            <DropdownSelect
                                options={yesNoValueOptions}
                                hasDefaultValue
                                value={
                                    wfhRebate?.to_complete_assessment || null
                                }
                                onSelect={(option) =>
                                    selectHandler(
                                        "to_complete_assessment",
                                        option.value
                                    )
                                }
                            />
                        </div>
                    </HeaderContainer>
                    <StyledBaseDetailsSubmitCtn>
                        <Button
                            type="submit"
                            btnText="Save WFH Rebate"
                            loading={updateWFHRebate.isLoading}
                            style="purple"
                        />
                    </StyledBaseDetailsSubmitCtn>
                </form>
            </StyledWFHRebateContent>
        </>
    );
};

export default WFHRebate;
