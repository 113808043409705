import { SetStateAction, useState, useEffect } from "react";
import { Input, Modal } from "../..";
import { StyledCreditorSettingsModal } from "./styles";
import ModalHeader from "../components/ModalHeader";
import ModalButtons from "../components/ModalButtons";
import { Button } from "../..";
import React from "react";
import useFetchData from "../../../global/hooks/useFetchData";
import SuccessContent from "../components/SuccessContent";
import { useNavigate } from "react-router-dom";
import DropdownSelect from "../../DropdownSelect";
import { Notify } from "notiflix";

interface TransferDebtsProps {
    isOpen: boolean;
    id: any;
    data: { [key: string]: any };
    setCurrentModal: React.Dispatch<SetStateAction<string>>;
}

const TransferDebts: React.FC<TransferDebtsProps> = ({
    isOpen,
    data,
    id,
    setCurrentModal,
}) => {
    const navigate = useNavigate();
    const [confirmText, setConfirmText] = useState("");
    const [creditorId, setCreditorId] = useState<number | null>(null);
    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-transfer-debts`,
        },
        {
            onSuccess: (data) => {
                Notify.success("Transfer Completed");
            },
        }
    );

    const creditorsList: any = data || [];

    const isConfirmed = confirmText.toLowerCase().trim() === "confirm";

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        fetchData({ cedtr_id: creditorId, id: id });
    };

    const closeHandler = () => {
        setCurrentModal("");
        if (isSuccess) navigate("/creditor/creditors");
    };

    useEffect(() => {
        setConfirmText("");
    }, [isOpen]);
    return (
        <Modal isVisible={isOpen}>
            <StyledCreditorSettingsModal
                show={isOpen}
                className="delete-creditor"
            >
                <ModalHeader onClose={closeHandler}>Transfer Debts</ModalHeader>
                {isSuccess ? (
                    <SuccessContent
                        message="Debts successfully Transfered."
                        onClose={closeHandler}
                    />
                ) : (
                    <form
                        className="delete-creditor__form"
                        onSubmit={submitHandler}
                    >
                        <DropdownSelect
                            label="New Creditor:"
                            value={creditorId}
                            options={creditorsList}
                            onSelect={(option) => setCreditorId(option?.id)}
                            optionKey="id"
                            labelKeys={["name"]}
                            enableInput
                        ></DropdownSelect>
                        <Input
                            type="text"
                            label="Type 'confirm' to confirm:"
                            value={confirmText}
                            onChange={(e) => setConfirmText(e.target.value)}
                        ></Input>
                        <ModalButtons>
                            <Button
                                type="button"
                                onClick={closeHandler}
                                style="cancel"
                                btnText="Close"
                            />
                            <Button
                                style="purple"
                                btnText="Transfer"
                                type="submit"
                                loading={isLoading}
                                disabled={!isConfirmed || !creditorId}
                            />
                        </ModalButtons>
                    </form>
                )}
            </StyledCreditorSettingsModal>
        </Modal>
    );
};

export default TransferDebts;
