import { ReactComponent as ArrowIcon } from "../../assets/icons/DropdownMenu/arrow.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/DropdownMenu/cancel.svg";
import { ReactComponent as CarryIcon } from "../../assets/icons/DropdownMenu/carry.svg";
import { ReactComponent as ChangeIcon } from "../../assets/icons/DropdownMenu/change.svg";
import { ReactComponent as CoinIcon } from "../../assets/icons/DropdownMenu/coin.svg";
import { ReactComponent as CoinCrossedIcon } from "../../assets/icons/DropdownMenu/coinCrossed.svg";
import { ReactComponent as DotIcon } from "../../assets/icons/DropdownMenu/dot.svg";
import { ReactComponent as ExpireIcon } from "../../assets/icons/DropdownMenu/expire.svg";
import { ReactComponent as FlipIcon } from "../../assets/icons/DropdownMenu/flip.svg";
import { ReactComponent as InactiveIcon } from "../../assets/icons/DropdownMenu/inactive.svg";
import { ReactComponent as PieIcon } from "../../assets/icons/DropdownMenu/pie.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/DropdownMenu/remove.svg";
import { ReactComponent as RequestIcon } from "../../assets/icons/DropdownMenu/request.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/DropdownMenu/send.svg";
import { ReactComponent as StandingIcon } from "../../assets/icons/DropdownMenu/standing.svg";
import { ReactComponent as TickIcon } from "../../assets/icons/DropdownMenu/tick.svg";
import { ReactComponent as TransferIcon } from "../../assets/icons/DropdownMenu/transfer.svg";
import { ReactComponent as UpdateIcon } from "../../assets/icons/DropdownMenu/update.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/DropdownMenu/upload.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/DropdownMenu/profile.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/DropdownMenu/logout.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/DropdownMenu/link.svg";
import { ReactComponent as ImageIcon } from "../../assets/icons/DropdownMenu/image.svg";

const DropdownMenuIcons = {
    arrow: ArrowIcon,
    cancel: CancelIcon,
    carry: CarryIcon,
    change: ChangeIcon,
    coin: CoinIcon,
    coinCrossed: CoinCrossedIcon,
    dot: DotIcon,
    expire: ExpireIcon,
    flip: FlipIcon,
    inactive: InactiveIcon,
    pie: PieIcon,
    remove: RemoveIcon,
    request: RequestIcon,
    send: SendIcon,
    standing: StandingIcon,
    tick: TickIcon,
    transfer: TransferIcon,
    update: UpdateIcon,
    upload: UploadIcon,
    profile: ProfileIcon,
    logout: LogoutIcon,
    link: LinkIcon,
    image: ImageIcon,
};

export default DropdownMenuIcons;
