import { useAppSelector, useAppDispatch } from ".";
import {
    clientLedgerPaymentActions,
    initialClientLedgerPaymentState,
} from "../slices/clientLedgerPaymentSlice";

const useReduxClientLedgerPayment = () => {
    const { ledger, payments, paymentsSchedule } = useAppSelector(
        (state) => state["client-ledgerPayment"]
    );
    const dispatch = useAppDispatch();

    const setDataByKey = (
        key: keyof typeof initialClientLedgerPaymentState,
        data: { [key: string]: any } | null
    ) => {
        dispatch(clientLedgerPaymentActions.setDataByKey({ key, data }));
    };

    const resetSlice = () => {
        dispatch(clientLedgerPaymentActions.resetSlice());
    };

    return {
        ledger,
        payments,
        paymentsSchedule,
        setDataByKey,
        resetSlice,
    };
};

export default useReduxClientLedgerPayment;
