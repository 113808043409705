import styled from "styled-components";
import { theme } from "../../../global/theme";

const StyledCreditorSettings = styled.section`
    display: flex;
    gap: 32px;
    flex: 1;

    & .page-tabs {
        & button {
            padding-inline: 24px;
        }
    }
    & .creditor-settings {
        &__title {
            margin-bottom: 16px;
        }
        &__info {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        &__tabs {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            margin-bottom: 24px;
        }

        &__create {
            border: none;
            background-color: transparent;
            background-image: ${theme?.gradients.mintGreen};
            color: ${theme.colors.white};
            border-radius: 6px;
            padding: 8px 24px;
            font-size: 14px;
            height: 40px;
        }

        &__content {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        &__actions {
        }

        &__fields {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px 32px;
            max-width: 900px;

            @media screen and (max-width: 1024px) {
                grid-template-columns: 1fr;
            }
        }

        &__input {
            & input {
                height: 40px;
            }
        }

        &__dropdown {
            & .dropdown-select__field {
                height: 40px;
            }
        }

        &__actions {
            width: min(25vw, 320px);
            min-width: 280px;

            &__form {
                background-color: ${theme?.colors.white};
                border-radius: 12px;
                padding: 16px;

                & > h4 {
                }

                & > p {
                    font-size: 12px;
                    font-weight: 300;
                    margin-top: 8px;
                    margin-bottom: 16px;
                }
            }

            &__btns {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }

            &__btn {
                padding: 8px;
                font-size: 14px;
                border: none;
                background-color: transparent;
                border-radius: 4px;
                color: ${theme?.colors.white};

                &:first-child,
                &:last-child {
                    background-color: ${theme?.colors.purple};
                }

                &:nth-child(2),
                &:nth-child(3) {
                    background-image: ${theme?.gradients.skyBlue};
                }

                &:nth-child(4) {
                    background-image: ${theme?.gradients.dangerRed};
                }
            }
        }
    }
`;

export const StyledCreditorDetails = styled.section`
    display: flex;
    flex-direction: column;
    gap: 24px;
    & .creditor-details {
        &__title {
            margin-bottom: 16px;
        }
    }
`;

export const StyledCreditorKeyEvents = styled.section`
    max-width: 900px;

    & .creditor-settings {
        &__textarea {
            grid-column: 1/-1;

            & textarea {
                height: 160px;
            }
        }
    }

    & .creditor-key-events {
        &__info {
            display: flex;
            margin-top: 32px;
            border-top: 0.6px solid ${theme?.colors.shade};
            padding: 24px 0;
            gap: 10%;
        }

        &__data {
            & > h5 {
                font-size: 12px;
            }

            & > p {
                margin-top: 12px;
                font-size: 12px;
                font-weight: 300;
            }
        }
    }
`;

export const StyledCreditorBankDetails = styled.section``;

export const StyledCreditorClientList = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;

    & .creditor-client-list {
    }
`;

export default StyledCreditorSettings;
