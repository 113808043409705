import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type StateProperty = {[key: string]: any} | Array<{[key: string]: any}> | null;

export interface CreateClientState {
    personal_info: {[key: string]: any} | null,
    address: Array<{[key: string]: any}> | null,
    additional_info: {[key: string]: any} | null,
    products: {[key: string]: any} | null,
    personal_budgeting: {[key: string]: any} | null,
    creditor_info: Array<{[key: string]: any}> | null,
    personal_accountancy: {[key: string]: any} | null,
    business_accountancy: {[key: string]: any} | null,
    payment: {[key: string]: any} | null,
}

const initialCreateClientState: CreateClientState = {
    personal_info: null,
    address: null,
    additional_info: null,
    products: null,
    personal_budgeting: null,
    creditor_info: [],
    personal_accountancy: null,
    business_accountancy: null,
    payment: null,
}

export const createClientSlice = createSlice({
    name: 'createClient',
    initialState: initialCreateClientState, 
    reducers: {
        resetSlice: () => {
            return initialCreateClientState
        },
        setData: (state, action: PayloadAction<{[key: string]: any}>) => {
            Object.keys(action.payload).forEach(key => {
                if(key in state) {
                    state[key as keyof CreateClientState] = action.payload[key] || null
                }
            })
            
        },
        setDataByKey: (state, action: PayloadAction<{key: keyof CreateClientState, data: {[key: string]: any} | {[key: string]: any}[] | null}>) => {
            const {key, data} = action.payload;
            if(key in state) {
                state[key as keyof CreateClientState] = data as any;
            }
        },
        createPersonalBudegting: (state, action: PayloadAction<{data: {[key: string]: any}, typeName: string}>) => {
            const {data, typeName} = action.payload
            state?.personal_budgeting?.[typeName].push(data)
        },
        updatePersonalBudegting: (state, action: PayloadAction<{data: {[key: string]: any}, typeName: string, id: number}>) => {
            const {data, typeName, id} = action.payload
            const typeArray = state?.personal_budgeting?.[typeName];
            if (typeArray) {
                const targetIndex = typeArray.findIndex((obj: {[key: string]: any}) => obj.id === id)
                if (targetIndex !== -1) typeArray.splice(targetIndex, 1, data)
            }
        },
        deletePersonalBudegting: (state, action: PayloadAction<{typeName: string, id: number}>) => {
            const {typeName, id} = action.payload
            const typeArray = state?.personal_budgeting?.[typeName];
            if (typeArray) {
                const targetIndex = typeArray.findIndex((obj: {[key: string]: any}) => obj.id === id)
                if (targetIndex !== -1) typeArray.splice(targetIndex, 1)
            }
        },
        createDebt: (state, action: PayloadAction<{[key: string]: any}>) => {
            if (!state?.creditor_info) {
                state.creditor_info = [action.payload]
            } else {
                state?.creditor_info?.push(action.payload)
            }
        },
        updateDebt: (state, action: PayloadAction<{data: {[key: string]: any}, id: number}>) => {
            const  {data, id} = action.payload
            if (Array.isArray(state?.creditor_info)) {
                const targetIndex = state.creditor_info.findIndex((obj: {[key: string]: any}) => obj.id === id);
                console.log(targetIndex, id)
                if (targetIndex !== -1) state.creditor_info.splice(targetIndex, 1, data)
            } 
        },
        deleteDebt: (state, action: PayloadAction<number>) => {
            if (Array.isArray(state?.creditor_info)) {
                const targetIndex = state.creditor_info.findIndex((obj: {[key: string]: any}) => obj.id === action.payload);
                if (targetIndex !== -1) state.creditor_info.splice(targetIndex, 1)
            } 
        },

        
    }
})

export const createClientActions = createClientSlice.actions
export default createClientSlice