import styled from "styled-components";
import { theme } from "../../global/theme";

export const LoginPageWrapper = styled.div`
    background-color: rgb(246, 249, 252);
    height: 100vh;
    width: 100%;
`;

export const Form = styled.form`
    width: min(90%, 732px);
    margin: 109px auto;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid rgba(77, 158, 255, 1);
    padding: 36px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 400px;

    .loginInput {
        width: min(90%, 375px);
        margin: 0 auto 20px;

        > input {
            width: 100%;
        }

        > label {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #0075ff;
        }

    }

    & .forgot-ctn {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 0;
    }
    
    & .message {
        font-weight: 300;
        font-size: 14px;
        margin-top: 24px;
        min-height: 52px;

    }
`;

export const Logo = styled.p`
    font-family: ${theme.fontFamily.primary};
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.06em;
    background: linear-gradient(123.76deg, #4d9eff 4.28%, #686fff 103.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    text-align: center;
    margin-bottom: 16px;
`;

export const SignInBtn = styled.button`
    font-family: ${theme.fontFamily.primary};
    font-weight: 400;
    font-size: 32px;
    background: linear-gradient(123.76deg, #4d9eff 4.28%, #686fff 103.7%);
    border: 1px solid #e9e9e9;
    border-radius: 200px;
    width: min(90%, 375px);
    color: #ffffff;
    font-weight: 500;
    font-size: 15px;
    padding: 13px 0;
    margin-top: 15px;
    cursor: pointer;
`;

export const OptionsCtn = styled.div`
    display: flex;
    justify-content: space-between;
    width: min(90%, 375px);
    margin: 15px 0;

    a,
    p {

        font-weight: 300;
        font-size: 15px;
    }

    .forgotPword {
        color: #0057ff;
        cursor: pointer;
        margin-left: auto;
    }
`;
export const RememberMeCtn = styled.div`
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
    }
`;
