import React from "react";
import { PayeRebateContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";

interface PayeRebateExpenseProps extends PayeRebateContainerProps {
    title: string;
    listTitle?: string;
    listitems?: string[];
    questionKeys: string[];
    answerKeys: string[];
}

const Expense: React.FC<PayeRebateExpenseProps> = ({
    onInputChange,
    payeRebate,
    title,
    listTitle,
    listitems = [],
    questionKeys,
    answerKeys,
}) => {
    return (
        <HeaderContainer
            title={title}
            headerStyle="gradientBlue"
            className="paye-rebate-expense"
            ctnClassName="col-2"
        >
            <ul>
                {listTitle && <p>{listTitle}</p>}
                {listitems?.length
                    ? listitems.map((item, i) => <li key={i}>{item}</li>)
                    : null}
            </ul>
            {questionKeys.map((key, i) => (
                <React.Fragment key={i}>
                    <Input
                        type="text"
                        label={`Question ${i + 1}:`}
                        value={payeRebate?.[key] || ""}
                        name={key}
                        onChange={onInputChange}
                    />
                    <Input
                        type="text"
                        label={`Answer ${i + 1}:`}
                        value={payeRebate?.[answerKeys[i]] || ""}
                        name={answerKeys[i]}
                        onChange={onInputChange}
                    />
                </React.Fragment>
            ))}
        </HeaderContainer>
    );
};

const Expenses: React.FC<PayeRebateContainerProps> = ({
    ...containerProps
}) => {
    return (
        <>
            <Expense
                {...containerProps}
                title="Job Expense:"
                listTitle="Claim expenses for money spent on likes of uniforms, work clothes & tools:"
                listitems={[
                    "This could include the repair of replacement of small tools you need to do your job.",
                    "Cleaning, repairing or replacing of essential items is also included.",
                ]}
                questionKeys={[
                    "job_expense_q1",
                    "job_expense_q2",
                    "job_expense_q3",
                    "job_expense_q4",
                ]}
                answerKeys={[
                    "job_expense_a1",
                    "job_expense_a2",
                    "job_expense_a3",
                    "job_expense_a4",
                ]}
            />
            <Expense
                {...containerProps}
                title="Vehicular Expenses:"
                listTitle="How much you can claim depends on whether or not you're using:"
                listitems={[
                    " A vehicle that you've bought or leased with your own money.",
                    "A vehicle owned or leased by your employer (i.e a company vehicle).",
                ]}
                questionKeys={[
                    "vehicle_q1",
                    "vehicle_q2",
                    "vehicle_q3",
                    "vehicle_q4",
                ]}
                answerKeys={[
                    "vehicle_a1",
                    "vehicle_a2",
                    "vehicle_a3",
                    "vehicle_a4",
                ]}
            />
            <Expense
                {...containerProps}
                title="Travel & Overnight Expenses:"
                listTitle="Claim expenses for personal finance spent on the likes of:"
                listitems={[
                    "Public transport costs",
                    "Overnight hotel accommodation",
                    "Food and drink expenses",
                    "Congestion charges and tolls",
                    "Parking fees",
                    "Business phone calls and printing costs",
                ]}
                questionKeys={[
                    "travel_q1",
                    "travel_q2",
                    "travel_q3",
                    "travel_q4",
                ]}
                answerKeys={[
                    "travel_a1",
                    "travel_a2",
                    "travel_a3",
                    "travel_a4",
                ]}
            />
            <Expense
                {...containerProps}
                title="Professional Fees:"
                listitems={[
                    "This can include fees or subscriptions you have paid for yourself.",
                ]}
                questionKeys={[
                    "professional_q1",
                    "professional_q2",
                    "professional_q3",
                    "professional_q4",
                ]}
                answerKeys={[
                    "professional_a1",
                    "professional_a2",
                    "professional_a3",
                    "professional_a4",
                ]}
            />
            <Expense
                {...containerProps}
                title="Work From Home:"
                listitems={[
                    "You may be able to claim tax relief for some of your bills you have to pay because you have to work at home on a regular basis.",
                    "You cannot claim tax relief if you have chosen to work from home.",
                    "You can only claim for work-related expenses, like business telephone calls or the extra cost of gas and electricity for your work area.",
                ]}
                questionKeys={["wfh_q1", "wfh_q2", "wfh_q3", "wfh_q4"]}
                answerKeys={["wfh_a1", "wfh_a2", "wfh_a3", "wfh_a4"]}
            />
            <Expense
                {...containerProps}
                title="Buying Other Equipment:"
                listitems={[
                    "In most cases, you can claim tax relief on the full cost of substanial equipment",
                    "For example, a computer you have to buy to do your work: this is because it qulifies for a type of capital allowance called Annual Investment Allowance(AIA).",
                    "You claim in a different way for small items that'll last less than 2 years, such as work uniforms or tools.",
                ]}
                questionKeys={[
                    "equipment_q1",
                    "equipment_q2",
                    "equipment_q3",
                    "equipment_q4",
                ]}
                answerKeys={[
                    "equipment_a1",
                    "equipment_a2",
                    "equipment_a3",
                    "equipment_a4",
                ]}
            />
        </>
    );
};

export default Expenses;
