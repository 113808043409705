import React, { useState } from "react";
import { TableRowProps } from "..";
import { Modal } from "../../..";
import EditIncomeExpenditure from "../../../Modals/Settings/IncomeExpenditure/EditIncomeExpenditure";
import { ReactComponent as TickIcon } from "../../../../assets/icons/greenTick.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/redCross.svg";
import { IconBlock } from "../styles";

const IncomeExpenditureRow: React.FC<TableRowProps> = ({ data }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const activeIcon = (value: any) => {
        if (data?.active === 1) return <TickIcon />;
        if (data?.active === 0) return <CrossIcon />;
        return "-";
    };
    const activeIconPBS = (value: any) => {
        if (data?.budgeting === 1) return <TickIcon />;
        if (data?.budgeting === 0) return <CrossIcon />;
        return <CrossIcon />;
    };
    return (
        <>
            <Modal
                isVisible={isModalOpen}
                children={
                    <EditIncomeExpenditure
                        setIsOpen={setIsModalOpen}
                        isOpen={isModalOpen}
                        data={data || {}}
                    />
                }
            />
            <tr role="button" onClick={() => setIsModalOpen(true)}>
                <td>{data?.nme ?? "-"}</td>
                {/* <td>{(data?.min ?? "-").toLocaleString()}</td>
                <td>{(data?.max ?? "-").toLocaleString()}</td> */}
                {/* <td>{data?.often ?? "-"}</td> */}
                {/* <td>{(data?.amount ?? "-").toLocaleString()}</td> */}
                <IconBlock width="30px">{activeIconPBS(data?.active)}</IconBlock>
                <IconBlock width="30px">{activeIcon(data?.budgeting)}</IconBlock>
            </tr>
        </>
    );
};
export default IncomeExpenditureRow;
