import styled from "styled-components";

export const StyledRequestsRow = styled.tr`
    & .request-row__dropdown.dropdown-menu {
        padding: 4px 8px;

        & .dropdown-menu__item {
            padding: 4px;
        }
    }
`;
