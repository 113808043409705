import { SetStateAction, useState } from "react";
import Button from "../../Button";
import Input from "../../Input";
import { StyledAPISettingsModal } from "./styles";
import ModalHeader from "../components/ModalHeader";
import ModalButtons from "../components/ModalButtons";
import DropdownSelect from "../../DropdownSelect";
import { generateTestOptions } from "../../DropdownSelect/options";
import useFetchData from "../../../global/hooks/useFetchData";
import SuccessContent from "../components/SuccessContent";

interface ApiSettingsProps {
    isOpen: boolean;
    onClose: (...args: any) => void;
    mode?: "create" | "edit";
    data?: { [key: string]: any };
}

const ApiSettingsModal: React.FC<ApiSettingsProps> = ({
    isOpen,
    onClose,
    mode = "create",
    data,
}) => {
    const [description, setDescription] = useState(data?.description || "");
    const [permissionIds, setPermissionIds] = useState<Array<number>>(
        data?.permission_ids || []
    );

    const getAPIKeyPermissions = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_PERMISSION_API}`,
        },
        { fetchOnLoad: true }
    );

    const apiKeyPermissions = getAPIKeyPermissions?.data?.select2 || [];

    const createAPIKey = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/admin-api-keys-create`,
    });

    const updateAPIKey = useFetchData({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_API}/api-key/${data?.id}`,
    });
    const isSubmitSuccess = createAPIKey.isSuccess || updateAPIKey.isSuccess;
    const isFormValid = permissionIds?.length > 0 && !!description;

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const requestData = {
            description,
            permission_Ids: permissionIds,
        };
        if (mode === "create") createAPIKey.fetchData(requestData);
        if (data?.id) updateAPIKey.fetchData(requestData);
    };

    return (
        <StyledAPISettingsModal
            show={isOpen}
            className="api-settings"
            width="460px"
            isSuccess={isSubmitSuccess}
        >
            <ModalHeader onClose={onClose}>
                {mode === "create" ? "Create API Key" : "API Key Settings"}
            </ModalHeader>
            {isSubmitSuccess ? (
                <SuccessContent
                    onClose={onClose}
                    message={
                        mode === "create"
                            ? "New API Key has been created."
                            : "API Key changes has been saved."
                    }
                />
            ) : (
                <form className="api-settings__form" onSubmit={submitHandler}>
                    <Input
                        label="Description"
                        onChange={(e) => setDescription(e.target.value)}
                        type="text"
                        value={description}
                        name="description"
                    />
                    <DropdownSelect
                        label="Permissions:"
                        options={apiKeyPermissions}
                        optionKey="id"
                        labelKeys={["text"]}
                        values={permissionIds}
                        setValues={setPermissionIds}
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText={
                                mode === "create" ? "Create" : "Save Changes"
                            }
                            disabled={!isFormValid}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledAPISettingsModal>
    );
};

export default ApiSettingsModal;
