import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { Outlet } from "react-router-dom";
import NavBar from "../../../layout/Header/NavBar";

const Creditor: React.FC = () => {
    const { creditorId } = useParams();
    const { pathname } = useLocation();
    const isOnClientSetting = !!creditorId && pathname.length >= 2;
    console.log(isOnClientSetting, creditorId);
    return (
        <>
            {!isOnClientSetting && (
                <NavBar subListName="creditor_maintenance" />
            )}
            <Outlet />
        </>
    );
};

export default Creditor;
