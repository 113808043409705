import React, { useState } from "react";
import { theme } from "../../../global/theme";
import { TableActionProps } from "../TableRows";
import { StyledTableButton, StyledTableLink } from "./styles";
import { Modal } from "../..";
import ProcessRefundRequest from "../../Modals/Payments/ProcessRefundRequest";

const RefundCase: React.FC<TableActionProps> = ({
    data,
    onActionSuccess = () => {},
}) => {
    const [isRefunding, setIsRefunding] = useState(false);

    const clickHandler = () => {
        setIsRefunding(true);
    };

    return (
        <>
            <Modal isVisible={isRefunding}>
                <ProcessRefundRequest
                    isOpen={isRefunding}
                    onClose={() => setIsRefunding(false)}
                    data={data}
                    onSuccess={onActionSuccess}
                />
            </Modal>

            <StyledTableLink
                to={`/client-case/${data?.['Case Id']}`}
                target="_blank"
            >
                Open Case
            </StyledTableLink>

            <StyledTableButton
                type="button"
                onClick={clickHandler}
                btnGradient={theme.gradients.burntOrange}
            >
                Process Refund
            </StyledTableButton>
        </>
    );
};

export default RefundCase;
