import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { ClientFormStepProps } from "../ClientForm";
import StyledPayment from "./styles";
import ClientFormStepButtons from "../ClientFormStepButtons";
import useFetchData from "../../../../../global/hooks/useFetchData";
import useReduxCreateClient from "../../../../../store/hooks/useReduxCreateClient";
import PaymentInfo from "./PaymentInfo";
import CardDetails from "./CardDetails";

const Payment: React.FC<ClientFormStepProps> = ({
    setCurrentStep,
    isFirstStep,
    isLastStep,
}) => {
    const { clientId } = useParams();
    const { setDataByKey, payment } = useReduxCreateClient();
    const [paymentInfo, setPaymentInfo] = useState<{ [key: string]: any }>(
        payment?.payment || {}
    );
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get("id");

    const getCasePayments = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/get-case-payments/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setDataByKey("payment", data?.data);
                if (data?.data?.payment) setPaymentInfo(data.data.payment);
            },
        }
    );

    useEffect(() => {
        if (!id) getCasePayments.fetchData();
    }, []);

    const savePayment = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/set-client-payments`,
        },
        {
            onSuccess: (data) => {
                setCurrentStep((prev) => prev + 1);
                setDataByKey("payment", data?.data);
            },
            notifyOnSuccess: true,
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (clientId) {
            const requestData = {
                id: +clientId,
                tab: "payment",
                data: {
                    id: +clientId,
                    ...paymentInfo,
                },
            };
            savePayment.fetchData(requestData);
        }
    };

    return (
        <>
            <StyledPayment className="payment-info">
                <form onSubmit={submitHandler}>
                    <PaymentInfo
                        paymentInfo={paymentInfo}
                        setPaymentInfo={setPaymentInfo}
                    />
                    <ClientFormStepButtons
                        onPrevClick={() => setCurrentStep((prev) => prev - 1)}
                        isFirstStep={isFirstStep}
                        isLastStep={isLastStep}
                        isSubmitting={savePayment.isLoading}
                    />
                </form>

                <CardDetails onAddSuccess={getCasePayments.fetchData} />
            </StyledPayment>
        </>
    );
};

export default Payment;
