import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ClientProductsState {
    personalBudgeting: Array<{[key: string]: any}>;
}

export const initialClientProductsState: ClientProductsState = {
    personalBudgeting: [],
}

export const clientProductsSlice = createSlice({
    name: 'client-products',
    initialState: initialClientProductsState,
    reducers: {
        setPersonalBudgeting: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.personalBudgeting = action.payload
        },
        resetSlice: () => {
            return initialClientProductsState
        },
    }
})

export const clientProductsActions = clientProductsSlice.actions
export default clientProductsSlice