import styled from "styled-components";
import { theme } from "../../global/theme";
import { mainScrollbar } from "../../global/theme/reuseable/mixins";

interface DataTableStyles {
    minWidth?: number | string;
    hasCheckbox?: boolean;
}

const checkboxWidth = 50;

const StyledDataTable = styled.div<DataTableStyles>`
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 12px;

    &[data-loading="true"] {
        opacity: 0.6;
    }

    & .data-table__wrapper {
        position: relative;
        overflow-x: auto;
        width: 100%;
    }

    & .table-checkbox {
        width: ${checkboxWidth}px;
        max-width: ${checkboxWidth}px;
        position: relative;
        z-index: 1;

        & > input {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    & table {
        width: inherit;
        border-collapse: collapse;
        font-size: 11px;
        color: ${theme?.colors.darkText};
        border-radius: inherit;
        min-width: ${(props) => props.minWidth ?? "initial"};
        border: 0.6px solid ${theme?.colors.formBlue};
        border-top: none;
        /* contain: content; */
    }

    & thead {
        position: sticky;
        top: 0;
        z-index: 2;
        transform: translateY(-1px);
        overflow: hidden;
    }

    & th,
    & td {
        &:first-child {
            position: sticky;
            left: -1px;
            z-index: 1;
        }

        &:nth-child(2) {
            position: ${(props) => props.hasCheckbox && "sticky"};
            left: ${(props) =>
                props.hasCheckbox ? `${checkboxWidth - 2}px` : "initial"};
            z-index: ${(props) => (props.hasCheckbox ? 1 : "initial")};
        }
    }

    & th {
        &:first-child {
            z-index: 2;
        }
        &:nth-child(2) {
            z-index: ${(props) => (props.hasCheckbox ? 2 : "initial")};
        }

        &.th-empty {
            height: 38px;
        }
    }

    & th {
        background-color: ${theme?.colors.formBlue};
        padding: 10px;
        font-weight: 500;
        line-height: 130%;
        text-align: left;

        & > .th-ctn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4px;
        }

        & th:not(:first-child) {
            border-left: 0.6px solid ${theme?.colors.formBlue};
        }
    }

    & tbody {
        & > tr {
            & > * {
                background-color: ${theme.colors.background};
            }
            &:nth-of-type(2n) {
                background-color: ${theme.colors.containerBackground};

                & > * {
                    background-color: ${theme.colors.containerBackground};
                }
            }
        }

        & td:not(:first-child) {
            border-left: 0.6px solid ${theme?.colors.formBlue};
        }
    }

    & tr {
        &[role="button"] {
            cursor: pointer;

            &:hover > td {
                background-color: ${theme?.colors.formBlue};
            }
        }
    }

    & td {
        padding: 10px;
        font-weight: 300;
        font-size: 11px;
        word-break: break-word;

        &.data-table-row {
            &--full {
                font-size: 17px;
                padding: 20px;
            }
        }

        &.td--action {
            padding: 0px 8px;
            width: 40px;
            & > div {
                height: inherit;
                display: flex;
                gap: 4px;
                padding-block: 4px;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }

    & tfoot {
        & tr {
            border-top: 0.4px solid ${theme.colors.formBlue};
        }
        & td {
            background-color: ${theme.colors.formBlue};
            font-weight: 600;
            &.td--empty,
            &[data-empty="true"] {
                /* background-color: ${theme.colors.formBlue}; */
                opacity: 0;
                visibility: hidden;
            }
        }
    }
`;

export const StyledSortButton = styled.button`
    margin-left: 4px;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    margin-right: -4px;
    height: 18px;
    font-size: 8px;
    line-height: 110%;
    font-weight: 500;
    cursor: pointer;

    &[aria-current="false"] {
        opacity: 0.2;
    }

    & > span {
        &[aria-hidden="true"] {
            display: none;
        }
    }
`;

export const StyledTableError = styled.div``;

export const StyledTablePagination = styled.div`
    align-self: flex-end;
    gap: 16px;
    display: flex;
    align-items: center;
    order: 2;

    & .data-table-pagination {
        &__info {
            font-weight: 300;
            font-size: 14px;
            margin-left: auto;
            text-align: right;
        }

        &__btns {
            display: flex;
            align-items: center;
            gap: 4px;
        }

        &__btn {
            width: 36px;
            height: auto;
            font-size: 14px;
            padding: 4px 8px;
        }

        &__page {
            min-width: 72px;
            text-align: center;
        }
    }
`;

export const StyledActionIconBtn = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 4px;
    height: 24px;
    width: 24px;
    margin-inline: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default StyledDataTable;
