import { useParams } from "react-router-dom";
import BasicModal from "../BasicModal";
import useFetchData from "../../../global/hooks/useFetchData";

interface RemoveServiceModalProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    serviceType: string;
}

const RemoveService: React.FC<RemoveServiceModalProps> = ({
    isOpen,
    onClose,
    serviceType,
}) => {
    const { clientId } = useParams();
    const { fetchData, isSuccess, isLoading } = useFetchData({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_API}/remove-service`,
    });

    const serviceName = () => {
        if (serviceType === "pbs") return "PBS";
        if (serviceType === "personal_accountancy")
            return "Personal Accountancy";
        if (serviceType === "business_accountancy")
            return "Business Accountancy";
        if (serviceType === "tax_rebate") return "Tax Rebate";
        if (serviceType === "settlements") return "Full and Final Settlements";
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({ service_type: serviceType });
    };
    return (
        <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            message={`Are you sure you want to remove ${serviceName()}?`}
            submitBtnColor="red"
            submitBtnText="Delete"
            successMessage={`${serviceName()} has been removed.`}
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSubmit={submitHandler}
        />
    );
};

export default RemoveService;
