import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import { StyledCreatePersonalBudgeting } from "./styles";
import ModalHeader from "../components/ModalHeader";
import SuccessContent from "../components/SuccessContent";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import Input from "../../Input";
import DropdownSelect from "../../DropdownSelect";
import { clientPBSTypeOptions } from "../../DropdownSelect/options";
import useReduxClientProducts from "../../../store/hooks/useReduxClientProducts";

interface CreatePersonalBudgetingProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    data?: { [key: string]: any };
    mode?: "create" | "edit";
}

const CreatePersonalBudgeting: React.FC<CreatePersonalBudgetingProps> = ({
    isOpen,
    onClose,
    data,
    mode = "create",
}) => {
    const { clientId } = useParams();
    const { setPersonalBudgeting } = useReduxClientProducts();
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        type: data?.type || clientPBSTypeOptions[0].value,
        provider: data?.provider || "",
        monthly_spent: data?.monthly_spent ?? "",
        renewal: data?.renewal || "",
    });

    const createPBS = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/pbs/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setPersonalBudgeting(data.data);
            },
        }
    );
    const editPBS = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/pbs/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setPersonalBudgeting(data.data);
            },
        }
    );

    const isSuccess = createPBS.isSuccess || editPBS.isSuccess;
    const isLoading = createPBS.isLoading || editPBS.isLoading;
    const isFormValid = !!formData.type;

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type } = e.target;
        setFormData((prev) => {
            return {
                ...prev,
                [name]: type === "number" ? +value : value,
            };
        });
    };

    const handleSelect = (key: string, value: any) => {
        setFormData((prev) => {
            return {
                ...prev,
                [key]: value,
            };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (mode === "create") {
            createPBS.fetchData(formData);
        }
        if (mode === "edit" && data?.id) {
            editPBS.fetchData({ id: data.id, ...formData });
        }
    };

    return (
        <StyledCreatePersonalBudgeting
            className="create-pbs"
            show={isOpen}
            width="400px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={onClose}>
                {mode === "create" ? "Create" : "Edit"} Budget
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    onClose={onClose}
                    message={`Personal budgeting has been ${
                        mode === "create" ? "created" : "edited"
                    }.`}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <DropdownSelect
                        label="Type:"
                        options={clientPBSTypeOptions}
                        value={formData.type}
                        onSelect={(option) =>
                            handleSelect("type", option.value)
                        }
                        enableInput
                    />
                    <Input
                        type="text"
                        label="Provider:"
                        value={formData?.provider || ""}
                        name="provider"
                        onChange={handleChange}
                    />
                    <Input
                        type="number"
                        label="Monthly Spend:"
                        value={formData?.monthly_spent || ""}
                        name="monthly_spent"
                        onChange={handleChange}
                        placeholder="Please enter amount..."
                        min={0}
                        step={1}
                    />
                    <Input
                        type="date"
                        label="Renewal Date:"
                        value={formData?.renewal || ""}
                        name="renewal"
                        onChange={handleChange}
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText={`${
                                mode === "create" ? "Create" : "Save Changes"
                            }`}
                            disabled={!isFormValid}
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledCreatePersonalBudgeting>
    );
};

export default CreatePersonalBudgeting;
