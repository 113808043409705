import { PayeRebateContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import DropdownSelect from "../../../../components/DropdownSelect";
import useFetchData from "../../../../global/hooks/useFetchData";

const SalesAdvisor: React.FC<PayeRebateContainerProps> = ({
    onSelect,
    payeRebate,
}) => {
    const getAdvisorList = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/users`,
        },
        {
            fetchOnLoad: true,
        }
    );
    const advisorList = getAdvisorList?.data?.data?.users || [];

    return (
        <HeaderContainer title="Sales Advisor:" headerStyle="gradientBlue">
            <DropdownSelect
                label="Sales Advisor:"
                value={payeRebate?.["rebate-sales"] || null}
                options={advisorList}
                optionKey="id"
                labelKeys={["name"]}
                onSelect={(option) => onSelect("rebate-sales", option.id)}
                enableInput
                isLoading={advisorList.isLoading}
            />
        </HeaderContainer>
    );
};

export default SalesAdvisor;
