import { Button } from "../../../components/index";
import { StyledDashboardEdit } from "./styles";
import DropdownSelect from "../../../components/DropdownSelect";
import { generateTestOptions } from "../../../components/DropdownSelect/options";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxDashboard from "../../../store/hooks/useReduxDashboard";
import { SetStateAction, useEffect, useState } from "react";
import EditDropdown from "./EditDropdown";
import { testReorderData } from "./data";
import { ReactComponent as CloseIcon } from "../../../assets/icons/greyLeftArrow.svg";

interface DashboardEditProps {
    setIsEditing: React.Dispatch<SetStateAction<boolean>>;
    isEditing: boolean;
}

interface OrderData {
    summary: Array<{ [key: string]: any }>;
    activeUsers: Array<{ [key: string]: any }>;
    outstandingCorres: Array<{ [key: string]: any }>;
}

const DashboardEdit: React.FC<DashboardEditProps> = ({
    setIsEditing,
    isEditing,
}) => {
    const { setLeftColumnData, setRightColumnData } = useReduxDashboard();
    const initOrderData = {
        summary: testReorderData ?? [],
        activeUsers: testReorderData ?? [],
        outstandingCorres: testReorderData ?? [],
    };
    const [assignToId, setAssignToId] = useState<number | null>(null);
    const [orderData, setOrderData] = useState<OrderData>(initOrderData);
    const saveDashboard = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/dashboard-edit`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Dashboard saved successfully",
            onSuccess: (data) => {
                setIsEditing(false);
            },
        }
    );

    const getDashboard = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/dashboard-edit`,
        },
        {
            onSuccess: (data) => {
                setOrderData({
                    summary: data?.data?.["Summary Information"] || [],
                    activeUsers: data?.data?.["Active Users"] || [],
                    outstandingCorres:
                        data?.data?.["Outstanding Correspondence"] || [],
                });
                //setIsEditing(false);
            },
        }
    );

    const test = {
        "Active Users": {
            reordered_ids: [],
            hide_ids: [],
        },
    };

    useEffect(() => {
        if (isEditing) getDashboard.fetchData();
    }, [isEditing]);

    const formatUpdateData = (dataList: Array<{ [key: string]: any }>) => {
        const reorderIds = dataList.map((data) => data?.id);
        const hideIds = dataList
            .filter((data) => !!data?.hide)
            .map((data) => data?.id);
        return {
            reorder_ids: reorderIds,
            hide_ids: hideIds,
        };
    };

    const saveDashboardHandler = () => {
        const sectionsData = {
            "Summary Information": formatUpdateData(orderData.summary),
            "Active Users": formatUpdateData(orderData.activeUsers),
            "Outstanding Correspondence": formatUpdateData(
                orderData.outstandingCorres
            ),
        };
        const requestData = {
            assign_to_id: assignToId,
            sections: sectionsData,
        };
        saveDashboard.fetchData(requestData);
    };

    const draggedHandler = (
        newList: Array<{ [key: string]: any }>,
        key: keyof typeof orderData
    ) => {
        setOrderData((prev) => {
            return { ...prev, [key]: newList };
        });
    };

    const toggleItemHandler = (
        data: { [key: string]: any },
        key: keyof typeof orderData
    ) => {
        setOrderData((prev) => {
            return {
                ...prev,
                [key]: prev[key].map((item) =>
                    item.id === data?.id
                        ? { ...item, hide: data.hide ? 0 : 1 }
                        : item
                ),
            };
        });
    };

    const toggleAllHandler = (key: keyof typeof orderData) => {
        setOrderData((prev) => {
            return {
                ...prev,
                [key]: prev[key].map((item) => {
                    return { ...item, hide: 0 };
                }),
            };
        });
    };

    return (
        <StyledDashboardEdit
            className="dashboard-edit"
            aria-expanded={isEditing}
        >
            <Button
                className="dashboard-edit__close"
                style="cancel"
                type="button"
                onClick={() => setIsEditing(false)}
                loading={saveDashboard.isLoading}
            >
                <CloseIcon />
            </Button>
            <header className="dashboard-edit__header">
                <h2 className="dashboard-edit__title">Edit Dashboard</h2>
                <Button
                    className="dashboard-edit__save"
                    style="purple"
                    type="button"
                    btnText="Save"
                    onClick={saveDashboardHandler}
                    loading={saveDashboard.isLoading}
                />
            </header>
            <DropdownSelect
                className="dashboard-edit__dropdown"
                label="Assign Dashboard To:"
                options={generateTestOptions(10)}
                value={assignToId}
                onSelect={(option) => setAssignToId(option.value)}
            />
            <div className="dashboard-edit__sections">
                <h3>Sections</h3>
                <EditDropdown
                    data={orderData.summary}
                    name="Summary Information"
                    onDragged={(data) => draggedHandler(data, "summary")}
                    onToggleItem={(data) => toggleItemHandler(data, "summary")}
                    onToggleAll={() => toggleAllHandler("summary")}
                />
                <EditDropdown
                    data={orderData.activeUsers}
                    name="Active Users"
                    onDragged={(data) => draggedHandler(data, "activeUsers")}
                    onToggleItem={(data) =>
                        toggleItemHandler(data, "activeUsers")
                    }
                    onToggleAll={() => toggleAllHandler("activeUsers")}
                />
                <EditDropdown
                    data={orderData.outstandingCorres}
                    name="Outstanding Correspondence"
                    onDragged={(data) =>
                        draggedHandler(data, "outstandingCorres")
                    }
                    onToggleItem={(data) =>
                        toggleItemHandler(data, "outstandingCorres")
                    }
                    onToggleAll={() => toggleAllHandler("outstandingCorres")}
                />
            </div>
        </StyledDashboardEdit>
    );
};

export default DashboardEdit;
