import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "../../../global/theme";

interface StyledTableActionProps {
    btncolor?: string;
    btnGradient?: string;
}

const tableActionMixins = css<StyledTableActionProps>`
    background-image: ${(props) => props?.btnGradient || "none"};
    background-color: ${(props) =>
        props?.btncolor || props?.btnGradient
            ? "transparent"
            : theme?.colors?.purple};
    color: ${theme?.colors?.white};
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 300;
    width: max-content;
    border: none;
`;

export const StyledTableLink = styled(Link)<StyledTableActionProps>`
    ${tableActionMixins}
`;

export const StyledTableButton = styled.button<StyledTableActionProps>`
    ${tableActionMixins}
`;
