import { PayeRebateContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import { formatDateString } from "../../../../global/utils/helpers";

const Payout: React.FC<PayeRebateContainerProps> = ({
    onInputChange,
    payeRebate,
}) => {

    return (
        <HeaderContainer
            title="Payout:"
            headerStyle="gradientBlue"
            ctnClassName="col-2"
        >
            <Input
                type="number"
                label="Amount:"
                value={`${payeRebate?.["fnds-payout-amt"] || ""}`}
                name="fnds-payout-amt"
                onChange={onInputChange}
            />
            <Input
                type="date"
                label="Date Sent:"
                value={formatDateString(payeRebate?.["fnds-payout-whn"] || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                name="fnds-payout-whn"
                onChange={onInputChange}
            />
        </HeaderContainer>
    );
};

export default Payout;
