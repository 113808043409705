import React from "react";
import { useAppSelector, useAppDispatch } from ".";
import { iSubNav } from "../../layout/SideNavs/types";
import { uiActions } from "../slices/uiSlice";
import { UIState } from "../slices/uiSlice";

const useReduxUI = () => {
    const { sideMenu, sideNavData, notifications } = useAppSelector(
        (state) => state.ui
    );
    const dispatch = useAppDispatch();
    const notificationData = notifications?.notification || {};
    const portalData = notificationData?.client || [];
    const emailData = notificationData?.email || [];
    const smsData = notificationData?.sms || [];
    const alertData = notificationData?.tasks?.data || [];
    const allData = [...portalData, ...emailData, ...smsData, ...alertData];
    const tabIndexData = [
        allData,
        portalData,
        emailData,
        smsData,
        [],
        alertData,
    ];

    const currentNotiData = (index: number = 0) =>
        tabIndexData[index] as {
            [key: string]: any;
        }[];

    const setSideMenu = (menuName: string) => {
        dispatch(uiActions.setSideMenu(menuName));
    };

    const setSideNavData = (data: { [key: string]: any } | null) => {
        dispatch(uiActions.setSideNavData(data));
    };

    const setNotifications = (data: { [key: string]: any } | null) => {
        dispatch(uiActions.setNotifications(data));
    };

    const getSubNavList = (key: string): iSubNav[] => {
        if (!sideNavData?.[key]?.sublist) return [];
        return Object.values(sideNavData[key].sublist) || [];
    };

    return {
        sideMenu,
        setSideMenu,
        sideNavData,
        setSideNavData,
        notifications,
        setNotifications,
        getSubNavList,
        currentNotificationData: currentNotiData,
    };
};

export default useReduxUI;
