import styled from "styled-components";
import { PageContent } from "../../../../pages/styles";
import { StyledBaseDetailsSubmitCtn } from "../../BaseDetails/styles";
import { theme } from "../../../../global/theme";

export const StyledSubmitBtn = styled(StyledBaseDetailsSubmitCtn)``;

const StyledPayeRebateContent = styled(PageContent)`
    & .submit-ctn {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;
    }
    & > form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    & .header-container {
        width: min(100%, 1120px);
    }

    & .paye-rebate-expense {
        & ul {
            grid-column: 1/-1;
            list-style: inside;
            font-weight: 300;
            font-size: 16px;

            & > p {
                margin-bottom: 4px;
            }

            & > li {
                font-size: 12px;
            }
        }
    }

    & .dropdown-menu {
        padding: 9.5px 16px;
        font-size: 14px;
        border-radius: 4px;

        &__menu {
            left: initial;
            right: 0;
        }
    }
`;

export default StyledPayeRebateContent;
