import { SetStateAction, useId, useState, useEffect } from "react";
import DropdownSelect from "../../../../../components/DropdownSelect";
import {
    departmentOptions,
    generateTestOptions,
} from "../../../../../components/DropdownSelect/options";
import TextEditor from "../../../../../components/TextEditor";
import { Button, Input } from "../../../../../components";
import MessageTemplateHeaderContainer from "./styles";
import useFetchData from "../../../../../global/hooks/useFetchData";
import BodyVariablesSelect from "../../../../../components/DropdownSelect/BodyVariablesSelect";
import useReduxSettings from "../../../../../store/hooks/useReduxSettings";

interface EmailFormContainerProps {
    templateData: { [key: string]: any } | null;
    client: { [key: string]: any } | null;
    onClear: (...args: any[]) => void;
}

const EmailFormContainer: React.FC<EmailFormContainerProps> = ({
    templateData,
    client,
    onClear,
}) => {
    const checkboxId = useId();
    const { setTemplate } = useReduxSettings();
    const [isSelectAllClients, setIsSelectAllClients] = useState(true);
    const [clientIds, setClientIds] = useState<Array<number>>([]);
    const [newName, setNewName] = useState("");
    const [department, setDepartment] = useState(departmentOptions[0].value);
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const isSendValid = isSelectAllClients || clientIds?.length;
    const isSaveAsNewValid = !!department && newName.trim() !== "";

    const createTemplate = useFetchData(
        {
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_API}/settings-template-email-create`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTemplate("email", data?.data);
            },
        }
    );

    const sendTemplate = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/send-email-custom`,
    });

    useEffect(() => {
        if (templateData) {
            setMessage(templateData?.body || "");
            setDepartment(templateData?.department || "");
            setSubject(templateData?.subject || "");
            setNewName(`${templateData?.title} Copy` || "");
        }
    }, [templateData]);

    const resetHandler = () => {
        setClientIds([]);
        setIsSelectAllClients(true);
        setMessage("");
        setNewName("");
        setSubject("");
        setDepartment(departmentOptions[0].value);
        onClear();
    };

    const saveAsNewHandler = () => {
        const requestData = {
            name: newName,
            department,
            subject,
            body: message,
        };
        createTemplate.fetchData({ data: requestData });
    };

    const sendMessageHandler = () => {
        const requestData = {
            to_all_clients: isSelectAllClients,
            client_ids: clientIds,
            subject: subject,
            message: message,
        };
        console.log(requestData);
        sendTemplate.fetchData({ data: requestData });
    };

    return (
        <MessageTemplateHeaderContainer
            title="Send Email"
            className="message-form-container"
            headerStyle="lightBlue"
        >
            <div className="message-form-container__select">
                <fieldset className="message-form-container__fieldset">
                    <p>Send to:</p>
                    <div className="message-form-container__checkbox">
                        <input
                            id={`all-${checkboxId}`}
                            type="checkbox"
                            checked={isSelectAllClients}
                            onChange={() => setIsSelectAllClients(true)}
                        ></input>
                        <label htmlFor={`all-${checkboxId}`}>All Clients</label>
                    </div>
                    <div className="message-form-container__checkbox">
                        <input
                            id={`select-${checkboxId}`}
                            type="checkbox"
                            checked={!isSelectAllClients}
                            onChange={() => setIsSelectAllClients(false)}
                        ></input>
                        <label htmlFor={`select-${checkboxId}`}>
                            Select Clients
                        </label>
                    </div>
                </fieldset>
                <DropdownSelect
                    options={client?.client || []}
                    optionKey="id"
                    labelKeys={["Customer"]}
                    values={clientIds}
                    setValues={setClientIds}
                    disabled={isSelectAllClients}
                    enableInput
                />
                <div className="message-form-container__new">
                    <DropdownSelect
                        options={departmentOptions}
                        value={department}
                        onSelect={(option) => setDepartment(option.value)}
                        label="Department:"
                    />
                    <Input
                        type="text"
                        label="New Name:"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        placeholder="Type new name..."
                    />
                    <BodyVariablesSelect />
                </div>
            </div>
            <Input
                className="message-form-container__subject"
                label="Subject:"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
            />
            <TextEditor
                value={message}
                onChange={setMessage}
                label="Message:"
            />
            <div className="message-form-container__btns">
                <Button
                    type="button"
                    style="cancel"
                    btnText="Clear All"
                    onClick={resetHandler}
                />
                <Button
                    type="button"
                    style="purple"
                    btnText="Save As New Template"
                    disabled={!isSaveAsNewValid}
                    onClick={saveAsNewHandler}
                    loading={createTemplate.isLoading}
                />
                <Button
                    type="button"
                    style="mint"
                    btnText="Send"
                    onClick={sendMessageHandler}
                    disabled={!isSendValid}
                    loading={sendTemplate.isLoading}
                />
            </div>
        </MessageTemplateHeaderContainer>
    );
};

export default EmailFormContainer;
