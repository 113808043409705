import React, { SetStateAction } from "react";
import Input from "../../../Input";

interface LiabilityKeyEventsProps {
    keyEvents: { [key: string]: any };
    setKeyEvents: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
}

const LiabilityKeyEvents: React.FC<LiabilityKeyEventsProps> = ({
    keyEvents,
    setKeyEvents,
}) => {
    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setKeyEvents((prev) => {
            return { ...prev, [name]: value };
        });
    };

    // console.log(keyEvents);
    return (
        <div className="liability-main__content liability-key-events">
            <Input
                type="date"
                label="Balance Requested:"
                value={keyEvents?.balance_requested || ""}
                name="balance_requested"
                onChange={inputHandler}
            />
            <Input
                type="date"
                label="Payment Proposed:"
                value={keyEvents?.payment_proposed || ""}
                name="payment_proposed"
                onChange={inputHandler}
            />
            <Input
                type="date"
                label="Hold Payments:"
                value={keyEvents?.hold_payments || ""}
                name="hold_payments"
                onChange={inputHandler}
            />
            <Input
                type="date"
                label="Full & Final Proposal:"
                value={keyEvents?.full_final_proposal || ""}
                name="full_final_proposal"
                onChange={inputHandler}
            />
            <Input
                type="date"
                label="Inactive:"
                value={keyEvents?.inactive || ""}
                name="inactive"
                onChange={inputHandler}
            />
            <Input
                type="date"
                label="Review:"
                value={keyEvents?.review || ""}
                name="review"
                onChange={inputHandler}
            />
            <Input
                type="date"
                label="Payment Accepted:"
                value={keyEvents?.payment_accepted || ""}
                name="payment_accepted"
                onChange={inputHandler}
            />
            <hr />
            <Input
                type="date"
                label="Initial Letter:"
                value={keyEvents?.initial_letter || ""}
                name="initial_letter"
                onChange={inputHandler}
                readOnly
            />
            <Input
                type="date"
                label="CCR Chase 1:"
                value={keyEvents?.ccr_chase_1 || ""}
                name="ccr_chase_1"
                onChange={inputHandler}
                readOnly
            />
            <Input
                type="date"
                label="CCR Chase 2:"
                value={keyEvents?.ccr_chase_2 || ""}
                name="ccr_chase_2"
                onChange={inputHandler}
                readOnly
            />
            <Input
                type="date"
                label="IVA Date:"
                value={keyEvents?.iva || ""}
                name="iva"
                onChange={inputHandler}
                readOnly
            />
        </div>
    );
};

export default LiabilityKeyEvents;
