import styled from "styled-components";
import { ResizableModalWrapper } from "../styles";

const StyledCreateComplaint = styled(ResizableModalWrapper)`
    & h2 {
        text-transform: capitalize;
    }

    & > form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        & > ul {
            list-style: none;

            & li {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-weight: 300;

                & > span {
                    font-weight: 400;
                }
            }
        }
    }
`;
export default StyledCreateComplaint;
