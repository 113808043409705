import styled from "styled-components";
import { theme } from "../../global/theme";

const StyledFileUpload = styled.div`
    height: 40px;
    position: relative;
    &[role="preview-upload"] {
        width: 400px;
        height: 240px;
        margin-inline: auto;

        & .file-upload {
            &__upload {
                width: inherit;
                height: inherit;
                position: relative;
                padding: 0;
                &__label {
                    background-image: none;
                    background-color: ${theme.colors.cancel};
                    color: ${theme.colors.text};
                    padding: 12px;
                    border: 1px dashed ${theme.colors.primaryBlue};
                    font-size: 16px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            &__types {
                top: 12px;
                transform: translate(-50%, -50%);
            }
        }
    }

    & .file-upload {
        &__upload {
            height: inherit;
            border: none;
            background-color: transparent;

            &__input {
                display: none;
            }

            &__label {
                background-image: ${theme?.gradients.mintGreen};
                color: ${theme?.colors.white};
                padding: 0 24px;
                height: 100%;
                font-size: 14px;
                line-height: 100%;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }

        &__types {
            display: inline-block;
            position: absolute;
            font-size: 11px;
            top: -18px;
            left: 50%;
            font-weight: 300;
            white-space: nowrap;
            transform: translate(-50%, 0);
        }
    }
`;

export default StyledFileUpload;
