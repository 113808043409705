import React from "react";
import { useState } from "react";
import ModalHeader from "./components/ModalHeader";
import ModalButtons from "./components/ModalButtons";
import Button from "../Button";
import SuccessContent from "./components/SuccessContent";
import FileUpload, { FileData } from "../FileUpload";
import { StyledUploadModal } from "./styles";
import { ReactComponent as RemoveIcon } from "../../assets/icons/deleteBin.svg";

export interface UploadModalProps {
    className?: string;
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    onSubmit: (fileData: FileData) => void;
    fileURL?: string;
    modalTitle?: string;
    isSuccess?: boolean;
    successMessage?: string;
    isLoading?: boolean;
    accept?: string;
    submitBtnText?: string;
    showRequirements?: boolean;
}

const UploadModal: React.FC<UploadModalProps> = ({
    className = "",
    isOpen,
    onClose,
    onSubmit,
    fileURL,
    modalTitle = "Upload File",
    isLoading,
    isSuccess,
    successMessage = "File has been uploaded.",
    accept = "*",
    submitBtnText = "Submit",
    showRequirements = true,
}) => {
    const initialFileData = {
        file: null,
        name: "",
        url: fileURL ?? "",
    };
    const [fileData, setFileData] = useState<FileData>(initialFileData);
    function isRenderableInCSSBackground(file: string): boolean {
        const supportedExtensions = [
            ".png",
            ".jpg",
            ".jpeg",
            ".gif",
            ".webp",
            ".svg",
            ".bmp",
        ];
        const fileExtension = file.split(".").pop()?.toLowerCase() || "";
        return supportedExtensions.includes(`.${fileExtension}`);
    }

    // Te

    const uploadHandler = (file: FileData) => {
        setFileData(file);
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(fileData);
    };

    return (
        <StyledUploadModal
            show={isOpen}
            className={`upload-modal${className ? ` ${className}` : ""}`}
        >
            <ModalHeader onClose={onClose}>{modalTitle}</ModalHeader>
            {isSuccess ? (
                <SuccessContent message={successMessage} onClose={onClose} />
            ) : (
                <form onSubmit={submitHandler} className="upload-modal__form">
                    <FileUpload
                        accept={accept}
                        onUploaded={uploadHandler}
                        isLabelPreview
                        labelStyle={{
                            backgroundImage: `url(${fileData.url || ""})`,
                            border: !!fileData.url ? "none" : "",
                        }}
                        showRequirement={showRequirements && !fileData.file}
                    >
                        {!!fileData.file ? (
                            <>
                                <p className="re-upload">Re-Upload</p>
                                {!isRenderableInCSSBackground(
                                    fileData?.name || ""
                                ) && "Preview Unavaliabile"}
                            </>
                        ) : (
                            "Upload File"
                        )}
                    </FileUpload>
                    {fileData.file && (
                        <div className="upload-modal__filename">
                            <strong>File:</strong>
                            <p>{fileData.name}</p>
                            <Button
                                style="red"
                                type="button"
                                onClick={() => setFileData(initialFileData)}
                            >
                                <RemoveIcon />
                            </Button>
                        </div>
                    )}
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText={submitBtnText}
                            loading={isLoading}
                            disabled={!fileData.file}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledUploadModal>
    );
};

export default UploadModal;
