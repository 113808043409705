import styled, { css } from "styled-components";
import { ResizableModalWrapper, innerModalMixin } from "../styles";
import { centeredRowMixin } from "../../../global/theme/reuseable/mixins";
import { theme } from "../../../global/theme";

export const StyledIntroducerCreate = styled(ResizableModalWrapper)`
    & .col-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px 16px;
    }

    & .text-area,
    & .input-email,
    & hr {
        grid-column: 1/-1;
    }

    & hr {
        border: none;
        border-top: 0.6px solid ${theme.colors.shade};
        margin-block: 8px;
    }

    & .modal-btns {
        /* margin-top: 2px; */
    }
`;

// export const CreateIntroducerModalWrapper = styled.div<iSCStepModal>`
//     ${innerModalMixin}
//     position: fixed;
//     left: 50%;
//     transform: translate(-50%, 0);
//     width: calc(100% - 40px);
//     max-width: 875px;
//     max-height: 85vh;
//     gap: 24px;
//     padding: 24px;
//     animation: ${({ show }) =>
//         show
//             ? css`
//                   ${enterRight} linear 0.3s forwards
//               `
//             : css`
//                   ${leaveRight} linear 0.3s forwards
//               `};

//     .heading {
//         display: flex;
//         padding-bottom: 17px;
//         border-bottom: 1px solid #5e5e5e;
//         margin-bottom: 20px;

//         img:last-child {
//             margin-left: auto;
//             cursor: pointer;
//         }
//     }

//     & .create-introducer {
//         &__content {
//             min-width: 600px;
//             overflow: auto;
//         }
//     }

//     .introducerInput,
//     .introducerSelect {
//         flex-grow: 1;
//     }
//     .introducerInput {
//         gap: 4px;
//         input {
//             width: 100%;
//         }
//     }
//     .introducerSelect {
//         width: 200px;

//         div {
//             width: 100%;
//         }
//         p {
//             height: 40px;
//         }
//     }

//     .introducerSelectContainer {
//         label {
//             margin-bottom: 13px;
//         }
//     }

//     .navigation {
//         margin-top: auto;
//         display: flex;
//         justify-content: flex-end;
//         gap: 15px;
//         padding-top: 15px;
//         margin-top: 45px;
//         border-top: 1px solid rgba(94, 94, 94, 0.2);

//         button {
//             width: clamp(108px, 120px, 120px);
//         }
//     }
// `;

export const ConfirmationSection = styled.div`
    .heading {
        background: #e5efff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 15px 10px;
        border: none;

        h6 {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #5e5e5e;
        }
    }

    .details {
        margin-block: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        label {
            display: flex;
            margin-bottom: 19px;
        }

        label,
        p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #5e5e5e;
        }
    }
`;

export const NavWrapper = styled.nav`
    max-width: 520px;
    margin: 0 auto;

    ul {
        ${centeredRowMixin};
        gap: 19.5px;
    }
`;

export const StepFourCtn = styled.div`
    overflow-y: auto;
`;
