import { SetStateAction, useState } from "react";
import { Input, Modal } from "../..";
import { StyledCreditorSettingsModal } from "./styles";
import ModalHeader from "../components/ModalHeader";
import ModalButtons from "../components/ModalButtons";
import { Button } from "../..";
import React from "react";
import useFetchData from "../../../global/hooks/useFetchData";
import SuccessContent from "../components/SuccessContent";
import { useNavigate } from "react-router-dom";

interface DeleteCreditorProps {
    isOpen: boolean;
    creditorId: any;
    setCurrentModal: React.Dispatch<SetStateAction<string>>;
}

const DeleteCreditor: React.FC<DeleteCreditorProps> = ({
    isOpen,
    creditorId,
    setCurrentModal,
}) => {
    const navigate = useNavigate();
    const [confirmText, setConfirmText] = useState("");
    const { fetchData, isLoading, isSuccess } = useFetchData({
        method: "DELETE",
        url: `${process.env.REACT_APP_SERVER_API}/creditor/${creditorId}`,
    });
    const isConfirmed = confirmText.toLowerCase().trim() === "delete";

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData(creditorId);
    };

    const closeHandler = () => {
        setCurrentModal("");
        if (isSuccess) navigate("/creditor/creditors");
    };
    return (
        <Modal isVisible={isOpen}>
            <StyledCreditorSettingsModal
                show={isOpen}
                className="delete-creditor"
            >
                <ModalHeader onClose={closeHandler}>
                    Delete Creditor
                </ModalHeader>
                {isSuccess ? (
                    <SuccessContent
                        message="Creditor successfully deleted."
                        onClose={closeHandler}
                    />
                ) : (
                    <form
                        className="delete-creditor__form"
                        onSubmit={submitHandler}
                    >
                        <p>Are you sure you want to delete this creditor?</p>
                        <Input
                            type="text"
                            label="Type 'delete' to confirm:"
                            value={confirmText}
                            onChange={(e) => setConfirmText(e.target.value)}
                        ></Input>
                        <ModalButtons>
                            <Button
                                style="red"
                                btnText="Delete"
                                type="submit"
                                loading={isLoading}
                                disabled={!isConfirmed}
                            />
                        </ModalButtons>
                    </form>
                )}
            </StyledCreditorSettingsModal>
        </Modal>
    );
};

export default DeleteCreditor;
