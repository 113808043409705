import { SetStateAction } from "react";
import useFetchData from "../../global/hooks/useFetchData";
import useReduxReports from "../../store/hooks/useReduxReports";
import BasicModal from "./BasicModal";

interface DeleteReportModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
    reportId: number;
    reportName: string;
}

const DeleteReportModal: React.FC<DeleteReportModalProps> = ({
    isOpen,
    setIsOpen,
    reportId,
    reportName = "",
}) => {
    const { setList } = useReduxReports();
    const deleteReport = useFetchData(
        {
            method: "DELETE",
            url: `${process.env.REACT_APP_SERVER_API}/reports/${reportId}`,
        },
        {
            onSuccess: (data) => {
                setList(data?.data);
            },
            notifyOnSuccess: true,
        }
    );
    const handleDelete = (e: React.FormEvent) => {
        e.preventDefault();
        deleteReport.fetchData();
    };

    return (
        <BasicModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            message={`Do you want to delete "${reportName}"?`}
            submitBtnText="Delete"
            successMessage="Report has been deleted"
            onSubmit={handleDelete}
            isLoading={deleteReport.isLoading}
            isSuccess={deleteReport.isSuccess}
            submitBtnColor="red"
        />
    );
};

export default DeleteReportModal;
