import styled from "styled-components";
import { theme } from "../../../global/theme";

export const StyledDayTimeSelect = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    & .day-time-select {
        &__checkbox {
            display: flex;
            align-items: center;
            gap: 12px;

            &__input {
                border: 0.6px solid ${theme?.colors.shade};
                outline: none;
                border-radius: 4px;

                &:checked {
                    border-color: ${theme?.colors.boxStroke};
                }
            }

            &__label {
                text-transform: capitalize;
                font-size: 14px;
            }
        }

        &__time {
            display: flex;
            align-items: center;
            gap: 12px;
            &__label {
                font-weight: 300;
                font-size: 12px;
            }

            &__input {
                padding-block: 4px;
                padding-left: 8px;
                padding-right: 4px;
                border-radius: 4px;
                border: 0.6px solid ${theme?.colors.shade};
                outline: none;
                font-size: 12px;
                width: 80px;

                &:focus {
                    border-color: ${theme?.colors.boxStroke};
                }

                &:disabled {
                    color: ${theme?.colors.shade};
                }
            }
        }
    }
`;
