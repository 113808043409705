import React, { useState } from "react";
import { Button } from "../../../../../components";
import ClientFormStepButtons from "../ClientFormStepButtons";
import DataTable from "../../../../../components/DataTable";
import { StyledCreditorInfo } from "./styles";
import { LinearSpan } from "../../../../../global/theme/reuseable/components";
import { ClientFormStepProps } from "../ClientForm";
import useReduxCreateClient from "../../../../../store/hooks/useReduxCreateClient";
import CreditorInfoRow from "../../../../../components/DataTable/TableRows/createClient/CreditorInfoRow";
import { Modal } from "../../../../../components";
import CreateCreditorInfo from "../../../../../components/Modals/ClientCreate/CreateCreditorInfo";

const CreditorInfo: React.FC<ClientFormStepProps> = ({
    setCurrentStep,
    isFirstStep,
    isLastStep,
    currentStep,
}) => {
    const [createDebt, setCreateDebt] = useState<boolean>(false);
    const { creditor_info } = useReduxCreateClient();
    const totalDebt =
        creditor_info
            ?.map((data) => data?.estmtdBlnce || 0)
            ?.reduce((a: number, c: number) => a + c, 0) || 0;

    const nextStepHandler = () => {
        setCurrentStep((prev) => prev + 1);
    };

    return (
        <>
            <Modal isVisible={createDebt}>
                <CreateCreditorInfo
                    isOpen={createDebt}
                    setIsOpen={setCreateDebt}
                    mode="create"
                />
            </Modal>
            <StyledCreditorInfo className="creditor-info">
                <div className="creditor-info__row">
                    <h3>
                        Total Debt:{" "}
                        <LinearSpan color="red">£{totalDebt}</LinearSpan>
                    </h3>
                    <Button
                        btnText="Create"
                        onClick={() => setCreateDebt(!createDebt)}
                        style="white"
                    />
                </div>
                <DataTable
                    labels={["Creditor Name", "Reference", "Balance"]}
                    keys={["name", "accntRfrnce", "estmtdBlnce"]}
                    dataKey="id"
                    data={creditor_info || []}
                    tableRow={CreditorInfoRow}
                />
                <ClientFormStepButtons
                    onPrevClick={() => setCurrentStep((prev) => prev - 1)}
                    isFirstStep={isFirstStep}
                    isLastStep={isLastStep}
                    submitDisabled={false}
                    isSubmitting={false}
                    submitBtnText="Next"
                    onSubmitClick={nextStepHandler}
                />
            </StyledCreditorInfo>
        </>
    );
};

export default CreditorInfo;
