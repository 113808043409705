import React from "react";
import Header from "../../../layout/Header";
import StyledSummaryContent from "./styles";
import SummaryCard from "../../../components/Cards/SummaryCard";
import useReduxUser from "../../../store/hooks/useReduxUser";

const Summary: React.FC = () => {
    const { setClientCase, clientCase } = useReduxUser();
    const { details, payments, services } = clientCase;

    return (
        <>
            <Header title="summary"></Header>
            <StyledSummaryContent>
                <SummaryCard
                    title="Details"
                    data={details || {}}
                    labels={[
                        "Reference",
                        "Name",
                        "Date of Birth",
                        "Address",
                        "Contact",
                        "Email",
                    ]}
                    keys={[
                        "reference",
                        "Name",
                        "dob",
                        "Address",
                        "contact",
                        "email",
                    ]}
                />
                <SummaryCard
                    title="Payments"
                    data={payments || {}}
                    labels={[
                        "Next Payment Date",
                        "Monthly Setup Fee",
                        "Accountancy Fee",
                        "Payment Frequency",
                        "GDPR Consent (Call)",
                        "GDPR Consent (SMS)",
                        "GDPR Consent (Email)",
                        "GDPR Consent (Letter)",
                    ]}
                    keys={[
                        "next_pmnt_in_dt",
                        "monthly_setup_fee",
                        "accountancy_fee",
                        "payment_freq",
                        "call",
                        "sms",
                        "email",
                        "letter",
                    ]}
                />
                <SummaryCard
                    title="Services"
                    data={services || {}}
                    labels={[
                        "Service",
                        "New Business Rep",
                        "Case Handler",
                        "Marketing Source",
                        "Status",
                        "ID Check Status",
                    ]}
                    keys={[
                        "service",
                        "new_business_rep",
                        "case_handler",
                        "marketing_source",
                        "status",
                        "id_status",
                    ]}
                />
            </StyledSummaryContent>
        </>
    );
};

export default Summary;
