import React from "react";
import { icons } from "../../assets/icons";
import { LinearHeading } from "../../global/theme/reuseable/components";
import { GroupSettingWrapper } from "./styles";
import { formatTimeString } from "../../global/utils/helpers";

interface GroupSettingsCardProps {
    data: { [key: string]: any };
    onClick?: (data: { [key: string]: any }) => void;
}

const GroupSettingCard = ({
    data,
    onClick = () => {},
}: GroupSettingsCardProps) => {
    return (
        <GroupSettingWrapper onClick={onClick.bind(null, data)}>
            <button className="editButton">
                <img src={icons.edit} alt="Edit Settings" />
                edit
            </button>
            <p>{data?.name || "--"}</p>
            <p>{data?.description || "-"}</p>

            <span>
                <LinearHeading color="blue">
                    {data?.count_users || 0}
                </LinearHeading>
                <p>Users</p>
            </span>
            <p>{formatTimeString(data?.last_updated || "")}</p>
        </GroupSettingWrapper>
    );
};

export default GroupSettingCard;
