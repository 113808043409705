import React, { useEffect, SetStateAction, useTransition } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { CalendarWrapper, DayHeader } from "./styles";
import { Moment } from "moment";
import { FilterParams } from "../../../pages/UserPages/Diary/Diary";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxDiary from "../../../store/hooks/useReduxDiary";
import DayTile from "./DayTile";

interface CalendarProps {
    currentDate: Moment;
    filterParams: FilterParams;
    setIsLoading?: React.Dispatch<SetStateAction<boolean>>;
    isClientCase?: boolean;
}

const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

const Calendar: React.FC<CalendarProps> = ({
    currentDate,
    filterParams,
    setIsLoading,
    isClientCase = false,
}) => {
    const { clientId } = useParams();
    const { setDiary, diary } = useReduxDiary();
    const [isPending, startTransition] = useTransition();
    const { fetchData, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/diary-filter-month`,
        },
        {
            onSuccess: (data) => {
                const dates = data?.data;
                if (dates) setDiary(dates);
            },
        }
    );
    const month = currentDate.month() + 1;
    const year = currentDate.year();
    const formattedData = () => {
        let dataObj: { [key: string]: Array<{ [key: string]: any }> } = {};
        let startDay = moment(currentDate)
            .startOf("month")
            .subtract(1, "day")
            .startOf("week");
        const endDay = moment(currentDate).endOf("month").endOf("week");
        while (startDay.isBefore(endDay)) {
            dataObj[startDay.add(1, "day").format("YYYY-MM-DD")] = [];
        }
        diary.forEach((obj) => {
            const keyName = moment(obj?.whn, "YYYY-MM-DD HH:mm:ss").format(
                "YYYY-MM-DD"
            );
            if (keyName in dataObj) {
                dataObj[keyName].push(obj);
            }
        });
        return dataObj;
    };

    useEffect(() => {
        if (!isClientCase) fetchData({ month, year, ...filterParams });
        if (isClientCase && clientId)
            fetchData({ month, year, case_id: +clientId });
    }, [month, year, filterParams, isClientCase]);

    useEffect(() => {
        if (setIsLoading) setIsLoading(isLoading);
    }, [isLoading]);

    return (
        <>
            <CalendarWrapper>
                <div className="calendar">
                    <div className="headers">
                        {days.map((day) => (
                            <DayHeader key={day}>{day}</DayHeader>
                        ))}
                    </div>
                    <div
                        className={`dates${
                            isLoading || isPending ? " loading" : ""
                        }`}
                    >
                        {Object.entries(formattedData()).map(([key, value]) => (
                            <DayTile
                                key={key}
                                date={key}
                                dayData={value}
                                currentMonth={moment(key, "YYYY-MM-DD").isSame(
                                    currentDate,
                                    "month"
                                )}
                            />
                        ))}
                    </div>
                </div>
            </CalendarWrapper>
        </>
    );
};

export default Calendar;
