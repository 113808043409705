import { ChangeEvent } from "react";
import { CreditorFormProps } from ".";
import { StyledCreditorKeyEvents } from "./styles";
import { Input, TextArea } from "../../../components";
import DropdownSelect from "../../../components/DropdownSelect";
import { creditorStatusOptions } from "../../../components/DropdownSelect/options";

const CreditorKeyEvents: React.FC<CreditorFormProps> = ({
    creditorData,
    setCreditorData,
}) => {
    const inputHandler = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type } = e.target;
        setCreditorData((prev) => {
            return {
                ...prev,
                [name]: type === "number" ? +value : value,
            };
        });
    };

    const selectHandler = (key: string, value: string | number) => {
        setCreditorData((prev) => {
            return { ...prev, [key]: value };
        });
    };
    return (
        <StyledCreditorKeyEvents className="creditor-key-events">
            <div className="creditor-settings__fields">
                <DropdownSelect
                    className="creditor-settings__dropdown"
                    label="Status:"
                    value={creditorData ? creditorData["stts"] : null}
                    options={creditorStatusOptions}
                    onSelect={(option) =>
                        selectHandler("status_id", option.value)
                    }
                />
                <Input
                    className="creditor-settings__input"
                    placeholder="Enter amount..."
                    type="number"
                    label="Min Payment:"
                    name="min_payment"
                    value={+creditorData ? creditorData["min_payment"] : ""}
                    onChange={inputHandler}
                ></Input>
                <TextArea
                    className="creditor-settings__textarea"
                    placeholder="Enter address..."
                    label="Address:"
                    name="Address"
                    value={creditorData ? creditorData.Address : ""}
                    onChange={inputHandler}
                ></TextArea>
            </div>
            <div className="creditor-key-events__info">
                <div className="creditor-key-events__data">
                    <h5>Created by:</h5>
                    <p>{creditorData ? creditorData.createdBy : ""}</p>
                </div>
                <div className="creditor-key-events__data">
                    <h5>Created on:</h5>
                    <p>{creditorData ? creditorData.created_at : ""}</p>
                </div>
                <div className="creditor-key-events__data">
                    <h5>Last update by:</h5>
                    <p>{creditorData ? creditorData.updatedBy : ""}</p>
                </div>
                <div className="creditor-key-events__data">
                    <h5>Last update on:</h5>
                    <p>{creditorData ? creditorData.updated_at : ""}</p>
                </div>
            </div>
        </StyledCreditorKeyEvents>
    );
};

export default CreditorKeyEvents;
