import styled from "styled-components";
import { theme } from "../global/theme";
import {
    centeredRowMixin,
    mainScrollbar,
} from "../global/theme/reuseable/mixins";
import { JustifyContentT } from "../global/theme/types";
import { iSCHideNav, PageFilterPanelProps } from "./types";
import { toolbarHeight, navBarHeight } from "../layout/Header/styles";

// DASHBOARD STYLING // START //
export const Page = styled.main`
    /* width: 100vw;
    height: 100vh; */
`;

export const PageMain = styled.section`
    background-color: ${theme?.colors?.background};
    min-height: 100vh;
    overflow-x: auto;
    margin-left: 85px;

    & .page-wrapper {
        display: flex;
        flex-direction: column;
        min-width: 800px;
        min-height: inherit;
        /* overflow-y: auto;
        ${mainScrollbar(14)} */
    }
`;

export const pageInlinePadding = "30px";

export const PageNavWrapper = styled.aside`
    background-color: ${theme?.colors?.background};
    position: sticky;
    top: 60px;
    z-index: 1;
`;

export const PageContent = styled.section<iSCHideNav>`
    padding: ${pageInlinePadding};
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    max-height: ${(props) => props.maxHeight};
    overflow-y: ${(props) => props.overflowY ?? "auto"};
    overflow-x: ${(props) => props.overflowX ?? "auto"};
    ${mainScrollbar(14)};
`;

export interface iFilterContent {
    panelColor?: string;
}
export const PageFilterContainer = styled.div<iFilterContent>`
    flex: 1;
    display: grid;
    grid-template-rows: 80px auto;
    grid-template-columns: 1fr auto;
    width: 100%;
    background-color: ${(props) => props.panelColor || theme?.colors.formBlue};
`;

export const PageFilterContent = styled.section`
    grid-area: 2/1/-1 / span 1;
    background-color: ${theme?.colors.background};
    padding: ${pageInlinePadding};
`;

export const PageFilterTableContent = styled(PageFilterContent)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const PageFilterPanel = styled.div<PageFilterPanelProps>`
    position: sticky;
    top: ${({ hasNavBarAbove = true }) =>
        hasNavBarAbove
            ? `calc(${toolbarHeight} + ${navBarHeight})`
            : toolbarHeight};
`;

interface iSCPageHeader {
    justify?: JustifyContentT;
}

export const PageHeader = styled.div<iSCPageHeader>`
    ${centeredRowMixin}
    justify-content: ${({ justify }) => (justify ? justify : "space-between")};
    padding-block: 12px;
    min-height: 80px;
    margin: 0 ${pageInlinePadding} 0 ${pageInlinePadding};
    border-bottom: 1px solid ${theme?.colors?.text};
    /* position: sticky;
    top: 60px; */
    z-index: 1;
    background-color: ${theme?.colors.background} button {
        height: 40px;
    }

    span {
        ${centeredRowMixin}
        gap: 28px;
        button {
            width: 120px;
            height: 40px;
        }
    }

    & .date-range-text {
        font-size: 13px;
        font-weight: 400;
    }
`;
export const PageHeading = styled.h1`
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: ${theme?.colors?.text};
    display: flex;

    .return {
        height: 28px;
        width: 28px;
        margin-right: 20px;
        background: #f5f5f5;
        border-radius: 4.66667px;
        display: grid;
        place-items: center;
        margin-right: 9px;

        :hover {
            cursor: pointer;
            background: #eeeeee;
        }
    }
`;

export const DiaryPageWrapper = styled.div`
    display: flex;
    flex-direction: column;

    .diaryNav {
        margin-bottom: 34px;
        button {
            height: 40px;
        }
    }

    & .left-btns {
        display: flex;
        gap: 8px;

        & > button {
            &:hover {
                background-color: ${theme?.colors.purple};

                & > img {
                    filter: brightness(3);
                }
            }

            &:nth-child(2) {
                & img {
                    transform: rotate(180deg);
                }
            }
        }
    }

    & .right-btns {
        display: flex;
        gap: 12px;

        & > button {
            min-width: 72px;
            &:last-of-type {
                /* margin-left: 24px; */
                width: 110px;

                & > span {
                    margin: 0;
                }
            }
        }
    }

    h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #5e5e5e;
        margin-left: 20px;
        margin-right: auto;
    }
    span {
        margin-left: auto;
        display: flex;
        gap: 18px;

        button {
            height: 36px;
        }
    }

    > section:first-child {
        width: 100%;
    }
`;

export const SearchRow = styled.div`
    display: flex;
    gap: 20px;
    height: 40px;
    margin-bottom: 20px;
    background-color: ${theme?.colors.background};

    & > button {
        height: inherit;
        padding: 0 20px;
    }
`;

export const TablePageContent = styled.section`
    position: relative;
    flex: 1 1 0;
    padding: ${pageInlinePadding};
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledErrorPage = styled(PageContent)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5%;

    & .error-page {
        &__title {
            font-size: 36px;
            font-weight: 400;
        }

        &__message {
            font-size: 20px;
            margin-top: 12px;
            font-weight: 300;
        }

        &__btn {
            margin-top: 12px;
            height: 50px;
            font-size: 16px;
            border-radius: 8px;
            padding-inline: 24px;
        }
    }
`;
