import React from "react";
import { Route, Routes } from "react-router-dom";
import Invoices from "../../subPages/User/Payments/Invoices";
import PaymentLanding from "../../subPages/User/Payments/Landing";
import Payment from "../../subPages/User/Payments/Payments";
import Refunds from "../../subPages/User/Payments/Refunds";
import NavBar from "../../layout/Header/NavBar";

const Payments: React.FC = () => {
    return (
        <>
            <NavBar subListName="payments" />
            <Routes>
                <Route index element={<PaymentLanding />} />
                <Route path="/payments/*" element={<Payment />} />
                <Route path="/refunds" element={<Refunds />} />
                <Route path="/invoices" element={<Invoices />} />
            </Routes>
        </>
    );
};

export default Payments;
