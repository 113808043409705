import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ClientManagementState {
    invoice: Array<{[key: string]: any}>;
    auditLogs: Array<{[key: string]: any}>;
    misc: {[key: string]: any} | null;
}

export const initialClientManagementState: ClientManagementState = {
    invoice: [],
    auditLogs: [],
    misc: {
        notes: ''
    }
}


export const clientManagementSlice = createSlice({
    name: 'client-management',
    initialState: initialClientManagementState,
    reducers: {
        setInvoice: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.invoice = action.payload
        },
        setAuditLogs: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.auditLogs = action.payload
        },
        setMisc: (state, action: PayloadAction<{[key: string]: any} | null>) => {
            state.misc = action.payload
        },
        resetSlice: () => {
            return initialClientManagementState
        },
    }
})

export const clientManagementActions = clientManagementSlice.actions
export default clientManagementSlice