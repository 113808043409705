import React, { useEffect,useState } from "react";
import { Button } from "../../../components";
import useFetchData from "../../../global/hooks/useFetchData";
import DataTable from "../../../components/DataTable";
import { testRequestsData, testSessionsData } from "./testData";
import { useParams } from "react-router-dom";
import RequestsRow from "../../../components/DataTable/TableRows/ClientPortal/RequestsRow";
import useReduxClientPortal from "../../../store/hooks/useReduxClientPortal";
import { Notify } from "notiflix";

const ClientPortalTables = () => {
    const { clientId } = useParams();
    const { requests, setRequests, sessions, setSessions } =
        useReduxClientPortal();

    const getRequestsUpdate = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/client-portal/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setRequests(data?.data?.requests);
            },
        }
    );
    const getSessionsUpdate = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/client-portal/${clientId}`,
        },
        {
            onSuccess: (data) => {

                Notify.info('Could not Load From Clear Start Accountants');
                //if (data?.data) setSessions(data.data);
            },
        }
    );
console.log(requests);
    // useEffect(() => {
    //     setRequests(testRequestsData);
    //     setSessions(testSessionsData);
    // }, []);

    return (
        <div className="client-portal__tables">
            <div className="client-portal__table">
                <div className="client-portal__row">
                    <h2>Requests</h2>
                    <Button
                        type="button"
                        btnText="Get Update"
                        style="white"
                        onClick={() => getRequestsUpdate.fetchData()}
                        loading={getRequestsUpdate.isLoading}
                    />
                </div>
                <DataTable
                    labels={["Request", "Marked?", "Date Created"]}
                    keys={["information", "marked", "time"]}
                    data={requests}
                    formatKeys={{
                        created_at: "time",
                    }}
                    columnWidths={["", "80px", "140px"]}
                    hasAction
                    tableRow={RequestsRow}
                />
            </div>
            <div className="client-portal__table">
                <div className="client-portal__row">
                    <h2>Sessions</h2>
                    <Button
                        type="button"
                        btnText="Get Update"
                        style="white"
                        onClick={() => getSessionsUpdate.fetchData()}
                        loading={getSessionsUpdate.isLoading}
                    />
                </div>
                <DataTable
                    labels={["IP Address", "ISP", "City", "OS", "When"]}
                    keys={["ip_address", "isp", "city", "os", "when"]}
                    data={sessions || []}
                    formatKeys={{
                        when: "time",
                    }}
                />
            </div>
        </div>
    );
};

export default ClientPortalTables;
