import styled from "styled-components";
import { PageContent } from "../../../../pages/styles";

const StyledBusinessAccountancyContent = styled(PageContent)`
    & > form {
        display: grid;
        grid-template-columns: min(100%, 1120px);
        justify-content: center;
        gap: 24px;

        & .full-col {
            grid-column: 1/-1;
        }
    }
`;

export default StyledBusinessAccountancyContent;
