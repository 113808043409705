import { useState } from "react";
import { StyledDeleteWorkflowTemplate } from "./styles";
import ModalHeader from "../../components/ModalHeader";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import WorkflowGroup from "./WorkflowGroup";
import LineBreak from "../../../../atoms/LineBreak";
import {
    generateTestOptions,
    workflowAreaOptions,
} from "../../../DropdownSelect/options";
import Input from "../../../Input";
import DropdownSelect from "../../../DropdownSelect";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";

interface DeleteWorkflowProps {
    isOpen: boolean;
    handleModal: (...args: any[]) => void;
    id: number;
}

const DeleteWorkflowTemplate = ({
    isOpen,
    handleModal,
    id,
}: DeleteWorkflowProps) => {
    const { setTemplate } = useReduxSettings();

    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/templates/workflow/${id}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTemplate("workflow", data.data);
            },
        }
    );

    const closeModal = () => handleModal("");

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData();
    };

    return (
        <StyledDeleteWorkflowTemplate show={isOpen}>
            <ModalHeader onClose={closeModal}>Delete Workflow</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    onClose={closeModal}
                    message="Workflow has been deleted."
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <p>Do you want to delete this workflow?</p>
                    <ModalButtons>
                        <Button
                            type="button"
                            onClick={closeModal}
                            style="cancel"
                            btnText="Close"
                        />
                        <Button
                            type="submit"
                            style="red"
                            btnText="Delete"
                            loading={isLoading}
                            disabled={false}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledDeleteWorkflowTemplate>
    );
};

export default DeleteWorkflowTemplate;
