import moment, { Moment } from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, ModalContainer } from "../../../components";
import { PurpleSelectButton } from "../../../components/Button/styles";
import CreateTicketModal from "../../../components/Modals/Diary/DayMeetings/CreateTicketModal";
import { Calendar, FilterMenu } from "../../../containers/User/Diary";
import { Row } from "../../../global/theme/reuseable/components";
import { PageFilterContainer, PageFilterPanel } from "../../styles";
import Header from "../../../layout/Header";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxDiary from "../../../store/hooks/useReduxDiary";
import { StyledDiaryContent } from "./styles";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/whiteLeftArrow.svg";

export interface FilterParams {
    case_id?: number;
    who?: number;
    status?: number;
}

interface DiaryProps {
    isClientCase?: boolean;
}

const Diary: React.FC<DiaryProps> = ({ isClientCase = false }) => {
    const { clientId } = useParams();
    const [createTicketModal, setCreateTicketModal] = useState(false);
    const [currentDate, setCurrentDate] = useState<Moment>(moment());
    const [filterParams, setFilterParams] = useState<FilterParams>({});
    const [isCalendarFetching, setIsCalendarFetching] = useState(false);
    const { setDiary, setCreditors, setUsers } = useReduxDiary();
    const displayedDate = moment(currentDate).format("MMMM, YYYY");
    const isDateCurrent = moment(currentDate).isSame(moment(), "month");

    useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/diary`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                setCreditors(data?.data?.creditors);
                setUsers(data?.data?.users);
            },
        }
    );

    const prevDateHandler = () => {
        setCurrentDate((prev) => {
            return moment(prev).subtract(1, "month");
        });
    };

    const nextDateHandler = () => {
        setCurrentDate((prev) => {
            return moment(prev).add(1, "month");
        });
    };

    const applyFilterHandler = (filterParams: FilterParams) => {
        setFilterParams(filterParams);
    };

    return (
        <>
            <ModalContainer
                isVisible={createTicketModal}
                children={
                    <CreateTicketModal
                        setCreateTicketModal={setCreateTicketModal}
                        open={createTicketModal}
                    />
                }
            />
            <PageFilterContainer>
                <Header title="diary" />
                <StyledDiaryContent>
                    <Row className="diaryNav">
                        <div className="left-btns">
                            <Button
                                onClick={prevDateHandler}
                                style="cancel"
                                aria-label="prev"
                            >
                                <ArrowIcon />
                            </Button>
                            <Button
                                onClick={nextDateHandler}
                                style="cancel"
                                aria-label="next"
                            >
                                <ArrowIcon />
                            </Button>
                        </div>

                        <h2>{displayedDate}</h2>
                        <div className="right-btns">
                            <PurpleSelectButton
                                selected={isDateCurrent}
                                color="purple"
                                onClick={() => setCurrentDate(moment())}
                            >
                                Today
                            </PurpleSelectButton>
                            <Button
                                btnText="+ Create"
                                onClick={() => setCreateTicketModal(true)}
                                style="mint"
                            />
                        </div>
                    </Row>
                    <Calendar
                        currentDate={currentDate}
                        filterParams={filterParams}
                        setIsLoading={setIsCalendarFetching}
                        isClientCase={isClientCase}
                    />
                </StyledDiaryContent>
                <PageFilterPanel>
                    {!isClientCase && (
                        <FilterMenu
                            onApplyFilter={applyFilterHandler}
                            isLoading={isCalendarFetching}
                            isClientCase
                        />
                    )}
                </PageFilterPanel>
            </PageFilterContainer>
        </>
    );
};

export default Diary;
