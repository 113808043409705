import React from "react";
import { Link, useParams } from "react-router-dom";
import StyledLedgerPaymentsLandingContent, {
    LedgerPaymentsLandingHeader,
} from "./styles";
import DetailCard from "../../../components/Cards/DetailCard";
import { formatDateString } from "../../../global/utils/helpers";
import LedgerCard from "./LedgerCard";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxClientLedgerPayment from "../../../store/hooks/useReduxClientLedgerPayment";
import AuthorisationRate from "../../../atoms/AuthorisationRate";

const LedgerPaymentsLanding = () => {
    const { clientId } = useParams();
    const { ledger, payments, paymentsSchedule } =
        useReduxClientLedgerPayment();
    const getLedgerPayments = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/ledger-payments/${clientId}`,
        },
        {
            fetchOnLoad: true,
        }
    );
    const data = getLedgerPayments?.data?.data || {};

    return (
        <>
            <LedgerPaymentsLandingHeader
                title="Ledger / Payments"
                hasNavBarAbove
            >
                <div className="auth-rate">
                    Authorisation Rate <span>{data?.auth_rate || 0}%</span>
                </div>
            </LedgerPaymentsLandingHeader>
            <StyledLedgerPaymentsLandingContent>
                <div className="left-ctn">
                    <LedgerCard
                        sum={data?.tot_ledger || 0}
                        isLoading={getLedgerPayments.isLoading}
                    />
                </div>
                <div className="right-ctn">
                    <DetailCard
                        titleElementTag="h2"
                        title={
                            <>
                                Total Client Payments
                                <Link to="payments">View Details</Link>
                            </>
                        }
                        count={data?.tot_client || 0}
                        countUnit="£"
                        isLoading={getLedgerPayments.isLoading}
                    />
                    <DetailCard
                        isLoading={getLedgerPayments.isLoading}
                        titleElementTag="h2"
                        title={
                            <>
                                Payment Schedule
                                <Link to="payment-schedule">View Details</Link>
                            </>
                        }
                    >
                        <ul>
                            <li>
                                Payment Frequency:
                                <span>
                                    {data?.payment_schedule?.payment_freq ||
                                        null}
                                </span>
                            </li>
                            <li>
                                Next Payment Date:
                                <span>
                                    {formatDateString(
                                        data?.payment_schedule
                                            ?.next_pmnt_in_dt || ""
                                    )}
                                </span>
                            </li>
                            <li>
                                Monthly Fee:
                                <span>
                                    £
                                    {data?.payment_schedule?.monthly_fee ||
                                        null}
                                </span>
                            </li>
                        </ul>
                    </DetailCard>
                </div>
            </StyledLedgerPaymentsLandingContent>
        </>
    );
};

export default LedgerPaymentsLanding;
