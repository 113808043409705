import styled from "styled-components";
import { theme } from "../../../../global/theme";
import { ResizableStepModalWrapper } from "../../styles";
import { ResizableModalWrapper, innerModalMixin } from "../../styles";

export const DayMeetingModalWrapper = styled(ResizableModalWrapper)`
    max-height: min(calc(100vh - 60px), 1024px);
    .heading {
    }
    .content {
    }
    .nav {
    }
`;

export const DayTaskWrapper = styled.figure`
    background: #ffffff;
    border-radius: 6px;
    border: solid #4d9eff 1px;
    padding: 16px;
    text-align: left;
    display: flex;
    flex-direction: column;

    label {
        font-family: "Poppins";
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
    }

    > div {
        button {
            margin-left: auto;
            background: none;
            border: none;
            text-decoration: underline;
            color: ${theme.colors.purple};
            font-weight: 300;
            cursor: pointer;
        }
    }
`;

export const StyledStepTwoContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    & .step-two-content {
        &__row {
            display: flex;
            gap: 12px;
        }
        &__info {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            flex: 1;
        }

        &__case {
            line-height: 100%;
            font-weight: 600;
        }

        &__handler {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
        }

        &__creditor {
            font-size: 12px;
            font-weight: 300;
            text-align: right;
        }
        &__back {
            background-color: transparent;
            border: none;
            padding: 4px;
            height: 30px;
        }

        &__card {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        &__time {
            & > p {
                font-weight: 300;
                font-size: 12px;
            }
        }

        &__task {
            & > p {
                font-weight: 300;
                font-size: 12px;
                margin-bottom: 24px;
            }
            margin-bottom: 12px;
            border-bottom: 0.6px solid ${theme?.colors.boxStroke};
        }

        &__bottom {
            display: flex;
            align-items: center;

            & .dropdown-select {
                flex-direction: row;
                align-items: center;
                gap: 8px;
            }
            & label {
                margin-left: 8px;
            }
        }
    }

    & .btns {
        margin-left: auto;
    }
`;
