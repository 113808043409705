import BasicModal from "../../BasicModal";
import { useParams } from "react-router-dom";
import useFetchData from "../../../../global/hooks/useFetchData";
import { CaseActionsModalProps } from "./types";

const FlipClientToAPF: React.FC<CaseActionsModalProps> = ({
    isOpen,
    onClose,
}) => {
    const { clientId } = useParams();
    const { fetchData, isSuccess, isLoading } = useFetchData({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/${clientId}`,
    });

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData();
    };
    return (
        <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            message="Do you want to filp client to APF?"
            submitBtnText="Confirm"
            successMessage="The client has been flipped to APF."
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSubmit={submitHandler}
        />
    );
};

export default FlipClientToAPF;
