import React, { useState, useEffect } from "react";
import { DashboardEdit, Button } from "../../../components";
import Layout from "./Layout";
import useReduxUI from "../../../store/hooks/useReduxUI";
import useReduxDashboard from "../../../store/hooks/useReduxDashboard";
import useFetchData from "../../../global/hooks/useFetchData";
import Header from "../../../layout/Header";
import DateButton from "../../../components/Button/DateButton";
import useDateSearch from "../../../layout/SlideInMenus/DateSearch/useDateSearch";
import { DashboardContent } from "./styles";
import { dashboardDateSearchOptions } from "../../../layout/SlideInMenus/DateSearch/data";

const Dashboard: React.FC = () => {
    const { setSideMenu, sideMenu } = useReduxUI();
    const [isEditing, setIsEditing] = useState(false);
    const { setLeftColumnData, setRightColumnData } = useReduxDashboard();
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today");
    const leftColData = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/tab2`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => setLeftColumnData(data.data),
        }
    );
    const rightColData = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/tab3`,
        },
        {
            onSuccess: (data) => {
                setSideMenu("");
                onDateSearchSuccess();
                return setRightColumnData(data?.data);
            },
            params: dateRange
                ? { from: dateRange.from, to: dateRange.to }
                : undefined,
        }
    );

    useEffect(() => {
        if (dateRange) {
            rightColData.fetchData();
        }
    }, [dateRange]);

    return (
        <>
            <Header title="dashboard">
                <DateButton
                    isLoading={rightColData.isLoading}
                    dateDisplay={dateDisplay}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                    options={dashboardDateSearchOptions}
                />
                <Button
                    btnText="Edit Dashboard"
                    style="purple"
                    onClick={() => setIsEditing(true)}
                />
            </Header>
            <DashboardEdit isEditing={isEditing} setIsEditing={setIsEditing} />

            <DashboardContent
                className="dashboard-content"
                data-editing={isEditing}
            >
                <Layout
                    leftIsLoading={leftColData.isLoading}
                    rightIsLoading={rightColData.isLoading}
                />
            </DashboardContent>
        </>
    );
};

export default Dashboard;
