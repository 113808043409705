import React, { SetStateAction } from "react";
import { formatTimeString } from "../../../../global/utils/helpers";

interface LiabilityHistoryProps {
    history: Array<{ [key: string]: any }>;
}

const LiabilityHistory: React.FC<LiabilityHistoryProps> = ({ history }) => {
    console.log(history);
    return (
        <ul className="liability-main__content data-list data-list--history">
            <p>Creditor History</p>
            {history?.length ? (
                history.map((obj) => (
                    <li key={obj.id}>
                        {obj?.name || "(Unknown User)"}
                        <span>{formatTimeString(obj?.when || "")}</span>
                    </li>
                ))
            ) : (
                <p className="empty">No history found.</p>
            )}
        </ul>
    );
};

export default LiabilityHistory;
