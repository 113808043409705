import styled from "styled-components";
import { theme } from "../../global/theme";
import { pageInlinePadding } from "../../pages/styles";
import {
    hideScrollbar,
    mainScrollbar,
} from "../../global/theme/reuseable/mixins";

const StyledFilePageContent = styled.div`
    display: grid;
    grid-template-columns: clamp(180px, 20vw, 220px) 1fr;
    grid-template-rows: auto 1fr;
    flex: 1 1 0;
    overflow: hidden;

    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
    }

    & .file-page-content {
        &__panel {
            background-color: ${theme.colors.formBlue};
            padding: 32px clamp(12px, 2vw, 24px);
            display: flex;
            flex-direction: column;
            grid-row: 1/-1;
            gap: 16px;

            @media screen and (max-width: 1200px) {
                grid-row: 2 / span 1;
                display: grid;
                grid-template-columns: 180px 1fr;
                grid-template-rows: auto;
                padding: 12px 30px;
                gap: 0px 8px;
            }

            &__ctn {
                display: flex;
                flex-direction: column;
                padding-inline: 12px;

                @media screen and (max-width: 1200px) {
                    grid-row: 1 / span 2;
                    justify-content: center;
                    padding: 0;
                }
            }
            &__title {
                font-size: 12px;
                text-decoration: underline;
                margin-bottom: -8px;
                padding-inline: 12px;

                @media screen and (max-width: 1200px) {
                    justify-self: flex-end;
                    margin-bottom: 0px;
                    display: none;
                }
            }

            &__options {
                list-style: none;
                display: flex;
                flex-direction: column;
                flex: 1 1 0;
                overflow: auto;
                ${hideScrollbar};

                @media screen and (max-width: 1200px) {
                    flex-direction: row;
                    gap: 8px;
                    flex-wrap: wrap;
                    justify-self: flex-end;
                    align-self: center;
                    grid-row: 1 / span 2;
                }
            }

            &__option {
                & > button {
                    background-color: transparent;
                    padding: 6px 12px;
                    border: none;
                    color: ${theme.colors.text};
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 8px;
                    width: 100%;
                    font-size: 12px;
                    border-radius: 6px;
                    font-weight: 300;
                    text-transform: capitalize;
                    min-width: 132px;

                    &:hover {
                        background-color: ${theme.colors.highlightBlue};
                    }

                    &[data-selected="true"] {
                        background-color: ${theme.colors.purple};
                        color: ${theme.colors.white};
                    }

                    &:disabled {
                        opacity: 1;
                        color: ${theme.colors.text};
                        pointer-events: none;

                        & > span {
                            opacity: 0.3;
                        }
                    }
                }
            }
        }

        &__header {
            position: initial;
            top: initial;
        }

        &__content {
            padding: ${pageInlinePadding};
            overflow: auto;
            display: flex;
            flex-direction: column;

            /* justify-self: flex-start; */
            ${mainScrollbar()} /* display: flex;
            justify-content: flex-start; */

            @media screen and (max-width: 1200px) {
                padding-block: 24px;
            }
        }

        &__spinner {
            position: relative;
        }
    }

    & .no-upload {
        font-size: 20px;
        font-weight: 300;
        color: ${theme.colors.shade};
        text-align: center;
        margin-top: 24px;
    }
`;

export default StyledFilePageContent;
