import React, { useState, useEffect, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { icons } from "../../../assets/icons";

import { iSubNav } from "../types";
import { NavItemCtn, NavDropdown } from "./styles";
import { iNav } from "../types";

interface NavItemProps {
    link: iNav;
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
    isClientCase: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
    link,
    isOpen,
    setIsOpen,
    isClientCase,
}) => {
    const { clientId } = useParams();
    const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
    const { name, path, icon, subLinks } = link;
    const linkPath = isClientCase
        ? `/client-case/${clientId}/${path.replace(/\//g, "")}`
        : path;

    const dropdownHandler = () => {
        if (subLinks) {
            setIsDropdownExpanded((prev) => !prev);
        }
    };

    useEffect(() => {
        setIsDropdownExpanded(false);
    }, [isOpen]);

    return (
        <NavItemCtn className="nav-item" aria-expanded={isDropdownExpanded}>
            <NavLink
                className="nav-item__link"
                to={linkPath}
                style={({ isActive }) => {
                    return {
                        backgroundColor: isActive
                            ? "rgba(255, 255, 255, 0.3)"
                            : "",
                    };
                }}
                onClick={() => setIsOpen(false)}
            >
                <img src={icon} alt={name} />
                {isOpen ? <span>{name}</span> : null}
            </NavLink>
            {isOpen && subLinks ? (
                <button
                    className={`nav-item__toggler${
                        isDropdownExpanded ? " expanded" : ""
                    }`}
                    onClick={dropdownHandler}
                >
                    <img src={icons?.whiteDownArrow} alt="arrow" />
                </button>
            ) : null}
            {isOpen && subLinks && isDropdownExpanded ? (
                <NavDropdown className="nav-dropdown">
                    {subLinks.map((subLink: iSubNav, i: number) => (
                        <li className="nav-dropdown__item" key={i}>
                            <Link
                                to={`${linkPath}${subLink?.path}`}
                                onClick={() => setIsOpen(false)}
                            >
                                {subLink?.name}
                            </Link>
                        </li>
                    ))}
                </NavDropdown>
            ) : null}
        </NavItemCtn>
    );
};

export default NavItem;
