import { Input } from "../../../index";
import { IntroducerCreateFormProps } from "../IntroducerCreate";
import isEmail from "validator/lib/isEmail";

const ContactInfo: React.FC<IntroducerCreateFormProps> = ({
    introducerDetails,
    onChange,
}) => {
    return (
        <div className="contact-info col-2">
            <Input
                name="contact"
                type="text"
                label="Contact Name: (*)"
                onChange={onChange}
                value={introducerDetails.contact}
                autoComplete="auto-off"
            />
            <Input
                name="tele_phone"
                type="tel"
                label="Telephone:"
                onChange={onChange}
                value={introducerDetails.tele_phone}
                autoComplete="auto-off"
            />
            <Input
                className="input-email"
                name="email"
                type="email"
                label="Email Address: (*)"
                placeholder="Email..."
                onChange={onChange}
                value={introducerDetails.email}
                autoComplete="auto-off"
                isValid={
                    introducerDetails.email === "" ||
                    isEmail(introducerDetails.email)
                }
            />
            <hr />
        </div>
    );
};

export default ContactInfo;
