import React,{useState} from "react";
import { Link,useParams } from "react-router-dom";
import DetailCard from "../../../components/Cards/DetailCard";
import Header from "../../../layout/Header";
import useReduxClientManagement from "../../../store/hooks/useReduxClientManagement";
import StyledManagementLandingContent from "./styles";
import { testAuditLogsData, testInvoiceData } from "./testData";
import { formatTimeString } from "../../../global/utils/helpers";
import useFetchData from "../../../global/hooks/useFetchData";

const ManagementLanding = () => {
    const { invoice, auditLogs, misc } = useReduxClientManagement();
    const { clientId } = useParams();
    const [invoiceSummary,setInvoiceSummary] =  useState([]);
    const [auditLogsSummary,setAuditLogsSummary] =  useState([]);
    const [notes,setNotes] =  useState("");
    const getManagement = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/management/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
            
                setInvoiceSummary(data?.data?.invoice);
                setAuditLogsSummary(data?.data?.logs);
                setNotes(data?.data?.notes?.accounts_alert || null);
            },
        }
    );

    return (
        <>
            <Header title="Management" hasNavBarAbove />
            <StyledManagementLandingContent>
              <div className="cards">
                <DetailCard
                    title={
                        <>
                            Invoice<Link to="invoices">View Details</Link>
                        </>
                    }
                    titleElementTag="h2"
                >
                    <ul className="invoice-list">
                        <li>
                            <span>ID</span>
                            <span>Total</span>
                            <span>Status</span>
                        </li>
                        {invoiceSummary?.length ? (
                            invoiceSummary.map((data:any) => (
                                <li key={data.id}>
                                    <span>{data?.ID || "-"}</span>
                                    <span>{data?.Total || "-"}</span>
                                    <span>{data?.Status || "-"}</span>
                                </li>
                            ))
                        ) : (
                            <p>No invoice found.</p>
                        )}
                    </ul>
                </DetailCard>
                <DetailCard
                    title={
                        <>
                            Audit Logs<Link to="audit-logs">View Details</Link>
                        </>
                    }
                    titleElementTag="h2"
                >
                    <ul className="audit-list">
                        <li>
                            <span>Description</span>
                            <span>Created By </span>
                            <span>Created At</span>
                        </li>
                        {auditLogsSummary?.length ? (
                            auditLogsSummary.map((data:any) => (
                                <li key={data.id}>
                                    <span>{data?.dscrptn || "-"}</span>
                                    <span>{data?.name || "-"}</span>
                                    <span>
                                        {formatTimeString(data?.created_at) ||
                                            "-"}
                                    </span>
                                </li>
                            ))
                        ) : (
                            <p>No audit log found.</p>
                        )}
                    </ul>
                </DetailCard>
                <DetailCard
                    title={
                        <>
                            Misc<Link to="misc">View Details</Link>
                        </>
                    }
                    titleElementTag="h2"
                    className="misc-card"
                >
                    <div className="misc-item">
                        <h3>Notes</h3>
                        <p>{notes || "-"}</p>
                    </div>
                </DetailCard>
        </div>

            </StyledManagementLandingContent>
        </>
    );
};

export default ManagementLanding;
