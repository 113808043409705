import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import { StyledPostalAddressContent } from "./styles";
import AddressCtn from "../../User/Client/ClientCreate/Address/AddressCtn";
import DataTable from "../../../components/DataTable";
import useFetchData from "../../../global/hooks/useFetchData";
import { Button, Modal } from "../../../components";
import CreateAddress from "../../../components/Modals/BaseDetails/CreateAddress";
import EditAddress from "../../../components/Modals/BaseDetails/EditAddress";
import { StyledBaseDetailsSubmitCtn } from "./styles";
import useReduxClientBaseDetails from "../../../store/hooks/useReduxClientBaseDetails";

const PostalAddress = () => {
    const { clientId } = useParams();
    const { setBaseDetailsbyKey, postalAddress } = useReduxClientBaseDetails();
    const [isCreatingAddress, setIsCreatingAddress] = useState(false);
    const [selectedData, setSelectedData] = useState<{
        [key: string]: any;
    } | null>(null);
    const [address1, setAddress1] = useState<{ [key: string]: any }>({});
    const [address2, setAddress2] = useState<{ [key: string]: any }>({});

    const getAddress = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/address/${clientId}`,
        },
        {
            fetchOnLoad: !!clientId,
            onSuccess: (data) => {
                if (data?.data) setBaseDetailsbyKey("postalAddress", data.data);
            },
        }
    );

    const updateAddress = useFetchData(
        {
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_API}/client`,
        },
        {
            onSuccess: (data) => {
                if (data?.data)
                    setBaseDetailsbyKey(
                        "postalAddress",
                        data?.data?.address || []
                    );
            },
        }
    );

    useEffect(() => {
        if (postalAddress?.[0]) setAddress1(postalAddress[0]);
        if (postalAddress?.[1]) setAddress2(postalAddress[1]);
    }, [postalAddress]);

    const testOtherAddressData = [
        {
            id: 1,
            door: "2",
            postcode: "N7 123",
            full: "2 Some Street, Manchester",
            year: 2012,
            last_updated: "2022-08-12",
        },
        {
            id: 2,
            door: "3",
            postcode: "N7 123",
            full: "3 Some Street, Manchester",
            year: 2002,
            last_updated: "2023-08-12",
        },
    ];

    const rowClickHandler = (data: { [key: string]: any }) => {
        setSelectedData(data);
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (clientId) {
            const data = {
                id: +clientId,
                tab: "address",
                data: { address: [address1, address2] },
            };
            updateAddress.fetchData(data);
        }
    };

    return (
        <>
            <Modal isVisible={isCreatingAddress}>
                <CreateAddress
                    isOpen={isCreatingAddress}
                    onClose={() => setIsCreatingAddress(false)}
                />
            </Modal>
            <Modal isVisible={!!selectedData}>
                <EditAddress
                    isOpen={!!selectedData}
                    onClose={() => setSelectedData(null)}
                    data={selectedData || undefined}
                />
            </Modal>
            <Header title="Postal Address" hasNavBarAbove hasPrevPath />
            <StyledPostalAddressContent>
                <form onSubmit={submitHandler}>
                    <div className="container">
                        <AddressCtn
                            setAddress={setAddress1}
                            address={address1}
                        />
                        <AddressCtn
                            setAddress={setAddress2}
                            address={address2}
                            title="Previous Address"
                        />
                    </div>
                    <div className="container">
                        <div className="container__row">
                            Other Known Address:
                            <Button
                                type="button"
                                className="container__create"
                                style="mint"
                                btnText="+ Create New"
                                onClick={() => setIsCreatingAddress(true)}
                            />
                        </div>
                        <DataTable
                            labels={["Full Address", "Year", "Last Updated"]}
                            keys={["full", "year", "last_updated"]}
                            data={testOtherAddressData}
                            isLoading={getAddress.isLoading}
                            dataKey="id"
                            onRowClick={rowClickHandler}
                            columnWidths={["", "80px", "120px"]}
                            formatKeys={{
                                last_updated: "date",
                            }}
                        />
                    </div>
                    <StyledBaseDetailsSubmitCtn>
                        <Button
                            type="submit"
                            btnText="Save Address"
                            style="purple"
                            loading={updateAddress.isLoading}
                        />
                    </StyledBaseDetailsSubmitCtn>
                </form>
            </StyledPostalAddressContent>
        </>
    );
};

export default PostalAddress;
