import { SetStateAction, CSSProperties } from "react";
import parse from "html-react-parser";
import {
    formatDateString,
    formatTimeString,
} from "../../../global/utils/helpers";
import { FormatType, HasCheckBoxFunc } from "..";

export interface TableCheckboxProps {
    id: number;
    selectedIds?: number[];
    setSelectedIds?: React.Dispatch<SetStateAction<number[]>>;
    disabled?: boolean;
}

export const TableCheckbox: React.FC<TableCheckboxProps> = ({
    id,
    selectedIds,
    setSelectedIds = () => {},
    disabled = false,
}) => {
    const checkboxHandler = () => {
        console.log(id);
        setSelectedIds((prev) =>
            prev.includes(id)
                ? prev.filter((prevId) => prevId !== id)
                : [...prev, id]
        );
    };
    return (
        <td className="table-checkbox">
            {!disabled && (
                <input
                    id={id.toString()}
                    name={`checkbox-${id}`}
                    type="checkbox"
                    onChange={checkboxHandler}
                    checked={selectedIds?.some((ids) => ids === id)}
                ></input>
            )}
        </td>
    );
};

export interface TableActionProps {
    data: { [key: string]: any };
    onActionSuccess?: (...args: any[]) => void;
}

export interface TableRowProps {
    data: { [key: string]: any };
    dataKey?: string;
    hideColumns?: number[];
    keys?: string[];
    hasCheckbox?: boolean | HasCheckBoxFunc;
    isCheckedAll?: boolean;
    selectedIds?: number[];
    setSelectedIds?: React.Dispatch<SetStateAction<number[]>>;
    action?: React.FC<any>;
    onRowClick?: (data: { [key: string]: any }) => void;
    style?: React.CSSProperties;
    formatKeys?: { [key: string]: FormatType };
    onActionSuccess?: (...args: any[]) => void;
    columnWidths?: string[];
}

const DefaultTableRow: React.FC<TableRowProps> = ({
    data,
    action,
    keys,
    hideColumns = [],
    dataKey = "",
    hasCheckbox = false,
    selectedIds = [],
    setSelectedIds = () => {},
    onRowClick,
    style,
    formatKeys = {},
    onActionSuccess,
    columnWidths = [],
}) => {
    const Action = (action as React.FC<TableActionProps>) || null;
    const rowData = data || {};
    const rowHasCheckbox =
        typeof hasCheckbox === "function" ? hasCheckbox(data) : hasCheckbox;

    const valueDisplay = (key: string, value: any) => {
        if (typeof value === "string") {
            if (formatKeys[key] === "date") return formatDateString(value);
            if (formatKeys[key] === "time") return formatTimeString(value);
            if (formatKeys[key] === "html" && typeof value === "string")
                return parse(value);
            if (formatKeys[key] === "currency") return `£${value}`;
        }
        return value ?? "-";
    };
    return (
        <tr
            role={onRowClick ? "button" : "row"}
            onClick={onRowClick ? () => onRowClick(data) : undefined}
            style={style}
            aria-selected={
                onRowClick ? selectedIds.includes(data[dataKey]) : false
            }
        >
            {!!hasCheckbox ? (
                <TableCheckbox
                    id={data[dataKey ?? ""]}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    disabled={!rowHasCheckbox}
                />
            ) : null}
            {keys?.length
                ? keys.map((k, i) => (
                      <td key={i}>{valueDisplay(k, data?.[k])}</td>
                  ))
                : Object.entries(rowData).map(([key, value], i) => {
                      return hideColumns.includes(i) ? null : (
                          <td key={i}>{valueDisplay(key, value)}</td>
                      );
                  })}
            {Action ? (
                <td className="td--action">
                    <div>
                        <Action data={data} onActionSuccess={onActionSuccess} />
                    </div>
                </td>
            ) : null}
        </tr>
    );
};

export default DefaultTableRow;
