import React from "react";
import { useNavigate } from "react-router-dom";
import { LinearHeading } from "../../global/theme/reuseable/components";
import { LinearHeadingT } from "../../global/theme/types";
import { ViewDetailsCardWrapper } from "./styles";

interface ViewDetailsCardProps {
    btnText?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    title?: string;
    path?: string;
    headingColor?: LinearHeadingT;
    value?: string | number;
}

const ViewDetailsCard: React.FC<ViewDetailsCardProps> = ({
    btnText,
    onClick,
    title,
    path,
    headingColor,
    value = "--",
}) => {
    const navigate = useNavigate();
    const handleButton = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (typeof onClick === "function") onClick(e);
        if (path) navigate(path);
    };
    return (
        <ViewDetailsCardWrapper>
            <span>
                <LinearHeading color={headingColor || "blue"}>
                    {value}
                </LinearHeading>
                <p>{title}</p>
            </span>
            <button
                type="button"
                className="view-details-card__btn"
                onClick={handleButton}
            >
                {btnText}
            </button>
        </ViewDetailsCardWrapper>
    );
};

export default ViewDetailsCard;
