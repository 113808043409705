import moment from "moment";
import React, { Dispatch, SetStateAction, useState } from "react";
import { icons } from "../../../../assets/icons";
import Button from "../../../Button";
import { DayMeetingModalWrapper } from "./styles";
import ViewMeeting from "../ViewMeeting";
import StepTwoContent from "./StepTwoContent";
import TimeTable from "../../../TimeTable";
import ModalButtons from "../../components/ModalButtons";

interface iDayMeeting {
    dayData: { [key: string]: any }[];
    date: string;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    open: boolean;
    stepTwoOnly?: boolean;
}

const DayMeetings = ({
    dayData = [],
    date = "",
    setShowModal,
    stepTwoOnly = false,
    open,
}: iDayMeeting) => {
    const [viewTicketModal, setViewTicketModal] = useState<boolean>(false);
    const [selectedMeeting, setSelectedMeeting] = useState<{
        [key: string]: any;
    } | null>(null);
    const closeModal = () => setShowModal(false);

    if (viewTicketModal)
        return (
            <ViewMeeting
                setViewTicketModal={setViewTicketModal}
                open={true}
                selectedMeeting={selectedMeeting}
                setSelectedMeeting={setSelectedMeeting}
                setShowModal={setShowModal}
            />
        );

    return (
        <DayMeetingModalWrapper width="720px" height="auto" show={open}>
            <div className="heading">
                <h2>{moment(date, "YYYY-MM-DD").format("dddd, Do, MMMM")}</h2>
                <img src={icons.close} alt="Close" onClick={closeModal} />
            </div>

            <div
                className="content"
                style={{
                    overflow: selectedMeeting && !stepTwoOnly ? "initial" : "",
                }}
            >
                {!selectedMeeting && !stepTwoOnly ? (
                    <TimeTable
                        data={dayData}
                        onCardSelect={setSelectedMeeting}
                    />
                ) : (
                    <StepTwoContent
                        selectedMeeting={selectedMeeting}
                        setSelectedMeeting={setSelectedMeeting}
                        setViewTicketModal={setViewTicketModal}
                        setShowModal={setShowModal}
                    />
                )}
            </div>
            {!selectedMeeting ? (
                <ModalButtons>
                    <Button
                        style="purple"
                        btnText="Done"
                        onClick={() => setShowModal(false)}
                    />
                </ModalButtons>
            ) : null}
        </DayMeetingModalWrapper>
    );
};

export default DayMeetings;
