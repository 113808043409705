import styled from "styled-components";
import { theme } from "../../../global/theme";

export const FormWrapper = styled.section``;

interface iSCFormContainerWrapper {
    single?: boolean;
}

export const FormContainerWrapper = styled.div<iSCFormContainerWrapper>`
    margin-bottom: 35px;
    margin: 0 auto;
    ${(props) => (props.single ? `max-width: 636px;` : `flex-grow: 1;`)}

    .content {
        background: ${theme?.colors?.containerBackground};
        border-radius: 0 0 10px 10px;
        padding: 24px 28px;

        > div {
        }
        input {
            height: 30px;
            width: 100%;
        }
        .select {
        }
        input[type="checkbox"] {
            height: 12px;
            width: 12px;
        }
    }
`;

const PersonalBudgetHeadingGap = "20px";

export const PersonalBudgetingWrapper = styled.aside`
    display: flex;
    align-items: flex-start;
    gap: 22px;

    article {
        flex: 1;
        .header {
            height: 40px;
            margin-bottom: 21px;
            display: flex;
            gap: ${PersonalBudgetHeadingGap};

            span,
            h3 {
                display: flex;
                gap: ${PersonalBudgetHeadingGap};
            }
        }

        table {
            width: 100%;
            border-radius: 10px;
        }
    }
`;

export const CreditorInfoWrapper = styled.aside`
    table {
        margin-top: 21px;
        width: 100%;
    }
`;

export const PaymentWrapper = styled.aside`
    button {
        width: 100%;
    }
`;

export const SummaryWrapper = styled.aside`
    display: flex;
    align-items: flex-start;
    gap: 45px;

    section {
        background: ${theme?.colors?.white};
        border-radius: 10px;
        padding: 27px 23px;
        flex: 2;
    }
`;

export const ApplicantsWrapper = styled.aside`
    /* > div:first-child > div {
    flex-grow: 1;
    border: solid red;
  } */
    > div:last-child {
        margin-top: 35px;
        width: 100%;
        max-width: unset;
    }
`;
export const AddressesWrapper = styled.aside`
    display: flex;
    align-items: flex-start;
    gap: 24px;
    > div {
        flex: 1;
    }
`;
