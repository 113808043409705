import React from "react";
import { Outlet } from "react-router-dom";
import useReduxUser from "../../store/hooks/useReduxUser";
import { PageContent } from "../styles";
import { LoginPageWrapper } from "./styles";
import { Navigate } from "react-router-dom";

export interface iLoginDetails {
    email: string;
    password: string;
}
const LoginPage: React.FC = () => {
    const { isSignedIn } = useReduxUser();

    if (isSignedIn) return <Navigate replace to="/" />;

    return (
        <>
            <LoginPageWrapper>
                <PageContent height="20px">
                    <Outlet />
                </PageContent>
            </LoginPageWrapper>
        </>
    );
};

export default LoginPage;
