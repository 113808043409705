import styled from "styled-components";
import { theme } from "../../global/theme";
import { diaryStatus } from "../../global/theme/reuseable/mixins";

const StyledTimeTable = styled.div`
    padding: 12px 0;
    position: relative;

    &[aria-disabled="true"] {
        opacity: 0.6;
        pointer-events: none;
    }

    & .spinner {
    }
`;

export const TimeTableRow = styled.div`
    display: flex;
    gap: 8px;
    position: relative;

    &:last-of-type {
        & > .time-table-row__ctn {
            border-bottom: 0.6px solid ${theme?.colors.shade};
        }
    }

    .time-table-row {
        &__expand {
            position: absolute;
            top: 12px;
            right: 0;
            height: 24px;
            width: 24px;
            font-size: 20px;
            background-color: ${theme?.colors.purple};
            color: white;
            border: none;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
        }

        &__time {
            font-size: 14px;
            text-align: right;
            width: 40px;
            margin-top: -10px;
        }

        &__ctn {
            flex: 1;
            border-top: 0.6px solid ${theme?.colors.shade};
            padding: 12px;
            padding-left: 0;
            padding-right: 30px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            &[aria-expanded="true"] {
                & .time-table-card {
                    &__text {
                        -webkit-line-clamp: initial;
                        -webkit-box-orient: vertical;
                        text-overflow: initial;
                    }
                }
            }
        }
    }
`;

export const TimeTableCard = styled.div<{
    autoHide?: boolean;
    status?: number;
}>`
    border: 1px solid ${theme?.colors.purple};
    ${(props) => diaryStatus(props.status)}
    border-radius: 5px;
    padding: 8px;
    font-size: 12px;
    text-align: left;
    display: grid;
    grid-template-rows: auto 1fr;
    cursor: pointer;

    .time-table-card {
        &__title {
            font-size: 14px;
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 0px 4px;
            margin-bottom: 4px;
        }

        &__handler {
            color: ${theme.colors.darkText};
            font-weight: 500;
        }

        &__case {
            font-weight: 600;
            font-size: 12px;
        }

        &__creditor {
            grid-column: 1/-1;
            font-size: 11px;
            font-weight: 400;
            color: ${theme.colors.text};
        }

        &__text {
            overflow: hidden;
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }
    }

    /* &:hover {
        & .time-table-card {
            &__text {
                white-space: initial;
                text-overflow: initial;
                max-height: none !important;
            }
        }
    } */
`;

export default StyledTimeTable;
