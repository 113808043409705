import styled from "styled-components";
import { theme } from "../../../global/theme";

export const NavItemCtn = styled.div`
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    grid-auto-rows: 0fr;
    transition: all 0.3s auto;
    row-gap: 8px;

    &[aria-expanded="true"] {
        grid-auto-rows: 1fr;
    }

    .nav-item {
        &__link {
            display: flex;
            align-items: center;
            border-radius: 8px;

            & > span {
                padding: 0 10px;
            }
        }

        &__toggler {
            background-color: transparent;
            border: none;
            height: 100%;
            padding: 0 4px;
            margin-left: 8px;

            & > img {
                transition: all 0.2s ease;
            }

            &.expanded {
                & > img {
                    transform: rotate(180deg);
                }
            }
        }
    }
`;

export const NavDropdown = styled.ul`
    list-style: none;
    border-radius: 8px;
    overflow: hidden;
    grid-column: 1/-1;
    height: 100%;

    & .nav-dropdown {
        &__item {
            background-color: rgba(255, 255, 255, 0.2);

            & > a {
                display: inline-block;
                padding: 8px 12px;
                width: 100%;
                height: 100%;
                font-size: 12px;
            }

            &:nth-child(2n + 1) {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
`;
