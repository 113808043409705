import React, { SetStateAction } from "react";
import { StyledSortButton } from "./styles";
import { SortMode, sortModes } from ".";

interface SortButtonProps {
    sortMode?: SortMode;
    setSortMode: React.Dispatch<SetStateAction<SortMode>>;
    sortKey: string;
    currentSortKey: string | null;
    setCurrentSortKey: React.Dispatch<SetStateAction<string | null>>;
}

const SortButton: React.FC<SortButtonProps> = ({
    sortMode = sortModes[0],
    setSortMode,
    sortKey,
    currentSortKey,
    setCurrentSortKey,
}) => {
    const isCurrentSortKey = sortKey === currentSortKey;
    const clickHandler = () => {
        setCurrentSortKey(sortKey);
        setSortMode((prev) => {
            const currentModeIndex = sortModes.indexOf(prev);
            if (currentModeIndex < sortModes.length)
                return sortModes[currentModeIndex + 1] || sortModes[0];
            return sortModes[0];
        });
    };

    return (
        <StyledSortButton
            type="button"
            className="data-table__sort"
            aria-label={sortMode}
            onClick={clickHandler}
            aria-current={sortKey === currentSortKey}
        >
            <span aria-hidden={sortMode === "desc" && isCurrentSortKey}>
                &#9650;
            </span>
            <span aria-hidden={sortMode === "asc" && isCurrentSortKey}>
                &#9660;
            </span>
        </StyledSortButton>
    );
};

export default SortButton;
