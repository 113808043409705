import { AgreementDetailsContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import { formatDateString } from "../../../../global/utils/helpers";

const YearPlusCases: React.FC<AgreementDetailsContainerProps> = ({
    formData,
    onInputChange,
}) => {
    
     //formatDateString(formData?.year_plus_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')

    return (
        <HeaderContainer
            title="12 Month Plus Cases"
            headerStyle="gradientBlue"
            className="year-plus col-2"
        >
            <Input
                type="date"
                label="Date Last Worked:"
                value={formatDateString(formData?.year_plus_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="year_plus_date"
            />
            <Input
                type="text"
                label="Notes:"
                value={`${formData?.year_plus_notes ?? ""}`}
                onChange={onInputChange}
                name="year_plus_notes"
            />
        </HeaderContainer>
    );
};

export default YearPlusCases;
