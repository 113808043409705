import { StyledTableLink } from "./styles";

const OpenCaseClient: React.FC<{ data: { [key: string]: any } }> = ({
    data,
}) => {
    return (
        <StyledTableLink
            to={`/client-case/${
                data?.case_id || data?.["Case Id"] || data?.id || data?.["Id"]
            }/summary`}
            target="_blank"
            // style={{ width: "100%" }}
        >
            Open Case
        </StyledTableLink>
    );
};

export default OpenCaseClient;
