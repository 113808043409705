import React from "react";
import { Route, Routes } from "react-router-dom";
import {
    Document,
    Email,
    Reports,
    Sms,
    Workflows,
} from "../../../subPages/User/Settings/Templates";
import { templateSubLinks } from "./data";
import CreateDocumentTemplate from "../../../subPages/User/Settings/Templates/Documents/CreateDocumentTemplate";
import NavBar from "../../../layout/Header/NavBar";

const Templates: React.FC = () => {
    return (
        <>
            <NavBar subList={templateSubLinks} />
            <Routes>
                <Route path="/sms" element={<Sms />} />
                <Route path="/email" element={<Email />} />
                <Route path="/document" element={<Document />} />
                <Route
                    path="/document/create"
                    element={<CreateDocumentTemplate mode="create" />}
                />
                <Route
                    path="/document/:documentId"
                    element={<CreateDocumentTemplate mode="edit" />}
                />
                <Route
                    path="/reports/*"
                    element={<Reports setHeadingName={() => {}} />}
                />
                <Route path="/workflows" element={<Workflows />} />
            </Routes>
        </>
    );
};

export default Templates;
