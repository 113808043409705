import React, { SetStateAction } from "react";
import StyledFilePageContent from "./styles";
import Header from "../../layout/Header";
import { LoadingSpinner } from "../../components/Button/styles";

interface FilePageContentProps {
    className?: string;
    panelTitle?: string;
    panelOptions: string[];
    optionLabels?: string[];
    panelCounts: number[];
    panelElement?: string | JSX.Element;
    selectedOption: string | null;
    setSelectedOption: React.Dispatch<SetStateAction<string>>;
    headerTitle: string;
    isLoading?: boolean;
    children?: React.ReactNode;
}

const FilePageContent: React.FC<FilePageContentProps> = ({
    className = "",
    panelTitle = "",
    panelElement,
    panelOptions = [],
    optionLabels = [],
    panelCounts = [],
    selectedOption,
    setSelectedOption,
    headerTitle,
    isLoading = false,
    children,
}) => {
    const optionHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { name } = e.currentTarget;
        setSelectedOption((prev) => (prev === name ? "" : name));
    };

    return (
        <StyledFilePageContent
            className={`file-page-content${className ? ` ${className}` : ""}`}
        >
            <div className="file-page-content__panel">
                {!!panelElement && (
                    <div className="file-page-content__panel__ctn">
                        {panelElement}
                    </div>
                )}
                {panelTitle && (
                    <h3 className="file-page-content__panel__title">
                        {panelTitle}
                    </h3>
                )}
                <ul className="file-page-content__panel__options">
                    {panelOptions.map((option, i) => (
                        <li
                            key={i}
                            className="file-page-content__panel__option"
                        >
                            <button
                                onClick={optionHandler}
                                // disabled={!panelCounts?.[i]}
                                name={option}
                                data-selected={option === selectedOption}
                                className="file-page-content__panel__btn"
                            >
                                {optionLabels?.[i] ?? option}
                                {isLoading ? (
                                    <div className="file-page-content__panel__spinner">
                                        <LoadingSpinner size={14} />
                                    </div>
                                ) : (
                                    <span>{panelCounts?.[i] || "-"}</span>
                                )}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <Header
                className="file-page-content__header"
                title={headerTitle}
                hasNavBarAbove
                isSubPath
                hasPrevPath
            />
            <section className="file-page-content__content">
                {isLoading ? (
                    <LoadingSpinner
                        className="file-page-content__content__spinner"
                        size={64}
                    />
                ) : (
                    children
                )}
            </section>
        </StyledFilePageContent>
    );
};

export default FilePageContent;
