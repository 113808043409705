import styled from "styled-components";
import { Column } from "../../../global/theme/reuseable/components";

export const ClientOverview = styled.div`
    & .client-overview {
        &__list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: min(1.5vw, 24px);
            width: 100%;

            &:not(:first-child) {
                margin-top: min(1.5vw, 24px);
            }
        }
    }
`;
