import styled from "styled-components";
import HeaderContainer from "../../../../../containers/HeaderContainter";

const MessageTemplateHeaderContainer = styled(HeaderContainer)`
    flex: 1 1 0;
    overflow-x: hidden;

    & .header-container__ctn {
        display: flex;
        flex-direction: column;
        overflow: auto;
        overflow-x: hidden;
        flex: 1 1 0;
        min-height: 500px;
        /* max-height: calc(100vh - 278px); */
    }

    & .text-editor {
        flex-grow: 1;
    }

    & .message-form-container {
        &__select {
        }

        &__fieldset {
            border: none;
            display: flex;
            gap: 16px;
            margin-bottom: 12px;

            & > p {
                font-size: 14px;
                font-weight: 300;
                flex: 1;
            }
        }

        &__checkbox {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            font-weight: 300;
        }

        &__new {
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: flex-end;
            gap: 12px;
            margin-block: 16px;

            & > button {
                height: 30px;
                padding-inline: 16px;
            }
        }

        &__subject {
            margin-bottom: 16px;
        }

        &__btns {
            margin-top: 16px;
            display: grid;
            grid-template-columns: auto 1fr auto auto;
            gap: 8px;
            & > button {
                padding-inline: 24px;

                &:nth-child(2) {
                    grid-column: 3 / span 1;
                }

                &:nth-child(3) {
                    grid-column: 4 / span 1;
                }
            }
        }
    }

    & .text-editor {
        &__textarea {
            flex: 1 1 0;
        }
    }

    & .se-container {
        flex: 1 1 0;
    }
`;

export default MessageTemplateHeaderContainer;
