import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ClientCorrespondenceState {
    list: {[key: string]: any}
    signables: Array<{[key: string]: any}>
    sms: {[key: string]: any}
    email: {[key: string]: any}
    files: Array<{[key: string]: any}>
}

export const initialClientCorrespondenceState: ClientCorrespondenceState = {
    list: {},
    signables: [],
    sms: {},
    email: {},
    files: []
}

export const clientCorrespondenceSlice = createSlice({
    name: 'client-balanceSheet',
    initialState: initialClientCorrespondenceState,
    reducers: {
        setList: (state, action: PayloadAction<{[key: string]: any}>) => {
            state.list = action.payload
        },
        setSignables: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.signables = action.payload
        },
        setSms: (state, action: PayloadAction<{[key: string]: any}>) => {
            state.sms = action.payload
        },
        setEmail: (state, action: PayloadAction<{[key: string]: any}>) => {
            state.email = action.payload
        },
        setFiles: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.files = action.payload
        },
        resetSlice: () => {
            return initialClientCorrespondenceState
        },
    }
})

export const clientCorrespondenceActions = clientCorrespondenceSlice.actions
export default clientCorrespondenceSlice