export const theme = {
    colors: {
        white: "#FFFFFF",
        white2: "#FBFBFB",
        white3: "#eeeeee",
        background: "#F9F9F9",
        purple: "#583BD8",
        text: "#5E5E5E",
        darkText: "#212121",
        highlightBlue: "#C5DBFF",
        formBlue: "#E5EFFF",
        boxStroke: "#082A52",
        shade: "rgba(8, 42, 82, 0.3)",
        primaryBlue: "#3973f1",
        cancel: "#E8E8E8",
        containerBackground: "#fbfdff",
        error: "#f31616",
        success: "#13c319",
    },
    gradients: {
        burntOrange:
            "-webkit-linear-gradient(156.78deg, #FFC24D 15.32%, #FF7A68 85.25%);",
        yellow: "-webkit-linear-gradient(156.78deg, #facd45 15.32%, #f49339 85.25%);",
        mintGreen:
            "-webkit-linear-gradient(156.78deg, #8CEEDD 15.32%, #1ACDAD 85.25%);",
        dangerRed:
            "-webkit-linear-gradient(124.94deg, #FF7A68 -8.84%, #C8311D 107.02%);",
        skyBlue:
            "-webkit-linear-gradient(156.78deg, #4D9EFF 15.32%, #686FFF 85.25%);",
        buttonBlue:
            "-webkit-linear-gradient(123.76deg, #4D9EFF 4.28%, #686FFF 103.7%);",
        good: "-webkit-linear-gradient(156.78deg, #79E678 15.32%, #2AB952 85.25%);",
    },
    fontFamily: {
        primary: "Poppins",
        secondary: "Arial",
        mono: "'IBM Plex Mono', monospace",
    },
    fontSize: {
        h1: "60px",
        h2: "52px",
        h3: "38px",
        h4: "28px",
        h5: "25px",
    },
    diary: {
        0: {
            border: "#0f72e3",
            background: "#eff7ff",
        },
        1: {
            border: "#dfdfdf",
            background: "#ffffff",
        },
        2: {
            border: "#01d64b",
            background: "#f2fff6",
        },
        3: {
            border: "#e8220c",
            background: "#fff3f2",
        },
    },
};
