import BasicModal from "../../BasicModal";
import { useParams } from "react-router-dom";
import useFetchData from "../../../../global/hooks/useFetchData";
import { CaseActionsModalProps } from "./types";
import useReduxUser from "../../../../store/hooks/useReduxUser";

const WelcomeCallComplete: React.FC<CaseActionsModalProps> = ({
    isOpen,
    onClose,
}) => {
    const { clientId } = useParams();
    const { setClientCaseByKey, clientCase } = useReduxUser();
    const services = clientCase?.services || {};
    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/mark-wlcme-call-complete/${clientId}`,
        },
        {
            onSuccess: (data) => {

                // if (data?.data) {
                //     setClientCaseByKey("services", {
                //         ...services,
                //         ...data.data,
                //     });
                // }
            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData();
    };
    return (
        <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            message="Do you want to mark the welcome call as complete?"
            submitBtnText="Mark As Completed"
            successMessage="The welcome call has been marked as complete."
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSubmit={submitHandler}
        />
    );
};

export default WelcomeCallComplete;
