import { ChangeEventHandler, useId } from "react";
import { StyledCheckbox } from "./styles";

interface CheckboxProps {
    className?: string;
    label: string;
    onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
    checked: boolean;
    name?: string;
    value?: string | number;
    readOnly?: boolean;
    disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
    className = "",
    label,
    onChange = () => {},
    checked,
    name,
    value,
    readOnly,
    disabled,
}) => {
    const id = useId();
    return (
        <StyledCheckbox
            className={`checkbox${className ? ` ${className}` : ""}`}
        >
            <input
                className="checkbox__input"
                type="checkbox"
                onChange={onChange}
                checked={checked}
                name={name}
                value={value}
                readOnly={readOnly}
                disabled={disabled}
                id={`checkbox-${id}`}
            ></input>
            <label className="checkbox__label" htmlFor={`checkbox-${id}`}>
                {label}
            </label>
        </StyledCheckbox>
    );
};

export default Checkbox;
