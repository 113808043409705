import Notiflix from "notiflix";
import React from "react";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import {
    Creditor,
    Diary,
    Introducer,
    Payments,
    Reports,
    Settings,
    UserAdmin,
} from "./pages/UserPages";
import { LoadingSpinner } from "./components/Button/styles";
import {
    BackBook,
    ClientCreate,
    RefundRequests,
    SalesSummary,
} from "./subPages/User/Client";
import ErrorPage from "./pages/ErrorPage";
import LoginPage from "./pages/Login";
import ClientPages from "./pages/ClientPages";
import ClientSummary from "./pages/ClientPages/Summary";
import Search from "./pages/UserPages/Search";
import Templates from "./pages/UserPages/Settings/Templates";
import Services from "./pages/UserPages/Settings/Services";
import SettingsLanding from "./pages/UserPages/Settings/Landing";
import CreditorLanding from "./pages/UserPages/Creditor/CreditorLanding";
import Creditors from "./pages/UserPages/Creditor/Creditors";
import MailShot from "./pages/UserPages/Administration/MailShot";
import MailUpload from "./pages/UserPages/Administration/MailUpload";
import Credit from "./pages/UserPages/Administration/Credit/Credit";
import AdministrationLanding from "./pages/UserPages/Administration/AdministrationLanding";
import Administration from "./pages/UserPages/Administration";
import Report from "./subPages/User/Reports/Report";
import Other from "./pages/UserPages/Settings/Other";
import {
    AccessLogs,
    ApiKeys,
    GroupSettings,
    UserAccounts,
} from "./containers/User";
import AppMain from "./AppMain";
import AdminAccounts from "./pages/UserPages/Accounts/AccountSettings";
import axios from "axios";
import Dashboard from "./pages/UserPages/Dashboard";
import Client from "./pages/UserPages/Client";
import ClientForm from "./subPages/User/Client/ClientCreate/ClientForm";
import ClientLanding from "./subPages/User/Client/Landing";
import EditIntroducer from "./subPages/User/Introducer/EditIntroducer";
import CreditorSettings from "./subPages/User/Creditor";
import MailUploadPreview from "./subPages/User/Administration/MailUploadPreview";
import { pdfjs } from "react-pdf";
import BaseDetails from "./pages/ClientPages/BaseDetails";
import IncomeExpense from "./pages/ClientPages/IncomeExpense";
import Products from "./pages/ClientPages/Products";
import LedgerPayments from "./pages/ClientPages/LedgerPayments";
import BalanceSheet from "./pages/ClientPages/BalanceSheet";
import Correspondence from "./pages/ClientPages/Correspondence";
import Complaints from "./pages/ClientPages/Complaints";
import ClientPortal from "./pages/ClientPages/ClientPortal";
import Management from "./pages/ClientPages/Management";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

declare global {
    interface Window {
        // _mfq: any[];
        // dataLayer: any[];
        predictiveAddress: any;
        data8: any;
    }
}

const App: React.FC = () => {
    Notiflix.Notify.init({
        info: {
            background: "#583BD8",
        },
    });
    Notiflix.Loading.init({
        svgColor: "#583BD8",
        messageFontSize: "24px",
    });
    const token = localStorage.getItem("ClearStartAccessToken");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const crmRoutes = createBrowserRouter([
        {
            path: "/",
            element: <AppMain />,
            errorElement: <ErrorPage />,
            children: [
                {
                    index: true,
                    element: <Dashboard />,
                },
                {
                    path: "search",
                    element: <Search />,
                },
                {
                    path: "client-dashboard",
                    element: <Client />,
                    children: [
                        {
                            index: true,
                            element: <ClientLanding />,
                        },
                        {
                            path: "create",
                            errorElement: <ErrorPage />,
                            children: [
                                {
                                    index: true,
                                    element: <ClientCreate />,
                                },
                                {
                                    path: ":clientId",
                                    element: <ClientForm />,
                                },
                            ],
                        },
                        {
                            path: "sales-summary",
                            element: <SalesSummary />,
                        },
                        {
                            path: "back-book",
                            element: <BackBook />,
                        },
                        {
                            path: "payments",
                            element: <Payments />,
                        },
                        {
                            path: "refunds",
                            element: <RefundRequests />,
                        },
                    ],
                },
                {
                    path: "/administration",
                    element: <Administration />,
                    children: [
                        {
                            path: "",
                            element: <AdministrationLanding />,
                        },
                        {
                            path: "mail-shot",
                            element: <MailShot />,
                        },
                        {
                            path: "mail-upload",
                            children: [
                                {
                                    index: true,
                                    element: <MailUpload />,
                                },
                                {
                                    path: ":uploadId",
                                    element: <MailUploadPreview />,
                                },
                            ],
                        },
                        {
                            path: "credit",
                            element: <Credit />,
                        },
                    ],
                },
                {
                    path: "/creditor",
                    element: <Creditor />,
                    children: [
                        {
                            path: "",
                            element: <CreditorLanding />,
                        },
                        {
                            path: "creditors",
                            element: <Creditors />,
                        },
                        {
                            path: ":creditorId",
                            element: <CreditorSettings />,
                        },
                    ],
                },
                {
                    path: "/diary",
                    element: <Diary />,
                },
                {
                    path: "/introducer",
                    children: [
                        {
                            index: true,
                            element: <Introducer />,
                        },
                        {
                            path: ":introducerId",
                            element: <EditIntroducer />,
                        },
                    ],
                },
                {
                    path: "/payments/*",
                    element: <Payments />,
                    children: [
                        {
                            path: "payment",
                            element: <MailShot />,
                        },
                        {
                            path: "refunds",
                            element: <MailUpload />,
                        },
                        {
                            path: "invoices",
                            element: <Credit />,
                        },
                    ],
                },
                {
                    path: "/reports",
                    children: [
                        {
                            index: true,
                            element: <Reports />,
                        },
                        {
                            path: ":reportId",
                            element: <Report />,
                        },
                    ],
                },

                {
                    path: "/settings/*",
                    element: <Settings />,
                    children: [
                        {
                            path: "",
                            element: <SettingsLanding />,
                        },
                        {
                            path: "templates/*",
                            element: <Templates />,
                        },
                        {
                            path: "services/*",
                            element: <Services />,
                        },
                        {
                            path: "other/*",
                            element: <Other />,
                        },
                    ],
                },
                {
                    path: "/admin",
                    element: <UserAdmin />,
                    errorElement: <Navigate to="/admin" />,
                    children: [
                        {
                            index: true,
                            element: <UserAccounts />,
                        },
                        {
                            path: "group-settings",
                            element: <GroupSettings />,
                        },
                        {
                            path: "access-logs",
                            element: <AccessLogs />,
                        },
                        {
                            path: "api-keys",
                            element: <ApiKeys />,
                        },
                    ],
                },
                {
                    path: "/admin/:userId",
                    element: <AdminAccounts />,
                    errorElement: <Navigate to="/admin" />,
                },
                {
                    path: "/login",
                    element: <LoginPage />,
                    children: [
                        {
                            index: true,
                            element: <Login />,
                        },
                        {
                            path: "forgot",
                            element: <ForgotPassword />,
                        },
                    ],
                },
                // CLIENT ROUTES
                {
                    path: "/client-case/:clientId",
                    element: <ClientPages />,
                    children: [
                        {
                            path: "summary",
                            element: <ClientSummary />,
                        },
                        {
                            index: true,
                            element: <Navigate to="summary" />,
                        },
                        {
                            path: "base_details/*",
                            element: <BaseDetails />,
                        },
                        {
                            path: "income-expense",
                            element: <IncomeExpense />,
                        },
                        {
                            path: "products/*",
                            element: <Products />,
                        },
                        {
                            path: "ledger-payments/*",
                            element: <LedgerPayments />,
                        },
                        {
                            path: "correspondence/*",
                            element: <Correspondence />,
                        },
                        {
                            path: "balance-sheet",
                            element: <BalanceSheet />,
                        },
                        {
                            path: "diary",
                            element: <Diary isClientCase />,
                        },
                        {
                            path: "complaints-outcome",
                            element: <Complaints />,
                        },
                        {
                            path: "client-portal",
                            element: <ClientPortal />,
                        },
                        {
                            path: "management/*",
                            element: <Management />,
                        },
                    ],
                },
            ],
        },
    ]);

    return (
        <RouterProvider
            router={crmRoutes}
            fallbackElement={
                <div>
                    <LoadingSpinner color="red" size={500} />
                    Loading
                </div>
            }
        />
    );
};

export default App;
