import React, { SetStateAction, useEffect } from "react";
import { Input } from "../../../../../components";
import { Row } from "../../../../../global/theme/reuseable/components";
import DropdownSelect from "../../../../../components/DropdownSelect";
import {
    martialStatusOptions,
    partnerUnawareOptions,
} from "../../../../../components/DropdownSelect/options";
import { OtherFormContainer } from "./styles";

interface OtherProps {
    data: { [key: string]: any } | null;
    setData: React.Dispatch<SetStateAction<{ [key: string]: any } | null>>;
}

const Other: React.FC<OtherProps> = ({ data, setData }) => {
    useEffect(() => {
        const dependantsAges = (data?.ages_of_dependants || "")
            .split(/[^0-9+]/)
            .filter((str: string) => str !== "");
        const adultDependantsAges = dependantsAges.filter(
            (age: string) => Number(age) >= 18
        );
        const dependantsCount = dependantsAges?.length || 0;
        const adultDependantsCount = adultDependantsAges?.length || 0;
        setData((prev) => {
            return {
                ...prev,
                no_dependants: dependantsCount,
                no_adult_dependants: adultDependantsCount,
            };
        });
    }, [data?.ages_of_dependants]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue =
            e.target.name === "ages_of_dependants"
                ? e.target.value
                : +e.target.value;
        setData((prev) => {
            return { ...prev, [e.target.name]: targetValue };
        });
    };

    const handleSelect = (key: string, value: any) => {
        setData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const handleAges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = e.target.value
            .replace(/[^0-9\\/]/, "")
            .replace(/\/{2,}/, "/");
        setData((prev) => {
            return { ...prev, [e.target.name]: formattedValue };
        });
    };

    const handleAgesBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = e.target.value
            .replace(/\/+/, "/")
            .replace(/^\/+|\/+$/, "");
        setData((prev) => {
            return { ...prev, [e.target.name]: formattedValue };
        });
    };

    return (
        <OtherFormContainer title="Other" headerStyle="gradientBlue">
            <Row>
                <DropdownSelect
                    label="Marital Status"
                    options={martialStatusOptions}
                    value={data?.marital_status}
                    onSelect={(data) =>
                        handleSelect("marital_status", data.value)
                    }
                />
                <DropdownSelect
                    label="Partner Unaware"
                    options={partnerUnawareOptions}
                    value={data?.partner_unware}
                    onSelect={(data) =>
                        handleSelect("partner_unware", data.value)
                    }
                />
            </Row>
            <Row>
                <Input
                    label="Number of dependencies"
                    type="number"
                    name="no_dependants"
                    value={data?.no_dependants ? +data?.no_dependants : 0}
                    onChange={handleChange}
                />
                <Input
                    label="Adult Dependants"
                    type="number"
                    name="no_adult_dependants"
                    value={
                        data?.no_adult_dependants
                            ? +data?.no_adult_dependants
                            : 0
                    }
                    onChange={handleChange}
                />
                <Input
                    label="Ages of Dependants (e.g. 23/40/12)"
                    type="text"
                    name="ages_of_dependants"
                    value={data?.ages_of_dependants || ""}
                    onChange={handleAges}
                    onBlur={handleAgesBlur}
                />
            </Row>
        </OtherFormContainer>
    );
};

export default Other;
