import Button from "../../Button";
import { StyledModalButtons } from "./styles";

interface ModalButtonsProps {
    className?: string;
    children?: React.ReactNode;
    onDelete?: (...args: any[]) => void;
    deleteBtnDisabled?: boolean;
}

const ModalButtons: React.FC<ModalButtonsProps> = ({
    className = "",
    children = <></>,
    onDelete,
    deleteBtnDisabled = false,
}) => {
    return (
        <StyledModalButtons
            className={`${className ? ` ${className}` : ""} modal-btns`}
        >
            {onDelete && (
                <div className="modal-btns__delete-wrapper">
                    <Button
                        className="modal-btns__delete"
                        style="red"
                        type="button"
                        btnText="Delete"
                        onClick={onDelete}
                        disabled={deleteBtnDisabled}
                    />
                </div>
            )}

            {children}
        </StyledModalButtons>
    );
};
export default ModalButtons;
