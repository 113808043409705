import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal } from "../../../components";
import { StyledMessageContent } from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxClientCorrespondence from "../../../store/hooks/useReduxClientCorrespondence";
import MessageViewer from "../../../containers/MessageViewer";
import CreateCorresSMS from "../../../components/Modals/Correspondence/CreateCorresSMS";

const Sms = () => {
    const { sms, setSms } = useReduxClientCorrespondence();
    const { clientId } = useParams();
    const [isComposing, setIsComposing] = useState(false);
    const [selectedOption, setSelectedOption] = useState("inbox");
    // const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const inboxData: Array<{ [key: string]: any }> = sms?.inbox || [];
    const unreadData = inboxData.filter((data) => !data?.is_unread);
    const sentData: Array<{ [key: string]: any }> = sms?.sent || [];

    const currentData = () => {
        if (selectedOption === "inbox") return inboxData;
        if (selectedOption === "unread") return unreadData;
        if (selectedOption === "sent") return sentData;
        return [];
    };

    const getSmsData = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/correspondence-sms/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) {
                    setSms(data.data);
                    // setSelectedIds([]);
                }
            },
        }
    );

    return (
        <>
            <Modal isVisible={isComposing}>
                <CreateCorresSMS
                    isOpen={isComposing}
                    onClose={() => setIsComposing(false)}
                    isCreatingOnList={false}
                />
            </Modal>
            <StyledMessageContent
                className="corres--sms"
                panelCounts={[
                    inboxData.length,
                    unreadData.length,
                    sentData.length,
                ]}
                panelOptions={["inbox", "unread", "sent"]}
                panelElement={
                    <Button
                        style="purple"
                        onClick={() => setIsComposing(true)}
                        btnText="Compose"
                    />
                }
                headerTitle="Sms"
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                isLoading={getSmsData.isLoading}
            >
                <MessageViewer
                    key={selectedOption}
                    data={currentData()}
                    // selectedIds={selectedIds}
                    // setSelectedIds={setSelectedIds}

                    messageGroup={selectedOption}
                    dataPerPage={10}
                    messageType="sms"
                ></MessageViewer>
            </StyledMessageContent>
        </>
    );
};

export default Sms;
