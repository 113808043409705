import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useFetchData from "../../global/hooks/useFetchData";
import { Modal, Button } from "..";
import AddCardDetails from "../Modals/ClientCreate/AddCardDetails";
import useReduxCreateClient from "../../store/hooks/useReduxCreateClient";
import StyledCardDetails from "./styles";

interface CardDetailsProps {
    redirectTo?: string;
}

const CardDetails = ({ redirectTo }: CardDetailsProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { clientId } = useParams();
    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get("id");
    const resourcePath = searchParams.get("resourcePath");
    const getCasePayments = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/get-case-payments/${clientId}`,
        },
        {
            fetchOnLoad: !id,
        }
    );

    const sendId = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/successApi`,
            params: {
                id,
                resourcePath,
            },
        },
        {
            onSuccess: (data) => {
                navigate(pathname);
                // setTimeout(() => getCasePayments.fetchData(), 50);
            },
        }
    );
    const cardsData = sendId?.data?.data?.card_details?.length
        ? sendId?.data?.data?.card_details
        : getCasePayments?.data?.data?.card_details || [];

    useEffect(() => {
        if (!!id) {
            console.log(id);
            sendId.fetchData();
        }
    }, []);

    return (
        <>
            <Modal isVisible={isModalOpen}>
                <AddCardDetails
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    redirectTo={redirectTo}
                />
            </Modal>
            <StyledCardDetails className="card-details">
                {cardsData?.length ? (
                    <ul className="card-details__cards">
                        {cardsData.map((card: { [key: string]: any }) => (
                            <li key={card?.id} className="card-details__card">
                                <p>
                                    Name: <span>{card?.nme || "--"}</span>
                                </p>
                                <p>
                                    Type: <span>{card?.type || "--"}</span>
                                </p>
                                <p>
                                    Expired End:{" "}
                                    <span>{`${card?.expry_m}/${card?.expry_y}`}</span>
                                </p>
                                <p>
                                    Last digits:{" "}
                                    <span>{card?.num || "---"}</span>
                                </p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="card-details__empty">No Card Created</p>
                )}
                <Button
                    btnText="Add Card Details"
                    onClick={() => setIsModalOpen(true)}
                    style="purple"
                    type="button"
                />
            </StyledCardDetails>
        </>
    );
};

export default CardDetails;
