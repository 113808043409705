import React, { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Input } from "../../../../components";
import { EditWrapper, StyledAdminEdit } from "./styles";
import useReduxAccounts from "../../../../store/hooks/useReduxAccounts";
import DropdownSelect from "../../../../components/DropdownSelect";
import useFetchData from "../../../../global/hooks/useFetchData";
import { Notify } from "notiflix";

const Edit: React.FC = () => {
    const { userData } = useReduxAccounts();
    const { userId } = useParams();
    const [basicInfo, setBasicInfo] = useState<{ [key: string]: any }>({
        name: userData?.users?.name || "",
        job_title_id: userData?.users?.job_title_id ?? null,
        ddi: userData?.users?.ddi || "",
        phone_line: userData?.users?.phone_line || "",
        email: userData?.users?.email || "",
        password: "",
        dprtmnt: userData?.users?.dprtmnt ?? null,
        type: userData?.users?.type ?? null,
        access_group: userData?.users?.name || "",
        disabled: userData?.users?.disabled ?? null,
        ipaddress: userData?.users?.ipaddress || "",
    });

    const disabled = [
        { id: 0, name: "No" },
        { id: 1, name: "Yes" },
    ];
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setBasicInfo((prev) => {
            return { ...prev, [name]: value };
        });
    };
    const handleSelect = (key: string, value: unknown) => {
        setBasicInfo((prev) => {
            return { ...prev, [key]: value };
        });
    };
    const updateDet = useFetchData(
        {
            method: "PUT",
            url: `${process.env.REACT_APP_SERVER_API}/admin/${userId}`,
        },
        {
            onSuccess: (data) => {
                Notify.success("Updated Successfully");
            },
        }
    );
    const handleFormSubmit = async (e: any) => {
        e.preventDefault();
        updateDet.fetchData(basicInfo);
    };

    return (
        <StyledAdminEdit>
            <EditWrapper>
                <h2 id="basic-info-heading">Basic Information</h2>
                <div>
                    <Input
                        label="Name:"
                        name="name"
                        onChange={handleChange}
                        value={basicInfo?.name || ""}
                        type="text"
                    />
                    {/* <Input
                        label="Job Role:"
                        name="job_title"
                        onChange={handleChange}
                        value={basicInfo.job_title}
                        type="text"
                    /> */}
                    <DropdownSelect
                        label="Job Role:"
                        options={userData?.job_titles}
                        value={basicInfo.job_title_id}
                        labelKeys={["name"]}
                        name="job_title_id"
                        optionKey="id"
                        onSelect={(option) =>
                            handleSelect("job_title_id", option.id)
                        }
                        size="m"
                    />
                    <Input
                        label="DDI:"
                        name="ddi"
                        onChange={handleChange}
                        value={basicInfo.ddi}
                        type="text"
                    />
                    <Input
                        label="Phone Line(EXN):"
                        name="phone_line"
                        onChange={handleChange}
                        value={basicInfo.phone_line}
                        type="tel"
                        gap="5px"
                    />
                    <Input
                        label="Email Address:"
                        name="email"
                        onChange={handleChange}
                        value={basicInfo.email}
                        type="email"
                    />
                    {/* <Input
                        label="password:"
                        name="password"
                        onChange={handleChange}
                        value={basicInfo.password}
                        type="password"
                        hasShowPassword
                        required
                    /> */}
                </div>
            </EditWrapper>
            <EditWrapper>
                <h2 id="user-settings-heading">User Settings</h2>

                <div>
                    <DropdownSelect
                        label="Department:"
                        options={userData?.department}
                        value={basicInfo.dprtmnt}
                        labelKeys={["name"]}
                        name="dprtmnt"
                        optionKey="id"
                        onSelect={(option) =>
                            handleSelect("dprtmnt", option.id)
                        }
                        size="m"
                    />
                    <DropdownSelect
                        label="User Type:"
                        options={userData?.types}
                        value={basicInfo.type}
                        labelKeys={["name"]}
                        optionKey="id"
                        name="type"
                        onSelect={(option) => handleSelect("type", option.id)}
                        size="m"
                    />
                    <DropdownSelect
                        label="Access Group:"
                        options={userData?.job_roles || []}
                        labelKeys={["name"]}
                        optionKey="id"
                        onSelect={(option) =>
                            handleSelect("access_group", option.id)
                        }
                        size="m"
                    />
                    <DropdownSelect
                        label="Disabled:"
                        options={disabled}
                        name="disabled"
                        value={basicInfo.disabled}
                        labelKeys={["name"]}
                        optionKey="id"
                        onSelect={(option) =>
                            handleSelect("disabled", option.value)
                        }
                        size="m"
                    />
                </div>
            </EditWrapper>
            <EditWrapper>
                <h2 id="other-information-heading">Other Information</h2>
                <div>
                    <Input
                        label="IP Address:"
                        name="ipaddress"
                        onChange={handleChange}
                        placeholder="e.g. 123.12.123.123"
                        value={basicInfo.ipaddress}
                        type="text"
                    />
                    <Button
                        btnText="SUBMIT"
                        onClick={handleFormSubmit}
                        style="mint"
                    />
                </div>
            </EditWrapper>
        </StyledAdminEdit>
    );
};

export default Edit;
