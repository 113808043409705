import React, { useState, useLayoutEffect, useEffect } from "react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import useReduxUser from "../../store/hooks/useReduxUser";
import { isEqual } from "lodash";

export interface UseFetchdataOptions {
    fetchOnLoad?: boolean;
    onSuccess?: (data: AxiosResponse["data"]) => void;
    onError?: (error: AxiosError | null) => void;
    requiresToken?: boolean;
    notifyOnError?: boolean;
    notifyOnSuccess?: boolean;
    notifySuccessMessage?: string;
    navigateOnSuccess?: string;
    params?: { [key: string]: string | number };
}

export const initialOptions: UseFetchdataOptions = {
    fetchOnLoad: false,
    requiresToken: true,
    notifyOnError: true,
    notifyOnSuccess: false,
};

const useFetchData = (
    config: AxiosRequestConfig,
    options: UseFetchdataOptions = initialOptions
) => {
    const navigate = useNavigate();
    const { logoutUser } = useReduxUser();
    const [data, setData] = useState<AxiosResponse["data"] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const createParamString = Object.entries(options?.params || {})
        .map(([key, value], i) => `${i === 0 ? "?" : "&"}${key}=${value}`)
        .join("");

    const {
        fetchOnLoad,
        onSuccess,
        onError,
        requiresToken,
        notifyOnError,
        notifyOnSuccess,
        notifySuccessMessage,
        navigateOnSuccess,
    } = {
        ...initialOptions,
        ...options,
    };

    const fetchData = async (
        data?: any,
        aConfig: AxiosRequestConfig = config
    ) => {
        try {
            setIsSuccess(false);
            setIsLoading(true);
            let axiosConfig = {
                ...aConfig,
                data,
                url: `${config.url}${createParamString}`,
            };
            const url = new URL(axiosConfig?.url);
            const requestName = `${axiosConfig?.method?.toUpperCase()} ${
                url?.pathname
            }:`;
            const token = localStorage.getItem("ClearStartAccessToken");
            if (requiresToken && token)
                axiosConfig.headers = {
                    ...axiosConfig.headers,
                    Authorization: `Bearer ${token}`,
                };

            const response = await axios(axiosConfig);
            if (response.data) {
                console.log(requestName, response.data);
                setIsSuccess(true);
                setData(response.data);
                setError(null);
                if (onSuccess) onSuccess(response.data);
                if (notifyOnSuccess)
                    Notify.success(
                        notifySuccessMessage ??
                            (response.data.message ||
                                "The request was successful.")
                    );
                if (navigateOnSuccess) navigate(navigateOnSuccess);
            }
        } catch (error: any) {
            const axiosError = error as AxiosError;
            const errorMessage = error?.response?.data?.message;
            const errCode = error?.response?.status;
            console.log(error);
            setError(error);
            if (onError) onError(axiosError);
            if (notifyOnError)
                Notify.failure(errorMessage || "Something went wrong");
            if (errCode === 401) logoutUser();
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    useLayoutEffect(() => {
        if (fetchOnLoad && !data) fetchData();
    }, [fetchOnLoad, data]);

    return {
        data,
        setData,
        isLoading,
        setIsLoading,
        isSuccess,
        setIsSuccess,
        error,
        setError,
        fetchData,
    };
};

export default useFetchData;
