import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../../layout/Header";
import StyledAgreementDetailsContent from "./styles";
import PlanDetails from "./PlanDetails";
import useFetchData from "../../../../global/hooks/useFetchData";
import OtherDetails from "./OtherDetails";
import {
    cardCapturedOptions,
    paymentDayOptions,
    paymentFrequencyOptions,
    paymentMethodOptions,
} from "../../../../components/DropdownSelect/options";
import PaymentDetails from "./PaymentDetails";
import SetupFeePayments from "./SetupFeePayments";
import CCR from "./CCR";
import UtilityWarehouseRef from "./UtilityWarehouseRef";
import YearPlusCases from "./YearPlusCase";
import IVAReview from "./IVAReview";
import { StyledBaseDetailsSubmitCtn } from "../styles";
import { Button } from "../../../../components";
import SecurityPassword from "./SecurityPassword";
import BackOffice from "./BackOffice";

export interface AgreementDetailsContainerProps {
    formData: { [key: string]: any };
    onSelect: (key: string, value: any) => void;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handlerList: { [key: string]: any };
}

const AgreementDetails = () => {
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        service_id: null,
        plan_type_id: null,
        plan_duration: 0,
        accountancy_fee: 0,
        payment_in: 0,
        client_distributions: 0,
        security_password: "",
        lead_source_id: null,
        case_handler_id: null,
        customer_card_handler_id: null,
        lead_details: "",
        standing_order_sent: "",
        next_payment_date: "",
        payment_frequency:'',
        payment_day:'',
        payment_method:'',
        card_captured: '',
        setup_fee_1_amount: 0,
        setup_fee_1_taken: "",
        setup_fee_2_amount: 0,
        setup_fee_2_taken: "",
        cc_fee_1_amount: 0,
        cc_fee_1_taken: "",
        cc_fee_2_amount: 0,
        cc_fee_2_taken: "",
        cc_fee_3_amount: 0,
        cc_fee_3_taken: "",
        r1_fee_amount: 0,
        r1_fee_taken: "",
        r2_fee_amount: 0,
        r2_fee_taken: "",
        back_office_agent_id: null,
        back_office_date: "",
        back_office_notes: "",
        ccr_filp_agent_id: null,
        ccr_filp_date: "",
        ccr_filp_amount: 0,
        ccr_filp_notes: "",
        ccr_migrated_agent_id: null,
        ccr_migrated_date: "",
        uwr_agent_id: null,
        uwr_date: "",
        year_plus_date: "",
        year_plus_notes: "",
        iva_review_date: "",
        iva_review_notes: "",
    });
    const { clientId } = useParams();
    const getHandlerList = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/get-agreement-details/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                setFormData(data?.data?.agreement_details);
            },
        }
    );
    const handlerList = getHandlerList?.data?.data || [];
    const updateAgreement = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/update-agreement-details/${clientId}`,
    },
    {
        onSuccess: (data) => {
            setFormData(data?.data?.agreement_details);
        },
    });

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = e.target;
        setFormData((prev) => {
            return { ...prev, [name]: type === "number" ? +value : value };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setFormData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const containerProps = {
        formData,
        onSelect: selectHandler,
        onInputChange: inputHandler,
        handlerList,
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        updateAgreement.fetchData({data:formData});
    };

    return (
        <>
            <Header title="Agreement Details" hasNavBarAbove hasPrevPath />
            <StyledAgreementDetailsContent>
                <form onSubmit={submitHandler}>
                    <PlanDetails {...containerProps} />
                    <SecurityPassword {...containerProps} />
                    <OtherDetails {...containerProps} />
                    <PaymentDetails {...containerProps} />
                    <SetupFeePayments {...containerProps} />
                    <BackOffice {...containerProps} />
                    <CCR {...containerProps} />
                    <UtilityWarehouseRef {...containerProps} />
                    <YearPlusCases {...containerProps} />
                    <IVAReview {...containerProps} />
                    <StyledBaseDetailsSubmitCtn>
                        <Button
                            type="submit"
                            btnText="Save Agreement"
                            style="purple"
                            loading={updateAgreement.isLoading}
                        />
                    </StyledBaseDetailsSubmitCtn>
                </form>
            </StyledAgreementDetailsContent>
        </>
    );
};

export default AgreementDetails;
