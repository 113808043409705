import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    ContinueClientModal,
    CreateClientCard,
    ModalContainer,
} from "../../../../components";
import { Row } from "../../../../global/theme/reuseable/components";
import Header from "../../../../layout/Header";
import { PageContent } from "../../../../pages/styles";
import useFetchData from "../../../../global/hooks/useFetchData";

const ClientCreate = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const { fetchData, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client`,
        },
        {
            onSuccess: (data) => {
                if (data?.data?.id) navigate(`${data?.data?.id}`);
            },
        }
    );

    const createClientHandler = () => {
        fetchData();
    };
    return (
        <>
            <ModalContainer
                isVisible={isModalOpen}
                children={
                    <ContinueClientModal
                        setContinueClient={setIsModalOpen}
                        open={isModalOpen}
                    />
                }
            />
            <Header
                title="create client"
                prevPath="/client-dashboard"
                isSubPath={false}
            />
            <PageContent>
                <Row gap="25px;" justify-content="center">
                    <CreateClientCard
                        title="New Client"
                        onClick={createClientHandler}
                        isLoading={isLoading}
                    />
                    <CreateClientCard
                        title="Continue Client"
                        onClick={() => setIsModalOpen(true)}
                    />
                </Row>
            </PageContent>
        </>
    );
};

export default ClientCreate;
