import React from "react";
import { useAppSelector, useAppDispatch } from ".";
import { dashboardActions } from "../slices/dashboardSlice";
import { DashboardState } from "../slices/dashboardSlice";

const useReduxDashboard = () => {
    const { leftColumnData, rightColumnData } = useAppSelector(
        (state) => state.dashboard
    );
    const dispatch = useAppDispatch();

    const setLeftColumnData = (data: { [key: string]: any }[]) => {
        dispatch(dashboardActions.setLeftColumnData(data));
    };

    const setRightColumnData = (data: { [key: string]: any } | null) => {
        dispatch(dashboardActions.setRightColumnData(data));
    };

    return {
        leftColumnData,
        rightColumnData,
        setLeftColumnData,
        setRightColumnData,
    };
};

export default useReduxDashboard;
