import React, { useEffect } from "react";
import {
    Button,
    CreateReportModal,
    DiscardReportModal,
    ModalContainer,
    SaveReportModal,
} from "../../../../components";
import { ComposeReportWrapper } from "./styles";
import TablePreview from "../../../../components/TablePreview";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";
import useUndoableState from "../../../../global/hooks/useUndoableState";
import { icons } from "../../../../assets/icons";
import { initialReportTemplate } from "../../../../store/slices/settingsSlice";

const NewReport = ({ reportModal, setReportModal }: any) => {
    const { report, setReport, setReportColumns } = useReduxSettings();
    const initColumns = report?.columns || [];
    const initColumnsNamesStr = initColumns
        .map((col: { [key: string]: any }) => col?.column_id)
        .join(" ");
    const columns =
        useUndoableState<Array<{ [key: string]: any }>>(initColumns);

    useEffect(() => {
        return () => setReport(initialReportTemplate);
    }, []);

    useEffect(() => {
        setReportColumns(columns.state);
    }, [columns.state]);

    useEffect(() => {
        columns.resetState(initColumns);
    }, [initColumnsNamesStr]);

    return (
        <>
            <ModalContainer
                isVisible={reportModal === "Discard"}
                children={
                    <DiscardReportModal
                        setReportModal={setReportModal}
                        open={reportModal === "Discard"}
                    />
                }
            />
            <ModalContainer
                isVisible={reportModal === "Save"}
                children={
                    <SaveReportModal
                        setReportModal={setReportModal}
                        open={reportModal === "Save"}
                        columns={columns}
                    />
                }
            />
            <ModalContainer
                isVisible={reportModal === "Edit"}
                children={
                    <CreateReportModal
                        modalHandler={setReportModal}
                        isOpen={reportModal === "Edit"}
                        mode="edit"
                    />
                }
            />
            <ComposeReportWrapper>
                <div className="nav">
                    <div className="step-control">
                        <Button
                            btnImg={icons.undo}
                            onClick={() => columns.goBack()}
                            disabled={columns.index <= 0}
                            style="purple"
                        />
                        <Button
                            btnImg={icons.redo}
                            onClick={() => columns.goForward()}
                            style="purple"
                            disabled={columns.index >= columns.lastIndex}
                        />
                    </div>
                    <div className="modal-controls">
                        <Button
                            btnText="Discard/Clear"
                            onClick={() => setReportModal("Discard")}
                            style="cancel"
                        />
                        <Button
                            btnText="Edit Table"
                            onClick={() => setReportModal("Edit")}
                            style="purple"
                        />
                        <Button
                            btnText="Save Report Template"
                            onClick={() => setReportModal("Save")}
                            style="mint"
                        />
                    </div>
                </div>
                <TablePreview
                    columns={columns.state}
                    setColumns={columns.setState}
                    id="new-report-preview"
                    objectKey="column_id"
                    headingKey="column_name"
                />
            </ComposeReportWrapper>
        </>
    );
};

export default NewReport;
