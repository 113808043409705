import { KeyEventsContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import DropdownSelect from "../../../../components/DropdownSelect";
import { clientCaseSubStatusOptions } from "../../../../components/DropdownSelect/options";
import { formatDateString } from "../../../../global/utils/helpers";

const Administration: React.FC<KeyEventsContainerProps> = ({
    formData,
    onInputChange,
    onSelect,
}) => {
    
    //formatDateString(formData?.cancel_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')
    return (
        <HeaderContainer
            title="Administration"
            headerStyle="gradientBlue"
            className="admin"
        >
            <Input
                type="date"
                label="Standing Order Sent:"
                value={formatDateString(formData?.standing_order_sent || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="standing_order_sent"
            />
            <Input
                type="date"
                label="Welcome Call Due:"
                value={formatDateString(formData?.welcome_call_due || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="welcome_call_due"
            />
            <Input
                type="date"
                label="Welcome Call Completed:"
                value={formatDateString(formData?.welcome_call_completed || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="welcome_call_completed"
            />
            <Input
                type="date"
                label="In Collections:"
                value={formatDateString(formData?.in_collection_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="in_collection_date"
            />
            <Input
                type="date"
                label="Cancelled:"
                value={formatDateString(formData?.cancel_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="cancel_date"
            />
            <DropdownSelect
                label="Cancel Status:"
                options={clientCaseSubStatusOptions}
                onSelect={(option) =>
                    onSelect("cancel_status", option.value ?? null)
                }
                hasDefaultValue
            />
        </HeaderContainer>
    );
};

export default Administration;
