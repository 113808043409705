import React, { useState } from "react";
import { icons } from "../../assets/icons";
import { LogoWrapper } from "./styles";

const Logo = () => {
  return (
    <LogoWrapper>
      <img src={icons?.csLogo} alt="Clear Start" />
      <span></span>
    </LogoWrapper>
  );
};

export default Logo;
