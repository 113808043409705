import DropdownSelect from "../../../DropdownSelect";
import {
    creditorStatusOptions,
    preferCorMethodOptions,
} from "../../../DropdownSelect/options";
import TextArea from "../../../Input/TextArea";
import { StepThreeWrapper } from "./styles";
import { iStepThree } from "./types";

const StepThree = ({ handleChange, handleSelect, newCreditor }: iStepThree) => {
    return (
        <StepThreeWrapper>
            <DropdownSelect
                label="Preferred Correspondence Method:"
                value={newCreditor?.correspondenceMethod ?? null}
                options={preferCorMethodOptions}
                onSelect={(option) =>
                    handleSelect("correspondenceMethod", option.value)
                }
            />
            <DropdownSelect
                label="Status:"
                value={newCreditor?.status ?? null}
                options={creditorStatusOptions}
                onSelect={(option) => handleSelect("status", option.value)}
            />
            <TextArea
                name="notes"
                onChange={handleChange}
                label="Notes:"
                gap="13px"
            />
        </StepThreeWrapper>
    );
};

export default StepThree;
