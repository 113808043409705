import styled from "styled-components";
import HeaderContainer from "../../../../../containers/HeaderContainter";

export const StyledAddressCtn = styled(HeaderContainer)`
    flex: 1;

    & .header-container {
        &__ctn {
            display: grid;
            grid-template-columns: 120px 1fr;
            gap: 12px 16px;

            & > *:last-child {
                grid-column: 1/-1;
            }
        }
    }
`;

const StyledAddress = styled.form`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    width: min(100%, 1600px);
    margin-inline: auto;
    justify-content: center;

    & .header-container {
        max-width: 800px;
    }
`;

export default StyledAddress;
