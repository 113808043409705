import { SetStateAction, useState, useRef, useCallback, useId } from "react";
import { ReactComponent as DeleteIcon } from "../../assets/icons/whiteX.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/purpleLeftArrow.svg";
import StyledTablePreview from "./styles";
import useClickOutside from "../../global/hooks/useClickOutside";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from "react-beautiful-dnd";
import { theme } from "../../global/theme";
import useReduxSettings from "../../store/hooks/useReduxSettings";

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    borderColor: isDragging ? theme.colors.purple : "",
    ...draggableStyle,
});

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

interface TablePerviewProps {
    columns: Array<{ [key: string]: any }>;
    setColumns: (col: Array<{ [key: string]: any }>) => void;
    objectKey?: string;
    headingKey?: string;
    id?: string;
}

const TablePreview: React.FC<TablePerviewProps> = ({
    columns,
    setColumns,
    objectKey,
    headingKey,
    id = "table-preview",
}) => {
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [isDragging, setIsDragging] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    useClickOutside(ref, () => setSelectedIndex(null));

    const deleteHandler = (index: number) => {
        setSelectedIndex(null);
        setColumns(columns.filter((col, i) => i !== index));
    };

    const moveColumnHandler = (
        index: number,
        direction: "right" | "left" = "left"
    ) => {
        const columnsCopy = [...columns];
        const extractedCol = columnsCopy.splice(index, 1);
        const offset = direction === "left" ? -1 : 1;
        columnsCopy.splice(index + offset, 0, ...extractedCol);
        setColumns(columnsCopy);
        setSelectedIndex((prev) =>
            typeof prev === "number" ? prev + offset : prev
        );
    };

    const onDragStart = useCallback(
        (context: any) => {
            setIsDragging(true);
            const index = context?.source?.index;
            if (typeof index === "number") setSelectedIndex(index);
        },
        [columns]
    );

    const onDragEnd = useCallback(
        (result: DropResult) => {
            // dropped outside the list
            if (!result.destination) {
                return;
            }

            const newItems = reorder(
                columns,
                result.source.index,
                result.destination.index
            );
            setIsDragging(false);

            setColumns(newItems);
            setSelectedIndex(result.destination.index);
        },
        [columns]
    );

    const columnKey = (col: string | { [key: string]: any }, i: number) => {
        return typeof col === "string"
            ? col
            : col[objectKey || "id"].toString() || i.toString();
    };

    return (
        <StyledTablePreview className="table-preview" ref={ref}>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId={id.toString()} direction="horizontal">
                    {(provided, snapshot) => (
                        <div
                            className="table-preview__table"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {columns.map((col, i) => (
                                <Draggable
                                    key={columnKey(col, i)}
                                    draggableId={columnKey(col, i)}
                                    index={i}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            className="table-preview__column"
                                            key={columnKey(col, i)}
                                            aria-selected={i === selectedIndex}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {i === selectedIndex &&
                                                !isDragging && (
                                                    <div className="table-preview__controls">
                                                        <button
                                                            type="button"
                                                            className="table-preview__controls__btn table-preview__controls__delete"
                                                            onClick={deleteHandler.bind(
                                                                null,
                                                                i
                                                            )}
                                                        >
                                                            <DeleteIcon />
                                                        </button>
                                                        {i !== 0 && (
                                                            <button
                                                                type="button"
                                                                className="table-preview__controls__btn table-preview__controls__prev"
                                                                onClick={moveColumnHandler.bind(
                                                                    null,
                                                                    i,
                                                                    "left"
                                                                )}
                                                                disabled={
                                                                    i === 0
                                                                }
                                                            >
                                                                <ArrowIcon />
                                                            </button>
                                                        )}
                                                        {i <
                                                            columns.length -
                                                                1 && (
                                                            <button
                                                                type="button"
                                                                className="table-preview__controls__btn table-preview__controls__next"
                                                                onClick={moveColumnHandler.bind(
                                                                    null,
                                                                    i,
                                                                    "right"
                                                                )}
                                                                disabled={
                                                                    i >=
                                                                    columns.length -
                                                                        1
                                                                }
                                                            >
                                                                <ArrowIcon />
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            <div
                                                className="table-preview__tc"
                                                onClick={setSelectedIndex.bind(
                                                    null,
                                                    i
                                                )}
                                            >
                                                <div className="table-preview__th">
                                                    {typeof col === "string"
                                                        ? col
                                                        : col[
                                                              headingKey || ""
                                                          ] || ""}
                                                </div>
                                                <div className="table-preview__td">
                                                    -
                                                </div>
                                                <div className="table-preview__td">
                                                    -
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </StyledTablePreview>
    );
};

export default TablePreview;
