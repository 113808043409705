import styled, { css } from "styled-components";
import {
    enterTop,
    leaveTop,
    slideIn,
} from "../../../global/theme/reuseable/animations";
import { centeredRowMixin } from "../../../global/theme/reuseable/mixins";
import { iCalendarDateSearch } from "../../../components/CalendarComponents/CalendarDateSearch/types";
import { slideInMixin } from "../../../global/theme/reuseable/mixins";
import { iSCDateSearchContainer } from "./types";
import { theme } from "../../../global/theme";

export const SlideInMenuMixin = css`
    top: 0;
    ${slideInMixin};
    background: ${theme?.colors?.background};
    padding-block: 37px;
    overflow-x: hidden;
    z-index: 10;
    transition: all 0.5s;
`;

export const DateSearchContainer = styled.div<iSCDateSearchContainer>`
    ${SlideInMenuMixin}
    padding: 32px;
    transition: all 0.3s ease;
    /* width: ${(props) => (props.open ? "0" : "100%")}; */
    transform: ${(props) =>
        props.open ? "translateX(0%)" : "translateX(100%)"};
    width: ${(props) => (props.large ? "728px" : "450px")};

    & .submit-btns {
        display: flex;
        gap: 12px;

        & > button {
            flex: 1;
            height: 48px;
            padding: 0 12px;
            font-size: 14px;
        }
    }
`;

export const ButtonContainer = styled.div<iCalendarDateSearch>`
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .select-btn {
        height: 40px;
    }
`;

export const DateMenuHeaderWrapper = styled.div`
    ${centeredRowMixin};
    justify-content: space-between;
    padding-bottom: 16px;
    padding-top: 24px;
    border-bottom: solid ${theme?.colors?.text} 1px;
    margin-bottom: 24px;

    & .header-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        width: 30px;
        height: 30px;

        & > img {
            height: 16px;
        }

        &--back {
            margin-right: 12px;
        }
    }

    h1 {
        ${centeredRowMixin};
        font-size: 20px;
        img {
            margin-right: 10px;
        }

        label {
            margin-left: 12px;
            margin-bottom: -4px;
            transform: translateX(120%);
            animation: ${slideIn} 0.7s forwards;
        }
    }
    img {
        cursor: pointer;
    }
`;
