import React, { useState } from "react";
import Header from "../../../layout/Header";
import { Route, Routes, useParams } from "react-router-dom";
import { TablePageContent } from "../../../pages/styles";
import SearchRow from "../../../components/SearchRow";
import DataTable from "../../../components/DataTable";
import useReduxClientManagement from "../../../store/hooks/useReduxClientManagement";
import { testInvoiceData } from "./testData";
import InvoiceRow from "../../../components/DataTable/TableRows/Management/InvoiceRow";
import useFetchData from "../../../global/hooks/useFetchData";

const Invoices = () => {

    const { clientId } = useParams();
    const { invoice,setInvoice, setAuditLogs, setMisc } = useReduxClientManagement();
    const [searchTerm, setSearchTerm] = useState("");

    const getManagement = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/management-invoice/${clientId}`,
        },
        {   fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setInvoice(data.data);
                //if (data?.data?.audit_logs) setAuditLogs(data.data.auditLogs);
                //if (data?.data?.misc) setMisc(data?.data?.misc);
            },
        }
    );
    return (
        <>
            <Header title="Invoices" hasNavBarAbove hasPrevPath isSubPath />
            <TablePageContent>
                <SearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by id or reference..."
                />
                <DataTable
                    labels={[
                        "ID",
                        "Type",
                        "Amount (£)",
                        "VAT (£)",
                        "Total (£)",
                        "Payment Reference",
                        "Taken",
                        "Status",
                    ]}
                    keys={[
                        "ID",
                        "Type",
                        "Amount",
                        "VAT",
                        "Total",
                        "Payment Reference",
                        "Taken",
                        "Status",
                    ]}
                    data={invoice}
                    searchKeys={["ID", "Payment Reference"]}
                    searchTerm={searchTerm}
                    tableRow={InvoiceRow}
                />
            </TablePageContent>
        </>
    );
};

export default Invoices;
