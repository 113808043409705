import styled from "styled-components";
import { ResizableModalWrapper } from "../../styles";

const StyledIncomeExpenditureModal = styled(ResizableModalWrapper)`
    & form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px 16px;

        & > :nth-child(1),
        & > :nth-child(2),
        & > .modal-btns {
            grid-column: 1/-1;
        }
    }
`;

export default StyledIncomeExpenditureModal;
