import { ChangeEvent, SetStateAction, useState } from "react";
import Button from "../../../Button";
import Input from "../../../Input";
import StyledIncomeExpenditureModal from "./styles";
import useFetchData from "../../../../global/hooks/useFetchData";
import ModalHeader from "../../components/ModalHeader";
import SuccessContent from "../../components/SuccessContent";
import ModalButtons from "../../components/ModalButtons";
import { Notify } from "notiflix";
import {
    incomeExpenditureTypeOptions,
    pbsOptions,
    isActiveOptions,
    timePeriodOptions,
} from "../../../DropdownSelect/options";
import DropdownSelect from "../../../DropdownSelect";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";
import BasicModal from "../../BasicModal";

interface EditIncomeExpenditureProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
    data: { [key: string]: any };
}

const EditIncomeExpenditure = ({
    setIsOpen,
    isOpen,
    data,
}: EditIncomeExpenditureProps) => {
    const { setOther } = useReduxSettings();
    const [isDeleting, setIsDeleting] = useState(false);
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        nme: data?.nme || "",
        type: data?.type || incomeExpenditureTypeOptions[0].value,
        min: data?.min || "",
        max: data?.max || "",
        budgeting: data?.budgeting || pbsOptions[0].value,
        active: data?.active || isActiveOptions[0].value,
        often: data?.often || timePeriodOptions[0].value,
        amount: data?.amount || 0,
    });

    const updateIncomeExpenditure = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/other-income-expenditure/${data?.id}`,
        },
        {
            notifyOnSuccess: true,
            onSuccess: (data) => {
                if (data?.data?.income) setOther("income", data.data.income);
                if (data?.data?.expenditure)
                    setOther("expenditure", data.data.expenditure);
            },
        }
    );

    const deleteIncomeExpenditure = useFetchData(
        {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_API}/other-income-expenditure/${data?.id}`,
        },
        {
            notifyOnSuccess: true,
            onSuccess: (data) => {
                if (data?.data?.income) setOther("income", data.data.income);
                if (data?.data?.expenditure)
                    setOther("expenditure", data.data.expenditure);
            },
        }
    );
    const isSuccess =
        updateIncomeExpenditure.isSuccess || deleteIncomeExpenditure.isSuccess;
    const isLoading =
        updateIncomeExpenditure.isLoading || deleteIncomeExpenditure.isLoading;
    const isFormValid = formData?.nme.trim() !== "";

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === "number" ? +value : value,
        });
    };

    const handleSelect = (key: string, value: any) => {
        setFormData({
            ...formData,
            [key]: value,
        });
    };

    const closeModal = () => {
        if (isDeleting && !deleteIncomeExpenditure.isSuccess)
            return setIsDeleting(false);
        return setIsOpen(false);
    };

    const deleteHandler = (e: React.FormEvent) => {
        e.preventDefault();
        deleteIncomeExpenditure.fetchData();
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        updateIncomeExpenditure.fetchData({ IE: formData });
    };

    if (isDeleting)
        return (
            <BasicModal
                isLoading={deleteIncomeExpenditure.isLoading}
                isSuccess={deleteIncomeExpenditure.isSuccess}
                message="Are you sure you want to delete this Income/Expenditure?"
                successMessage="Income/Expenditure has been deleted."
                submitBtnColor="red"
                submitBtnText="Delete"
                onClose={closeModal}
                isOpen={isOpen}
                onSubmit={deleteHandler}
            />
        );

    return (
        <StyledIncomeExpenditureModal
            show={isOpen}
            width="500px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={closeModal}>
                Edit Income/Expenditure
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    onClose={closeModal}
                    message={`Income/Expenditure has been ${
                        updateIncomeExpenditure.isSuccess
                            ? "updated"
                            : "deleted"
                    }.`}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <Input
                        type="text"
                        name="nme"
                        label="Name:"
                        value={formData?.nme || ""}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <DropdownSelect
                        label="Type:"
                        value={formData?.type}
                        options={incomeExpenditureTypeOptions}
                        onSelect={(option) =>
                            handleSelect("type", option.value)
                        }
                    />
                    <Input
                        type="number"
                        name="min"
                        label="Min Amount:"
                        value={`${formData?.min || ""}`}
                        onChange={handleChange}
                        min={0}
                        step={1}
                        autoComplete="off"
                    />
                    <Input
                        type="number"
                        name="max"
                        label="Max Amount"
                        value={`${formData?.max || ""}`}
                        onChange={handleChange}
                        min={formData?.min}
                        step={1}
                        autoComplete="off"
                    />
                    <DropdownSelect
                        label="PBS:"
                        value={formData?.budgeting}
                        options={pbsOptions}
                        onSelect={(option) =>
                            handleSelect("budgeting", option.value)
                        }
                    />
                    <DropdownSelect
                        label="Active:"
                        value={formData?.active}
                        options={isActiveOptions}
                        onSelect={(option) =>
                            handleSelect("active", option.value)
                        }
                    />
                    <DropdownSelect
                        label="Often:"
                        value={formData?.often}
                        options={timePeriodOptions}
                        onSelect={(option) =>
                            handleSelect("often", option.value)
                        }
                    />
                    <Input
                        type="number"
                        label="Amount:"
                        name="amount"
                        value={`${formData?.amount || ""}`}
                        onChange={handleChange}
                        min={formData?.min}
                        max={formData?.max}
                        step={1}
                        autoComplete="off"
                    />

                    <ModalButtons onDelete={() => setIsDeleting(true)}>
                        <Button
                            btnText="Save Changes"
                            style="purple"
                            type="submit"
                            loading={isLoading}
                            disabled={!isFormValid}
                        />
                    </ModalButtons>
                </form>
            )}

            <></>
        </StyledIncomeExpenditureModal>
    );
};

export default EditIncomeExpenditure;
