import styled from "styled-components";
import { theme } from "../../global/theme";

const StyledTablePreview = styled.div`
    & .table-preview {
        &__table {
            display: flex;
            padding-top: 36px;
        }
        &__column {
            flex-grow: 1;
            flex-basis: 0;
            /* flex-basis: auto; */
            position: relative;
            border: 0.6px solid ${theme.colors.formBlue};
            cursor: grab;

            &:hover,
            &[aria-selected="true"] {
                border-color: ${theme.colors.purple};

                & .table-preview {
                    &__th {
                        background-color: ${theme.colors.highlightBlue};
                    }
                }
            }
        }

        &__controls {
            position: absolute;
            top: -38px;
            left: 0;
            height: 34px;
            display: flex;
            align-items: center;
            gap: 4px;

            &__btn {
                border: none;
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
            }

            &__delete {
                background-color: transparent;
                background-image: ${theme.gradients.dangerRed};
                background-size: cover;
            }

            &__prev {
            }

            &__next {
                & > svg {
                    transform: rotate(180deg);
                }
            }
        }

        &__th {
            height: 40px;
            background-color: ${theme.colors.formBlue};
            display: flex;
            align-items: center;
            font-size: 12px;
            padding: 0 8px;
        }

        &__td {
            height: 40px;
            padding: 0 8px;
            font-weight: 300;
            display: flex;
            align-items: center;
            background-color: ${theme.colors.containerBackground};
            &:nth-of-type(2n) {
                background-color: ${theme.colors.background};
            }
        }
    }
`;

export default StyledTablePreview;
