import { ChangeEvent, useState } from "react";
import isEmail from "validator/lib/isEmail";
import Button from "../../Button";
import { StyledIntroducerCreate } from "./styles";
import ModalHeader from "../components/ModalHeader";
import useReduxIntroducers from "../../../store/hooks/useReduxIntroducers";
import SuccessContent from "../components/SuccessContent";
import useFetchData from "../../../global/hooks/useFetchData";
import StepTabs from "../../StepTabs";
import {
    IntroducerInfo,
    ContactInfo,
    BillingInfo,
    Confirmation,
} from "./Steps";
import ModalButtons from "../components/ModalButtons";

interface IntroducerCreateProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    onSuccess?: (data: { [key: string]: any }) => void;
}

export interface IntroducerCreateFormProps {
    introducerDetails: { [key: string]: any };
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSelect: (key: string, value: any) => void;
    isTypeValid: boolean;
    isCompanyNameValid: boolean;
    isContactNameValid: boolean;
    isEmailValid: boolean;
}

const IntroducerCreate: React.FC<IntroducerCreateProps> = ({
    isOpen,
    onClose,
    onSuccess = () => {},
}) => {
    const { types } = useReduxIntroducers();
    const [introducerDetails, setIntroducerDetails] = useState<{
        [key: string]: any;
    }>({
        name: "",
        type: types?.[0]?.id ?? null,
        address: "",
        contact: "",
        tele_phone: "",
        email: "",
        act_name: "",
        act_no: "",
        sortcode: "",
    });
    const [currentStep, setCurrentStep] = useState<number>(0);
    const isLastStep = currentStep >= 3;
    const isTypeValid = !!introducerDetails?.type;
    const isCompanyNameValid = introducerDetails?.name.trim() !== "";
    const isContactNameValid = introducerDetails?.contact.trim() !== "";
    const isEmailValid = isEmail(introducerDetails?.email);
    const isStepValid = () => {
        if (currentStep === 0) return isTypeValid && isCompanyNameValid;
        if (currentStep === 1) return isContactNameValid && isEmailValid;
        if (isLastStep)
            return (
                isTypeValid &&
                isCompanyNameValid &&
                isContactNameValid &&
                isEmailValid
            );
        return true;
    };

    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/introducer`,
        },
        {
            onSuccess,
        }
    );

    function handleChange(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const { name, value } = e.target;
        setIntroducerDetails((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    }

    function handleSelect(key: string, value: any) {
        setIntroducerDetails((prevState) => {
            return {
                ...prevState,
                [key]: value,
            };
        });
    }

    const formProps: IntroducerCreateFormProps = {
        introducerDetails,
        onChange: handleChange,
        onSelect: handleSelect,
        isCompanyNameValid,
        isContactNameValid,
        isEmailValid,
        isTypeValid,
    };

    const prevStepHandler = () => {
        setCurrentStep((prev) => prev - 1);
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (!isLastStep) return setCurrentStep((prev) => prev + 1);
        fetchData(introducerDetails);
    };

    return (
        <StyledIntroducerCreate
            show={isOpen}
            width="560px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={onClose}>
                Create Introducer Wizard
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="you have successfully created a new introducer."
                    onClose={onClose}
                />
            ) : (
                <>
                    <StepTabs
                        tabs={[
                            "Introducer Info",
                            "Contact Info",
                            "Billing Info",
                            "Confirmation",
                        ]}
                        setSelectedTabIndex={setCurrentStep}
                        selectedTabIndex={currentStep}
                        currentProgress={currentStep}
                    />
                    <form onSubmit={submitHandler}>
                        {currentStep === 0 && <IntroducerInfo {...formProps} />}
                        {currentStep === 1 && <ContactInfo {...formProps} />}
                        {currentStep === 2 && <BillingInfo {...formProps} />}
                        {currentStep === 3 && <Confirmation {...formProps} />}
                        <ModalButtons>
                            {currentStep > 0 && (
                                <Button
                                    style="cancel"
                                    btnText="Previous"
                                    type="button"
                                    onClick={prevStepHandler}
                                />
                            )}

                            <Button
                                type="submit"
                                style="purple"
                                btnText={isLastStep ? "Create" : "Next"}
                                loading={isLoading}
                                disabled={!isStepValid()}
                            />
                        </ModalButtons>
                    </form>
                </>
            )}
        </StyledIntroducerCreate>
    );
};

export default IntroducerCreate;
