import styled from "styled-components";
import { theme } from "../../../global/theme";
import { PageFilterContent } from "../../styles";

export const StyledDiaryContent = styled(PageFilterContent)`
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .diaryNav {
        margin-bottom: 24px;
        button {
            height: 40px;
        }
    }

    & .left-btns {
        display: flex;
        gap: 8px;

        & > button {
            &:hover {
                background-color: ${theme?.colors.purple};

                & svg {
                    & path {
                        stroke: ${theme?.colors.white};
                    }
                }
            }

            &:nth-child(2) {
                & svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    & .right-btns {
        display: flex;
        gap: 12px;

        & > button {
            min-width: 72px;
            &:last-of-type {
                /* margin-left: 24px; */
                width: 110px;

                & > span {
                    margin: 0;
                }
            }
        }
    }

    h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #5e5e5e;
        margin-left: 20px;
        margin-right: auto;
    }
    span {
        margin-left: auto;
        display: flex;
        gap: 18px;

        button {
            height: 36px;
        }
    }

    > section:first-child {
        width: 100%;
    }
`;

export const DiaryCtn = styled.div`
    display: flex;
`;
