import React, { useState } from "react";
import Header from "../../../layout/Header";
import LedgerCard from "./LedgerCard";
import { StyledLedgerContent } from "./styles";
import SearchRow from "../../../components/SearchRow";
import DataTable from "../../../components/DataTable";
import useReduxClientLedgerPayment from "../../../store/hooks/useReduxClientLedgerPayment";
import { Modal, Input, Button } from "../../../components";
import EditLedgerModal from "../../../components/Modals/Payments/EditLedgerModal";
import DropdownSelect from "../../../components/DropdownSelect";
import { ipWorkflowOptions } from "../../../components/DropdownSelect/options";
import { useParams } from "react-router-dom";
import DetailCard from "../../../components/Cards/DetailCard";
import useFetchData from "../../../global/hooks/useFetchData";

const Ledger = () => {
    const { clientId } = useParams();
    const { ledger, setDataByKey } = useReduxClientLedgerPayment();
    const [selectedLedger, setSelectedLedger] = useState<{
        [key: string]: any;
    } | null>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [workflow, setWorkflow] = useState("");
    const [caseId, setCaseId] = useState<number | null>(
        clientId ? +clientId : null
    );

    const { isLoading } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/ledger/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setDataByKey("ledger", data?.data || []);
            },
        }
    );

    const submitIpWorkflowHandler = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(workflow, caseId);
    };
    return (
        <>
            <Modal isVisible={!!selectedLedger}>
                <EditLedgerModal
                    isOpen={!!selectedLedger}
                    onClose={() => setSelectedLedger(null)}
                    data={selectedLedger || undefined}
                />
            </Modal>
            <Header title="Ledger" hasNavBarAbove isSubPath hasPrevPath />
            <StyledLedgerContent>
                <div className="table-col">
                    <SearchRow
                        value={searchTerm}
                        setValue={setSearchTerm}
                        placeholder="Search by id, type or reference..."
                    />
                    <DataTable
                        labels={[
                            "ID",
                            "Reason",
                            "Payment Reference",
                            "Type",
                            "Amount (£)",
                            "Created When",
                        ]}
                        keys={[
                            "id",
                            "nts",
                            "pmntRfrnce",
                            "type",
                            "amnt",
                            "created_at",
                        ]}
                        data={ledger?.ledger || []}
                        searchTerm={searchTerm}
                        searchKeys={["id", "pmntRfrnce", "type"]}
                        onRowClick={(data) => setSelectedLedger(data)}
                        formatKeys={{
                            created_at: "time",
                        }}
                        isLoading={isLoading}
                    />
                </div>
                <div className="right-col">
                    <LedgerCard hasLink={false} sum={ledger?.ledger_sum || 0} />
                    <DetailCard
                        title="IP Workflow"
                        titleElementTag="h2"
                        className="ip-workflow"
                    >
                        <form onSubmit={submitIpWorkflowHandler}>
                            <DropdownSelect
                                label="Select a workflow:"
                                options={ipWorkflowOptions}
                                value={workflow}
                                onSelect={(option) => setWorkflow(option.value)}
                            />
                            <Input
                                type="number"
                                value={`${caseId}` ?? ""}
                                label="Case ID:"
                                onChange={(e) => setCaseId(+e.target.value)}
                            />
                            <Button
                                type="submit"
                                style="purple"
                                btnText="Submit"
                                disabled={!workflow || !caseId}
                            />
                        </form>
                    </DetailCard>
                </div>
            </StyledLedgerContent>
        </>
    );
};

export default Ledger;
