import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import { StyledDeleteLiability } from "./styles";
import useReduxClientBalanceSheet from "../../../store/hooks/useReduxClientBalanceSheet";

interface DeleteLiabilityModalProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    data?: { [key: string]: any };
    onSuccess?: (...args: any[]) => void;
}

const DeleteLiabilityModal: React.FC<DeleteLiabilityModalProps> = ({
    isOpen,
    onClose,
    data,
    onSuccess = () => {},
}) => {
    const { tableData, setTableData, notes, setNotes, resetSlice } =
    useReduxClientBalanceSheet();
    const { clientId } = useParams();
    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/liability/${
                data?.id || ""
            }`,
        },
        {
            onSuccess: (data) => {

                if (data?.data) setTableData(data.data?.balance_sheet);setNotes(data.data?.additional_balancesheet);

            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (data?.id) {
            fetchData();
        }
    };
    return (
        <StyledDeleteLiability
            isOpen={isOpen}
            onClose={onClose}
            message={`Do you want to delete:`}
            submitBtnText="Delete"
            submitBtnColor="red"
            successMessage="The client has been flipped to APF."
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSubmit={submitHandler}
        >
            <ul>
                <li>
                    Liability Name:<span>{data?.liability || "-"}</span>
                </li>
                <li>
                    Account Reference:
                    <span>{data?.account_reference || "-"}</span>
                </li>
            </ul>
        </StyledDeleteLiability>
    );
};

export default DeleteLiabilityModal;
