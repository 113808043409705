import { useAppSelector, useAppDispatch } from ".";
import { createClientActions } from "../slices/createClientSlice";
import { CreateClientState } from "../slices/createClientSlice";

const useReduxCreateClient = () => {
    const data = useAppSelector((state) => state.createClient);
    const {
        personal_info,
        address,
        additional_info,
        products,
        personal_budgeting,
        personal_accountancy,
        business_accountancy,
        creditor_info,
        payment,
    } = data;
    const dispatch = useAppDispatch();
    const isDataEmpty = Object.values(data).every((v) => v === null);

    const resetSlice = () => {
        dispatch(createClientActions.resetSlice());
    };

    const setData = (data: { [key: string]: any }) => {
        dispatch(createClientActions.setData(data));
    };

    const setDataByKey = (
        key: keyof CreateClientState,
        data: { [key: string]: any }
    ) => {
        dispatch(createClientActions.setDataByKey({ key, data }));
    };

    const createPersonalBudegting = (
        data: { [key: string]: any },
        typeName: string
    ) => {
        dispatch(
            createClientActions.createPersonalBudegting({
                data,
                typeName,
            })
        );
    };

    const updatePersonalBudegting = (
        data: { [key: string]: any },
        typeName: string,
        id: number
    ) => {
        dispatch(
            createClientActions.updatePersonalBudegting({ data, typeName, id })
        );
    };

    const deletePersonalBudegting = (typeName: string, id: number) => {
        dispatch(createClientActions.deletePersonalBudegting({ typeName, id }));
    };

    const createDebt = (data: { [key: string]: any }) => {
        dispatch(createClientActions.createDebt(data));
    };

    const updateDebt = (data: { [key: string]: any }, id: number) => {
        dispatch(createClientActions.updateDebt({ data, id }));
    };

    const deleteDebt = (id: number) => {
        dispatch(createClientActions.deleteDebt(id));
    };

    return {
        data,
        personal_info,
        address,
        additional_info,
        products,
        personal_budgeting,
        personal_accountancy,
        business_accountancy,
        creditor_info,
        payment,
        resetSlice,
        setData,
        setDataByKey,
        isDataEmpty,
        createPersonalBudegting,
        updatePersonalBudegting,
        deletePersonalBudegting,
        createDebt,
        updateDebt,
        deleteDebt,
    };
};

export default useReduxCreateClient;
