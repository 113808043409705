import { Outlet, useLocation } from "react-router-dom";
import useFetchData from "../../global/hooks/useFetchData";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Modal } from "../../components";
import AgentPopUp from "../../components/Modals/ClientCase/AgentPopUp";
import useReduxUser from "../../store/hooks/useReduxUser";

const ClientPages: React.FC = () => {
    const { setClientCase, setClientCaseByKey } = useReduxUser();
    const { pathname } = useLocation();
    const { clientId } = useParams();
    const isSummaryRegexp = new RegExp(
        "^\\/client-case\\/[0-9]+\\/summary$",
        "g"
    );
    const isSummary = isSummaryRegexp.test(pathname);
    const [isPopUpOpen, setIsPopUpOpen] = useState(isSummary);
    const getClientCase = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/${clientId}/summary`,
        },
        {
            fetchOnLoad: !!clientId,
            onSuccess: (data) => {
                if (data?.data) {
                    setClientCaseByKey("details", data?.data?.column1[0]);
                    setClientCaseByKey("payments", data?.data?.column2[0]);
                    setClientCaseByKey("services", data?.data?.column3[0]);
                    setClientCaseByKey("uploads", {});
                }
            },
        }
    );

    return (
        <>
            <Modal isVisible={isPopUpOpen}>
                <AgentPopUp
                    isOpen={isPopUpOpen}
                    setIsOpen={setIsPopUpOpen}
                    uploadKeys={["aml"]}
                />
            </Modal>
            <Outlet />
        </>
    );
};

export default ClientPages;
