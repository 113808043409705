import { SetStateAction } from "react";
import DataTable from "../../../../components/DataTable";

interface CreditTablesProps {
    selectedTabIndex: number;
    tablesData: { [key: string]: any } | null;
    searchTerm: string;
    setSelectedIds: React.Dispatch<SetStateAction<number[]>>;
}

const CreditTables: React.FC<CreditTablesProps> = ({
    selectedTabIndex,
    tablesData,
    searchTerm,
    setSelectedIds,
}) => {
    return (
        <DataTable
            key={selectedTabIndex}
            data={selectedTabIndex === 0 ? tablesData?.body : tablesData?.body}
            searchTerm={searchTerm}
            dataKey="ID"
            searchKeys={["ID", "Customer Name"]}
            setSelectedIds={setSelectedIds}
            hasCheckbox
            formatKeys={{
                Status: "html",
                "IP1 Paid": "time",
                "Next Payment Date": "date",
            }}
        />
    );
};

export default CreditTables;
