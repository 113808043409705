import styled from "styled-components";
import { theme } from "../global/theme";
import { mainScrollbar } from "../global/theme/reuseable/mixins";
import { HeaderStyles } from "./HeaderContainter";

export const StyledHeaderContainer = styled.div<{ headerStyle: HeaderStyles }>`
    display: flex;
    flex-direction: column;
    /* display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40px 0fr; */
    transition: all 0.3s ease;

    &[aria-expanded="true"] {
        grid-template-rows: 40px 1fr;

        & .header-container {
            &__header {
                border-radius: 8px 8px 0 0;
            }

            &__ctn-wrapper {
                display: initial;
            }
        }
    }

    & .header-container {
        &__header {
            background-color: ${({ headerStyle }) => {
                if (headerStyle === "lightBlue") return theme?.colors.formBlue;
                return "transparent";
            }};
            background-image: ${({ headerStyle }) => {
                if (headerStyle === "gradientBlue")
                    return theme?.gradients.skyBlue;
                return "none";
            }};
            background-size: cover;
            background-repeat: no-repeat;
            display: block;
            padding: 8px 24px;
            border-radius: 8px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > .header-container__title {
                color: ${({ headerStyle }) => {
                    if (headerStyle === "gradientBlue")
                        return theme?.colors.white;
                    return theme?.colors.text;
                }};
            }
        }

        &__expand {
            border: none;
            background: transparent;
            padding: 0 4px;
            margin-right: -4px;

            & > svg {
                transition: all 0.3s ease;
            }

            &.expanded {
                & svg {
                    transform: rotate(-180deg);
                }
            }
        }

        &__title {
            font-size: 16px;
        }

        &__ctn-wrapper {
            /* overflow: hidden; */
            height: 100%;
            width: 100%;
            display: none;
        }

        &__ctn {
            flex: 1 1 0;
            height: 100%;
            padding: 24px;
            background-color: ${theme.colors.containerBackground};
            border-radius: 0 0 8px 8px;
            ${mainScrollbar(12)};

            &.col-2 {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 12px 24px;
            }

            &.col-2-3 {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                gap: 12px 24px;

                & > * {
                    grid-column-end: span 2;
                }

                & > :nth-child(1),
                & > :nth-child(2) {
                    grid-column-end: span 3;
                }
            }
        }
    }
`;
