import { StyledModalHeader as Header } from "./styles";
import { icons } from "../../../assets/icons";
import { SetStateAction } from "react";

export interface ModalHeaderProps {
    className?: string;
    children?: React.ReactNode;
    setIsOpen?: React.Dispatch<SetStateAction<boolean>>;
    onClose?: (...args: any[]) => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
    children,
    setIsOpen,
    onClose,
    className = "",
}) => {
    const closeHandler = () => {
        if (setIsOpen) setIsOpen(false);
        if (onClose) onClose();
    };

    return (
        <Header className={`modal-header ${className ? ` ${className}` : ""}`}>
            <h2 className="modal-header__heading">{children}</h2>
            <button className="modal-header__close" onClick={closeHandler}>
                <img src={icons.close} alt="Close" />
            </button>
        </Header>
    );
};

export default ModalHeader;
