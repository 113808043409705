import React, { SetStateAction, useEffect, useState } from "react";
import FormStepHeader from "../../../../../atoms/FormStepContainerHeader";
import { Input, Label } from "../../../../../components";
import { Row } from "../../../../../global/theme/reuseable/components";
import { FormContainerWrapper } from "../../../../../containers/User/Client/styles";
import { PersonalInfoForm } from "./styles";
import DropdownSelect from "../../../../../components/DropdownSelect";
import {
    employmentStatusOptions,
    preferredContactMethodOptions,
    titleOptions,
} from "../../../../../components/DropdownSelect/options";
import useReduxCreateClient from "../../../../../store/hooks/useReduxCreateClient";
import HeaderContainer from "../../../../../containers/HeaderContainter";

export interface iApplicant {
    primary: boolean;
    data?: { [key: string]: any } | null;
    setData?: React.Dispatch<SetStateAction<{ [key: string]: any } | null>>;
}

const Applicant = ({ primary, data = {}, setData = () => {} }: iApplicant) => {
    const { personal_info } = useReduxCreateClient();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSelect = (key: string, value: any) => {
        setData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const marketingConsentHandler = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setData((prev) => {
            if (!prev?.marketing_consent)
                return {
                    ...prev,
                    marketing_consent: {
                        [e.target.name]: e.target.checked ? 1 : 0,
                    },
                };

            return {
                ...prev,
                marketing_consent: {
                    ...prev.marketing_consent,
                    [e.target.name]: e.target.checked ? 1 : 0,
                },
            };
        });
    };

    const handlePostReference = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData((prev) => {
            if (!prev) return { [e.target.name]: !e.target.value };
            return { ...prev, [e.target.name]: prev[e.target.name] ? 0 : 1 };
        });
    };

    return (
        <HeaderContainer title={primary ? "Applicant 1" : "Applicant 2"}>
            <PersonalInfoForm className="personal-info-form">
                <div className="col-3">
                    <DropdownSelect
                        label={`Title${primary ? "(*)" : ""}:`}
                        options={titleOptions}
                        value={data?.title}
                        onSelect={(data) => handleSelect("title", data.value)}
                    />

                    <Input
                        type="text"
                        label="First Name"
                        name="firstname"
                        value={data?.firstname || ""}
                        onChange={handleChange}
                    />
                    <Input
                        type="text"
                        label="Last Name"
                        name="lastname"
                        value={data?.lastname || ""}
                        onChange={handleChange}
                    />
                </div>
                <div className="grid-col-2">
                    <Input
                        type="date"
                        label="Date Of Birth"
                        name="dob"
                        value={data?.dob || "1970-01-01"}
                        onChange={handleChange}
                    />
                    <Input
                        type="text"
                        name="maid_name"
                        label="Maiden Name (Other Names)"
                        value={data?.maid_name || ""}
                        onChange={handleChange}
                    />
                    <Input
                        type="tel"
                        label="Home Phone"
                        name="hme_phone"
                        value={data?.hme_phone || ""}
                        onChange={handleChange}
                    />
                    <Input
                        type="tel"
                        label="Mobile"
                        name="mobile"
                        value={data?.mobile || ""}
                        onChange={handleChange}
                    />
                    <Input
                        type="email"
                        label="Email"
                        name="email"
                        value={data?.email || ""}
                        onChange={handleChange}
                    />
                    <DropdownSelect
                        label="Preferred Contact Method"
                        options={preferredContactMethodOptions}
                        value={data?.prf_cont_mthd}
                        onSelect={(data) =>
                            handleSelect("prf_cont_mthd", data.value)
                        }
                    />
                    <Input
                        type="text"
                        label="Occupation"
                        name="occupation"
                        value={data?.occupation || ""}
                        onChange={handleChange}
                    />
                    <DropdownSelect
                        label="Employment Status"
                        options={employmentStatusOptions}
                        value={data?.employment_status}
                        onSelect={(data) =>
                            handleSelect("employment_status", data.value)
                        }
                    />
                    {primary && (
                        <>
                            <div className="checkbox-ctn">
                                <Label label="Marketing Consent:" />
                                <Row>
                                    <Input
                                        type="checkbox"
                                        label="Email"
                                        name="email"
                                        value={
                                            data?.marketing_consent?.email || 0
                                        }
                                        checked={
                                            !!data?.marketing_consent?.email
                                        }
                                        onChange={marketingConsentHandler}
                                    />
                                    <Input
                                        type="checkbox"
                                        label="Phone"
                                        name="phone"
                                        value={
                                            data?.marketing_consent?.phone || 0
                                        }
                                        checked={
                                            data?.marketing_consent?.phone === 1
                                        }
                                        onChange={marketingConsentHandler}
                                    />
                                    <Input
                                        type="checkbox"
                                        label="Letters"
                                        name="letters"
                                        value={
                                            data?.marketing_consent?.letters ||
                                            0
                                        }
                                        checked={
                                            data?.marketing_consent?.letters ===
                                            1
                                        }
                                        onChange={marketingConsentHandler}
                                    />
                                </Row>
                            </div>
                            <div className="checkbox-ctn">
                                <Label label="Post Preference" />
                                <div>
                                    <Input
                                        type="checkbox"
                                        label="No Post"
                                        name="post_prefrnce"
                                        checked={!data?.post_prefrnce}
                                        value={data?.post_prefrnce ? 1 : 0}
                                        onChange={handlePostReference}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </PersonalInfoForm>
        </HeaderContainer>
    );
};

export default Applicant;
