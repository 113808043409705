import styled from "styled-components";
import { ResizableModalWrapper } from "../styles";
import UploadModal from "../UploadModal";

export const StyledCreateCorres = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px 16px;

        &.col-2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    & .modal-btns,
    & .text-area,
    & .text-editor,
    & .input-subject {
        grid-column: 1/-1;
    }

    & .checkbox-group {
        margin-top: 8px;
        & h3 {
            font-size: 14px;
            margin-bottom: 8px;
        }

        &__checkboxes {
            display: flex;
            gap: 16px;
        }
    }

    & .se-container {
        height: 400px;
    }
`;

export const StyledCreateCorresUpload = styled(UploadModal)``;
