import styled from "styled-components";
import { theme } from "../../global/theme";
import { iSCReportHeader } from "./types";

export const ReportHeaderWrapper = styled.li<iSCReportHeader>`
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    background: ${(props) =>
        props.light ? `${theme?.colors?.white}` : `#FBFBFB`};
    border-width: 0px 0.5px 0.5px 0px;
    border-style: solid;
    border-color: #e5efff;

    p {
        font-family: "Poppins";
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        color: ${theme?.colors?.darkText};
    }

    div {
        margin-left: auto;
        display: flex;
        gap: 8px;

        & > * {
            border: none;
            background-color: transparent;
            height: 28px;
            width: 28px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;

            :hover {
                background: #f5f5f5;
                cursor: pointer;
            }
        }
    }
    span:last-child {
        margin-left: 13px;
    }
`;
