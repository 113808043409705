import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HeaderContainer } from "./styles";
import { ReactComponent as PrevIcon } from "../../assets/icons/purpleLeftArrow.svg";
import NavBar, { NavBarProps } from "./NavBar";

interface HeaderProps extends NavBarProps {
    className?: string;
    children?: React.ReactNode;
    prevPath?: string;
    title?: string | JSX.Element;
    hasNavBarAbove?: boolean;
    hasPrevPath?: boolean;
    contentStyle?: React.CSSProperties;
}

const Header: React.FC<HeaderProps> = ({
    className = "",
    subList,
    subListName = "",
    isSubPath = true,
    children = <></>,
    prevPath,
    hasPrevPath = false,
    title = "",
    hasNavBarAbove = false,
    contentStyle,
}) => {
    const { pathname } = useLocation();
    const isOnSubPath = pathname.split("/").length > 1;
    const showPrevBtn = (prevPath || hasPrevPath) && isOnSubPath;
    const previousPath = pathname.replace(/\/[^\\/{1}]+\/?$/, "");
    const LinkTo = prevPath || (hasPrevPath ? previousPath : "");

    return (
        <HeaderContainer
            className={`header${className ? ` ${className}` : ""}`}
            hasNavBar={hasNavBarAbove}
        >
            <NavBar
                subList={subList}
                subListName={subListName}
                isSubPath={isSubPath}
            />
            <header className="header__header">
                {showPrevBtn ? (
                    <Link className="header__prev" to={LinkTo}>
                        <PrevIcon />
                    </Link>
                ) : null}
                <h1 className="header__title">{title}</h1>
                <div className="header__content" style={contentStyle}>
                    {children}
                </div>
            </header>
        </HeaderContainer>
    );
};

export default Header;
