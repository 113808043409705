import React from "react";
import { useParams } from "react-router-dom";
import { CreateDocumentTemplateFormProps } from "../CreateDocumentTemplate";
import { StyledDocumentTemplateForm } from "./styles";
import { Input } from "../../../../../../components";
import DropdownSelect from "../../../../../../components/DropdownSelect";
import useFetchData from "../../../../../../global/hooks/useFetchData";
import {
    documentSendToOptions,
    documentSignablePageOptions,
} from "../../../../../../components/DropdownSelect/options";

const GeneralSettings: React.FC<CreateDocumentTemplateFormProps> = ({
    documentData,
    setDocumentData,
    checkboxHandler,
    selectHandler,
}) => {
    const { documentId } = useParams();
    const getPrinterNames = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/printers`,
        },
        {
            fetchOnLoad: true,
        }
    );

    const printerNames = getPrinterNames?.data?.data || [];

    return (
        <StyledDocumentTemplateForm>
            <ul className="info">
                <li>
                    Printer name:
                    <DropdownSelect
                        value={documentData.printer_name}
                        name="printer_name"
                        optionKey="printer"
                        labelKeys={["printer"]}
                        options={printerNames}
                        onSelect={(option) =>
                            selectHandler("printer_name", option.printer)
                        }
                    />
                </li>
                <li className="checkboxes">
                    Paper Settings:
                    <div className="checkbox-ctn">
                        <Input
                            className="checkbox"
                            type="checkbox"
                            label="Print on letterhead"
                            checked={!!documentData?.printer_pre}
                            onChange={checkboxHandler}
                            name="printer_pre"
                        />
                        <Input
                            className="checkbox"
                            type="checkbox"
                            label="Save as PDF"
                            checked={!!documentData?.saveasPDF}
                            onChange={checkboxHandler}
                            name="saveasPDF"
                        />
                    </div>
                </li>
                <li>
                    Send To:
                    <DropdownSelect
                        value={documentData.sendto}
                        name="sendto"
                        options={documentSendToOptions}
                        onSelect={(option) =>
                            selectHandler("sendto", option.value)
                        }
                    />
                </li>
                <li>
                    Signable Page:
                    <DropdownSelect
                        value={documentData.signable_page}
                        name="signable_page"
                        options={documentSignablePageOptions}
                        onSelect={(option) =>
                            selectHandler("signable_page", option.value)
                        }
                    />
                </li>
            </ul>
        </StyledDocumentTemplateForm>
    );
};

export default GeneralSettings;
