import { useState } from "react";
import { TableRowProps } from "..";
import { Modal } from "../../..";
import CreateCreditorInfo from "../../../Modals/ClientCreate/CreateCreditorInfo";

const CreditorInfoRow: React.FC<TableRowProps> = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);
    const rowClickHandler = () => {
        setIsOpen(true);
    };

    return (
        <>
            <Modal isVisible={isOpen}>
                <CreateCreditorInfo
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    mode="edit"
                    data={data}
                />
            </Modal>
            <tr onClick={rowClickHandler} role="button">
                <td>{data?.name ?? "-"}</td>
                <td>{data?.accntRfrnce ?? "-"}</td>
                <td>{data?.estmtdBlnce ?? "-"}</td>
            </tr>
        </>
    );
};
export default CreditorInfoRow;
