import React, { useEffect, useState } from "react";
import { Button, ModalContainer } from "../../../components";
import CreateCreditorModal from "../../../components/Modals/Creditor/CreateCreditor";
import { PageContent, TablePageContent } from "../../styles";
import Header from "../../../layout/Header";
import DateButton from "../../../components/Button/DateButton";
import useDateSearch from "../../../layout/SlideInMenus/DateSearch/useDateSearch";
import { dateSearchOptions } from "../../../layout/SlideInMenus/DateSearch/data";
import SearchRow from "../../../components/SearchRow";
import DataTable from "../../../components/DataTable";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxUI from "../../../store/hooks/useReduxUI";
import CreditorsRow from "../../../components/DataTable/TableRows/Creditors/CreditorsRow";
import useReduxCreditors from "../../../store/hooks/useReduxCreditors";

const Creditors: React.FC = () => {
    const { setSideMenu } = useReduxUI();
    const { setList, list } = useReduxCreditors();
    const [createCreditorModal, setCreateCreditorModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const getCreditors = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/get-creditor-list`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                setSideMenu("");
                if (data?.data?.creditors) setList(data?.data?.creditors);
            },
        }
    );

    const tableHeaders = [
        "Id",
        "Creditor Name",
        "Phone Number",
        "Address",
        "Preferred Correspondence",
        "No of Client",
        "WET LOA",
        "Status",
    ];

    return (
        <>
            <ModalContainer
                isVisible={createCreditorModal}
                children={
                    <CreateCreditorModal
                        open={createCreditorModal}
                        setCreateCreditorModal={setCreateCreditorModal}
                    />
                }
            />
            <Header title="creditors" hasNavBarAbove hasPrevPath>
                {/* <DateButton
                    isLoading={false}
                    dateDisplay={dateDisplay}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                /> */}
            </Header>
            <TablePageContent>
                <SearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by Creditor name..."
                >
                    <Button
                        btnText="+ Create"
                        onClick={() => setCreateCreditorModal(true)}
                        style="mint"
                        paddingInline="24px"
                    />
                </SearchRow>
                <DataTable
                    labels={tableHeaders}
                    keys={tableHeaders}
                    data={list}
                    isLoading={getCreditors.isLoading}
                    searchTerm={searchTerm}
                    hideColums={[0]}
                    dataKey="Id"
                    searchKeys={["Creditor Name"]}
                    tableRow={CreditorsRow}
                />
            </TablePageContent>
        </>
    );
};

export default Creditors;
