import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { SetStateAction } from "react";
import StyledStepTabs from "./styles";

interface StepTabsProps {
    className?: string;
    tabs: string[];
    selectedTabIndex: number;
    setSelectedTabIndex: React.Dispatch<SetStateAction<number>>;
    stepsCompleted?: boolean[];
    currentProgress?: number;
    gap?: number;
}

const StepTabs: React.FC<StepTabsProps> = ({
    className = "",
    tabs,
    selectedTabIndex,
    setSelectedTabIndex,
    stepsCompleted,
    currentProgress,
    gap = 6,
}) => {
    const tabsRef = useRef<HTMLUListElement>(null);
    const [tabWidths, setTabWidths] = useState<number[]>([]);
    const thumbOffset = () => {
        if (!tabWidths?.length) return 0;
        const widths = tabWidths.filter((_, i) => i <= selectedTabIndex);
        if (!widths?.length) return 0;
        widths[widths.length - 1] /= 2;
        return widths.reduce((a, c) => a + c) + selectedTabIndex * gap;
    };

    useLayoutEffect(() => {
        if (tabsRef?.current) {
            const widths = Array.from(tabsRef.current.children)
                .map((child) => (child as HTMLElement).offsetWidth)
                .slice(0, -1);
            setTabWidths(widths);
        }
    }, [tabsRef?.current, tabs]);

    const stepDisabled = (index: number) => {
        if (typeof currentProgress === "number")
            return currentProgress <= index;
        if (stepsCompleted)
            return !stepsCompleted[index] && selectedTabIndex !== index;
        return false;
    };

    return (
        <StyledStepTabs className={`step-tabs ${className}`} gap={gap}>
            <ul className="step-tabs__tabs" ref={tabsRef}>
                {tabs?.map((tab, i) => (
                    <li key={i} className="step-tabs__tab" aria-label={tab}>
                        <button
                            type="button"
                            className="step-tabs__btn"
                            onClick={setSelectedTabIndex.bind(null, i)}
                            disabled={stepDisabled(i)}
                            data-selected={selectedTabIndex === i}
                        >
                            {tab}
                        </button>
                    </li>
                ))}
                <div
                    className="step-tabs__thumb"
                    style={{
                        transform: `translateX(calc(${thumbOffset()}px - 50%))`,
                        width: tabWidths[selectedTabIndex]
                            ? tabWidths[selectedTabIndex] * 0.6
                            : "",
                    }}
                ></div>
            </ul>
        </StyledStepTabs>
    );
};

export default StepTabs;
