import parse from "html-react-parser";
import StyledSummaryCard from "./styles";
import { ReactComponent as TickIcon } from "../../../assets/icons/greenTick.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/redCross.svg";
import {
    calculateAgeFromDate,
    formatDateString,
    formatTimeString,
} from "../../../global/utils/helpers";

interface SummaryCardProps {
    className?: string;
    title: string;
    data: { [key: string]: any };
    labels: string[];
    keys: string[];
}

const SummaryCard: React.FC<SummaryCardProps> = ({
    className = "",
    title,
    data,
    labels,
    keys,
}) => {
    const valueDisplay = (label: string, value: any) => {
        if (label.includes("GDPR")) {
            return !!value ? <TickIcon /> : <CrossIcon />;
        }
        if (label === "Date of Birth")
            return `${formatDateString(value)}(${calculateAgeFromDate(value)})`;
        if (label === "Next Payment Date") return formatDateString(value);
        if (label === "Welcome Call") return formatTimeString(value);
        if (label.includes("Fee") && typeof value === "number")
            return `£${value.toFixed(2)}`;
        if (label === "Status") return parse(value ?? "");
        return value || "-";
    };
    return (
        <StyledSummaryCard
            className={`summary-card${className ? ` ${className}` : ""}`}
        >
            <h2 className="summary-card__title">{title}</h2>
            <ul className="summary-card__list">
                {labels.map((label, i) => (
                    <li className="summary-card__item" key={label}>
                        {label}
                        <div className="summary-card__value">
                            {valueDisplay(label, data[keys[i]])}
                        </div>
                    </li>
                ))}
            </ul>
        </StyledSummaryCard>
    );
};

export default SummaryCard;
