import { BusinessAccountancyContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";

const GovGateway: React.FC<BusinessAccountancyContainerProps> = ({
    formData,
    onInputChange,
}) => {
    return (
        <HeaderContainer title="Government Gateway:" ctnClassName="col-2">
            <Input
                type="text"
                label="Username"
                value={formData?.gov_gateway_username || ""}
                onChange={onInputChange}
                name="gov_gateway_username"
            />
            <Input
                type="password"
                label="Password"
                value={formData?.gov_gateway_password || ""}
                onChange={onInputChange}
                name="gov_gateway_password"
                hasShowPassword
            />
        </HeaderContainer>
    );
};

export default GovGateway;
