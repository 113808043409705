import styled from "styled-components";
import { ResizableStepModalWrapper, ResizableModalWrapper } from "../styles";

export const StyledChangeUserPermissions = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
`;

export const StyledAPISettingsModal = styled(ResizableModalWrapper)`
    & form {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
`;

export const ApiSettingsModalWrapper = styled(ResizableStepModalWrapper)`
    .content {
        padding: 12px 0 15px 0;

        input {
            width: 100%;
            margin-bottom: 20px;
        }
        .select,
        .select p {
            height: 40px;
        }
    }
    .nav {
        gap: 12px;
        button:first-child {
            width: 140px;
        }
        button:last-child {
            width: 120px;
        }
    }
`;

export const StyledDeleteAccountModal = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 400px;
    }
`;
