import { useAppSelector, useAppDispatch } from ".";
import { clientPortalActions } from "../slices/clientPortalSlice";

const useReduxClientPortal = () => {
    const { requests, sessions } = useAppSelector(
        (state) => state["client-portal"]
    );
    const dispatch = useAppDispatch();

    const setRequests = (data: Array<{ [key: string]: any }>) => {
        dispatch(clientPortalActions.setRequests(data));
    };

    const setSessions = (data: Array<{ [key: string]: any }>) => {
        dispatch(clientPortalActions.setSessions(data));
    };

    const resetSlice = () => {
        dispatch(clientPortalActions.resetSlice());
    };

    return {
        requests,
        setRequests,
        sessions,
        setSessions,
        resetSlice,
    };
};

export default useReduxClientPortal;
