import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components";

import { PageContent, PageHeader, PageMain, StyledErrorPage } from "./styles";
import { adminSubLinks } from "./UserPages/Administration/data";
import { btnStyle } from "../components/Button/types";

interface ErrorPageProps {
    statusCode?: number;
    message?: string;
    to?: string;
    btnText?: string;
    btnColor?: btnStyle;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
    statusCode,
    message = "There is an error.",
    to = "/",
    btnText = "Back to dashboard",
    btnColor = "purple",
}) => {
    const navigate = useNavigate();
    return (
        <StyledErrorPage className="error-page">
            <h1 className="error-page__title">
                Error{statusCode ? `: ${statusCode}` : ""}
            </h1>
            <p className="error-page__message">{message}</p>
            <Button
                className="error-page__btn"
                type="button"
                btnText={btnText}
                onClick={() => navigate(to)}
                style={btnColor}
            />
        </StyledErrorPage>
    );
};

export default ErrorPage;
