import styled from "styled-components";
import { PageContent } from "../../../pages/styles";
import { theme } from "../../../global/theme";

const StyledManagementLandingContent = styled(PageContent)`
    & .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 24px;
    }

    & .detail-card {
        align-items: initial;
        min-height: 160px;
        flex: 1 1 auto;
        &__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 24px;
            margin-bottom: 12px;
            font-size: 14px;

            & a {
                color: ${theme.colors.purple};
                border-radius: 6px;
                padding: 6px 12px;
                font-size: 12px;
                border: 1px solid ${theme.colors.purple};
            }
        }

        & ul {
            list-style: none;

            & > li {
                font-size: 12px;
                font-weight: 300;
                &:first-child {
                    font-weight: 400;
                    margin-bottom: 4px;
                }

                & > span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        & .invoice-list {
            & > li {
                display: grid;
                grid-template-columns: 100px 100px 100px;
            }
        }
        & .audit-list {
            & > li {
                display: grid;
                grid-template-columns: 140px 120px 140px;
            }
        }
    }

    & .misc-card {
        min-width: 332px;
        & h3 {
            font-size: 12px;
        }
        & p {
            font-size: 12px;
            font-weight: 300;
        }
    }
`;

export const StyledMiscellaneousContent = styled(PageContent)``;

export default StyledManagementLandingContent;
