import React, { useRef, useEffect } from "react";
import { TimeTableCard as Card } from "./styles";
import moment from "moment";

interface TimeTableCardProps {
    data: { [key: string]: any };
    autoHide?: boolean;
    onClick?: (...args: any[]) => void;
    isFirstCard?: boolean;
}

const TimeTableCard: React.FC<TimeTableCardProps> = ({
    data,
    autoHide = false,
    onClick = () => {},
    isFirstCard = false,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (isFirstCard && ref?.current) ref?.current.scrollIntoView();
    }, [ref?.current]);
    return (
        <Card
            className="time-table-card"
            autoHide={autoHide}
            onClick={onClick}
            ref={ref}
            status={data?.stts}
        >
            <div className="time-table-card__title">
                <p className="time-table-card__handler">
                    {data?.get_user
                        ?.map((user: { [key: string]: any }) => user?.name)
                        .join(", ") || "Unknown User"}
                </p>
                <p className="time-table-card__case">{data?.case_id || ""}</p>
                {!!data?.get_creditor?.length && (
                    <p className="time-table-card__creditor">
                        {data?.get_creditor
                            .map((user: { [key: string]: any }) => user?.name)
                            .join(", ") || "Unknown User"}
                    </p>
                )}
            </div>
            <p className="time-table-card__text">
                <strong>
                    {moment(data?.whn, "YYYY-MM-DD HH:mm:ss").format(
                        "h:mm A"
                    ) || "--:--"}
                </strong>
                {" - "}
                {data?.smmry ?? (data?.summary || "No summary.")}
            </p>
        </Card>
    );
};

export default TimeTableCard;
