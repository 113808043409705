import React, { useState } from "react";
import { Button, CreateAdminModal, ModalContainer } from "../../../components";
import SearchRow from "../../../components/SearchRow";
import DataTable from "../../../components/DataTable";
import UserAccountRow from "../../../components/DataTable/TableRows/Accounts/UserAccountRow";
import useFetchData from "../../../global/hooks/useFetchData";

const UserAccounts = () => {
    const [createAdmin, setCreateAdmin] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [userDet, setUserDet] = useState<Array<{ [ket: string]: any }>>([]);
    const TableKeyHeadings = {
        Name: "Name",
        "Email/Username": "Email/Username",
        DDI: "DDI",
        EXTN: "EXTN",
        Department: "Department",
        Type: "Type",
        "Last Login": "Last Login",
        Status: "Status",
    };
    const { data, fetchData, isLoading } = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/users-admin`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) {
                    setUserDet(data?.data);
                }
            },
        }
    );

    return (
        <>
            <ModalContainer
                isVisible={createAdmin}
                children={
                    <CreateAdminModal
                        open={createAdmin}
                        setUserDet={setUserDet}
                        setCreateAdmin={setCreateAdmin}
                    />
                }
            />
            <SearchRow value={searchTerm} setValue={setSearchTerm}>
                <Button
                    btnText="+ CREATE"
                    onClick={() => setCreateAdmin(true)}
                    style="mint"
                    role="create"
                />
            </SearchRow>
            <DataTable
                labels={Object.values(TableKeyHeadings)}
                keys={["Id"]}
                data={userDet}
                dataKey="Id"
                searchTerm={searchTerm}
                searchKeys={["Name"]}
                tableRow={UserAccountRow}
                isLoading={isLoading}
                hasAction
            ></DataTable>
        </>
    );
};

export default UserAccounts;
