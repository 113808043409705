import React, { Dispatch, SetStateAction, useState } from "react";
import Button from "../../../Button";
import Input from "../../../Input";
import BasicModal from "../../BasicModal";
import ModalHeader from "../../components/ModalHeader";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import DropdownSelect from "../../../DropdownSelect";
import { departmentOptions } from "../../../DropdownSelect/options";
import ModalButtons from "../../components/ModalButtons";
import TextEditor from "../../../TextEditor";
import StyledMessageTemplateModal from "./style";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";
import BodyVariablesSelect from "../../../DropdownSelect/BodyVariablesSelect";
export interface EmailTemplateModalProps {
    open: boolean;
    setCreateNewTemplate: Dispatch<SetStateAction<boolean>>;
    mode?: "create" | "edit";
    data?: { [key: string]: any };
}

const EmailTemplateModal = ({
    setCreateNewTemplate,
    open,
    mode = "create",
    data,
}: EmailTemplateModalProps) => {
    const [name, setName] = useState(data?.title || "");
    const [id, setId] = useState(data?.id || "");
    const [department, setDepartment] = useState(
        data?.department || departmentOptions[0].value
    );
    const [subject, setSubject] = useState(data?.subject || "");
    const [body, setBody] = useState(data?.body || "");
    const [isDeleting, setIsDeleting] = useState(false);
    const { templates, setTemplate } = useReduxSettings();

    const createTemplate = useFetchData(
        {
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_API}/settings-template-email-create`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTemplate("email", data?.data);
            },
        }
    );
    const updateTemplate = useFetchData(
        {
            method: "PUT",
            url: `${process.env.REACT_APP_SERVER_API}/settings-template-email-update/${id}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTemplate("email", data?.data);
            },
        }
    );

    const deleteTemplate = useFetchData(
        {
            method: "DELETE",
            url: `${process.env.REACT_APP_SERVER_API}/settings-template-email/${id}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTemplate("email", data?.data);
            },
        }
    );

    const isSubmitSuccess =
        createTemplate.isSuccess || updateTemplate.isSuccess;
    const isLoading = createTemplate.isLoading || updateTemplate.isLoading;
    const isFormValid =
        name.trim() !== "" && !!department && body.trim() !== "";

    const closeModal = () => {
        if (!deleteTemplate.isSuccess && isDeleting)
            return setIsDeleting(false);
        setCreateNewTemplate(false);
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const requestData = {
            name,
            department,
            subject,
            body,
        };
        if (mode === "create") {
            createTemplate.fetchData({ data: requestData });
        } else {
            updateTemplate.fetchData({ data: requestData });
        }
    };

    const submitDeleteHandler = (e: React.FormEvent) => {
        e.preventDefault();
        deleteTemplate.fetchData();
    };

    if (isDeleting)
        return (
            <BasicModal
                isSuccess={deleteTemplate.isSuccess}
                isLoading={deleteTemplate.isLoading}
                title="Delete Template"
                onClose={closeModal}
                onSubmit={submitDeleteHandler}
                message="Are you sure you want to delete this template?"
                successMessage="Template has been deleted."
                isOpen={open}
                submitBtnColor="red"
                submitBtnText="Delete"
            />
        );

    return (
        <StyledMessageTemplateModal
            show={open}
            className="message-template-modal message-template-modal--email"
            width="660px"
            isSuccess={isSubmitSuccess}
        >
            <ModalHeader onClose={closeModal}>
                {mode === "edit" ? "Edit Template" : "Create New Template"}
            </ModalHeader>
            {isSubmitSuccess ? (
                <SuccessContent
                    onClose={closeModal}
                    message={
                        mode === "create"
                            ? "New Email Template has been created"
                            : "Template has been updated."
                    }
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <DropdownSelect
                        label="Department:"
                        options={departmentOptions}
                        value={department}
                        onSelect={(option) => setDepartment(option.value)}
                    />
                    <Input
                        label="Template name:"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        name="name"
                        type="text"
                        required
                    />
                    <BodyVariablesSelect />
                    <Input
                        className="subject-input"
                        label="Subject:"
                        onChange={(e) => setSubject(e.target.value)}
                        value={subject}
                        name="subject"
                        type="text"
                        required
                    />
                    <TextEditor
                        label="message:"
                        value={body}
                        name="message"
                        onChange={setBody}
                    />

                    <ModalButtons
                        onDelete={
                            mode === "edit"
                                ? () => setIsDeleting(true)
                                : undefined
                        }
                    >
                        <Button
                            type="submit"
                            style="purple"
                            btnText={
                                mode === "create" ? "Create" : "Save Changes"
                            }
                            loading={isLoading}
                            disabled={!isFormValid}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledMessageTemplateModal>
    );
};

export default EmailTemplateModal;
