import styled from "styled-components";
import { theme } from "../../../global/theme";
import { ResizableModalWrapper } from "../styles";

export const StyledPaymentRunModal = styled(ResizableModalWrapper)`
    & .payment-run {
        &__form {
            min-width: 400px;
        }
        &__options {
            list-style: none;
            padding: 8px 16px;
            padding-top: 0;
        }

        &__option {
            border-bottom: 0.6px solid ${theme?.colors.shade};
            padding-block: 8px;
        }
    }
`;

export const StyledAdminLedgerModal = styled(ResizableModalWrapper)`
    & .update-ledger {
        &__client-select {
            padding-bottom: 16px;
        }

        &__fields {
            display: grid;
            grid-template-columns: 1fr 140px;
            gap: 12px 16px;
            padding-bottom: 16px;

            & input {
                width: 100%;
            }

            & > .inputWrapper:first-of-type,
            & > :last-child,
            & > .text-area {
                grid-column: 1/-1;
            }

            & textarea {
                height: 70px;
            }

            & .data-list {
                grid-column: 1/-1;
                list-style: none;
                display: grid;
                grid-template-columns: 1fr 1fr;
                font-size: 12px;
                gap: 2px 24px;
                font-weight: 300;

                & li {
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;

                    &:first-child {
                        grid-column: 1/-1;
                    }

                    & > span {
                        font-weight: 400;
                    }
                }
            }
        }
    }
`;

export const StyledProcessRefundRequest = styled(ResizableModalWrapper)`
    width: min(100%, 460px);

    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    & .data-list {
        list-style: none;
        margin-bottom: 8px;

        & > li {
            display: flex;
            justify-content: space-between;
            gap: 12px;
            font-size: 12px;
            font-weight: 300;

            &:not(:first-child) {
                margin-top: 4px;
            }

            & > span {
                font-weight: 400;
            }
        }
    }

    & .checkbox {
        flex-direction: row;
        align-items: center;
        margin-top: 12px;

        & .inputWrapper {
            &__ctn {
                width: auto;
                height: 18px;
                width: 18px;
                margin-left: 8px;

                & > input {
                    min-height: initial;
                }
            }
        }
    }
`;
