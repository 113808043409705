export const testClientOverviewData = {
    top: [{
        title: 'Packs In',
        count: 0,
        path: ''
    }, {
        title: 'Packs Out',
        count: 0,
        path: ''
    }, {
        title: 'Paid Client by IP1',
        count: 0,
        path: ''
    }, {
        title: 'Cancelled Due IP1',
        count: 0,
        path: ''
    }],
    mid: [{
        title: 'Migration Overview',
        text: 'Month to date',
        card_number: '12341234',
        cash: 123,
        path: ''
    }, {
        title: 'Migration Overview',
        text: 'Month to date',
        card_number: '12341234',
        cash: 123,
        path: ''
    }, {
        title: 'Migration Overview',
        text: '',
        card_number: '12341234',
        cash: 123,
        path: ''
    }],
    bottom: [{
        title: 'Client Due For Review',
        can_report: true,
        path: ''
    }, {
        title: 'Client Due For Renewal',
        can_report: false,
        path: ''
    }, {
        title: 'Introducer Performance',
        can_report: false,
        path: ''
    }, {
        title: 'Performance For Advisor',
        can_report:false,
        path: ''
    }]

}
