import React, { useState } from "react";
import { Modal } from "../../../components";
import CreatePeronsalBudgeting from "../../../components/Modals/ClientCreate/CreatePeronsalBudgeting";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import Header from "../../../layout/Header";
import { Button } from "../../../components";
import { LinearSpan } from "../../../global/theme/reuseable/components";
import { StyledPersonalBudgeting } from "../../../subPages/User/Client/ClientCreate/ProductInfo/styles";
import DataTable from "../../../components/DataTable";
import SearchRow from "../../../components/SearchRow";
import StyledIncomeExpense from "./styles";

const IncomeExpense: React.FC = () => {
    const [isCreating, setIsCreating] = useState(false);
    const [selectedData, setSelectedData] = useState<{
        [key: string]: any;
    } | null>(null);
    const { clientId } = useParams();
    const [income, setIncome] = useState<Array<{ [key: string]: any }>>([]);
    const [expenditure, setExpenditure] = useState<
        Array<{ [key: string]: any }>
    >([]);
    const [incomeSearch, setIncomeSearch] = useState("");
    const [expenditureSearch, setExpenditureSearch] = useState("");

    const getIncomeExpense = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/client-income-expenditure/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data?.case?.income) setIncome(data.data.case.income);
                if (data?.data?.case?.expenditure)
                    setExpenditure(data.data.case.expenditure);
            },
        }
    );

    const incomeSum = income
        .map((data) => data.subtotal)
        .reduce((a, c) => a + c, 0);
    const expenditureSum = expenditure
        .map((data) => data.subtotal)
        .reduce((a, c) => a + c, 0);

    const createHandler = (keyName: string, data: { [key: string]: any }) => {
        const setter = keyName === "income" ? setIncome : setExpenditure;
        console.log(data);
        setter((prev) => [...prev, data]);
    };

    const updateHandler = (keyName: string, data: { [key: string]: any }) => {
        console.log(data);
        const setter = keyName === "income" ? setIncome : setExpenditure;
        setter((prev) => prev.map((d) => (data?.id === d?.id ? data : d)));
    };

    const deleteHandler = (keyName: string, id: number) => {
        const setter = keyName === "income" ? setIncome : setExpenditure;
        setter((prev) => prev.filter((d) => d?.id !== id));
    };

    return (
        <>
            <Modal isVisible={isCreating}>
                <CreatePeronsalBudgeting
                    isOpen={isCreating}
                    onClose={setIsCreating}
                    mode="create"
                    onCreateSuccess={createHandler}
                />
            </Modal>
            <Modal isVisible={!!selectedData}>
                <CreatePeronsalBudgeting
                    isOpen={!!selectedData}
                    onClose={() => setSelectedData(null)}
                    mode="edit"
                    onUpdateSuccess={updateHandler}
                    onDeleteSuccess={deleteHandler}
                    data={selectedData || undefined}
                />
            </Modal>
            <Header title="Income & Expenditure">
                <Button
                    btnText="+ Create"
                    type="button"
                    style="mint"
                    onClick={() => setIsCreating(true)}
                />
            </Header>
            <StyledIncomeExpense>
                <h2>
                    Total ={" "}
                    <LinearSpan color="green">
                        £{incomeSum - expenditureSum}
                    </LinearSpan>
                </h2>
                <StyledPersonalBudgeting
                    className="client-form__content"
                    // onSubmit={submitHandler}
                >
                    <div className="table-col">
                        <SearchRow
                            className="header"
                            value={incomeSearch}
                            setValue={setIncomeSearch}
                            placeholder="Search by values..."
                        >
                            <h3>
                                Income:{" "}
                                <LinearSpan color="green">
                                    £{incomeSum.toLocaleString()}
                                </LinearSpan>
                            </h3>
                        </SearchRow>
                        <DataTable
                            labels={["Name", "Amount", "Often", "Subtotal"]}
                            keys={["name", "amount", "often", "subtotal"]}
                            dataKey="id"
                            data={income || []}
                            onRowClick={(data) => setSelectedData(data)}
                            dataPerPage={99999}
                            searchKeys={["name", "amount", "often", "subtotal"]}
                            searchTerm={incomeSearch}
                            columnWidths={["", "120px", "120px", "120px"]}
                        />
                    </div>
                    <div className="table-col">
                        <SearchRow
                            className="header"
                            value={expenditureSearch}
                            setValue={setExpenditureSearch}
                            placeholder="Search by values..."
                        >
                            <h3>
                                Expenditure:{" "}
                                <LinearSpan color="red">
                                    £{expenditureSum.toLocaleString()}
                                </LinearSpan>
                            </h3>
                        </SearchRow>
                        <DataTable
                            labels={["Name", "Amount", "Often", "Subtotal"]}
                            keys={["name", "amount", "often", "subtotal"]}
                            dataKey="id"
                            data={expenditure || []}
                            onRowClick={(data) => setSelectedData({ ...data })}
                            dataPerPage={99999}
                            searchKeys={["name", "amount", "often", "subtotal"]}
                            searchTerm={expenditureSearch}
                            columnWidths={["", "120px", "120px", "120px"]}
                        />
                    </div>
                </StyledPersonalBudgeting>
            </StyledIncomeExpense>
        </>
    );
};

export default IncomeExpense;
