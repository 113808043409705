import { StyledBasicModal } from "./styles";
import { Button } from "..";
import ModalHeader from "./components/ModalHeader";
import ModalButtons from "./components/ModalButtons";
import { btnStyle } from "../Button/types";
import SuccessContent from "./components/SuccessContent";

interface BasicModalProps {
    className?: string;
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    title?: string;
    message: string;
    submitBtnColor?: btnStyle;
    submitBtnText?: string;
    successMessage: string;
    onSubmit: (e: React.FormEvent) => void;
    isSuccess: boolean;
    isLoading: boolean;
    data?: { [key: string]: any };
    children?: React.ReactNode;
    hasCancelBtn?: boolean;
}

const BasicModal: React.FC<BasicModalProps> = ({
    className = "",
    isOpen,
    onClose,
    title = "Please Confirm...",
    message,
    submitBtnColor = "purple",
    submitBtnText = "Submit",
    successMessage,
    onSubmit,
    isSuccess,
    isLoading = false,
    children,
    hasCancelBtn = false,
}) => {
    return (
        <StyledBasicModal
            show={isOpen}
            className={`basic-modal${className ? ` ${className}` : ""}`}
        >
            <ModalHeader onClose={onClose}>{title}</ModalHeader>
            {isSuccess ? (
                <SuccessContent message={successMessage} onClose={onClose} />
            ) : (
                <form onSubmit={onSubmit}>
                    <p style={{ textAlign: "center", marginTop: "8px" }}>
                        {message}
                    </p>
                    {children}
                    <ModalButtons>
                        <Button
                            type="submit"
                            style={submitBtnColor}
                            btnText={submitBtnText}
                            loading={!!isLoading}
                        />
                        {hasCancelBtn && (
                            <Button
                                onClick={() => onClose()}
                                type="button"
                                style="cancel"
                                btnText="Cancel"
                            />
                        )}
                    </ModalButtons>
                </form>
            )}
        </StyledBasicModal>
    );
};

export default BasicModal;
