import styled from "styled-components";
import { theme } from "../../../../global/theme";

export const StyledConfirmation = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    & .header-container {
        &__header {
            padding-inline: 16px;
        }

        &__title {
            font-size: 14px;
        }

        &__ctn {
            background-color: transparent;
            padding: 16px;
            padding-bottom: 0;
        }
    }

    & .data-list {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        gap: 2px 16px;

        & > li {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            font-size: 12px;
            font-weight: 300;
            &[data-invalid="true"] {
                color: ${theme.colors.error};
            }

            & > span {
                font-weight: 400;
            }

            &:last-child:nth-child(2n + 1) {
                grid-column: 1/-1;
            }
        }
    }
`;
