import styled from "styled-components";
import { theme } from "../../../global/theme";
import { mainScrollbar } from "../../../global/theme/reuseable/mixins";

export const StyledModalHeader = styled.header`
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 0.6px solid ${theme?.colors.boxStroke};
    & .modal-header {
        &__heading {
            font-size: 20px;
            color: ${theme?.colors.text};
            text-align: left;
            flex: 1;
            display: flex;
            align-items: center;
            gap: 12px;
        }

        &__close {
            height: 35px;
            width: 35px;
            border-radius: 10px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const StyledSuccessContent = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;

    & .modal-success {
        &__message {
            font-size: 14px;
            font-weight: 300;
        }

        &__close {
            margin-top: 12px;
            width: min(100%, 200px);
            padding: 10px;
            background-color: ${theme?.colors.purple};
            color: ${theme?.colors.white};
            border-radius: 8px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const StyledModalContent = styled.div<{
    height?: string;
    width?: string;
}>`
    overflow: auto;
    ${mainScrollbar(12)};
    height: ${(props) => props.height || "auto"};
    width: ${(props) => props.width || "auto"};
    max-height: calc(100vh - 240px);
    max-width: calc(100vw - 112px);
`;

export const StyledModalButtons = styled.div`
    display: flex;
    gap: 6px;
    justify-content: flex-end;
    margin-top: 16px;

    & .modal-btns {
        &__delete-wrapper {
            flex: 1;
            display: flex;
            justify-content: flex-start;
        }
    }

    & button {
        font-size: 14px;
        padding: 16px 24px;
    }
`;
