import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import ModalHeader from "../components/ModalHeader";
import { StyledCreateCorres } from "./styles";
import SuccessContent from "../components/SuccessContent";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import TextArea from "../../Input/TextArea";
import DropdownSelect from "../../DropdownSelect";
import useReduxClientCorrespondence from "../../../store/hooks/useReduxClientCorrespondence";

interface CreateCorresNotesProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
}

const CreateCorresNotes: React.FC<CreateCorresNotesProps> = ({
    isOpen,
    onClose,
}) => {
    const { setList } = useReduxClientCorrespondence();
    const { clientId } = useParams();
    const [notes, setNotes] = useState("");
    const [debtId, setDebtId] = useState<number | null>(null);
    const isFormValid = !!debtId && notes.trim() !== "";


    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/create-note/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setList(data?.data);
            },
        }
    );

    const getDebtsList = useFetchData({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/get-debts/${clientId}`,
    },{

        fetchOnLoad: true,
    });

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({
            type: "note",
            data: {
                debt_id: debtId,
                notes,
            },
        });
    };

    return (
        <StyledCreateCorres
            className="create-corres create-corres--notes"
            show={isOpen}
            width="500px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={onClose}>
                Compose Correspondence Note
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    onClose={onClose}
                    message="Note has been created."
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <TextArea
                        label="Notes:"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                    <DropdownSelect
                        label="Debt:"
                        options={getDebtsList?.data?.data?.debt || []}
                        isLoading={getDebtsList.isLoading}
                        enableInput
                        value={debtId}
                        optionKey="id"
                        labelKeys={["name"]}
                        onSelect={(option) => setDebtId(option.id)}
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Create"
                            disabled={!isFormValid}
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledCreateCorres>
    );
};

export default CreateCorresNotes;
