import React, { useState, useEffect } from "react";
import DropdownSelect from "..";
import useFetchData from "../../../global/hooks/useFetchData";
import { ReactComponent as TickIcon } from "../../../assets/icons/tick.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";
import StyledBodyVariablesSelect from "./styles";

interface BodyVariablesSelectProps {
    className?: string;
    copyOnSelect?: boolean;
    options?: Array<{ [key: string]: any }>;
}

const BodyVariablesSelect: React.FC<BodyVariablesSelectProps> = ({
    className = "",
    copyOnSelect = true,
    options,
}) => {
    const [selectedValue, setSelectedValue] = useState("");
    const [isCopied, setIsCopied] = useState(false);
    const getVariables = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/body-variables`,
        },
        {
            fetchOnLoad: !!options,
        }
    );

    const testMessageVariablesData = [
        {
            value: "${APP1}",
            label: "Applicant 1",
        },
        {
            value: "${APP2}",
            label: "Applicant 2",
        },
        {
            value: "${ddi}",
            label: "DDI",
        },
        {
            value: "${AccountUser}",
            label: "Account User",
        },
    ];

    const variablesList =
        options || getVariables?.data?.data || testMessageVariablesData || [];

    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => {
                setIsCopied(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [isCopied]);

    const copyStringToClipboard = (text: string) => {
        if (navigator.clipboard) {
            // Clipboard API is available
            navigator.clipboard
                .writeText(text)
                .then(function () {
                    console.log("Text copied to clipboard");
                })
                .catch(function (err) {
                    console.error("Unable to copy text", err);
                });
        }
        setIsCopied(true);
    };

    const selectHandler = (option: { [key: string]: any }) => {
        if (typeof option?.value !== "string") return;
        if (copyOnSelect) return copyStringToClipboard(option.value);
        return setSelectedValue(option.value);
    };

    return (
        <StyledBodyVariablesSelect
            className={`body-variables-select${
                className ? ` ${className}` : ""
            }`}
        >
            <DropdownSelect
                className="body-variables-select__select"
                label="Select Variables:"
                options={variablesList}
                optionKey="value"
                labelKeys={["label"]}
                enableInput
                onSelect={selectHandler}
                placeholder="Please select..."
            />

            <button
                className="body-variables-select__copy"
                type="button"
                onClick={() => copyStringToClipboard(selectedValue)}
                disabled={isCopied}
                data-copied={isCopied}
            >
                {isCopied ? <TickIcon /> : <CopyIcon />}
            </button>
        </StyledBodyVariablesSelect>
    );
};

export default BodyVariablesSelect;
