import React, { useEffect, useState } from "react";
import { icons } from "../../assets/icons";
import Logo from "../../atoms/Logo";
import NavItem from "./NavItem";
import {
    NavWrapper,
    OpenNavPanel,
    OuterNav,
    WhiteBackgroundFade,
} from "./styles";
import useFetchData from "../../global/hooks/useFetchData";
import useReduxUI from "../../store/hooks/useReduxUI";

interface SideNavProps {
    isClientCase: boolean;
}

const UserSideNav: React.FC<SideNavProps> = ({ isClientCase }) => {
    const [open, setOpen] = useState(false);
    const [openPanel, setOpenPanel] = useState(false);
    const { sideNavData, setSideNavData } = useReduxUI();
    const { fetchData, isLoading } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/${
                isClientCase ? "client-case/" : ""
            }nav-bar`,
        },
        {
            onSuccess: (data: any) => {
                if (data?.data?.nav_bar) setSideNavData(data?.data?.nav_bar);
            },
        }
    );

    useEffect(() => {
        fetchData();
    }, [isClientCase]);

    const sideNavEntries: [string, { [key: string]: any }][] =
        Object.entries(sideNavData || {}) || [];
    const renderedLinks = sideNavEntries.map(([key, dataObj]) => (
        <NavItem
            key={key}
            link={{
                path: dataObj?.path,
                icon: `${process.env.REACT_APP_SERVER_ICON_API}${dataObj?.icon}`,
                name: dataObj?.name,
                subNav: !!dataObj?.sublist,
                subLinks: dataObj?.sublist
                    ? Object.values(dataObj.sublist)
                    : undefined,
            }}
            isOpen={open}
            setIsOpen={setOpen}
            isClientCase={isClientCase}
        />
    ));

    const manageClosePanel = () => (!open ? setOpenPanel(false) : null);

    return (
        <OuterNav
            onMouseEnter={() => setOpenPanel(true)}
            onMouseLeave={manageClosePanel}
            open={open}
        >
            <WhiteBackgroundFade open={open}></WhiteBackgroundFade>
            <NavWrapper open={open} className="side-nav">
                <Logo />
                <div className="side-nav__links">
                    {isLoading ? "" : renderedLinks}
                </div>
                {openPanel && (
                    <OpenNavPanel open={open} onClick={() => setOpen(!open)}>
                        <img src={icons?.leftArrow} alt="Close" />
                    </OpenNavPanel>
                )}
            </NavWrapper>
        </OuterNav>
    );
};

export default UserSideNav;
