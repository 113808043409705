import React, { useState } from "react";
import { useParams } from "react-router-dom";
import HeaderContainer from "../../../containers/HeaderContainter";
import Header from "../../../layout/Header";
import { StyledPersonalAccountancyContent } from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";
import { Input, TextArea, Button } from "../../../components";
import { StyledBaseDetailsSubmitCtn } from "../BaseDetails/styles";
import { formatDateString } from "../../../global/utils/helpers";

const PersonalAccountancy = () => {
    const { clientId } = useParams();
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        trdngnme: "",
        ni: "",
        utr: "",
        TrdAddrss: "",
        start_self: "",
        FilingDte: "",
        GG_ID: "",
        GG_Pass: "",
        Sge_ref: "",
        sa2019date: "",
        sa2019lia: "",
        sa2020lia: "",
        sa2020date: "",
        sa2021date: "",
        sa2021lia: "",
    });

    //formatDateString(formData?. || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')

    const getPersonalAccountancy = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/personal-accounts/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data)
                    setFormData(data?.data?.personal_accountancy || []);
            },
        }
    );

    const updatePersonalAccountancy = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/personal-accounts/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data)
                    setFormData(data?.data?.personal_accountancy || []);
            },
            notifyOnSuccess: true,
            notifySuccessMessage: "Update has been saved",
        }
    );

    const inputHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value, name, type } = e.target;
        setFormData((prev) => {
            return { ...prev, [name]: type === "number" ? +value : value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        //console.log(formData);
        updatePersonalAccountancy.fetchData({ data: formData });
    };

    return (
        <>
            <Header
                title="Personal Accountancy"
                hasNavBarAbove
                isSubPath
                hasPrevPath
            />
            <StyledPersonalAccountancyContent>
                <form onSubmit={submitHandler}>
                    <HeaderContainer
                        title="Personal Accountancy"
                        ctnClassName="col-2"
                    >
                        <Input
                            type="text"
                            label="Trading Name: (If Applicable)"
                            value={formData?.trdngnme || ""}
                            name="trdngnme"
                            onChange={inputHandler}
                        />
                        <Input
                            type="text"
                            label="National Insurance Number:"
                            value={formData?.NI || ""}
                            name="NI"
                            onChange={inputHandler}
                        />
                        <Input
                            type="text"
                            label="UTR Number:"
                            value={formData?.UTR || ""}
                            name="UTR"
                            onChange={inputHandler}
                        />
                        <TextArea
                            label="Trading Address: (If Applicable)"
                            value={formData?.TrdAddrss || ""}
                            name="TrdAddrss"
                            onChange={inputHandler}
                        />
                        <Input
                            type="date"
                            label="Self-Employment Start Date:"
                            value={formData?.start_self || ""}
                            name="start_self"
                            onChange={inputHandler}
                        />
                        <Input
                            type="date"
                            label="Filing Date:"
                            value={formData?.FilingDte || ""}
                            name="FilingDte"
                            onChange={inputHandler}
                        />
                        <Input
                            type="text"
                            label="Goverment Gatway Username:"
                            value={formData?.GG_ID || ""}
                            name="GG_ID"
                            onChange={inputHandler}
                        />
                        <Input
                            type="password"
                            label="Goverment Gatway Password:"
                            value={formData?.GG_Pass || ""}
                            name="GG_Pass"
                            onChange={inputHandler}
                            hasShowPassword
                        />
                        <Input
                            type="text"
                            label="Tax Filer Reference Number:"
                            className="full-col"
                            value={formData?.Sge_ref || ""}
                            name="Sge_ref"
                            onChange={inputHandler}
                        />
                        <Input
                            type="date"
                            label="SA2019 Filing Date:"
                            value={formData?.sa2019date || ""}
                            name="sa2019date"
                            onChange={inputHandler}
                        />
                        <Input
                            type="text"
                            label="SA2019 Liability:"
                            value={formData?.sa2019lia || ""}
                            name="sa2019lia"
                            onChange={inputHandler}
                            autoComplete="off"
                        />
                        <Input
                            type="date"
                            label="SA2020 Filing Date:"
                            value={formData?.sa2020date || ""}
                            name="sa2020date"
                            onChange={inputHandler}
                        />
                        <Input
                            type="text"
                            label="SA2020 Liability:"
                            value={formData?.sa2020lia || ""}
                            name="sa2020lia"
                            onChange={inputHandler}
                            autoComplete="off"
                        />
                        <Input
                            type="date"
                            label="SA2021 Filing Date:"
                            value={formData?.sa2021date || ""}
                            name="sa2021date"
                            onChange={inputHandler}
                        />
                        <Input
                            type="text"
                            label="SA2021 Liability:"
                            value={formData?.sa2021lia || ""}
                            name="sa2021lia"
                            onChange={inputHandler}
                            autoComplete="off"
                        />
                    </HeaderContainer>
                    <StyledBaseDetailsSubmitCtn>
                        <Button
                            type="submit"
                            btnText="Save Personal Accountancy"
                            loading={updatePersonalAccountancy.isLoading}
                            style="purple"
                        />
                    </StyledBaseDetailsSubmitCtn>
                </form>
            </StyledPersonalAccountancyContent>
        </>
    );
};

export default PersonalAccountancy;
