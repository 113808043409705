import { useState } from "react";
import { StyledHeaderContainer } from "./styles";
import { ReactComponent as ArrowIcon } from "../assets/icons/whiteDownArrow.svg";

export type HeaderStyles = "lightBlue" | "gradientBlue";

interface HeaderContainerProps {
    className?: string;
    title?: JSX.Element | string;
    headingElement?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
    headerContent?: JSX.Element | string;
    children: React.ReactNode;
    isExpandable?: boolean;
    headerStyle?: HeaderStyles;
    ctnClassName?: string;
}

const HeaderContainer: React.FC<HeaderContainerProps> = ({
    className = "",
    children,
    headingElement = "h2",
    title = "",
    headerContent = "",
    isExpandable = false,
    headerStyle = "gradientBlue",
    ctnClassName = "",
}) => {
    const HeadingElement = headingElement;
    const [isExpanded, setIsExpanded] = useState(false);

    const expandHandler = () => {
        setIsExpanded((prev) => !prev);
    };
    return (
        <StyledHeaderContainer
            className={`header-container${className ? ` ${className}` : ""}`}
            headerStyle={headerStyle}
            aria-expanded={isExpandable ? isExpanded : true}
        >
            <header className="header-container__header">
                <HeadingElement className="header-container__title">
                    {title}
                </HeadingElement>
                {headerContent && (
                    <div className="header-container__header-content">
                        {headerContent}
                    </div>
                )}
                {isExpandable && (
                    <button
                        type="button"
                        className={`header-container__expand${
                            isExpanded ? " expanded" : ""
                        }`}
                        onClick={expandHandler}
                    >
                        <ArrowIcon />
                    </button>
                )}
            </header>
            <div
                className="header-container__ctn-wrapper"
                // aria-expanded={isExpandable ? isExpanded : true}
            >
                <div
                    className={`header-container__ctn ${
                        ctnClassName ? ` ${ctnClassName}` : ""
                    }`}
                >
                    {children}
                </div>
            </div>
        </StyledHeaderContainer>
    );
};

export default HeaderContainer;
