import { theme } from "../theme";
import { css } from "styled-components";

export const centeredRowMixin = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const columnMixin = css`
    display: flex;
    flex-direction: column;
`;

export const roundedHeaderMixin = css`
    background: ${theme?.gradients?.buttonBlue};
    border-radius: 10px 10px 0px 0px;

    ${centeredRowMixin};

    justify-content: space-between;
    padding-left: 34px;
    padding-right: 25px;
`;

export const paragraphMixin = (
    fontWeight: string,
    fontColor: "darkText" | "greyText"
) => css`
    font-weight: ${fontWeight};
    font-size: 12px;
    line-height: 18px;
    color: ${fontColor === "darkText"
        ? `${theme?.colors?.darkText};`
        : "#5e5e5e"};
`;

export const hideScrollbar = css`
    scrollbar-width: none;
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;

export const mainScrollbar = (scrollbarSize = 18) => css`
    scrollbar-color: #ced2d4;
    &::-webkit-scrollbar {
        width: ${scrollbarSize}px;
        height: ${scrollbarSize}px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
        background-clip: padding-box;
        border: none;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: ${scrollbarSize / 4}px solid transparent;
        background-color: #ced2d4;
        background-clip: padding-box;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #c0cacd;
    }
    &::-webkit-scrollbar-thumb:active {
        background-color: #b2bdc1;
    }
`;

export const transparentGradient = (gradients = theme.gradients.skyBlue) => css`
    background-color: transparent;
    background-image: ${gradients};
    -webkit-background-clip: text;
    background-clip: text;
    background-size: cover;
    color: transparent;
    border: none;
`;

export const lineClamp = (numOfLine: 2) => css`
    display: -webkit-box;
    overflow: hidden;
    line-clamp: ${numOfLine};
    box-orient: vertical;
    -webkit-line-clamp: ${numOfLine};
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
`;

export const slideInMixin = css`
    position: fixed;
    right: 0;
    top: 0px;
    height: 100vh;
    z-index: 10;
`;

export const diaryStatus = (
    status?: number,
    backgroundOnly: boolean = false
) => css`
    border-color: ${() => {
        if (backgroundOnly) return "transparent";
        if (status === 0) return "#1a89f9";
        if (status === 1) return "#c5c5c5";
        if (status === 2) return "#00d44a";
        if (status === 3) return "#da1600";
        return theme?.colors.shade;
    }};
    background-color: ${() => {
        if (backgroundOnly) {
            if (status === 0) return "#1a89f9";
            if (status === 1) return "#ffffff";
            if (status === 2) return "#00d44a";
            if (status === 3) return "#da1600";
        }
        if (status === 0) return "#eff7ff";
        if (status === 1) return "#ffffff";
        if (status === 2) return "#f2fff6";
        if (status === 3) return "#fff3f2";

        return theme?.colors.shade;
    }};
`;

export const diaryStatusText = (status?: number) => css`
    ${() => {
        if (status === 0) return "#1a89f9";
        if (status === 1) return theme.colors.darkText;
        if (status === 2) return "#00d44a";
        if (status === 3) return "#da1600";
        return theme.colors.darkText;
    }}
`;
