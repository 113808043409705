import DropdownMenu from "../../../components/DropdownMenu";
import DropdownMeunItem from "../../../components/DropdownMenu/DropdownMenuItem";
import useReduxUser from "../../../store/hooks/useReduxUser";

interface UserProps {
    username?: string;
}
const User: React.FC<UserProps> = ({ username }) => {
    const { logoutUser } = useReduxUser();
    return (
        <DropdownMenu title={username || "User"}>
            <DropdownMeunItem
                elementType="link"
                title="Profile"
                to="/settings"
                iconName="profile"
            />
            <DropdownMeunItem
                elementType="link"
                title="Logout"
                to="/login"
                onClick={logoutUser}
                iconName="logout"
                colorMode="odd"
            />
        </DropdownMenu>
    );
};

export default User;
