import React, { SetStateAction } from "react";

interface LiabilityContactProps {
    contact: { [key: string]: any };
}

const LiabilityContact: React.FC<LiabilityContactProps> = ({ contact }) => {
    console.log(contact);
    return (
        <ul className="liability-main__content data-list">
            <li>
                Name:<span>{contact?.name || "-"}</span>
            </li>
            <li>
                Address:<span>{contact?.address || "-"}</span>
            </li>
            <li>
                Telephone 1:<span>{contact?.tel_1 || "-"}</span>
            </li>
            <li>
                Telephone 2:<span>{contact?.tel_2 || "-"}</span>
            </li>
            <li>
                Email:<span>{contact?.email || "-"}</span>
            </li>
            <li>
                Min Payment:<span>£{contact?.min_payment || "-"}</span>
            </li>
            <li>
                Account Name:<span>{contact?.account_name || "-"}</span>
            </li>
            <li>
                Account Number:<span>{contact?.account_number || "-"}</span>
            </li>
            <li>
                Sort Code:<span>{contact?.sort_code || "-"}</span>
            </li>
        </ul>
    );
};

export default LiabilityContact;
