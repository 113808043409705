import { useState, useRef } from "react";
import StyledDropdownMenu, { DropdownMenuColor } from "./styles";
import useClickOutside from "../../global/hooks/useClickOutside";
import { ReactComponent as ArrowIcon } from "../../assets/icons/whiteDownArrow.svg";
import { LoadingSpinner } from "../Button/styles";

interface DropdownMenuProps {
    className?: string;
    title?: string;
    children?: React.ReactNode;
    isHidden?: boolean;
    color?: DropdownMenuColor;
    isLoading?: boolean;
    disabled?: boolean;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
    className,
    title,
    children,
    isHidden,
    color = "white",
    isLoading = false,
    disabled = false,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useClickOutside(ref, () => setIsMenuOpen(false));

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    if (isHidden) return <></>;
    return (
        <StyledDropdownMenu
            className={`dropdown-menu${className ? ` ${className}` : ""}`}
            onClick={toggleMenu}
            color={color}
            aria-expanded={isMenuOpen && !disabled}
            aria-disabled={disabled}
            data-loading={isLoading}
            ref={ref}
        >
            <p>{title}</p>
            {isLoading && (
                <LoadingSpinner size={16} className="dropdown-menu__spinner" />
            )}
            <ArrowIcon
                className="dropdown-menu__arrow"
                aria-expanded={isMenuOpen}
            />
            {isMenuOpen && (
                <div className="dropdown-menu__menu">{children}</div>
            )}
        </StyledDropdownMenu>
    );
};

export default DropdownMenu;
