import React, { useState, useEffect } from "react";
import SendMessage from "../../../../../containers/User/Settings/Templates/SendMessage";
import { Button } from "../../../../../components";
import { Modal } from "../../../../../components";

import Header from "../../../../../layout/Header";
import SearchRow from "../../../../../components/SearchRow";
import TemplateCard from "../../../../../components/Cards/TemplateCard";
import TextEditor from "../../../../../components/TextEditor";
import EmailFormContainer from "./EmailFormContainer";
import EmailTemplateModal from "../../../../../components/Modals/Settings/MessageTemplates/EmailTemplateModal";
import useReduxSettings from "../../../../../store/hooks/useReduxSettings";
import useFetchData from "../../../../../global/hooks/useFetchData";
import { PageContent } from "../../../../../pages/styles";

const Sms: React.FC = () => {
    const { templates, setTemplate } = useReduxSettings();
    const [showModal, setShowModal] = useState(false);
    const [editModalId, setEditModalId] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [appliedTemplate, setAppliedTemplate] = useState<{
        [key: string]: any;
    } | null>(null);
    const [client, setClient] = useState<{ [key: string]: any }[] | null>(null);

    const emailTemplates = templates.email || [];

    useEffect(() => {
        setTemplate("email", emailTemplates);
    }, []);

    const getEmailTemplates = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/settings-template-email`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setTemplate("email", data?.data?.email);
                setClient(data?.data);
            },
        }
    );

    const selectedTemplate = emailTemplates?.find(
        (data) => data.id === selectedId
    );

    const filteredData = emailTemplates.filter((data) => {
        return (
            data.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            data.department.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const editTemplateHandler = (id: number) => {
        setEditModalId(id);
    };

    const applyTemplateHandler = (id: number) => {
        const bodyContent = emailTemplates?.find((data) => data.id === id);
        if (bodyContent) setAppliedTemplate(bodyContent);
    };

    const clearHandler = () => {
        setSelectedId(null);
        setAppliedTemplate(null);
    };

    return (
        <>
            <Modal
                isVisible={showModal}
                children={
                    <EmailTemplateModal
                        setCreateNewTemplate={setShowModal}
                        open={showModal}
                        mode="create"
                    />
                }
            />
            <Modal
                isVisible={!!editModalId}
                children={
                    <EmailTemplateModal
                        setCreateNewTemplate={() => setEditModalId(null)}
                        open={!!editModalId}
                        mode="edit"
                        data={selectedTemplate}
                    />
                }
            />
            <Header title="send email" prevPath="/settings" isSubPath />
            <PageContent>
                <SendMessage
                    leftContent={
                        <EmailFormContainer
                            templateData={appliedTemplate}
                            client={client}
                            onClear={clearHandler}
                        />
                    }
                    isRightContentLoading={getEmailTemplates.isLoading}
                    rightContent={
                        <>
                            <SearchRow
                                placeholder="Search by name or department..."
                                value={searchTerm}
                                setValue={setSearchTerm}
                            >
                                <Button
                                    onClick={() => setShowModal(true)}
                                    type="button"
                                    style="mint"
                                    btnText="Create New Template"
                                    paddingInline="16px"
                                />
                            </SearchRow>
                            <p className="message-template__subject">
                                Subject:{" "}
                                <span>{selectedTemplate?.subject || ""}</span>
                            </p>
                            <TextEditor
                                value={selectedTemplate?.body || ""}
                                previewMode
                                isPreviewSelected={!!selectedId}
                            />
                            {filteredData?.length ? (
                                <ul className="message-template__list">
                                    {filteredData.map((data) => (
                                        <li
                                            className="message-template__item"
                                            key={data?.id}
                                        >
                                            <TemplateCard
                                                id={data?.id}
                                                name={data?.title}
                                                type={data?.department}
                                                date={data?.updated_at}
                                                body={data?.body}
                                                onSelect={setSelectedId}
                                                isSelected={
                                                    selectedId === data?.id
                                                }
                                                onEdit={editTemplateHandler}
                                                onApply={applyTemplateHandler}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No Template found.</p>
                            )}
                        </>
                    }
                />
            </PageContent>
        </>
    );
};

export default Sms;
