import { TableRowProps } from "..";
import { ReactComponent as TickIcon } from "../../../../assets/icons/greenTick.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/redCross.svg";
import { IconBlock } from "../styles";

const PrintListRow: React.FC<TableRowProps> = ({ data }) => {
    const activeIcon = () => {
        if (data?.is_active === 1) return <TickIcon />;
        if (data?.is_active === 0) return <CrossIcon />;
        return "-";
    };
    return (
        <tr>
            <td>{data?.name ?? "-"}</td>
            <td>{data?.letterhead ?? "-"}</td>
            <td>{data?.plain ?? "-"}</td>
            <IconBlock width="30px">{activeIcon()}</IconBlock>
        </tr>
    );
};
export default PrintListRow;
