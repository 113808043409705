import DataTable from "../../../../components/DataTable";
import OpenCaseClient from "../../../../components/DataTable/actions/OpenCaseClient";

interface SalesSummaryTablesProps {
    data: { [key: string]: any };
    selectedTableIndex: number;
    searchTerm: string;
    isLoading: boolean;
}

const SalesSummaryTables: React.FC<SalesSummaryTablesProps> = ({
    data,
    selectedTableIndex,
    searchTerm,
    isLoading,
}) => {
    if (selectedTableIndex === 1) {
        return (
            <DataTable
                labels={data?.pack_sent?.headings}
                data={data?.pack_sent?.data}
                dataKey="Case Id"
                isLoading={isLoading}
                searchTerm={searchTerm}
                searchKeys={["Case Id", "Customer"]}
                formatKeys={{ Created: "time" }}
            />
        );
    } else if (selectedTableIndex === 2) {
        return (
            <DataTable
                labels={data?.signable_leads?.headings}
                data={data?.signable_leads?.data}
                dataKey="Id"
                isLoading={isLoading}
                action={OpenCaseClient}
                hideColums={[0]}
                searchTerm={searchTerm}
                searchKeys={["Case Id"]}
                formatKeys={{ Created: "time" }}
            />
        );
    } else if (selectedTableIndex === 3) {
        return (
            <DataTable
                labels={data?.pack_back?.headings}
                data={data?.pack_back?.data}
                dataKey="case_id"
                isLoading={isLoading}
                searchTerm={searchTerm}
                searchKeys={["case_id"]}
                formatKeys={{
                    Created: "time",
                    "`Pack Sent`": "time",
                    "Pack Back": "time",
                    "`Next Payment`": "date",
                }}
                minWidth="1500px"
            />
        );
    } else if (selectedTableIndex === 4) {
        return (
            <DataTable
                labels={data?.ip1?.headings}
                data={data?.ip1?.data}
                dataKey="Id"
                isLoading={isLoading}
                searchTerm={searchTerm}
                searchKeys={["Case Id", "Customer"]}
                formatKeys={{ Created: "time" }}
            />
        );
    } else {
        return (
            <DataTable
                labels={data?.incomplete_leads?.headings}
                data={data?.incomplete_leads?.data}
                dataKey="Case Id"
                isLoading={isLoading}
                action={OpenCaseClient}
                searchTerm={searchTerm}
                searchKeys={["Case Id", "Customer"]}
                formatKeys={{ Created: "time" }}
            />
        );
    }
};

export default SalesSummaryTables;
