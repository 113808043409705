import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../../../layout/Header/NavBar";

const Administration: React.FC = () => {
    return (
        <>
            <NavBar subListName="administration" />
            <Outlet />
        </>
    );
};

export default Administration;
