import { SetStateAction, useState } from "react";
import ModalHeader from "../components/ModalHeader";
import { StyledAgentPopUp } from "./styles";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import FileUpload, { FileData } from "../../FileUpload";

interface AgentPopUpProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
    uploadKeys: string[];
}
const AgentPopUp: React.FC<AgentPopUpProps> = ({
    isOpen,
    setIsOpen,
    uploadKeys,
}) => {
    const [uploadedTypes, setUploadedTypes] = useState<string[]>([]);
    const closeModal = () => {
        setIsOpen(false);
    };
    const { clientId } = useParams();
    const uploadItemsData = [
        {
            label: "Letter of Authority",
            name: "loa",
        },
        {
            label: "ID",
            name: "id",
        },
        {
            label: "AML",
            name: "aml",
        },
        {
            label: "Bank Details",
            name: "bank_details",
        },
        {
            label: (
                <>
                    Payment Details<span>(Card Information / Taken)</span>
                </>
            ),
            name: "payment_details",
        },
        {
            label: "Creditor Reference Number",
            name: "creditor_ref_number",
        },
    ];
    const isUploadFile = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/upload/${clientId}`,
        },
        {    fetchOnLoad:true,
            onSuccess: (data) => {
                if (data?.data) setUploadedTypes(data?.data);
            },
        }
    );

    const uploadFile = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/case-upload-dashboard/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setUploadedTypes(data?.data);
            },
        }
    );

    const uploadHandler = (
        file: FileData,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        console.log(file, e.target.name);
        const formData = new FormData();
        formData.append("type", e.target.name);
        if (file?.file) formData.append("file", file?.file);
        if (file?.name) formData.append("file_name", file?.name);
        uploadFile.fetchData(formData);
    };
    return (
        <StyledAgentPopUp show={isOpen} className="agent-pop-up">
            <ModalHeader onClose={closeModal}>Agent Pop-up</ModalHeader>
            <div className="agent-pop-up__content">
                <p>The following information is missing:</p>
                <ul className="agent-pop-up__list">
                    {uploadItemsData.map((data) =>
                        uploadKeys.includes(data.name) ? null : (
                            <li className="agent-pop-up__item" key={data.name}>
                                <p>{data.label}</p>
                                <FileUpload
                                    onUploaded={uploadHandler}
                                    accept=".jpg,.jpeg"
                                    name={data.name}
                                    showRequirement={false}
                                    maxFileSize={10}

                                    // isLoading={uploadFile.isLoading}
                                >
                                    {uploadedTypes.includes(data.name)
                                        ? "Uploaded"
                                        : "Upload"}
                                </FileUpload>
                            </li>
                        )
                    )}
                </ul>
            </div>
        </StyledAgentPopUp>
    );
};

export default AgentPopUp;
