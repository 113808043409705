import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import ModalHeader from "../components/ModalHeader";
import { StyledCreateCorres } from "./styles";
import SuccessContent from "../components/SuccessContent";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import TextArea from "../../Input/TextArea";
import DropdownSelect from "../../DropdownSelect";
import useReduxClientCorrespondence from "../../../store/hooks/useReduxClientCorrespondence";

interface CreateCorreCallProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    isCreatingOnList?: boolean;
}

const CreateCorresSMS: React.FC<CreateCorreCallProps> = ({
    isOpen,
    onClose,
    isCreatingOnList = true,
}) => {
    const { setList, setSms } = useReduxClientCorrespondence();
    const { clientId } = useParams();
    const [smsData, setSmsData] = useState<{ [key: string]: any }>({
        receiver_id: null,
        body: "",
        id: null,
    });
    const isFormValid = !!smsData.receiver_id;

    const getReceiverList = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/correspondence-receivers-sms/${clientId}`,
        },
        {
            fetchOnLoad: true,
        }
    );

    const getTemplateList = useFetchData({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_API}/sms-templates`,
    });

    const createSmsOnList = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/correspondence/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setList(data?.data);
            },
        }
    );

    const createSmsOnSms = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/send-sms/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setSms(data?.data);
            },
        }
    );
    const isLoading = createSmsOnList.isLoading || createSmsOnSms.isLoading;
    const isSuccess = createSmsOnList.isSuccess || createSmsOnSms.isSuccess;

    const inputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setSmsData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const createBodySMS = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/create-sms-body/${clientId}`,
        },
        {
            onSuccess: (data) => {
                setSmsData((prev) => {
                    return { ...prev, ["body"]: data?.data };
                });
            },
        }
    );

    const selectHandler = (key: string, value: any) => {
        setSmsData((prev) => {
            return { ...prev, ["id"]: value?.id };
        });

        createBodySMS.fetchData({ data: value?.id || null });
    };
    const selectHandlerUser = (key: string, value: any) => {
        setSmsData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (isCreatingOnList) {
            createSmsOnList.fetchData({
                type: "sms",
                data: smsData,
            });
        } else {
            createSmsOnSms.fetchData({ data: smsData });
        }
    };

    return (
        <StyledCreateCorres
            className="create-corres create-corres--sms"
            show={isOpen}
            width="660px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={onClose}>Compose SMS</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    onClose={onClose}
                    message="SMS has been sent."
                />
            ) : (
                <form onSubmit={submitHandler} className="col-2">
                    <DropdownSelect
                        label="Send To:"
                        options={getReceiverList?.data?.data?.users || []}
                        isLoading={getReceiverList.isLoading}
                        value={smsData?.debt_id ?? null}
                        optionKey="id"
                        labelKeys={["mobile"]}
                        onSelect={(option) =>
                            selectHandlerUser("receiver_id", option.id)
                        }
                    />
                    <DropdownSelect
                        label="Template:"
                        options={getReceiverList?.data?.data?.template || []}
                        isLoading={getTemplateList.isLoading}
                        enableInput
                        optionKey="id"
                        labelKeys={["title"]}
                        onSelect={(option) =>
                            selectHandler("data", option || "")
                        }
                    />
                    <TextArea
                        label="Message:"
                        value={smsData.body}
                        onChange={inputHandler}
                        name="body"
                        rows={8}
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Send"
                            disabled={!isFormValid}
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledCreateCorres>
    );
};

export default CreateCorresSMS;
