import React from "react";
import moment from "moment";
import { useState } from "react";
import { TimeTableRow as Row } from "./styles";

interface TimeTableRowProps {
    hour: number;
    expandable?: boolean;
    children: React.ReactNode;
}

const TimeTableRow: React.FC<TimeTableRowProps> = ({
    hour,
    children,
    expandable = false,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);



    const expandHandler = () => {
        setIsExpanded((prev) => !prev);
    };
    return (
        <Row className="time-table-row" key={hour}>
            {expandable ? (
                <button
                    className="time-table-row__expand"
                    onClick={expandHandler}
                    type="button"
                >
                    {isExpanded ? "-" : "+"}
                </button>
            ) : null}
            <span className="time-table-row__time">
                {moment(hour, "hh").format("h A")}
            </span>
            <div
                className="time-table-row__ctn"
                aria-expanded={expandable ? isExpanded : true}
            >
                {children}
            </div>
        </Row>
    );
};

export default TimeTableRow;
