import React, { useState } from "react";
import SearchRow from "../../../components/SearchRow";
import DataTable from "../../../components/DataTable";
import AccessLogsRow from "../../../components/DataTable/TableRows/Accounts/AccessLogsRow";
import useFetchData from "../../../global/hooks/useFetchData";

const AccessLogs = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [userLogs, setAccessLogs] = useState<Array<{ [ket: string]: any }>>(
        []
    );
    const tableKeyheadings = {
        name: "Name",
        datetime: "When",
        ip: "IP Address",
        browser: "Broswer",
        os: "OS",
        status: "Status",
    };

    const { data, fetchData, isLoading } = useFetchData(
        {
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_API}/user-logs`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) {
                    setAccessLogs(data?.data?.logs);
                }
            },
        }
    );

    return (
        <>
            <SearchRow
                value={searchTerm}
                setValue={setSearchTerm}
                placeholder="Search by name..."
            />
            <DataTable
                labels={Object.values(tableKeyheadings)}
                keys={Object.keys(tableKeyheadings)}
                data={userLogs}
                dataKey="id"
                isLoading={isLoading}
                searchTerm={searchTerm}
                tableRow={AccessLogsRow}
                searchKeys={["name"]}
            />
        </>
    );
};

export default AccessLogs;
