import { PaymentDashboardCardWrapper } from "./styles";
import { iPaymentDashboardCard } from "./types";

const PaymentDashboardCard = ({
  title,
  img,
  onClick,
}: iPaymentDashboardCard) => {
  return (
    <PaymentDashboardCardWrapper onClick={onClick}>
      <img src={img} alt={title} />
      <h4>{title}</h4>
    </PaymentDashboardCardWrapper>
  );
};

export default PaymentDashboardCard;
