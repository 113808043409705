import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import Applicant from "../../User/Client/ClientCreate/PersonalInfo/Applicant";
import Other from "../../User/Client/ClientCreate/PersonalInfo/Other";
import { StyledPersonalInfo } from "../../User/Client/ClientCreate/PersonalInfo/styles";
import useReduxClientBaseDetails from "../../../store/hooks/useReduxClientBaseDetails";
import useFetchData from "../../../global/hooks/useFetchData";
import { PageContent } from "../../../pages/styles";
import { StyledBaseDetailsSubmitCtn } from "./styles";
import { Button } from "../../../components";

const Applicants = () => {
    const { clientId } = useParams();
    const { applicants, setBaseDetailsbyKey } = useReduxClientBaseDetails();
    const [applicant1, setApplicant1] = useState<{ [key: string]: any } | null>(
        {}
    );
    const [applicant2, setApplicant2] = useState<{ [key: string]: any } | null>(
        {}
    );

    const getApplicants = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/applicants/${clientId}`,
        },
        {
            fetchOnLoad: true,
        }
    );

    const updateApplicants = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) {
                    setBaseDetailsbyKey(
                        "applicants",
                        data?.data?.personal_info
                    );
                }
            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(applicant1, applicant2);
        if (clientId) {
            const data = {
                id: +clientId,
                tab: "personal_info",
                data: [{ applicant1 }, { applicant2 }],
            };
            updateApplicants.fetchData(data);
        }
    };
    return (
        <>
            <Header title="Applicants" hasNavBarAbove hasPrevPath />
            <PageContent style={{ position: "relative" }}>
                <StyledPersonalInfo
                    className="client-form__content personal-info"
                    onSubmit={submitHandler}
                >
                    <Applicant
                        primary
                        data={applicant1}
                        setData={setApplicant1}
                    />
                    <Applicant
                        primary={false}
                        data={applicant2}
                        setData={setApplicant2}
                    />
                    <Other data={applicant1} setData={setApplicant1} />
                    <StyledBaseDetailsSubmitCtn>
                        <Button
                            type="submit"
                            btnText="Save Applicants"
                            style="purple"
                            loading={updateApplicants.isLoading}
                        />
                    </StyledBaseDetailsSubmitCtn>
                </StyledPersonalInfo>
            </PageContent>
        </>
    );
};

export default Applicants;
