import styled from "styled-components";

export const StyledLiabilityRow = styled.tr`
    &.inactive {
        & * {
            text-decoration-line: line-through;
            color: #aaaaaa;
        }
    }

    & .status {
        display: flex;
        align-items: center;
        gap: 12px;

        & ul {
            list-style: inside;
        }
    }
`;
