import styled from "styled-components";
import { theme } from "../../../global/theme";

export const TopSection = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    max-width: 1000px;
    gap: 24px;

    & > :first-child {
        grid-row: 1/-1;
        align-self: flex-start;
    }

    & .detail-card {
        display: flex;
        flex-direction: column;

        &__btns {
            flex: 1;
            display: flex;
            flex-direction: column-reverse;
        }
    }

    @media screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(6, 1fr);

        & > :first-child {
            grid-column: 1 / span 1;
            grid-row-end: span 3;
            height: 100%;
        }

        & > *:nth-child(n + 2) {
            grid-row-end: span 2;
        }

        & > *:nth-child(4) {
            grid-row-end: span 3;
            height: 100%;
        }
    }

    /* & > :nth-child(4) {
        grid-area: 2/2 / span 1 / span 1;
    }
    & > :nth-child(5) {
        grid-area: 2/3 / span 1 / span 1;
    } */
`;

export const Divider = styled.div`
    display: flex;
    align-items: center;
    gap: 26px;
    margin-top: 25px;
    margin-bottom: 23px;
    h5 {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: ${theme?.colors?.darkText};
    }
    .rule {
        width: 100%;
        height: 1px;
        background-color: rgba(8, 42, 82, 0.3);
    }
`;

export const DebtsWrapper = styled.section`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 26px;
    grid-column-gap: 22px;
`;

export const CreditorsWrappers = styled.article`
    .nav {
        margin-bottom: 30px;
        button,
        .searchInput {
            height: 40px;
            min-width: 132px;
        }
        .searchInput {
            width: 303px;
        }
    }
`;

export const BottomSection = styled.section`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
`;
