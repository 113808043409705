import { TableCheckbox, TableRowProps } from "../";
import {
    formatTimeString,
    viewUploadOnNewWindow,
} from "../../../../global/utils/helpers";
import { ColoredBlock } from "../styles";

const InvoiceRow: React.FC<TableRowProps> = ({
    data,
    setSelectedIds,
    selectedIds,
}) => {
    const statusColor = (value: string) => {
        if (value === "Paid") return "green";
        if (value === "In Process") return "yellow";
        if (value === "Declined") return "red";
        return "";
    };
    const clickHandler = () => {
        //viewUploadOnNewWindow(data.url);
    };

    return (
        <tr role="button" onClick={clickHandler}>
            <td>{data?.ID ?? "-"}</td>
            <td>{data?.Type ?? "-"}</td>
            <td>{data?.Amount ?? "-"}</td>
            <td>{data?.VAT ?? "-"}</td>
            <td>{data?.Total ?? "-"}</td>
            <td>{data?.['Payment Reference'] ?? "-"}</td>
            <td>{formatTimeString(data?.Taken ?? "-")}</td>
            <ColoredBlock color={statusColor(data?.Status ?? "-")}>
                {data?.Status ?? "-"}
            </ColoredBlock>
        </tr>
    );
};
export default InvoiceRow;
