import styled from "styled-components";

export const StyledCreditorsRow = styled.tr`
    & td {
        &:nth-child(1) {
            width: 25%;
            min-width: 240px;
        }
        &:nth-child(2) {
            width: 160px;
        }
        &:nth-child(3) {
        }
        &:nth-child(4) {
            width: 120px;
        }
        &:nth-child(5) {
            width: 120px;
        }
        &:nth-child(6) {
            width: 80px;
        }
        &:nth-child(7) {
            width: 100px;
        }
    }
`;

export const CreditorStatusBlock = styled.td<{ value: string }>`
    &.creditor-row-status {
        font-weight: 600;

        color: ${({ value }) => {
            if (value === "Verified") return "#09b33c";
            if (value === "DO NOT USE") return "#f21616";
            if (value === "Unverified") return "#eec10c";
            return "";
        }};
    }
`;

export const StyledDebtStatusTd = styled.td`
    min-width: 250px;

    & li {
        list-style: inside;
    }
`;
