import BusinessAccountancy from "./BusinessAccountancy";
import PersonalAccountancy from "./PersonalAccountancy";
import PersonalBudgeting from "./PersonalBudgeting";
import { ClientFormStepProps } from "../ClientForm";

interface ProductInfoProps extends ClientFormStepProps {
    typeId: number | null;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
    typeId,
    setCurrentStep,
    setCurrentProductTypeId,
    currentStep,
    isFirstStep,
    isLastStep,
}) => {
    const stepFormProps: ClientFormStepProps = {
        setCurrentStep,
        currentStep,
        isFirstStep,
        isLastStep,
        setCurrentProductTypeId,
    };
    if (typeId === 5) return <PersonalBudgeting {...stepFormProps} />;
    if (typeId === 101) return <PersonalAccountancy {...stepFormProps} />;
    if (typeId === 102) return <BusinessAccountancy {...stepFormProps} />;
    return <></>;
};

export default ProductInfo;
