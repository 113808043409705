import styled from "styled-components";
import { theme } from "../../global/theme";
import { mainScrollbar } from "../../global/theme/reuseable/mixins";

const StyledTextEditor = styled.div`
    display: flex;
    flex-direction: column;

    &[aria-readonly="true"] {
        & * {
            border-radius: 8px;
        }

        & .se-container {
            height: 152px;
        }

        & .se-toolbar {
            display: none;
        }

        & .se-wrapper {
            max-height: 152px;
        }

        & .se-placeholder {
            padding: 0;
            font-weight: 300;
            font-size: 14px;
            text-align: center;
            height: 100%;
            line-height: 152px;
            justify-content: center;
            align-items: center;
        }

        & .se-wrapper-inner {
            max-height: 152px;
            overflow: auto;
            ${mainScrollbar(12)}
        }
    }
    & .text-editor {
        &__label {
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 4px;
            text-align: left;
        }

        &__field {
            flex: 1;
            display: flex;
            flex-direction: column;
            &--disabled {
                height: 120px;
                flex: initial;
                padding: 0 !important;
            }
        }

        &__textarea {
            border: 0.6px solid ${theme?.colors.shade};
            padding: 8px;
            border-radius: 4px;
            ${mainScrollbar(12)};
            overflow: auto;
            width: 100%;
            resize: none;
            outline: none;
            height: 120px;

            &[readonly] {
                height: 152px;
                flex: initial;

                &::placeholder {
                    text-align: center;
                    line-height: 130px;
                    font-weight: 300;
                }
            }

            &:focus {
                border-color: ${theme.colors.boxStroke};
            }
        }
    }

    .se-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    & .se-wrapper {
        flex: 1 1 0;
        display: flex;

        flex-direction: column;
        justify-content: flex-start;
        min-height: 160px;
        overflow: auto;
        ${mainScrollbar(12)}
    }

    & .se-btn-tray {
        padding: 6px;
        padding-bottom: 0;
    }

    & .se-btn {
        height: 30px;
        width: 30px;

        &.se-btn-select {
            & > span {
                transform: translateY(-1px);
            }
        }
    }

    & .se-wrapper-inner.se-wrapper-wysiwyg.se-read-only {
        min-height: 152px;
        max-height: 152px;
    }

    & .se-wrapper-inner {
        flex: 1;
        padding: 8px;
        height: 100%;

        & * {
            margin-bottom: 0;
        }
    }

    & .sun-editor {
        flex: 1;
        z-index: 1;
    }
`;

export default StyledTextEditor;
