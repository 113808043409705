import { useState } from "react";
import ModalHeader from "../components/ModalHeader";
import StyledCreateComplaint from "./styles";
import TextArea from "../../Input/TextArea";
import useFetchData from "../../../global/hooks/useFetchData";
import { useParams } from "react-router-dom";
import useReduxClientComplaints from "../../../store/hooks/useReduxClientComplaints";
import SuccessContent from "../components/SuccessContent";
import DropdownSelect from "../../DropdownSelect";
import { formatTimeString } from "../../../global/utils/helpers";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";

interface CreateComplaintProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    mode?: "create" | "edit";
    data?: { [key: string]: any };
}

const CreateComplaint: React.FC<CreateComplaintProps> = ({
    isOpen,
    onClose,
    mode = "create",
    data,
}) => {
    const { clientId } = useParams();
    const { setComplaints } = useReduxClientComplaints();
    const [users, setUsers] = useState([]);
    const [description, setDescription] = useState(data?.description || "");
    const [actionedId, setActionedId] = useState<number | null>(
        data?.action_by ?? null
    );
    const [actionTaken, setActionTaken] = useState(data?.action || "");
    const isFormValid = description.trim() !== "" && !!actionedId;

    const getManagement = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/list-users/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                setUsers(data?.data || []);
            },
        }
    );
    const createComplaint = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/create-complaints/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setComplaints(data.data?.complaints);
            },
        }
    );
    const updateComplaint = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/update-complaints/${data?.id}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setComplaints(data.data?.complaints);
            },
        }
    );
    const isSuccess = createComplaint.isSuccess || updateComplaint.isSuccess;
    const isLoading = createComplaint.isLoading || updateComplaint.isLoading;

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (!clientId) return;
        const requestData = {
            client_id: +clientId,
            description,
            action_by: actionedId,
            action: actionTaken,
        };
        if (mode === "edit" && data?.id) {
            updateComplaint.fetchData({ data: requestData });
        } else {
            createComplaint.fetchData({ data: requestData });
        }
    };

    return (
        <StyledCreateComplaint
            show={isOpen}
            className="create-complaint"
            width="500px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={onClose}>{mode} complaint</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message={`Complaint has been ${mode}ed.`}
                    onClose={onClose}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    {mode === "edit" && (
                        <ul>
                            <li>
                                ID:<span>{data?.id || "-"}</span>
                            </li>
                            <li>
                                Created By:
                                <span>{data?.created_by || "-"}</span>
                            </li>
                            <li>
                                Created At:
                                <span>
                                    {formatTimeString(data?.created_at || "")}
                                </span>
                            </li>
                        </ul>
                    )}
                    <TextArea
                        label="Description:"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        name="description"
                    />
                    <DropdownSelect
                        label="Actioned by:"
                        options={users}
                        optionKey="id"
                        labelKeys={["name"]}
                        value={actionedId}
                        onSelect={(option) => setActionedId(option.id ?? null)}
                    />
                    {mode === "edit" && (
                        <ul>
                            <li>
                                Updated By:
                                <span>{data?.updated_by || "-"}</span>
                            </li>
                            <li>
                                Updated At:
                                <span>
                                    {formatTimeString(data?.updated_at || "")}
                                </span>
                            </li>
                        </ul>
                    )}
                    <TextArea
                        label="Action Taken:"
                        value={actionTaken}
                        onChange={(e) => setActionTaken(e.target.value)}
                        name="action_taken"
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            btnText={`${
                                mode === "create" ? "Create" : "Save Changes"
                            }`}
                            style="purple"
                            loading={isLoading}
                            disabled={!isFormValid}
                        ></Button>
                    </ModalButtons>
                </form>
            )}
        </StyledCreateComplaint>
    );
};

export default CreateComplaint;
