import { TableRowProps } from ".";
import { useNavigate } from "react-router-dom";

const IntroducerRow: React.FC<TableRowProps> = ({ data }) => {
    const navigate = useNavigate();

    const rowClickHandler = () => {
        if (data?.id) navigate(`/introducer/${data.id}`);
    };

    return (
        <tr onClick={rowClickHandler} role="button">
            <td>{data?.id ?? "-"}</td>
            <td>{data?.nme ?? "-"}</td>
            <td>{data?.contact ?? "-"}</td>
            <td>{data?.type ?? "-"}</td>
            <td>{data?.leads_total ?? "-"}</td>
            <td>{data?.leads_today ?? "-"}</td>
            <td>{data?.users ?? "-"}</td>
        </tr>
    );
};
export default IntroducerRow;
