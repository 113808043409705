import styled from "styled-components";
import { theme } from "../../global/theme";

const StyledCheckboxGroup = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    border-radius: 4px;
    background-color: ${theme?.colors.formBlue};
    padding: 4px 8px;

    & .checkbox-group {
        &__legend {
            padding: 0;
            font-weight: 500;
            font-size: 12px;
            margin-right: 16px;
        }

        &__ctn {
            display: flex;
            align-items: center;
            gap: 8px;
            font-weight: 300;
            font-size: 12px;

            &:not(:first-of-type) {
                margin-left: 12px;
            }
        }

        &__label {
        }
        &__checkbox {
            height: 15px;
            width: 15px;
        }
    }
`;

export const StyledCheckboxGroups = styled.div``;

export const StyledPermissionCheckboxes = styled.div`
    min-height: 80px;
    max-width: 720px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .permission-checkboxes {
        &__group {
        }

        &__ctn {
            margin-top: 16px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 8px;
        }
    }
`;

export default StyledCheckboxGroup;
