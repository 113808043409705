import React, { useEffect } from "react";
import Header from "../../../layout/Header";
import DateButton from "../../../components/Button/DateButton";
import useDateSearch from "../../../layout/SlideInMenus/DateSearch/useDateSearch";
import { dateSearchOptions } from "../../../layout/SlideInMenus/DateSearch/data";
import useFetchData from "../../../global/hooks/useFetchData";
import DetailCard from "../../../components/Cards/DetailCard";
import {
    StyledDetailCardButton,
    StyledDetailCardLink,
} from "../../../components/Cards/DetailCard/styles";
import { AdminstrationLandingContent } from "./styles";

const AdministrationLanding: React.FC = () => {
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today");

    const mailTable = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/administration`,
        },
        {
            params: { from: dateRange.from, to: dateRange.to },
            onSuccess: onDateSearchSuccess,
            notifyOnSuccess: true,
        }
    );

    useEffect(() => {
        mailTable.fetchData();
    }, [dateRange.from, dateRange.to]);

    const clickHandler = () => {};

    return (
        <>
            <Header title="administration dashboard">
                <DateButton
                    isLoading={mailTable.isLoading}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                    dateDisplay={dateDisplay}
                />
            </Header>
            <AdminstrationLandingContent>
                <div className="cards">
                    <DetailCard
                        title="Missing SO pack"
                        count={mailTable?.data?.data?.missing_so_pack || 0}
                        buttons={
                            <StyledDetailCardButton onClick={clickHandler}>
                                Push To Report
                            </StyledDetailCardButton>
                        }
                    />
                    <DetailCard
                        title="So Pack Overview"
                        buttons={
                            <StyledDetailCardLink to="/">
                                View Details
                            </StyledDetailCardLink>
                        }
                    >
                        {" "}
                    </DetailCard>
                    <DetailCard
                        title="Email Generated"
                        count={mailTable?.data?.data?.email_generated || 0}
                    />
                    <DetailCard
                        title="Letters Generated"
                        count={mailTable?.data?.data?.letters_generated || 0}
                    />
                </div>
            </AdminstrationLandingContent>
        </>
    );
};

export default AdministrationLanding;
