import styled from "styled-components";
import { theme } from "../../global/theme";

export const LogoWrapper = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 15px;
    width: 35px;
    span {
        width: 100%;
        margin: auto;
        border: 0.6px solid ${theme?.colors?.background};
        border-radius: 1px;
        background-color: ${theme?.colors?.white};
    }
`;
