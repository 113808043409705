import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import { StyledCreateAddress } from "./styles";
import ModalHeader from "../components/ModalHeader";
import SuccessContent from "../components/SuccessContent";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import Input from "../../Input";
import AddressSearch from "../../Input/AddressSearch";
import TextArea from "../../Input/TextArea";

interface CreateAddressProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
}

const CreateAddress: React.FC<CreateAddressProps> = ({ isOpen, onClose }) => {
    const { clientId } = useParams();
    const { fetchData, isLoading, isSuccess } = useFetchData({});
    const [address, setAddress] = useState({
        door: "",
        postcode: "",
        full: "",
        year: "",
    });
    const isFormValid =
        address.full.trim() !== "" && address.postcode.trim() !== "";

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setAddress((prev) => {
            return {
                ...prev,
                [name]: name === "postcode" ? value.toUpperCase() : value,
            };
        });
    };

    const addressSelectHandler = (
        doorNumber: string,
        address: string,
        postcode: string
    ) => {
        setAddress((prev) => {
            return { ...prev, door: doorNumber, full: address, postcode };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData();
    };

    return (
        <StyledCreateAddress className="create-address" show={isOpen}>
            <ModalHeader onClose={onClose}>Create Other Address</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    onClose={onClose}
                    message="New Address has been created."
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <Input
                        type="text"
                        label="Door Number:"
                        value={address?.door || ""}
                        name="door"
                        onChange={handleChange}
                        placeholder="e.g. 38"
                    />
                    <AddressSearch
                        postcode={address?.postcode || ""}
                        onPostcodeChange={handleChange}
                        onSelect={addressSelectHandler}
                    />
                    <TextArea
                        label="Address:"
                        value={address?.full || ""}
                        name="full"
                        onChange={handleChange}
                        placeholder=""
                    />
                    <Input
                        type="number"
                        label="Year Spent At This Address:"
                        value={address?.year || ""}
                        name="year"
                        onChange={handleChange}
                        placeholder="e.g. 2023"
                    />
                    <ModalButtons>
                        <Button
                            type="button"
                            onClick={onClose}
                            style="cancel"
                            btnText="Cancel"
                        />
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Create"
                            disabled={!isFormValid}
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledCreateAddress>
    );
};

export default CreateAddress;
