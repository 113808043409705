import { SetStateAction } from "react";
import Button from "../../Button";
import { StyledSuccessContent as Content } from "./styles";

export interface SuccessContentProps {
    className?: string;
    title?: string;
    message?: string;
    setIsOpen?: React.Dispatch<SetStateAction<boolean>>;
    onClose?: (...args: any[]) => void;
    hasCloseBtn?: boolean;
    redirectTo?: string;
}

const SuccessContent: React.FC<SuccessContentProps> = ({
    className = "",
    title = "Success!",
    message = "",
    setIsOpen,
    onClose,
    hasCloseBtn = true,
}) => {
    const closeHandler = () => {
        if (setIsOpen) setIsOpen(false);
        if (onClose) onClose();
    };
    return (
        <Content className={`modal-success${className ? ` ${className}` : ""}`}>
            <h3 className="modal-success__title">{title}</h3>
            {message ? (
                <p className="modal-success__message">{message}</p>
            ) : null}
            {hasCloseBtn ? (
                <button className="modal-success__close" onClick={closeHandler}>
                    Close
                </button>
            ) : null}
        </Content>
    );
};

export default SuccessContent;
