import { useParams } from "react-router-dom";
import BasicModal from "../BasicModal";
import useFetchData from "../../../global/hooks/useFetchData";

interface SendRebateBreakdownModalProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
}

const SendRebateBreakdown: React.FC<SendRebateBreakdownModalProps> = ({
    isOpen,
    onClose,
}) => {
    const { clientId } = useParams();
    const { fetchData, isSuccess, isLoading } = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/send-action/${clientId}`,
    });

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({data:'breakdown'});
    };
    return (
        <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            message={`Do you want to send a breakdown of the rebate?`}
            submitBtnColor="purple"
            submitBtnText="Send"
            successMessage={`Rebate breakdown has been sent`}
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSubmit={submitHandler}
        />
    );
};

export default SendRebateBreakdown;
