import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import { FileData } from "../../FileUpload";
import { StyledCreateCorresUpload } from "./styles";
import useReduxClientCorrespondence from "../../../store/hooks/useReduxClientCorrespondence";

interface UploadFilesProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
}

const UploadFiles: React.FC<UploadFilesProps> = ({ isOpen, onClose }) => {
    const { setFiles } = useReduxClientCorrespondence();
    const { clientId } = useParams();
    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/files/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setFiles(data?.data);
            },
        }
    );

    const submitHandler = (fileData: FileData) => {
        console.log(fileData);
        if (fileData.file) {
            const formData = new FormData();
            formData.append("type", "upload");
            formData.append("file", fileData.file);
            fetchData(formData);
        }
    };
    return (
        <StyledCreateCorresUpload
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={submitHandler}
            showRequirements={false}
            isLoading={isLoading}
            isSuccess={isSuccess}
            accept="image/jpeg,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
    );
};

export default UploadFiles;
