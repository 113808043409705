import { KeyEventsContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import { formatDateString } from "../../../../global/utils/helpers";

const IdLoa: React.FC<KeyEventsContainerProps> = ({
    formData,
    onInputChange,
}) => {

    
    //formatDateString(formData?.solicitor_loa_sent || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')

    return (
        <HeaderContainer
            title="ID/LOA"
            headerStyle="gradientBlue"
            className="id-loa"
        >
            <Input
                type="date"
                label="Scanned ID:"
                value={formatDateString(formData?.scanned_id || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="scanned_id"
            />
            <Input
                type="date"
                label="WET LOA Sent:"
                value={formatDateString(formData?.wet_loa_sent || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="wet_loa_sent"
            />
            <Input
                type="date"
                label="WET LOA Received:"
                value={formatDateString(formData?.wet_loa_received || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="wet_loa_reveiced"
            />
            <Input
                type="date"
                label="WET LOA Reminder:"
                value={formatDateString(formData?.wet_loa_reminder || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="wet_loa_reminder"
            />
            <Input
                type="date"
                label="Solicitor LOA Sent:"
                value={formatDateString(formData?.solicitor_loa_sent || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="solicitor_loa_sent"
            />
        </HeaderContainer>
    );
};

export default IdLoa;
