import React, { useState } from "react";
import { CreateTicketModalWrapper } from "../../styles";
import { iCreateTicketModal } from "../../types";
import ModalHeader from "../../components/ModalHeader";
import SuccessContent from "../../components/SuccessContent";
import TicketContent from "../TicketContent";

const CreateTicketModal = ({
    setCreateTicketModal,
    open,
}: iCreateTicketModal) => {
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

    const closeModal = () => setCreateTicketModal(false);

    return (
        <CreateTicketModalWrapper show={open}>
            <ModalHeader setIsOpen={setCreateTicketModal}>
                Create Ticket
            </ModalHeader>
            {isSubmitSuccess ? (
                <SuccessContent
                    message="You have successfully created a ticket"
                    onClose={closeModal}
                />
            ) : (
                <TicketContent
                    mode="create"
                    onClose={closeModal}
                    onCreateSuccess={() => setIsSubmitSuccess(true)}
                />
            )}
        </CreateTicketModalWrapper>
    );
};

export default CreateTicketModal;
