import React, { SetStateAction, useState } from "react";
import TextArea from "../../../Input/TextArea";
import {
    formatFileSize,
    formatTimeString,
    viewUploadOnNewWindow,
} from "../../../../global/utils/helpers";
import Button from "../../../Button";
import Checkbox from "../../../Input/Checkbox";
import TextEditor from "../../../TextEditor";

interface TypeDetailsProps {
    data: { [key: string]: any };
    setData: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
    type: string;
}

const TypeDetails: React.FC<TypeDetailsProps> = ({ data, setData, type }) => {
    const inputHandler = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        const { value, name, type } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: type === "checkbox" ? (value ? 1 : 0) : value,
            };
        });
    };

    const printDocumentHandler = () => {
        //if (data?.id) alert(`print document id: ${data.id}`);
        if (data?.s3Path) window.open(data?.s3Path);
    };
    console.log(data);
    if (type === "Email")
        return (
            <>
                <ul className="data-list">
                    <li>
                        ID:<span>{data?.id || "-"}</span>
                    </li>
                    <li>
                        Sent To:<span>{data?.recevier_name || "-"}</span>
                    </li>
                    <li>
                        Sent By:<span>{data?.send_by || "-"}</span>
                    </li>
                    <li>
                        Sent At:
                        <span>{formatTimeString(data?.send_at || "")}</span>
                    </li>

                    <TextEditor
                        label="Body:"
                        value={data?.body || ""}
                        previewMode
                        previewSelectPlaceholder="Email body will display here."
                    />
                </ul>
            </>
        );

    if (type === "SMS")
        return (
            <>
                <ul className="data-list">
                    <li>
                        ID:<span>{data?.id || "-"}</span>
                    </li>

                    <li>
                        Sent To:<span>{data?.recevier_name || "-"}</span>
                    </li>
                    <TextArea
                        label="Message:"
                        value={data?.message || ""}
                        readOnly
                        disabled
                    />
                </ul>
            </>
        );

    if (type === "Phone Call")
        return (
            <>
                <div className="checkboxes-ctn">
                    <h4>Call Markers</h4>
                    <Checkbox
                        label="Courtesy Call"
                        checked={!!data?.courtesy_call}
                        name="courtesy_call"
                        onChange={inputHandler}
                        // disabled
                        // readOnly
                    />
                    <Checkbox
                        label="Welcome Call"
                        checked={!!data?.welcome_call}
                        name="welcome_call"
                        onChange={inputHandler}
                        // disabled
                        // readOnly
                    />
                </div>
                <div className="checkboxes-ctn">
                    <h4>Personal Budgeting</h4>
                    <Checkbox
                        label="Electricity / Gas"
                        checked={!!data?.electricity}
                        name="electricity"
                        onChange={inputHandler}
                        // disabled
                        // readOnly
                    />
                    <Checkbox
                        label="Insurance"
                        checked={!!data?.insurance}
                        name="insurance"
                        onChange={inputHandler}
                        // disabled
                        // readOnly
                    />
                    <Checkbox
                        label="Mobile"
                        checked={!!data?.mobile}
                        name="mobile"
                        // disabled
                        // readOnly
                    />
                </div>
            </>
        );

    if (type === "Document" || type === "Upload")
        return (
            <>
                <ul className="data-list">
                    <li>
                        ID:<span>{data?.documet_id || "-"}</span>
                    </li>
                    <li>
                        File Name:<span>{data?.file_name || "-"}</span>
                    </li>
                    <li>
                        Size:<span>{data?.file_size || 0}</span>
                    </li>
                    <li>
                        EXTN:<span>{data?.file_extn || "-"}</span>
                    </li>
                    {type === "Document" && (
                        <li>
                            Template:<span>{data?.template || "-"}</span>
                        </li>
                    )}
                </ul>
                <div className="actions">
                    {data?.s3Path && (
                        <Button
                            type="button"
                            style="white"
                            btnText="View"
                            onClick={() => viewUploadOnNewWindow(data.s3Path)}
                        ></Button>
                    )}
                    <Button
                        type="button"
                        style="purple"
                        btnText="Print"
                        onClick={printDocumentHandler}
                    ></Button>
                </div>
            </>
        );
    if (type === "Note")
        return (
            <TextArea
                label="Notes"
                value={data?.notes || ""}
                onChange={inputHandler}
                name="notes"
            />
        );
    return null;
};

export default TypeDetails;
