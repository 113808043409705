import { EditLiabilityFormProps } from ".";
import DropdownMenu from "../../../DropdownMenu";
import DropdownMeunItem from "../../../DropdownMenu/DropdownMenuItem";
import useFetchData from "../../../../global/hooks/useFetchData";

const DropdownActions: React.FC<EditLiabilityFormProps> = ({
    data,
    setCurrentForm,
    id,
    onSuccess = () => {},
    setLiabilityDetails = () => {},
}) => {
    console.log(data);

    const hasStandingOrder = !!data?.has_standing_order;
    const isCCRSent = data?.ccr?.service_id !== 0;
    const isInitialLetterSent = !!data?.key_events?.initial_letter;
    const isCCRChase1Sent = !!data?.key_events?.ccr_chase_1;
    const isCCRChase2Sent = !!data?.key_events?.ccr_chase_2;
    const hasIVA = !!data?.iva;
    const isProposed = !!data?.proposal_status;

    const postLiabilityAction = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/liability-action/${id}`,
        },
        {
            onSuccess: (data) => {
                onSuccess();
                if (data?.data) setLiabilityDetails(data.data);
            },
        }
    );

    const liabilityActionHandler = (actionType: string) => {
        // console.log(actionType);
        const temp = {
            action_type: actionType,
        };
        postLiabilityAction.fetchData({ data: temp });
    };

    return (
        <DropdownMenu color="purple" title="Actions">
            <DropdownMeunItem
                iconName="change"
                title="Change Creditor"
                elementType="button"
                onClick={() => setCurrentForm("change-creditor")}
            />
            <DropdownMeunItem
                iconName="change"
                title="Change Originial Creditor"
                elementType="button"
                onClick={() => setCurrentForm("change-original-creditor")}
            />
            <DropdownMeunItem
                iconName="transfer"
                title="Transfer To Originial Creditor"
                elementType="button"
                onClick={liabilityActionHandler.bind(
                    null,
                    "Transfer-debt-to-original-creditor"
                )}
            />
            {/* <DropdownMeunItem
                iconName="change"
                title="Edit Key events"
                elementType="button"
                onClick={() => setCurrentForm("")}
            /> */}
            <DropdownMeunItem
                iconName="change"
                title="Alternative Payment"
                elementType="button"
                onClick={() => setCurrentForm("alternative-payment")}
            />
            <DropdownMeunItem
                iconName="coin"
                title="Pro-Rata Debt"
                elementType="button"
                onClick={liabilityActionHandler.bind(null, "pro-rata-debt")}
            />
            <DropdownMeunItem
                iconName={hasStandingOrder ? "cancel" : "standing"}
                title={
                    hasStandingOrder
                        ? "Remove Standing Order"
                        : "Standing Order Ready"
                }
                elementType="button"
                onClick={liabilityActionHandler.bind(
                    null,
                    "standing-order-ready"
                )}
            />
            <hr />
            <DropdownMeunItem
                iconName={data?.is_active ? "inactive" : "remove"}
                title={
                    data?.is_active
                        ? "Mark as Inactive"
                        : "Remove Inactive Mark"
                }
                elementType="button"
                onClick={liabilityActionHandler.bind(
                    null,
                    data?.is_active ? "mark-inactive" : "Remove-inactive"
                )}
            />
            <DropdownMeunItem
                iconName={data?.is_witheld ? "remove" : "coinCrossed"}
                title={data?.is_witheld ? "Remove Witheld" : "Witheld Payment"}
                elementType="button"
                onClick={liabilityActionHandler.bind(
                    null,
                    data?.is_witheld
                        ? "Remove-Withheld-Payments"
                        : "Withheld-Payments"
                )}
            />
            <DropdownMeunItem
                iconName={data?.is_client_responsible ? "remove" : "inactive"}
                title={
                    data?.is_client_responsible
                        ? "Remove Responsible"
                        : "Client Responsibile"
                }
                elementType="button"
                onClick={liabilityActionHandler.bind(
                    null,
                    data?.is_client_responsible
                        ? "client-responsible"
                        : "remove-responsible"
                )}
            />
            <hr />
            <DropdownMeunItem
                iconName="upload"
                title="Upload CA"
                elementType="button"
                onClick={() => setCurrentForm("upload-ca")}
            />
            <hr />
            {!isProposed && (
                <DropdownMeunItem
                    iconName="tick"
                    title="Proposal Accepted"
                    elementType="button"
                    onClick={liabilityActionHandler.bind(
                        null,
                        "Accept-Proposal"
                    )}
                />
            )}
            {isProposed && (
                <>
                    <DropdownMeunItem
                        iconName="cancel"
                        title="Proposal Declined"
                        elementType="button"
                        onClick={liabilityActionHandler.bind(
                            null,
                            "declined-proposal"
                        )}
                    />
                    <DropdownMeunItem
                        iconName="remove"
                        title="Remove Proposal"
                        elementType="button"
                        onClick={liabilityActionHandler.bind(
                            null,
                            "Removed-Proposal"
                        )}
                    />
                </>
            )}
            <hr />
            <DropdownMeunItem
                iconName="send"
                title="Send Full & Final Proposal"
                elementType="button"
                onClick={() => setCurrentForm("send-full-final")}
            />
            {data?.full_final?.status === 1 && (
                <DropdownMeunItem
                    iconName="tick"
                    title="Full & final Accepted"
                    elementType="button"
                    onClick={liabilityActionHandler.bind(
                        null,
                        "accept-full-final"
                    )}
                />
            )}
            <DropdownMeunItem
                iconName={isCCRSent ? "change" : "flip"}
                title={isCCRSent ? "Change CCR Details" : "Switch To CCR"}
                elementType="button"
                onClick={() => setCurrentForm("send-to-ccr")}
            />
            {isCCRSent && (
                <DropdownMeunItem
                    iconName="remove"
                    title="Remove CCR"
                    elementType="button"
                    onClick={liabilityActionHandler.bind(null, "Remove-CCR")}
                />
            )}
            <hr />
            {isCCRSent && !isInitialLetterSent && (
                <DropdownMeunItem
                    iconName="send"
                    title="Send Initial Letter"
                    elementType="button"
                    onClick={liabilityActionHandler.bind(
                        null,
                        "Send-CCR-Letter"
                    )}
                />
            )}
            {!isCCRChase1Sent && isInitialLetterSent && (
                <DropdownMeunItem
                    iconName="send"
                    title="Send CCR Chase 1"
                    elementType="button"
                    onClick={liabilityActionHandler.bind(
                        null,
                        "send-ccr-chase-1"
                    )}
                />
            )}
            {!isCCRChase2Sent && isCCRChase1Sent && isInitialLetterSent && (
                <DropdownMeunItem
                    iconName="send"
                    title="Send CCR Chase 2"
                    elementType="button"
                    onClick={liabilityActionHandler.bind(
                        null,
                        "send-ccr-chase-2"
                    )}
                />
            )}
            {isInitialLetterSent && (
                <DropdownMeunItem
                    iconName="tick"
                    title="Accepted"
                    elementType="button"
                    onClick={liabilityActionHandler.bind(null, "debt-accepted")}
                />
            )}

            <DropdownMeunItem
                iconName={hasIVA ? "remove" : "send"}
                title={hasIVA ? "Remove IVA" : "IVA"}
                elementType="button"
                onClick={liabilityActionHandler.bind(
                    null,
                    hasIVA ? "remove-iva" : "accepted-iva"
                )}
            />
        </DropdownMenu>
    );
};

export default DropdownActions;
