import React from "react";
import { PrintTableWrapper, StyledPrintMaintenance } from "./styles";
import DataTable from "../../../../components/DataTable";
import PrintListRow from "../../../../components/DataTable/TableRows/Settings/PrintListRow";

const PrintMaintenance: React.FC = () => {
    const testPrintList = [
        {
            id: 1,
            name: "DC_HP",
            letterhead: "1274",
            plain: "1278",
            is_active: 1,
        },
        {
            id: 2,
            name: "DC_HP2",
            letterhead: "1275",
            plain: "1278",
            is_active: 0,
        },
    ];

    const testQueueList = [
        {
            case_id: 1,
            document_name: "Doc Name",
            created_by: "User 1",
            created_at: "2022-09-02",
        },
        {
            case_id: 2,
            document_name: "Doc Name",
            created_by: "User 1",
            created_at: "2022-09-02",
        },
    ];

    const testErrorList = [
        {
            case_id: 1,
            document_name: "Doc Name",
            message: "Error",
            when: "2022-09-02",
        },
        {
            case_id: 2,
            document_name: "Doc Name",
            message: "Error 2",
            when: "2022-09-02",
        },
    ];

    return (
        <StyledPrintMaintenance className="print-maintenance">
            <PrintTableWrapper>
                <div className="header">
                    <h4>Printer List</h4>
                </div>
                <DataTable
                    labels={["Name", "Letterhead", "Plain", "Active"]}
                    data={testPrintList}
                    dataKey="id"
                    enableSorting={false}
                    tableRow={PrintListRow}
                ></DataTable>
            </PrintTableWrapper>
            <PrintTableWrapper>
                <div className="header">
                    <h4>Queue List</h4>
                </div>
                <DataTable
                    labels={[
                        "Case ID",
                        "Document Name",
                        "Create By",
                        "Created When",
                    ]}
                    keys={[
                        "case_id",
                        "document_name",
                        "created_by",
                        "created_at",
                    ]}
                    data={testQueueList}
                    dataKey="case_id"
                    enableSorting={false}
                ></DataTable>
            </PrintTableWrapper>
            <PrintTableWrapper aria-colcount={testErrorList?.length || 0}>
                <div className="header">
                    <h4>Error List</h4>
                </div>
                <DataTable
                    labels={[
                        "Case ID",
                        "Document Name",
                        "Error Message",
                        "When",
                    ]}
                    keys={["case_id", "document_name", "message", "when"]}
                    data={testErrorList}
                    dataKey="case_id"
                    enableSorting={false}
                ></DataTable>
            </PrintTableWrapper>
        </StyledPrintMaintenance>
    );
};

export default PrintMaintenance;
