import { SetStateAction, useId, useState } from "react";
import moment from "moment";

import Button from "../../../Button";
import { DayTaskWrapper } from "./styles";
import { StyledStepTwoContent } from "./styles";
import useFetchData from "../../../../global/hooks/useFetchData";
import useReduxDiary from "../../../../store/hooks/useReduxDiary";
import useReduxUser from "../../../../store/hooks/useReduxUser";
import { ReactComponent as BackIcon } from "../../../../assets/icons/purpleLeftArrow.svg";

interface StepTwoContentProps {
    setSelectedMeeting: React.Dispatch<
        SetStateAction<{ [key: string]: any } | null>
    >;
    selectedMeeting: { [key: string]: any } | null;
    setViewTicketModal: React.Dispatch<SetStateAction<boolean>>;
    setShowModal: React.Dispatch<SetStateAction<boolean>>;
    onSuccess?: (...args: any[]) => void;
}

const StepTwoContent: React.FC<StepTwoContentProps> = ({
    setSelectedMeeting,
    selectedMeeting,
    setViewTicketModal,
    setShowModal,
    onSuccess = () => {},
}) => {
    const id = useId();
    const { user } = useReduxUser();
    const { updateDiary } = useReduxDiary();
    const [isChecked, setIsChecked] = useState(selectedMeeting?.aknwldgd);

    const goToTaskHandler = () => {
        setViewTicketModal(true);
    };

    const updateTicket = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/diary`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) updateDiary(data.data);
            },
            notifyOnSuccess: true,
            notifySuccessMessage: "Updated successfully",
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const data = {
            userId: user?.id,
            type: "update",
            task_id: selectedMeeting?.id,
            aknwldgd: isChecked ? 1 : 0,
        };

        console.log(data);
        updateTicket.fetchData(data);
    };

    return (
        <StyledStepTwoContent className="step-two-content">
            <DayTaskWrapper className="step-two-content__card">
                <div className="step-two-content__row">
                    <button
                        onClick={() => setSelectedMeeting(null)}
                        type="button"
                        className="step-two-content__back"
                        aria-label="prev"
                    >
                        <BackIcon />
                    </button>
                    <div className="step-two-content__info">
                        <div className="step-two-content__case">
                            {selectedMeeting?.case_id || ""}
                        </div>
                        {!!selectedMeeting?.get_creditor?.length && (
                            <p className="step-two-content__creditor">
                                {selectedMeeting?.get_creditor
                                    .map((user: any) => user?.name || "")
                                    .join(", ") || "Unknown Creditor"}
                            </p>
                        )}
                    </div>
                </div>
                <p className="step-two-content__handler">
                    {selectedMeeting?.get_user
                        .map((user: any) => user?.name || "")
                        .join(", ") || "Unknown User"}
                </p>
                <div className="step-two-content__time">
                    Start date & time:
                    <p>
                        {moment(selectedMeeting?.whn).format(
                            "dddd, DD MMM YYYY HH:mm A"
                        )}
                    </p>
                </div>

                <div className="step-two-content__task">
                    Task:
                    <p>{selectedMeeting?.dscrptn || selectedMeeting?.smmry}</p>
                </div>

                <div className="step-two-content__bottom">
                    <input
                        id={id}
                        type="checkbox"
                        checked={isChecked}
                        value={isChecked ? 1 : 0}
                        onChange={() => setIsChecked((prev: boolean) => !prev)}
                    ></input>
                    <label htmlFor={id}>Acknowledged</label>
                    <button type="button" onClick={goToTaskHandler}>
                        Go to task
                    </button>
                </div>
            </DayTaskWrapper>
            <form onSubmit={submitHandler} className="btns">
                <Button
                    style="purple"
                    btnText="Done"
                    loading={updateTicket.isLoading}
                />
            </form>
        </StyledStepTwoContent>
    );
};

export default StepTwoContent;
