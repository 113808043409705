import React, { useEffect } from "react";
import { PageContent } from "../../styles";
import { Divider, TopSection, BottomSection } from "./styles";
import Header from "../../../layout/Header";
import DateButton from "../../../components/Button/DateButton";
import DetailCard from "../../../components/Cards/DetailCard";
import { StyledDetailCardButton } from "../../../components/Cards/DetailCard/styles";
import useDateSearch from "../../../layout/SlideInMenus/DateSearch/useDateSearch";
import useReduxUI from "../../../store/hooks/useReduxUI";
import useFetchData from "../../../global/hooks/useFetchData";
import { useParams } from "react-router-dom";

const CreditorLanding: React.FC = () => {
    const { setSideMenu } = useReduxUI();
    const { clientId } = useParams();
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today");
    const reportHandler = () => {
        console.log("report");
    };

    const getDashboard = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/creditor`,
        },
        {
            onSuccess: (data) => {
                setSideMenu("");
                onDateSearchSuccess();
            },
            params: dateRange
                ? { from: dateRange.from, to: dateRange.to }
                : undefined,
        }
    );

    useEffect(() => {
        if (dateRange) {
            getDashboard.fetchData();
        }
    }, [dateRange]);

    return (
        <>
            <Header hasNavBarAbove title="creditor maintenance">
                <DateButton
                    isLoading={false}
                    dateDisplay={dateDisplay}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                />
            </Header>
            <PageContent>
                <TopSection>
                    <DetailCard
                        title="Acceptance Rate"
                        count={getDashboard?.data?.data?.acceptance_rate || 0}
                        buttons={
                            <StyledDetailCardButton onClick={reportHandler}>
                                Push To Report
                            </StyledDetailCardButton>
                        }
                    />
                    <DetailCard
                        title="Average Debt Value"
                        count={getDashboard?.data?.data?.acceptance_rate || 0}
                        countUnit="£"
                    />
                    <DetailCard
                        title="Offer Awaiting Acceptance"
                        count={
                            getDashboard?.data?.data?.awaiting_acceptance || 0
                        }
                    />
                    <DetailCard
                        title="Missing Balance"
                        buttons={
                            <StyledDetailCardButton onClick={reportHandler}>
                                Push To Report
                            </StyledDetailCardButton>
                        }
                    />
                    <DetailCard
                        title="Average Creditors Per Case"
                        count={
                            getDashboard?.data?.data
                                ?.average_creditor_per_case || 0
                        }
                    />
                </TopSection>
                <Divider>
                    <h5>Debts</h5>
                    <div className="rule"></div>
                </Divider>
                <BottomSection>
                    {getDashboard?.data?.data?.debts.map(
                        (debt: any, i: any) => (
                            <DetailCard
                                title={debt.name}
                                key={i}
                                count={debt.count}
                            />
                        )
                    )}
                </BottomSection>
            </PageContent>
        </>
    );
};

export default CreditorLanding;
