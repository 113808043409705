import styled from "styled-components";
import Header from "../../../../layout/Header";
import { theme } from "../../../../global/theme";

export const PersonalBudgetingHeader = styled(Header)`
    & .header {
        &__content {
            & > button {
                &:last-child {
                    background-color: ${theme.colors.white};
                    color: ${theme.colors.purple};
                    border-color: ${theme.colors.purple};
                }

                & path {
                    stroke: ${theme.colors.purple};
                }
            }
        }
    }
`;
