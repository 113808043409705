import { AgreementDetailsContainerProps } from ".";
import DropdownSelect from "../../../../components/DropdownSelect";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import useFetchData from "../../../../global/hooks/useFetchData";

const OtherDetails: React.FC<AgreementDetailsContainerProps> = ({
    formData,
    onSelect,
    onInputChange,
    handlerList,
}) => {
    const planDurationHandler = () => {
        alert(formData?.plan_duration);
    };
    const getLeadSourceList = useFetchData({});
    const leadSourceList = handlerList?.lead_source || [];

    return (
        <HeaderContainer
            title="Other Details"
            headerStyle="gradientBlue"
            className="other-details"
        >
            <DropdownSelect
                label="Lead Source:"
                options={leadSourceList}
                value={formData?.lead_source_id ?? null}
                onSelect={(option) => onSelect("lead_source_id", option.id)}
                optionKey="id"
                labelKeys={["name"]}
                enableInput
            />
            <DropdownSelect
                label="Case Handler:"
                options={handlerList?.case_handler || []}
                value={formData?.case_handler_id ?? null}
                onSelect={(option) => onSelect("case_handler_id", option.value)}
                optionKey="id"
                labelKeys={["name"]}
                enableInput
            />
            <DropdownSelect
                label="Customer Care Handler:"
                options={handlerList?.customer_handler || []}
                value={formData?.customer_card_handler_id ?? null}
                onSelect={(option) =>
                    onSelect("customer_card_handler_id", option.value)
                }
                optionKey="id"
                labelKeys={["name"]}
                enableInput
            />
            <Input
                type="text"
                label="Lead Details:"
                value={`${formData?.lead_details ?? ""}`}
                onChange={onInputChange}
                name="lead_details"
            />
            <Input
                type="date"
                label="Standing Order Sent:"
                value={`${formData?.standing_order_sent ?? ""}`}
                onChange={onInputChange}
                name="standing_order_sent"
            />
        </HeaderContainer>
    );
};

export default OtherDetails;
