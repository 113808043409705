import { TableRowProps } from "../";
import { formatDateString } from "../../../../global/utils/helpers";
import { ColoredBlock } from "../styles";

const VerificationTypeRow: React.FC<TableRowProps> = ({ data }) => {
    const statusColor = (value: string) => {
        if (value === "completed") return "green";
        if (value === "failed") return "red";
        if (value === "in progress") return "yellow";
        return "";
    };

    return (
        <tr>
            <td>{data?.name ?? "-"}</td>
            <ColoredBlock color={statusColor(data?.status)}>
                {data?.status ?? "-"}
            </ColoredBlock>
            <td>{formatDateString(data?.created_at || "") ?? "-"}</td>
        </tr>
    );
};

export default VerificationTypeRow;
