import styled from "styled-components";

export const NavigationTileWrapper = styled.button`
    height: 100px;
    width: 100px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px 8px;
    background: linear-gradient(123.76deg, #4d9eff 4.28%, #686fff 103.7%);
    border-radius: 15px;
    cursor: pointer;

    img {
        margin-top: 8px;
        width: 24px;
        height: 24px;
    }

    p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */

        color: #f9f9f9;
    }
`;
