import useReduxUI from "../../store/hooks/useReduxUI";
import DateSearch from "../../layout/SlideInMenus/DateSearch";
import { StyledDateButtonCtn } from "./styles";
import React from "react";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import {
    DateSearchOption,
    defaultDateSearchOptions,
} from "../../layout/SlideInMenus/DateSearch/data";

interface DateButtonProps {
    isLoading?: boolean;
    label?: string;
    dateDisplay?: string;
    dateRange?: DateSearchOption;
    setDateRange?: React.Dispatch<DateSearchOption>;
    submitBtnText?: string;
    onSubmit?: (...args: any[]) => void;
    selectedDateAsBtnText?: boolean;
    options?: Array<DateSearchOption>;
}

const DateButton: React.FC<DateButtonProps> = ({
    isLoading = false,
    label = "From",
    dateDisplay = "",
    dateRange,
    setDateRange,
    onSubmit = () => {},
    submitBtnText,
    selectedDateAsBtnText = false,
    options = defaultDateSearchOptions,
}) => {
    const { setSideMenu, sideMenu } = useReduxUI();

    const submitHandler = (dateRange: DateSearchOption) => {
        console.log(dateRange);
        if (setDateRange) setDateRange(dateRange);
        onSubmit();
    };

    return (
        <>
            <DateSearch
                open={sideMenu === "date"}
                onSubmit={submitHandler}
                isLoading={isLoading}
                initialDateRange={dateRange}
                submitBtnText={submitBtnText}
                options={options}
            />
            <StyledDateButtonCtn isColumn={selectedDateAsBtnText}>
                {dateDisplay ? (
                    <p className="date-button__date">
                        {selectedDateAsBtnText
                            ? "Date Range:"
                            : `${label}: ${dateDisplay}`}
                    </p>
                ) : null}
                <button
                    className="date-button__btn"
                    onClick={setSideMenu.bind(null, "date")}
                    type="button"
                >
                    {!selectedDateAsBtnText && <CalendarIcon role="icon" />}
                    {selectedDateAsBtnText ? dateDisplay : "Date Search"}
                </button>
            </StyledDateButtonCtn>
        </>
    );
};

export default DateButton;
