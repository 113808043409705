import styled from "styled-components";
import { ResizableModalWrapper } from "../../styles";
import { theme } from "../../../../global/theme";

export const StyledChangeStatus = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`;

export const StyledCaseComplete = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    & textarea {
        height: 120px;
    }
`;

export const StyledRequestCancellation = styled(ResizableModalWrapper)`
    & > form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px 16px;

        & > * {
            grid-column: 1/-1;
        }
        p {
            grid-column: initial;
            display: flex;
            justify-content: space-between;
            font-weight: 300;

            & > span {
                font-weight: 400;
            }
        }
    }
`;

export const StyledCaseActionsUpload = styled(ResizableModalWrapper)`
    width: min(100%, 500px);

    & .file-upload {
        margin-bottom: 24px;
    }

    & .re-upload {
        text-align: center;
        margin-bottom: 8px;
        border-radius: 4px;
        font-size: 12px;
    }
`;

export const StyledCaseActionsSendTo = styled(ResizableModalWrapper)`
    & > form {
        & > p {
            padding-inline: 12px;
            text-align: center;
        }

        & .dropdown-select {
            margin-block: 16px;
        }
    }
`;
