import React from "react";
import { ReportWrapper } from "./styles";
import ReportList from "../../../containers/User/Reports/ReportList";
import Header from "../../../layout/Header";

export interface iSelectedReport {
    reportId: number;
    reportName: string;
}

const Reports: React.FC = () => {
    return (
        <>
            <Header title="Reports" />
            <ReportWrapper className="report-wrapper">
                <ReportList />
            </ReportWrapper>
        </>
    );
};

export default Reports;
