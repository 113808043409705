import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { icons } from "../../../assets/icons";
import Header from "../../../layout/Header";
import {
    Amazon,
    PaymentGateway,
    Signable,
} from "../../../subPages/User/Settings/Services";
import { Email, Sms } from "../../../subPages/User/Settings/Services";
import { PageContent } from "../../styles";
import { servicesSubLinks } from "./data";

const Services: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const pageName = () => {
        const pageName = pathname.split("/").pop();
        switch (pageName) {
            case "sms":
                return "SMS Service";
            case "signable":
                return "Signable";
            case "amazon":
                return "Amazon S3";
            case "payment-gateway":
                return "Payment Gateway";
            case "email":
                return "Email Service";
            default:
                break;
        }
    };
    return (
        <>
            <Header
                title={`services - ${pageName()}`}
                prevPath="/settings"
                subList={servicesSubLinks}
            />
            <PageContent>
                <Routes>
                    <Route path="/email" element={<Email />} />
                    <Route path="/sms" element={<Sms />} />
                    <Route path="/signable" element={<Signable />} />
                    <Route path="/amazon" element={<Amazon />} />
                    <Route
                        path="/payment-gateway"
                        element={<PaymentGateway />}
                    />
                </Routes>
            </PageContent>
        </>
    );
};

export default Services;
