import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "../../../../assets/icons";
import Button from "../../../Button";
import { DeleteReportModalWrapper } from "../../styles";
import { StyledDiscardReportModal } from "./styles";
import ModalHeader from "../../components/ModalHeader";
import ModalButtons from "../../components/ModalButtons";

export interface iDiscardReportModal {
    setReportModal: Dispatch<SetStateAction<any>>;
    open: boolean;
}

const DiscordReportModal = ({ setReportModal, open }: iDiscardReportModal) => {
    const navigate = useNavigate();
    const closeModal = () => setReportModal("");
    const handleDiscard = () => navigate("/settings/templates/reports");

    return (
        <StyledDiscardReportModal show={open}>
            <ModalHeader onClose={closeModal}>Please confirm...</ModalHeader>
            <form>
                <p>Do you want to discard the report?</p>
                <ModalButtons>
                    <Button
                        onClick={handleDiscard}
                        style="red"
                        btnText="Discard"
                    />
                </ModalButtons>
            </form>
        </StyledDiscardReportModal>
    );
};

export default DiscordReportModal;
