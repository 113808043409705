import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
    Button,
    DeleteUploadModal,
    ModalContainer,
    ReDoUploadModal,
} from "../../../../components";
import { Column } from "../../../../global/theme/reuseable/components";
import { UploadDetailsWrapper } from "./styles";
import { useState } from "react";
import { MailModalT } from "../../../../components/Modals/Administration/types";

interface MailUploadDetailsProps {
    file: string;
    data: { [key: string]: any };
}

const MailUploadDetails: React.FC<MailUploadDetailsProps> = ({ file,data }) => {
    const [modalType, setModalType] = useState<MailModalT>("");
    const [searchParams, setSearchParams] = useSearchParams();
    const handleOpen = () => {};
    const { userId } = useParams();
    console.log(data);
    return (
        <>
            <ModalContainer
                isVisible={modalType === "Delete"}
                children={
                    <DeleteUploadModal
                        setModalType={setModalType}
                        open={modalType === "Delete"}
                        id={searchParams}
                    />
                }
            />
            <ModalContainer
                isVisible={modalType === "ReDo"}
                children={
                    <ReDoUploadModal
                        setModalType={setModalType}
                        open={modalType === "ReDo"}
                    />
                }
            />

            <UploadDetailsWrapper>
                <div className="header">
                    <h4>Mail Upload Details</h4>
                </div>
                <div className="body">
                    <li className="detail">
                        ID: <span>{data?.id}</span>
                    </li>
                    <li className="detail">
                        File:{" "}
                        <span>
                            <a href={file} target="_blank">
                                <Button
                                    btnText="Open File"
                                    style="purple"
                                    onClick={handleOpen}
                                />
                            </a>
                            <Button
                                btnText="Delete"
                                style="red"
                                onClick={() => setModalType("Delete")}
                            />
                            {/* <Button
                                btnText="ReDo"
                                style="white"
                                onClick={() => setModalType("ReDo")}
                            /> */}
                        </span>
                    </li>

                    <hr />

                    <li className="detail">
                        Created by: <span>{data?.name}</span>
                    </li>
                    <li className="detail">
                        Created on: <span>{data?.created_at}</span>
                    </li>
                    <li className="detail">
                        Completed on: <span>{data?.completed_when}</span>
                    </li>
                    <li className="detail">
                        Deleted on: <span>{data?.deleted_when}</span>
                    </li>
                </div>
            </UploadDetailsWrapper>
        </>
    );
};

export default MailUploadDetails;
