import React, { useState } from "react";
import { CreateDocumentTemplateFormProps } from "../CreateDocumentTemplate";
import { StyledDocumentTemplateForm } from "./styles";
import { Input } from "../../../../../../components";
import DropdownSelect from "../../../../../../components/DropdownSelect";
import FileUpload, { FileData } from "../../../../../../components/FileUpload";
import { documentTypeOptions } from "../../../../../../components/DropdownSelect/options";

const GeneralSettings: React.FC<CreateDocumentTemplateFormProps> = ({
    mode,
    documentData,
    setDocumentData,
    inputHandler,
    selectHandler,
}) => {
    const [uploadedFileNname, setUploadedFileNname] = useState("");

    const uploadHandler = (fileData: FileData) => {
        console.log(fileData.file);
        setUploadedFileNname(fileData.name);
        setDocumentData((prev) => {
            return {
                ...prev,
                file: fileData.file,
            };
        });
    };

    // const submitHandler = (e: React.FormEvent) => {
    //     e.preventDefault();
    //     const { name, type, file, saveas } = documentData;
    //     const requestData: { [key: string]: any } = {
    //         name,
    //         type,
    //         file,
    //         saveas,
    //     };
    //     const formData = new FormData();
    //     for (const p in requestData) {
    //         formData.append(p, requestData[p]);
    //     }
    // };

    return (
        <StyledDocumentTemplateForm>
            <ul className="info">
                {mode === "edit" && (
                    <li>
                        ID: <span>{documentData?.id ?? "-"}</span>
                    </li>
                )}
                <li>
                    Name(*):
                    <Input
                        type="text"
                        value={documentData.name}
                        name="name"
                        onChange={inputHandler}
                        required
                    />
                </li>
                <li>
                    Type:
                    <DropdownSelect
                        value={documentData.type}
                        name="type"
                        options={documentTypeOptions}
                        onSelect={(option) =>
                            selectHandler("type", option.value)
                        }
                    />
                </li>
                {mode === "edit" && (
                    <li>
                        Version: <span>{documentData?.version ?? "-"}</span>
                    </li>
                )}
                <hr />
                <li className="upload">
                    <p>
                        {uploadedFileNname ||
                            documentData?.file_name ||
                            "Choose a file:"}
                    </p>
                    <FileUpload onUploaded={uploadHandler} accept=".doc,.docx">
                        Upload Document
                    </FileUpload>
                </li>
                <li>
                    Save As:
                    <Input
                        placeholder="Enter new file name..."
                        type="text"
                        value={documentData.saveas}
                        name="saveas"
                        onChange={inputHandler}
                    />
                </li>
            </ul>
            {/* <Button
                className="submit-btn"
                type="submit"
                btnText={"Save"}
                style="mint"
            ></Button> */}
        </StyledDocumentTemplateForm>
    );
};

export default GeneralSettings;
