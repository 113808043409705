import styled from "styled-components";
import { LabelMixin } from "../../../Label/styles";
import { ResizableModalWrapper } from "../../styles";

export const StyledCreateUserModal = styled(ResizableModalWrapper)``;

export const StepWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 27px;
    row-gap: 13px;

    .inputWrapper,
    .inputWrapper input {
        width: 100%;
    }
    .selectWrapper {
        width: 100%;

        p {
            height: 40px;
        }
    }
`;
export const ConfirmationWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    & > :nth-child(3) {
        grid-column: 1/-1;
    }

    .confirmationSection {
        flex-grow: 1;
        h6 {
            text-align: left;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
        }

        ul {
            list-style: none;
            margin-top: 8px;
        }

        li {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 4px;
            text-align: left;
            font-size: 12px;
            font-weight: 300;

            &:not(:first-child) {
                margin-top: 2px;
            }

            & > span {
                font-weight: 400;
            }
        }
    }
    > .confirmationSection p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        background: linear-gradient(124.94deg, #ff7a68 -8.84%, #c8311d 107.02%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: left;
        text-fill-color: transparent;
    }

    .inputWrapper,
    .inputWrapper input {
        width: 100%;
    }
`;

export const SuccessText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    padding-top: 22px;
    h4 {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #5e5e5e;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #5e5e5e;
    }
    button {
        width: 194px;
        height: 40px;
    }
`;
