import React, { useId, useState } from "react";
import Button from "../Button";
import Label from "../Label";
import { InputWrapper, ButtonInputWrapper } from "./styles";
import { iButtonInput } from "./types";

const ButtonInput = ({
    onChange = () => {},
    placeholder,
    type,
    inputType,
    name,
    label,
    onClick,
    btnText,
    autoComplete,
    value,
    disabled,
    buttonDisabled,
    className = "",
    isLoading = false,
}: iButtonInput) => {
    const [isFocusing, setIsFocusing] = useState(false);
    const id = useId();

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { type, value } = e.target;
        if (type === "tel") e.target.value = value.replace(/[^0-9\\+]/g, "");
        onChange(e);
    };
    return (
        <InputWrapper className={className}>
            {label && <Label label={label || ""} />}
            <ButtonInputWrapper
                className="buttonInputWrapper"
                data-focused={isFocusing}
            >
                <input
                    type={type}
                    onChange={inputHandler}
                    placeholder={placeholder}
                    name={name}
                    id={`input-${id}`}
                    autoComplete={autoComplete}
                    value={value || ""}
                    disabled={disabled}
                    onFocus={() => setIsFocusing(true)}
                    onBlur={() => setIsFocusing(false)}
                />
                <Button
                    btnText={btnText}
                    style="purple"
                    onClick={onClick}
                    type="button"
                    disabled={disabled || buttonDisabled}
                    loading={isLoading}
                />
            </ButtonInputWrapper>
        </InputWrapper>
    );
};

export default ButtonInput;
