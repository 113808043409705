import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ClientPortalState {
    requests: Array<{[key: string]: any}>
    sessions: Array<{[key: string]: any}>
}

export const initialClientPortalState: ClientPortalState = {
    requests: [],
    sessions: [],
}

export const clientPortalSlice = createSlice({
    name: 'client-portal',
    initialState: initialClientPortalState,
    reducers: {
        setRequests: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.requests = action.payload
        },
        setSessions: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.sessions = action.payload
        },
        resetSlice: () => {
            return initialClientPortalState
        },
    }
})

export const clientPortalActions = clientPortalSlice.actions
export default clientPortalSlice