import React, { useState } from "react";
import { MailShotSearchRow, SearchHeader } from "./styles";
import { TablePageContent } from "../../styles";
import DataTable from "../../../components/DataTable";
import useDateSearch from "../../../layout/SlideInMenus/DateSearch/useDateSearch";
import { dateSearchOptions } from "../../../layout/SlideInMenus/DateSearch/data";
import DateButton from "../../../components/Button/DateButton";
import DropdownSelect from "../../../components/DropdownSelect";
import {
    fieldForDateOptions,
    generateTestOptions,
} from "../../../components/DropdownSelect/options";
import useFetchData from "../../../global/hooks/useFetchData";
import { Button } from "../../../components";

const MailShot: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [fieldForDate, setFieldForDate] = useState(
        fieldForDateOptions[0].value as string
    );
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today", true);
    const [templateId, setTemplateId] = useState<number | null>(null);
    const [tablesData, setTablesData] = useState<{ [key: string]: any } | null>(
        null
    );

    const getMailShots = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/adminstration`,
        },
        {
            fetchOnLoad: false,
            params: {
                from: dateRange.from,
                to: dateRange.to,
                field: fieldForDate,
            },
            onSuccess: (data) => {
                if (data?.data) setTablesData(data?.data);
            },
        }
    );

    const sendLetter = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/adminstration/send-letter`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTablesData(data?.data);
            },
        }
    );

    const testData = [
        {
            ID: 1,
            "Customer Name": "Test Name 1",
            Address: "Address 1",
            "Next Payment": "2022-08-12",
            "Last Letter Sent":
                "AML STandard System - Risk Assessment Form - Individual",
        },
        {
            ID: 2,
            "Customer Name": "Test Name",
            Address: "Address 1",
            "Next Payment": "2022-09-19",
            "Last Letter Sent":
                "AML STandard System - Risk Assessment Form - Individual",
        },
    ];

    const filterSearchHandler = () => {
        getMailShots.fetchData();
    };

    const sendLetterHandler = () => {
        const requestData = {
            template_id: templateId,
            client_id: selectedIds,
        };
        console.log(requestData);
        sendLetter.fetchData(requestData);
    };

    return (
        <>
            <SearchHeader
                title="administration mail shot"
                hasNavBarAbove
                hasPrevPath
            >
                <DropdownSelect
                    label="Field For Date:"
                    placeholder="Field For Date"
                    value={fieldForDate}
                    options={fieldForDateOptions}
                    onSelect={(option) => setFieldForDate(option.value)}
                />
                <DateButton
                    isLoading={false}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                    dateDisplay={dateDisplay}
                    submitBtnText="Select"
                    onSubmit={onDateSearchSuccess}
                    selectedDateAsBtnText
                />
                <Button
                    btnText="Filter"
                    onClick={filterSearchHandler}
                    style="purple"
                    paddingInline="24px"
                    loading={getMailShots.isLoading}
                />
            </SearchHeader>
            <TablePageContent>
                <MailShotSearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by id or customer name..."
                >
                    <DropdownSelect
                        label="Document Template:"
                        value={templateId}
                        placeholder="Select Document Template"
                        options={generateTestOptions(5)}
                        onSelect={(option) => setTemplateId(option.value)}
                        enableInput
                    />
                    <Button
                        btnText={`Send Letter${
                            selectedIds?.length
                                ? ` (${selectedIds.length})`
                                : ""
                        }`}
                        onClick={sendLetterHandler}
                        style="purple"
                        paddingInline="24px"
                        disabled={!templateId || !selectedIds.length}
                    />
                </MailShotSearchRow>
                <DataTable
                    data={testData}
                    dataKey="ID"
                    searchKeys={["Customer Name", "ID"]}
                    searchTerm={searchTerm}
                    hasCheckbox
                    setSelectedIds={setSelectedIds}
                />
            </TablePageContent>
        </>
    );
};

export default MailShot;
