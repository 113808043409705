import React, { useId, useState } from "react";
import Label from "../Label";
import { InputWrapper } from "./styles";
import { iInput } from "./types";
import { ReactComponent as ViewIcon } from "../../assets/icons/eye.svg";

const Input = ({
    onChange = () => {},
    onBlur = () => {},
    placeholder,
    type,
    inputType,
    name,
    value,
    label,
    innerImg,
    gap,
    checked,
    className,
    min,
    max,
    autoComplete,
    defaultValue,
    isValid = true,
    required = false,
    hasShowPassword = false,
    step,
    inputHeight,
    disabled,
    readOnly,
}: iInput) => {
    const id = useId();
    const [showPassword, setShowPassword] = useState(false);

    const showPasswordHandler = () => {
        setShowPassword((prev) => !prev);
    };

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { type, value } = e.target;
        if (type === "tel") e.target.value = value.replace(/[^0-9\\+]/g, "");
        if (inputType === "numString")
            e.target.value = value.replace(/[^0-9]/g, "");
        if (inputType === "sortCode")
            e.target.value = value.replace(/[^0-9\\-]/g, "");
        if (inputType === "ipAddress")
            e.target.value = value.replace(/[^0-9\\.]/g, "");
        onChange(e);
    };

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { type, value } = e.target;
        if (inputType === "sortCode")
            e.target.value = value.replace(/-+/g, "-").replace(/^-+|-+$/g, "");
        if (inputType === "ipAddress")
            e.target.value = value
                .replace(/\.+/g, ".")
                .replace(/^\.+|\.+$/g, "");
        onBlur(e);
    };

    const pattern = () => {
        if (inputType === "sortCode") return "[0-9]{2}-[0-9]{2}-[0-9]{2}";
        return undefined;
    };

    const patternInfo = () => {
        if (inputType === "sortCode") return "Example: 11-22-33";
        return undefined;
    };

    return (
        <InputWrapper
            gap={gap}
            className={`${
                className ? `${className} inputWrapper` : "inputWrapper"
            }`}
        >
            {label && <Label label={label} htmlFor={`input-${id}`} />}
            <div className="inputWrapper__ctn">
                {innerImg && <img src={innerImg} alt={name || "btn-img"} />}
                <input
                    type={
                        hasShowPassword
                            ? showPassword
                                ? "text"
                                : "password"
                            : type
                    }
                    onChange={inputHandler}
                    onBlur={blurHandler}
                    placeholder={
                        placeholder ?? readOnly ? "" : "Please enter..."
                    }
                    name={name}
                    value={value}
                    id={`input-${id}`}
                    checked={checked}
                    min={min}
                    max={max}
                    autoComplete={autoComplete}
                    defaultValue={defaultValue}
                    required={required}
                    aria-invalid={!isValid}
                    step={step}
                    disabled={disabled}
                    readOnly={readOnly}
                    pattern={pattern()}
                    title={patternInfo()}
                />
                {hasShowPassword && (
                    <button
                        type="button"
                        className="inputWrapper__view"
                        onClick={showPasswordHandler}
                        data-active={showPassword}
                    >
                        <ViewIcon />
                    </button>
                )}
            </div>
        </InputWrapper>
    );
};

export default Input;
