import { useParams } from "react-router-dom";
import useFetchData from "../../../../global/hooks/useFetchData";
import useReduxUser from "../../../../store/hooks/useReduxUser";
import { CaseActionsModalProps } from "./types";
import useReduxClientBaseDetails from "../../../../store/hooks/useReduxClientBaseDetails";
import { FileData } from "../../../FileUpload";
import UploadModal from "../../UploadModal";

const data = {
    received_dates: {
        loa_file: "2023-02-28",
        wet_loa: "2023-02-28",
        solicitor_loa: "2023-02-28",
        client_loa: "2023-02-28",
        death_certificate: null,
    },
    uploads: {
        loa_file: {
            id: 1,
            url: "https://image-url.com",
        },
        wet_loa: {
            id: 2,
            url: "https://image-url.com",
        },
        solicitor_loa: {
            id: 3,
            url: "https://image-url.com",
        },
        client_loa: {
            id: 4,
            url: "https://image-url.com",
        },
        death_certificate: null,
    },
};

interface CaseActionsUploadProps extends CaseActionsModalProps {
    type: string;
    page: string;
}

const CaseActionsUpload: React.FC<CaseActionsUploadProps> = ({
    isOpen,
    onClose,
    type,
    page,
}) => {
    const { clientId } = useParams();
    const { setClientCase } = useReduxUser();
    const { loaIds, setBaseDetailsbyKey } = useReduxClientBaseDetails();
    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/case-upload-document/${clientId}`,
        },
        {
            onSuccess: (data) => {
                switch (page) {
                    case "loa-ids":
                        setBaseDetailsbyKey("loaIds", data?.data || []);
                        break;
                    default:
                        break;
                }
                // if (data?.data) setClientCase(data.data);
            },
        }
    );
    const typeName = () => {
        if (type === "loa_file") return "LOA";
        if (type === "client_loa") return "Client LOA";
        if (type === "wet_loa") return "Wet LOA";
        if (type === "solicitor_loa") return "Solicitor LOA";
        if (type === "death_certificate") return "Death Certificate";
        return "";
    };

    const submitHandler = (fileData: FileData) => {
        if (fileData.file) {
            const formData = new FormData();
            formData.append("type", type);
            formData.append("page", page);
            formData.append("file", fileData.file);
            fetchData(formData);
        }
    };

    return (
        <UploadModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={submitHandler}
            isLoading={isLoading}
            isSuccess={isSuccess}
            submitBtnText="Upload"
            accept=".jpg,.jpeg.,png"
            modalTitle={`Upload ${typeName()}`}
        />
    );
};
export default CaseActionsUpload;
