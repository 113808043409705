import styled from "styled-components";
import { mainScrollbar } from "../../../../global/theme/reuseable/mixins";
import { Button } from "../../../../components";
import { toolbarHeight, navBarHeight } from "../../../../layout/Header/styles";
import { PageContent } from "../../../../pages/styles";

export const StyledClientFormNav = styled.div`
    display: flex;
    gap: 8px;
    & > button {
        font-size: 14px;
        padding: 12px 12px;
        width: 120px;
    }
`;

export const ClientFormPageContent = styled(PageContent)`
    flex-basis: 0;
    overflow-y: auto;
    ${mainScrollbar(12)}
    padding-top: 8px;
`;

export const ClientFormStepButtons = styled.div`
    position: fixed;
    z-index: 5;
    right: 30px;
    top: calc(${toolbarHeight} + ${navBarHeight} + 40px);
    transform: translateY(-50%);
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    & > button {
        width: 100px;
    }
`;

const StyledClientForm = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* overflow: hidden; */
    & .step-tabs {
    }
    & .client-form {
        &__content {
            flex: 1;
            /* overflow: auto;
            ${mainScrollbar(12)}; */
        }
    }
`;

export default StyledClientForm;
