import HeaderContainer from "../../../../containers/HeaderContainter";
import useReduxIntroducers from "../../../../store/hooks/useReduxIntroducers";
import { IntroducerCreateFormProps } from "../IntroducerCreate";
import { StyledConfirmation } from "./styles";

const Confirmation: React.FC<IntroducerCreateFormProps> = ({
    introducerDetails,
    isTypeValid,
    isCompanyNameValid,
    isContactNameValid,
    isEmailValid,
}: any) => {
    const { types } = useReduxIntroducers();
    const typeName = types?.find(
        (t) => t?.id === introducerDetails?.type
    )?.name;

    return (
        <StyledConfirmation>
            <HeaderContainer
                headingElement="h3"
                headerStyle="lightBlue"
                title="Introducer Details"
            >
                <ul className="data-list">
                    <li data-invalid={!isContactNameValid}>
                        Company Name (*):
                        <span>{introducerDetails?.name || "-"}</span>
                    </li>
                    <li data-invalid={!isTypeValid}>
                        Type (*):<span>{typeName ?? "-"}</span>
                    </li>
                    <li>
                        Address:<span>{introducerDetails?.address || "-"}</span>
                    </li>
                </ul>
            </HeaderContainer>
            <hr />
            <HeaderContainer
                headingElement="h3"
                headerStyle="lightBlue"
                title="Contact Details"
            >
                <ul className="data-list">
                    <li data-invalid={!isContactNameValid}>
                        Contact Name (*):
                        <span>{introducerDetails?.contact || "-"}</span>
                    </li>
                    <li>
                        Telephone:
                        <span>{introducerDetails?.tele_phone || "-"}</span>
                    </li>
                    <li data-invalid={!isEmailValid}>
                        Email Address (*):
                        <span>{introducerDetails?.email || "-"}</span>
                    </li>
                </ul>
            </HeaderContainer>
            <hr />
            <HeaderContainer
                headingElement="h3"
                headerStyle="lightBlue"
                title="Account Details"
            >
                <ul className="data-list">
                    <li>
                        Account Name:
                        <span>{introducerDetails?.act_name || "-"}</span>
                    </li>
                    <li>
                        Account Number:
                        <span>{introducerDetails?.act_no || "-"}</span>
                    </li>
                    <li>
                        Sort Code:
                        <span>{introducerDetails?.sortcode || "-"}</span>
                    </li>
                </ul>
            </HeaderContainer>
            <hr />
        </StyledConfirmation>
    );
};

export default Confirmation;
