import SendMessagePageContent from "./styles";
import { Button } from "../../../../components";
import { LoadingSpinner } from "../../../../components/Button/styles";

interface SendMessageProps {
    leftContent?: JSX.Element | string;
    rightContent?: JSX.Element | string;
    isRightContentLoading?: boolean;
}

const SendMessage: React.FC<SendMessageProps> = ({
    leftContent = "",
    rightContent = "",
    isRightContentLoading = false,
}) => {
    return (
        <>
            <SendMessagePageContent className="message-template">
                <div className="message-template__col">{leftContent}</div>
                <div className="message-template__col">
                    {isRightContentLoading ? (
                        <LoadingSpinner size={40} />
                    ) : (
                        rightContent
                    )}
                </div>
            </SendMessagePageContent>
        </>
    );
};

export default SendMessage;
