import React, { useState } from "react";
import { icons } from "../../../../assets/icons";
import { Button, Input } from "../../../../components";
import { Column } from "../../../../global/theme/reuseable/components";
import { BlueBox, EditableSettingsWrapper } from "./styles";
import useFetchData from "../../../../global/hooks/useFetchData";
import DropdownSelect from "../../../../components/DropdownSelect";
import {
    paymentGatewayModeOptions,
    serviceActiveOptions,
} from "../../../../components/DropdownSelect/options";
import useReduxUser from "../../../../store/hooks/useReduxUser";

const PaymentGateway = () => {
    const [editedData, setEditedData] = useState({
        id: "",
        username: "",
        password: "",
        active: null,
        apikey: "",
        apisecret: "",
        other_1: "",
        other_2: "",
    });
    const { user } = useReduxUser();
    const userId = user?.id;

    const getPaymentGateway = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/service-payments`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setEditedData(data.data);
            },
        }
    );

    const savePaymentGateway = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/service-payments`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Changes has been saved",
        }
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setEditedData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setEditedData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const handleSave = (e: React.FormEvent) => {
        e.preventDefault();
        savePaymentGateway.fetchData({ payment: editedData });
    };

    return (
        <Column gap="24px">
            <BlueBox>
                <img src={icons?.worldPay} alt="" />
                <a
                    href="https://secure.worldpay.com/sso/public/auth/login.html?serviceIdentifier=merchantshoppercell"
                    target="_blank"
                    rel="noreferrer"
                >
                    Login
                </a>
            </BlueBox>
            <EditableSettingsWrapper>
                <div className="heading">
                    <h2>Settings</h2>
                </div>
                <form className="content" onSubmit={handleSave}>
                    <div className="row">
                        <Input
                            type="text"
                            name="username"
                            placeholder=""
                            value={editedData?.username || ""}
                            onChange={handleChange}
                            label="Username"
                        />
                        <Input
                            type="password"
                            name="password"
                            placeholder=""
                            value={editedData?.password || ""}
                            onChange={handleChange}
                            label="Password"
                            hasShowPassword
                        />
                        <DropdownSelect
                            label="Service:"
                            value={editedData?.active ?? null}
                            options={serviceActiveOptions}
                            onSelect={(option) =>
                                selectHandler("active", option.value)
                            }
                        />
                    </div>
                    <hr />
                    <div className="row">
                        <h3 className="title">API Settings</h3>
                        <Input
                            className="col-2"
                            type="text"
                            name="other_1"
                            placeholder=""
                            value={editedData.other_1}
                            onChange={handleChange}
                            label="XML ID"
                        />
                        <DropdownSelect
                            label="Mode:"
                            value={editedData?.other_2 || null}
                            options={paymentGatewayModeOptions}
                            onSelect={(option) =>
                                selectHandler("other_2", option.value)
                            }
                        />
                    </div>
                    <hr />
                    <Button
                        className="submit-btn"
                        btnText="Save"
                        loading={savePaymentGateway.isLoading}
                        style="purple"
                    />
                </form>
            </EditableSettingsWrapper>
        </Column>
    );
};

export default PaymentGateway;
