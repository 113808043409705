import { icons } from "../../../assets/icons";
import { iSubNav } from "../../../layout/SideNavs/types";
import { iSettingSubNavTile, SettingNavT } from "./types";

export const settingsNav: SettingNavT[] = ["Templates", "Services", "Other"];
export const templateSubNavTiles: iSettingSubNavTile[] = [
    {
        path: "templates/sms",
        title: "SMS",
        icon: icons?.sms,
    },
    {
        path: "templates/email",
        title: "Email",
        icon: icons?.email,
    },
    {
        path: "templates/document",
        title: "Document",
        icon: icons?.document,
    },
    {
        path: "templates/reports",
        title: "Reports",
        icon: icons?.reports,
    },
    {
        path: "templates/workflows",
        title: "Work Flows",
        icon: icons?.workflow,
    },
    // {
    //     path: "templates/dashboard",
    //     title: "Dashboard",
    //     icon: icons?.dashboard,
    // },
];
export const servicesSubNavTiles: iSettingSubNavTile[] = [
    {
        path: "services/sms",
        title: "SMS",
        icon: icons?.sms,
    },
    {
        path: "services/email",
        title: "Email",
        icon: icons?.email,
    },
    {
        path: "services/signable",
        title: "Signable",
        icon: icons?.signature,
    },
    {
        path: "services/amazon",
        title: "Amazon S3",
        icon: icons?.aws,
    },
    {
        path: "services/payment-gateway",
        title: "Payment Gateway",
        icon: icons?.paymentCard,
    },
];

export const otherSubNavTiles: iSettingSubNavTile[] = [
    {
        path: "other/global",
        title: "Global Settings",
        icon: icons?.globalSettings,
    },
    {
        path: "other/print-maintenance",
        title: "Print Maintenance",
        icon: icons?.printer,
    },
    {
        path: "other/lead-status",
        title: "Lead Status",
        icon: icons?.leadStatus,
    },
    {
        path: "other/income-expenditure",
        title: "Income and Expenditure",
        icon: icons?.aws,
    },
    {
        path: "other/tv-dashboards",
        title: "TV Dashboards",
        icon: icons?.tv,
    },
];

export const templateSubLinks: iSubNav[] = [
    {
        path: "sms",
        name: "SMS",
    },
    // {
    //     path: "letter",
    //     name: "Letter",
    // },

    {
        path: "email",
        name: "Email",
    },

    {
        path: "document",
        name: "Document",
    },
    {
        path: "reports",
        name: "Report",
    },
    {
        path: "workflows",
        name: "Workflow",
    }
    // {
    //     path: "dashboard",
    //     name: "Dashboard",
    // },
];

export const servicesSubLinks: iSubNav[] = [
    {
        path: "sms",
        name: "SMS",
    },
    {
        path: "email",
        name: "Email",
    },
    {
        path: "signable",
        name: "Signable",
    },
    {
        path: "amazon",
        name: "Amazon S3",
    },
    {
        path: "payment-gateway",
        name: "Payment Gateway",
    },
];

export const otherSubLinks: iSubNav[] = [
    {
        path: "global",
        name: "Global",
    },
    {
        path: "print-maintenance",
        name: "Print",
    },
    {
        path: "lead-status",
        name: "Lead Status",
    },
    {
        path: "income-expenditure",
        name: "Income & Expenditure",
    },
    {
        path: "tv-dashboards",
        name: "TV Dashboards",
    },
];
