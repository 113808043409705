import { theme } from "../../global/theme";
import StyledCircularMeter from "./styles";

interface CircularMeterProps {
    percentage: number;
}

const CircularMeter: React.FC<CircularMeterProps> = ({ percentage }) => {
    const circumference = Math.PI * 120;
    const strokeDashArray = `${circumference * (percentage / 100)} ${
        circumference * (1 - percentage / 100) - 2
    }`;

    return (
        <StyledCircularMeter className="circular-meter">
            <svg className="circular-meter__meter" viewBox="0 0 120 120">
                <defs>
                    <linearGradient
                        id="linearGradient"
                        // gradientTransform="rotate(135deg)"
                    >
                        <stop offset="0%" stopColor="#4D9EFF" />
                        <stop offset="100%" stopColor="#686FFF" />
                    </linearGradient>
                </defs>
                <circle
                    strokeWidth={4}
                    fill="none"
                    stroke="#e8e8ef"
                    cx={60}
                    cy={60}
                    r={58}
                />
                <circle
                    strokeWidth={4}
                    fill="none"
                    stroke="url(#linearGradient)"
                    cx={60}
                    cy={60}
                    r={58}
                    strokeDasharray={strokeDashArray}
                    strokeLinecap="butt"
                    strokeDashoffset={circumference * 0.25 + 2}
                />
                <text
                    x={60}
                    y={70}
                    fill="url(#linearGradient)"
                    textAnchor="middle"
                    fontSize={28}
                    fontWeight={600}
                >
                    {percentage.toFixed(0)}%
                </text>
            </svg>
        </StyledCircularMeter>
    );
};

export default CircularMeter;
