import { PayeRebateContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import { formatDateString } from "../../../../global/utils/helpers";


const Funds: React.FC<PayeRebateContainerProps> = ({
    onInputChange,
    payeRebate,
}) => {
//{formatDateString(payeRebate?.["fnds-rcivd-whn"] || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
    return (
        <HeaderContainer
            title="Funds:"
            headerStyle="gradientBlue"
            ctnClassName="col-2-3"
        >
            <Input
                type="number"
                label="Funds (Total Amount):"
                value={`${payeRebate?.["fnds-rcivd-amt"] || ""}`}
                name="fnds-rcivd-amt"
                onChange={onInputChange}
            />
            <Input
                type="date"
                label="Date Received:"
                value={formatDateString(payeRebate?.["fnds-rcivd-whn"] || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                name="fnds-rcivd-whn"
                onChange={onInputChange}
            />
            <Input
                type="number"
                label="Fees:"
                value={`${payeRebate?.["fnds-rcivd-fee"] || ""}`}
                name="fnds-rcivd-fee"
                onChange={onInputChange}
                readOnly
            />
            <Input
                type="number"
                label="VAT:"
                value={`${payeRebate?.["fuds-fee-vat"] || ""}`}
                name="fuds-fee-vat"
                onChange={onInputChange}
                readOnly
            />
            <Input
                type="number"
                label="Fee Amount: (Inc VAT)"
                value={`${payeRebate?.["fnds-fee-plus-vat"] || ""}`}
                name="fnds-fee-plus-vat"
                onChange={onInputChange}
                readOnly
            />
        </HeaderContainer>
    );
};

export default Funds;
