import { useParams } from "react-router-dom";
import BasicModal from "../BasicModal";
import useFetchData from "../../../global/hooks/useFetchData";

interface SendHMRCSignedFormModalProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
}

const SendHMRCSignedForm: React.FC<SendHMRCSignedFormModalProps> = ({
    isOpen,
    onClose,
}) => {
    const { clientId } = useParams();
    const { fetchData, isSuccess, isLoading } = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/send-action/${clientId}`,
    });

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({data:'hmrc'});
    };
    return (
        <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            message={`Do you want to send signed form from HMRC?`}
            submitBtnColor="purple"
            submitBtnText="Send"
            successMessage={`HMRC Signed Form has been sent.`}
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSubmit={submitHandler}
        />
    );
};

export default SendHMRCSignedForm;
