import { SetStateAction } from "react";
import useFetchData from "../../../global/hooks/useFetchData";
import { useNavigate, useParams } from "react-router-dom";
import BasicModal from "../BasicModal";

interface IntroducerDeleteModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const IntroducerDeleteModal: React.FC<IntroducerDeleteModalProps> = ({
    isOpen,
    setIsOpen,
}) => {
    const { introducerId } = useParams();
    const navigate = useNavigate();
    const deleteReport = useFetchData({
        method: "DELETE",
        url: `${process.env.REACT_APP_SERVER_API}/introducer/${introducerId}`,
    });

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        deleteReport.fetchData();
    };

    const closeHandler = () => {
        setIsOpen(false);
        if (deleteReport.isSuccess) navigate("/introducer");
    };

    return (
        <BasicModal
            isLoading={deleteReport.isLoading}
            isSuccess={deleteReport.isSuccess}
            isOpen={isOpen}
            onClose={closeHandler}
            message="Do you want to delete this introducer?"
            successMessage="Introducer has been deleted."
            onSubmit={submitHandler}
            submitBtnColor="red"
            submitBtnText="Delete"
        />
    );
};

export default IntroducerDeleteModal;
