import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ClientBalanceSheetState {
    tableData: Array<{[key: string]: any}>
    notes: Array<{[key: string]: any}>
    debtList: Array<{[key: string]: any}>
}

export const initialClientBalanceSheetState: ClientBalanceSheetState = {
    tableData: [],
    notes: [],
    debtList: []
}

export const clientBalanceSheetSlice = createSlice({
    name: 'client-balanceSheet',
    initialState: initialClientBalanceSheetState,
    reducers: {
        setTableData: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.tableData = action.payload
        },
        setNotes: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.notes = action.payload
        },
        setDebtList: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.debtList = action.payload
        },
        resetSlice: () => {
            return initialClientBalanceSheetState
        },
    }
})

export const clientBalanceSheetActions = clientBalanceSheetSlice.actions
export default clientBalanceSheetSlice