import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../../components";
import SearchRow from "../../../components/SearchRow";
import DataTable from "../../../components/DataTable";
import LiabilityActions from "./LiabilityActions";
import useFetchData from "../../../global/hooks/useFetchData";
import LiabilityRow from "../../../components/DataTable/TableRows/BalanceSheet/LiabilityRow";
import { Modal } from "../../../components";
import StyledBalanceSheetContent, { BalanceSheetHeader } from "./styles";
import Switch from "../../../components/Switch";
import { ReactComponent as CreditorIcon } from "../../../assets/icons/creditorRating.svg";
import { ReactComponent as ReloadIcon } from "../../../assets/icons/redo.svg";
import { calculateRatingGradient } from "../../../global/utils/helpers";
import CreateNote from "../../../components/Modals/BalanceSheet/CreateNote";
import CreateLiability from "../../../components/Modals/BalanceSheet/CreateLiability";
import useReduxClientBalanceSheet from "../../../store/hooks/useReduxClientBalanceSheet";
import AssignNewCreditor from "../../../components/Modals/BalanceSheet/AssignNewCreditor";
import CreditorRating from "../../../components/Modals/BalanceSheet/CreditorRating";

const BalanceSheet = () => {
    const {
        tableData,
        setTableData,
        notes,
        setNotes,
        resetSlice,
        setDebtList,
        debtList,
    } = useReduxClientBalanceSheet();
    const { clientId } = useParams();
    const [showModal, setShowModal] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const selectedIdsCount = selectedIds?.length
        ? `(${selectedIds.length})`
        : "";
    const [switchTo, setSwitchTo] = useState("");
    const getBalanceSheetData = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/balance-sheet/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTableData(data.data?.balance_sheet);
                setNotes(data.data?.additional_balancesheet);
                setDebtList(data.data?.debt);
            },
        }
    );

    const reloadData = () => {
        getBalanceSheetData.fetchData();
    };

    useEffect(() => {
        reloadData();
        return () => resetSlice();
    }, []);

    useEffect(() => {
        if (selectedIds?.length) console.log(switchTo, selectedIds);
    }, [switchTo]);

    const switchHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        const value = e.currentTarget?.value;
        if (value) {
            setSwitchTo((prev) => (prev === value ? "" : value));
        }
    };

    return (
        <>
            <Modal isVisible={showModal === "create-note"}>
                <CreateNote
                    isOpen={showModal === "create-note"}
                    onClose={() => setShowModal("")}
                />
            </Modal>
            <Modal isVisible={showModal === "create-liability"}>
                <CreateLiability
                    isOpen={showModal === "create-liability"}
                    onClose={() => setShowModal("")}
                />
            </Modal>
            <Modal isVisible={showModal === "assign-creditor"}>
                <AssignNewCreditor
                    isOpen={showModal === "assign-creditor"}
                    onClose={() => setShowModal("")}
                    selectedIds={selectedIds}
                    updateTable={getBalanceSheetData.fetchData}
                />
            </Modal>
            <Modal isVisible={showModal === "creditor-rating"}>
                <CreditorRating
                    isOpen={showModal === "creditor-rating"}
                    onClose={() => setShowModal("")}
                />
            </Modal>
            <BalanceSheetHeader title="Balance Sheet">
                <button
                    className="creditor-rating"
                    style={{
                        backgroundImage: calculateRatingGradient(5 ?? 3),
                    }}
                    type="button"
                    onClick={() => setShowModal("creditor-rating")}
                >
                    <CreditorIcon />
                    {5 ?? "-"}
                </button>
                <div className="right-btns">
                    <Button
                        btnText="Reload"
                        type="button"
                        style="white"
                        onClick={() => reloadData()}
                        loading={getBalanceSheetData.isLoading}
                    >
                        <ReloadIcon height={20} width={20} />
                    </Button>
                    <LiabilityActions
                        selectedIds={selectedIds}
                        selectedIdsCount={selectedIdsCount}
                        onSuccess={reloadData}
                    />
                    <Button
                        btnText="Create New Liability"
                        type="button"
                        style="mint"
                        onClick={() => setShowModal("create-liability")}
                    />
                    <Button
                        btnText="Create Balance Sheet Note"
                        type="button"
                        style="mint"
                        onClick={() => setShowModal("create-note")}
                    />
                </div>
            </BalanceSheetHeader>
            <StyledBalanceSheetContent>
                <SearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by liability name..."
                >
                    <p></p>
                    <div className="switches">
                        <Switch
                            label="Switch to CCR"
                            onClick={switchHandler}
                            value={switchTo}
                            switchValue="ccr"
                        />
                        <Switch
                            label="Switch to IBA"
                            onClick={switchHandler}
                            value={switchTo}
                            switchValue="iba"
                        />
                        <Switch
                            label="Switch to Solicitor"
                            onClick={switchHandler}
                            value={switchTo}
                            switchValue="solicitor"
                        />
                    </div>
                    <Button
                        btnText={`Assign New Creditor ${selectedIdsCount}`}
                        type="button"
                        style="mint"
                        onClick={() => setShowModal("assign-creditor")}
                        disabled={!selectedIds?.length}
                    />
                </SearchRow>
                <DataTable
                    labels={[
                        "Liability",
                        "Account reference",
                        "Balance",
                        "Repayment",
                        "Pro Rata (%)",
                        "CA Id",
                        "Responsibility",
                        // "Creditor",
                        "Created By",
                        "Status",
                    ]}
                    keys={[
                        "liability",
                        "account_reference",
                        "balance",
                        "repayment",
                        "pro-rata",
                        "ca",
                        "rspnsblty_name",
                        // "creditor",
                        "created_by",
                        "status",
                    ]}
                    hasCheckbox
                    data={tableData || []}
                    setSelectedIds={setSelectedIds}
                    hasAction
                    selectedIds={selectedIds}
                    searchTerm={searchTerm}
                    searchKeys={["liability_name"]}
                    sumKeys={["balance", "repayment", "pro-rata"]}
                    tableRow={LiabilityRow}
                    minWidth={"1200px"}
                    onActionSuccess={() => getBalanceSheetData.fetchData()}
                    isLoading={getBalanceSheetData.isLoading}
                />
                <div className="notes">
                    <h2>Additional Balance Sheet Notes:</h2>
                    <DataTable
                        labels={[
                            "Notes",
                            "Creditor",
                            "Created By",
                            "Created At",
                        ]}
                        keys={["dscrptn", "crdtr", "createdBy", "created_at"]}
                        data={notes || []}
                        columnWidths={["400px", "", "", ""]}
                        enableSorting={false}
                        formatKeys={{
                            created_at: "time",
                        }}
                    />
                </div>
            </StyledBalanceSheetContent>
        </>
    );
};

export default BalanceSheet;
