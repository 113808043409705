import styled from "styled-components";
import { ResizableModalWrapper } from "../../styles";
import {
    mainScrollbar,
    transparentGradient,
} from "../../../../global/theme/reuseable/mixins";
import { theme } from "../../../../global/theme";

export const StyledCreateReportModal = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;

        & .add-column {
            ${transparentGradient()};
            align-self: flex-start;
            &:disabled {
                opacity: 0.4;
            }
        }
    }
    & .checkbox-group {
        background-color: transparent;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(3, auto) 1fr;
        gap: 4px 4px;

        &__legend {
            grid-column: 1/-1;
            font-weight: 300 !important;
        }
    }

    & .columns {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 12px;
        overflow: auto;
        ${mainScrollbar(12)};
        min-height: 220px;

        & > p {
            text-align: center;
            padding-top: 48px;
            font-weight: 300;
            color: ${theme.colors.shade};
        }
    }

    & .column {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 12px;

        & > .dropdown-select {
            flex: 1;
        }

        &__delete {
            height: 30px;
            width: 30px;
            border: none;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
        }
    }
`;

export const ReportColumn = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 21px;
    margin-bottom: 16px;

    .selectWrapper {
        flex-grow: 3;

        label {
            margin-bottom: 8px;
        }

        .select {
            width: 100%;
        }
    }

    .modifiers {
        flex-grow: 1;
        height: 100%;
        span {
            display: flex;
            .inputWrapper {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                margin-right: 21px;
                input {
                    height: 40px;
                    width: 19px;
                }
            }
        }
    }
`;

export const AddColumn = styled.p`
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    background: linear-gradient(156.78deg, #4d9eff 15.32%, #686fff 85.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    cursor: pointer;
`;

export const StyledDiscardReportModal = styled(ResizableModalWrapper)`
    width: min(100%, 400px);

    & p {
        text-align: center;
        padding-block: 8px;
    }
`;

export const StyledSaveReportModal = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    & .checkbox-group {
        background-color: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 4px;

        &__legend {
            flex: 1;
            font-weight: 300 !important;
        }
    }

    & p {
        text-align: center;
        padding-block: 8px;
    }
`;
