import moment, { Moment } from "moment";
import { useState, useEffect } from "react";

export type DateTimePickerMode = "dateTime" | "time" | "date";

interface DateTimePickerOptions {
    initialValue?: string;
    defaultValue?: string | Moment;
    minDate?: Moment;
    maxDate?: Moment;
    format?: string;
    required?: boolean;
    minOverValueText?: string;
    maxBeforeValueText?: string;
    mode?: DateTimePickerMode;
    minTime?: string;
    maxTime?: string;
    disabled?: boolean;
}

export interface DateTimeInputProps {
    selected: Date | null;
    dateHandler: (date: Date | null) => void;
    error: string;
    isValid: boolean;
    minDate?: Date;
    maxDate?: Date;
    required: boolean;
    mode: DateTimePickerMode;
    minTime?: string;
    maxTime?: string;
    disabled?: boolean;
}

const initOption: DateTimePickerOptions = {
    mode: "dateTime",
    format: "YYYY-MM-DD HH:mm",
    required: true,
};

const useDateTimePicker = (options: DateTimePickerOptions) => {
    const hookOptions = { ...initOption, ...options };
    const dateFormat = "YYYY-MM-DD";
    const timeFormat = "HH:mm";
    const format = () => {
        if (hookOptions.mode === "date") return dateFormat;
        if (hookOptions.mode === "time") return timeFormat;
        return `${dateFormat} ${timeFormat}`;
    };
    const defaultValue = moment(hookOptions?.defaultValue, format());
    const isInitValueValid = defaultValue.isValid();
    const initialStateValue = isInitValueValid ? defaultValue : null;
    const [value, setValue] = useState<Moment | null>(initialStateValue);
    const [error, setError] = useState("");
    const isValid = !error;
    const valueAsDate = value?.toDate() || null;
    const valueAsString = value?.format(format()) || "";
    const checkDayType = hookOptions?.mode === "date" ? "day" : "minute";

    const isMinOverValue = hookOptions?.minDate
        ? moment(hookOptions?.minDate).isAfter(value, checkDayType)
        : false;
    const isMaxBeforeValue = hookOptions?.maxDate
        ? moment(hookOptions?.maxDate).isBefore(value, checkDayType)
        : false;

    const isMinTimeOverValue = moment(hookOptions?.minTime, timeFormat).isAfter(
        value,
        "minute"
    );
    const isMaxTimeBeforeValue = moment(
        hookOptions?.minTime,
        timeFormat
    ).isAfter(value, "minute");

    useEffect(() => {
        const initValue = moment(hookOptions?.initialValue, format());
        const isInitValueValid = initValue.isValid();
        if (isInitValueValid) setValue(initValue);
    }, [options?.initialValue]);

    useEffect(() => {
        if (hookOptions?.disabled) return setError("");
        if (hookOptions?.required && !value)
            return setError("This field is required.");
        if (isMinOverValue || isMinTimeOverValue)
            return setError(
                hookOptions?.minOverValueText || "Excceed Min. Date"
            );
        if (isMaxBeforeValue || isMaxTimeBeforeValue)
            return setError(
                hookOptions?.maxBeforeValueText || "Excceed Max. Date"
            );
        return setError("");
    }, [
        value,
        isMinOverValue,
        isMaxBeforeValue,
        isMinTimeOverValue,
        isMaxTimeBeforeValue,
        hookOptions?.required,
        hookOptions?.minOverValueText,
        hookOptions?.maxBeforeValueText,
        hookOptions?.disabled,
    ]);

    const setValueFromDateString = (string?: string) => {
        if (string && moment(string, format()).isValid())
            setValue(moment(string, format()));
    };

    const dateHandler = (date: Date | null) => {
        if (moment(date).isValid()) return setValue(moment(date));
        return setValue(null);
    };

    return {
        value,
        setValue,
        setValueFromDateString,
        error,
        isValid,
        valueAsDate,
        valueAsString,
        inputProps: {
            selected: valueAsDate,
            dateHandler,
            error,
            isValid,
            minDate: hookOptions?.minDate?.toDate() || undefined,
            maxDate: hookOptions?.maxDate?.toDate() || undefined,
            required: !!hookOptions?.required,
            mode: hookOptions?.mode,
            minTime: hookOptions?.minTime,
            maxTime: hookOptions?.maxTime,
            disabled: !!hookOptions?.disabled,
        } as DateTimeInputProps,
    };
};

export default useDateTimePicker;
