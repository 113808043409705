import React, { useState } from "react";
import { Button, ModalContainer } from "../../../../components";
import CreateIncomeExpenditure from "../../../../components/Modals/Settings/IncomeExpenditure/CreateIncomeExpenditure";
import SearchRow from "../../../../components/SearchRow";
import DataTable from "../../../../components/DataTable";
import useFetchData from "../../../../global/hooks/useFetchData";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";
import { LoadingSpinner } from "../../../../components/Button/styles";
import { IncomeExpenditureTables } from "./styles";
import IncomeExpenditureRow from "../../../../components/DataTable/TableRows/Settings/IncomeExpenditureRow";

const IncomeExpenditure = () => {
    const { others, setOther } = useReduxSettings();
    const [searchTerm, setSearchTerm] = useState("");
    const [createModal, setCreateModal] = useState(false);

    const getIncomeExpenditure = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/other-income-expenditure`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data?.income) setOther("income", data.data.income);
                if (data?.data?.expenditure)
                    setOther("expenditure", data.data.expenditure);
            },
        }
    );

    const tableKeys = ["nme", "budgeting", "active"];

    return (
        <>
            <ModalContainer
                isVisible={createModal}
                children={
                    <CreateIncomeExpenditure
                        setIsOpen={setCreateModal}
                        isOpen={createModal}
                    />
                }
            />
            <SearchRow
                value={searchTerm}
                setValue={setSearchTerm}
                placeholder="Search by name..."
                style={{ marginBottom: "24px" }}
            >
                <Button
                    btnText="+ Create"
                    onClick={() => setCreateModal(true)}
                    style="mint"
                    role="create"
                />
            </SearchRow>
            {getIncomeExpenditure.isLoading ? (
                <LoadingSpinner size={40} />
            ) : (
                <IncomeExpenditureTables className="tables">
                    <DataTable
                        labels={["Income", "PBS", "Active"]}
                        keys={tableKeys}
                        data={others.income || []}
                        searchTerm={searchTerm}
                        searchKeys={["nme"]}
                        tableRow={IncomeExpenditureRow}
                    />
                    <DataTable
                        labels={["Expenditure", "PBS", "Active"]}
                        keys={tableKeys}
                        data={others.expenditure || []}
                        searchTerm={searchTerm}
                        searchKeys={["nme"]}
                        tableRow={IncomeExpenditureRow}
                    />
                </IncomeExpenditureTables>
            )}
        </>
    );
};

export default IncomeExpenditure;
