import styled from "styled-components";
import { theme } from "../../../../global/theme";
import { Row } from "../../../../global/theme/reuseable/components";
import { mainScrollbar } from "../../../../global/theme/reuseable/mixins";

// Print Maintenance

export const StyledPrintMaintenance = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 16px;
`;

export const PrintTableWrapper = styled.section`
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    min-width: 340px;

    &:nth-child(3) {
        & .header {
            background-image: ${theme.gradients.dangerRed};

            &[aria-colcount="0"] {
                background-image: ${theme.gradients.mintGreen};
            }
        }
    }

    .header {
        padding: 8px;
        background: linear-gradient(123.76deg, #4d9eff 4.28%, #686fff 103.7%);

        h4 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            text-align: center;
            color: ${theme?.colors.white};
        }
    }

    & .data-table {
        flex: initial;
        &__wrapper {
            flex: initial;
        }
    }
`;

// Lead Status

export const LeadStatusWrapper = styled.section`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .nav {
        margin-bottom: 24px;
        & > button {
            height: 40px;
            padding-inline: 24px;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        list-style: none;
        flex: 1 1 0;
        overflow-y: auto;
        ${mainScrollbar(12)}
    }
`;

// Income & Expenditure

export const IncomeExpenditureTables = styled.div`
    flex: 1 1 0;
    display: flex;
    gap: 24px;
    overflow: hidden;
`;

// Global

export const GlobalSettingsRow = styled(Row)`
    flex: 1;
    align-items: stretch;
`;

export const GlobalWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    & .content {
        flex: 1 1 0;
    }

    &:first-child {
        & .text-editor {
            flex: 1;
        }
    }

    &:last-child {
        & .content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto 1fr auto;
            max-height: 600px;

            & .text-editor {
                grid-column: 1/-1;
            }

            & > button {
                grid-column: 1/-1;
                justify-self: flex-end;
            }
        }
    }

    .header {
        background: linear-gradient(123.76deg, #4d9eff 4.28%, #686fff 103.7%);
        padding: 14px 34px 12px 34px;
        width: 100%;
        border-radius: 10px 10px 0px 0px;

        h3 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: ${theme?.colors.white};
        }
    }
    .content {
        padding: 24px;
        background-color: ${theme?.colors.containerBackground};
        display: flex;
        flex-direction: column;
        gap: 16px;

        & hr {
            border: none;
            border-top: 0.6px solid ${theme?.colors.shade};
        }

        & > button {
            align-self: flex-end;
            padding-inline: 24px;
        }
    }
`;
