import React, { useState } from "react";
import { icons } from "../../../assets/icons";
import StyledTVCard from "./styles";
import { ReactComponent as GreenTVIcon } from "../../../assets/icons/greenTv.svg";
import { ReactComponent as RedTVIcon } from "../../../assets/icons/redTv.svg";
import { ReactComponent as LayoutIcon } from "../../../assets/icons/greyLayout.svg";
import { ReactComponent as GlobeIcon } from "../../../assets/icons/greyGlobe.svg";
import { formatTimeString } from "../../../global/utils/helpers";

interface TvCardProps {
    data: { [key: string]: any };
    onClick: (data: { [key: string]: any }) => void;
}

const TVCard: React.FC<TvCardProps> = ({ data, onClick }) => {
    const ActiveIcon = data?.active ? GreenTVIcon : RedTVIcon;

    return (
        <>
            <StyledTVCard onClick={() => onClick(data)} className="tv-card">
                <ActiveIcon />
                <div className="tv-card__name">
                    <h2 className="tv-card__name">
                        {data?.name || "Unknown Device"}
                    </h2>
                    <LayoutIcon />
                </div>
                <div className="tv-card__info">
                    <ul className="tv-card__list">
                        <li className="tv-card__item">
                            ID: <span>{data?.id || "-"}</span>
                        </li>
                        <li className="tv-card__item">
                            Weather Location:{" "}
                            <span>{data?.location || "Unknown"}</span>
                        </li>
                        <li className="tv-card__item">
                            Start Time:{" "}
                            <span>
                                {formatTimeString(
                                    data?.start || "",
                                    "HH:mm:ss",
                                    "HH:mm"
                                )}
                            </span>
                        </li>
                        <li className="tv-card__item">
                            Finish Time:{" "}
                            <span>
                                {formatTimeString(
                                    data?.end || "",
                                    "HH:mm:ss",
                                    "HH:mm"
                                )}
                            </span>
                        </li>
                        <li className="tv-card__item">
                            Last Update:{" "}
                            <span>
                                {formatTimeString(data?.updated_at || "")}
                            </span>
                        </li>
                    </ul>
                    <GlobeIcon />
                </div>
            </StyledTVCard>
        </>
    );
};

export default TVCard;
