import { useState } from "react";
import { useParams } from "react-router-dom";

import ModalHeader from "../../components/ModalHeader";
import { StyledCaseComplete } from "./styles";
import DropdownSelect from "../../../DropdownSelect";
import { clientCaseSubStatusOptions } from "../../../DropdownSelect/options";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import useReduxUser from "../../../../store/hooks/useReduxUser";
import TextArea from "../../../Input/TextArea";
import { CaseActionsModalProps } from "./types";

const CaseComplete: React.FC<CaseActionsModalProps> = ({ isOpen, onClose }) => {
    const { clientId } = useParams();
    const { setClientCase, setClientCaseByKey } = useReduxUser();
    const [status, setStatus] = useState<string | null>("");
    const [notes, setNotes] = useState<string>("");
    const { data } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/mark-as-complete/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) {
                    setStatus(data.data.status || "");
                    setNotes(data.data.notes || "");
                }
            },
        }
    );

    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/mark-as-complete/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) {
                    setClientCaseByKey("details", data?.data?.column1[0]);
                    setClientCaseByKey("payments", data?.data?.column2[0]);
                    setClientCaseByKey("services", data?.data?.column3[0]);
                }
            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({
            status: status,
            notes: notes,
        });
    };

    return (
        <StyledCaseComplete show={isOpen} width="500px" isSuccess={isSuccess}>
            <ModalHeader onClose={onClose}>Mark As Case Complete</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="The case has been marked as complete."
                    onClose={onClose}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <DropdownSelect
                        label="Please select a sub-status:"
                        options={clientCaseSubStatusOptions}
                        value={status}
                        name="status"
                        onSelect={(option) => setStatus(option?.value || null)}
                        hasDefaultValue
                    />
                    <TextArea
                        label="Additional notes for marking case complete:"
                        value={notes || ""}
                        name="notes"
                        onChange={(e) => setNotes(e.target.value)}
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Save"
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledCaseComplete>
    );
};

export default CaseComplete;
