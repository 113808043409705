import { Input, TextArea } from "../../../index";
import useReduxIntroducers from "../../../../store/hooks/useReduxIntroducers";
import DropdownSelect from "../../../DropdownSelect";
import { IntroducerCreateFormProps } from "../IntroducerCreate";

const IntroducerInfo: React.FC<IntroducerCreateFormProps> = ({
    introducerDetails,
    onChange,
    onSelect,
}) => {
    const { types } = useReduxIntroducers();

    return (
        <div className="introducer-info col-2">
            <Input
                name="name"
                type="text"
                label="Company Name: (*)"
                placeholder="Company Name..."
                onChange={onChange}
                value={introducerDetails.name}
                autoComplete="off"
            />
            <DropdownSelect
                label="Type: (*)"
                placeholder="Select One..."
                options={types}
                optionKey="id"
                labelKeys={["name"]}
                onSelect={(option) => onSelect("type", option.id)}
                value={introducerDetails.type}
            />
            <TextArea
                placeholder="Company Address..."
                onChange={onChange}
                name="address"
                label="Address:"
                value={introducerDetails.address}
            />
            <hr />
        </div>
    );
};

export default IntroducerInfo;
