import React, { useState } from "react";
import { StyledAddressSearch } from "./styles";
import ButtonInput from "./ButtonInput";
import DropdownSelect from "../DropdownSelect";
import { predictAddress, getFullAddress } from "../../global/services/data8";
import { listCountText } from "../DropdownSelect/functions";
import TextArea from "./TextArea";

interface AddressSearchProps {
    className?: string;
    postcode: string;
    onPostcodeChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    onSelect: (doorNumber: string, address: string, postcode: string) => void;
    postcodeInputName?: string;
}

const AddressSearch: React.FC<AddressSearchProps> = ({
    className = "",
    postcode,
    onPostcodeChange,
    onSelect,
    postcodeInputName = "postcode",
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [addressList, setAddressList] = useState<
        { [key: string]: any }[] | null
    >(null);

    const handleAddressSearch = () => {
        predictAddress(postcode || "", setAddressList, setIsLoading);
    };

    const addressSelectHandler = (data: { [key: string]: any }) => {
        const valueId = data?.value ? data?.value?.split("|")?.[0] : "";
        console.log(valueId);
        getFullAddress(valueId, (doorNumber, address, postcode) =>
            onSelect(doorNumber, address, postcode)
        );
    };

    return (
        <StyledAddressSearch
            className={`address-search${className ? ` ${className}` : ""}`}
        >
            <ButtonInput
                className="address-search__postcode"
                label="Postcode"
                type="text"
                name={postcodeInputName}
                onChange={onPostcodeChange}
                onClick={handleAddressSearch}
                btnText="Search"
                value={postcode || ""}
                buttonDisabled={!postcode || postcode.trim() === ""}
                placeholder="Postcode"
            />
            <DropdownSelect
                className="address-search__select"
                label={`Search Results:`}
                options={addressList || []}
                optionKey="value"
                labelKeys={["label"]}
                enableInput
                placeholder={
                    addressList
                        ? listCountText(addressList)
                        : "Enter Postcode to search..."
                }
                isLoading={isLoading}
                onSelect={addressSelectHandler}
            />
        </StyledAddressSearch>
    );
};
export default AddressSearch;
