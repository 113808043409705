import styled from "styled-components";
import { PageContent } from "../../../../pages/styles";

const StyledAgreementDetailsContent = styled(PageContent)`
    & > form {
        display: grid;
        grid-template-columns: min(100%, 1120px);
        justify-content: center;
        gap: 24px;
    }

    & .col-2 {
        & .header-container__ctn {
            display: grid;
            gap: 12px 16px;
            grid-template-columns: 1fr 1fr;
        }
    }

    & .other-details,
    & .payment-details {
        & .header-container__ctn {
            display: grid;
            gap: 12px 16px;
            grid-template-columns: repeat(6, 1fr);
            & > :nth-child(1),
            & > :nth-child(2),
            & > :nth-child(3) {
                grid-column-end: span 2;
            }
            & > :nth-child(4),
            & > :nth-child(5) {
                grid-column-end: span 3;
            }
        }
    }

    & .back-office {
        & .header-container__ctn {
            & > :last-child {
                grid-column: 1/-1;
            }
        }
    }
`;

export default StyledAgreementDetailsContent;
