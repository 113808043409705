import styled from "styled-components";
import { lineClamp } from "../../../../global/theme/reuseable/mixins";

export const StyledListRow = styled.tr`
    & td {
        &:nth-child(1) {
            width: 80px;
        }
        &:nth-child(2) {
            width: 120px;
        }
        &:nth-child(3) {
            & > div {
                ${lineClamp(2)};
            }
        }

        &:nth-child(4) {
            width: 220px;
        }
        &:nth-child(5) {
            width: 180px;
        }
        &:nth-child(6) {
            width: 140px;
        }
    }
`;

export const CorresTypeBlock = styled.td`
    & > div {
        display: flex;
        align-items: center;
        gap: 4px;
        justify-content: space-between;
        text-transform: capitalize;
    }
    & svg {
        width: 8px;
        &[name="in"] {
            & path {
                stroke: #11d311;
            }
        }
        &[name="out"] {
            & path {
                stroke: #ee1212;
            }
        }
    }
`;
