import styled from "styled-components";
import { theme } from "../../global/theme";
import { iSCInput } from "./types";
import { mainScrollbar } from "../../global/theme/reuseable/mixins";

export const inputPlaceholderMixin = `
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  color: ${theme?.colors?.text};
`;

export const inputMixin = `
  background: ${theme?.colors?.white};
  border: 0.4px solid rgba(8, 42, 82, 0.3);
  border-radius: 5px;
`;

export const InputWrapper = styled.div<iSCInput>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    input {
        ${inputMixin}
        border-radius: 5px;
        padding: 6px 8px;
        min-height: 30px;
        width: 100%;
        ${inputPlaceholderMixin};

        &:focus {
            outline: none;
            border: 0.1px solid ${theme.colors.boxStroke};
        }

        &[aria-invalid="true"] {
            outline: none;
            border-color: #dc1313;
        }

        &:disabled {
            background-color: ${theme.colors.background};
            opacity: 0.6;
        }

        &:read-only {
            background-color: ${theme.colors.containerBackground};
            opacity: 1;
            pointer-events: none;
        }
    }
    input[type="checkbox"] {
        accent-color: ${theme?.colors?.formBlue};
    }
    textarea {
        ${inputMixin}
        width: 100%;
        resize: none;
        padding: 8px;
        ${inputPlaceholderMixin}
        border-radius: 6px;
        ${mainScrollbar(12)};

        &:focus {
            outline: none;
            border: 0.1px solid ${theme.colors.boxStroke};
        }
    }

    input[type="radio"] {
    }

    .imgInputContainer {
        // If there is an innerImg
        ${inputMixin}
        padding-right: 10px;
        width: 100%;

        display: flex;
        flex-direction: row-reverse;

        input,
        &:focus,
        &:active {
            outline: none;
            border: none;
        }
    }

    & .inputWrapper {
        &__ctn {
            width: 100%;
            display: flex;
            gap: 4px;
        }

        &__view {
            border: none;
            border-radius: 4px;
            padding: 0 4px;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;

            &[data-active="true"] {
                background-color: ${theme?.colors.highlightBlue};

                /* & path {
                    fill: ${theme?.colors.white};
                } */
            }
        }
    }
`;

export const SearchInputWrapper = styled.div`
    height: 40px;
    width: 300px;
    position: relative;

    img {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
    }
    input {
        height: inherit;
        width: inherit;
        border: 0.6px solid ${theme?.colors.shade};
        border-radius: 20px;
        padding-left: 42px;
        background: #f5f5f5;
        outline: none;
        font-weight: 300;
        font-size: 12px;

        &:active,
        &:focus {
            outline: none;
            border-color: ${theme?.colors.boxStroke};
        }
        ::placeholder {
            ${inputPlaceholderMixin}
        }
    }
`;

export const ButtonInputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    ${inputMixin}
    border-radius: 5px;
    border: 0.6px solid ${theme?.colors.shade};
    height: 30px;
    overflow: hidden;

    &[data-focused="true"] {
        border-color: ${theme?.colors.boxStroke};
    }

    input {
        flex: 1;
        height: 100%;
        background-color: transparent !important;
        border: none;
        outline: none;
        border-radius: 0;
        padding: 2px 6px;
        &:focus {
            border: none;
        }
    }
    button {
        height: calc(100% - 4px);
        border-radius: 4px;
        margin: 0;
        margin-right: 2px;
        padding: 4px 12px;
        font-size: 11px;
        width: auto;
    }
`;

export const StyledMultiCheckbox = styled.div``;

export const StyledAddressSearch = styled.div`
    display: flex;
    gap: 12px 16px;

    & .address-search {
        &__postcode {
            width: 160px;
        }

        &__select {
            flex: 1;
        }
    }
`;

export const StyledCheckbox = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    & .checkbox {
        &__label {
            font-size: 12px;
            font-weight: 300;
            color: ${theme.colors.text};
        }

        &__input {
            height: 18px;
            width: 18px;
        }
    }
`;
