import { useState } from "react";
import { icons } from "../../../assets/icons";

import Button from "../../Button";
import { DeleteReportModalWrapper } from "../styles";
import { iAdminModal } from "./types";
const ReDoUploadModal = ({ setModalType, open }: iAdminModal) => {
    const closeModal = () => setModalType("");

    return (
        <DeleteReportModalWrapper show={open} stage={0}>
            <div className="heading">
                <h2>Please Confirm...</h2>
                <img src={icons.close} alt="Close" onClick={closeModal} />
            </div>
            <p>Do you want to redo this upload?</p>

            <div className="nav">
                <Button onClick={closeModal} style="purple" btnText="ReDo" />
                <Button onClick={closeModal} style="cancel" btnText="Cancel" />
            </div>
        </DeleteReportModalWrapper>
    );
};

export default ReDoUploadModal;
