import React, { useState, useEffect } from "react";
import { icons } from "../../../../assets/icons";
import PaymentDashboardStat from "../../../../atoms/PaymentDashboardStat";
import { Modal } from "../../../../components";
import PaymentDashboardCard from "../../../../components/Cards/PaymentDashboard";
import SetPaymentRun from "../../../../components/Modals/Payments/SetPaymentRunModal";
import AdminLedgerModal from "../../../../components/Modals/Payments/AdminLedgerModal";
import { Column } from "../../../../global/theme/reuseable/components";
import { PaymentsLandingWrapper } from "../styles";
import Header from "../../../../layout/Header";
import DateButton from "../../../../components/Button/DateButton";
import AuthorisationRate from "../../../../atoms/AuthorisationRate";
import { PageContent } from "../../../../pages/styles";
import useDateSearch from "../../../../layout/SlideInMenus/DateSearch/useDateSearch";
import useFetchData from "../../../../global/hooks/useFetchData";

const PaymentLanding: React.FC = () => {
    const [showPaymentRunModal, setShowPaymentRunModal] = useState(false);
    const [showUpdateBankPayments, setShowUpdateBankPayments] = useState(false);
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today", true);

    const { data, isLoading, fetchData } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-payment-index`,
        },
        {
            fetchOnLoad: true,
            params: { from: dateRange.from, to: dateRange.to },
            onSuccess: onDateSearchSuccess,
            notifyOnSuccess: true,
        }
    );
    const paymentData = data?.data;

    useEffect(() => {
        if (dateRange) {
            fetchData();
        }
    }, [dateRange]);

    return (
        <>
            <Modal isVisible={showPaymentRunModal}>
                <SetPaymentRun
                    isOpen={showPaymentRunModal}
                    setIsOpen={setShowPaymentRunModal}
                />
            </Modal>
            <Modal isVisible={showUpdateBankPayments}>
                <AdminLedgerModal
                    isOpen={showUpdateBankPayments}
                    setIsOpen={setShowUpdateBankPayments}
                />
            </Modal>
            <Header
                title="payments dashboard"
                contentStyle={{ justifyContent: "space-between" }}
                hasNavBarAbove
            >
                <AuthorisationRate value={paymentData?.rate || 0} />
                <DateButton
                    isLoading={isLoading}
                    dateDisplay={dateDisplay}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                />
            </Header>
            <PageContent>
                <PaymentsLandingWrapper>
                    <Column gap="21px">
                        <PaymentDashboardStat
                            value={paymentData?.collected || 0}
                            subTitle="Collected"
                        />
                        <PaymentDashboardStat
                            value={paymentData?.declined || 0}
                            subTitle="Declined"
                        />
                        <PaymentDashboardStat
                            value={paymentData?.error || 0}
                            subTitle="Error"
                        />
                    </Column>
                    <PaymentDashboardCard
                        title="Set Payment Run"
                        img={icons?.paymentRun}
                        onClick={() => setShowPaymentRunModal(true)}
                    />
                    <PaymentDashboardCard
                        title="Update Bank Payments"
                        img={icons?.updateBankPayments}
                        onClick={() => setShowUpdateBankPayments(true)}
                    />
                </PaymentsLandingWrapper>
            </PageContent>
        </>
    );
};

export default PaymentLanding;
