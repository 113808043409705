import styled from "styled-components";

export const StyledAccessLogsRow = styled.tr<{ status?: string }>`
    & td {
        background-color: ${({ status }) => {
            if (status === "Login") return "#d5f8cc !important";
            if (status === "Denied: Password") return "#ffd2d2 !important";
            return "orange";
        }};

        &[datatype="ipAddress"] {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & a {
                display: flex;
                align-items: center;
            }

            & svg {
                height: 12px;
                width: 12px;
            }
        }
    }
`;

export const StyledLoginLogsRow = styled(StyledAccessLogsRow)``;
