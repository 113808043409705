import styled from "styled-components";
import { theme } from "../../global/theme";

export const LabelMixin = `
font-weight: 300;
font-size: 12px;
line-height: 18px;
color: ${theme?.colors?.darkText};
`;
export const LabelWrapper = styled.label`
    ${LabelMixin}// margin-bottom: 13px;
`;
