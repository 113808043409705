import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UIState {
   sideMenu: string;
   sideNavData: {[key: string]: any} | null;
   notifications: {[key: string]: any} | null;
}

const initialUIState: UIState = {
    sideMenu: '',
    sideNavData: null,
    notifications: null,
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState: initialUIState,
    reducers: {
        setSideMenu: (state, action: PayloadAction<string>) => {
            state.sideMenu = action.payload
        },
        setSideNavData: (state, action: PayloadAction<{[key: string]: any} | null>) => {
            state.sideNavData = action.payload
        },
        setNotifications: (state, action: PayloadAction<{[key: string]: any} | null>) => {
            state.notifications = action.payload
        },
    }
})

export const uiActions = uiSlice.actions
export default uiSlice