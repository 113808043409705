import { useAppSelector, useAppDispatch } from ".";
import { clientComplaintsActions } from "../slices/clientComplaintsSlice";

const useReduxClientComplaints = () => {
    const { complaints } = useAppSelector(
        (state) => state["client-complaints"]
    );
    const dispatch = useAppDispatch();

    const setComplaints = (data: Array<{ [key: string]: any }>) => {
        dispatch(clientComplaintsActions.setComplaints(data));
    };

    const resetSlice = () => {
        dispatch(clientComplaintsActions.resetSlice());
    };

    return {
        complaints,
        setComplaints,
        resetSlice,
    };
};

export default useReduxClientComplaints;
