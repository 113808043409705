import React from "react";
import { Route, Routes } from "react-router-dom";
import NavBar from "../../layout/Header/NavBar";
import ProductsLanding from "../../subPages/ClientCase/Products";
import PersonalBudgeting from "../../subPages/ClientCase/Products/PersonalBudgeting";
import PersonalBudgetingIE from "../../subPages/ClientCase/Products/PersonalBudgetingIE";
import PayeRebate from "../../subPages/ClientCase/Products/PayeRebate";
import WFHRebate from "../../subPages/ClientCase/Products/WFHRebate";
import PersonalAccountancy from "../../subPages/ClientCase/Products/PersonalAccountancy";
import BusinessAccountancy from "../../subPages/ClientCase/Products/BusinessAccountancy";

const Products: React.FC = () => {
    return (
        <>
            <NavBar subListName="products" />
            <Routes>
                <Route index element={<ProductsLanding />} />
                <Route
                    path="personal-budgeting"
                    element={<PersonalBudgeting />}
                />
                <Route
                    path="personal-budgeting-ie"
                    element={<PersonalBudgetingIE />}
                />
                <Route path="paye-rebate" element={<PayeRebate />} />
                <Route path="work-from-home-rebate" element={<WFHRebate />} />
                <Route
                    path="personal-accountancy"
                    element={<PersonalAccountancy />}
                />
                <Route
                    path="business-accountancy"
                    element={<BusinessAccountancy />}
                />
            </Routes>
        </>
    );
};

export default Products;
