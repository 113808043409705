import React, { useEffect, useState } from "react";
import { SearchHeader } from "../styles";
import { TablePageContent } from "../../../styles";
import DataTable from "../../../../components/DataTable";
import PageTabs from "../../../../components/PageTabs/PageTabs";
import useDateSearch from "../../../../layout/SlideInMenus/DateSearch/useDateSearch";
import { dateSearchOptions } from "../../../../layout/SlideInMenus/DateSearch/data";
import DateButton from "../../../../components/Button/DateButton";
import DropdownSelect from "../../../../components/DropdownSelect";
import { fieldForDateOptions } from "../../../../components/DropdownSelect/options";
import useFetchData from "../../../../global/hooks/useFetchData";
import { Button } from "../../../../components";
import SearchRow from "../../../../components/SearchRow";
import CreditTables from "./CreditTables";

const tabs = ["Credit Control Transfer", "Overdue Transfer"];

const Credit: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today", true);
    const [fieldForDate, setFieldForDate] = useState(
        fieldForDateOptions[0].value as string
    );
    const [tablesData, setTablesData] = useState<{ [key: string]: any } | null>(
        null
    );

    const getCreditLists = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/administration-transfacc`,
        },
        {
            fetchOnLoad: true,
            params: {
                from: dateRange.from,
                to: dateRange.to,
                field: fieldForDate,
            },
            onSuccess: (data) => {
                if (data?.data) setTablesData(data?.data);
            },
        }
    );

    const transferClient = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/credit-case-transfer`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTablesData(data?.data);
            },
        }
    );

    const overDueClient = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/over-case-transfer`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setTablesData(data?.data);
            },
        }
    );

    // On Enter we need to search

    useEffect(() => {
        setSelectedIds([]);
        setSearchTerm("");
    }, [selectedTabIndex]);

    const filterSearchHandler = () => {
        getCreditLists.fetchData();
    };

    const transferClientHandler = () => {
        const requestData = {
            transfer_to: selectedTabIndex === 0 ? "credit control" : "overdue",
            client_id: selectedIds,
            from: dateRange.from,
            to: dateRange.to,
        };
        if (selectedTabIndex == 0) {
            transferClient.fetchData(requestData);
        } else {
            overDueClient.fetchData(requestData);
        }
    };

    return (
        <>
            <SearchHeader
                title="administration credit"
                hasNavBarAbove
                hasPrevPath
            >
                <DropdownSelect
                    label="Field For Date:"
                    placeholder="Field For Date"
                    value={fieldForDate}
                    options={fieldForDateOptions}
                    onSelect={(option) => setFieldForDate(option.value)}
                />
                <DateButton
                    isLoading={false}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                    dateDisplay={dateDisplay}
                    submitBtnText="Select"
                    onSubmit={onDateSearchSuccess}
                    selectedDateAsBtnText
                />
                <Button
                    btnText="Filter"
                    onClick={filterSearchHandler}
                    style="purple"
                    paddingInline="24px"
                    loading={getCreditLists.isLoading}
                />
            </SearchHeader>
            <TablePageContent>
                <PageTabs
                    tabs={tabs}
                    selectedTabIndex={selectedTabIndex}
                    setSelectedTabIndex={setSelectedTabIndex}
                    notifications={[
                        tablesData?.body.length,
                        tablesData?.body.length,
                    ]}
                />
                <SearchRow value={searchTerm} setValue={setSearchTerm}>
                    <Button
                        btnText={`Transfer Clients${
                            selectedIds?.length
                                ? ` (${selectedIds.length})`
                                : ""
                        }`}
                        onClick={transferClientHandler}
                        style="purple"
                        paddingInline="24px"
                        disabled={!selectedIds?.length}
                    />
                </SearchRow>
                <CreditTables
                    selectedTabIndex={selectedTabIndex}
                    tablesData={tablesData}
                    searchTerm={searchTerm}
                    setSelectedIds={setSelectedIds}
                />
            </TablePageContent>
        </>
    );
};

export default Credit;
