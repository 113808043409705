import SearchInput, { SearchInputProps } from "../Input/SearchInput";
import StyledSearchRow from "./styles";

interface SearchRowProps extends SearchInputProps {
    className?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties
}

const SearchRow: React.FC<SearchRowProps> = ({
    children,
    value,
    setValue,
    onSearch = () => {},
    placeholder = "search...",
    searchKey = "enter",
    searchOnChange = false,
    searchOnDebounce = false,
    searchOnKeyDown = false,
    debounceDelay = 250,
    className = "",
    style
}) => {
    return (
        <StyledSearchRow
            className={`search-row${className ? ` ${className}` : ""}`}
            style={style}
        >
            <SearchInput
                className="search-row__input"
                value={value}
                setValue={setValue}
                onSearch={onSearch}
                placeholder={placeholder}
                searchKey={searchKey}
                searchOnChange={searchOnChange}
                searchOnDebounce={searchOnDebounce}
                searchOnKeyDown={searchOnKeyDown}
                debounceDelay={debounceDelay}
            />
            <div className="search-row__content">{children}</div>
        </StyledSearchRow>
    );
};

export default SearchRow;
