import { linkSync } from "fs";
import React, { useState } from "react";
import { LinearHeading } from "../../global/theme/reuseable/components";
import { AccordionCardContainer, ViewMore } from "./styles";
import { iAccordionCard } from "./types";

const AccordionCard = ({ list, color, jobTitle, count }: iAccordionCard) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <AccordionCardContainer open={open}>
            <h3>{jobTitle}</h3>

            <LinearHeading color={color}>{count|| "--"}</LinearHeading>
            {list?.length ? (
                <ul className="list">
                    {list.map((obj, i) => (
                        <li className="item" key={i}>
                            {obj?.name || "---"}
                        </li>
                    ))}
                </ul>
            ) : null}

            {list?.length > 3 ? (
                <ViewMore onClick={() => setOpen(!open)}>
                    {open ? "View Less -" : "View More +"}
                </ViewMore>
            ) : null}
        </AccordionCardContainer>
    );
};

export default AccordionCard;
