import { Dispatch, SetStateAction, useState } from "react";
import Button from "../../Button";
import ModalHeader from "../components/ModalHeader";
import DropdownSelect from "../../DropdownSelect";
import { generateTestOptions } from "../../DropdownSelect/options";
import { StyledChangeUserPermissions } from "./styles";
import ModalButtons from "../components/ModalButtons";
import useFetchData from "../../../global/hooks/useFetchData";
import SuccessContent from "../components/SuccessContent";

interface iChangeUserPermissionModal {
    open: boolean;
    setUserPermissionModal: Dispatch<SetStateAction<boolean>>;
    userId: number;
}

const ChangeUserPermissionModal = ({
    open,
    setUserPermissionModal,
    userId,
}: iChangeUserPermissionModal) => {
    const [permission, setPermission] = useState<number[]>([]);
    const closeModal = () => setUserPermissionModal(false);
    const [userPermission, setUerPermission] = useState<
        Array<{ [ket: string]: any }>
    >([]);
    const [groupList, setGroupList] = useState<Array<{ [ket: string]: any }>>(
        []
    );
    const { fetchData: updateGroup, isSuccess } = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/permission-groups/${userId}`,
    });
    const {
        data,
        fetchData: listGroup,
        isLoading,
    } = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/users-permission-admin/${userId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) {
                    setPermission(data?.data?.group || []);
                    setGroupList(data?.data?.list);
                }
            },
        }
    );
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        updateGroup(permission);
    };

    return (
        <StyledChangeUserPermissions
            show={open}
            className="change-user-permission"
        >
            <ModalHeader onClose={closeModal}>
                Change User Permissions
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="Admin permission has been updated."
                    onClose={closeModal}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <p>Please select user access rights below</p>
                    <DropdownSelect
                        options={groupList}
                        values={permission}
                        labelKeys={["name"]}
                        setValues={setPermission}
                        optionKey="id"
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Save Changes"
                            loading={isLoading}
                            disabled={permission === null}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledChangeUserPermissions>
    );
};

export default ChangeUserPermissionModal;
