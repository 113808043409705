import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { PayloadAction } from "@reduxjs/toolkit";
import uiSlice from './slices/uiSlice';
import userSlice from './slices/userSlice';
import dashboardSlice from './slices/dashboardSlice';
import accountsSlice from './slices/accountsSlice';
import diarySlice from './slices/diarySlice';
import introducersSlice from './slices/introducersSlice';
import createClientSlice from './slices/createClientSlice';
import creditorsSlice from './slices/creditorsSlice';
import ReportsSlice from './slices/reportsSlice';
import settingsSlice from './slices/settingsSlice';
import clientBaseDetailsSlice from './slices/clientBaseDetailsSlice';
import clientProductsSlice from './slices/clientProductsSlice';
import clientLedgerPaymentSlice from './slices/clientLedgerPaymentSlice';
import clientManagementSlice from './slices/clientManagementSlice';
import clientPortalSlice from './slices/clientPortalSlice';
import clientComplaintsSlice from './slices/clientComplaintsSlice';
import clientBalanceSheetSlice from './slices/clientBalanceSheetSlice';
import clientCorrespondenceSlice from './slices/clientCorrespondenceSlice';

// ...

const rootReducer = combineReducers({
    ui: uiSlice.reducer,
    user: userSlice.reducer,
    dashboard: dashboardSlice.reducer,
    createClient: createClientSlice.reducer,
    accounts: accountsSlice.reducer,
    diary: diarySlice.reducer,
    introducers: introducersSlice.reducer,
    creditors: creditorsSlice.reducer,
    reports:ReportsSlice.reducer,
    settings: settingsSlice.reducer,
    'client-baseDetails': clientBaseDetailsSlice.reducer,
    'client-products': clientProductsSlice.reducer,
    'client-ledgerPayment': clientLedgerPaymentSlice.reducer,
    'client-management': clientManagementSlice.reducer,
    'client-portal': clientPortalSlice.reducer,
    'client-complaints': clientComplaintsSlice.reducer,
    'client-balanceSheet': clientBalanceSheetSlice.reducer,
    'client-correspondence': clientCorrespondenceSlice.reducer

    
})
  

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch;

export const appReducer = (state: RootState | undefined, action: PayloadAction<RootState>) => {
    if(action.type === 'user/userLogout') return rootReducer(undefined, action)
    return rootReducer(state ,action)
  }

export const store = configureStore({
    reducer: appReducer
});