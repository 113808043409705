import React, { ChangeEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../components";
import { Form, Logo, OptionsCtn, SignInBtn } from "./styles";
import useFetchData from "../../global/hooks/useFetchData";

export interface iLoginDetails {
    email: string;
    password: string;
}
const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/reset-password`,
        },
        {
            requiresToken: false,
            onSuccess: (data) => {},
        }
    );

    const backHandler = () => {
        navigate("/login");
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({ email });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Logo>Clear Start</Logo>
            <div className="forgot-ctn">
                {isSuccess ? (
                    <>
                        <p className="message">
                            Reset Success! Please check your email and follow
                            the instructions.
                        </p>
                        <SignInBtn type="button" onClick={backHandler}>
                            Back To Login
                        </SignInBtn>
                    </>
                ) : (
                    <>
                        <p className="message">
                            Please enter you email to receive reset instruction.
                        </p>
                        <Input
                            placeholder="youremail@cs.com"
                            type="email"
                            label="Email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="loginInput reset-email"
                            autoComplete="reset-email"
                        />
                        <SignInBtn type="submit">Submit</SignInBtn>
                        <OptionsCtn>
                            <Link className="forgotPword" to="/login">
                                Back to Login
                            </Link>
                        </OptionsCtn>
                    </>
                )}
            </div>
        </Form>
    );
};

export default ForgotPassword;
