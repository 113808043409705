import styled from "styled-components";
import { theme } from "../../global/theme";
import { iSCCardEdit } from "./types";

export const CardEditWrapper = styled.span<iSCCardEdit>`
  /* top: ${(props) =>
    props.type === "delete"
      ? "-0.5px"
      : "41px"}; // Delete always sits on top of card and move sits below it -> 41px is the height (34.8 + margin (6.2px)) */

  height: 34.8px;
  width: 34.8px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 5px 5px 0px;

  // Background colour logic
  ${(props) =>
    props.type === "delete"
      ? `
      background: ${theme?.colors?.white};
      :hover { 
        background: ${theme?.gradients?.dangerRed};
      }`
      : `background: ${theme?.colors?.white};
      `}

  :hover {
    cursor: pointer;
    ${(props) =>
      props.type === "move" &&
      `
      border: solid 1px rgba(77, 158, 255, 1);
      border-left: none;

      `}
  }
`;
