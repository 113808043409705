import { PayeRebateContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";

const Gateways: React.FC<PayeRebateContainerProps> = ({
    onInputChange,
    payeRebate,
}) => {
    return (
        <>
            <HeaderContainer
                title="Gateway Information (Personal):"
                headerStyle="gradientBlue"
                ctnClassName="col-2"
            >
                <Input
                    type="text"
                    label="Gatway Username:"
                    value={payeRebate?.["gateway-username"] || ""}
                    name="gateway-username"
                    onChange={onInputChange}
                />
                <Input
                    type="password"
                    label="Gatway password:"
                    value={payeRebate?.["gateway-psswrd"] || ""}
                    name="gateway-psswrd"
                    onChange={onInputChange}
                    hasShowPassword
                    autoComplete="off"
                />
            </HeaderContainer>
            <HeaderContainer
                title="Gateway Information (UTR):"
                headerStyle="gradientBlue"
                ctnClassName="col-2"
            >
                <Input
                    type="text"
                    label="Gatway Username:"
                    value={payeRebate?.["utrgateway-username"] || ""}
                    name="utrgateway-username"
                    onChange={onInputChange}
                />
                <Input
                    type="password"
                    label="Gatway password:"
                    value={payeRebate?.["utrgateway-psswrd"] || ""}
                    name="utrgateway-psswrd"
                    onChange={onInputChange}
                    hasShowPassword
                    autoComplete="off"
                />
            </HeaderContainer>
        </>
    );
};

export default Gateways;
