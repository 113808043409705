import styled from "styled-components";
import { PageContent } from "../../../pages/styles";
import { theme } from "../../../global/theme";
import {
    mainScrollbar,
    transparentGradient,
} from "../../../global/theme/reuseable/mixins";

const StyledBaseDetailsLandingContent = styled(PageContent)`
    & .containers {
        display: grid;
        grid-template-columns: 1fr 360px 1fr;
        gap: 24px;

        @media screen and (max-width: 1250px) {
            grid-template-columns: 1fr 360px;
        }

        @media screen and (max-width: 900px) {
            grid-template-columns: 1fr;
        }

        & ul {
            list-style: none;
        }
    }

    & .container {
        background-color: ${theme.colors.white};
        border-radius: 10px;
        padding: 24px;
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__row {
            display: flex;
            align-items: center;
            gap: 12px;

            & > h2 {
                font-size: 16px;
                flex: 1;
            }

            & > a {
                border-radius: 6px;
                border: 1px solid ${theme?.colors.purple};
                padding: 6px 16px;
                font-size: 12px;
                color: ${theme?.colors.purple};
            }
        }

        & > button {
            width: min(100%, 200px);
            align-self: center;
        }
    }

    & .key-events {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 12px;
        & li {
            display: flex;
            justify-content: space-between;
            gap: 12px;
            font-size: 12px;
            font-weight: 300;
        }
    }

    & .card-details {
        flex: 1;
        justify-content: space-between;
        &__cards {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
            align-items: flex-start;
            max-height: 206px !important;
        }
        & > button {
            width: min(100%, 200px);
            align-self: center;
        }

        &__card {
            width: 100%;
        }

        &__empty {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    & .circular-meter {
        flex: 1;
        aspect-ratio: initial;
        display: flex;
        justify-content: center;
        align-items: center;

        &__meter {
            width: 140px;
            height: 140px;
        }
    }

    & .allocated-services {
        & li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 12px;
            padding-block: 12px;
            border-bottom: 0.6px solid ${theme.colors.boxStroke};
            font-weight: 300;
            font-size: 14px;
        }

        & button {
            background-color: transparent;
            border-radius: 6px;
            border: 1px solid ${theme?.colors.purple};
            color: ${theme?.colors.purple};
            padding: 6px 16px;
            font-size: 12px;

            &.allocated-services__add {
                border-color: #d01111;
                color: #d01111;
            }
        }
    }
`;

export const StyledBaseDetailsSubmitCtn = styled.div`
    position: fixed;
    top: 120px;
    right: 30px;
    z-index: 5;

    & > button {
        height: 40px;
        padding: 0 16px;
    }
`;

export const StyledApplicantsContent = styled(PageContent)``;

export const StyledPostalAddressContent = styled(PageContent)`
    position: relative;

    & > form {
        flex: 1 1 0;
        display: flex;
        gap: 24px;
        overflow-y: hidden;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
            overflow-y: initial;
        }
    }

    & .container {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
        ${mainScrollbar(12)};
        overflow: auto;

        &:nth-child(2) {
            min-height: 200px;
        }

        @media screen and (max-width: 1200px) {
            flex: 1;
            overflow: initial;
        }

        & > .header-container {
            flex: initial;
        }

        &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__create {
            padding-inline: 16px;
            height: 40px;
        }

        & .data-table {
            min-height: 0px;
        }
    }
`;

export const StyledLoaIdsContent = styled(PageContent)`
    & > form {
        display: grid;
        grid-template-columns: 1fr;
        width: min(100%, 900px);
        margin-inline: auto;
        gap: 24px;

        @media screen and (max-width: 1200px) {
            grid-template-columns: 1fr;
        }
    }

    & .header-container {
        &__ctn {
            display: flex;
            align-items: flex-end;
            gap: 16px;

            & .inputWrapper {
                flex: 1;
            }

            & .btns {
                display: flex;
                gap: 8px;

                & > button {
                    height: 31px;
                    padding-inline: 16px;
                    font-size: 12px;

                    &.hidden {
                        opacity: 0.3;
                        visibility: hidden;
                        pointer-events: none;
                    }
                }
            }
        }
    }
`;

export const StyledBankCardContent = styled(PageContent)`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
    align-items: flex-start;
    gap: 24px;

    & > form {
        & .header-container {
            &__ctn {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 12px 16px;

                & > :last-child {
                    grid-column: 1/-1;
                }
            }
        }
    }

    & .card-details {
        &__cards {
            align-items: flex-start;
            min-height: 134px;
            max-height: 260px;
        }
    }
`;

export const StyledMiscContent = styled(PageContent)`
    & > form {
        display: flex;
        flex-direction: column;
        width: min(100%, 1120px);
        margin-inline: auto;
        gap: 24px;
    }

    & .header-container {
        &__ctn {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 12px 24px;

            & > .inputWrapper:first-child {
                grid-column: 1/-1;

                & input {
                    color: ${theme.colors.error};
                    border-color: ${theme.colors.error};
                }
            }

            & textarea {
                &:read-only {
                    height: min-content;
                    font-size: 12px;
                    background-color: transparent;
                }
            }

            & .readonly-list {
                list-style: none;
                display: flex;
                flex-direction: column;
                font-size: 12px;
                font-weight: 300;
                gap: 4px;
            }
        }
    }
`;

export const StyledIdAmlContent = styled(PageContent)`
    & .containers {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
    }

    & .container {
        border-radius: 10px;
        background-color: ${theme.colors.white};
        padding: 24px;
        display: flex;
        flex-direction: column;
        width: 340px;
        & h2 {
            font-size: 14px;
        }

        & ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin-top: 12px;
        }

        & li {
            display: flex;
            justify-content: space-between;
            gap: 12px;
            font-size: 12px;
            font-weight: 300;
        }

        & .circular-meter {
            flex: 1;
            aspect-ratio: initial;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    & .table-container {
        display: flex;
        flex-direction: column;
        margin-top: 32px;

        & > h2 {
            font-size: 14px;
            text-decoration: underline;
            margin-bottom: 16px;
        }

        & .data-table {
            &__wrapper {
                flex: initial;
                max-height: 240px;
            }
        }
    }
`;

export default StyledBaseDetailsLandingContent;
