import { useState } from "react";
import { useParams, Link } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import Header from "../../../layout/Header";
import StyledProductsLandingContent from "./styles";
import { Button, Modal } from "../../../components";
import SendRebateBreakdown from "../../../components/Modals/ClientProducts/SendRebateBreakdown";
import SendClientRebateForm from "../../../components/Modals/ClientProducts/SendClientRebateForm";
import SendHMRCSignedForm from "../../../components/Modals/ClientProducts/SendHMRCSignedForm";
import DetailCard from "../../../components/Cards/DetailCard";

const ProductsLanding = () => {
    const [currentModal, setCurrentModal] = useState("");
    const { clientId } = useParams();
    const getProductsDashboard = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/products/${clientId}`,
        },
        {
            fetchOnLoad: true,
        }
    );
    const data = getProductsDashboard?.data?.data || {};
    const pbsIeData = data?.psb_ie || {};
    const personalAccountancyData = data?.personal_accountancy?.[0] || {};
    const businessAccountancyData = data?.business_accountancy?.[0] || {};

    return (
        <>
            <Modal isVisible={currentModal === "rebate-breakdown"}>
                <SendRebateBreakdown
                    isOpen={currentModal === "rebate-breakdown"}
                    onClose={() => setCurrentModal("")}
                />
            </Modal>
            <Modal isVisible={currentModal === "client-rebate"}>
                <SendClientRebateForm
                    isOpen={currentModal === "client-rebate"}
                    onClose={() => setCurrentModal("")}
                />
            </Modal>
            <Modal isVisible={currentModal === "hmrc"}>
                <SendHMRCSignedForm
                    isOpen={currentModal === "hmrc"}
                    onClose={() => setCurrentModal("")}
                />
            </Modal>
            <Header title="Products" hasNavBarAbove />
            <StyledProductsLandingContent>
                <div className="pbs-ie">
                    <div className="row">
                        <h2>This Month's PBS IE:</h2>

                        <Link to="personal-budgeting-ie">View Details</Link>
                    </div>
                    <div className="cards">
                        <DetailCard
                            countUnit="£"
                            count={pbsIeData.income || 0}
                            title="Income"
                        />
                        <DetailCard
                            countUnit="£"
                            count={pbsIeData?.expense || 0}
                            title="Expenditure"
                        />
                        <DetailCard
                            countUnit="£"
                            count={pbsIeData?.di || 0}
                            title="Distribution Income (DI)"
                        />
                    </div>
                </div>
                <div className="container container--pa">
                    <div className="row">
                        <h2>Personal Accountancy</h2>
                        <Link to="personal-accountancy">View Details</Link>
                    </div>
                    <ul className="data-list">
                        <li>
                            Trading Name:
                            <span>
                                {personalAccountancyData?.trading_name || ""}
                            </span>
                        </li>
                        <li>
                            National Insurance Number:
                            <span>{personalAccountancyData?.ni || ""}</span>
                        </li>
                        <li>
                            UTR Number:
                            <span>{personalAccountancyData?.utr || ""}</span>
                        </li>
                        <li>
                            Trading Address:
                            <span>
                                {personalAccountancyData?.trading_address || ""}
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="container container--ba">
                    <div className="row">
                        <h2>Business Accountancy</h2>
                        <Link to="business-accountancy">View Details</Link>
                    </div>
                    <ul className="data-list">
                        <li>
                            Company Number:
                            <span>
                                {businessAccountancyData?.company_number || ""}
                            </span>
                        </li>
                        <li>
                            Name:
                            <span>
                                {businessAccountancyData?.company_name || ""}
                            </span>
                        </li>
                        <li>
                            Address:
                            <span>
                                {businessAccountancyData?.address || ""}
                            </span>
                        </li>
                        <li>
                            Authentication Code:
                            <span>
                                {businessAccountancyData?.authentication_code ||
                                    ""}
                            </span>
                        </li>
                        <li>
                            UTR Number:
                            <span>{businessAccountancyData?.utr || ""}</span>
                        </li>
                    </ul>
                </div>
                <div className="container container--paye">
                    <div className="row">
                        <h2>PAYE Rebate</h2>
                    </div>
                    <div className="btns">
                        <Button
                            type="button"
                            style="purple"
                            btnText="Send Rebate Breakdown"
                            onClick={() => setCurrentModal("rebate-breakdown")}
                        />
                        <Button
                            type="button"
                            style="white"
                            btnText="Send Client Rebate Form"
                            onClick={() => setCurrentModal("client-rebate")}
                        />
                        <Button
                            type="button"
                            style="white"
                            btnText="Send HMRC Signed Form"
                            onClick={() => setCurrentModal("hmrc")}
                        />
                    </div>
                </div>
            </StyledProductsLandingContent>
        </>
    );
};

export default ProductsLanding;
