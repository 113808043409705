import { useParams } from "react-router-dom";
import BasicModal from "../BasicModal";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxClientProducts from "../../../store/hooks/useReduxClientProducts";

interface DeletePersonalBudgetingModalProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    id: number;
}

const DeletePersonalBudgeting: React.FC<DeletePersonalBudgetingModalProps> = ({
    isOpen,
    onClose,
    id,
}) => {
    const { clientId } = useParams();
    const { setPersonalBudgeting } = useReduxClientProducts();
    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_API}/pbs/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setPersonalBudgeting(data.data);
            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData();
    };
    return (
        <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            message={`Are you sure you want to delete this entry from the list?`}
            submitBtnColor="red"
            submitBtnText="Delete"
            successMessage={`Enrty has been deleted.`}
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSubmit={submitHandler}
        />
    );
};

export default DeletePersonalBudgeting;
