import Product from "../Products/Product";
import { ClientFormStepProps } from "../ClientForm";
import useFetchData from "../../../../../global/hooks/useFetchData";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import productsData from "../Products/data";
import StyledClientSummary from "./styles";
import ClientSummaryCtn from "./ClientSummaryCtn";
import ClientFormStepButtons from "../ClientFormStepButtons";
import { Modal } from "../../../../../components";
import ClientFinish from "../../../../../components/Modals/ClientCreate/ClientFinish";

const Summary: React.FC<ClientFormStepProps> = ({
    setCurrentStep,
    isFirstStep,
    isLastStep,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { clientId } = useParams();
    const getSummary = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/end-summary/${clientId}`,
        },
        { fetchOnLoad: true }
    );
    const summaryData = getSummary?.data?.data?.summary || {};
    const selectedProduct =
        productsData.find((d) => d.id === summaryData?.products) || null;
    console.log(selectedProduct);

    const applicantKeyLabels = {
        firstname: "First Name",
        maid_name: "Maiden Name",
        lastname: "Last Name",
        dob: "Date of Birth",
        hme_phone: "Home Number",
        mobile: "Mobile Number",
        email: "Email Address",
        employment_status: "Employment Status",
        martial_status: "Martial Status",
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

    return (
        <>
            <Modal isVisible={isModalOpen}>
                <ClientFinish open={isModalOpen} setIsOpen={setIsModalOpen} />
            </Modal>
            <StyledClientSummary
                className="client-summary"
                onSubmit={submitHandler}
            >
                <section className="client-summary__info">
                    <div className="client-summary__row">
                        <ClientSummaryCtn
                            title="Applicant 1"
                            labels={Object.values(applicantKeyLabels)}
                            keys={Object.keys(applicantKeyLabels)}
                            data={summaryData?.applicant1}
                        />
                        <ClientSummaryCtn
                            title="Applicant 2"
                            labels={Object.values(applicantKeyLabels)}
                            keys={Object.keys(applicantKeyLabels)}
                            data={summaryData?.applicant2}
                        />
                    </div>
                    <div className="client-summary__row"></div>
                </section>
                <div className="client-summary__product">
                    {selectedProduct && (
                        <Product
                            title={selectedProduct.title}
                            subTitle={selectedProduct.subTitle}
                            benefits={selectedProduct.benefits}
                            active
                        />
                    )}
                </div>
                <ClientFormStepButtons
                    onPrevClick={() => setCurrentStep((prev) => prev - 1)}
                    isFirstStep={isFirstStep}
                    isLastStep={isLastStep}
                    isSubmitting={false}
                />
            </StyledClientSummary>
        </>
    );
};

export default Summary;
