import styled from "styled-components";
import { theme } from "../../../global/theme";

const StyledSummaryCard = styled.div`
    background-color: ${theme.colors.white};
    border-radius: 10px;
    padding: 16px;
    .summary-card {
        &__title {
            font-size: 14px;
            margin-bottom: 16px;
        }

        &__list {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        &__item {
            font-size: 10px;
            font-weight: 300;
            display: grid;
            grid-template-columns: 1fr max-content;
        }

        &__value {
            max-width: 200px;
            justify-self: flex-end;
            text-align: right;
        }
    }
`;

export default StyledSummaryCard;
