import { useAppSelector, useAppDispatch } from ".";
import { clientProductsActions } from "../slices/clientProductsSlice";

const useReduxClientProducts = () => {
    const { personalBudgeting } = useAppSelector(
        (state) => state["client-products"]
    );
    const dispatch = useAppDispatch();

    const setPersonalBudgeting = (data: Array<{ [key: string]: any }>) => {
        dispatch(clientProductsActions.setPersonalBudgeting(data));
    };

    const resetSlice = () => {
        dispatch(clientProductsActions.resetSlice());
    };

    return {
        personalBudgeting,
        setPersonalBudgeting,
        resetSlice,
    };
};

export default useReduxClientProducts;
