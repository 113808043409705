import styled from "styled-components";
import { theme } from "../../../../global/theme";
import { Column } from "../../../../global/theme/reuseable/components";
import {
    centeredRowMixin,
    mainScrollbar,
} from "../../../../global/theme/reuseable/mixins";
import { InputWrapper } from "../../../Input/styles";
import { ResizableModalWrapper } from "../../styles";
import { iSCActiveInput } from "./types";

export const CreateCreditorModalWrapper = styled(ResizableModalWrapper)`
    padding: 20px;
    gap: 16px;
`;

// export const FormNavWrapper = styled.nav`
//     margin-block: 22px;

//     ul {
//         ${centeredRowMixin};
//         justify-content: center;
//         gap: 20px;
//     }
// `;
export const StepOneWrapper = styled.div`
    & .row-1 {
        & > * {
            flex: 1;
        }
    }
`;

export const ContactGrid = styled.div`
    display: flex;
    flex-direction: column;
    margin-block: 20px;
    & > p {
        text-align: left;
        font-size: 12px;
        width: 140px;
        margin-bottom: 8px;
    }
    span {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 25px;
        row-gap: 13px;
    }
`;

export const DayWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 13px;
    > div:first-of-type {
        // Active Input
        margin-right: 15px;
    }

    .dayName {
        display: flex;
        gap: 17px;
        width: 150px;
        justify-content: flex-start;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #5e5e5e;
        gap: 9px;
    }

    input[type="checkbox"] {
        margin-right: 15px;
        height: 20px;
        width: 20px;
    }
`;
export const ActiveInput = styled(InputWrapper)<iSCActiveInput>`
    flex-grow: 3;
    input {
        ${(props) =>
            props.active ? "" : `background: ${theme?.colors.background}`}
        width: 100%;
    }
`;

export const StepTwoWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
    row-gap: 17px;
    input {
        width: 100%;
    }
`;

export const StepThreeWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    & > :last-child {
        grid-column: 1/-1;
    }

    label {
        margin-top: 17px;
    }
    textarea {
        height: 142px;
    }
`;

export const SuccessWrapper = styled(Column)`
    align-items: center;
    padding-top: 22px;
    p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #5e5e5e;
    }

    button {
        width: 194px;
        height: 40px;
    }
`;
