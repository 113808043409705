import styled from "styled-components";
import { ResizableModalWrapper } from "../../styles";

const StyledMessageTemplateModal = styled(ResizableModalWrapper)`
    & > form {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr;
        gap: 12px 16px;

        & .text-editor {
            grid-column: 1/-1;

            &__textarea {
                height: 300px;
            }
        }

        & .modal-btns {
            grid-column: 1/-1;
        }

        & .se-container {
            height: 320px;
        }
    }

    &.message-template-modal {
        & > form {
            & .subject-input {
                grid-column: 1/-1;
            }
        }
    }
`;

export default StyledMessageTemplateModal;
