import styled from "styled-components";
import { theme } from "../../../../../global/theme";

const StyledClientSummary = styled.form`
    display: flex;
    gap: 32px;
    width: min(100%, 1600px);
    margin-inline: auto;
    & .client-summary {
        &__info {
            flex: 2;
            border-radius: 16px;
            background-color: ${theme?.colors.white};
            padding: 24px;
        }

        &__product {
            flex: 1;
        }

        &__row {
            display: flex;
            justify-content: space-between;

            &:not(:first-of-type) {
                margin-top: 16px;
                border-top: 0.6px solid ${theme?.colors.boxStroke};
            }
        }
    }
`;

export const StyledClientSummaryCtn = styled.div`
    & .client-summary-ctn {
        &__title {
            font-size: 12px;
            text-decoration: underline;
        }

        &__list {
            list-style: none;
            margin-top: 8px;
        }

        &__item {
            display: flex;
            font-size: 10px;
            font-weight: 300;

            & > * {
                line-height: 15px;
            }

            & > h4 {
                width: 160px;
                font-weight: 300;
            }
        }
    }
`;

export default StyledClientSummary;
