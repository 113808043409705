import React, { useState } from "react";
import Header from "../../../layout/Header";
import { StyledMiscContent } from "./styles";
import HeaderContainer from "../../../containers/HeaderContainter";
import { Input, TextArea, Button } from "../../../components";
import { StyledBaseDetailsSubmitCtn } from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";
import { useParams } from "react-router-dom";

const Misc = () => {
    const [misc, setMisc] = useState<{ [keu: string]: any }>({
        alrt: "",
        rsn_dffclts: "",
        rsn_dmp: "",
        slsld_dtls: "",
        nts: "",
        ivants: "",
        bckbk_nts: "",
    });
    const { clientId } = useParams();
    const getMisc = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/misc/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setMisc(data.data);
            },
        }
    );

    const updateMisc = useFetchData(
        {
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/misc/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setMisc(data.data);
            },
        }
    );

    const inputHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value, name } = e.target;
        setMisc((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        updateMisc.fetchData({ data: misc });
    };

    return (
        <>
            <Header title="Misc (Miscellaneous)" hasNavBarAbove hasPrevPath />
            <StyledMiscContent>
                <form onSubmit={submitHandler}>
                    <HeaderContainer
                        title="Miscellaneous:"
                        headerStyle="gradientBlue"
                    >
                        <Input
                            label="Case Alert (Red):"
                            type="text"
                            value={misc?.alrt || ""}
                            name="alrt"
                            onChange={inputHandler}
                        />
                        <TextArea
                            label="Change in Circumstances: (Reason Of Difficulties)"
                            value={misc?.rsn_dffclts || ""}
                            name="rsn_dffclts"
                            onChange={inputHandler}
                        />
                        <TextArea
                            label="Reason Of Plan:"
                            value={misc?.rsn_dmp || ""}
                            name="rsn_dmp"
                            onChange={inputHandler}
                        />
                        <TextArea
                            label="Financial Goals:"
                            value={misc?.slsld_dtls || ""}
                            name="slsld_dtls"
                            onChange={inputHandler}
                        />
                        <TextArea
                            label="Other Notes:"
                            value={misc?.nts || ""}
                            name="nts"
                            onChange={inputHandler}
                        />
                        <TextArea
                            label="IVA Notes:"
                            value={misc?.ivants || ""}
                            name="ivants"
                            onChange={inputHandler}
                        />
                        <TextArea
                            label="Back Book Notes:"
                            value={misc?.bckbk_nts || ""}
                            name="bckbk_nts"
                            onChange={inputHandler}
                        />
                    </HeaderContainer>
                    <HeaderContainer
                        title="Request Cancellation Information:"
                        headerStyle="gradientBlue"
                    >
                        <TextArea
                            label="Reason for Cancellation:"
                            value={""}
                            name="reason-for-cancellation"
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="is the client going to another company:"
                            value={""}
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="Reason for Cancellation:"
                            value={""}
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="Was Refund Issued?"
                            value={""}
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="Is there a cancellation fee?"
                            value={""}
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="Additional information:"
                            value={""}
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="Feedback from client:"
                            value={""}
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="Agent who cancelled:"
                            value={""}
                            readOnly
                            disabled
                        />
                    </HeaderContainer>
                    <HeaderContainer
                        title="Confirmed Cancellation Information:"
                        headerStyle="gradientBlue"
                    >
                        <TextArea
                            label="Has the cancellation been accepted?"
                            value={""}
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="Was there a refund issued as part of the cancellation?"
                            value={""}
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="If so how much how was refunded?"
                            value={""}
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="Cancellation fee due:"
                            value={""}
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="Additional information:"
                            value={""}
                            readOnly
                            disabled
                        />
                        <TextArea
                            label="Management Who Authorized Cancellation:"
                            value={""}
                            readOnly
                            disabled
                        />
                    </HeaderContainer>
                    <StyledBaseDetailsSubmitCtn>
                        <Button
                            type="submit"
                            btnText="Save Miscellaneous"
                            style="purple"
                            loading={updateMisc.isLoading}
                        />
                    </StyledBaseDetailsSubmitCtn>
                </form>
            </StyledMiscContent>
        </>
    );
};

export default Misc;
