import React, { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "../../components";
import useReduxUser from "../../store/hooks/useReduxUser";
import { Form, Logo, OptionsCtn, RememberMeCtn, SignInBtn } from "./styles";
import useFetchData from "../../global/hooks/useFetchData";
import {
    setSecureLocalStorage,
    getSecureLocalStorage,
} from "../../global/utils/helpers";

export interface iLoginDetails {
    email: string;
    password: string;
}
const Login: React.FC = () => {
    const storedLoginData = getSecureLocalStorage("ClearStartLogin");
    const [loginDetails, setLoginDetails] = useState<iLoginDetails>({
        email: storedLoginData?.email || "",
        password: storedLoginData?.password || "",
    });
    const [rememberUser, setRememberUser] = useState(!!storedLoginData);
    const { setIsSignedIn, setUser } = useReduxUser();
    const loginUser = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/login`,
        },
        {
            requiresToken: false,
            navigateOnSuccess: "/",
            onSuccess: (data) => {
                localStorage.setItem("ClearStartAccessToken", data?.token);
                setIsSignedIn(true);
                setUser(data?.user);
            },
        }
    );

    const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRememberUser(e.target.checked);
    };

    const inputHandler = (
        e: ChangeEvent<HTMLInputElement>,
        key: keyof iLoginDetails
    ) => {
        setLoginDetails((prev) => {
            return { ...prev, [key]: e.target.value };
        });
    };

    const handleLogin = (e: React.FormEvent) => {
        e.preventDefault();
        loginUser.fetchData(loginDetails);
        if (rememberUser) {
            setSecureLocalStorage(
                "ClearStartLogin",
                JSON.stringify(loginDetails)
            );
        } else {
            localStorage.removeItem("ClearStartLogin");
        }
    };

    return (
        <Form onSubmit={handleLogin}>
            <Logo>Clear Start</Logo>
            <Input
                placeholder="youremail@cs.com"
                type="email"
                label="Email"
                name="email"
                value={loginDetails?.email}
                onChange={(e) => inputHandler(e, "email")}
                className="loginInput"
                autoComplete="email"
            />
            <Input
                placeholder="***********"
                type="password"
                label="Password"
                name="password"
                value={loginDetails?.password}
                onChange={(e) => inputHandler(e, "password")}
                className="loginInput"
                autoComplete="current-password"
            />
            <SignInBtn type="submit">Sign In</SignInBtn>
            <OptionsCtn>
                <RememberMeCtn>
                    <input
                        type="checkbox"
                        onChange={checkboxHandler}
                        checked={rememberUser}
                    />
                    <p>Remember me</p>
                </RememberMeCtn>
                <Link className="forgotPword" to="forgot">
                    Forgot Password?
                </Link>
            </OptionsCtn>
        </Form>
    );
};

export default Login;
