import { useState } from "react";
import { useParams } from "react-router-dom";

import ModalHeader from "../../components/ModalHeader";
import { StyledChangeStatus } from "./styles";
import DropdownSelect from "../../../DropdownSelect";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import useReduxUser from "../../../../store/hooks/useReduxUser";
import { clientCaseStatusOptions } from "../../../DropdownSelect/options";
import Input from "../../../Input";
import { CaseActionsModalProps } from "./types";

const ChangeStatus: React.FC<CaseActionsModalProps> = ({ isOpen, onClose }) => {
    const { clientId } = useParams();
    const { clientCase, setClientCaseByKey } = useReduxUser();
    const [statusId, setStatusId] = useState<number | null>(null);
    const [nextPaymentDate, setNextPaymentDate] = useState<string>("");

    const { data } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/update-status/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) {
                    setStatusId(data.data.status_id);
                    setNextPaymentDate(data.data.next_pmnt_in_dt);
                }
            },
        }
    );
    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/update-status/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) {
                    setClientCaseByKey("details", data?.data?.column1[0]);
                    setClientCaseByKey("payments", data?.data?.column2[0]);
                    setClientCaseByKey("services", data?.data?.column3[0]);
                }
            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({
            status_id: statusId,
            next_payment_date: nextPaymentDate,
        });
    };

    return (
        <StyledChangeStatus show={isOpen} width="360px" isSuccess={isSuccess}>
            <ModalHeader onClose={onClose}>Change Status</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message={`${clientCase?.services?.[0]?.service} has been created`}
                    onClose={onClose}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <DropdownSelect
                        label="Status:"
                        options={clientCaseStatusOptions}
                        value={statusId}
                        onSelect={(option) => setStatusId(option.value)}
                        enableInput
                    />
                    <Input
                        label="Next Payment Date"
                        type="date"
                        value={nextPaymentDate}
                        onChange={(e) => setNextPaymentDate(e.target.value)}
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Save"
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledChangeStatus>
    );
};

export default ChangeStatus;
