import { AuthorisationRateWrapper } from "./styles";

interface AuthorisationRateProps {
    value: number;
}
const AuthorisationRate: React.FC<AuthorisationRateProps> = ({ value }) => {
    // Will be from store once I know how I'll be receiving this data from api
    return (
        <AuthorisationRateWrapper>
            <h5>Authorisation Rate</h5>
            <span>
                <p>{value}%</p>
            </span>
        </AuthorisationRateWrapper>
    );
};

export default AuthorisationRate;
