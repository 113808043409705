import { ChangeEvent } from "react";
import { CreditorFormProps } from ".";
import { StyledCreditorDetails } from "./styles";
import { Input, TextArea } from "../../../components";
import DropdownSelect from "../../../components/DropdownSelect";
import {
    preferCorMethodOptions,
    requireWetLOAOptions,
} from "../../../components/DropdownSelect/options";

const CreditorDetails: React.FC<CreditorFormProps> = ({
    creditorData,
    setCreditorData,
}) => {
    const inputHandler = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setCreditorData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const selectHandler = (key: string, value: string | number) => {
        setCreditorData((prev) => {
            return { ...prev, [key]: value };
        });
    };
    return (
        <StyledCreditorDetails className="creditor-details">
            <div className="creditor-details__ctn">
                <h3 className="creditor-details__title">Basic Information</h3>
                <div className="creditor-settings__fields">
                    <Input
                        className="creditor-settings__input"
                        placeholder="Enter company name..."
                        type="text"
                        label="Company Name:"
                        name="Creditor Name"
                        value={
                            creditorData ? creditorData["Creditor Name"] : ""
                        }
                        onChange={inputHandler}
                    ></Input>
                    <TextArea
                        className="creditor-settings__textarea"
                        placeholder="Enter address..."
                        label="Address:"
                        name="Address"
                        value={creditorData ? creditorData["Address"] : ""}
                        onChange={inputHandler}
                    ></TextArea>
                </div>
            </div>
            <div className="creditor-details__ctn">
                <h3 className="creditor-settings__title">
                    Contact Information
                </h3>
                <div className="creditor-settings__fields">
                    <Input
                        className="creditor-settings__input"
                        placeholder="Enter number..."
                        type="tel"
                        label="Telephone 1:"
                        name="Phone Number"
                        value={creditorData ? creditorData["Phone Number"] : ""}
                        onChange={inputHandler}
                    ></Input>
                    <Input
                        className="creditor-settings__input"
                        placeholder="Enter number..."
                        type="tel"
                        label="Telephone 2:"
                        name="tlphne2"
                        value={creditorData ? creditorData["tlphne2"] : ""}
                        onChange={inputHandler}
                        autoComplete="tel-2"
                    ></Input>
                    <Input
                        className="creditor-settings__input"
                        placeholder="Enter email..."
                        type="email"
                        label="Email address 1:"
                        name="email1"
                        value={creditorData ? creditorData["email1"] : ""}
                        onChange={inputHandler}
                    ></Input>
                    <Input
                        className="creditor-settings__input"
                        placeholder="Enter email..."
                        type="email"
                        label="Email address 2:"
                        name="email2"
                        value={creditorData ? creditorData["email2"] : ""}
                        onChange={inputHandler}
                        autoComplete="email-2"
                    ></Input>
                    <Input
                        className="creditor-settings__input"
                        placeholder="Enter email..."
                        type="email"
                        label="Email address 3:"
                        name="email3"
                        value={creditorData ? creditorData["email3"] : ""}
                        onChange={inputHandler}
                        autoComplete="email-3"
                    ></Input>
                    <Input
                        className="creditor-settings__input"
                        placeholder="Enter tax number..."
                        type="tel"
                        label="Fax:"
                        name="fx"
                        value={creditorData ? creditorData["fx"] : ""}
                        onChange={inputHandler}
                    ></Input>
                    <DropdownSelect
                        className="creditor-settings__dropdown"
                        label="Prefered Contact Method:"
                        value={
                            creditorData
                                ? creditorData["prfrrd_crrspndnce_mthd"]
                                : ""
                        }
                        options={preferCorMethodOptions}
                        onSelect={(option) =>
                            selectHandler("prefer_cor_id", option.value)
                        }
                    />
                    <DropdownSelect
                        className="creditor-settings__dropdown"
                        label="Required WET LOA:"
                        value={creditorData ? creditorData["loamthd"] : ""}
                        options={requireWetLOAOptions}
                        onSelect={(option) =>
                            selectHandler("wet_loa_id", option.value)
                        }
                    />
                </div>
            </div>
        </StyledCreditorDetails>
    );
};

export default CreditorDetails;
