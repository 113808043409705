import { SetStateAction } from "react";
import { useParams } from "react-router-dom";
import { StyledPermissionCheckboxes } from "./styles";
import useReduxAccounts from "../../store/hooks/useReduxAccounts";
import CheckboxGroup from ".";
import LineBreak from "../../atoms/LineBreak";
interface PermissionsCheckBoxesProps {
    permissionIds: Array<number>;
    setPermissionIds?: React.Dispatch<SetStateAction<Array<number>>>;
    readonly?: boolean;
}

const PermissionCheckBoxes: React.FC<PermissionsCheckBoxesProps> = ({
    permissionIds,
    setPermissionIds,
    readonly = false,
}) => {
    const { permissions } = useReduxAccounts();
    const { userId } = useParams();
    return (
        <StyledPermissionCheckboxes
            className="permission-checkboxes"
            aria-readonly={readonly}
        >
            {Object.entries(permissions || {}).map(([key, value], i) => (
                <div className="permission-checkboxes__group" key={key}>
                    <LineBreak title={key} width="100%" />
                    <div className="permission-checkboxes__ctn">
                        {Object.entries(value).map(
                            ([vKey, v], index: number) => (
                                <CheckboxGroup
                                    key={index}
                                    selectedValues={permissionIds}
                                    setSelectedValues={
                                        readonly ? undefined : setPermissionIds
                                    }
                                    legend={vKey}
                                    labels={v.map((obj) => obj?.display_title)}
                                    values={v.map((obj) => obj?.id)}
                                    disabled={v.map((obj) => !!obj?.disabled)}
                                    readOnly={readonly}
                                />
                            )
                        )}
                    </div>
                </div>
            ))}
        </StyledPermissionCheckboxes>
    );
};

export default PermissionCheckBoxes;
