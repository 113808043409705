import React, { useEffect, useState } from "react";
import { ClientFormStepProps } from "../ClientForm";
import AddressCtn from "./AddressCtn";
import StyledAddress from "./styles";
import useReduxCreateClient from "../../../../../store/hooks/useReduxCreateClient";
import ClientFormStepButtons from "../ClientFormStepButtons";
import useFetchData from "../../../../../global/hooks/useFetchData";
import { useParams } from "react-router-dom";

interface AddressObject {
    id: number;
    case_id: number;
    door: string;
    postcode: string;
    full: string;
    type: 1 | 2;
}

const initialAddresses: AddressObject[] = [
    {
        id: 123,
        case_id: 512,
        door: "",
        postcode: "",
        full: "",
        type: 1,
    },
    {
        id: 123,
        case_id: 512,
        door: "",
        postcode: "",
        full: "",
        type: 2,
    },
];

const Address: React.FC<ClientFormStepProps> = ({
    setCurrentStep,
    isFirstStep,
    isLastStep,
    currentStep,
}) => {
    const { setDataByKey, address } = useReduxCreateClient();
    const { clientId } = useParams();
    const [address1, setAddress1] = useState<{ [key: string]: any }>({});
    const [address2, setAddress2] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        if (address?.[0]) setAddress1(address[0]);
        if (address?.[1]) setAddress2(address[1]);
    }, [address]);

    const { fetchData, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client`,
        },
        {
            onSuccess: (data) => {
                setCurrentStep((prev) => prev + 1);
                setDataByKey("address", data?.data?.address);
            },
            notifyOnSuccess: true,
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (clientId) {
            const data = {
                id: +clientId,
                tab: "address",
                data: { address: [address1, address2] },
            };
            fetchData(data);
        }
    };

    return (
        <StyledAddress
            className="client-form__content"
            onSubmit={submitHandler}
        >
            <AddressCtn setAddress={setAddress1} address={address1} />
            <AddressCtn
                setAddress={setAddress2}
                address={address2}
                title="Previous Address"
            />
            <ClientFormStepButtons
                onPrevClick={() => setCurrentStep((prev) => prev - 1)}
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                isSubmitting={isLoading}
            />
        </StyledAddress>
    );
};

export default Address;
