import styled from "styled-components";
import DropdownMenu from "../../../components/DropdownMenu";
import { TablePageContent } from "../../styles";
import Header from "../../../layout/Header";
import { theme } from "../../../global/theme";
import { mainScrollbar } from "../../../global/theme/reuseable/mixins";

export const StyledLiabilityActions = styled(DropdownMenu)`
    & .dropdown-menu {
        &__menu {
            max-height: calc(100vh - 140px);
        }
    }
`;

export const BalanceSheetHeader = styled(Header)`
    & .header {
        &__content {
            justify-content: space-between;
            gap: 8px;

            & > .creditor-rating {
                border: none;
                padding: 8px 12px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                gap: 8px;
                color: ${theme.colors.white};
                & svg {
                    height: 18px;
                    width: 18px;
                }
            }
        }
    }
    & .right-btns {
        display: flex;
        gap: 8px;

        & > * {
            height: 40px;
            padding-inline: 16px;
        }
    }
`;

const StyledBalanceSheetContent = styled(TablePageContent)`
    flex: 1 1 0;
    overflow: auto;
    ${mainScrollbar(14)};

    & .search-row {
        justify-content: space-between;
        &__content {
            & .switches {
                display: flex;
                align-items: center;
                gap: 8px;

                & > p {
                    font-size: 14px;
                }
            }
        }

        & .button {
            padding-inline: 16px;
        }
    }
    & .notes {
        display: flex;
        flex-direction: column;
        max-width: 1200px;

        & > h2 {
            font-size: 16px;
            margin-bottom: 16px;
            margin-top: 24px;
        }
    }

    & .data-table {
        flex: initial;

        &__wrapper {
            flex: initial;
        }
    }

    & .swtiches {
        display: flex;
        flex-direction: row;
    }
`;

export default StyledBalanceSheetContent;
