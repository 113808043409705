import { useEffect, useMemo, useState } from "react";
import { FixedSizeList as List } from "react-window";

import NotificationCard from "../../../components/Cards/NotificationCard";
import { BlackBackScreen } from "../../../global/theme/reuseable/components";
import { NotificationMenuWrapper } from "./styles";
import useReduxUI from "../../../store/hooks/useReduxUI";
import ModalHeader from "../../../components/Modals/components/ModalHeader";
import { ReactComponent as BellIcon } from "../../../assets/icons/bell.svg";
import PageTabs from "../../../components/PageTabs/PageTabs";
import useWindowSize from "../../../global/hooks/useWindowSize";
import NotificationDiary from "./NotificationDiary";

interface Props {
    open: boolean;
}
const tabs = [
    "All",
    "Portal Uploads",
    "Client Emails",
    "Client SMS",
    "Diaries",
    "System Alerts",
];

const Notifications = ({ open }: Props) => {
    const { setSideMenu, notifications } = useReduxUI();
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const { height } = useWindowSize();
    const notificationCounts: number[] = Object.values(
        notifications?.count || []
    );
    const totalCount = notificationCounts.reduce((a, c) => a + c, 0) || 0;
    const portalUploadCount = notifications?.count?.["client-count"] || 0;
    const emailCount = notifications?.count?.["email-count"] || 0;
    const smsCount = notifications?.count?.["sms-count"] || 0;
    const alertCount = notifications?.count?.["task-count"] || 0;
    const tabCounts = [
        totalCount,
        portalUploadCount,
        emailCount,
        smsCount,
        undefined,
        alertCount,
    ];

    const portalData = useMemo(
        () => notifications?.notification?.client || [],
        [notifications]
    );
    const emailData = useMemo(
        () => notifications?.notification?.email || [],
        [notifications]
    );
    const smsData = useMemo(
        () => notifications?.notification?.sms || [],
        [notifications]
    );
    const alertData = useMemo(
        () => notifications?.notification?.tasks?.data || [],
        [notifications]
    );
    const allData = useMemo(
        () => [...portalData, ...emailData, ...smsData, ...alertData],
        [portalData, emailData, smsData, alertData]
    );
    const alertProcessCount = notifications?.notification?.tasks?.process || 0;
    const alertNewCount = notifications?.notification?.tasks?.new || 0;

    const tabIndexData = [
        allData,
        portalData,
        emailData,
        smsData,
        [],
        alertData,
    ];
    const currentData = tabIndexData[currentTabIndex] as {
        [key: string]: any;
    }[];

    const cardMessage = (data: { [key: string]: any }): string => {
        if (currentTabIndex === 1) return "upload";
        if (currentTabIndex === 5) return data?.smmry || "";
        return data?.email_subject || "";
    };

    return (
        <>
            <BlackBackScreen open={open}></BlackBackScreen>
            <NotificationMenuWrapper open={open}>
                <ModalHeader onClose={() => setSideMenu("")}>
                    <BellIcon />
                    Notifications
                </ModalHeader>
                <PageTabs
                    notifications={tabCounts}
                    tabs={tabs}
                    selectedTabIndex={currentTabIndex}
                    setSelectedTabIndex={setCurrentTabIndex}
                />
                {currentTabIndex === 5 && (
                    <ul className="notification__alert-count">
                        <li>In Process: {alertProcessCount}</li>
                        <li>New: {alertNewCount}</li>
                    </ul>
                )}
                {open ? (
                    <div className="notification__content">
                        <NotificationDiary isOpen={currentTabIndex === 4} />
                        {currentTabIndex !== 4 && (
                            <div className="notification__cards">
                                {currentData?.length ? (
                                    <List
                                        key={currentTabIndex}
                                        height={
                                            height -
                                            (currentTabIndex === 5 ? 290 : 250)
                                        }
                                        itemCount={tabCounts[currentTabIndex]}
                                        width="100%"
                                        itemSize={130}
                                        initialScrollOffset={0}
                                    >
                                        {({ index, style }) => {
                                            const data = currentData[index];
                                            return (
                                                <div style={style}>
                                                    <NotificationCard
                                                        key={data?.id ?? index}
                                                        title={
                                                            data?.name ||
                                                            data?.client
                                                        }
                                                        message={cardMessage(
                                                            data
                                                        )}
                                                        time={
                                                            data?.time ||
                                                            data?.whn
                                                        }
                                                        remark={data?.whm}
                                                        caseId={
                                                            data?.case_id ||
                                                            data?.["CaseID"]
                                                        }
                                                    />
                                                </div>
                                            );
                                        }}
                                    </List>
                                ) : (
                                    <p className="no-result">
                                        No results found.
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                ) : null}
            </NotificationMenuWrapper>
        </>
    );
};

export default Notifications;
