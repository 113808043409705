import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface DashboardState {
   leftColumnData?: {[key: string]: any}[];
   rightColumnData?: {[key: string]: any} | null;
   
}

const initialDashboardState: DashboardState = {
    leftColumnData: [],
    rightColumnData: null,
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialDashboardState,
    reducers: {
        setLeftColumnData: (state, action: PayloadAction<{[key: string]: any}[]>) => {
            state.leftColumnData = action.payload
        },
        setRightColumnData: (state, action: PayloadAction<{[key: string]: any} | null>) => {
            state.rightColumnData = action.payload
        },
    }
})

export const dashboardActions = dashboardSlice.actions
export default dashboardSlice