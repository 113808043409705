import React, { useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import useFetchData from "../../global/hooks/useFetchData";
import NavBar from "../../layout/Header/NavBar";
import BaseDetailsLanding from "../../subPages/ClientCase/BaseDetails";
import Applicants from "../../subPages/ClientCase/BaseDetails/Applicants";
import PostalAddress from "../../subPages/ClientCase/BaseDetails/PostalAddress";
import AgreementDetails from "../../subPages/ClientCase/BaseDetails/AgreementDetails";
import KeyEvents from "../../subPages/ClientCase/BaseDetails/KeyEvents";
import LoaIds from "../../subPages/ClientCase/BaseDetails/LoaIds";
import BankCard from "../../subPages/ClientCase/BaseDetails/BankCard";
import Misc from "../../subPages/ClientCase/BaseDetails/Misc";
import IdAml from "../../subPages/ClientCase/BaseDetails/IdAml";

const BaseDetails: React.FC = () => {
    return (
        <>
            <NavBar subListName="base_details" />
            <Routes>
                <Route index element={<BaseDetailsLanding />} />
                <Route path="applicants" element={<Applicants />} />
                <Route path="postal-address" element={<PostalAddress />} />
                <Route
                    path="agreement-details"
                    element={<AgreementDetails />}
                />
                <Route path="key-events" element={<KeyEvents />} />
                <Route path="load-ids" element={<LoaIds />} />
                <Route path="bank-card" element={<BankCard />} />
                <Route path="misc" element={<Misc />} />
                <Route path="id-aml" element={<IdAml />} />
            </Routes>
        </>
    );
};

export default BaseDetails;
