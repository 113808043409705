import React, { ChangeEvent, useState, useEffect } from "react";
import { Button, Input } from "../../../components";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxUser from "../../../store/hooks/useReduxUser";
import { PageFilterContainer } from "../../styles";
import { SettingPageFilterPanel, SettingsLandingContent } from "./styles";
import { SettingNavT } from "./types";
import UserProfile from "../../../containers/User/Settings/UserProfile";
import Header from "../../../layout/Header";
import SubNav from "./SubNav";
import PageTabs from "../../../components/PageTabs/PageTabs";
import isEmail from "validator/lib/isEmail";

const SettingsLanding: React.FC = () => {
    const { user, setUser } = useReduxUser();
    const [basicData, setBasicData] = useState<{ [key: string]: any }>({});
    const [settingsChoice, setSettingsChoice] =
        useState<SettingNavT>("Templates");
    const isFormValid =
        basicData?.name?.trim() !== "" && isEmail(basicData?.email ?? "");

    const updateUser = (data: any) => {
        const userData = data.data;
        setUser(data.data);
        setBasicData({
            id: userData?.id,
            name: userData?.name,
            email: userData?.email,
            ddi: userData?.ddi,
        });
    };
    const userById = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/get-user-by-id/${user.id}`,
        },
        { onSuccess: updateUser }
    );
    const updateBasicInfo = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/update-basic-details`,
        },
        { onSuccess: updateUser, notifyOnSuccess: true }
    );
    useEffect(() => {
        if (user?.id) {
            userById.fetchData();
        }
    }, [user?.id]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setBasicData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const submitBasicInfoHandler = (e: React.FormEvent) => {
        e.preventDefault();
        updateBasicInfo.fetchData({
            id: user.id,
            name: basicData.name,
            email: basicData.email,
            ddi: basicData.ddi,
        });
    };
    return (
        <PageFilterContainer>
            <Header title="settings" />
            <SettingsLandingContent>
                <h2>Server Settings</h2>
                <PageTabs
                    tabs={["Templates", "Services", "Other"]}
                    selectedTab={settingsChoice}
                    setSelectedTab={setSettingsChoice}
                />
                <div className="subNav">
                    {<SubNav settingsChoice={settingsChoice} />}
                </div>
                <section className="basic-info">
                    <h2>Basic Information</h2>
                    <form
                        className="basic-info__form"
                        onSubmit={submitBasicInfoHandler}
                    >
                        <Input
                            type="text"
                            label="Name: (*)"
                            onChange={handleChange}
                            value={basicData?.name || ""}
                            placeholder="Enter your name..."
                            name="name"
                            required
                        />
                        <Input
                            type="text"
                            label="DDI"
                            placeholder="Enter DDI..."
                            onChange={handleChange}
                            value={basicData?.ddi || ""}
                            name="ddi"
                        />
                        <Input
                            type="text"
                            label="Email: (*)"
                            onChange={handleChange}
                            value={basicData?.email || ""}
                            placeholder="Enter new email..."
                            name="email"
                            required
                        />
                        <Button
                            type="submit"
                            btnText="SUBMIT"
                            style="mint"
                            loading={updateBasicInfo.isLoading}
                            disabled={!isFormValid}
                        />
                    </form>
                </section>
            </SettingsLandingContent>
            <SettingPageFilterPanel className="user-profile">
                <UserProfile />
            </SettingPageFilterPanel>
        </PageFilterContainer>
    );
};

export default SettingsLanding;
