import { formatDateString, formatFileSize } from "../../global/utils/helpers";
import StyledUploadPreview from "./styles";

import { ReactComponent as FileIcon } from "../../assets/icons/FileType/document.svg";
import { ReactComponent as PDFIcon } from "../../assets/icons/FileType/pdf.svg";
import { ReactComponent as ImageIcon } from "../../assets/icons/FileType/image.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";

interface UploadPreviewProps {
    className?: string;
    filePath: string;
    fileName?: string;
    fileSize?: string;
    fileType?: string;
    date?: string;
    size?: "m" | "s";
}

const UploadPreview: React.FC<UploadPreviewProps> = ({
    className = "",
    filePath,
    fileName,
    fileType,
    fileSize,
    date = "",
    size = "m",
}) => {
    const Icon =
        fileType === "jpg" || fileType === "jpeg" || fileType === "png"
            ? ImageIcon
            : fileType === "pdf"
            ? PDFIcon
            : FileIcon;

    return (
        <StyledUploadPreview
            className={`upload-preview${className ? ` ${className}` : ""}`}
            size={size}
        >
            <div className="upload-preview__file">
                <Icon className="upload-preview__icon" data-type={fileType} />
                {!!fileType && (
                    <p className="upload-preview__type" data-type={fileType}>
                        {fileType}
                    </p>
                )}
                <a
                    className="upload-preview__download"
                    href={filePath}
                    download
                    target="_blank"
                    rel="noreferrer"
                >
                    <DownloadIcon role="icon" />
                    Download
                </a>
            </div>
            <div className="upload-preview__info">
                <p className="upload-preview__filename">
                    {fileName || <span>Unknown Filename</span>}
                </p>
                <p className="upload-preview__size">
                    {fileSize || "Size Unknown"}
                </p>
                <p className="upload-preview__date">
                    {formatDateString(date ?? "")}
                </p>
            </div>
        </StyledUploadPreview>
    );
};

export default UploadPreview;
