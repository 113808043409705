import styled from "styled-components";
import { theme } from "../../../../../global/theme";
import { iSCActive } from "../../../../../global/theme/types";

export const ProductWrapper = styled.div<iSCActive>`
    width: 420px;
    background: ${theme?.colors?.containerBackground};
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #b5bcc8;
    border-color: ${(props) => (props.active ? "rgba(77, 158, 255, 1)" : "")};
    overflow: hidden;

    &:hover {
        border-color: rgba(77, 158, 255, 1);
        & .header {
            background: ${theme?.gradients?.buttonBlue};
            & ~ p {
                background-color: ${theme?.colors.formBlue};
            }
        }
    }

    .header {
        border-radius: 10px 10px 0px 0px;
        padding-top: 14px;
        padding-bottom: 16px;
        background-color: #b5bcc8;

        h3,
        h4 {
            color: ${theme?.colors?.white};
        }
        h3 {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
        }

        h4 {
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;
        }

        ${({ active }) =>
            active && `background: ${theme?.gradients?.buttonBlue};`}

        :hover {
            background: ${theme?.gradients?.buttonBlue};
        }
    }

    p {
        font-family: "Poppins";
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: ${theme?.colors?.darkText};
        padding-block: 15px;
        border-top: solid grey 0.5px;
        white-space: nowrap;
        background-color: ${(props) => props.active && theme.colors.formBlue};
        min-height: 52px;
    }
`;

const StyledProducts = styled.form`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    width: min(100%, 1400px);
    margin-inline: auto;
`;

export default StyledProducts;
