import styled from "styled-components";
import { ButtonWrapper } from "../../../components/Button/styles";
import { PageFilterContainer } from "../../styles";
import { mainScrollbar } from "../../../global/theme/reuseable/mixins";

export const AccountsContainer = styled(PageFilterContainer)`
    & > section {
        display: flex;
        flex-direction: column;

        & > .content {
            flex-grow: 1;
            flex-basis: 0;
            overflow: auto;
            ${mainScrollbar(14)}

            display: flex;
            flex-direction: column;
        }
    }

    & .page-tabs {
        & button {
            width: 120px;
        }
        margin-bottom: 24px;
    }

    & .data-table-pagination {
        position: initial;
        margin-bottom: 16px;
    }
`;

export const AccountsWrapper = styled.div`
    display: flex;
    gap: 20px;

    button {
        width: 132px;
    }
`;

export const AdminSubNavWrapper = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 45px;
    margin-bottom: 41px;
    button,
    .searchInput {
        height: 40px !important;
    }
    button:first-child {
        width: 86.23px;
    }
    button:last-child {
        width: 132px;
    }
`;

export const CreatePermissionButton = styled(ButtonWrapper)`
    height: 40px;
    width: 253px;
    background: linear-gradient(156.78deg, #8ceedd 15.32%, #1acdad 85.25%);
`;

export const AccountsNav = styled.nav`
    display: flex;

    gap: 20px;
    margin-bottom: 43px;
    button {
        height: 50px;
    }
    button:not(:first-child) {
        width: 147px;
    }
    button:first-child {
        width: 99px;
    }
`;
