import React, { SetStateAction, useState } from "react";
import { Button, Input, TextArea } from "../../../../components";
import { GlobalWrapper, GlobalSettingsRow } from "./styles";
import TextEditor from "../../../../components/TextEditor";
import useFetchData from "../../../../global/hooks/useFetchData";

const GlobalSettings: React.FC = () => {
    const [globalSettings, setGlobalSettings] = useState<{
        [key: string]: any;
    }>({
        company_name: "",
        company_invoice_address: "",
        company_emailfooter: "",
        email_disclaimer: "",
    });
    const [plannedMaintenance, setPlannedMaintenance] = useState<{
        [key: string]: any;
    }>({
        planed_maintenance_start: "",
        planed_maintenance_end: "",
        planed_maintenance_message: "",
    });

    const getGlobalData = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/global-settings`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setGlobalSettings(data?.data || []);
                setPlannedMaintenance(data?.data || []);
            },
        }
    );
    const saveGlobalSettings = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/global-settings`,
    });
    const savePlannedMaintenance = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/global-settings`,
    });

    const handleInput = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        setter: React.Dispatch<SetStateAction<{ [key: string]: any }>>
    ) => {
        const { name, value } = e.target;
        setter((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const handleTextEditor = (
        key: string,
        content: string,
        setter: React.Dispatch<SetStateAction<{ [key: string]: any }>>
    ) => {
        setter((prev) => {
            return { ...prev, [key]: content };
        });
    };

    const submitGlobalSettings = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(globalSettings);
        saveGlobalSettings.fetchData({ global: globalSettings });
    };

    const submitPlannedMaintenance = (e: React.FormEvent) => {
        e.preventDefault();
        savePlannedMaintenance.fetchData({ planned: plannedMaintenance });
    };

    return (
        <GlobalSettingsRow gap="24px" align-items="flex-start">
            <GlobalWrapper>
                <div className="header">
                    <h3>Global Settings</h3>
                </div>
                <form className="content" onSubmit={submitGlobalSettings}>
                    <Input
                        name="company_name"
                        type="text"
                        value={globalSettings?.company_name || ""}
                        onChange={(e) => handleInput(e, setGlobalSettings)}
                        label="Company Name:"
                    />
                    <TextArea
                        label="Invoice Address:"
                        value={globalSettings?.company_invoice_address || ""}
                        onChange={(e) => handleInput(e, setGlobalSettings)}
                        name="company_invoice_address"
                    />
                    <hr />
                    <TextEditor
                        label="message:"
                        value={globalSettings?.company_emailfooter || ""}
                        onChange={(content) =>
                            handleTextEditor(
                                "company_emailfooter",
                                content,
                                setGlobalSettings
                            )
                        }
                    />
                    <TextArea
                        label="Disclaimer:"
                        value={globalSettings?.email_disclaimer || ""}
                        onChange={(e) => handleInput(e, setGlobalSettings)}
                        name="email_disclaimer"
                    />
                    <Button
                        btnText="Save"
                        style="purple"
                        loading={saveGlobalSettings.isLoading}
                    />
                </form>
            </GlobalWrapper>
            <GlobalWrapper>
                <div className="header">
                    <h3>Planned Maintenance</h3>
                </div>
                <form className="content" onSubmit={submitPlannedMaintenance}>
                    <Input
                        name="planed_maintenance_start"
                        type="datetime-local"
                        value={
                            plannedMaintenance?.planed_maintenance_start || ""
                        }
                        onChange={(e) => handleInput(e, setPlannedMaintenance)}
                        label="Start:"
                    />
                    <Input
                        name="planed_maintenance_end"
                        type="datetime-local"
                        value={plannedMaintenance?.planed_maintenance_end || ""}
                        onChange={(e) => handleInput(e, setPlannedMaintenance)}
                        label="End:"
                    />
                    <TextEditor
                        label="message:"
                        value={
                            plannedMaintenance?.planed_maintenance_message || ""
                        }
                        onChange={(content) =>
                            handleTextEditor(
                                "planed_maintenance_message",
                                content,
                                setPlannedMaintenance
                            )
                        }
                    />
                    <Button
                        btnText="Save"
                        style="purple"
                        loading={savePlannedMaintenance.isLoading}
                    />
                </form>
            </GlobalWrapper>
        </GlobalSettingsRow>
    );
};

export default GlobalSettings;
