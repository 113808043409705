import styled from "styled-components";
import { LinearHeading } from "../../../global/theme/reuseable/components";
import SearchRow from "../../../components/SearchRow";
import { theme } from "../../../global/theme";
import { mainScrollbar } from "../../../global/theme/reuseable/mixins";

export const GroupSettingsCardsWrapper = styled.div`
    flex-grow: 1;
    flex-basis: 0;
    overflow: auto;
    ${mainScrollbar(14)};
    margin-right: -30px;
    & .cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, 340px);
        gap: 24px;
        max-width: 1500px;
    }
`;

export const StatusCell = styled(LinearHeading)`
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
`;

export const ActionWrapper = styled.span`
    display: flex;
    gap: 22.14px;

    img {
        height: 20px;
        width: 20px;
        cursor: pointer;
    }
`;

export const APIKeysSearchRow = styled(SearchRow)`
    & .search-row {
        &__content {
            flex: 1;
            justify-content: space-between;

            & > a {
                background-color: ${theme?.colors.purple};
                padding: 9.5px 24px;
                border-radius: 6px;
                font-size: 14px;
                color: ${theme?.colors.white};
            }
        }
    }
`;
