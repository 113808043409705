import React, { useEffect, useState } from "react";
import Header from "../../../layout/Header";
import SearchRow from "../../../components/SearchRow";
import { TablePageContent } from "../../../pages/styles";
import useFetchData from "../../../global/hooks/useFetchData";
import DataTable from "../../../components/DataTable";
import ViewMailUpload from "../../../components/DataTable/actions/ViewMailUpload";
import FileUpload, { FileData } from "../../../components/FileUpload";
import MailUploadRow from "../../../components/DataTable/TableRows/MailUpload/MailUploadRow";
const MailUpload = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [dataLoad, SetDataLoad] = useState<{ [key: string]: any }>({});
    const { data, fetchData, isLoading } = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/administration-mail-upload`,
        },
        {
            fetchOnLoad: true,
            notifyOnSuccess: true,
            onSuccess: (data) => {
                SetDataLoad(data);
            },
        }
    );

    const uploadMail = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/mail-upload-file`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "File Uploaded",
            onSuccess: (data) => {
                SetDataLoad(data);
            },
        }
    );

    const uploadHandler = (file: FileData) => {
        console.log(file);
        if (file.file) {
            let formData = new FormData();
            formData.append("file", file.file);
            uploadMail.fetchData(formData);
        }
    };

    const tableKeyHeadings = {
        id: "#",
        hash: "Hash",
        page_total: "No. of Pages",
        created_at: "Date of Upload",
        status: "Status",
    };
    return (
        <>
            <Header
                title="administration mail upload"
                hasNavBarAbove
                hasPrevPath
            ></Header>
            <TablePageContent>
                <SearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by upload id..."
                >
                    <FileUpload onUploaded={uploadHandler} accept=".pdf">
                        Upload
                    </FileUpload>
                </SearchRow>
                <DataTable
                    labels={Object.values(tableKeyHeadings)}
                    keys={Object.keys(tableKeyHeadings)}
                    data={dataLoad?.data || []}
                    dataKey="id"
                    searchKeys={["id"]}
                    searchTerm={searchTerm}
                    isLoading={isLoading}
                    tableRow={MailUploadRow}
                    action={ViewMailUpload}
                />
            </TablePageContent>
        </>
    );
};

export default MailUpload;
