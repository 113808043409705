import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Button } from "../../../components";
import {
    GlobalSettings,
    IncomeExpenditure,
    LeadStatus,
    PrintMaintenance,
    TvDashboards,
} from "../../../subPages/User/Settings/Other";
import { PageContent } from "../../styles";
import { otherSubLinks } from "./data";
import Header from "../../../layout/Header";

const Other: React.FC = () => {
    const { pathname } = useLocation();
    const [isCreateingTV, setIsCreatingTV] = useState(false);
    const isOnTVDashboard = pathname.split("/")[3] === "tv-dashboards";
    const pageName = () => {
        const pageName = pathname.split("/").pop();
        switch (pageName) {
            case "global":
                return "Global";
            case "print-maintenance":
                return "Printer Maintenance";
            case "lead-status":
                return "Lead Management Status";
            case "income-expenditure":
                return "Income & Expenditure";
            case "tv-dashboards":
                return "TV Dashboards";
            default:
                return "TV Dashboards";
        }
    };

    return (
        <>
            <Header
                title={`settings - ${pageName()}`}
                subList={otherSubLinks}
                prevPath="/settings"
            >
                {isOnTVDashboard && (
                    <Button
                        btnText="Create Dashboard"
                        onClick={() => setIsCreatingTV(true)}
                        style="mint"
                    />
                )}
            </Header>
            <PageContent height="235px">
                <Routes>
                    <Route path="/global" element={<GlobalSettings />} />
                    <Route
                        path="/print-maintenance"
                        element={<PrintMaintenance />}
                    />
                    <Route path="/lead-status" element={<LeadStatus />} />
                    <Route
                        path="/income-expenditure"
                        element={<IncomeExpenditure />}
                    />
                    <Route
                        path="/tv-dashboards"
                        element={
                            <TvDashboards
                                isCreatingNew={isCreateingTV}
                                setIsCreatingNew={setIsCreatingTV}
                            />
                        }
                    />
                </Routes>
            </PageContent>
        </>
    );
};

export default Other;
