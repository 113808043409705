import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import ModalHeader from "../components/ModalHeader";
import { StyledCreateNote } from "./styles";
import SuccessContent from "../components/SuccessContent";
import TextArea from "../../Input/TextArea";
import DropdownSelect from "../../DropdownSelect";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import useReduxClientBalanceSheet from "../../../store/hooks/useReduxClientBalanceSheet";

interface CreateNoteProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
}

const CreateNote: React.FC<CreateNoteProps> = ({ isOpen, onClose }) => {
    const { clientId } = useParams();
    const [note, setNote] = useState("");
    const { tableData, setTableData, notes, setNotes, resetSlice, debtList } =
        useReduxClientBalanceSheet();
    const [debtId, setDebtId] = useState<number | null>(null);
    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/create-balance-note/${clientId}`,
        },
        {
            onSuccess: (data: any) => {
                setNotes(data.data?.additional_balancesheet);
            },
        }
    );
    const isFormValid = note.trim() !== "";

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({
            note,
            debt_id: debtId,
        });
    };

    return (
        <StyledCreateNote show={isOpen} width="500px" isSuccess={isSuccess}>
            <ModalHeader onClose={onClose}>
                Create Balance Sheet Note
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="Note has been created."
                    onClose={onClose}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <TextArea
                        label="Note:"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                    <DropdownSelect
                        label="Debt:"
                        value={debtId}
                        options={debtList}
                        optionKey="id"
                        labelKeys={["name"]}
                        onSelect={(option) => setDebtId(option?.id ?? null)}
                        hasDefaultValue
                    />
                    <ModalButtons>
                        <Button
                            type="button"
                            btnText="Cancel"
                            style="cancel"
                            onClick={onClose}
                        />
                        <Button
                            type="submit"
                            btnText="Create"
                            style="purple"
                            disabled={!isFormValid}
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledCreateNote>
    );
};

export default CreateNote;
