import React, { useState, useId, useRef } from "react";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from "react-beautiful-dnd";
import { theme } from "../../../global/theme";
import { StyledEditDropdown } from "./styles";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/greyLeftArrow.svg";
import { Button } from "../../../components";

interface EditDropdownProps {
    name: string;
    data: Array<{ [key: string]: any }>;
    onDragged: (data: Array<any>) => void;
    onToggleItem: (data: { [key: string]: any }) => void;
    onToggleAll?: (...args: any[]) => void;
    dataKey?: string;
    labelKey?: string;
    hiddenKey?: string;
}

const EditDropdown: React.FC<EditDropdownProps> = ({
    name,
    data,
    dataKey = "id",
    labelKey = "row",
    hiddenKey = "hide",
    onDragged,
    onToggleItem,
    onToggleAll = () => {},
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const id = useId();
    const ctnRef = useRef<HTMLDivElement>(null);

    const expandHandler = () => {
        setIsExpanded((prev) => !prev);
    };

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        userSelect: "none",
        borderColor: isDragging ? theme.colors.purple : "",
        ...draggableStyle,
    });

    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragStart = (context: any) => {
        const index = context?.source?.index;
        console.log(index);
        if (typeof index === "number") setSelectedIndex(index);
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const newItems = reorder(
            data,
            result.source.index,
            result.destination.index
        );
        onDragged(newItems);
        setSelectedIndex(result.destination.index);
    };

    return (
        <StyledEditDropdown
            className="edit-dropdown"
            aria-expanded={isExpanded}
        >
            <button className="edit-dropdown__row" onClick={expandHandler}>
                {name}
                <ArrowIcon
                    className="edit-dropdown__arrow"
                    aria-expanded={isExpanded}
                />
            </button>
            <div className="edit-dropdown__ctn" ref={ctnRef}>
                <DragDropContext
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStart}
                >
                    <Droppable droppableId={id.toString()} direction="vertical">
                        {(provided, snapshot) => (
                            <div
                                className="edit-dropdown__list"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <div className="edit-dropdown__item edit-dropdown__item--all">
                                    Add All
                                    <Button
                                        type="button"
                                        style={"mint"}
                                        btnText={"+"}
                                        onClick={onToggleAll}
                                    />
                                </div>
                                {data.map((obj, i) => (
                                    <Draggable
                                        key={`${id}${obj?.[dataKey] ?? i}`}
                                        draggableId={`${id}${
                                            obj?.[dataKey] ?? i
                                        }`}
                                        index={i}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                className="edit-dropdown__item"
                                                key={obj?.[dataKey] ?? i}
                                                data-selected={
                                                    i === selectedIndex
                                                }
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                        .style
                                                )}
                                            >
                                                {obj[labelKey]}
                                                <Button
                                                    type="button"
                                                    style={
                                                        !!obj[hiddenKey]
                                                            ? "mint"
                                                            : "red"
                                                    }
                                                    btnText={
                                                        !!obj[hiddenKey]
                                                            ? "+"
                                                            : "-"
                                                    }
                                                    onClick={() =>
                                                        onToggleItem(obj)
                                                    }
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </StyledEditDropdown>
    );
};

export default EditDropdown;
