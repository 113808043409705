import React, { useState } from "react";
import ReportHeader from "../../../atoms/ReportHeader";
import { ReportHeaderWrapper } from "../../../atoms/ReportHeader/styles";
import { Row } from "../../../global/theme/reuseable/components";
import { ReportColumn } from "../../../pages/UserPages/Reports/styles";
import useFetchData from "../../../global/hooks/useFetchData";
import { LoadingSpinner } from "../../../components/Button/styles";
import SearchRow from "../../../components/SearchRow";
import useReduxReports from "../../../store/hooks/useReduxReports";
const ReportList = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const { list, setList } = useReduxReports();
    const printReportList = (headers: { [key: string]: any }) =>
        headers.map((report: { [key: string]: any }, index: number) =>
            report.Name.toLowerCase().includes(searchTerm.toLowerCase()) ? (
                <ReportHeader
                    key={report?.id ?? index}
                    name={report?.Name || "-"}
                    id={report?.id ?? index}
                    index={index}
                />
            ) : null
        );
    const { data, isLoading, fetchData } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/reports`,
        },
        {
            onSuccess: (data) => {
                console.log(data?.data);
                setList(data?.data);
            },
            fetchOnLoad: true,
        }
    );
    if (isLoading) return <LoadingSpinner size={40} />;

    return (
        <>
            <SearchRow setValue={setSearchTerm} value={searchTerm} />
            <Row gap="24px" align-items="flex-start">
                {Object.entries(list ?? {}).map(([name, data]) => (
                    <ReportColumn key={name}>
                        <span className="header">
                            <h4>{name}</h4>
                        </span>
                        {Array.isArray(data) && data?.length ? (
                            printReportList(data)
                        ) : (
                            <ReportHeaderWrapper light={false}>
                                <p>No Reports</p>
                            </ReportHeaderWrapper>
                        )}
                    </ReportColumn>
                ))}
            </Row>
        </>
    );
};

export default ReportList;
