import React, { useState, useEffect } from "react";
import { DateRange, Range, RangeKeyDict } from "react-date-range";
import moment from "moment";

import { CalendarDateSearchContainer } from "./styles";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateSearchOption } from "../../../layout/SlideInMenus/DateSearch/data";

interface CalendarDateSearchProps {
    initialStartDate?: Date;
    initialEndDate?: Date;
    onDateChange?: (range: DateSearchOption) => void;
}

const CalendarDateSearch: React.FC<CalendarDateSearchProps> = ({
    initialStartDate = new Date(),
    initialEndDate = new Date(),
    onDateChange = () => {},
}) => {
    const [range, setRange] = useState<Range>({
        startDate: initialStartDate,
        endDate: initialEndDate,
        key: "dateSearch",
    });
    const dateFormat = "YYYY-MM-DD";
    const fromDateStr = moment(range?.startDate, "DD/MM/YYYY").format(
        dateFormat
    );
    const toDateStr = moment(range?.endDate, "DD/MM/YYYY").format(dateFormat);

    console.log();

    useEffect(() => {
        const newDateRange = {
            from: fromDateStr,
            to: toDateStr,
            displayText: `${moment(fromDateStr, dateFormat).format(
                "DD/MM/YYYY"
            )} - ${moment(toDateStr, dateFormat).format("DD/MM/YYYY")}`,
        };

        onDateChange(newDateRange);
    }, [fromDateStr, toDateStr]);

    const handleSelect = (ranges: RangeKeyDict) => {
        setRange(ranges.dateSearch);
    };

    return (
        <CalendarDateSearchContainer>
            <DateRange
                months={2}
                direction="horizontal"
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                retainEndDateOnFirstSelection={true}
                onChange={handleSelect}
                dateDisplayFormat="dd/MM/yyyy"
                ranges={[range]}
                minDate={new Date(2000, 1, 1)}
                maxDate={new Date(2050, 12, 31)}
            />
        </CalendarDateSearchContainer>
    );
};

export default CalendarDateSearch;
