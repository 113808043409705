import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import ModalHeader from "../components/ModalHeader";
import { StyledAssignNewCreditor } from "./styles";
import SuccessContent from "../components/SuccessContent";
import TextArea from "../../Input/TextArea";
import DropdownSelect from "../../DropdownSelect";
import { generateTestOptions } from "../../DropdownSelect/options";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import Input from "../../Input";

interface AssignNewCreditorProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    selectedIds: Array<number>;
    updateTable?: (...args: any[]) => void;
}

const AssignNewCreditor: React.FC<AssignNewCreditorProps> = ({
    isOpen,
    onClose,
    selectedIds,
    updateTable = () => {},
}) => {
    const { clientId } = useParams();
    const [reference, setReference] = useState("");
    const [creditorId, setCreditorId] = useState<number | null>(null);

    const getCreditorList = useFetchData({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_API}/creditors`,
    });

    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/assign-new-creditor/${clientId}`,
        },
        {
            onSuccess: (data) => {
                updateTable();
            },
        }
    );
    const isFormValid = !!creditorId;

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({
            libability_ids: selectedIds,
            creditor_id: creditorId,
            reference,
        });
    };

    return (
        <StyledAssignNewCreditor show={isOpen}>
            <ModalHeader onClose={onClose}>Assign New Creditor</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="New Creditor has been assigned"
                    onClose={onClose}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <DropdownSelect
                        label="New Creditor:"
                        value={creditorId}
                        options={getCreditorList?.data?.data || []}
                        onSelect={(option) => setCreditorId(option?.id)}
                        optionKey="id"
                        labelKeys={["name"]}
                        hasDefaultValue
                    />
                    <Input
                        type="text"
                        label="New Ref"
                        value={reference}
                        onChange={(e) => setReference(e.target.value)}
                    />
                    <ModalButtons>
                        <Button
                            type="button"
                            btnText="Cancel"
                            style="cancel"
                            onClick={onClose}
                        />
                        <Button
                            type="submit"
                            btnText="Save Creditor"
                            style="purple"
                            disabled={!isFormValid}
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledAssignNewCreditor>
    );
};

export default AssignNewCreditor;
