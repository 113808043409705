import React, { useState } from "react";
import { TableCheckbox, TableRowProps } from "..";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ColoredBlock } from "../styles";
import { StyledActionIconBtn } from "../../styles";
import { ModalContainer } from "../../..";
import { CreateAdminModal, ChangeUserPermissionModal } from "../../..";
import { ReactComponent as ViewIcon } from "../../../../assets/icons/eye.svg";
import { ReactComponent as KeyIcon } from "../../../../assets/icons/key.svg";
import { formatTimeString } from "../../../../global/utils/helpers";

const UserAccountRow: React.FC<TableRowProps> = ({ data }) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const statusColor = (value: string | number) => {
        if (value === 0) return "green";
        return "red";
    };

    const viewAdminHandler = () => {
        if (data?.Id) navigate(`/admin/${data?.Id}`);
    };

    return (
        <>
            <ModalContainer
                isVisible={isModalOpen}
                children={
                    <ChangeUserPermissionModal
                        open={isModalOpen}
                        setUserPermissionModal={setIsModalOpen}
                        userId={data?.Id || 0}
                    />
                }
            />
            <tr>
                <td>{data?.Name ?? "-"}</td>
                <td>{data["Email/Username"] ? data["Email/Username"] : "-"}</td>
                <td>{data?.DDI ?? "-"}</td>
                <td>{data?.EXTN ?? "-"}</td>
                <td>{data?.Department ?? "-"}</td>
                <td>{data?.Type ?? "-"}</td>
                <td>{formatTimeString(data?.["Last Login"] ?? "")}</td>
                <ColoredBlock color={statusColor(data?.Status) || ""}>
                    {data?.Status === 0 ? " Active" : " Not Active"}
                </ColoredBlock>
                <td className="td--action">
                    <div>
                        <StyledActionIconBtn
                            type="button"
                            onClick={viewAdminHandler}
                        >
                            <ViewIcon />
                        </StyledActionIconBtn>
                        <StyledActionIconBtn
                            type="button"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <KeyIcon />
                        </StyledActionIconBtn>
                    </div>
                </td>
            </tr>
        </>
    );
};
export default UserAccountRow;
