import { KeyEventsContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";

const CallMarkers: React.FC<KeyEventsContainerProps> = ({
    formData,
    onInputChange,
}) => {
    return (
        <HeaderContainer
            title="Call Markers"
            headerStyle="gradientBlue"
            className="call-markers"
        >
            <div className="group">
                <h3>Personal Budgeting</h3>
                <Input
                    type="date"
                    label="Eletricity / Gas:"
                    value={formData?.electricity_marker || ""}
                    onChange={onInputChange}
                    name="electricity_marker"
                />
                <Input
                    type="date"
                    label="Insurance:"
                    value={formData?.insurance_marker || ""}
                    onChange={onInputChange}
                    name="insurance_marker"
                />
                <Input
                    type="date"
                    label="Mobile:"
                    value={formData?.mobile_marker || ""}
                    onChange={onInputChange}
                    name="mobile_marker"
                />
            </div>
        </HeaderContainer>
    );
};

export default CallMarkers;
