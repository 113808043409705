import React, {
    SetStateAction,
    useEffect,
    useState,
    useLayoutEffect,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../../../layout/Header";
import { ClientFormPageContent } from "./styles";
import PersonalInfo from "./PersonalInfo";
import { ClientStepTabs } from "./PersonalInfo/styles";
import Address from "./Address";
import AdditionalInfo from "./AdditionalInfo";
import Products from "./Products";
import ProductInfo from "./ProductInfo";
import useFetchData from "../../../../global/hooks/useFetchData";
import useReduxCreateClient from "../../../../store/hooks/useReduxCreateClient";
import Payment from "./Payment";
import Summary from "./Summary";
import CreditorInfo from "./ProductInfo/CreditorInfo";

export interface ClientFormStepProps {
    setCurrentStep: React.Dispatch<SetStateAction<number>>;
    currentStep: number;
    isLastStep: boolean;
    isFirstStep: boolean;
    updateClient?: { [key: string]: any };
    setCurrentProductTypeId?: React.Dispatch<SetStateAction<number | null>>;
}

const ClientForm = () => {
    const { clientId } = useParams();
    const { setData, resetSlice, products } = useReduxCreateClient();
    const currentProductTypeId = products?.type;
    const stepOffset = currentProductTypeId === 5 ? 1 : 0;
    const productSteps = (id: number | null) => {
        if (id === 5) return ["Personal Budegeting", "Creditor Info"];
        if (id === 101) return ["Personal Accountancy"];
        if (id === 102) return ["Business Accountancy"];
        return ["Product Details"];
    };
    const formSteps = [
        "Personal Info",
        "Address",
        "Additional Info",
        "Products",
        ...productSteps(currentProductTypeId),
        "Payment",
        "Summary",
    ];
    const [currentStep, setCurrentStep] = useState(0);
    const isFirstStep = currentStep === 0;
    const isLastStep = currentStep + 1 >= formSteps.length;
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get("id");
    // const resourcePath = searchParams.get("resourcePath");

    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client/${clientId}`,
        },
        {
            notifyOnSuccess: true,
            onSuccess: (data) => {
                setData(data?.data);
            },
        }
    );

    useEffect(() => {
        fetchData();
        return () => {
            resetSlice();
        };
    }, []);

    useLayoutEffect(() => {
        if (id) {
            setCurrentStep(5 + stepOffset);
        }
    }, [id, stepOffset]);

    const stepFormProps: ClientFormStepProps = {
        setCurrentStep,
        currentStep,
        isFirstStep,
        isLastStep,
    };

    return (
        <>
            <Header
                title="create client"
                prevPath="/client-dashboard/create"
                isSubPath={false}
                hasNavBarAbove
            ></Header>
            <ClientStepTabs
                tabs={formSteps}
                selectedTabIndex={currentStep}
                setSelectedTabIndex={setCurrentStep}
                currentProgress={currentStep}
            />
            <ClientFormPageContent>
                {currentStep === 0 && <PersonalInfo {...stepFormProps} />}
                {currentStep === 1 && <Address {...stepFormProps} />}
                {currentStep === 2 && <AdditionalInfo {...stepFormProps} />}
                {currentStep === 3 && <Products {...stepFormProps} />}
                {currentStep === 4 && (
                    <ProductInfo
                        typeId={currentProductTypeId}
                        {...stepFormProps}
                    />
                )}
                {currentStep === 5 && currentProductTypeId === 5 && (
                    <CreditorInfo {...stepFormProps} />
                )}
                {currentStep === 5 + stepOffset && (
                    <Payment {...stepFormProps} />
                )}
                {currentStep === 6 + stepOffset && (
                    <Summary {...stepFormProps} />
                )}
            </ClientFormPageContent>
        </>
    );
};

export default ClientForm;
