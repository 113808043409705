import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import StyledTextEditor from "./styles";
import { SunEditorOptions } from "suneditor/src/options";

interface TextEditorProps {
    className?: string;
    label?: string;
    value?: string;
    name?: string;
    previewMode?: boolean;
    isPreviewSelected?: boolean;
    previewSelectPlaceholder?: string;
    hideToolbar?: boolean;
    textareaOnly?: boolean;
    required?: boolean;

    onChange?: (content: string) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({
    className = "",
    value,
    label,
    previewMode = false,
    isPreviewSelected = false,
    previewSelectPlaceholder = "Select template to preview.",
    onChange = () => {},
    hideToolbar = false,
    textareaOnly = false,
    name,
    required = false,
}) => {
    const editorOptions: SunEditorOptions = {
        buttonList: [
            ["undo", "redo"],
            ["removeFormat"],
            ["bold", "underline", "italic", "fontSize", "font"],
            ["fontColor", "hiliteColor"],
            ["align", "horizontalRule", "list", "paragraphStyle"],
            ["table", "link", "image"],
            ["showBlocks", "codeView"],
        ],
        imageRotation: false,
        fontSize: [12, 14, 16, 20, 24, 32, 48],

        resizingBar: false,
        font: [
            "Arial",
            "Arial Black",
            "Comic Sans MS",
            "Courier New",
            "Helvetica Neue",
            "Helvetica",
            "impact",
            "Lucida Grande",
            "Tahoma",
            "Times New Roman",
            "Verdana",
            "Open sans",
        ],
    };

    return (
        <StyledTextEditor
            className={`text-editor${className ? ` ${className}` : ""}`}
            aria-readonly={previewMode}
        >
            {label && <p className="text-editor__label">{label}</p>}
            {textareaOnly ? (
                <textarea
                    className="text-editor__textarea"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    name={name}
                    readOnly={previewMode}
                    placeholder={
                        previewMode && !isPreviewSelected
                            ? previewSelectPlaceholder
                            : ""
                    }
                    required={required}
                />
            ) : (
                <SunEditor
                    setOptions={editorOptions}
                    setContents={value}
                    onChange={onChange}
                    disableToolbar={previewMode || hideToolbar}
                    hideToolbar={previewMode || hideToolbar}
                    readOnly={previewMode}
                    disable={previewMode || hideToolbar}
                    placeholder={
                        previewMode && !isPreviewSelected
                            ? previewSelectPlaceholder
                            : ""
                    }
                    name={name}
                />
            )}
        </StyledTextEditor>
    );
};

export default TextEditor;
