import styled from "styled-components";
import { centeredRowMixin } from "../../../global/theme/reuseable/mixins";

export const PaymentsLandingWrapper = styled.section`
    display: flex;
    align-items: flex-start;
    gap: 25px;

    > * {
        width: 302px;
    }
`;

export const PaymentsWrapper = styled.section`
    margin-top: 10px;

    nav {
        display: flex;
        gap: 24px;
        /* margin-bottom: 48px; */
    }

    & > .menu {
        display: flex;
        flex-direction: column;
        row-gap: 48px;
    }

    & .menu__row {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;
    }

    .searchBar {
        ${centeredRowMixin};
        margin-bottom: 24px;
        gap: 24px;
    }
`;
