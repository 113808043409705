import { SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";

import { CreatePersonalBudgetingModal } from "./styles";
import ModalHeader from "../components/ModalHeader";
import Button from "../../Button";
import DropdownSelect from "../../DropdownSelect";
import {
    timePeriodOptions,
    expenditureNameOptions,
    incomeNameOptions,
    personalBudgetingTypeOptions,
} from "../../DropdownSelect/options";
import Input from "../../Input";
import ModalButtons from "../components/ModalButtons";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxCreateClient from "../../../store/hooks/useReduxCreateClient";

interface CreatePersonalBudgetingProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    mode?: "create" | "edit";
    data?: { [key: string]: any };
    initialType?: "I" | "E";
    onCreateSuccess?: (keyName: string, data: { [key: string]: any }) => void;
    onUpdateSuccess?: (keyName: string, data: { [key: string]: any }) => void;
    onDeleteSuccess?: (keyName: string, id: number) => void;
}

const CreatePeronsalBudgeting: React.FC<CreatePersonalBudgetingProps> = ({
    isOpen,
    onClose,
    mode = "create",
    data,
    initialType,
    onCreateSuccess,
    onUpdateSuccess,
    onDeleteSuccess,
}) => {
    const {
        personal_budgeting,
        createPersonalBudegting,
        updatePersonalBudegting,
        deletePersonalBudegting,
    } = useReduxCreateClient();
    const { clientId } = useParams();
    const defaultType = personalBudgetingTypeOptions[0].value as string;
    const defaultOften = timePeriodOptions[0].value as string;
    const [type, setType] = useState<string | null>(
        data?.type ?? initialType ?? defaultType
    );
    const [name, setName] = useState(data?.name || "");
    const [amount, setAmount] = useState<number>(data?.amount ?? 0);
    const [often, setOften] = useState<string | null>(
        data?.often || defaultOften
    );
    const hasNamesDropdown = type === "I" || type === "E";
    const currentMultiplier =
        timePeriodOptions.find((option) => option.value === often)
            ?.multiplier || 1;
    const subTotal = (amount * currentMultiplier).toFixed(0);
    const keyName =
        type === "I" ? "income" : type === "E" ? "expenditure" : "assets";
    const checkNameTaken = () => {
        if (data?.name === name) return false;
        const recordNames: string[] =
            personal_budgeting?.[keyName]?.map(
                (obj: { [key: string]: any }) => obj.name
            ) || [];
        const isNameTaken = recordNames.some((n) => n === name);
        return isNameTaken;
    };
    const isNameTaken = checkNameTaken() && name.trim() !== "";

    const isFormValid =
        !!type && !!name && !!often && amount >= 0 && !isNameTaken;

    const postRecord = useFetchData(
        {
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_API}/personal-budgeting`,
        },
        {
            onSuccess: (data) => {
                if (mode === "create") {
                    onCreateSuccess
                        ? onCreateSuccess(keyName, data?.data)
                        : createPersonalBudegting(data?.data, keyName);
                }
                if (mode === "edit") {
                    onUpdateSuccess
                        ? onUpdateSuccess(keyName, data?.data)
                        : updatePersonalBudegting(
                              data?.data,
                              keyName,
                              data?.data?.id
                          );
                }
                onClose();
            },
            notifyOnSuccess: true,
            notifySuccessMessage: `Record ${mode} success.`,
        }
    );

    const deleteRecord = useFetchData(
        {
            method: "DELETE",
            url: `${process.env.REACT_APP_SERVER_API}/personal-budgeting/${data?.id}`,
        },
        {
            onSuccess: () => {
                console.log(data?.id);
                if (data?.id) {
                    onDeleteSuccess
                        ? onDeleteSuccess(keyName, data?.id || 0)
                        : deletePersonalBudegting(keyName, data?.id);
                }
                onClose();
            },
            notifyOnSuccess: true,
            notifySuccessMessage: `Record Deleted.`,
        }
    );

    const deleteHandler = () => {
        deleteRecord.fetchData();
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const requestData = {
            tab: "personal_budgeting",
            id: clientId,
            data: {
                id: data?.id || null,
                name,
                type,
                amount,
                subTotal,
                often,
            },
        };
        postRecord.fetchData(requestData);
    };

    return (
        <CreatePersonalBudgetingModal show={isOpen} className="create-pb-modal">
            <ModalHeader onClose={onClose}>
                {mode}
                {mode === "edit" && (
                    <Button
                        type="button"
                        onClick={deleteHandler}
                        style="red"
                        btnText="Delete"
                    />
                )}
            </ModalHeader>
            <form onSubmit={submitHandler} className="create-pb-modal__form">
                <div className="input-fields">
                    <DropdownSelect
                        className="input-fields__type"
                        label="Type:"
                        value={type}
                        options={personalBudgetingTypeOptions}
                        onSelect={(data) => setType(data.value)}
                    />

                    <Input
                        className={`input-fields__name ${
                            hasNamesDropdown ? "" : "full-col"
                        }`}
                        type="text"
                        label="Name:"
                        placeholder={`Enter name here...`}
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoComplete="off"
                        isValid={!isNameTaken}
                    ></Input>
                    {type === "I" && (
                        <DropdownSelect
                            className="input-fields__default-names"
                            value={name}
                            label="Default Names:"
                            options={incomeNameOptions}
                            onSelect={(data) => setName(data?.value || "")}
                            enableInput
                        />
                    )}
                    {type === "E" && (
                        <DropdownSelect
                            className="input-fields__default-names"
                            value={name}
                            label="Default Names:"
                            options={expenditureNameOptions}
                            onSelect={(data) => setName(data?.value || "")}
                            enableInput
                        />
                    )}
                    <DropdownSelect
                        className="input-fields__often"
                        label="Often:"
                        value={often}
                        options={timePeriodOptions}
                        onSelect={(data) => setOften(data?.value || "")}
                    />
                    <Input
                        className="input-fields__amount"
                        type="number"
                        label="Amount:"
                        placeholder="Enter amount here..."
                        name="amount"
                        value={`${amount}`}
                        onChange={(e) =>
                            setAmount(+(+e.target.value).toFixed(0))
                        }
                        min={0}
                        autoComplete="off"
                    ></Input>
                </div>
                <p className="sub-total">
                    SubTotal: <span>£{subTotal}</span>
                </p>
                <ModalButtons>
                    {isNameTaken ? (
                        <span>Record with same name already exist.</span>
                    ) : (
                        ""
                    )}
                    <Button
                        style="purple"
                        btnText={mode === "create" ? "Create" : "Save Changes"}
                        type="submit"
                        loading={postRecord.isLoading}
                        disabled={!isFormValid}
                    />
                </ModalButtons>
            </form>
        </CreatePersonalBudgetingModal>
    );
};

export default CreatePeronsalBudgeting;
