import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import { Button, TextArea } from "../../../components";
import useReduxClientManagement from "../../../store/hooks/useReduxClientManagement";
import useFetchData from "../../../global/hooks/useFetchData";
import { StyledBaseDetailsSubmitCtn } from "../BaseDetails/styles";
import { StyledMiscellaneousContent } from "./styles";

const Miscellaneous = () => {
    const { misc,setMisc } = useReduxClientManagement();
    const { clientId } = useParams();
    const [notes, setNotes] = useState(misc?.notes || "");

    const updateNotes = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/management-notesUpdate/${clientId}`,
    },{
        onSuccess: (data) => {
            if (data?.data) setMisc(data?.data);
        },

    });

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        updateNotes.fetchData({
            notes,
        });
    };
    const getManagement = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/management-notes/${clientId}`,
        },
        {   fetchOnLoad: true,
            onSuccess: (data) => {
                console.log(data);
                if (data?.data) setMisc(data?.data);
            },
        }
    );

    console.log(notes);
    return (
        <>
            <Header
                title="Miscellaneous"
                hasNavBarAbove
                hasPrevPath
                isSubPath
            />
            <StyledMiscellaneousContent>
                <form onSubmit={submitHandler}>
                    <TextArea
                        label="Notes:"
                        value={notes?.accounts_alert || null}
                        onChange={(e) => setNotes(e.target.value)}
                        name="accounts_alert"
                        rows={8}
                    ></TextArea>
                    <StyledBaseDetailsSubmitCtn>
                        <Button
                            type="submit"
                            btnText="Save Miscellaneous"
                            style="purple"
                        />
                    </StyledBaseDetailsSubmitCtn>
                </form>
            </StyledMiscellaneousContent>
        </>
    );
};

export default Miscellaneous;
