import { Dispatch, SetStateAction, useState } from "react";
import { CreateTicketModalWrapper } from "../../styles";
import { StyledTicketContainer } from "../styles";
import ModalHeader from "../../components/ModalHeader";
import TicketContent from "../TicketContent";
import StepTabs from "../../../StepTabs";
import EditNotes from "../EditNotes";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";

interface iEditTicketModal {
    open: boolean;
    setViewTicketModal: Dispatch<SetStateAction<boolean>>;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    selectedMeeting: any;
    setSelectedMeeting?: Dispatch<
        SetStateAction<{ [key: string]: any } | null>
    >;
    onUpdated?: (...arg: any[]) => void;
}

const ViewMeeting = ({
    setViewTicketModal,
    open,
    selectedMeeting,
    setSelectedMeeting,
    setShowModal,
    onUpdated = () => {},
}: iEditTicketModal) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState<{ [key: string]: any }>(selectedMeeting);
    const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

    const getTicket = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/diary/${data?.id}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                setData(data?.data);
            },
        }
    );

    const closeModal = () => setViewTicketModal(false);

    const updateTicketHandler = (data: { [key: string]: any }) => {
        setIsUpdateSuccess(true);
        onUpdated();
        if (setSelectedMeeting && data) setSelectedMeeting(data);
    };

    return (
        <CreateTicketModalWrapper show={open}>
            <ModalHeader
                onClose={
                    isUpdateSuccess ? () => setShowModal(false) : closeModal
                }
            >
                View Ticket
            </ModalHeader>
            {isUpdateSuccess ? (
                <SuccessContent
                    message="You have successfully updated a ticket"
                    onClose={() => setShowModal(false)}
                />
            ) : (
                <StyledTicketContainer className="ticket-container">
                    <StepTabs
                        tabs={["Details", "Notes"]}
                        setSelectedTabIndex={setTabIndex}
                        selectedTabIndex={tabIndex}
                    />
                    {tabIndex === 0 ? (
                        <TicketContent
                            mode="edit"
                            onClose={closeModal}
                            data={data}
                            onUpdateSuccess={updateTicketHandler}
                        />
                    ) : (
                        <EditNotes
                            data={data}
                            onCreateSuccess={getTicket.fetchData}
                            isUpdated={getTicket.isSuccess}
                            isUpdating={getTicket.isLoading}
                        />
                    )}
                </StyledTicketContainer>
            )}
        </CreateTicketModalWrapper>
    );
};

export default ViewMeeting;
