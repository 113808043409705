import React, { useState } from "react";
import { LineBreakWrapper } from "./styles";

interface iLineBreak {
    title: string;
    width?: string;
}
const LineBreak = ({ title, width }: iLineBreak) => {
    return (
        <LineBreakWrapper width={width}>
            <p>{title}</p>
            <div className="line"></div>
        </LineBreakWrapper>
    );
};

export default LineBreak;
