import React from "react";
import { useNavigate } from "react-router-dom";
import { ContinueClientModalWrapper } from "../styles";
import ModalHeader from "../components/ModalHeader";
import SuccessContent from "../components/SuccessContent";

interface ClientFinishProps {
    open: boolean;
    setIsOpen: (...args: any[]) => void;
}

const ClientFinish = ({ setIsOpen, open }: ClientFinishProps) => {
    const navigate = useNavigate();
    const closeModal = () => {
        setIsOpen(false);
        navigate("/client-dashboard");
    };

    return (
        <ContinueClientModalWrapper show={open}>
            <ModalHeader onClose={closeModal}>
                Create Client Completed
            </ModalHeader>
            <SuccessContent
                message="You have successfully completed a client"
                onClose={closeModal}
            ></SuccessContent>
        </ContinueClientModalWrapper>
    );
};

export default ClientFinish;
