import styled from "styled-components";
import { theme } from "../../../global/theme";

const StyledTVCard = styled.div`
    border-radius: 16px;
    padding: 16px 24px;
    background-color: ${theme.colors.white};
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    gap: 4px 24px;
    cursor: pointer;
    border: 0.6px solid ${theme.colors.shade};

    &:hover {
        border-color: ${theme.colors.purple};
        background-color: ${theme.colors.formBlue};
    }

    & > svg {
        grid-row: 1/-1;
        align-self: center;
    }

    & .tv-card {
        &__name {
            display: flex;
            gap: 12px;
            & > h2 {
                flex: 1;
            }
        }

        &__info {
            display: flex;
            gap: 12px;
        }

        &__list {
            flex: 1;
            display: flex;
            list-style: none;
            flex-wrap: wrap;
            row-gap: 4px;
        }

        &__item {
            font-size: 12px;
            font-weight: 300;
            padding-inline: 12px;
            &:not(:last-child) {
                border-right: 0.6px solid ${theme.colors.shade};
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }
`;

export default StyledTVCard;
