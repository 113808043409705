import React, { useState } from "react";
import { Button, Modal } from "../../../components";
import IntroducerCreate from "../../../components/Modals/IntroducerCreate/IntroducerCreate";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxIntroducers from "../../../store/hooks/useReduxIntroducers";
import { TablePageContent } from "../../styles";
import Header from "../../../layout/Header";
import DataTable from "../../../components/DataTable";
import IntroducerRow from "../../../components/DataTable/TableRows/IntrodcuerRow";
import SearchRow from "../../../components/SearchRow";

const Introducer: React.FC = () => {
    const { setTypes, setData, data: introducerList } = useReduxIntroducers();
    const [createModal, setCreateModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const introducers = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/introducer`,
        },
        {
            onSuccess: (data) => {
                setTypes(data?.types || []);
                setData(data?.data || []);
            },
            fetchOnLoad: true,
        }
    );
    const introducersData = introducerList || [];

    return (
        <>
            <Modal isVisible={createModal}>
                <IntroducerCreate
                    onClose={() => setCreateModal(false)}
                    isOpen={createModal}
                    onSuccess={() => introducers.fetchData()}
                />
            </Modal>
            <Header title="introducers" />
            <TablePageContent>
                <SearchRow
                    className="search-row"
                    searchOnDebounce
                    minCharacters={1}
                    value={searchValue}
                    setValue={setSearchValue}
                    placeholder="Search by id, names, or type..."
                >
                    <Button
                        btnText="+ Create"
                        onClick={() => setCreateModal(true)}
                        style="mint"
                        paddingInline="24px"
                    />
                </SearchRow>
                <DataTable
                    labels={[
                        "#",
                        "Name",
                        "Contact Name",
                        "Type",
                        "Total Leads",
                        "Today's Leads",
                        "No. of Accounts",
                    ]}
                    keys={[
                        "id",
                        "nme",
                        "contact",
                        "type",
                        "leads_total",
                        "leads_today",
                        "users",
                    ]}
                    tableRow={IntroducerRow}
                    data={introducersData}
                    isLoading={introducers.isLoading}
                    searchKeys={["id", "nme", "contact", "type"]}
                    searchTerm={searchValue}
                    columnWidths={[
                        "40px",
                        "",
                        "",
                        "40px",
                        "50px",
                        "50px",
                        "50px",
                    ]}
                />
            </TablePageContent>
        </>
    );
};

export default Introducer;
