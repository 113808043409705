import React, { useState } from "react";
import DropdownMeunItem from "../../../components/DropdownMenu/DropdownMenuItem";
import { StyledCaseActions } from "./styles";
import { uploadActions, sendToActions } from "./data";
import { Modal } from "../../../components";
import { caseActionsModals } from "./data";
import CaseActionsSendTo from "../../../components/Modals/ClientCase/CaseActions/CaseActionsSendTo";
import CaseActionsUpload from "../../../components/Modals/ClientCase/CaseActions/CaseActionsUpload";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxUser from "../../../store/hooks/useReduxUser";
import { useParams } from "react-router-dom";

const CaseActions = () => {
    const [caseAction, setCaseAction] = useState<
        keyof typeof caseActionsModals | ""
    >("");
    const [uploadModal, setUploadModal] = useState("");
    const [sendToModal, setSendToModal] = useState("");
    const CaseActionModal = caseAction ? caseActionsModals[caseAction] : null;
    const { clientId } = useParams();
    const { user } = useReduxUser();
    const requestSMSLink = useFetchData({
        method: "GET",
        url: "https://hooks.zapier.com/hooks/catch/13770498/3bf5d8x/?ref=" + clientId + "&user=" + (user ? user.id : '')

    });

    const requestSMSLinkHandler = () => {
        requestSMSLink.fetchData();
    };

    return (
        <>
            <Modal isVisible={!!caseAction}>
                {CaseActionModal ? (
                    <CaseActionModal
                        isOpen={!!caseAction}
                        onClose={() => setCaseAction("")}
                    />
                ) : (
                    <></>
                )}
            </Modal>
            <Modal isVisible={!!uploadModal}>
                <CaseActionsUpload
                    isOpen={!!uploadModal}
                    page="case-actions"
                    onClose={() => setUploadModal("")}
                    type={uploadModal}
                />
            </Modal>
            <Modal isVisible={!!sendToModal}>
                <CaseActionsSendTo
                    isOpen={!!sendToModal}
                    onClose={() => setSendToModal("")}
                    type={sendToModal}
                />
            </Modal>
            <StyledCaseActions title="Case Actions" color="purple">
                <DropdownMeunItem
                    title="Change Status"
                    iconName="change"
                    name="changeStatus"
                    onClick={setCaseAction}
                />
                <DropdownMeunItem
                    title="Mark As Case Complete"
                    iconName="pie"
                    name="caseComplete"
                    onClick={setCaseAction}
                />
                <DropdownMeunItem
                    title="Mark Welcome Call Complete"
                    iconName="pie"
                    name="welcomeCallComplete"
                    onClick={setCaseAction}
                />
                <DropdownMeunItem
                    title="Request Cancellation"
                    iconName="cancel"
                    name="caseCancel"
                    onClick={setCaseAction}
                />
                <DropdownMeunItem
                    title="Remove From Credit Control"
                    iconName="remove"
                    name="removeFromCreditControl"
                    onClick={setCaseAction}
                />
                <DropdownMeunItem
                    title="Remove From Introducer View"
                    iconName="remove"
                    name="removeFromIntroducerView"
                    onClick={setCaseAction}
                />
                <hr />
                <DropdownMeunItem
                    title="ID Request SMS Link"
                    iconName="link"
                    onClick={requestSMSLinkHandler}
                />
                <DropdownMeunItem
                    title="Send AML and ID Request"
                    iconName="send"
                    name="sendAmlIdRequest"
                    onClick={setCaseAction}
                />
                <hr />
                {uploadActions.map((action) => (
                    <DropdownMeunItem
                        key={action.name}
                        title={action.title}
                        iconName="upload"
                        name={action.name}
                        onClick={setUploadModal}
                    />
                ))}

                <hr />
                {sendToActions.map((action) => (
                    <DropdownMeunItem
                        key={action.name}
                        title={action.title}
                        iconName="send"
                        name={action.name}
                        onClick={setSendToModal}
                    />
                ))}
                <hr />
                <DropdownMeunItem
                    title="Carry On To CCR"
                    iconName="carry"
                    name="carryOnToCCR"
                    onClick={setCaseAction}
                />
                <DropdownMeunItem
                    title="Flip Client to APF"
                    iconName="flip"
                    name="flipClientToAPF"
                    onClick={setCaseAction}
                />
            </StyledCaseActions>
        </>
    );
};

export default CaseActions;
