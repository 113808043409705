import React, { useCallback, useState } from "react";
import { Button, Modal } from "../../../../../components";
import {
    LinearSpan,
    Row,
} from "../../../../../global/theme/reuseable/components";
import CreatePeronsalBudgeting from "../../../../../components/Modals/ClientCreate/CreatePeronsalBudgeting";
import { ClientFormStepProps } from "../ClientForm";
import useReduxCreateClient from "../../../../../store/hooks/useReduxCreateClient";
import DataTable from "../../../../../components/DataTable";
import { StyledPersonalBudgeting } from "./styles";
import ClientFormStepButtons from "../ClientFormStepButtons";

const PersonalBudgeting: React.FC<ClientFormStepProps> = ({
    setCurrentStep,
    isFirstStep,
    isLastStep,
    currentStep,
}) => {
    const [createModal, setCreateModal] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<{
        [key: string]: any;
    } | null>(null);
    const { personal_budgeting } = useReduxCreateClient();
    const getListIncomeSum = useCallback(
        (listName: "income" | "asset" | "expenditure") => {
            const output =
                personal_budgeting?.[listName]
                    ?.map(
                        (data: { [key: string]: any }) =>
                            Number(data?.subtotal || 0) || 0
                    )
                    ?.reduce((a: number, c: number) => a + c) || 0;
            return typeof output === "number" ? output : 0;
        },
        [personal_budgeting]
    );
    const incomeSum = getListIncomeSum("income");
    const expenditureSum = getListIncomeSum("expenditure");

    const nextStepHandler = () => {
        setCurrentStep((prev) => prev + 1);
    };

    return (
        <>
            <Modal isVisible={createModal}>
                <CreatePeronsalBudgeting
                    isOpen={createModal}
                    onClose={() => setCreateModal(false)}
                    mode="create"
                />
            </Modal>
            <Modal isVisible={!!selectedData}>
                <CreatePeronsalBudgeting
                    isOpen={!!selectedData}
                    onClose={() => setSelectedData(null)}
                    mode="edit"
                    data={selectedData || undefined}
                />
            </Modal>
            <StyledPersonalBudgeting className="client-form__content">
                <div className="table-col">
                    <Row justify-content="space-between" className="header">
                        <h3>
                            Income:
                            <LinearSpan color="green">
                                £{incomeSum.toLocaleString()}
                            </LinearSpan>
                        </h3>
                    </Row>
                    <DataTable
                        labels={["Name", "Amount", "Often", "Subtotal"]}
                        keys={["name", "amount", "often", "subtotal"]}
                        dataKey="id"
                        data={personal_budgeting?.income || []}
                        onRowClick={(data) => setSelectedData(data)}
                        dataPerPage={99999}
                    />
                </div>
                <div className="table-col">
                    <Row justify-content="space-between" className="header">
                        <h3>
                            Expenditure:
                            <LinearSpan color="red">
                                £{expenditureSum.toLocaleString()}
                            </LinearSpan>
                        </h3>
                        <h3>
                            ={" "}
                            <LinearSpan color="green">
                                £{incomeSum - expenditureSum}
                            </LinearSpan>
                        </h3>
                        <Button
                            type="button"
                            style="white"
                            btnText="Create"
                            onClick={() => setCreateModal(true)}
                        />
                    </Row>
                    <DataTable
                        labels={["Name", "Amount", "Often", "Subtotal"]}
                        keys={["name", "amount", "often", "subtotal"]}
                        dataKey="id"
                        onRowClick={(data) => setSelectedData(data)}
                        data={personal_budgeting?.expenditure || []}
                        dataPerPage={99999}
                    />
                </div>
                <ClientFormStepButtons
                    onPrevClick={() => setCurrentStep((prev) => prev - 1)}
                    isFirstStep={isFirstStep}
                    isLastStep={isLastStep}
                    submitDisabled={false}
                    isSubmitting={false}
                    submitBtnText="Next"
                    onSubmitClick={nextStepHandler}
                />
            </StyledPersonalBudgeting>
        </>
    );
};

export default PersonalBudgeting;
