import React, { useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { importanceOptions, statusOptions } from "../../DropdownSelect/options";

import { StyledTicketContent } from "./styles";
import useReduxUser from "../../../store/hooks/useReduxUser";
import useReduxDiary from "../../../store/hooks/useReduxDiary";
import DropdownSelect from "../../DropdownSelect";
import ButtonInput from "../../Input/ButtonInput";
import DateTimePicker from "../../DatePicker/DateTimePicker";
import useDateTimePicker from "../../DatePicker/useDateTimePicker";
import TextArea from "../../Input/TextArea";
import useFetchData from "../../../global/hooks/useFetchData";
import { listCountText } from "../../DropdownSelect/functions";
import Button from "../../Button";
import ModalButtons from "../components/ModalButtons";

interface TicketContentProps {
    mode?: "create" | "edit";
    onCreateSuccess?: (...args: any[]) => void;
    onUpdateSuccess?: (...args: any[]) => void;
    onClose?: (...args: any[]) => void;
    data?: { [key: string]: any };
}

const TicketContent: React.FC<TicketContentProps> = ({
    mode = "create",
    onUpdateSuccess = () => {},
    onCreateSuccess = () => {},
    onClose = () => {},
    data,
}) => {
    const strNumsToNumArr = (str: string | null | undefined) => {
        if (typeof str !== "string") return [];
        return str.split(",").map((num) => +num);
    };
    // console.log(data);
    const isCompleted = data?.stts === 2;
    const { creditors, users, createDiary, updateDiary } = useReduxDiary();
    const { clientId } = useParams();
    const { user } = useReduxUser();
    const [actIds, setActIds] = useState<number[]>(strNumsToNumArr(data?.whm));
    const [creditorIds, setCreditorIds] = useState<number[]>(
        strNumsToNumArr(data?.creditor_id)
    );
    const [caseId, setCaseId] = useState<number | null>(data?.case_id);
    const [statusId, setStatusId] = useState<number>(data?.stts);
    const [importanceId, setImportanceId] = useState<number>(
        typeof data?.imprtnce === "string" ? +data?.imprtnce : 0
    );
    const [selectedCase, setSelectedCase] = useState<number | null>(null);
    const assignTime = useDateTimePicker({
        initialValue: data?.whn,
        defaultValue: moment().add(1, "hour").startOf("hour"),
        minDate:
            mode === "create"
                ? moment().add(1, "hour").startOf("hour")
                : undefined,
        disabled: isCompleted,
    });
    const deadline = useDateTimePicker({
        initialValue: data?.deadline || undefined,
        minDate: assignTime?.value || undefined,
        minOverValueText: "Deadline must after assigning time.",
        required: false,
        disabled: isCompleted,
    });
    const [summary, setSummary] = useState(data?.smmry);
    const isFormValid =
        actIds?.length &&
        summary &&
        (isCompleted ? true : assignTime.isValid) &&
        deadline.isValid;

    const createTicket = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/diary`,
        },
        {
            onSuccess: (fetchedData) => {
                if (fetchedData?.data) {
                    if (mode === "create") {
                        createDiary(fetchedData.data);
                        onCreateSuccess();
                    }
                    if (mode === "edit") {
                        updateDiary(fetchedData.data);
                        onUpdateSuccess(fetchedData?.data);
                    }
                }
            },
        }
    );

    const caseList = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/diary-filter-case`,
    });

    const getClientsList = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/client-search`,
        },
        {
            params: { q: `${caseId ?? ""}` },
        }
    );
    const clientList = getClientsList?.data?.data || [];

    const caseListData: { [key: string]: any }[] = caseList?.data?.data || [];

    const caseIdHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCaseId(+e.target.value.replace(/[^0-9]/, "") ?? null);
    };

    const searchCaseHandler = () => {
        if (caseId) {
            setSelectedCase(null);
            getClientsList.fetchData();
        }
    };

    const handleCreate = () => {
        const requestData = {
            userId: user?.id,
            type: mode === "create" ? "create" : "update",
            case_id: clientId ?? caseId,
            whm: actIds.join(","),
            creditor_id: creditorIds.join(","),
            whn: assignTime.valueAsString,
            deadline: deadline.valueAsString,

            smmry: summary,
        };

        if (mode === "create") createTicket.fetchData(requestData);
        if (mode === "edit" && data?.id)
            createTicket.fetchData({
                ...requestData,
                task_id: data.id,
                stts: statusId,
                imprtnce: importanceId || null,
            });
    };
    return (
        <>
            <StyledTicketContent className="ticket-content">
                {mode === "edit" && (
                    <div className="ticket-content__info">
                        <ul className="ticket-content__ids">
                            <li>
                                Task ID: <span>{data?.id || "---"}</span>
                            </li>
                            <li>
                                Case ID: <span>{data?.case_id || "---"}</span>
                            </li>
                        </ul>
                        <DropdownSelect
                            label="Status:"
                            value={statusId}
                            options={statusOptions}
                            onSelect={(value) => {
                                setStatusId(value?.value);
                            }}
                            optionKey="value"
                        />
                        <DropdownSelect
                            label="Importance:"
                            value={importanceId}
                            options={importanceOptions}
                            onSelect={(value) => {
                                setImportanceId(value?.value || 0);
                            }}
                            optionKey="value"
                            hasDefaultValue
                        />
                    </div>
                )}
                <div className="ticket-content__selects">
                    <DropdownSelect
                        className=""
                        label="Assign to: (*)"
                        values={actIds}
                        setValues={setActIds}
                        options={users || []}
                        optionKey="act_id"
                        labelKeys={["whm"]}
                        enableInput
                    />
                    {mode === "create" && !clientId && (
                        <>
                            <ButtonInput
                                gap="8px"
                                type="text"
                                label="Case ID (optional):"
                                onChange={caseIdHandler}
                                name="case_id"
                                value={caseId || ""}
                                placeholder="Case ID..."
                                onClick={searchCaseHandler}
                                btnText="Search"
                                autoComplete="off"
                                buttonDisabled={!caseId}
                            />
                            <DropdownSelect
                                label="Search result:"
                                value={selectedCase}
                                options={clientList}
                                onSelect={(option) => {
                                    setCaseId(option.id);
                                    setSelectedCase(option.id ?? null);
                                }}
                                enableInput
                                optionKey="id"
                                labelKeys={["customer_name", "id"]}
                                isLoading={getClientsList.isLoading}
                                placeholder={listCountText(clientList)}
                            />
                        </>
                    )}

                    <DropdownSelect
                        label="Creditor (optional):"
                        values={creditorIds}
                        setValues={setCreditorIds}
                        options={creditors}
                        optionKey="id"
                        labelKeys={["name"]}
                        enableInput
                        hasDefaultValue={false}
                    />
                </div>
                <hr className="lineBreak"></hr>
                <div className="ticket-content__dates">
                    <DateTimePicker
                        label="Assign on"
                        isLayoutVertical={false}
                        {...assignTime.inputProps}
                    />
                    <DateTimePicker
                        label="Deadline (Optional)"
                        isLayoutVertical={false}
                        {...deadline.inputProps}
                    />
                </div>

                <hr className="lineBreak"></hr>
                <TextArea
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    label="Task"
                    name="summary"
                />
            </StyledTicketContent>
            <ModalButtons>
                <Button
                    btnText={mode === "create" ? "Create" : "Save Changes"}
                    onClick={handleCreate}
                    style="purple"
                    loading={createTicket.isLoading}
                    disabled={!isFormValid}
                />
            </ModalButtons>
        </>
    );
};

export default TicketContent;
