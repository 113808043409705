import styled from "styled-components";
import { theme } from "../../../global/theme";

export const StyledTemplateCard = styled.div`
    background-color: ${theme.colors.white};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    gap: 8px;
    border: 1px solid transparent;
    cursor: pointer;

    &[aria-selected="true"],
    &:hover {
        border-color: ${theme.colors.purple};
    }

    &[aria-selected="true"] {
        background-color: ${theme.colors.formBlue};
    }

    & .template-card {
        &__info {
            flex-grow: 1;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto auto auto;
            gap: 0px 8px;
            margin-left: 6px;
            align-items: center;
        }

        &__name {
            font-size: 11px;
        }

        &__type {
            text-align: left;
            justify-self: flex-end;
            font-size: 11px;
            color: ${theme.colors.purple};
        }

        &__body {
            grid-column: 1/-1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 11px;
            font-weight: 300;
        }

        &__date {
            font-size: 12px;
            font-weight: 300;
        }

        &__btns {
            display: flex;
            gap: 4px;
        }

        &__edit,
        &__apply,
        &__cancel {
            border: none;
            padding: 4px 12px;
            border-radius: 6px;
            font-size: 11px;
        }

        &__edit {
            background-color: transparent;
            display: flex;
            align-items: center;
            gap: 8px;
        }

        &__apply {
            border: none;
            background-color: ${theme.colors.purple};
            color: ${theme.colors.white};
        }

        &__cancel {
            border: none;
            background-color: ${theme.colors.cancel};
            color: ${theme.colors.text};
        }
    }
`;
