import { useState } from "react";
import { Link } from "react-router-dom";
import { StyledLedgerCard } from "./styles";
import { Button, Modal } from "../../../components";
import CreateLedgerModal from "../../../components/Modals/Payments/CreateLedgerModal";
import useFetchData from "../../../global/hooks/useFetchData";
import { useParams } from "react-router-dom";

interface LedgerCardProps {
    hasLink?: boolean;
    sum?: number;
    onSuccess?: (data: { [key: string]: any }) => void;
    isLoading?: boolean;
}

const LedgerCard: React.FC<LedgerCardProps> = ({
    hasLink = true,
    sum = 0,
    onSuccess = () => {},
    isLoading,
}) => {
    const { clientId } = useParams();
    const autoPayment = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/auto-payment/${clientId}`,
        },
        {
            onSuccess: (data) => {
                //if (data?.data) setDataByKey('ledger',data?.data || []);
            },
        }
    );
    const [isCreating, setIsCreating] = useState(false);
    const autoPaymentHandler = () => {
        // autoPayment.fetchData();
    };

    const adHocPaymentHandler = () => {
        //alert("adHoc Payment");
    };

    const oneTimeCardPaymentHandler = () => {
        //alert("one time Payment");
    };
    const requestPaymentLinkHandler = () => {
        // alert("request payment link");
    };
    return (
        <>
            <Modal isVisible={isCreating}>
                <CreateLedgerModal
                    isOpen={isCreating}
                    setIsOpen={setIsCreating}
                    onSuccess={onSuccess}
                />
            </Modal>
            <StyledLedgerCard
                titleElementTag="h2"
                title={
                    <>
                        Total Legder
                        {hasLink && <Link to="ledger">View Details</Link>}
                    </>
                }
                count={sum}
                countUnit="£"
                isLoading={isLoading}
            >
                <h3>Auto Payments</h3>
                <div className="btns">
                    <Button
                        type="button"
                        btnText="+ Create Ledger"
                        style="mint"
                        onClick={() => setIsCreating(true)}
                    />
                    <Button
                        type="button"
                        btnText="Auto Payment"
                        style="purple"
                        onClick={autoPaymentHandler}
                    />
                    <Button
                        type="button"
                        btnText=" Ad-Hoc Payment"
                        style="purple"
                        onClick={adHocPaymentHandler}
                    />
                    <Button
                        type="button"
                        btnText="One-Time Card Payment"
                        style="purple"
                        onClick={oneTimeCardPaymentHandler}
                    />
                    <Button
                        type="button"
                        btnText="Request Payment Link"
                        style="purple"
                        onClick={requestPaymentLinkHandler}
                    />
                </div>
            </StyledLedgerCard>
        </>
    );
};

export default LedgerCard;
