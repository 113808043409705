import BasicModal from "../../BasicModal";
import { useParams } from "react-router-dom";
import useFetchData from "../../../../global/hooks/useFetchData";
import { CaseActionsModalProps } from "./types";

const SendAMLIdRequest: React.FC<CaseActionsModalProps> = ({
    isOpen,
    onClose,
}) => {
    const { clientId } = useParams();
    const { fetchData, isSuccess, isLoading } = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/send-aml-id-request/${clientId}`,
    });

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData();
    };
    return (
        <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            message="Do you want to send AML and ID request?"
            submitBtnText="Send"
            successMessage="AML and ID request has been sent."
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSubmit={submitHandler}
        />
    );
};

export default SendAMLIdRequest;
