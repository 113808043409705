import React, { useState } from "react";
import { TableCheckbox, TableRowProps } from "..";
import { Modal } from "../../..";
import { StyledActionIconBtn } from "../../styles";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import parse from "html-react-parser";
import { StyledLiabilityRow } from "./styles";
import DeleteLiabilityModal from "../../../Modals/BalanceSheet/DeleteLiability";
import EditLiability from "../../../Modals/BalanceSheet/EditLiability";
import { extractTextFromHTML } from "../../../../global/utils/helpers";

const LiabilityRow: React.FC<TableRowProps> = ({
    data,
    setSelectedIds,
    selectedIds,
    onActionSuccess,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const rowStyle = (status: string) => {
        const extractedStatus = extractTextFromHTML(data?.status ?? "");
        if (extractedStatus.includes("Inactive")) return "inactive";
        return "";
    };
    return (
        <>
            <Modal isVisible={isEditing}>
                <EditLiability
                    isOpen={isEditing}
                    onClose={() => setIsEditing(false)}
                    id={data?.id ?? 0}
                    onSuccess={onActionSuccess}
                />
            </Modal>
            <Modal isVisible={isDeleting}>
                <DeleteLiabilityModal
                    isOpen={isDeleting}
                    onClose={() => setIsDeleting(false)}
                    data={data}
                    onSuccess={onActionSuccess}
                />
            </Modal>
            <StyledLiabilityRow
                className={rowStyle(data?.status_display || "")}
            >
                <TableCheckbox
                    id={data?.id}
                    setSelectedIds={setSelectedIds}
                    selectedIds={selectedIds}
                />
                <td>{data?.liability ?? "-"}</td>
                <td>{data?.account_reference ?? "-"}</td>
                <td>{data?.balance ?? "-"}</td>
                <td>{data?.repayment ?? "-"}</td>
                <td>{data?.["pro-rata"] ?? "-"}</td>
                <td>{data?.ca ?? "-"}</td>
                <td>{data?.rspnsblty_name ?? "-"}</td>
                {/* <td>{data?.creditor ?? "-"}</td> */}
                <td>{data?.created_by ?? "-"}</td>
                <td className="status">
                    <div className="status__container">
                        {typeof data?.status === "string"
                            ? parse(data?.status || "-")
                            : "-"}
                    </div>
                </td>
                <td className="td--action">
                    <div>
                        <StyledActionIconBtn onClick={() => setIsEditing(true)}>
                            <EditIcon />
                        </StyledActionIconBtn>
                        <StyledActionIconBtn
                            onClick={() => setIsDeleting(true)}
                        >
                            <DeleteIcon />
                        </StyledActionIconBtn>
                    </div>
                </td>
            </StyledLiabilityRow>
        </>
    );
};
export default LiabilityRow;
