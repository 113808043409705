import React from "react";
import { StyledAccountPermissions } from "./styles";
import PermissionCheckBoxes from "../../../../components/CheckboxGroup/PermissionCheckboxes";
import useReduxAccounts from "../../../../store/hooks/useReduxAccounts";

const Permissions: React.FC = () => {
    const { userData } = useReduxAccounts();
    console.log(userData?.permissions);
    const permissionIds = userData?.permissions || [];
    return (
        <StyledAccountPermissions className="account-permissions">
            <PermissionCheckBoxes permissionIds={permissionIds} readonly />
        </StyledAccountPermissions>
    );
};
export default Permissions;
