import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface ClientCase {
    details: {[key: string]: any} | null
    payments: {[key: string]: any} | null
    services: {[key: string]: any} | null
    uploads: {[key: string]: any} | null
}
export const initialClientCase = {
    details: {},
    payments: {},
    services: {},
    uploads: {},
}

export interface UserState {
   isSignedIn: boolean
   user: {[key: string]: any}
   clientCase: ClientCase
   device: {
      os: string
      ip: string
      phpVersion: string,
      clientMasterVersion: string
   }



}

export const initialUserState: UserState = {
    isSignedIn: !!localStorage.getItem("ClearStartAccessToken"),
    user: {},
    clientCase: initialClientCase,
    device: {
        os: "",
        ip: "",
        phpVersion: "",
        clientMasterVersion: ""
     }
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setIsSignedIn: (state, action: PayloadAction<boolean>) => {
            state.isSignedIn = action.payload
        },
        setUser: (state, action: PayloadAction<{[key: string]: any}>) => {
            state.user = action.payload
        },
        setClientCase: (state, action: PayloadAction<ClientCase>) => {
            state.clientCase = action.payload
        },
        setClientCaseByKey: (state, action: PayloadAction<{key: keyof ClientCase, data:{[key: string]: any} | null}>) => {
            const {key, data} = action.payload
            state.clientCase[key] = data
        },
        setDeviceByKey: (state, action: PayloadAction<{key: keyof typeof initialUserState.device, value: string}>) => {
            const {key, value} = action.payload
            state.device[key] = value
        },
        userLogout: (state) => {}
    }
})

export const userActions = userSlice.actions
export default userSlice