import styled from "styled-components";
import { PageContent } from "../../../pages/styles";
import { theme } from "../../../global/theme";
import SearchRow from "../../../components/SearchRow";
import FilePageContent from "../../../containers/FilePageContent";
import Header from "../../../layout/Header";

const StyledCorrespondenceLandingContent = styled(PageContent)`
    & h2 {
        flex: 1;
        font-size: 14px;
    }

    & .corres-landing {
        &__cards {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 24px;
        }
        &__card {
            border-radius: 10px;
            background-color: ${theme.colors.white};
            padding: 16px;
            min-width: 300px;
            display: flex;
            flex-direction: column;

            & > h3 {
                font-size: 12px;
            }

            &__row {
                display: flex;
                align-items: center;
                gap: 12px;
                font-size: 14px;
                width: 100%;
                height: 30px;
                margin-bottom: 8px;

                a {
                    border: 1px solid ${theme.colors.purple};
                    padding: 6px 12px;
                    color: ${theme.colors.purple};
                    border-radius: 6px;
                    font-size: 12px;
                }
            }

            &__count {
                & > p {
                    font-size: 32px;
                    background-image: ${theme.gradients.skyBlue};
                    color: transparent;
                    background-size: cover;
                    background-clip: text;
                    font-weight: 600;
                    -webkit-background-clip: text;
                    line-height: 120%;

                    &[data-color="orange"] {
                        background-image: ${theme.gradients.burntOrange};
                    }
                    &[data-color="red"] {
                        background-image: ${theme.gradients.dangerRed};
                    }
                }
                & > h3 {
                    font-size: 12px;
                    font-weight: 300;
                }

                &:not(:first-child):not(:last-child) {
                    margin-bottom: 12px;
                }
            }

            &__uploads {
                list-style: none;
                display: flex;
                gap: 12px;
                margin-block: 12px;
                flex: 1;
            }

            &__upload {
            }

            &__checkboxes {
                margin-block: 12px;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
            }
        }
    }

    & .small-cards {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    & .file-upload {
        &__upload {
            &__label {
                background-image: initial !important;
                background-color: ${theme.colors.purple};
            }
        }
    }

    & textarea {
        width: clamp(300px, 30vw, 500px);
    }
`;

export const StyledListControls = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 24px;

    & > .buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        & > button {
            padding: 10px 20px;
        }
    }
`;

export const StyledListHeader = styled(Header)`
    & .text-area {
        flex-direction: row;
        flex: 1 1 0;
        justify-content: flex-end;
        align-items: center;
        max-width: 600px;
        margin-left: auto;

        & label {
            width: 132px;
        }
    }
`;

export const StyledListSearchRow = styled(SearchRow)`
    height: auto;
    & .search-row {
        &__content {
            flex: 1 1 0;
            display: flex;
            justify-content: flex-end;
            height: auto;
        }
    }
`;

export const StyledMessageContent = styled(FilePageContent)`
    & .select-controls {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-left: auto;
        & > p {
            font-weight: 300;
            font-size: 14px;
            margin-right: 8px;
        }

        & > button {
            padding-inline: 16px;
            height: 40px;
        }
    }
`;

export const StyledFilesContent = styled(FilePageContent)`
    & .file-list {
        list-style: none;
        display: flex;
        flex-direction: wrap;
        gap: 24px;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
`;

export default StyledCorrespondenceLandingContent;
