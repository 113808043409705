import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Button";
import Input from "../../../Input";
import useFetchData from "../../../../global/hooks/useFetchData";
import ModalHeader from "../../components/ModalHeader";
import SuccessContent from "../../components/SuccessContent";
import ModalButtons from "../../components/ModalButtons";
import CheckboxGroup from "../../../CheckboxGroup";
import { StyledSaveReportModal } from "./styles";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";

export interface iDiscardReportModal {
    setReportModal: Dispatch<SetStateAction<any>>;
    open: boolean;
    columns: any;
}

const SaveReportModal = ({
    setReportModal,
    open,
    columns,
}: iDiscardReportModal) => {
    const { report, setReport } = useReduxSettings();
    const [reportName, setReportName] = useState(report?.name || "");
    const [type, setType] = useState("Universal");
    const navigate = useNavigate();
    const isFormValid = reportName.trim() !== "";

    const { fetchData, isLoading, isSuccess } = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/reports`,
    });

    const closeModal = () => {
        if (isSuccess) navigate("/settings/templates/reports");
        setReportModal("");
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const requestData = {
            name: reportName,
            type: type,
            dprtmnt: report?.type || "Sales",
            columns: columns.state || [],
        };
        fetchData({ data: requestData });
    };

    return (
        <StyledSaveReportModal show={open} width="400px" isSuccess={isSuccess}>
            <ModalHeader onClose={closeModal}>Save Report</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="Template has been saved."
                    onClose={closeModal}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <CheckboxGroup
                        legend="Save report As"
                        labels={["Universal", "Personal"]}
                        values={["Universal", "Personal"]}
                        selectedValues={[type]}
                        onChange={(value) => setType(value)}
                    />
                    <Input
                        type="text"
                        name="reportName"
                        label="Report Name"
                        value={reportName}
                        placeholder="Template 1"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setReportName(e.target.value)
                        }
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Save"
                            loading={isLoading}
                            disabled={!isFormValid}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledSaveReportModal>
    );
};

export default SaveReportModal;
