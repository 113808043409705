import { AgreementDetailsContainerProps } from ".";
import DropdownSelect from "../../../../components/DropdownSelect";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import { formatDateString } from "../../../../global/utils/helpers";

const BackOffice: React.FC<AgreementDetailsContainerProps> = ({
    formData,
    onSelect,
    onInputChange,
    handlerList,
}) => {
    //formatDateString(formData?.back_office_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')
    return (
        <HeaderContainer
            title="Back Office"
            headerStyle="gradientBlue"
            className="back-office col-2"
        >
            <DropdownSelect
                label="Back Office Agent:"
                options={handlerList?.case_handler}
                value={formData?.back_office_agent_id ?? null}
                onSelect={(option) =>
                    onSelect("back_office_agent_id", option.id)
                }
                optionKey="id"
                labelKeys={["name"]}
                enableInput
            />
            <Input
                type="date"
                label="back Office Last Worked:"
                value={formatDateString(formData?.back_office_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="back_office_date"
            />
            <Input
                type="text"
                label="Additional Information:"
                value={`${formData?.back_office_notes ?? ""}`}
                onChange={onInputChange}
                name="back_office_notes"
            />
        </HeaderContainer>
    );
};

export default BackOffice;
