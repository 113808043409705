import { useAppSelector, useAppDispatch } from ".";
import {
    clientManagementActions,
    initialClientManagementState,
} from "../slices/clientManagementSlice";

const useReduxClientManagement = () => {
    const { invoice, auditLogs, misc } = useAppSelector(
        (state) => state["client-management"]
    );
    const dispatch = useAppDispatch();

    const setInvoice = (data: Array<{ [key: string]: any }>) => {
        dispatch(clientManagementActions.setInvoice(data));
    };
    const setAuditLogs = (data: Array<{ [key: string]: any }>) => {
        dispatch(clientManagementActions.setAuditLogs(data));
    };
    const setMisc = (data: { [key: string]: any } | null) => {
        dispatch(clientManagementActions.setMisc(data));
    };
    const resetSlice = () => {
        dispatch(clientManagementActions.resetSlice());
    };

    return {
        invoice,
        setInvoice,
        auditLogs,
        setAuditLogs,
        misc,
        setMisc,
        resetSlice,
    };
};

export default useReduxClientManagement;
