import styled from "styled-components";
import StepTabs from "../../../../../components/StepTabs";
import { theme } from "../../../../../global/theme";
import HeaderContainer from "../../../../../containers/HeaderContainter";

export const ClientStepTabs = styled(StepTabs)`
    position: sticky;
    padding: 16px 0;
    top: 185px;
    z-index: 2;
    width: 100%;
    background-color: ${theme?.colors.background};
`;

export const StyledPersonalInfo = styled.form`
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    justify-content: center;
    grid-template-rows: auto 1fr;
    gap: 32px;
    width: min(100%, 1600px);
    margin-left: auto;
    margin-right: auto;

    & .header-container {
        width: min(100%, 800px);
        margin-inline: auto;
    }
`;

export const PersonalInfoForm = styled.div`
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    & input:not([type="checkbox"]) {
        height: 30px;
        width: 100%;
    }
    & .col-3 {
        display: flex;
        gap: 16px;

        & > * {
            flex: 1;
        }
    }

    & .grid-col-2 {
        margin-top: 12px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px 16px;
    }

    & .checkbox-ctn {
        display: flex;
        flex-direction: column;
        & > div {
            display: flex;
            gap: 24px;
        }
    }
`;

export const OtherFormContainer = styled(HeaderContainer)`
    width: min(100%, 800px);
    grid-column: 1/-1;
    align-self: flex-start;
    justify-self: center;

    & .header-container {
        &__ctn {
            & > * {
                gap: 16px;

                &:first-child {
                    margin-bottom: 12px;

                    & > * {
                        flex: 1;
                    }
                }

                &:last-child > *:last-child {
                    flex: 1;
                }
            }
        }
    }

    & .dropdown-select {
        & input {
            max-width: initial;
        }
    }
`;
