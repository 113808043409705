import { useState } from "react";
import Button from "../../../Button";
import Input from "../../../Input";
import ModalHeader from "../../components/ModalHeader";
import SuccessContent from "../../components/SuccessContent";
import useFetchData from "../../../../global/hooks/useFetchData";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";
import ModalButtons from "../../components/ModalButtons";
import DropdownSelect from "../../../DropdownSelect";
import { tvActiveOptions } from "../../../DropdownSelect/options";
import StyledTVDasbboardModal from "./styles";
import BasicModal from "../../BasicModal";

interface TVDashboardModalProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    mode?: "create" | "edit";
    data?: { [key: string]: any };
}

const TvDashboardModal: React.FC<TVDashboardModalProps> = ({
    isOpen,
    onClose,
    mode = "create",
    data,
}) => {
    const { setOther } = useReduxSettings();
    const [isDeleting, setIsDeleting] = useState(false);
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        name: data?.name || "",
        location: data?.location || "",
        start: data?.start || "",
        end: data?.end || "",
        internet_ip: data?.internet_ip || "",
        local_ip: data?.local_ip || "",
        active: data?.active || tvActiveOptions[0].value,
    });

    const createTV = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/tv-dashboards`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setOther("tvDashboards", data.data);
            },
        }
    );

    const updateTV = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/tv-dashboards/${data?.id}`,
        },
        {
            notifyOnSuccess: true,
            onSuccess: (data) => {
                if (data?.data) setOther("tvDashboards", data.data);
            },
        }
    );

    const deleteTV = useFetchData(
        {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_API}/tv-dashboards/${data?.id}`,
        },
        {
            notifyOnSuccess: true,
            onSuccess: (data) => {
                if (data?.data) setOther("tvDashboards", data.data);
            },
        }
    );
    const isSuccess = createTV.isSuccess || updateTV.isSuccess;
    const isLoading = createTV.isLoading || updateTV.isLoading;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSelect = (key: string, value: any) => {
        setFormData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const deleteHandler = (e: React.FormEvent) => {
        e.preventDefault();
        deleteTV.fetchData();
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (mode === "create") {
            createTV.fetchData({ tv: formData });
        } else {
            updateTV.fetchData({ tv: formData });
        }
    };

    if (isDeleting)
        return (
            <BasicModal
                isSuccess={deleteTV.isSuccess}
                isLoading={deleteTV.isLoading}
                submitBtnColor="red"
                submitBtnText="Delete"
                onClose={
                    deleteTV.isSuccess ? onClose : () => setIsDeleting(false)
                }
                isOpen={isOpen}
                message="Are you sure you want to delete this TV Dashboard?"
                successMessage="TV Dashboard has been deleted."
                onSubmit={deleteHandler}
            />
        );

    return (
        <StyledTVDasbboardModal show={isOpen}>
            <ModalHeader onClose={onClose}>
                {`${mode} TV Dashboard`}
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message={`TV dasboard has been ${
                        mode === "create"
                            ? "created"
                            : deleteTV.isSuccess
                            ? "deleted"
                            : "updated"
                    }`}
                    onClose={onClose}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <Input
                        name="name"
                        label="Name:"
                        onChange={handleChange}
                        type="text"
                        value={formData?.name}
                        autoComplete="off"
                    />
                    <Input
                        name="location"
                        label="Weather Location:"
                        onChange={handleChange}
                        type="text"
                        value={formData?.location}
                        autoComplete="city"
                    />
                    <Input
                        name="start"
                        label="Time Start:"
                        onChange={handleChange}
                        type="time"
                        value={formData?.start}
                    />
                    <Input
                        name="end"
                        label="Time End:"
                        onChange={handleChange}
                        type="time"
                        value={formData?.end}
                    />
                    <Input
                        name="internet_ip"
                        label="Internet IP:"
                        inputType="ipAddress"
                        onChange={handleChange}
                        placeholder="e.g. 123.12.123"
                        type="text"
                        value={formData?.internet_ip}
                        onBlur={handleChange}
                    />
                    <Input
                        name="local_ip"
                        label="Local Network IP:"
                        inputType="ipAddress"
                        placeholder="e.g. 123.12.123"
                        onChange={handleChange}
                        onBlur={handleChange}
                        type="text"
                        value={formData?.local_ip}
                    />
                    <DropdownSelect
                        label="Active:"
                        options={tvActiveOptions}
                        value={formData.active}
                        onSelect={(option) =>
                            handleSelect("active", option.value)
                        }
                    />
                    <ModalButtons
                        onDelete={
                            mode === "edit"
                                ? () => setIsDeleting(true)
                                : undefined
                        }
                    >
                        <Button
                            type="submit"
                            btnText={
                                mode === "create" ? "Create" : "Save Changes"
                            }
                            style="purple"
                            loading={isLoading}
                            disabled={deleteTV.isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledTVDasbboardModal>
    );
};

export default TvDashboardModal;
