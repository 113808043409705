import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import useFetchData from "../../global/hooks/useFetchData";
import NavBar from "../../layout/Header/NavBar";
import ManagementLanding from "../../subPages/ClientCase/Management";
import AuditLogs from "../../subPages/ClientCase/Management/AuditLogs";
import Miscellaneous from "../../subPages/ClientCase/Management/Miscellaneous";
import useReduxClientManagement from "../../store/hooks/useReduxClientManagement";
import Invoices from "../../subPages/ClientCase/Management/Invoices";

const Management: React.FC = () => {
    const { clientId } = useParams();
    const { setInvoice, setAuditLogs, setMisc } = useReduxClientManagement();

    return (
        <>
            <NavBar subListName="management" />
            <Routes>
                <Route index element={<ManagementLanding />} />
                <Route path="invoices" element={<Invoices />} />
                <Route path="audit-logs" element={<AuditLogs />} />
                <Route path="misc" element={<Miscellaneous />} />
            </Routes>
        </>
    );
};

export default Management;
