import { BusinessAccountancyContainerProps } from ".";
import { ButtonInput, Input, TextArea } from "../../../../components";
import DropdownSelect from "../../../../components/DropdownSelect";
import { listCountText } from "../../../../components/DropdownSelect/functions";
import HeaderContainer from "../../../../containers/HeaderContainter";
import useFetchData from "../../../../global/hooks/useFetchData";

const CompanyDetails: React.FC<BusinessAccountancyContainerProps> = ({
    formData,
    setFormData,
    onInputChange,
}) => {
    const getCompanyList = useFetchData({});
    const companyList = getCompanyList?.data?.data || [];

    const searchCompanyHandler = () => {
        getCompanyList.fetchData();
    };

    const selectCompanyHandler = (option: { [key: string]: any }) => {
        setFormData((prev) => {
            return {
                ...prev,
                company_number: option?.company_number ?? prev.company_number,
                company_name: option?.company_name ?? prev.company_name,
                auth_code: option?.auth_code ?? prev.auth_code,
                utr: option?.utr ?? prev.utr,
                no_of_directors:
                    option?.no_of_directors ?? prev.no_of_directors,
                no_of_employees:
                    option?.no_of_employees ?? prev.no_of_employees,
            };
        });
    };

    return (
        <HeaderContainer title="Company Details" ctnClassName="col-2">
            <ButtonInput
                label="Company Number:"
                value={formData?.company_number || ""}
                onChange={onInputChange}
                onClick={searchCompanyHandler}
                btnText="Search"
                type="text"
                name="company_number"
            />
            <DropdownSelect
                label="Search Result:"
                options={companyList}
                isLoading={getCompanyList.isLoading}
                placeholder={
                    getCompanyList.isSuccess
                        ? listCountText(companyList)
                        : "Enter company number to search..."
                }
                onSelect={selectCompanyHandler}
                enableInput
            />
            <Input
                type="text"
                label="Company Name:"
                value={formData?.company_name || ""}
                onChange={onInputChange}
                name="company_name"
            />
            <TextArea
                label="Company Address:"
                value={formData?.company_address || ""}
                onChange={onInputChange}
                name="company_address"
            />
            <Input
                type="text"
                label="Authentication Code:"
                value={formData?.auth_code || ""}
                onChange={onInputChange}
                name="auth_code"
            />
            <Input
                type="text"
                label="UTR Number:"
                value={formData?.utr || ""}
                onChange={onInputChange}
                name="utr"
            />
            <Input
                type="number"
                label="Number of Directors:"
                value={`${formData?.no_of_directors || ""}`}
                onChange={onInputChange}
                name="no_of_directors"
            />
            <Input
                type="number"
                label="Number of Employees:"
                value={`${formData?.no_of_employees || ""}`}
                onChange={onInputChange}
                name="no_of_employees"
            />
        </HeaderContainer>
    );
};

export default CompanyDetails;
