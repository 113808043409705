import styled from "styled-components";
import { theme } from "../../global/theme";
import { mainScrollbar } from "../../global/theme/reuseable/mixins";

export type DropdownMenuColor = "purple" | "mint" | "white" | "yellow";

const StyledDropdownMenu = styled.div<{ color: DropdownMenuColor }>`
    color: ${({ color }) => {
        if (color === "white") return theme.colors.text;
        return theme.colors.white;
    }};
    position: relative;
    padding: 8px 12px;
    cursor: pointer;
    background: ${({ color }) => {
        if (color === "white") return theme.colors.background;
        if (color === "mint") return theme.gradients.mintGreen;
        return theme.colors.purple;
    }};
    border-radius: 6px;
    box-sizing: border-box;
    border: ${({ color }) => {
        if (color === "white") return `1px solid transparent`;
        return "";
    }};
    font-size: 12px;
    display: flex;
    align-items: center;

    &[aria-expanded="true"],
    &:hover {
        border: ${({ color }) => {
            if (color === "white")
                return `1px solid ${theme.colors.primaryBlue}`;
            return "";
        }};
        background-color: ${({ color }) => {
            if (color === "white") return theme.colors.white;
        }};
    }

    &[data-loading="true"] {
        pointer-events: none;
        & > p {
            visibility: hidden;
        }
    }

    &[aria-disabled="true"] {
        pointer-events: none;
        opacity: 0.5;
    }

    & .dropdown-menu {
        &__arrow {
            margin-left: 8px;
            transition: all 0.2s ease;
            & path {
                stroke: ${({ color }) => {
                    if (color === "white") return theme.colors.text;
                    return theme.colors.white;
                }};
            }

            &[aria-expanded="true"] {
                transform: rotate(-180deg);
            }
        }
        &__menu {
            position: absolute;
            top: calc(100% + 6px);
            left: 0;
            list-style: none;
            min-width: 100%;
            background-color: ${theme?.colors.white};
            color: ${theme?.colors.text};
            font-size: 12px;
            border: 1px solid ${theme?.colors.primaryBlue};
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            ${mainScrollbar(12)}

            & > hr {
                margin-block: 5px;
                margin-left: 10px;
                width: calc(100% - 20px);
                border: none;
                border-top: 0.6px solid ${theme.colors.boxStroke};
            }
        }

        &__item {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            font-size: 10px;
            gap: 8px;
            min-width: max-content;
            color: ${theme.colors.text};
            border: none;
            background-color: transparent;

            &.odd-colored:nth-of-type(2n + 1),
            &.even-colored:nth-of-type(2n) {
                background-color: ${theme.colors.cancel};

                &:focus,
                &:hover {
                    background-color: ${theme.colors.formBlue};
                }
            }

            &:focus,
            &:hover {
                background-color: ${theme.colors.formBlue};
                cursor: pointer;
            }
        }

        &__spinner {
            position: absolute;
            left: calc(50% - 8px);
            top: calc(50% - 8px);
        }
    }
`;

export const StyledDropdownMenuItem = styled.li``;

export default StyledDropdownMenu;
