import React, { SetStateAction, useId } from "react";
import StyledCheckboxGroup from "./styles";

interface CheckboxGroupProps {
    className?: string;
    legend?: string;
    inputName?: string;
    selectedValues: Array<any>;
    setSelectedValues?: React.Dispatch<SetStateAction<Array<any>>>;
    onChange?: (...args: any[]) => void;
    labels: Array<string>;
    values: Array<any>;
    disabled?: Array<boolean>;
    readOnly?: boolean;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
    className = "",
    selectedValues,
    setSelectedValues,
    onChange,
    labels,
    values,
    legend,
    inputName,
    disabled,
    readOnly = false,
}) => {
    const id = useId();

    const changeHandler = (value: any) => {
        if (setSelectedValues) {
            setSelectedValues((prev) =>
                prev.includes(value)
                    ? prev.filter((v) => v !== value)
                    : [...prev, value]
            );
        }
        if (onChange) onChange(value);
    };
    return (
        <StyledCheckboxGroup
            className={`checkbox-group${className ? ` ${className}` : ""}`}
        >
            {legend && (
                <legend className="checkbox-group__legend">{legend}:</legend>
            )}

            {values.map((value, i) => (
                <div className="checkbox-group__ctn" key={labels[i]}>
                    <label
                        className="checkbox-group__label"
                        htmlFor={`${id}-${i}`}
                    >
                        {`${labels[i]}`}
                    </label>
                    <input
                        className="checkbox-group__checkbox"
                        type="checkbox"
                        id={`${id}-${i}`}
                        value={value.toString()}
                        name={inputName}
                        checked={selectedValues.includes(value)}
                        onChange={changeHandler.bind(null, value)}
                        disabled={disabled ? disabled[i] : false}
                        readOnly={readOnly}
                    ></input>
                </div>
            ))}
        </StyledCheckboxGroup>
    );
};

export default CheckboxGroup;
