import styled, { keyframes } from "styled-components";

import { theme } from "../theme";
import { iRow, iSCColumn, iSCGrid, iSCLinearHeading, iSCOpen } from "../types";
import { centeredRowMixin, columnMixin, roundedHeaderMixin } from "./mixins";

export const Column = styled.div<iSCColumn>`
    ${columnMixin};
    ${(props) => props.gap && `gap: ${props.gap}`}
`;

export const Row = styled.div<iRow>`
    ${centeredRowMixin}

    // Justify Content
  ${(props) =>
        props["justify-content"] &&
        `justify-content: ${props["justify-content"]};`}

  // Align Items 
  ${(props) => props["align-items"] && `align-items: ${props["align-items"]};`}

  // Gap
  ${({ gap }) => (gap ? `gap: ${gap}` : null)}
`;

export const Container = styled.section`
    border-radius: 10px;
    div {
        ${roundedHeaderMixin}
    }
    background: ${theme?.colors?.containerBackground};
`;

export const Grid = styled.section<iSCGrid>`
    display: grid;
    grid-template-columns: ${({ columns }) => columns};
    gap: 27px;
`;

export const LinearHeading = styled.h5<iSCLinearHeading>`
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;

    background: ${({ color }) =>
        color === "blue"
            ? `${theme?.gradients.skyBlue}`
            : color === "green"
            ? `${theme?.gradients.mintGreen};`
            : color === "orange"
            ? ` ${theme?.gradients.burntOrange};`
            : color === "red"
            ? `${theme?.gradients?.dangerRed}`
            : ""};

    font-size: 2rem;
    font-weight: 600;
`;

export const LinearSpan = styled.span<iSCLinearHeading>`
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;

    background: ${({ color }) =>
        color === "blue"
            ? `${theme?.gradients.skyBlue}`
            : color === "green"
            ? `${theme?.gradients.mintGreen};`
            : color === "orange"
            ? ` ${theme?.gradients.burntOrange};`
            : color === "red"
            ? `${theme?.gradients?.dangerRed}`
            : ""};
`;

export const BlackBackScreen = styled.section<iSCOpen>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    opacity: ${(props) => (props.open ? "1" : "0")};
    visibility: ${(props) => (props.open ? "visible" : "hidden")};
    background-color: #45454510;
    backdrop-filter: ${(props) => (props.open ? "blur(1.5px)" : "blur(0px)")};
    transition: all 200ms linear, height 0s;
    z-index: 15;

    ${(props) =>
        props.open
            ? `
      height: 100vh;
  `
            : `
    height: 0;

  `}
`;
