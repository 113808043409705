import React, { useState } from "react";
import useFetchData from "../../../global/hooks/useFetchData";
import ModalHeader from "../components/ModalHeader";
import { StyledProcessRefundRequest } from "./styles";
import SuccessContent from "../components/SuccessContent";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import Input from "../../Input";
import TextArea from "../../Input/TextArea";

interface ProcessRefundRequestProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    data?: { [key: string]: any };
    onSuccess?: (...args: any[]) => void;
}

const ProcessRefundRequest: React.FC<ProcessRefundRequestProps> = ({
    isOpen,
    onClose,
    data,
    onSuccess = () => {},
}) => {
    const [reason, setReason] = useState("");
    const [refundAmount, setRefundAmount] = useState<number>(
        Number(data?.["Refund Amount"] ?? 0)
    );
    const [rewindTime, setRewindTime] = useState<0 | 1>(0);

    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-refund-request/${data?.["Case Id"]}`,
        },
        {
            onSuccess: (data) => {
                onSuccess();
            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (data?.["Case Id"]) {
            const requestData = {
                id : data?.['Id'],
                reason,
                refund_amount: refundAmount,
                rewind_time: rewindTime,
            };
            console.log(requestData);
            fetchData({data:requestData});
        }
    };

    return (
        <StyledProcessRefundRequest show={isOpen}>
            <ModalHeader onClose={onClose}>Refund Request</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="Refund has been sent."
                    onClose={onClose}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <ul className="data-list">
                        <li>
                            Customer Name:
                            <span>{data?.["Customer Name"] || "-"}</span>
                        </li>
                        <li>
                            Customer Bank:
                            <span>{data?.["Bank Name"] || "-"}</span>
                        </li>
                        <li>
                            Payment Reference:
                            <span>{data?.["Payment Ref"] || "-"}</span>
                        </li>
                        <li>
                            Request By:
                            <span>{data?.["Request By"] || "-"}</span>
                        </li>
                        <li>
                            Ledger Amount:
                            <span>£{data?.["Refund Amount"] || "-"}</span>
                        </li>
                    </ul>
                    <TextArea
                        label="Reason:"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                    <Input
                        type="number"
                        label="Refund Amount:"
                        value={`${refundAmount}`}
                        name="refund_amount"
                        onChange={(e) => setRefundAmount(+e.target.value)}
                    />
                    <Input
                        className="checkbox"
                        type="checkbox"
                        label="Rewind Time:"
                        checked={!!rewindTime}
                        name="pro_rata"
                        onChange={(e) =>
                            setRewindTime(e.target.checked ? 1 : 0)
                        }
                    />
                    <ModalButtons>
                        <Button
                            type="button"
                            btnText="Cancel"
                            style="cancel"
                            onClick={onClose}
                        />
                        <Button
                            type="submit"
                            btnText="Confirm"
                            style="purple"
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledProcessRefundRequest>
    );
};

export default ProcessRefundRequest;
