import DataTable from "../../../../components/DataTable";
import OpenCase from "../../../../components/DataTable/actions/OpenCase";

interface BackBookTablesProps {
    data: { [key: string]: any };
    selectedTableIndex: number;
    searchTerm: string;
    isLoading: boolean;
}

const BackBookTables: React.FC<BackBookTablesProps> = ({
    data,
    selectedTableIndex,
    searchTerm,
    isLoading,
}) => {
    const dataResultKeys = [
        "IP1",
        "IP2",
        "IP3",
        "IP4",
        "IP5",
        "APF",
        "CreditControl",
        "FAF",
        "Reviews",
    ];

    return (
        <DataTable
            key={selectedTableIndex}
            labels={data?.headings}
            data={data?.result?.[dataResultKeys[selectedTableIndex]] || []}
            dataKey="Case Id"
            isLoading={isLoading}
            //action={OpenCase}
            searchTerm={searchTerm}
            searchKeys={["Case Id"]}
            // columnWidths={[...Array(14).fill(""), "100px"]}
            minWidth={"2700px"}
            formatKeys={{
                "Next payment date": "date",
                "Last Payment attempted": "time",
                "Courtesy Call": "time",
            }}
        />
    );
};

export default BackBookTables;
