import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assets/icons";
import { NavigationTileWrapper } from "./styles";

export interface iNavTile { 
  icon: string;
  to: string;
  title: string
}

const NavigationTile = ({ icon, to, title }: iNavTile) => {
    const navigate = useNavigate()
  return (
    <NavigationTileWrapper onClick={() => navigate(to)}>
      <img src={icon} alt={title} />
      <p>{title}</p>
    </NavigationTileWrapper>
  );
};

export default NavigationTile;
