import { useParams } from "react-router-dom";
import useReduxUser from "../../../store/hooks/useReduxUser";
import { StyledClientDetails } from "./styles";
import {
    calculateAgeFromDate,
    formatDateString,
    formatTimeString,
} from "../../../global/utils/helpers";

interface UserProps {}
const ClientDetails: React.FC<UserProps> = () => {
    const { clientId } = useParams();
    const { clientCase } = useReduxUser();
    const { details, payments } = clientCase;

    return (
        <StyledClientDetails title="Client Details">
            <ul>
                <li>
                    Reference: <p>{clientId || "-"}</p>
                </li>
                <li>
                    Name(s):{" "}
                    <p className="names">
                        <span>{details?.["Name"]}</span>
                    </p>
                </li>
                <li>
                    Address: <p>{details?.["Address"] || "-"}</p>
                </li>
                <li>
                    Contact: <p>{details?.contact || "-"}</p>
                </li>
                <li>
                    DOB:{" "}
                    <p>
                        {formatDateString(details?.dob || "")}
                        <span>
                            {" "}
                            ({calculateAgeFromDate(details?.dob ?? "")})
                        </span>
                    </p>
                </li>
                <li>
                    Next Payment:{" "}
                    <p>{formatDateString(payments?.next_pmnt_in_dt || "")}</p>
                </li>
                <li>
                    Payment fq: <p>{payments?.payment_freq || "-"}</p>
                </li>
                <li>
                    DI:{" "}
                    <p
                        className={`${
                            parseInt(payments?.di) <= 0 ? "red" : ""
                        }`}
                    >
                        {(parseInt(payments?.di) || 0).toFixed(2)}
                    </p>
                </li>
            </ul>
        </StyledClientDetails>
    );
};

export default ClientDetails;
