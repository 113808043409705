import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import UserDoc from "../../../containers/User/FilterMenus/UserDoc";
import { ViewUser } from "../../../containers/User";
import { PageFilterContent, PageFilterPanel } from "../../styles";
import { AccountsContainer } from "./styles";
import Header from "../../../layout/Header";
import useReduxAccounts from "../../../store/hooks/useReduxAccounts";
import useFetchData from "../../../global/hooks/useFetchData";
import { LoadingSpinner } from "../../../components/Button/styles";
import PageTabs from "../../../components/PageTabs/PageTabs";
import Edit from "../../../subPages/User/Accounts/AccountSettings/Edit";
import Permissions from "../../../subPages/User/Accounts/AccountSettings/Permissions";
import CaseLogs from "../../../subPages/User/Accounts/AccountSettings/CaseLogs";
import LoginLogs from "../../../subPages/User/Accounts/AccountSettings/LoginLogs";
import ErrorPage from "../../ErrorPage";

const AccountSettings = () => {
    const { userId } = useParams();
    const docRef = useRef<HTMLDivElement>(null);
    const { setUserData } = useReduxAccounts();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const getUserAdmin = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/users-admin-by-id/${userId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setUserData(data.data);
            },
        }
    );
    const isUserExist = !!getUserAdmin?.data?.data?.users;
    const userData = getUserAdmin?.data?.data;
    const handlePrintUser = useReactToPrint({
        content: () => docRef.current,
    });

    if (getUserAdmin.isLoading) return <LoadingSpinner size={40} />;

    if (!isUserExist)
        return (
            <ErrorPage
                message="User not found."
                to="/admin"
                btnText="Back To Accounts"
            />
        );

    return (
        <>
            <UserDoc data={userData?.users ?? {}} elementRef={docRef} />
            <AccountsContainer>
                <Header prevPath="/admin" title="account settings"></Header>
                <PageFilterContent>
                    <PageTabs
                        tabs={[
                            "Edit",
                            "Permissions",
                            "Case Logs",
                            "Login Logs",
                        ]}
                        selectedTabIndex={selectedTabIndex}
                        setSelectedTabIndex={setSelectedTabIndex}
                    />
                    {getUserAdmin.isLoading ? (
                        <LoadingSpinner size={40} />
                    ) : (
                        <div className="content">
                            {selectedTabIndex === 0 && <Edit />}
                            {selectedTabIndex === 1 && <Permissions />}
                            {selectedTabIndex === 2 && (
                                <CaseLogs userId={userId} />
                            )}
                            {selectedTabIndex === 3 && (
                                <LoginLogs userId={userId} />
                            )}
                        </div>
                    )}
                </PageFilterContent>
                <PageFilterPanel hasNavBarAbove={false}>
                    <ViewUser onPrint={handlePrintUser} />
                </PageFilterPanel>
            </AccountsContainer>
        </>
    );
};

export default AccountSettings;
