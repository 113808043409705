import React, { ChangeEvent, useState } from "react";
import Button from "../../../Button";
import Input from "../../../Input";
import { StyledLeadStatusModal } from "./styled";
import ModalHeader from "../../components/ModalHeader";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import ModalButtons from "../../components/ModalButtons";
import DropdownSelect from "../../../DropdownSelect";
import { leadStatusGroupOptions } from "../../../DropdownSelect/options";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";

interface LeadStatusModalProps {
    closeModal: (...args: any[]) => void;
    open: boolean;
}

const CreateLeadStatusModal = ({ closeModal, open }: LeadStatusModalProps) => {
    const { setOther } = useReduxSettings();
    const [leadSettings, setLeadSettings] = useState<{ [key: string]: any }>({
        system_case_status_name: "",
        system_case_status_group: leadStatusGroupOptions[0].value,
        color: "#000000",
    });

    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/settigs-lead-status`,
        },
        {
            notifyOnSuccess: true,
            onSuccess: (data) => {
                if (data?.data) setOther("leadStatus", data.data);
            },
        }
    );
    const isFormValid = leadSettings.system_case_status_name.trim() !== "";

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setLeadSettings((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const handleSelect = (key: string, value: any) => {
        setLeadSettings((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({ lead: leadSettings });
    };

    return (
        <StyledLeadStatusModal show={open} className="lead-status-modal">
            <ModalHeader onClose={closeModal}>Lead Status Setting</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message={"New Lead Status has been created"}
                    onClose={closeModal}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <Input
                        type="text"
                        name="system_case_status_name"
                        label="Name"
                        value={leadSettings?.system_case_status_name || ""}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <DropdownSelect
                        label="Grouped By:"
                        options={leadStatusGroupOptions}
                        value={leadSettings.system_case_status_group}
                        onSelect={(option) =>
                            handleSelect(
                                "system_case_status_group",
                                option.value
                            )
                        }
                    />
                    <Input
                        type="color"
                        name="color"
                        label="Colour"
                        value={leadSettings?.color || ""}
                        onChange={handleChange}
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            btnText="Create"
                            style="purple"
                            loading={isLoading}
                            disabled={!isFormValid}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledLeadStatusModal>
    );
};

export default CreateLeadStatusModal;
