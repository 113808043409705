import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import { iDeleteModal } from "./types";
import BasicModal from "../BasicModal";

const DeleteUploadModal = ({ setModalType, open, id }: iDeleteModal) => {
    const { uploadId } = useParams();
    const closeModal = () => setModalType("");

    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_API}/upload/${uploadId}`,
        },
        {
            navigateOnSuccess: "/administration/mail-upload",
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData();
    };

    return (
        <BasicModal
            title="Delete Upload"
            isOpen={open}
            onClose={closeModal}
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSubmit={submitHandler}
            message="Do you want to delete this upload?"
            successMessage="Upload successfully deleted"
            submitBtnColor="red"
            submitBtnText="Delete"
            hasCancelBtn={false}
        />
    );
};

export default DeleteUploadModal;
