import styled from "styled-components";

const StyledPageTabs = styled.nav`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;

    & .page-tabs {
        &__list {
            list-style: none;
            display: flex;
            gap: 12px;
            flex-wrap: wrap;
        }

        &__item {
        }

        &__btn {
            border-radius: 8px;
            padding: 4px 16px;
            height: 42px;
        }
    }
`;

export default StyledPageTabs;
