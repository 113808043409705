import React, { useState } from "react";
import Header from "../../../layout/Header";
import { StyledPaymentsScheduleContent } from "./styles";
import useReduxClientLedgerPayment from "../../../store/hooks/useReduxClientLedgerPayment";
import SearchRow from "../../../components/SearchRow";
import { SearchInput } from "../../../components";
import { useParams } from "react-router-dom";
import DataTable from "../../../components/DataTable";
import DetailCard from "../../../components/Cards/DetailCard";
import { formatDateString } from "../../../global/utils/helpers";
import useFetchData from "../../../global/hooks/useFetchData";

const PaymentsSchedule = () => {
    const { paymentsSchedule, setDataByKey } = useReduxClientLedgerPayment();
    const [searchTerm, setSearchTerm] = useState("");
    const { clientId } = useParams();
    const scheduleInfo = paymentsSchedule?.methods || {};

    const { isLoading } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/payment-schedule/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data)
                    setDataByKey("paymentsSchedule", data?.data || []);
            },
        }
    );

    return (
        <>
            <Header
                title="Payment Schedule"
                hasNavBarAbove
                isSubPath
                hasPrevPath
            />
            <StyledPaymentsScheduleContent>
                <div className="search-row">
                    <h2>Client Payment Schedule</h2>
                    <SearchInput
                        value={searchTerm}
                        setValue={setSearchTerm}
                        placeholder="Search by due payments..."
                    />
                    <DetailCard>
                        <ul className="data-list">
                            <li>
                                Payment Frequency:
                                <span>{scheduleInfo?.pmnt_in_frq || "-"}</span>
                            </li>
                            <li>
                                Next Payment Date:
                                <span>
                                    {formatDateString(
                                        scheduleInfo?.nxt_pmnt_dte || "-"
                                    )}
                                </span>
                            </li>
                            <li>
                                Monthly Fee:
                                <span>£{scheduleInfo?.dmp_pmnt_in || ""}</span>
                            </li>
                        </ul>
                    </DetailCard>
                </div>

                <DataTable
                    labels={[
                        "Due Payments (£)",
                        "Overdue Payments (£)",
                        "Paid (£)",
                    ]}
                    keys={["due_payments", "overdue_payments", "paidamount"]}
                    data={paymentsSchedule?.case_payment_schedule || []}
                    searchTerm={searchTerm}
                    searchKeys={["due_payments"]}
                    isLoading={isLoading}
                />
            </StyledPaymentsScheduleContent>
        </>
    );
};

export default PaymentsSchedule;
