import React, { ReactElement, ReactNode, useEffect } from "react";
import { BackDrop, Modal } from "./styles";
import ReactDOM from "react-dom";

interface iBasicModalProps {
    isVisible: boolean;
    isSubmitSuccess?: boolean;
    children: ReactNode | ReactElement<any, any> | null;
}

const BasicModal = ({
    isVisible,
    isSubmitSuccess = false,
    children,
}: iBasicModalProps) => {
    const portalElement = document.getElementById("modal-box")!;

    useEffect(() => {
        document.body.style.overflow = isVisible ? "hidden" : "auto";
    }, [isVisible]);

    if (!isVisible) return null;

    return (
        <>
            {ReactDOM.createPortal(
                <BackDrop onClick={(event: any) => event.stopPropagation()}>
                    <Modal>{children}</Modal>
                </BackDrop>,
                portalElement
            )}
        </>
    );
};

export default BasicModal;
