import { LoadingSpinner } from "../../Button/styles";
import StyledDetailCard from "./styles";

interface DetailCardProps {
    className?: string;
    title?: string | JSX.Element;
    titleElementTag?: "p" | "h2" | "h3" | "h4" | "h5" | "h6";
    count?: number;
    countUnit?: string;
    children?: React.ReactNode;
    buttons?: JSX.Element;
    isLoading?: boolean;
}

const DetailCard: React.FC<DetailCardProps> = ({
    className = "",
    title,
    titleElementTag = "p",
    count,
    countUnit = "",
    children,
    buttons,
    isLoading,
}) => {
    const TitleElementTag = titleElementTag;

    return (
        <StyledDetailCard
            className={`detail-card${className ? ` ${className}` : ""}`}
        >
            {title && (
                <TitleElementTag className="detail-card__title">
                    {title}
                </TitleElementTag>
            )}
            {isLoading ? (
                <div className="detail-card__spinner">
                    <LoadingSpinner size={32} />
                </div>
            ) : (
                <>
                    {count !== undefined && (
                        <p className="detail-card__count">
                            {countUnit}
                            {count?.toLocaleString()}
                        </p>
                    )}
                    {children && (
                        <div className="detail-card__content">{children}</div>
                    )}
                    {buttons && (
                        <div className="detail-card__btns">{buttons}</div>
                    )}
                </>
            )}
        </StyledDetailCard>
    );
};

export default DetailCard;
