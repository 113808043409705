import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";
import { icons } from "../../assets/icons";
import { mainScrollbar } from "./reuseable/mixins";

export const GlobalStyle = createGlobalStyle`

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins";
    
    
  }


  html {
    scroll-behavior: smooth;
    height: 100%;
    margin: 0;
    ${mainScrollbar()}
    
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    height: 100svh;
    /* min-width: 1024px; */
    overflow-x: auto;
/* 
    display: flex;
    flex-direction: column; */
    background-color: #f9f9f9;

  }

  h1,h2,h3,h4,h5,h6 { 
    color: ${theme?.colors?.text};
    font-weight: 500;
  }


  a {text-decoration: none;}

  ::-webkit-calendar-picker-indicator {
    background-color: orange;
    filter: invert(1)
}


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


input[type=checkbox]{ 
  height: 19.79px;
  width: 19.79px;
  accent-color: "#583BD8";
}
button {
  cursor: pointer;
  
}


hr {
  border: none;
  border-top: 0.6px solid ${theme.colors.shade}
}
`;
