import { useMemo, useState } from "react";
import isEqual from "lodash/isEqual";

function useUndoableState<T>(init: T) {
    const [states, setStates] = useState<T[]>([init]);
    const [index, setIndex] = useState(0);

    const state = useMemo(() => states[index], [states, index]);

    const setState = (value: T) => {
        if (isEqual(state, value)) {
            return;
        }

        const copy = states.slice(0, index + 1);
        copy.push(value);
        setStates(copy);
        setIndex(copy.length - 1);
    };

    const resetState = (init: T) => {
        setIndex(0);
        setStates([init]);
    };

    const goBack = (steps = 1) => {
        setIndex(Math.max(0, index - steps));
    };

    const goForward = (steps = 1) => {
        setIndex(Math.min(states.length - 1, index + steps));
    };

    return {
        state,
        setState,
        resetState,
        index,
        lastIndex: states.length - 1,
        goBack,
        goForward,
    };
}

export default useUndoableState;
