import { formatTimeString } from "../../../global/utils/helpers";
import Button from "../../Button";
import ModalButtons from "../components/ModalButtons";
import ModalHeader from "../components/ModalHeader";
import { StyledAuditLogDetails } from "./styles";

interface AuditLogDetailsProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    data: { [key: string]: any };
}

const AuditLogDetails: React.FC<AuditLogDetailsProps> = ({
    isOpen,
    onClose,
    data,
}) => {
    return (
        <StyledAuditLogDetails className="audit-log-details" show={isOpen}>
            <ModalHeader onClose={onClose}>View Audit Log</ModalHeader>
            <ul className="audit-log-details__list">
                <li className="audit-log-details__item">
                    Description:<span>{data?.dscrptn || "-"}</span>
                </li>
                <li className="audit-log-details__item">
                    Creditor:<span>{data?.dbt || "-"}</span>
                </li>
                <li className="audit-log-details__item">
                    Created By:<span>{data?.name || "-"}</span>
                </li>
                <li className="audit-log-details__item">
                    Created When:
                    <span>{formatTimeString(data?.created_at || "")}</span>
                </li>
        
                {/* <li className="audit-log-details__item audit-log-details__data">
                    Data:
                    {data?.data ? (
                        <ul className="audit-log-details__data__list">
                            {Object.entries(data.data || {}).map(
                                ([key, value]) => (
                                    <li className="audit-log-details__data__item">
                                        {key}:
                                        <span>{JSON.stringify(value)}</span>
                                    </li>
                                )
                            )}
                        </ul>
                    ) : (
                        <span>-</span>
                    )}
                </li> */}
            </ul>
            <ModalButtons>
                <Button
                    type="button"
                    btnText="Close"
                    style="cancel"
                    onClick={onClose}
                />
            </ModalButtons>
        </StyledAuditLogDetails>
    );
};

export default AuditLogDetails;
