import React from "react";
import { Page, PageContent } from "../../../../pages/styles";

const RefundRequests = () => {
  return (
    <Page>
      <PageContent height="160px">
        <h1>RefundRequests</h1>
      </PageContent>
    </Page>
  );
};

export default RefundRequests;
