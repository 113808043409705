import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import ModalHeader from "../components/ModalHeader";
import { StyledCreateCorres } from "./styles";
import SuccessContent from "../components/SuccessContent";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import DropdownSelect from "../../DropdownSelect";
import TextEditor from "../../TextEditor";
import Input from "../../Input";
import useReduxClientCorrespondence from "../../../store/hooks/useReduxClientCorrespondence";

interface CreateCorreCallProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    isCreatingOnList?: boolean;
}

const CreateCorresEmail: React.FC<CreateCorreCallProps> = ({
    isOpen,
    onClose,
    isCreatingOnList = true,
}) => {
    const { setList, setEmail } = useReduxClientCorrespondence();
    const { clientId } = useParams();
    const [emailData, setEmailData] = useState<{ [key: string]: any }>({
        receiver_id: null,
        subject: "",
        body: "",
        id: "",
    });
    const isFormValid = !!emailData.receiver_id;

    const getReceiverList = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/correspondence-receivers/${clientId}`,
        },
        {
            fetchOnLoad: true,
        }
    );
    const getTemplateList = useFetchData({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_API}/email-teplates`,
    });

    const createEmailOnList = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/correspondence/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setEmail(data?.data);
            },
        }
    );

    const createEmailOnEmail = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/send-email/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setEmail(data?.data);
            },
        }
    );

    const createBodyEmail = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/create-email-body/${clientId}`,
        },
        {
            onSuccess: (data) => {
                setEmailData((prev) => {
                    return { ...prev, ["body"]: data?.data };
                });
            },
        }
    );

    const isLoading =
        createEmailOnEmail.isLoading || createEmailOnList.isLoading;
    const isSuccess =
        createEmailOnEmail.isSuccess || createEmailOnList.isSuccess;

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEmailData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const selectHandlerReceiver = (key: string, value: any) => {
        setEmailData((prev) => {
            return { ...prev, [key]: value };
        });
    };
    const selectBodyHandler = (key: string, value: any) => {
        setEmailData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setEmailData((prev) => {
            return { ...prev, ["subject"]: value?.subject };
        });
        setEmailData((prev) => {
            return { ...prev, ["id"]: value?.id };
        });
        createBodyEmail.fetchData({ data: value?.id || null });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (isCreatingOnList) {
            createEmailOnList.fetchData({
                type: "email",
                data: emailData,
            });
        } else {
            createEmailOnEmail.fetchData({ data: emailData });
        }
    };

    return (
        <StyledCreateCorres
            className="create-corres create-corres--sms"
            show={isOpen}
            width="660px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={onClose}>Compose Email</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    onClose={onClose}
                    message="Email has been sent."
                />
            ) : (
                <form onSubmit={submitHandler} className="col-2">
                    <DropdownSelect
                        label="Send To:"
                        options={getReceiverList?.data?.data?.users || []}
                        isLoading={getReceiverList.isLoading}
                        value={emailData?.receiver_id ?? null}
                        optionKey="id"
                        labelKeys={["email"]}
                        onSelect={(option) =>
                            selectHandlerReceiver("receiver_id", option.id)
                        }
                    />
                    <DropdownSelect
                        label="Template:"
                        options={getReceiverList?.data?.data?.template || []}
                        isLoading={getReceiverList.isLoading}
                        enableInput
                        optionKey="id"
                        labelKeys={["title"]}
                        onSelect={(option) => {
                            selectHandler("data", option || "");
                            //selectHandler("body", option?.body || "");
                        }}
                    />
                    <Input
                        className="input-subject"
                        label="Subject:"
                        type="text"
                        value={emailData.subject}
                        onChange={inputHandler}
                        name="subject"
                        autoComplete="off"
                    />
                    <TextEditor
                        label="Body:"
                        value={emailData.body}
                        name="body"
                        onChange={(content) =>
                            selectBodyHandler("body", content)
                        }
                    />
                    <ModalButtons>
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Send"
                            disabled={!isFormValid}
                            loading={isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledCreateCorres>
    );
};

export default CreateCorresEmail;
