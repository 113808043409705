import { ClientFormStepButtons as Buttons } from "./styles";
import { Button } from "../../../../components";

interface ClientFormStepButtonsProps {
    isFirstStep?: boolean;
    isLastStep?: boolean;
    onPrevClick?: (...args: any[]) => void;
    onSubmitClick?: (...args: any[]) => void;
    submitBtnText?: string;
    submitDisabled?: boolean;
    isSubmitting?: boolean;
}

const ClientFormStepButtons: React.FC<ClientFormStepButtonsProps> = ({
    isFirstStep,
    isLastStep,
    onPrevClick = () => {},
    onSubmitClick = () => {},
    submitBtnText = "Submit",
    submitDisabled = false,
    isSubmitting = false,
}) => {
    return (
        <Buttons>
            {!isFirstStep && (
                <Button
                    type="button"
                    btnText="Previous"
                    style="cancel"
                    onClick={onPrevClick}
                    disabled={isSubmitting}
                ></Button>
            )}
            <Button
                type="submit"
                btnText={isLastStep ? "Finish" : submitBtnText}
                style={isLastStep ? "mint" : "purple"}
                disabled={submitDisabled}
                loading={isSubmitting}
                onClick={onSubmitClick}
            ></Button>
        </Buttons>
    );
};

export default ClientFormStepButtons;
