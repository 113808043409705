export interface DayTimeObject {
    isSelected?: boolean;
    time: string;
    time2?: string;
}

export const initDayTimeObject = {
    isSelected: false,
    time: '00:00'
}

export const initDayTimeRangeObject = {
    isSelected: false,
    time: '00:00',
    time2: '01:00'
}

export interface WeekdaysTimes {
    monday: DayTimeObject,
    tuesday: DayTimeObject,
    wednesday: DayTimeObject,
    thursday: DayTimeObject,
    friday: DayTimeObject,
    saturday: DayTimeObject,
    sunday: DayTimeObject
}

export const initialWeekdaysTimes = {
    monday: initDayTimeObject,
    tuesday: initDayTimeObject,
    wednesday: initDayTimeObject,
    thursday: initDayTimeObject,
    friday: initDayTimeObject,
    saturday: initDayTimeObject,
    sunday: initDayTimeObject,
}