import { useAppSelector, useAppDispatch } from ".";
import { ReportTemplate, settingsActions } from "../slices/settingsSlice";
import { initialSettingsState } from "../slices/settingsSlice";

const useReduxSettings = () => {
    const { templates, report, others } = useAppSelector(
        (state) => state.settings
    );
    const dispatch = useAppDispatch();
    const reportColumns = report?.columns || [];

    const setTemplate = (
        type: keyof typeof initialSettingsState.templates,
        data: Array<{ [key: string]: any }>
    ) => {
        dispatch(settingsActions.setTemplate({ type, data }));
    };

    const setReport = (data: ReportTemplate) => {
        dispatch(settingsActions.setReport(data));
    };

    const setReportColumns = (data: Array<{ [key: string]: any }>) => {
        dispatch(settingsActions.setReportColumns(data));
    };

    const setOther = (
        type: keyof typeof initialSettingsState.others,
        data: Array<{ [key: string]: any }>
    ) => {
        dispatch(settingsActions.setOther({ type, data }));
    };

    const resetSlice = () => {
        dispatch(settingsActions.resetSlice());
    };

    return {
        templates,
        setTemplate,
        resetSlice,
        report,
        reportColumns,
        setReport,
        setReportColumns,
        others,
        setOther,
    };
};

export default useReduxSettings;
