import { PayeRebateContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import { formatDateString } from "../../../../global/utils/helpers";

const KeyEvents: React.FC<PayeRebateContainerProps> = ({
    onInputChange,
    payeRebate,
}) => {
    

    return (
        <HeaderContainer
            title="Key Events:"
            headerStyle="gradientBlue"
            ctnClassName="col-2"
        >
            <Input
                type="date"
                label="Pack Sent:"
                value={formatDateString(payeRebate?.pcksnt || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                name="pcksnt"
                onChange={onInputChange}
            />
            <Input
                type="date"
                label="Pack Received:"
                value={formatDateString(payeRebate?.pckbck || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                name="pckbck"
                onChange={onInputChange}
            />
        </HeaderContainer>
    );
};

export default KeyEvents;
