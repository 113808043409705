import React, { useState } from "react";
import { useParams } from "react-router-dom";
import StyledProducts from "./styles";
import Product from "./Product";
import { ClientFormStepProps } from "../ClientForm";
import useReduxCreateClient from "../../../../../store/hooks/useReduxCreateClient";
import useFetchData from "../../../../../global/hooks/useFetchData";
import ClientFormStepButtons from "../ClientFormStepButtons";
import { CreateClientState } from "../../../../../store/slices/createClientSlice";
import productsData from "./data";

const Products: React.FC<ClientFormStepProps> = ({
    setCurrentStep,
    isFirstStep,
    isLastStep,
}) => {
    const { products, setDataByKey } = useReduxCreateClient();
    const [selectedProductId, setSelectedProductId] = useState<number | null>(
        products?.type || null
    );
    const { clientId } = useParams();

    const { fetchData, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client`,
        },
        {
            onSuccess: (data) => {
                setCurrentStep((prev) => prev + 1);
                const keys: Array<keyof CreateClientState> = [
                    "products",
                    "personal_budgeting",
                    "personal_accountancy",
                    "business_accountancy",
                ];
                keys.forEach((key) => {
                    if (data?.data?.[key]) {
                        setDataByKey(key, data.data[key]);
                    }
                });
            },
            notifyOnSuccess: true,
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (clientId) {
            const requestData = {
                id: +clientId,
                tab: "products",
                data: { products: { type: selectedProductId } },
            };
            fetchData(requestData);
        }
    };

    return (
        <StyledProducts onSubmit={submitHandler}>
            {productsData.map((data) => (
                <Product
                    key={data?.id}
                    title={data?.title}
                    subTitle={data?.subTitle}
                    benefits={data?.benefits || []}
                    active={data.id === selectedProductId}
                    onClick={() => setSelectedProductId(data.id)}
                />
            ))}
            <ClientFormStepButtons
                onPrevClick={() => setCurrentStep((prev) => prev - 1)}
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                submitDisabled={!selectedProductId}
                isSubmitting={isLoading}
            />
        </StyledProducts>
    );
};

export default Products;
