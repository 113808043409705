import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SearchRow from "../../../../components/SearchRow";
import { ReactComponent as RedoIcon } from "../../../../assets/icons/redo.svg";
import { TablePageContent } from "../../../../pages/styles";
import DataTable from "../../../../components/DataTable";
import useFetchData from "../../../../global/hooks/useFetchData";
import { Button, Modal } from "../../../../components";
import useReduxClientProducts from "../../../../store/hooks/useReduxClientProducts";
import PersonalBudgetingActions from "../../../../components/DataTable/actions/PersonalBudgetingActions";
import { PersonalBudgetingHeader } from "./styles";
import CreatePersonalBudgeting from "../../../../components/Modals/ClientProducts/CreatePersonalBudgeting";

const PersonalBudgeting = () => {
    const { clientId } = useParams();
    const { setPersonalBudgeting, personalBudgeting } =
        useReduxClientProducts();
    const [isCreating, setIsCreating] = useState(false);
    const [searchTerm, setSeachTerm] = useState("");

    const getPersonalBudgeting = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/personal-budgeting/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data)
                    setPersonalBudgeting(data?.data?.personal_budgeting);
            },
        }
    );
    const createAllPbs = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/create-all/${clientId}`,
        },
        {
            onSuccess: (data) => {
                 if (data?.data)  setPersonalBudgeting(data?.data?.personal_budgeting);
            },
        }
    );

    const createAllPbsHandler = () => {
        createAllPbs.fetchData();
    };

    const reloadHandler = () => {
        getPersonalBudgeting.fetchData();
    };

    return (
        <>
            <Modal isVisible={isCreating}>
                <CreatePersonalBudgeting
                    isOpen={isCreating}
                    onClose={() => setIsCreating(false)}
                />
            </Modal>
            <PersonalBudgetingHeader
                title="Personal Budgeting"
                hasNavBarAbove
                hasPrevPath
            >
                <Button
                    type="button"
                    btnText="+ Create"
                    style="mint"
                    onClick={() => setIsCreating(true)}
                />
                <Button
                    type="button"
                    btnText="+ Create All"
                    style="purple"
                    onClick={createAllPbsHandler}
                    loading={createAllPbs.isLoading}
                />
                <Button
                    type="button"
                    style="purple"
                    onClick={reloadHandler}
                    loading={getPersonalBudgeting.isLoading}
                >
                    <RedoIcon />
                    Reload
                </Button>
            </PersonalBudgetingHeader>
            <TablePageContent>
                <SearchRow
                    value={searchTerm}
                    setValue={setSeachTerm}
                    placeholder="Search by id, type name or provider..."
                />
                <DataTable
                    labels={[
                        "ID",
                        "Type",
                        "Provider",
                        "Monthly Spend (£)",
                        "Renewal Date",
                    ]}
                    keys={[
                        "id",
                        "type",
                        "provider",
                        "monthly_spent",
                        "renewal",
                    ]}
                    data={personalBudgeting || []}
                    hasAction
                    searchTerm={searchTerm}
                    searchKeys={["id", "type", "provider"]}
                    action={PersonalBudgetingActions}
                    columnWidths={["120px", "", "", "150px", "120px"]}
                    formatKeys={{
                        renewal_date: "date",
                    }}
                />
            </TablePageContent>
        </>
    );
};

export default PersonalBudgeting;
