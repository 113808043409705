import styled from "styled-components";
import DropdownMenu from "../../../components/DropdownMenu";
import { mainScrollbar } from "../../../global/theme/reuseable/mixins";

export const StyledCaseActions = styled(DropdownMenu)`
    z-index: 4;
    & .dropdown-menu {
        &__menu {
            max-height: calc(100vh - 75px);
            z-index: 4;
        }
    }
`;

export const StyledClientDetails = styled(DropdownMenu)`
    & .dropdown-menu {
        &__menu {
            max-height: calc(100vh - 75px);
            padding: 8px;
            z-index: 6;

            & ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 6px;
            }

            & li {
                display: grid;
                grid-template-columns: 100px max-content;
                font-size: 11px;
                font-weight: 300;
                line-height: 110%;

                & > p {
                    font-weight: 400;

                    &.names {
                        display: flex;
                        flex-direction: column;
                    }

                    &.red {
                        color: #e31414;
                    }
                }
            }
        }
    }
`;
