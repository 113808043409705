import React, { useEffect,useState } from "react";
import Header from "../../../layout/Header";
import { useParams } from "react-router-dom";
import StyledClientPortalContent from "./styles";
import { Button } from "../../../components";
import useFetchData from "../../../global/hooks/useFetchData";
import ClientPortalTables from "./ClientPortalTables";
import useReduxClientPortal from "../../../store/hooks/useReduxClientPortal";
import { Notify } from "notiflix";

const ClientPortal = () => {
    const { clientId } = useParams();
    const { resetSlice,setRequests } = useReduxClientPortal();
    const [isCredit, setIsCredit] = useState("");
    const requestCreditSearch = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/request-credit-search/${clientId}`,
        },
        {
            onSuccess: (data) => {
               if(data?.success == false){
                    Notify.failure('Unable to Send to URL System');
               } else {

                    setRequests(data?.data?.requests);
               }
            },
        }
    );

    const sendPasswordEmail = useFetchData(
        {
            method: "GET",
            url: `https://client.clearstartaccountants.co.uk/api/emailsendpassword/38526`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Password email sent",
        }
    );

    const setTemporaryPassword = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/set-temp-password/${clientId}`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Temporary password sent",
        }
    );

    const getBaseDetils = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/client-portal/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
               
                setRequests(data?.data?.requests);
                setIsCredit(data?.data?.request_credit);
            },
        }
    );

    //     const getSession = useFetchData(
    //     {
    //         method: "get",
    //         url: `https://client.clearstartaccountants.co.uk/api/sessions/${clientId}`,
    //     },
    //     {
    //         fetchOnLoad: true,
    //         onSuccess: (data) => {
               

    //         },
    //     }
    // );

    const requestCreditSearchHandler = () => {

        if (!isCredit) {
        
            requestCreditSearch.fetchData();
        
        } else {

            Notify.info('Credit Search Has Been Done. Please Report Back to Support.');            
          
        }
    };

    const sendPasswordEmailHandler = () => {
        sendPasswordEmail.fetchData();
    };

    const setTemporaryPasswordHandler = () => {
        //setTemporaryPassword.fetchData();
    };

    useEffect(() => {
        return () => resetSlice();
    }, []);

    return (
        <>
            <Header title="Client Portal" />
            <StyledClientPortalContent className="client-portal">
                <div className="client-portal__controls">
                    <Button
                        type="button"
                        btnText="Request Credit Search"
                        style="mint"
                        onClick={requestCreditSearchHandler}
                        disabled={
                            sendPasswordEmail.isLoading ||
                            setTemporaryPassword.isLoading
                        }
                        loading={requestCreditSearch.isLoading}
                    ></Button>
                    <Button
                        type="button"
                        btnText="Send Password Email"
                        style="purple"
                        onClick={sendPasswordEmailHandler}
                        disabled={
                            requestCreditSearch.isLoading ||
                            setTemporaryPassword.isLoading
                        }
                        loading={sendPasswordEmail.isLoading}
                    ></Button>
                    <Button
                        type="button"
                        btnText="Set Temporary Password"
                        style="purple"
                        onClick={setTemporaryPasswordHandler}
                        disabled={
                            requestCreditSearch.isLoading ||
                            sendPasswordEmail.isLoading
                        }
                        loading={setTemporaryPassword.isLoading}
                    ></Button>
                </div>
                <ClientPortalTables />
            </StyledClientPortalContent>
        </>
    );
};

export default ClientPortal;
