import styled from "styled-components";
import { ResizableModalWrapper } from "../../styles";
import { theme } from "../../../../global/theme";
import { mainScrollbar } from "../../../../global/theme/reuseable/mixins";

const StyledSignableDetails = styled(ResizableModalWrapper)`
    min-height: 380px;

    & .data-list {
        list-style: none;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        & > li {
            display: grid;
            grid-template-columns: 160px 1fr;
            gap: 12px;
            font-weight: 300;

            & > span {
                font-weight: 400;
            }
        }
    }

    & .content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        min-height: 170px;
    }

    & .dropdown-menu {
        padding-inline: 24px;

        &__item {
            min-width: 220px;
        }
    }

    & .data-table {
        flex: initial;
        &__wrapper {
            flex: initial;
        }
    }

    & .document-viewer {
        display: flex;
        gap: 24px;

        &__select {
            & input {
                max-width: 70px;
            }
        }

        &__viewer {
            background-color: ${theme.colors.cancel};
            border-radius: 8px;
            border: 0.6px solid ${theme.colors.boxStroke};
            padding: 16px;
            width: 435px;
            height: 600px;
            ${mainScrollbar(12)};
            overflow: auto;
        }
    }

    & .dropdown-menu {
        &__menu {
            top: initial;
            left: initial;
            right: 0;
            bottom: calc(100% + 6px);
        }
    }
`;

export default StyledSignableDetails;
