import styled from "styled-components";
import { ResizableModalWrapper } from "../../styles";
import { theme } from "../../../../global/theme";

const StyledCorresDetails = styled(ResizableModalWrapper)`
    & h2 {
        text-transform: capitalize;
    }

    & .data-list {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 4px;

        & > li {
            display: flex;
            justify-content: space-between;
            font-weight: 300;
            font-size: 12px;

            & > span {
                font-weight: 400;
            }
        }
    }

    & form {
        display: flex;
        flex-direction: column;
        gap: 12px;
        & .form-content {
            display: flex;

            flex-wrap: wrap;
            gap: 24px;

            & .summary,
            & .details {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex: 1;
                gap: 12px;
            }

            & .summary {
                min-width: 300px;
            }

            & .details {
                min-width: 400px;
                border-radius: 8px;
                border: 0.6px solid ${theme.colors.shade};
                background-color: ${theme.colors.white};
                padding: 12px;

                & h3 {
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 8px;
                }

                & .text-area,
                & .text-area textarea,
                & .data-list,
                & .text-editor {
                    flex: 1;
                }

                & .text-editor {
                    min-height: 240px;
                }

                &[data-type="document"],
                &[data-type="upload"] {
                    & .data-list {
                        flex: initial;
                    }
                }

                & .actions {
                    margin-top: 12px;

                    display: flex;

                    gap: 8px;

                    & button {
                        flex: 1;
                    }
                }

                & .checkboxes-ctn {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    & > h4 {
                        font-size: 12px;
                        font-weight: 500;
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }
`;

export default StyledCorresDetails;
