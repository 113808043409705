import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import { StyledBankCardContent } from "./styles";
import CardDetails from "../../../components/CardDetails";
import HeaderContainer from "../../../containers/HeaderContainter";
import useFetchData from "../../../global/hooks/useFetchData";
import { Input, ButtonInput, Button } from "../../../components";
import { StyledBaseDetailsSubmitCtn } from "./styles";

const BankCard = () => {
    const { clientId } = useParams();
    const [bankDetails, setBankDetails] = useState<{ [key: string]: any }>({
        name: "",
        account_name: "",
        account_number: "",
        sortcode: "",
        address: "",
    });

    const getBankDetails = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/bank-details/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setBankDetails(data.data);
            },
        }
    );

    const updateBankDetails = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/bank-details/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setBankDetails(data.data);
            },
        }
    );

    const verifySortCode = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/verify-sort-code`,
    });

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setBankDetails((prev) => {
            return {
                ...prev,
                [name]:
                    name === "account_number"
                        ? value.replace(/[^0-9]/g, "")
                        : name === "sort_code"
                        ? value.replace(/[^0-9\\-]/g, "").replace(/-+/g, "-")
                        : value,
            };
        });
    };

    const sortCodeBlurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setBankDetails((prev) => {
            return { ...prev, [name]: value.replace(/^-+ | -+$/gm, "") };
        });
    };

    const verifyHandler = () => {
        verifySortCode.fetchData({
            sort_code: bankDetails.sort_code,
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        updateBankDetails.fetchData({data:bankDetails});
    };

    return (
        <>
            <Header title="Bank & Card" hasNavBarAbove hasPrevPath />
            <StyledBankCardContent>
                <form onSubmit={submitHandler}>
                    <HeaderContainer
                        title="Bank Details"
                        headerStyle="gradientBlue"
                    >
                        <Input
                            label="Bank Name:"
                            name="name"
                            value={bankDetails?.name || ""}
                            onChange={inputHandler}
                            type="text"
                        />
                        <Input
                            label="Account Name:"
                            name="account_name"
                            value={bankDetails?.account_name || ""}
                            onChange={inputHandler}
                            type="text"
                        />
                        <Input
                            label="Account Number:"
                            name="account_number"
                            value={bankDetails?.account_number || ""}
                            onChange={inputHandler}
                            type="text"
                        />
                        <ButtonInput
                            label="Sort Code:"
                            name="sortcode"
                            value={bankDetails?.sortcode || ""}
                            onChange={inputHandler}
                            type="text"
                            onClick={verifyHandler}
                            btnText="Verify"
                            isLoading={verifySortCode.isLoading}
                            placeholder="e.g.: 11-22-33"
                            onBlur={sortCodeBlurHandler}
                        />
                        <Input
                            label="Bank Address:"
                            name="address"
                            value={bankDetails?.address || ""}
                            onChange={inputHandler}
                            type="text"
                        />
                    </HeaderContainer>
                    <StyledBaseDetailsSubmitCtn>
                        <Button
                            type="submit"
                            btnText="Save Bank Details"
                            style="purple"
                            loading={updateBankDetails.isLoading}
                        />
                    </StyledBaseDetailsSubmitCtn>
                </form>

                <HeaderContainer
                    title="Card Details"
                    headerStyle="gradientBlue"
                >
                    <CardDetails />
                </HeaderContainer>
            </StyledBankCardContent>
        </>
    );
};

export default BankCard;
