import { useEffect } from "react";
import { iCreateAdmin } from ".";
import { Row } from "../../../../global/theme/reuseable/components";
import { ConfirmationWrapper } from "./styles";

interface iStepThree {
    adminDetails: iCreateAdmin;
}
const StepThree = ({ adminDetails }: iStepThree) => {
    useEffect(() => {
    }, [adminDetails]);

    return (
        <ConfirmationWrapper>
            <div className="confirmationSection">
                <h6>Staff Details</h6>

                <ul>
                    <li>
                        Name:<span>{adminDetails.name || "-"}</span>
                    </li>
                    <li>
                        Email Address:
                        <span>{adminDetails.email || "-"}</span>
                    </li>
                    <li>
                        DDI:<span>{adminDetails.ddi || "-"}</span>
                    </li>
                    <li>
                        Department:
                        <span>{adminDetails.department || "-"}</span>
                    </li>
                    <li>
                        Job Role:<span>{adminDetails.user_type || "-"}</span>
                    </li>
                </ul>
            </div>
            <div className="confirmationSection">
                <h6>Permissions</h6>
                <ul>
                    <li>
                        User Type:
                        <span>{adminDetails.user_type || "-"}</span>
                    </li>
                    <li>
                        Access Group:
                        <span>{adminDetails.access_group || "-"}</span>
                    </li>
                    <li>
                        IP Address
                        <span>{adminDetails.ipaddress || "-"}</span>
                    </li>
                </ul>
            </div>
            <div className="confirmationSection">
                <h6>Notes</h6>
                <p>
                    Password will be sent to {adminDetails.email} once confirmed
                    and created
                </p>
            </div>
        </ConfirmationWrapper>
    );
};

export default StepThree;
