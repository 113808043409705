import { formatDateString } from "../../../../../global/utils/helpers";
import { StyledClientSummaryCtn } from "./styles";

interface ClientSummaryCtnProps {
    className?: string;
    title?: string;
    labels?: string[];
    keys?: string[];
    data?: { [key: string]: any };
}

const ClientSummaryCtn: React.FC<ClientSummaryCtnProps> = ({
    className = "",
    title = "",
    labels = [],
    keys = [],
    data,
}) => {
    if (!data) return <></>;

    const displayedValue = (label: string, i: number) => {
        if (label === "Date of Birth")
            return formatDateString(data[keys[i]] || "");
        return data[keys[i]] || "-";
    };

    return (
        <StyledClientSummaryCtn
            className={`client-summary-ctn ${className ? ` ${className}` : ""}`}
        >
            <h3 className="client-summary-ctn__title">{title}</h3>
            <ul className="client-summary-ctn__list">
                {labels.map((label, i) => (
                    <li key={i} className="client-summary-ctn__item">
                        <h4>{label}:</h4>
                        <span>{displayedValue(label, i)}</span>
                    </li>
                ))}
            </ul>
        </StyledClientSummaryCtn>
    );
};

export default ClientSummaryCtn;
