import React, { useState } from "react";
import { icons } from "../../../../assets/icons";
import { Button, Input } from "../../../../components";
import { Column } from "../../../../global/theme/reuseable/components";
import {
    BlueBox,
    EditableSettingsWrapper,
    StyledForms,
    StyledTopRow,
} from "./styles";
import useFetchData from "../../../../global/hooks/useFetchData";
import DropdownSelect from "../../../../components/DropdownSelect";
import { serviceActiveOptions } from "../../../../components/DropdownSelect/options";
import useReduxUser from "../../../../store/hooks/useReduxUser";

const Sms = () => {
    // const { user } = useReduxUser();
    // const userId = user?.id;
    const [editedData, setEditedData] = useState({
        id: null,
        username: "",
        password: "",
        active: null,
        apikey: "",
        apisecret: "",
        other_1: "",
    });
    const [testTel, setTel] = useState("");
    const [smsBalance, setSmsBalance] = useState(0);
    const [smsTotal, setSmsTotal] = useState(0);

    const getSms = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/services-sms`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setEditedData(data.data.sms);
                setSmsBalance(data.data?.amount);
                setSmsTotal(data.data?.tot);
            },
        }
    );

    const saveSms = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/services-sms`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: 'Changes has been saved'
        }
    );

    const sendTestMessage = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/send-test-sms`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setSmsBalance(data.data?.balance);
            },
        }
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setEditedData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setEditedData((prev) => {
            return { ...prev, [key]: value };
        });

        console.log(editedData);
    };

    const handleSave = (e: React.FormEvent) => {
        e.preventDefault();
        saveSms.fetchData({ sms: editedData });
    };

    const handleTest = (e: React.FormEvent) => {
        e.preventDefault();
        const requestData = { sms: testTel };
        sendTestMessage.fetchData(requestData);
    };

    return (
        <Column gap="24px">
            <StyledTopRow className="top-row" gap="24px">
                <BlueBox>
                    <img src={icons?.vonage} alt="vonage" />
                    <a
                        href="https://ui.idp.vonage.com/ui/auth/login"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Login
                    </a>
                </BlueBox>
                <p>
                    Balance: <span>£{smsBalance.toFixed(2)}</span>
                </p>
                <p>
                    SMS: <span>{smsTotal}</span>
                </p>
            </StyledTopRow>

            <StyledForms className="forms">
                <EditableSettingsWrapper>
                    <div className="heading">
                        <h2>Settings</h2>
                    </div>
                    <form className="content">
                        <div className="row">
                            <Input
                                type="text"
                                name="username"
                                placeholder=""
                                value={editedData?.username || ""}
                                onChange={handleChange}
                                label="Username"
                            />
                            <Input
                                type="password"
                                name="password"
                                placeholder=""
                                value={editedData?.password || ""}
                                onChange={handleChange}
                                label="Password"
                                hasShowPassword
                            />
                            <DropdownSelect
                                label="Service:"
                                value={editedData?.active ?? null}
                                options={serviceActiveOptions}
                                onSelect={(option) =>
                                    selectHandler("active", option.value)
                                }
                            />
                        </div>
                        <hr />
                        <div className="row">
                            <h3 className="title">API Settings</h3>
                            <Input
                                type="text"
                                name="apikey"
                                placeholder=""
                                value={editedData.apikey}
                                onChange={handleChange}
                                label="Key:"
                                autoComplete="api-key"
                            />
                            <Input
                                type="text"
                                name="apisecret"
                                placeholder=""
                                value={editedData.apisecret}
                                onChange={handleChange}
                                label="Secret:"
                                autoComplete="api-secret"
                            />
                            <Input
                                type="text"
                                name="other_1"
                                placeholder=""
                                value={editedData.other_1}
                                onChange={handleChange}
                                label="Virtual Number:"
                                autoComplete="virtual-number"
                            />
                        </div>
                        <hr />
                        <Button
                            className="submit-btn"
                            btnText="Save"
                            onClick={handleSave}
                            style="purple"
                            loading={saveSms.isLoading}
                        />
                    </form>
                </EditableSettingsWrapper>
                <EditableSettingsWrapper className="send-test">
                    <div className="heading">
                        <h2>Send Test Sms</h2>
                    </div>
                    <form
                        className="content content--send-test"
                        onSubmit={handleTest}
                    >
                        <div className="row">
                            <Input
                                type="tel"
                                name="testTel"
                                placeholder="0788923430..."
                                value={testTel}
                                onChange={(e) =>
                                    setTel(
                                        e.target.value.replace(/[^0-9\\+]/g, "")
                                    )
                                }
                                label="Mobile Number:"
                            />
                        </div>
                        <Button
                            className="submit-btn"
                            btnText="Send Test Message"
                            style="purple"
                            loading={sendTestMessage.isLoading}
                        />
                    </form>
                </EditableSettingsWrapper>
            </StyledForms>
        </Column>
    );
};

export default Sms;
