import { useParams } from "react-router-dom";
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { Button, ButtonInput, TextArea } from "../../../../components";
import { Row } from "../../../../global/theme/reuseable/components";
import { StyledPageCounter } from "./styles";
import useFetchData from "../../../../global/hooks/useFetchData";
import DropdownSelect from "../../../../components/DropdownSelect";
import { listCountText } from "../../../../components/DropdownSelect/functions";
import { mailUploadActionOptions } from "../../../../components/DropdownSelect/options";

interface iPageCounter {
    totalPages: number;
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
}

const PageCounter = ({
    totalPages,
    currentPage,
    setCurrentPage,
}: iPageCounter) => {
    const { uploadId } = useParams();
    const [newCaseId, setNewCaseId] = useState<number>(0);
    const [action, actionSelect] = useState<number>(0);
    const [selectedCase, setSelectedCase] = useState<{
        [key: string]: any;
    } | null>(null);
    const [selectCaseDebts, setSelectCaseDebts] = useState<{
        [key: string]: any;
    } | null>(null);
    const [selectedDebtId, setSelectedDebtId] = useState<number | null>(null);
    const [notes, setNotes] = useState("");

    const getCaseIdList = useFetchData({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_API}/case-serach-by-id/${newCaseId}`,
    });

    const assignToCase = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/assign-case`,
        },
        {
            notifyOnSuccess: true,
        }
    );
    const getDebtors = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/case-debt_id/${selectedCase?.case_id}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setSelectCaseDebts(data?.data || []);
            },
        }
    );
    const testCaseList = getCaseIdList?.data?.data?.case_search || [];
    const caseIdList = testCaseList || [];
    const debtList = selectedCase?.debts || [];
    const isFormValid = !!selectedCase?.case_id && !!selectedDebtId;

    useEffect(() => {
        if (selectedCase?.case_id)
            setTimeout(() => getDebtors.fetchData(), 100);
    }, [selectedCase?.case_id]);

    const searchResultHandler = (option: { [key: string]: any }) => {
        // console.log(option);
        setSelectedCase(option || null);
        if (option?.case_id) setNewCaseId(option.case_id);
    };

    const searchCaseIdHandler = () => {
        getCaseIdList.fetchData({ id: newCaseId });
    };
    const searchDebtResultHandler = (key: string, value: number) => {
        setSelectedDebtId(value);
    };
    const assignToCaseHandler = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(uploadId);
        if (uploadId && selectedDebtId && selectedCase?.case_id) {
            const requestData = {
                data: {
                    upload_id: +uploadId,
                    case_id: selectedCase?.case_id,
                    debt_id: selectedDebtId,
                    notes,
                    action,
                    currentPage,
                },
            };
            console.log(requestData);
            assignToCase.fetchData(requestData);
        }
    };
    const resetHandler = () => {
        setNewCaseId(0);
        setSelectedCase(null);
        setNotes("");
    };

    return (
        <StyledPageCounter className="page-counter">
            <div className="header">
                <h4>
                    Page {currentPage} of {totalPages}
                </h4>
                <Row gap="8px" justify-content="flex-end">
                    {totalPages > 1 ? (
                        <>
                            <Button
                                btnText="<"
                                onClick={() =>
                                    setCurrentPage((prev) => prev - 1)
                                }
                                style="purple"
                                disabled={currentPage <= 1}
                            />
                            <Button
                                btnText=">"
                                onClick={() =>
                                    setCurrentPage((prev) => prev + 1)
                                }
                                style="purple"
                                disabled={currentPage >= totalPages}
                            />
                        </>
                    ) : null}
                </Row>
            </div>
            <div className="body">
                <ButtonInput
                    placeholder="Search by id..."
                    name="caseId"
                    onClick={searchCaseIdHandler}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setNewCaseId(e.target.valueAsNumber)
                    }
                    value={newCaseId}
                    label="Case ID"
                    type="number"
                    btnText="Search"
                />
                <DropdownSelect
                    label="Search Results:"
                    placeholder={listCountText(caseIdList)}
                    options={caseIdList}
                    value={selectedCase?.case_id || null}
                    optionKey="case_id"
                    labelKeys={["applicant_name"]}
                    onSelect={searchResultHandler}
                    hasDefaultValue
                    isLoading={getCaseIdList.isLoading}
                />
                {selectedCase && (
                    <form onSubmit={assignToCaseHandler}>
                        <DropdownSelect
                            label="Debts:"
                            placeholder={listCountText(debtList)}
                            options={selectCaseDebts?.case_debts}
                            value={selectedDebtId}
                            optionKey="cdbt_id"
                            labelKeys={["creditor_name"]}
                            onSelect={(option) =>
                                searchDebtResultHandler("id", option.cdbt_id)
                            }
                            isLoading={getCaseIdList.isLoading}
                        />

                        <DropdownSelect
                            className="input-fields__type"
                            label="Action:"
                            value={action}
                            options={mailUploadActionOptions}
                            onSelect={(data) => actionSelect(data.value)}
                        />

                        <TextArea
                            label="Notes:"
                            name="notes"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />

                        <div className="btns">
                            <Button
                                btnText="Reset"
                                style="cancel"
                                type="button"
                                onClick={resetHandler}
                            />
                            <Button
                                btnText="Submit"
                                style="mint"
                                type="submit"
                                disabled={!isFormValid}
                            />
                        </div>
                    </form>
                )}
            </div>
        </StyledPageCounter>
    );
};

export default PageCounter;
