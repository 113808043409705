import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "../../../components/DataTable";
import Header from "../../../layout/Header";
import useFetchData from "../../../global/hooks/useFetchData";
import { useEffect } from "react";
import { LoadingSpinner } from "../../../components/Button/styles";
import OpenCaseClient from "../../../components/DataTable/actions/OpenCaseClient";
import SearchPageContent from "./styles";
import SearchRow from "../../../components/SearchRow";

const Search = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const searchParams = new URLSearchParams(search);
    const q = searchParams.get("q") || "";

    const getClientsList = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/client-search`,
        },
        {
            params: { q },
        }
    );

    useEffect(() => {
        getClientsList.fetchData();
    }, [q]);
    const tableKeyHeadings = {
        id: "Client ID",
        customer_name: "Name",
        postcode: "Address",
        email_phone: "Email / Telephone",
        next_pmnt_in_dt: "Next Payment Date",
        pmntin_frqncy: "Payment Frequency",
        case_advisor: "Case Advisor",
        status: "Status",
    };

    const rowClickHandler = (data: { [key: string]: string }) => {
        window.open(
            `/client-case/${data.id}/summary`,
            "_blank"
            // "directories=no"
        );
    };

    return (
        <>
            <Header title="Search" prevPath="/"></Header>
            {getClientsList.isLoading ? (
                <LoadingSpinner size={40} />
            ) : (
                <SearchPageContent>
                    <SearchRow
                        value={searchTerm}
                        setValue={setSearchTerm}
                        placeholder="Filter by id or name..."
                    />
                    <DataTable
                        labels={Object.values(tableKeyHeadings)}
                        keys={Object.keys(tableKeyHeadings)}
                        data={getClientsList?.data?.data}
                        onRowClick={rowClickHandler}
                        searchTerm={searchTerm}
                        searchKeys={["id", "customer_name"]}
                        formatKeys={{
                            status: "html",
                            next_pmnt_in_dt: "date",
                        }}
                    />
                </SearchPageContent>
            )}
        </>
    );
};

export default Search;
