import moment from "moment";
import { LoadingSpinner } from "../Button/styles";

import StyledTimeTable from "./styles";
import TimeTableCard from "./TimeTableCard";
import TimeTableRow from "./TimeTableRow";

interface TimeTableProps {
    className?: string;
    data?: { [key: string]: any }[];
    isLoading?: boolean;
    onCardSelect?: (...args: any[]) => void;
    minHour?: number;
    maxHour?: number;
}

const TimeTable: React.FC<TimeTableProps> = ({
    className = "",
    data = [],
    isLoading = false,
    onCardSelect = () => {},
    minHour = 0,
    maxHour = 24,
}) => {
    const hoursArray = Array(maxHour - minHour)
        .fill("")
        .map((_, i) => i + minHour);

    const hourData = (
        hour: number,
        key: string = "whn",
        format: string = "YYYY-MM-DD HH:mm:ss"
    ) => {
        return data
            .filter((obj) => {
                const objHour = moment(obj[key], format).hour();
                return objHour === hour;
            })
            .sort((a, b) => a.whn.localeCompare(b.whn));
    };
    // if (isLoading) return <LoadingSpinner size={40} />;

    return (
        <StyledTimeTable
            className={`time-table${className ? ` ${className}` : ""}`}
            aria-disabled={isLoading}
        >
            {isLoading && <LoadingSpinner className="spinner" size={40} />}
            {hoursArray.map((hr) => {
                return (
                    <TimeTableRow
                        key={hr}
                        hour={hr}
                        expandable={hourData(hr)?.length > 3}
                    >
                        {hourData(hr).map((hrData, i) => (
                            <TimeTableCard
                                key={hrData?.id || i}
                                data={hrData}
                                autoHide={hourData(hr)?.length > 3}
                                isFirstCard={data[0]?.id === hrData?.id}
                                onClick={onCardSelect.bind(null, hrData)}
                            ></TimeTableCard>
                        ))}
                    </TimeTableRow>
                );
            })}
        </StyledTimeTable>
    );
};

export default TimeTable;
