import React, { ChangeEvent, useEffect, useState, SetStateAction } from "react";
import Button from "../../../Button";
import Input from "../../../Input";
import { StyledLeadStatusModal } from "./styled";
import ModalHeader from "../../components/ModalHeader";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import ModalButtons from "../../components/ModalButtons";
import DropdownSelect from "../../../DropdownSelect";
import { leadStatusGroupOptions } from "../../../DropdownSelect/options";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";
import BasicModal from "../../BasicModal";

interface LeadStatusModalProps {
    closeModal: (...args: any[]) => void;
    open: boolean;
    data: { [key: string]: any };
}

const EditLeadStatusModal = ({
    closeModal,
    open,
    data,
}: LeadStatusModalProps) => {
    const { setOther } = useReduxSettings();
    const [isDeleting, setIsDeleting] = useState(false);
    const [leadSettings, setLeadSettings] = useState<{ [key: string]: any }>({
        system_case_status_name: data?.system_case_status_name || "",
        system_case_status_group:
            data?.system_case_status_group || leadStatusGroupOptions[0].value,
        color: data?.color || "#000000",
    });

    const updateSetting = useFetchData(
        {
            method: "put",
            url: `${process.env.REACT_APP_SERVER_API}/settigs-lead-status/${data?.system_case_status_id}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setOther("leadStatus", data.data);
            },
        }
    );

    const deleteSetting = useFetchData(
        {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_API}/settigs-lead-status/${data?.system_case_status_id}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setOther("leadStatus", data.data);
            },
        }
    );

    const isSubmitSuccess = updateSetting.isSuccess || deleteSetting.isSuccess;
    const isFormValid = leadSettings.system_case_status_name.trim() !== "";

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setLeadSettings((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const handleSelect = (key: string, value: any) => {
        setLeadSettings((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const deleteHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (data?.system_case_status_id) deleteSetting.fetchData();
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        updateSetting.fetchData({ lead: leadSettings });
    };

    if (isDeleting)
        return (
            <BasicModal
                isLoading={deleteSetting.isLoading}
                isSuccess={deleteSetting.isSuccess}
                message={`Are you sure you want to delete "${
                    data?.system_case_status_name ?? ""
                }"?`}
                successMessage="Lead managment status has been deleted"
                onClose={
                    deleteSetting.isSuccess
                        ? closeModal
                        : () => setIsDeleting(false)
                }
                isOpen={open}
                onSubmit={deleteHandler}
                submitBtnColor="red"
                submitBtnText="Delete"
            />
        );

    return (
        <StyledLeadStatusModal show={open} className="lead-status-modal">
            <ModalHeader onClose={closeModal}>Lead Status Setting</ModalHeader>
            {isSubmitSuccess ? (
                <SuccessContent
                    message={
                        updateSetting.isSuccess
                            ? "Changes has been saved"
                            : "Setting has been deleted"
                    }
                    onClose={closeModal}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <p>
                        ID: <span>{data?.system_case_status_id || "-"}</span>
                    </p>
                    <Input
                        type="text"
                        name="system_case_status_name"
                        label="Name"
                        value={leadSettings?.system_case_status_name || ""}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <DropdownSelect
                        label="Grouped By:"
                        options={leadStatusGroupOptions}
                        value={leadSettings.system_case_status_group}
                        onSelect={(option) =>
                            handleSelect(
                                "system_case_status_group",
                                option.value
                            )
                        }
                    />
                    <Input
                        type="color"
                        name="color"
                        label="Colour"
                        value={leadSettings?.color || ""}
                        onChange={handleChange}
                    />
                    <ModalButtons onDelete={() => setIsDeleting(true)}>
                        <Button
                            type="submit"
                            btnText="Save Changes"
                            style="purple"
                            loading={updateSetting.isLoading}
                            disabled={!isFormValid || deleteSetting.isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledLeadStatusModal>
    );
};

export default EditLeadStatusModal;
