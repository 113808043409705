import { ChangeEvent, SetStateAction } from "react";
import FormStepContainerHeader from "../../../../../atoms/FormStepContainerHeader";
import { Input, TextArea } from "../../../../../components";
import { Column } from "../../../../../global/theme/reuseable/components";
import { FormContainerWrapper } from "../../../../../containers/User/Client/styles";
import AddressSearch from "../../../../../components/Input/AddressSearch";
import { StyledAddressCtn } from "./styles";

export interface iAddress {
    title?: string;
    address: { [key: string]: any };
    setAddress: React.Dispatch<SetStateAction<{ [key: string]: any }[]>>;
}

const AddressCtn = ({
    title = "Current Address",
    address,
    setAddress,
}: iAddress) => {
    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setAddress((prev) => {
            return {
                ...prev,
                [name]: name === "postcode" ? value.toUpperCase() : value,
            };
        });
    };

    const addressSelectHandler = (
        doorNumber: string,
        address: string,
        postcode: string
    ) => {
        setAddress((prev) => {
            return { ...prev, door: doorNumber, full: address, postcode };
        });
    };

    return (
        <StyledAddressCtn title={title} headerStyle="gradientBlue">
            <Input
                type="number"
                onChange={handleChange}
                value={address?.door || ""}
                placeholder="e.g. 20"
                name="door"
                label="Door Number:"
            />
            <AddressSearch
                postcode={address?.postcode || ""}
                onPostcodeChange={handleChange}
                onSelect={addressSelectHandler}
            />
            <TextArea
                onChange={handleChange}
                value={address?.full || ""}
                placeholder="Address"
                name="full"
                label="Address"
            />
        </StyledAddressCtn>
    );
};

export default AddressCtn;
