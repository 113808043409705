import styled from "styled-components";
import { theme } from "../../../../global/theme";

export const StyledAdminEdit = styled.form`
    height: 100%;
`;

export const EditWrapper = styled.div`
    margin-bottom: 24px;

    h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #474747;
        margin-bottom: 12px;
    }
    > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 900px;
        gap: 12px 24px;

        & input {
            height: 40px;
        }
    }
    &:last-of-type {
        & > div {
            & > button {
                align-self: flex-end;
                justify-self: flex-end;
                height: 40px;
                width: 120px;
            }
        }
    }
`;

export const StyledAccountPermissions = styled.section`
    & .permission-checkboxes {
        max-width: 1100px;
        &__group {
        }

        &__ctn {
            display: grid;
            row-gap: 0;
            column-gap: 24px;
            background-color: ${theme.colors.white};
            border-radius: 8px;
            padding: 8px;
            border: 0.6px solid ${theme.colors.shade};
            grid-template-columns: repeat(auto-fit, minmax(470px, 1fr));
        }
    }

    & .checkbox-group {
        font-size: 14px;
        padding: 6px;
        background-color: transparent;

        /* background-color: ${theme?.colors.white}; */
        &__legend {
            flex-grow: 1;
            font-size: 14px;
            font-weight: 400;
            color: ${theme?.colors.text};
            font-size: 12px;
        }

        &__ctn {
            font-size: 12px;
        }
        & input {
            height: 18px;
            width: 18px;
        }
    }
`;
