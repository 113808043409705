import { ChangeEvent, useEffect, useState } from "react";
import { TextArea } from "../../../../../components";
import StyledAdditionalInfo from "./styles";
import { ClientFormStepProps } from "../ClientForm";
import useFetchData from "../../../../../global/hooks/useFetchData";
import useReduxIntroducers from "../../../../../store/hooks/useReduxIntroducers";
import DropdownSelect from "../../../../../components/DropdownSelect";
import ClientFormStepButtons from "../ClientFormStepButtons";
import useReduxCreateClient from "../../../../../store/hooks/useReduxCreateClient";
import { useParams } from "react-router-dom";
import HeaderContainer from "../../../../../containers/HeaderContainter";

const AdditionalInfo: React.FC<ClientFormStepProps> = ({
    isFirstStep,
    isLastStep,
    setCurrentStep,
}) => {
    const { setData, data, setTypes } = useReduxIntroducers();
    const { setDataByKey, additional_info } = useReduxCreateClient();
    const { clientId } = useParams();
    const [additionalInfo, setAdditionalInfo] = useState<{
        [key: string]: any;
    }>({});

    const introducers = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/introducer`,
        },
        {
            onSuccess: (data) => {
                setData(data?.data || []);
                setTypes(data?.types || []);
            },
            fetchOnLoad: true,
        }
    );

    const { fetchData, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client`,
        },
        {
            onSuccess: (data) => {
                setCurrentStep((prev) => prev + 1);
                setDataByKey("additional_info", data?.data?.additional_info);
            },
            notifyOnSuccess: true,
        }
    );

    useEffect(() => {
        if (additional_info) setAdditionalInfo(additional_info);
    }, [additional_info]);

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (clientId) {
            const requestData = {
                id: +clientId,
                tab: "additional_info",
                data: { additional_info: additionalInfo },
            };
            fetchData(requestData);
        }
    };

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setAdditionalInfo((prev) => {
            return { ...prev, [name]: value };
        });
    };

    return (
        <StyledAdditionalInfo
            className="client-form__content"
            onSubmit={submitHandler}
        >
            <HeaderContainer title="Additional Info:">
                <DropdownSelect
                    label="Select Introducer"
                    optionKey="id"
                    options={data || []}
                    value={additionalInfo?.sls_ld}
                    labelKeys={["nme"]}
                    isLoading={introducers.isLoading}
                    enableInput
                    onSelect={(value) =>
                        setAdditionalInfo((prev) => {
                            return { ...prev, sls_ld: value?.id };
                        })
                    }
                />

                <TextArea
                    name="slsld_dtls"
                    label="Financial Goals"
                    value={additionalInfo?.slsld_dtls || ""}
                    onChange={handleChange}
                />

                <TextArea
                    name="nts"
                    label="Case Notes"
                    value={additionalInfo?.nts || ""}
                    onChange={handleChange}
                />
            </HeaderContainer>
            <ClientFormStepButtons
                onPrevClick={() => setCurrentStep((prev) => prev - 1)}
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                isSubmitting={isLoading}
            />
        </StyledAdditionalInfo>
    );
};

export default AdditionalInfo;
