import { BusinessAccountancyContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";

const TaxFilerRef: React.FC<BusinessAccountancyContainerProps> = ({
    formData,
    onInputChange,
}) => {
    return (
        <HeaderContainer title="Tax Filer Reference:">
            <Input
                type="text"
                label="Reference Number"
                value={formData?.tax_filer_ref_number || ""}
                onChange={onInputChange}
                name="tax_filer_ref_number"
            />
        </HeaderContainer>
    );
};

export default TaxFilerRef;
