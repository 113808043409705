import React, { useState, useEffect } from "react";
import { StyledEditNotes } from "./styles";
import useReduxDiary from "../../../store/hooks/useReduxDiary";
import DropdownSelect from "../../DropdownSelect";
import TextArea from "../../Input/TextArea";
import { Row } from "../../../global/theme/reuseable/components";
import Button from "../../Button";
import useFetchData from "../../../global/hooks/useFetchData";
import moment from "moment";

interface EditNotesProps {
    data: { [key: string]: any };
    onCreateSuccess: (...args: any[]) => void;
    isUpdated?: boolean;
    isUpdating?: boolean;
}

const EditNotes: React.FC<EditNotesProps> = ({
    data,
    onCreateSuccess,
    isUpdated = false,
    isUpdating = false,
}) => {
    const notesData = data?.get_notes as { [key: string]: any }[];
    const { users } = useReduxDiary();
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [actIds, setActIds] = useState<number[]>([]);
    const [notes, setNotes] = useState("");

    useEffect(() => {
        if (isUpdated) setIsCreatingNew(false);
    }, [isUpdated]);

    const createNote = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/diary-create-note`,
        },
        {
            onSuccess: (data) => {
                onCreateSuccess(data);
                setNotes("");
                setActIds([]);
            },
        }
    );

    console.log();

    const createModeHandler = () => {
        setIsCreatingNew((prev) => !prev);
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const requestData = {
            task_id: data?.id,
            case_id: data?.case_id,
            whm: actIds,
            notes,
        };
        console.log(requestData);
        createNote.fetchData(requestData);
    };

    return (
        <StyledEditNotes className="edit-notes">
            {!isCreatingNew && (
                <button
                    className="edit-notes__create"
                    type="button"
                    onClick={createModeHandler}
                >
                    {isCreatingNew ? "See All Notes" : "+ Create Note"}
                </button>
            )}
            {isCreatingNew ? (
                <form className="edit-notes__form" onSubmit={submitHandler}>
                    <DropdownSelect
                        className=""
                        label="Assign to:"
                        values={actIds}
                        setValues={setActIds}
                        options={users || []}
                        optionKey="act_id"
                        labelKeys={["whm"]}
                        enableInput
                    />
                    <TextArea
                        label="Notes:"
                        name="notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                    <Row justify-content="flex-end" className="nav" gap="12px">
                        <Button
                            btnText={"Create"}
                            type="submit"
                            style="purple"
                            loading={createNote.isLoading || isUpdating}
                            disabled={!actIds.length || !notes}
                        />
                        <Button
                            type="button"
                            btnText="Cancel"
                            onClick={createModeHandler}
                            style="cancel"
                        />
                    </Row>
                </form>
            ) : notesData?.length ? (
                <ul className="edit-notes__cards">
                    {notesData.map((note) => (
                        <li key={note.id} className="edit-notes__card">
                            <h3>
                                {note?.whm_name || "Unknown User"}{" "}
                                <span>
                                    {note?.updated_at
                                        ? moment(note?.updated_at).format(
                                              "DD MMM, h:mma"
                                          )
                                        : "Unknow Date"}
                                </span>
                            </h3>
                            <p>{note?.notes || "(No Message)"}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="edit-notes__empty">No Notes Created.</p>
            )}
        </StyledEditNotes>
    );
};

export default EditNotes;
