import React from "react";
import { useAppSelector, useAppDispatch } from ".";
import { introducersActions } from "../slices/introducersSlice";
import { IntroducersState } from "../slices/introducersSlice";

const useReduxIntroducers = () => {
    const { types, data } = useAppSelector((state) => state.introducers);
    const dispatch = useAppDispatch();

    const setTypes = (data: { [key: string]: any }[]) => {
        dispatch(introducersActions.setTypes(data));
    };

    const setData = (data: { [key: string]: any }[]) => {
        dispatch(introducersActions.setData(data));
    };

    return {
        types,
        setTypes,
        data,
        setData,
    };
};

export default useReduxIntroducers;
