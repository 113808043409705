import Button from "../../Button";
import { useParams } from "react-router";
import ModalHeader from "../components/ModalHeader";
import { StyledCreditorRating } from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";

interface CreditorRatingProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
}


const CreditorRating: React.FC<CreditorRatingProps> = ({ isOpen, onClose }) => {
    const { clientId } = useParams();
    const postCreditorAction = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/creditor-rating/${clientId}`,
    });

    const actionHandler = (actionType: string) => {
        alert(actionType);
        
    };
    
    return (
        <StyledCreditorRating className="creditor-rating" show={isOpen}>
            <ModalHeader onClose={onClose}>Creditor Rating</ModalHeader>
            <div className="content">
                <p>The following information is overdue</p>

                <div className="info-ctn">
                    Balance Confirmed
                    <Button
                        style="white"
                        btnText="Action"
                        onClick={() => actionHandler("balance-confirmed")}
                    />
                </div>
                <div className="info-ctn">
                    Proposal Sent
                    <Button
                        style="white"
                        btnText="Action"
                        onClick={() => actionHandler("proposal-sent")}
                    />
                </div>
                <div className="info-ctn">
                    Proposal Accepted
                    <Button
                        style="white"
                        btnText="Action"
                        onClick={() => actionHandler("proposal-accepted")}
                    />
                </div>
                <div className="info-ctn">
                    Standing Order Sent
                    <Button
                        style="white"
                        btnText="Action"
                        onClick={() => actionHandler("standing-order-sent")}
                    />
                </div>
            </div>
        </StyledCreditorRating>
    );
};

export default CreditorRating;
