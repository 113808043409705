import React, { ChangeEvent, SetStateAction, useState, useEffect } from "react";

import { StyledAdminLedgerModal } from "./styles";
import ModalButtons from "../components/ModalButtons";
import Input from "../../Input";
import Button from "../../Button";
import ModalHeader from "../components/ModalHeader";
import DropdownSelect from "../../DropdownSelect";
import { ledgerTypeOptions } from "../../DropdownSelect/options";
import TextArea from "../../Input/TextArea";
import SuccessContent from "../components/SuccessContent";
import useFetchData from "../../../global/hooks/useFetchData";
interface AdminLedgerModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const AdminLedgerModal: React.FC<AdminLedgerModalProps> = ({
    isOpen,
    setIsOpen,
}) => {
    const [clientId, setClientId] = useState<number | null>(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false);
    const [ledgerData, setLedgerData] = useState<{ [key: string]: any }>({
        payment_ref: "",
        reason: "",
        amount: 0,
        type: null,
        notes: "",
    });
    const { data } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/case-ids`,
        },
        { fetchOnLoad: true }
    );
    const paymentData = data?.data;
    const isPaymentRefValid = !!ledgerData?.payment_ref;
    const isTypeValid = !!ledgerData?.type;
    const isFormValid = isPaymentRefValid && isTypeValid;

    const closeModal = () => setIsOpen(false);

    const selectHandler = (key: string, value: number) => {
        if (key in ledgerData)
            setLedgerData((prev) => {
                return { ...prev, [key]: value };
            });
    };

    const addPayment = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/add-payment-details/${clientId}`,
        },
        {
            onSuccess: (data) => {
                setIsSuccess(true);
            },
        }
    );

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type } = e.target;
        setLedgerData((prev) => {
            return { ...prev, [name]: type === "number" ? +value : value };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (!currentStep) return setCurrentStep((prev) => prev + 1);

        addPayment.fetchData({ data: ledgerData });
    };

    return (
        <StyledAdminLedgerModal
            show={isOpen}
            className="update-ledger"
            isSuccess={isSuccess}
            width="560px"
        >
            <ModalHeader onClose={closeModal}>Create Ledger</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="Ledger has been updated"
                    onClose={closeModal}
                />
            ) : (
                <form className="update-ledger__form" onSubmit={submitHandler}>
                    {currentStep === 0 ? (
                        <>
                            <DropdownSelect
                                label="Select Client Case / Client Name"
                                options={paymentData}
                                labelKeys={["id", "label"]}
                                optionKey="id"
                                enableInput
                                value={clientId}
                                onSelect={(data) => setClientId(data.id)}
                                className="update-ledger__client-select"
                            />
                        </>
                    ) : (
                        <div className="update-ledger__fields">
                            <Input
                                type="text"
                                label="Payment Refernece:"
                                name="payment_ref"
                                value={ledgerData?.payment_ref}
                                onChange={handleChange}
                                placeholder="Enter payment reference..."
                            />
                            <DropdownSelect
                                label="Type"
                                options={ledgerTypeOptions}
                                value={ledgerData?.type || null}
                                onSelect={(data) =>
                                    selectHandler("type", data.value)
                                }
                                className="update-ledger__type"
                                enableInput
                            />

                            <Input
                                type="number"
                                label="Amount:"
                                name="amount"
                                value={ledgerData?.amount.toString()}
                                onChange={handleChange}
                            />

                            <TextArea
                                label="Reason:"
                                name="reason"
                                value={ledgerData?.reason}
                                onChange={handleChange}
                                placeholder="Enter reason..."
                            />
                            <TextArea
                                label="Notes:"
                                name="notes"
                                value={ledgerData?.notes}
                                onChange={handleChange}
                            />
                        </div>
                    )}
                    <ModalButtons>
                        <Button
                            key={currentStep}
                            style="purple"
                            btnText={currentStep === 0 ? "Next" : "Create"}
                            type="submit"
                            loading={false}
                            disabled={
                                currentStep === 0 ? !clientId : !isFormValid
                            }
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledAdminLedgerModal>
    );
};

export default AdminLedgerModal;
