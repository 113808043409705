import BasicModal from "../BasicModal";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxClientComplaints from "../../../store/hooks/useReduxClientComplaints";

interface DeleteComplaintModalProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    id: number;
}

const DeleteComplaint: React.FC<DeleteComplaintModalProps> = ({
    isOpen,
    onClose,
    id,
}) => {
    const { setComplaints } = useReduxClientComplaints();
    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/delete-complaints/${id}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setComplaints(data.data?.complaints || []);
            },
            notifyOnSuccess: true,
            notifySuccessMessage: "Complaint deleted.",
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (id) {
            fetchData();
        }
    };
    return (
        <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            message={`Are you sure you want to delete this complaint?`}
            submitBtnColor="red"
            submitBtnText="Delete"
            successMessage={`Complaint has been deleted.`}
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSubmit={submitHandler}
        />
    );
};

export default DeleteComplaint;
