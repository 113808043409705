import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../../layout/Header";
import StyledPayeRebateContent from "./styles";
import useFetchData from "../../../../global/hooks/useFetchData";
import { StyledBaseDetailsSubmitCtn } from "../../BaseDetails/styles";
import { Button, Modal } from "../../../../components";
import SendRebateBreakdown from "../../../../components/Modals/ClientProducts/SendRebateBreakdown";
import SendClientRebateForm from "../../../../components/Modals/ClientProducts/SendClientRebateForm";
import SendHMRCSignedForm from "../../../../components/Modals/ClientProducts/SendHMRCSignedForm";
import PersonalInformation from "./PersonalInformation";
import KeyEvents from "./KeyEvents";
import Funds from "./Funds";
import Payout from "./Payout";
import Other from "./Other";
import SalesAdvisor from "./SalesAdvisor";
import Gateways from "./Gateways";
import Questionnaire from "./Questionnaire";
import Expenses from "./Expenses";
import DropdownMenu from "../../../../components/DropdownMenu";
import DropdownMeunItem from "../../../../components/DropdownMenu/DropdownMenuItem";

export interface PayeRebateContainerProps {
    onInputChange: (...args: any[]) => void;
    onSelect: (...args: any[]) => void;
    payeRebate: { [key: string]: any };
}

const initialPageRebate = {
    ni: "",
    utr: "",
    pcksnt: "",
    pckbck: "",
    "fnds-rcivd-amt": 0,
    "fnds-rcivd-whn": "",
    "fnds-rcivd-fee": 0,
    funds_vat: 0,
    funds_fees_vat: 0,
    "fnds-payout-amt": 0,
    "fnds-payout-whn": "",
    nts: "",
    stts: null,
    "rebate-sales": null,
    "gateway-username": "",
    "gateway-psswrd": "",
    "utrgateway-username": "",
    "utrgateway-psswrd": "",
    "question-travel": null,
    "question-uniform": null,
    "question-fees": null,
    "question-equip": null,
    "question-home": null,
    "question-partner": null,
    "question-sector": "",
    "question-employer": "",
    job_expense_q1: "",
    job_expense_q2: "",
    job_expense_q3: "",
    job_expense_q4: "",
    job_expense_a1: "",
    job_expense_a2: "",
    job_expense_a3: "",
    job_expense_a4: "",
    vehicle_q1: "",
    vehicle_q2: "",
    vehicle_q3: "",
    vehicle_q4: "",
    vehicle_a1: "",
    vehicle_a2: "",
    vehicle_a3: "",
    vehicle_a4: "",
    travel_q1: "",
    travel_q2: "",
    travel_q3: "",
    travel_q4: "",
    travel_a1: "",
    travel_a2: "",
    travel_a3: "",
    travel_a4: "",
    professional_q1: "",
    professional_q2: "",
    professional_q3: "",
    professional_q4: "",
    professional_a1: "",
    professional_a2: "",
    professional_a3: "",
    professional_a4: "",
    wfh_q1: "",
    wfh_q2: "",
    wfh_q3: "",
    wfh_q4: "",
    wfh_a1: "",
    wfh_a2: "",
    wfh_a3: "",
    wfh_a4: "",
    equipment_q1: "",
    equipment_q2: "",
    equipment_q3: "",
    equipment_q4: "",
    equipment_a1: "",
    equipment_a2: "",
    equipment_a3: "",
    equipment_a4: "",
};

const PayeRebate = () => {
    const { clientId } = useParams();
    const [currentModal, setCurrentModal] = useState("");
    const [payeRebate, setPayeRebate] = useState<{ [key: string]: any }>(
        initialPageRebate
    );
    // const feeAmountVat =
    //     +(payeRebate?.funds_fees || 0) + +(payeRebate?.funds_vat || 0);

    const getPayeRebate = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/paye-rebate/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setPayeRebate(data.data?.paye_rebate || []);
            },
        }
    );
    const updatePayeRebate = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/paye-rebate/${clientId}`,
        },
        {
            notifyOnSuccess: true,
            onSuccess: (data) => {
                if (data?.data) setPayeRebate(data.data?.paye_rebate || []);
            },
        }
    );

    // useEffect(() => {
    //     setPayeRebate((prev) => {
    //         return { ...prev, funds_fees_vat: feeAmountVat };
    //     });
    // }, [feeAmountVat]);

    const inputHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value, name, type } = e.target;
        setPayeRebate((prev) => {
            return { ...prev, [name]: type === "number" ? +value : value };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setPayeRebate((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const containerProps: PayeRebateContainerProps = {
        onInputChange: inputHandler,
        onSelect: selectHandler,
        payeRebate,
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(payeRebate);
        updatePayeRebate.fetchData({ data: payeRebate });
    };

    return (
        <>
            <Modal isVisible={currentModal === "rebate-breakdown"}>
                <SendRebateBreakdown
                    isOpen={currentModal === "rebate-breakdown"}
                    onClose={() => setCurrentModal("")}
                />
            </Modal>
            <Modal isVisible={currentModal === "client-rebate"}>
                <SendClientRebateForm
                    isOpen={currentModal === "client-rebate"}
                    onClose={() => setCurrentModal("")}
                />
            </Modal>
            <Modal isVisible={currentModal === "hmrc"}>
                <SendHMRCSignedForm
                    isOpen={currentModal === "hmrc"}
                    onClose={() => setCurrentModal("")}
                />
            </Modal>
            <Header
                title="Paye Rebate"
                hasNavBarAbove
                isSubPath
                hasPrevPath
            ></Header>
            <StyledPayeRebateContent>
                <form onSubmit={submitHandler}>
                    <PersonalInformation {...containerProps} />
                    <KeyEvents {...containerProps} />
                    <Funds {...containerProps} />
                    <Payout {...containerProps} />
                    <Other {...containerProps} />
                    <SalesAdvisor {...containerProps} />
                    <Gateways {...containerProps} />
                    <Questionnaire {...containerProps} />
                    <Expenses {...containerProps} />

                    <StyledBaseDetailsSubmitCtn className="submit-ctn">
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Save Paye Rebate"
                            loading={updatePayeRebate.isLoading}
                        />
                        <DropdownMenu title="Send" color="purple">
                            <DropdownMeunItem
                                elementType="button"
                                title="Rebate Breakdown"
                                onClick={() =>
                                    setCurrentModal("rebate-breakdown")
                                }
                                iconName="send"
                            />
                            <DropdownMeunItem
                                elementType="button"
                                title="Client Rebate Form"
                                onClick={() => setCurrentModal("client-rebate")}
                                iconName="send"
                            />
                            <DropdownMeunItem
                                elementType="button"
                                title="HMRC Signed Form"
                                onClick={() => setCurrentModal("hmrc")}
                                iconName="send"
                            />
                        </DropdownMenu>
                    </StyledBaseDetailsSubmitCtn>
                </form>
            </StyledPayeRebateContent>
        </>
    );
};

export default PayeRebate;
