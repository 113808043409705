import styled from "styled-components";
import { theme } from "../../global/theme";
import { mainScrollbar } from "../../global/theme/reuseable/mixins";

const minOptionHeight = 30.6;

interface StyledDropdownSelectProps {
    size?: "s" | "m";
}

const StyledDropdownSelect = styled.div<StyledDropdownSelectProps>`
    display: flex;
    flex-direction: column;
    & .dropdown-select {
        &__label {
            font-size: 12px;
            font-weight: 300;
            margin-right: auto;
            margin-bottom: 4px;
        }

        &__field {
            background-color: ${theme?.colors.white};
            position: relative;
            display: flex;
            align-items: center;
            gap: 4px;
            height: ${(props) => (props.size === "m" ? "40px" : "30px")};
            width: 100%;
            border-radius: 6px;
            border: 0.6px solid ${theme?.colors.shade};

            &.focus {
                border-color: ${theme?.colors.purple};
            }

            &[aria-expanded="true"] {
                border-color: ${theme?.colors.boxStroke};
            }

            &[aria-disabled="true"] {
                pointer-events: none;
                opacity: 0.6;
                background-color: ${theme?.colors.background};
            }

            &[aria-invalid="true"] {
                border-color: #ef2020;
            }
        }

        &__input {
            background-color: transparent;
            border: none;
            outline: none;
            font-size: 11px;
            font-weight: 300;
            padding: 0 8px;
            flex: 1;
            &[readonly] {
                cursor: pointer;
            }

            &::placeholder {
                font-weight: 300 !important;
            }
        }

        &__btn {
            margin: 0;
            padding: 4px 0;
            height: 100%;
            width: 30px;
            border: none;
            border-radius: 4px;
            background-color: transparent;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            & > svg {
                transition: transform 0.2s ease;
                &[aria-expanded="true"] {
                    transform: rotate(180deg);
                }
            }
        }

        &__menu {
            position: absolute;
            top: calc(100% + 4px);
            left: 0;
            right: 0;
            list-style: none;
            background-color: ${theme?.colors.white};
            border-radius: 4px;
            z-index: 4;
            border: 0.6px solid ${theme?.colors.boxStroke};
            overflow-y: auto;
            ${mainScrollbar(12)};
            max-height: calc(${minOptionHeight}px * 5 + 1px);

            &[aria-hidden="true"] {
                display: none;
            }

            &__item {
                font-size: 12px;
                padding: 4px 6px;
                display: flex;
                align-items: center;
                min-height: ${minOptionHeight}px;
                font-weight: 300;
                cursor: pointer;

                &:hover {
                    background-color: ${theme?.colors.formBlue};
                }
                &[data-selected="true"] {
                    background-color: ${theme?.colors.highlightBlue};
                }

                &[role="group-header"] {
                    pointer-events: none;
                    font-weight: 500;
                    background-color: ${theme?.colors.background};
                }

                &:not(:last-child) {
                    border-bottom: 0.6px solid ${theme?.colors.shade};
                }
            }
        }

        &__tags {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            margin-block: 10px;
        }
        &__tag {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 300;
            height: auto;
            gap: 4px;
            color: ${theme?.colors.purple};
            background-color: ${theme?.colors.white};
            border-radius: 4px;
            padding: 4px;

            & > button {
                height: 100%;
                width: auto;
                margin: 0;
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                padding: 4px;

                & svg {
                    height: 10px;
                    width: 10px;

                    & > path {
                        fill: ${theme?.colors.purple};
                    }
                }

                &:hover {
                    background-color: ${theme?.colors.background};
                    & svg {
                        & > path {
                            fill: ${theme?.colors.error};
                        }
                    }
                }
            }
        }
    }
`;

export default StyledDropdownSelect;
