import React from "react";
import { CreateDocumentTemplateFormProps } from "../CreateDocumentTemplate";
import { StyledDocumentTemplateForm } from "./styles";
import { Input } from "../../../../../../components";
import TextEditor from "../../../../../../components/TextEditor";
import BodyVariablesSelect from "../../../../../../components/DropdownSelect/BodyVariablesSelect";

const EmailSettings: React.FC<CreateDocumentTemplateFormProps> = ({
    documentData,
    setDocumentData,
    inputHandler,
}) => {
    const textEditorHandler = (key: string, content: string) => {
        setDocumentData((prev) => {
            return { ...prev, [key]: content };
        });
    };

    return (
        <StyledDocumentTemplateForm className="message-form">
            <Input
                label="Subject:"
                type="text"
                value={documentData?.email_subject}
                name="email_subject"
                onChange={inputHandler}
            />
            <BodyVariablesSelect />
            <TextEditor
                label="Body:"
                value={documentData?.email_body}
                onChange={(content) => textEditorHandler("email_body", content)}
                name="email_body"
            />
        </StyledDocumentTemplateForm>
    );
};

export default EmailSettings;
