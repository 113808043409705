import React, { SetStateAction } from "react";
import TextArea from "../../../Input/TextArea";

interface LiabilityMiscProps {
    misc: { [key: string]: any };
    setMisc: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
}

const LiabilityMisc: React.FC<LiabilityMiscProps> = ({ misc, setMisc }) => {
    const inputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setMisc((prev) => {
            return { ...prev, [name]: value };
        });
    };

    return (
        <div className="liability-main__content liability-misc">
            <TextArea
                label="Notes:"
                value={misc?.notes || ""}
                name="notes"
                onChange={inputHandler}
            />
            <TextArea
                label="Transfer Logs:"
                value={misc?.transfer_logs || ""}
                name="transfer_logs"
                onChange={inputHandler}
                readOnly
                disabled
            />
        </div>
    );
};

export default LiabilityMisc;
