import { PayeRebateContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { TextArea } from "../../../../components";
import DropdownSelect from "../../../../components/DropdownSelect";
import { payeRebateStatusOptions } from "../../../../components/DropdownSelect/options";

const Other: React.FC<PayeRebateContainerProps> = ({
    onInputChange,
    onSelect,
    payeRebate,
}) => {
    return (
        <HeaderContainer
            title="Other:"
            headerStyle="gradientBlue"
            ctnClassName="col-2"
        >
            <TextArea
                label="Notes:"
                value={`${payeRebate?.nts || ""}`}
                name="nts"
                onChange={onInputChange}
            />
            <DropdownSelect
                label="Status:"
                value={payeRebate?.stts ?? null}
                options={payeRebateStatusOptions}
                onSelect={(option) => onSelect("stts", option?.value ?? null)}
                hasDefaultValue
            />
        </HeaderContainer>
    );
};

export default Other;
