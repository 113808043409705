import React, { SetStateAction } from "react";
import Input from "../../../Input";
import DropdownSelect from "../../../DropdownSelect";
import { accountHolderOptions } from "../../../DropdownSelect/options";

interface LiabilityDetailsProps {
    details: { [key: string]: any };
    originalCreditor: { [key: string]: any };
    setDetails: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
    data: { [key: string]: any };
}

const LiabilityDetails: React.FC<LiabilityDetailsProps> = ({
    details,
    setDetails,
    originalCreditor,
    data,
}) => {
    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = e.target;
        setDetails((prev) => {
            return {
                ...prev,
                [name]:
                    type === "number" ? (value === "" ? null : +value) : value,
            };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setDetails((prev) => {
            return { ...prev, [key]: value };
        });
    };

    return (
        <>
            {" "}
            <div className="liability-main__content liability-details">
                <div className="data-row">
                    Original Creditor and Ref:
                    <p>
                        {originalCreditor?.name || "-"}
                        <span>
                            {originalCreditor?.ref
                                ? ` (${originalCreditor?.ref})`
                                : ""}
                        </span>
                    </p>
                </div>
                <Input
                    type="text"
                    label="Account Reference:"
                    value={details?.account_reference || ""}
                    name="account_reference"
                    onChange={inputHandler}
                />
                <DropdownSelect
                    label="Responsibility:"
                    options={accountHolderOptions}
                    value={details?.account_holder_type_id ?? null}
                    onSelect={(option) =>
                        selectHandler("account_holder_type_id", option.value)
                    }
                />
                <hr />
                <Input
                    type="number"
                    label="Estimated Balance:"
                    value={`${details?.est_balance ?? ""}`}
                    name="est_balance"
                    onChange={inputHandler}
                    step={0.01}
                />
                <Input
                    type="number"
                    label="Confirmed Balance:"
                    value={`${details?.confirmed_balance ?? ""}`}
                    name="confirmed_balance"
                    onChange={inputHandler}
                    step={0.01}
                />
                <Input
                    type="number"
                    label="Active Balance:"
                    value={`${details?.active_balance ?? ""}`}
                    name="active_balance"
                    onChange={inputHandler}
                    step={0.01}
                />
                <Input
                    type="number"
                    label="Repayment Balance:"
                    value={`${details?.repayment_balance ?? ""}`}
                    name="repayment_balance"
                    onChange={inputHandler}
                    step={0.01}
                />
            </div>
        </>
    );
};

export default LiabilityDetails;
