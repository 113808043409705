import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Pagination, Navigation } from "swiper";
import { RemovableCard, ViewDetailsCard } from "../../../components";
import AccordionCard from "../../../components/Cards/AccordionCard";
import { Row } from "../../../global/theme/reuseable/components";
import {
    Break,
    DashboardLayout,
    Right,
    RightBottom,
    RightTop,
    RightBottomColumn,
} from "./styles";
import useReduxDashboard from "../../../store/hooks/useReduxDashboard";
import { LinearHeadingT } from "../../../global/theme/types";
import { LoadingSpinner } from "../../../components/Button/styles";

interface LayoutProps {
    leftIsLoading?: boolean;
    rightIsLoading?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
    leftIsLoading = true,
    rightIsLoading = true,
}) => {
    const { leftColumnData, rightColumnData } = useReduxDashboard();
    const activeUsers = rightColumnData?.active_users || [];
    const outstandingCorresObj = rightColumnData?.outstanding_corres || {};
    const outstandingCorresEntries = Object.entries(outstandingCorresObj) as [
        string,
        { [key: string]: any }
    ][];
    const headingColors: LinearHeadingT[] = ["blue", "orange", "green"];

    const breakpoints = {
        1024: {
            slidesPerView: 2,
            slidesPerGroup: 2,
        },
        1440: {
            slidesPerView: 3,
            slidesPerGroup: 3,
        },
        1800: {
            slidesPerView: 4,
            slidesPerGroup: 4,
        },
        2400: {
            slidesPerView: 5,
            slidesPerGroup: 5,
        },
    };

    const leftContent = () => {
        if (leftIsLoading) return <LoadingSpinner size={60} />;
        if (!leftColumnData?.length)
            return <p style={{ textAlign: "center" }}>No Data Found</p>;
        return leftColumnData.map((data, i) => (
            <RemovableCard
                key={`${data.heading}-${i}`}
                isEditing={false}
                title={data?.heading}
                value={data?.count}
                path={data?.link}
            />
        ));
    };

    return (
        <DashboardLayout>
            <Row className="left">
                <div className="left__wrapper">{leftContent()}</div>
            </Row>
            <Break></Break>
            {rightIsLoading ? (
                <LoadingSpinner size={60} />
            ) : (
                <Right>
                    <div className="right-ctn">
                        <RightTop>
                            <h2>Active Users</h2>
                            {activeUsers?.length ? (
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={24}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    loop
                                    navigation={true}
                                    breakpoints={breakpoints}
                                    modules={[Pagination, Navigation]}
                                    className="dashboard-layout__swiper"
                                >
                                    {activeUsers.map(
                                        (
                                            data: { [key: string]: any },
                                            i: number
                                        ) => (
                                            <SwiperSlide
                                                className="dashboard-layout__slide"
                                                key={i}
                                            >
                                                <AccordionCard
                                                    color="blue"
                                                    list={data?.users || []}
                                                    value={data?.count || "--"}
                                                    jobTitle={
                                                        data?.job_title || "---"
                                                    }
                                                    count={data?.count}
                                                />
                                            </SwiperSlide>
                                        )
                                    )}
                                </Swiper>
                            ) : null}
                        </RightTop>
                        <RightBottom>
                            <h2>Outstanding Correspondence</h2>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={24}
                                pagination={{
                                    clickable: true,
                                }}
                                loop
                                navigation={true}
                                breakpoints={breakpoints}
                                modules={[Pagination, Navigation]}
                                className="dashboard-layout__swiper"
                            >
                                {outstandingCorresEntries.map(
                                    ([name, obj], i) => (
                                        <SwiperSlide
                                            className="dashboard-layout__slide"
                                            key={i}
                                        >
                                            <RightBottomColumn>
                                                <Row>
                                                    <h3>
                                                        {name.split("-")[0]}
                                                    </h3>
                                                    {/* <Button
                                                btnText="Filter"
                                                onClick={() => alert("Filter")}
                                                style="purple"
                                                btnImg={icons?.whiteDownArrow}
                                                gap="10px"
                                            /> */}
                                                </Row>
                                                <div className="cards">
                                                    {Object.entries(obj).map(
                                                        ([cardName, data]) => (
                                                            <ViewDetailsCard
                                                                key={cardName}
                                                                btnText="View Details"
                                                                value={
                                                                    data?.count
                                                                }
                                                                path={
                                                                    data?.path
                                                                }
                                                                title={cardName}
                                                                headingColor={
                                                                    headingColors[
                                                                        i % 3
                                                                    ]
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            </RightBottomColumn>
                                        </SwiperSlide>
                                    )
                                )}
                            </Swiper>
                        </RightBottom>
                    </div>
                </Right>
            )}
        </DashboardLayout>
    );
};

export default Layout;
