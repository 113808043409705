import { SetStateAction, useState } from "react";
import { StyledPaymentRunModal } from "./styles";
import ModalHeader from "../components/ModalHeader";
import SuccessContent from "../components/SuccessContent";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import useFetchData from "../../../global/hooks/useFetchData";
import {
    WeekdaysTimes,
    initialWeekdaysTimes,
} from "../../DatePicker/DayTimeSelect/types";
import DayTimeSelect from "../../DatePicker/DayTimeSelect/DayTimeSelect";

interface SetPaymentRunModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const SetPaymentRunModal = ({ isOpen, setIsOpen }: SetPaymentRunModalProps) => {
    const [paymentRuns, setPaymentRuns] =
        useState<WeekdaysTimes>(initialWeekdaysTimes);
    const [isSuccess, setIsSuccess] = useState(false);
    const isSelectedValues = Object.values(paymentRuns).map(
        (obj) => obj.isSelected
    );
    const isFormValid = isSelectedValues.some((v) => v === true);
    const closeModal = () => setIsOpen(false);
    const paymentSchedule = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/payment-schedule`,
    });
    const getPaymentSchedule = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/payment-schedule`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => setPaymentRuns(data.data),
        }
    );
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        setPaymentRuns(paymentRuns);
        setIsSuccess(true);
        paymentSchedule.fetchData({ data: paymentRuns });
    };

    const selectDayHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setPaymentRuns((prev) => {
            return {
                ...prev,
                [name]: {
                    ...prev[name as keyof WeekdaysTimes],
                    isSelected: checked,
                },
            };
        });
    };

    const timeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPaymentRuns((prev) => {
            return {
                ...prev,
                [name]: {
                    ...prev[name as keyof WeekdaysTimes],
                    time: value,
                },
            };
        });
    };
    // {Object.entries(paymentRuns).map(([key, value], i) => {
    //     console.log("Current key:", key);
    //     console.log("Current value:", value);
    // })};
    return (
        <StyledPaymentRunModal show={isOpen} className="payment-run">
            <ModalHeader onClose={closeModal}>Set Payment Run</ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="Your payment run has been set"
                    onClose={closeModal}
                />
            ) : (
                <form className="payment-run__form" onSubmit={submitHandler}>
                    <ul className="payment-run__options">
                        {Object.entries(paymentRuns).map(([key, value], i) => (
                            <li key={key} className="payment-run__option">
                                <DayTimeSelect
                                    label={key}
                                    isSelected={value.isSelected}
                                    onSelect={selectDayHandler}
                                    time={value.time}
                                    onTimeChange={timeChangeHandler}
                                    name={key}
                                />
                            </li>
                        ))}
                    </ul>
                    <ModalButtons>
                        <Button
                            style="purple"
                            btnText="Submit"
                            type="submit"
                            loading={false}
                            disabled={!isFormValid}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledPaymentRunModal>
    );
};

export default SetPaymentRunModal;
