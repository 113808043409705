import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { orderBy } from 'lodash';


export interface DiaryState {
    creditors: Array<{[key: string]: any}>;
    users: Array<{[key: string]: any}>;
    diary: Array<{[key: string]: any}>;
}

const initialDiaryState: DiaryState = {
    diary: [],
    creditors: [],
    users: [],

}

export const diarySlice = createSlice({
    name: 'diary',
    initialState: initialDiaryState,
    reducers: {
        setDiary: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.diary = action.payload
        },
        setCreditors: (state, action: PayloadAction<{[key: string]: any}[]>) => {
            state.creditors = action.payload
        },
        setUsers: (state, action: PayloadAction<{[key: string]: any}[]>) => {
            state.users = action.payload
        },
        createDiary: (state, action: PayloadAction<{[key: string]: any}>) => {
            state.diary.push(action.payload)
            state.diary = orderBy(state.diary, 'whn', 'asc');
        },
        updateDiary: (state, action: PayloadAction<{[key: string]: any}>) => {
            if (action.payload?.id) {
                const targetIndex = state.diary.findIndex((obj) => obj?.id === action.payload?.id)
                if (typeof targetIndex === 'number') {
                    state.diary[targetIndex] = action.payload
                    state.diary = orderBy(state.diary, 'whn', 'asc');
                }
            }       
        },
        deleteDiaryById: (state, action: PayloadAction<number>) => {
            state.diary = state.diary.filter(obj => obj.id !== action.payload)
        },

        setDiaryDayData: (state, action: PayloadAction<{data: {[key: string]: any}, date: string, oldDate?: string}>) => {
            // const { date, data, oldDate } = action.payload;
            // if (!state.diary) state.diary = {};
            // if (oldDate) {
            //     if (Array.isArray(state.diary[oldDate]?.data)) 
            //         state.diary[oldDate].data = state.diary[oldDate].data.filter((obj: {[key: string]: any}) => data?.id !== obj?.id)
            // }
            // if (Array.isArray(state.diary?.[date || '']?.data)) {
            //     state.diary[date].data.push(data);
            //     state.diary[date].data = orderBy(state.diary[date].data, 'whn', 'asc');
            // } else {
            //     state.diary[date].data = [data];
            // }
        },
        
    }
})

export const diaryActions = diarySlice.actions
export default diarySlice