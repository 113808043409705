import moment from "moment";
import { useState } from "react";
import { ApiSettingsModal, Button, ModalContainer } from "../../../components";
import DataTable from "../../../components/DataTable";
import { APIKeysSearchRow } from "./styles";
import SearchRow from "../../../components/SearchRow";
import useFetchData from "../../../global/hooks/useFetchData";
import APIKeysRow from "../../../components/DataTable/TableRows/Accounts/APIKeysRow";

const ApiKeys = () => {
    const [apiSettingsModal, setApiSettingsModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedData, setSelectedData] = useState<null | {
        [key: string]: any;
    }>(null);
    const [userApikeys, setUserApikeys] = useState<
        Array<{ [ket: string]: any }>
    >([]);

    const { data, fetchData, isLoading } = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/admin-api-keys`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) {
                    setUserApikeys(data?.data);
                }
            },
        }
    );
    const selectDataHandler = (data: { [key: string]: any }) => {
        setSelectedData(data);
    };

    return (
        <>
            <ModalContainer
                isVisible={apiSettingsModal}
                children={
                    <ApiSettingsModal
                        isOpen={apiSettingsModal}
                        onClose={() => setApiSettingsModal(false)}
                        mode="create"
                    />
                }
            />
            <ModalContainer
                isVisible={!!selectedData}
                children={
                    <ApiSettingsModal
                        isOpen={!!selectedData}
                        onClose={() => setSelectedData(null)}
                        mode="edit"
                        data={selectedData || undefined}
                    />
                }
            />
            <APIKeysSearchRow
                value={searchTerm}
                setValue={setSearchTerm}
                className="api-keys-search-row"
            >
                <Button
                    btnText="+ CREATE"
                    onClick={() => setApiSettingsModal(!apiSettingsModal)}
                    style="mint"
                    role="create"
                />
                <a
                    href="https://documenter.getpostman.com/view/17310254/UzJFxJzJ"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View Document
                </a>
            </APIKeysSearchRow>
            <DataTable
                labels={["Description", "API KEY", "Created At"]}
                keys={["name", "api_key", "created_at"]}
                data={userApikeys}
                isLoading={isLoading}
                dataKey="id"
                searchTerm={searchTerm}
                searchKeys={["name"]}
                columnWidths={["", "30%", "30%"]}
                tableRow={APIKeysRow}
                onRowClick={selectDataHandler}
            />
        </>
    );
};

export default ApiKeys;
