import { StyledTableButton, StyledTableLink } from "./styles";

const OpenCase: React.FC<{ data: { [key: string]: any } }> = ({ data }) => {
    const hasNotTried = !data?.["Number Of Trys"];
    const clickHandler = () => {
        alert(OpenCase);
    };

    return (
        <>
            <StyledTableLink
                to={`/client-case/${data?.["Case Id"]}/summary`}
                target="_blank"
            >
                Open Case
            </StyledTableLink>
            {hasNotTried && (
                <StyledTableButton type="button" onClick={clickHandler}>
                    Send Payment Link
                </StyledTableButton>
            )}
        </>
    );
};

export default OpenCase;
