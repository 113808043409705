import React, { useState, useEffect, SetStateAction, useMemo } from "react";
import { icons } from "../../../../assets/icons";
import AuthorisationRate from "../../../../atoms/AuthorisationRate";
import { Button } from "../../../../components";
import { TablePageContent } from "../../../../pages/styles";
import Header from "../../../../layout/Header";
import DateButton from "../../../../components/Button/DateButton";
import useFetchData from "../../../../global/hooks/useFetchData";
import PageTabs from "../../../../components/PageTabs/PageTabs";
import SearchRow from "../../../../components/SearchRow";
import {
    dateSearchOptions,
    tableDateSearchOptions,
} from "../../../../layout/SlideInMenus/DateSearch/data";
import useDateSearch from "../../../../layout/SlideInMenus/DateSearch/useDateSearch";
import PaymentsTables from "./PaymentsTables";
import { exportCsv } from "../../../../global/utils/helpers";

export interface PaymentTableProps {
    data: { [key: string]: any };
    isLoading?: boolean;
    selectedIds?: number[];
    setSelectedIds?: React.Dispatch<SetStateAction<number[]>>;
    searchTerm?: string;
}

const Payments: React.FC = () => {
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today");

    const paymentIndex = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-payment-index`,
        },
        {
            fetchOnLoad: true,
            params: { from: dateRange.from, to: dateRange.to },
        }
    );
    const { data, isLoading, fetchData } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-payment`,
        },
        {
            params: { from: dateRange.from, to: dateRange.to },
            onSuccess: onDateSearchSuccess,
            notifyOnSuccess: true,
        }
    );
    const paymentsTablesData: { [key: string]: any } = data?.data || {};
    const paymentsTabs = [
        "Payments Due",
        "Credit Control Payments",
        "Payments Refused",
        "Payments Authorised",
    ];
    // const paymentsTabs = Object.keys(paymentsTablesData);
    const paymentsData = Object.values(paymentsTablesData);
    const paymentsCounts = paymentsData.map((obj) => obj?.count || 0);
    const paymentsBodies = paymentsData.map((obj) => obj?.body || {});
    const paymentsHeadings = paymentsData.map((obj) => obj?.headings || "");

    const [selectedTableIndex, setSelectedTableIndex] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedIds, setSelectedIds] = useState<number[]>([]);

    useEffect(() => {
        fetchData();
        paymentIndex.fetchData();
    }, [dateRange]);

    useEffect(() => {
        setSearchTerm("");
        setSelectedIds([]);
    }, [selectedTableIndex]);

    const exportHandler = () => {
        console.log(paymentsBodies, paymentsHeadings);
        exportCsv(
            paymentsBodies,
            paymentsHeadings,
            paymentsTabs,
            `Payments_${dateRange.from}-${dateRange.to}`
        );
    };
    return (
        <>
            <Header title="payments" hasNavBarAbove hasPrevPath>
                <DateButton
                    isLoading={isLoading}
                    dateDisplay={dateDisplay}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                    options={tableDateSearchOptions}
                />
            </Header>
            <TablePageContent>
                <PageTabs
                    tabs={paymentsTabs}
                    selectedTabIndex={selectedTableIndex}
                    setSelectedTabIndex={setSelectedTableIndex}
                    notifications={paymentsCounts}
                >
                    <AuthorisationRate
                        value={paymentIndex?.data?.data?.rate ?? 0}
                    />
                </PageTabs>
                <SearchRow
                    placeholder="Search by case id..."
                    value={searchTerm}
                    setValue={setSearchTerm}
                >
                    <Button
                        btnText="Payment Process"
                        onClick={() => alert("Click")}
                        style="mint"
                    />
                    {/* <Button
                        btnText="Filter"
                        onClick={() => alert("Click")}
                        style="purple"
                        btnImg={icons?.whiteDownArrow}
                    /> */}
                    <Button
                        btnText=""
                        onClick={exportHandler}
                        style="red"
                        btnImg={icons?.exportIcon}
                    />
                </SearchRow>
                <PaymentsTables
                    headings={paymentsHeadings}
                    bodies={paymentsBodies}
                    selectedTableIndex={selectedTableIndex}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    searchTerm={searchTerm}
                    isLoading={isLoading}
                />
            </TablePageContent>
        </>
    );
};

export default Payments;
