import styled from "styled-components";
import { FilterMenuWrapper, ViewUserWrapper } from "../Diary/styles";
import { theme } from "../../../global/theme";

export const StyledViewUser = styled(ViewUserWrapper)`
    height: min(100vh - 60px);
    padding: 24px;
    & .view-user {
        &__info {
            display: flex;
            flex-direction: column;
            gap: 0;
        }

        &__name {
        }

        &__role {
            font-weight: 300;
        }

        &__list {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 24px;

            & > li {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                font-weight: 300;
            }
        }
    }
`;

export const CreditFilter = styled(FilterMenuWrapper)`
    padding-top: 39px;
    h3 label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 11px;
        line-height: 16px;
        color: #212121;
    }
    .selectWrapper {
        margin-top: 35px;
    }
`;

export const StyledUserDoc = styled.div`
    position: fixed;
    z-index: -10;
    right: 0;
    bottom: 0;
    height: 1123px;
    width: 794px;
    background-color: #fff;
    padding: 36px 48px;

    & h1 {
        font-size: 18px;
        border: none;
    }

    & hr {
        border-bottom: none;
        border-color: ${theme.colors.boxStroke};
        margin-block: 12px;
    }

    & .header-container {
        border: 0.6px solid ${theme.colors.shade};
        border-radius: 12px;
        overflow: hidden;
        margin-top: 12px;
        &__ctn {
            background-color: transparent;
            padding-block: 12px;
        }

        &__title {
            font-size: 14px;
        }

        & .data-list {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 2px;

            & > li {
                display: flex;
                justify-content: space-between;
                font-weight: 300;
                font-size: 12px;

                & > span {
                    font-weight: 400;
                }
            }
        }
    }
`;
