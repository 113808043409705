import styled from "styled-components";
import { theme } from "../../global/theme";
import { lineClamp, mainScrollbar } from "../../global/theme/reuseable/mixins";

const StyledMessageViewer = styled.div<{ hasCheckbox: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1 1 0;

    & .message-viewer {
        &__table {
            flex: 1 1 0;
            display: flex;
            flex-direction: column;
        }

        &__row {
            display: grid;

            &--head {
                background-color: ${theme.colors.formBlue};
                font-size: 14px;
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
                grid-template-columns: 46px clamp(270px, 18vw, 320px) 1fr;
            }

            &--body {
                grid-template-columns: 46px clamp(270px, 18vw, 320px) 1fr;
                background-color: ${theme.colors.white};
                flex: 1 1 0;
                overflow: hidden;
                border-bottom-right-radius: 6px;
                border-bottom-left-radius: 6px;
            }
        }

        &__th {
            display: flex;
            justify-content: space-between;
            text-transform: capitalize;
            align-items: center;
            gap: 8px;
            padding: 8px;
            &:not(:last-child) {
                border-right: 1px solid transparent;
            }

            &--checkbox {
                display: ${(props) => !props.hasCheckbox && "none"};
            }

            &--group {
                grid-column: ${(props) => !props.hasCheckbox && "1/span 2"};
            }
        }

        &__pagination {
            font-weight: 300;
            font-size: 12px;
            text-transform: initial;
            &__btn {
                padding-inline: 10px;
                border: none;
                font-weight: 600;
                color: ${theme.colors.white};
                background-color: ${theme.colors.purple};
                border-radius: 4px;
                font-size: 14px;
                margin-left: 4px;

                &:disabled {
                    opacity: 0.5;
                    background-color: ${theme.colors.shade};
                }
            }
        }

        &__list {
            grid-column-end: span 2;
            list-style: none;
            overflow-y: auto;
            ${mainScrollbar(12)};
        }

        &__checkbox {
            margin-inline: auto;
        }

        &__empty {
            text-align: center;
            font-size: 16px;
            padding: 24px;
            font-weight: 300;
            background-color: ${theme.colors.white};
            width: 100%;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }

        &__search {
            justify-content: space-between;
        }

        &__unknown {
            font-weight: 300;
            color: #898989;
        }
    }
`;

export const StyledMessageItem = styled.li`
    display: flex;
    background-color: ${theme.colors.white2};

    &:last-child {
        border-bottom: 0.6px solid ${theme.colors.shade};
    }

    &:nth-child(2n) {
        background-color: ${theme.colors.white};
    }

    & .message-viewer-item {
        &__checkbox-ctn {
            padding: 10px;
            min-width: 46px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__checkbox {
        }

        &__info {
            padding: 10px;
            flex: 1;
            overflow: hidden;
            border: 0.6px solid transparent;
            border-left: 0.6px solid ${theme.colors.shade};
            box-sizing: border-box;
            background-color: transparent;
            font-size: 12px;
            text-align: left;

            &:hover {
                border: 0.6px solid ${theme.colors.boxStroke};
                /* background-color: ${theme.colors.containerBackground}; */
            }
            &[data-selected="true"] {
                border: 0.6px solid ${theme.colors.highlightBlue};
                background-color: ${theme.colors.formBlue};
            }
        }
        &__title {
            display: flex;
            justify-content: flex-start;
            gap: 12px;
            line-height: 120%;
        }

        &__sender {
            flex: 1 1 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__date {
            font-weight: 300;
        }

        &__subject {
            font-size: 11px;
            font-weight: 400;
            margin-top: 8px;
            line-height: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            & > span {
                font-weight: 300;
                color: ${theme.colors.text};
            }
        }

        &__body {
            margin-top: 4px;
            font-size: 10px;
            font-weight: 300;
            line-height: 135%;
            min-height: 27px;
            ${lineClamp(2)};
        }
    }
`;

export const StyledMessageDetails = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 0.6px solid ${theme.colors.shade};

    & > hr {
        width: calc(100% - 24px);
        border: none;
        border-top: 0.6px solid ${theme.colors.shade};
        margin-inline: auto;
    }

    & .message-viewer-details {
        &__empty {
            text-align: center;
            font-weight: 16px;
            padding: 24px;
            font-weight: 300;
        }
        &__header {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 12px;

            & span {
                font-weight: 300;
            }
        }

        &__title {
            display: flex;
            justify-content: space-between;
            line-height: 110%;
        }

        &__subject {
            font-size: 12px;
            margin-top: 4px;
        }

        &__sent-to {
            font-size: 11px;
        }

        &__body {
            flex: 1 1 0;
            overflow-y: auto;
            ${mainScrollbar(12)};

            & .sun-editor {
                border: none;
            }
            & .se-container {
                height: 100%;
            }
            & .se-wrapper {
                max-height: initial;
            }
            &
                .se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable.se-read-only {
                min-height: initial;
                max-height: initial;
                padding: 24px 12px;
            }
        }
    }
`;

export default StyledMessageViewer;
