import React, { useState } from "react";
import SearchRow from "../../../components/SearchRow";
import Header from "../../../layout/Header";
import { StyledPaymentsContent } from "./styles";
import DetailCard from "../../../components/Cards/DetailCard";
import useReduxClientLedgerPayment from "../../../store/hooks/useReduxClientLedgerPayment";
import DataTable from "../../../components/DataTable";
import useFetchData from "../../../global/hooks/useFetchData";
import { useParams } from "react-router-dom";

const Payments = () => {
    const { payments, setDataByKey } = useReduxClientLedgerPayment();
    const [searchTerm, setSearchTerm] = useState("");
    const { clientId } = useParams();

    const {isLoading} = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/payments/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setDataByKey("payments", data?.data || []);
            },
        }
    );

    return (
        <>
            <Header title="Payments" hasNavBarAbove isSubPath hasPrevPath />
            <StyledPaymentsContent>
                <SearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by taken by, status, or method..."
                >
                    <DetailCard
                        title="Total Client Payments:"
                        count={payments?.case_payment_amnt || 0}
                        countUnit="£"
                    />
                </SearchRow>
                <DataTable
                    labels={[
                        "Date of Payment",
                        "Take By",
                        "Amount (£)",
                        "Status",
                        "Worldpay Code",
                        "Payment Method",
                    ]}
                    keys={[
                        "whn",
                        "whom",
                        "amount",
                        "status",
                        "worldpay_code",
                        "payment_method",
                    ]}
                    data={payments?.case_payment || []}
                    searchTerm={searchTerm}
                    searchKeys={["whom", "status", "payment_method"]}
                    formatKeys={{
                        whn: "time",
                        status: "html",
                    }}
                    isLoading={isLoading}
                />
            </StyledPaymentsContent>
        </>
    );
};

export default Payments;
