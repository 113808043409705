import { SetStateAction, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Input, Modal } from "../..";
import { StyledDeleteAccountModal } from "./styles";
import ModalHeader from "../components/ModalHeader";
import ModalButtons from "../components/ModalButtons";
import { Button } from "../..";
import React from "react";
import useFetchData from "../../../global/hooks/useFetchData";
import SuccessContent from "../components/SuccessContent";

interface DeleteAccountProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const DeleteAccount: React.FC<DeleteAccountProps> = ({ isOpen, setIsOpen }) => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [confirmText, setConfirmText] = useState("");
    const { fetchData, isLoading, isSuccess } = useFetchData({
        method: "DELETE",
        url: `${process.env.REACT_APP_SERVER_API}/users/${userId}`,
    });
    const isConfirmed = confirmText.toLowerCase().trim() === "delete";

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (userId) fetchData(+userId);
    };

    const closeHandler = () => {
        setIsOpen(false);
        if (isSuccess) navigate("/admin");
    };
    return (
        <Modal isVisible={isOpen}>
            <StyledDeleteAccountModal show={isOpen} className="delete-account">
                <ModalHeader onClose={closeHandler}>Delete Account</ModalHeader>
                {isSuccess ? (
                    <SuccessContent
                        message="Admin successfully deleted."
                        onClose={closeHandler}
                    />
                ) : (
                    <form
                        className="delete-account__form"
                        onSubmit={submitHandler}
                    >
                        <p>Are you sure you want to delete this account?</p>
                        <Input
                            type="text"
                            label="Type 'delete' to confirm:"
                            value={confirmText}
                            onChange={(e) => setConfirmText(e.target.value)}
                        ></Input>
                        <ModalButtons>
                            <Button
                                style="red"
                                btnText="Delete"
                                type="submit"
                                loading={isLoading}
                                disabled={!isConfirmed}
                            />
                        </ModalButtons>
                    </form>
                )}
            </StyledDeleteAccountModal>
        </Modal>
    );
};

export default DeleteAccount;
