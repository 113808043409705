import { Dispatch, SetStateAction } from "react";
import { SelectButton } from "../../components";
import { iNewPassword } from "../../components/Modals/ChangePassword";
// import { TicketTimes } from "../../components/Modals/Diary/DayMeetings/CreateTicketModal";
import CryptoJS from "crypto-js";
import moment from "moment";
import { write, utils } from "xlsx";
import { Notify } from "notiflix";

export const delay = (time: number) =>
    new Promise((resolve) => setTimeout(resolve, time));

export function returnSelectedNav<T>(
    navList: T[],
    setSelected: Dispatch<SetStateAction<T>>,
    selected: T
) {
    return navList.map((but: T, index: number) => {
        return (
            <SelectButton
                key={index}
                btnText={but as unknown as string}
                onClick={() => setSelected(but)}
                selected={selected === but}
                notifications={Math.floor(Math.random() * 28)}
            />
        );
    });
}

export const checkPassword = (passwords: iNewPassword): string | true => {
    if (passwords.new !== passwords.retyped) {
        return "error";
    } else if (passwords.new === passwords.retyped) {
        return true;
    } else {
        return true;
    }
    //  They'll be more conditions but don't know what they are yet.
};

export function getDate(dayOffset: number) {
    const date = new Date();
    date.setDate(date.getDate() + dayOffset);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    const newDate = `${year}-${month}-${day}`;

    return newDate;
}

export const mergeDatesAndTimes = (diaryTicket: any, ticketTimes: any) => {
    // if (diaryTicket.dead_line) {
    //     const startTime = `${diaryTicket.whn} ${ticketTimes.start}`;
    //     const endTime = `${diaryTicket.dead_line} ${ticketTimes.end}`;
    //     const updatedTicket = {
    //         ...diaryTicket,
    //         whn: startTime,
    //         dead_line: endTime,
    //     };
    //     return updatedTicket;
    // } else {
    //     const startTime = `${diaryTicket.whn} ${ticketTimes.start}`;
    //     const updatedTicket = {
    //         ...diaryTicket,
    //         whn: startTime,
    //     };
    //     return updatedTicket;
    // }
};

export const hashKey = process.env.REACT_APP_LOCAL_HASH || "";

export const setSecureLocalStorage = (key: string, value: string) => {
    const hash = CryptoJS.AES.encrypt(value, hashKey).toString();
    localStorage.setItem(key, hash);
};

export const getSecureLocalStorage = (key: string) => {
    const storageValue = localStorage.getItem(key);
    if (!storageValue) return;
    const decrypted = JSON.parse(
        CryptoJS.AES.decrypt(storageValue, hashKey).toString(CryptoJS.enc.Utf8)
    );
    return decrypted;
};

export const getSecureLocalStorageString = (key: string) => {
    const storageValue = localStorage.getItem(key);
    if (!storageValue) return;
    const decrypted = CryptoJS.AES.decrypt(storageValue, hashKey).toString(
        CryptoJS.enc.Utf8
    );
    return decrypted;
};

export const removeSecureLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};

export const formatDateString = (
    date: string,
    format: string = "YYYY-MM-DD HH:mm:ss",
    toFormat: string = "DD/MM/YYYY"
) => {
    if (!date || !moment(date, format).isValid()) return "-";
    return moment(date, format).format(toFormat);
};

export const formatTimeString = (
    date: string,
    format: string = "YYYY-MM-DD HH:mm:ss",
    toFormat: string = "DD/MM/YYYY h:mm A"
) => {
    if (!date || !moment(date, format).isValid()) return "-";
    return moment(date, format).format(toFormat);
};

export const filterObjectArrayByKeys = (
    data: Array<{ [key: string]: any }> = [],
    keyArray: string[] = [],
    searchTerm: string = ""
): Array<{ [key: string]: any }> => {
    if (!data?.length || !keyArray?.length || !searchTerm) return data;
    return data.filter((item) =>
        keyArray.some(
            (key) =>
                item[key] &&
                item[key]
                    .toString()
                    .toLowerCase()
                    .trim()
                    .includes(searchTerm.toLowerCase().trim())
        )
    );
};

export const makeArray = (data: []) => {
    // console.log(data);

    let returnData: any = [];
    data.forEach((rowData: any) => {
        const rowValues = Object.values(rowData);
        returnData.push(rowValues);
    });
    return returnData;
};

export const exportCsv = (
    data: any[],
    headings: string[][],
    labels: string[],
    name: string = "Tables"
) => {
    try {
        let sheetData: any[][] = [];
        data.forEach((obj) =>
            sheetData.push(
                Object.values(obj).map((obj) => Object.values(obj || {}))
            )
        );
        console.log(sheetData, headings);

        const workbook = utils.book_new();
        labels.forEach((rowData: string, i: number) => {
            const sheet = utils.aoa_to_sheet([headings[i], ...sheetData[i]]);
            utils.book_append_sheet(workbook, sheet, rowData);
        });
        const excelBuffer = write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const dataBlob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileName = name + ".xlsx";
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(dataBlob);
        downloadLink.download = fileName;
        downloadLink.click();
    } catch (error) {
        Notify.failure(
            "Error creating and downloading CSV file with multiple pages:"
        );
        console.error(error);
    }

    // let sheetData: any[][] = data || [];
    // const workbook = utils.book_new();
    // labels.forEach((rowData: any, i) => {
    //     Object.entries(data[i]).forEach(([key, value]) => {
    //         sheetData = data[i];
    //     });
    //     const sheet = utils.aoa_to_sheet([headings[i], ...sheetData]);
    //     utils.book_append_sheet(workbook, sheet, rowData);
    // });
    // const excelBuffer = write(workbook, { bookType: "xlsx", type: "array" });
    // const dataBlob = new Blob([excelBuffer], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // });
    // const fileName = name + ".xlsx";
    // const downloadLink = document.createElement("a");
    // downloadLink.href = window.URL.createObjectURL(dataBlob);
    // downloadLink.download = fileName;
    // downloadLink.click();
};

export const strNumsToNumArr = (str: string | null | undefined) => {
    if (typeof str !== "string") return [];
    return str.split(",").map((num) => +num);
};

export function extractTextFromHTML(htmlString: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
}

export const calculateAgeFromDate = (date: string) => {
    if (!moment(date).isValid()) return "Unknown";
    const age = Math.floor(moment().diff(date, "years"));
    return `${age} year${age > 1 ? "s" : ""} old`;
};

export const calculateRatingGradient = (
    rating: number,
    hueOffset: number = -10
) => {
    const hue = rating * 12 + 6 * rating;
    return `linear-gradient(150deg, hsl(${hue}, 85%, 65%), hsl(${
        hue - hueOffset
    }, 90%, 45%))`;
};

export const viewUploadOnNewWindow = (imageURL: string) => {
    const windowFeatures =
        "width=800,height=600,location=no,menubar=no,scrollbars=yes,status=yes,directories=no";
    window.open(imageURL, "_blank", windowFeatures);
};

export function formatFileSize(fileSize: number): string {
    const units = ["Bytes", "KB", "MB", "GB", "TB"];

    let currentUnitIndex = 0;

    while (fileSize >= 1000 && currentUnitIndex < units.length - 1) {
        fileSize /= 1000;
        currentUnitIndex++;
    }

    return `${fileSize.toFixed(1)} ${units[currentUnitIndex]}`;
}

export function getOperatingSystem() {
    const userAgent = navigator.userAgent;
    // Windows
    if (/Win/i.test(userAgent)) {
        const match = userAgent.match(/Windows NT (\d+\.\d+)/);
        return match ? `Windows ${versionLookup(match[1])}` : "Windows";
    }
    // macOS
    if (/Mac/i.test(userAgent)) {
        const match = userAgent.match(/Mac OS X (\d+([_\.\d]+)+)/i);
        return match ? `Mac OS ${match[1].replace(/_/g, ".")}` : "macOS";
    }
    // iOS
    if (/iPhone|iPad|iPod/.test(userAgent)) {
        const match = userAgent.match(/OS (\d+([_\.\d]+)+)/i);
        return match ? `iOS ${match[1].replace(/_/g, ".")}` : "iOS";
    }
    // Android
    if (/Android/i.test(userAgent)) {
        const match = userAgent.match(/Android (\d+(\.\d+)+)/i);
        return match ? `Android ${match[1]}` : "Android";
    }

    // Linux, UNIX, BlackBerry, Windows Mobile: Version extraction can be more complex and might not always be present in userAgent.
    if (/Linux/i.test(userAgent)) return "Linux";
    if (/X11/.test(userAgent)) return "UNIX";
    if (/BlackBerry/i.test(userAgent)) return "BlackBerry";
    if (/IEMobile/i.test(userAgent)) return "Windows Mobile";

    return "Unknown";
}

function versionLookup(ver: string): string {
    const versions: { [key: string]: string } = {
        "5.0": "2000",
        "5.1": "XP",
        "5.2": "XP",
        "6.0": "Vista",
        "6.1": "7",
        "6.2": "8",
        "6.3": "8.1",
        "10.0": "10",
    };
    return versions[ver] || ver;
}

export async function getPublicIPAddress(): Promise<string | null> {
    try {
        const response = await fetch(
            "https://api.ipify.org?format=json"
        );
        const data = await response.json();
        return data.ip;
    } catch (error) {
        console.error("Error fetching IP address:", error);
        return null;
    }
}

