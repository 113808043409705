import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import Button from "../../../Button";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import { StyledCreateUserModal } from "./styles";
import ModalHeader from "../../components/ModalHeader";
import ModalButtons from "../../components/ModalButtons";
import StepTabs from "../../../StepTabs";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import isEmail from "validator/lib/isEmail";

export interface iCreateAdmin {
    name: string;
    email: string;
    ddi: string;
    job_title: string;
    job_title_id: number | null;
    department: string;
    dprtmnt: number | null;
    user_type: string;
    type: number | null;
    access_group: string;
    role_id: number | null;
    ipaddress: string;
}

export interface StepProps {
    handleChange: ({
        target,
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSelect: (key: string, value: any) => void;
    adminDetails: iCreateAdmin;
    select: any;
    setAdminDetails: Dispatch<SetStateAction<iCreateAdmin>>;
}

interface iCreateAdminModal {
    open: boolean;
    setCreateAdmin: Dispatch<SetStateAction<boolean>>;
    setUserDet: any;
}

const CreateUserModal = ({
    setCreateAdmin,
    open,
    setUserDet,
}: iCreateAdminModal) => {
    const [activeStep, setActiveStep] = useState(0);
    const [userSelect, setUserSelect] = useState<Array<{ [key: string]: any }>>(
        []
    );
    const [adminDetails, setAdminDetails] = useState<iCreateAdmin>({
        name: "",
        email: "",
        ddi: "",
        job_title: "",
        job_title_id: null,
        department: "",
        dprtmnt: null,
        user_type: "",
        type: null,
        access_group: "",
        role_id: null,
        ipaddress: "",
    });
    const listSelectAccount = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/select-list-account`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                setUserSelect(data?.data);
            },
        }
    );

    const steps = ["Staff Details", "Permissions", "Confirmations"];

    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/admin-user-create`,
        },
        {
            onSuccess: (data) => {
                setUserDet(data?.data);
            },
        }
    );

    function handleChange({
        target,
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const { value, name } = target;

        setAdminDetails((adminDetails) => {
            return {
                ...adminDetails,
                [name]: value,
            };
        });
    }

    function handleSelect(key: string, value: any) {
        setAdminDetails((adminDetails) => {
            return {
                ...adminDetails,
                [key]: value,
            };
        });
    }

    const prevHandler = () => {
        if (activeStep === 0) return setCreateAdmin(false);
        return setActiveStep((prev) => prev - 1);
    };

    const stepProps = {
        handleChange,
        handleSelect,
        adminDetails,
        setAdminDetails,
    };

    const isStepValid = () => {
        if (activeStep === 0)
            return (
                adminDetails.name.trim() !== "" &&
                !!adminDetails.dprtmnt &&
                isEmail(adminDetails.email)
            );
        if (activeStep === 1) return !!adminDetails.type && !!adminDetails.type;
        return true;
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (activeStep < 2) return setActiveStep((prev) => prev + 1);
        const { department, user_type, access_group, ...requestData } =
            adminDetails;
        console.log(requestData);
        fetchData(requestData);
    };

    return (
        <StyledCreateUserModal
            show={open}
            className="create-user"
            width="700px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={() => setCreateAdmin(false)}>
                Create New Account
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="New user has been created."
                    onClose={() => setCreateAdmin(false)}
                />
            ) : (
                <>
                    <StepTabs
                        tabs={steps}
                        selectedTabIndex={activeStep}
                        setSelectedTabIndex={setActiveStep}
                        currentProgress={activeStep}
                    ></StepTabs>

                    <form className="create-user-form" onSubmit={submitHandler}>
                        {activeStep === 0 && (
                            <StepOne {...stepProps} select={userSelect} />
                        )}
                        {activeStep === 1 && (
                            <StepTwo {...stepProps} select={userSelect} />
                        )}
                        {activeStep === 2 && (
                            <StepThree adminDetails={adminDetails} />
                        )}
                        <ModalButtons>
                            {activeStep !== 0 && (
                                <Button
                                    type="button"
                                    onClick={prevHandler}
                                    style="cancel"
                                    btnText={"Previous"}
                                />
                            )}
                            <Button
                                style="purple"
                                btnText={activeStep >= 2 ? "Create" : "Next"}
                                type="submit"
                                disabled={!isStepValid()}
                                loading={isLoading}
                            />
                        </ModalButtons>
                    </form>
                </>
            )}
        </StyledCreateUserModal>
    );
};

export default CreateUserModal;
