import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import { StyledCreateAddress } from "./styles";
import ModalHeader from "../components/ModalHeader";
import SuccessContent from "../components/SuccessContent";
import ModalButtons from "../components/ModalButtons";
import Button from "../../Button";
import Input from "../../Input";
import AddressSearch from "../../Input/AddressSearch";
import TextArea from "../../Input/TextArea";

interface EditAddressProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    data?: { [key: string]: any };
}

const EditAddress: React.FC<EditAddressProps> = ({ isOpen, onClose, data }) => {
    const { clientId } = useParams();
    const editAddress = useFetchData({});
    const deleteAddress = useFetchData({});
    const [address, setAddress] = useState({
        door: data?.door || "",
        postcode: data?.postcode || "",
        full: data?.full || "",
        year: data?.year || "",
    });
    const isSuccess = deleteAddress.isSuccess || editAddress.isSuccess;
    const isFormValid =
        address.full.trim() !== "" && address.postcode.trim() !== "";

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setAddress((prev) => {
            return {
                ...prev,
                [name]: name === "postcode" ? value.toUpperCase() : value,
            };
        });
    };

    const deleteHandler = () => {
        deleteAddress.fetchData();
    };

    const addressSelectHandler = (
        doorNumber: string,
        address: string,
        postcode: string
    ) => {
        setAddress((prev) => {
            return { ...prev, door: doorNumber, full: address, postcode };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        editAddress.fetchData();
    };

    return (
        <StyledCreateAddress className="create-address" show={isOpen}>
            <ModalHeader onClose={onClose}>
                Edit Other Address
                {!isSuccess && (
                    <Button
                        type="button"
                        onClick={deleteHandler}
                        style="red"
                        btnText="Delete"
                        disabled={editAddress.isLoading}
                        loading={deleteAddress.isLoading}
                    />
                )}
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    onClose={onClose}
                    message={`Address has been ${
                        editAddress.isSuccess ? "updated" : "deleted"
                    }.`}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <p>
                        ID: <span>{data?.id ?? "-"}</span>
                    </p>
                    <Input
                        type="text"
                        label="Door Number:"
                        value={address?.door || ""}
                        name="door"
                        onChange={handleChange}
                        placeholder="e.g. 38"
                    />
                    <AddressSearch
                        postcode={address?.postcode || ""}
                        onPostcodeChange={handleChange}
                        onSelect={addressSelectHandler}
                    />
                    <TextArea
                        label="Address:"
                        value={address?.full || ""}
                        name="full"
                        onChange={handleChange}
                        placeholder=""
                    />
                    <Input
                        type="number"
                        label="Year Spent At This Address:"
                        value={address?.year || ""}
                        name="year"
                        onChange={handleChange}
                        placeholder="e.g. 2023"
                    />
                    <ModalButtons>
                        <Button
                            type="button"
                            onClick={onClose}
                            style="cancel"
                            btnText="Cancel"
                        />
                        <Button
                            type="submit"
                            style="purple"
                            btnText="Save Changes"
                            disabled={!isFormValid || deleteAddress.isLoading}
                            loading={editAddress.isLoading}
                        />
                    </ModalButtons>
                </form>
            )}
        </StyledCreateAddress>
    );
};

export default EditAddress;
