import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../../global/hooks/useFetchData";
import BasicModal from "../../BasicModal";
import ModalHeader from "../../components/ModalHeader";
import StyledSignableDetails from "./styles";
import { LoadingSpinner } from "../../../Button/styles";
import ModalButtons from "../../components/ModalButtons";
import DropdownMenu from "../../../DropdownMenu";
import DropdownMeunItem from "../../../DropdownMenu/DropdownMenuItem";
import StepTabs from "../../../StepTabs";
import SignableContent from "./SignableContent";
import useReduxClientCorrespondence from "../../../../store/hooks/useReduxClientCorrespondence";

interface SignableDetailsProps {
    isOpen: boolean;
    onClose: (...args: any[]) => void;
    data: { [key: string]: any };
}

const actionText = {
    reminder: {
        message: "Do you want to send a reminder?",
        btnText: "Send",
        successMessage: "Reminder has been sent.",
    },
    expire: {
        message: "Do you want to expire (slient cancel) this signable?",
        btnText: "Confirm",
        successMessage: "Signable has been set expired.",
    },
    cancel: {
        message: "Do you wan to cancel this signable?",
        btnText: "Confirm",
        successMessage: "Signable has been cancelled.",
    },
    delete: {
        message: "Do you want to delete this signable?",
        btnText: "Delete",
        successMessage: "Signable has been deleted.",
    },
};

const tabs = ["Main", "Partys", "History", "Original Document"];

const SignableDetails: React.FC<SignableDetailsProps> = ({
    isOpen,
    onClose,
    data,
}) => {
    const { clientId } = useParams();
    const { setSignables, signables } = useReduxClientCorrespondence();
    const [signableDetails, setSignableDetails] = useState<{
        [key: string]: any;
    }>(data || {});
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [currentAction, setCurrentAction] = useState<string>("");

    const refetchSignablesData = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/correspondence-signables/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setSignables(data.data);
            },
        }
    );

    const getSignableData = useFetchData(
        {
            method: "get",
            url: `${
                process.env.REACT_APP_SERVER_API
            }/client-case/signable-action/${data?.id || ""}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                const newData = data?.data?.[0];
                if (newData) setSignableDetails(newData);
            },
        }
    );
    const postSignableAction = useFetchData(
        {
            method: "post",
            url: `${
                process.env.REACT_APP_SERVER_API
            }/client-case/signable-action/${data?.id || ""}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) {
                    setSignableDetails(data?.data);
                }
                refetchSignablesData.fetchData();
            },
        }
    );

    const currentText = actionText[currentAction as keyof typeof actionText];

    const actionCloseHandler = () => {
        if (postSignableAction.isSuccess) {
            postSignableAction.setIsSuccess(false);
            if (currentAction === "delete") return onClose();
        }
        return setCurrentAction("");
    };

    const actionSubmitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        postSignableAction.fetchData({
            action_type: currentAction,
        });
    };

    if (currentAction !== "")
        return (
            <BasicModal
                isOpen={isOpen}
                onClose={actionCloseHandler}
                message={currentText?.message || ""}
                submitBtnColor={currentAction === "delete" ? "red" : "purple"}
                submitBtnText={currentText?.btnText || ""}
                successMessage={currentText?.successMessage || ""}
                isLoading={postSignableAction.isLoading}
                isSuccess={postSignableAction.isSuccess}
                onSubmit={actionSubmitHandler}
            />
        );

    return (
        <StyledSignableDetails
            className="signable-details"
            show={isOpen}
            width={currentTabIndex === 3 ? "720px" : "600px"}
        >
            <ModalHeader onClose={onClose}>Signable Data</ModalHeader>
            {getSignableData.isLoading ? (
                <LoadingSpinner size={40} />
            ) : (
                <>
                    <StepTabs
                        tabs={tabs}
                        selectedTabIndex={currentTabIndex}
                        setSelectedTabIndex={setCurrentTabIndex}
                    />
                    <div className="content">
                        <SignableContent
                            data={signableDetails}
                            currentTabIndex={currentTabIndex}
                        />
                    </div>
                    <ModalButtons>
                        <DropdownMenu color="purple" title="Actions">
                            <DropdownMeunItem
                                elementType="button"
                                iconName="send"
                                onClick={() => setCurrentAction("reminder")}
                                title="Send Reminder"
                            />
                            <hr />
                            <DropdownMeunItem
                                elementType="button"
                                iconName="transfer"
                                onClick={() => getSignableData.fetchData()}
                                title="Update"
                            />
                            <hr />
                            <DropdownMeunItem
                                elementType="button"
                                iconName="expire"
                                onClick={() => setCurrentAction("expire")}
                                title="Expire (Silently Cancel)"
                            />
                            <DropdownMeunItem
                                elementType="button"
                                iconName="remove"
                                onClick={() => setCurrentAction("cancel")}
                                title="Cancel"
                            />
                            <DropdownMeunItem
                                elementType="button"
                                iconName="cancel"
                                onClick={() => setCurrentAction("delete")}
                                title="Delete / Remove"
                            />
                        </DropdownMenu>
                    </ModalButtons>
                </>
            )}
        </StyledSignableDetails>
    );
};

export default SignableDetails;
