import { ChangeEvent, useEffect, useState } from "react";
import Button from "../../../Button";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import { CreateCreditorModalWrapper } from "./styles";
import { iClientModal, iCreateCreditor } from "./types";
import ModalHeader from "../../components/ModalHeader";
import ModalButtons from "../../components/ModalButtons";
import StepTabs from "../../../StepTabs";
import useFetchData from "../../../../global/hooks/useFetchData";
import SuccessContent from "../../components/SuccessContent";
import { useNavigate } from "react-router-dom";
import useReduxCreditors from "../../../../store/hooks/useReduxCreditors";

const steps = ["Base Details", "Account Details", "Other Info"];

const CreateCreditorModal = ({
    setCreateCreditorModal,
    open,
}: iClientModal) => {
    const [activeStep, setActiveStep] = useState(0);
    const { setList } = useReduxCreditors();
    const [stepsComplete, setStepsCompleted] = useState<boolean[]>([
        false,
        false,
        false,
    ]);
    const [newCreditor, setNewCreditor] = useState<iCreateCreditor>({
        companyName: "",
        address: "",
        phone: "",
        email: "",
        accountName: "",
        accountNumber: "",
        sortCode: "",
        correspondenceMethod: 1,
        status: 0,
        notes: "",
    });

    const isStepComplete = () => {
        if (activeStep === 0) return newCreditor.companyName.trim() !== "";
        return true;
    };
    const navigate = useNavigate();
    const previousHandler = () => {
        if (activeStep === 0) return setCreateCreditorModal(false);
        return setActiveStep((prev) => prev - 1);
    };

    const createCreditor = useFetchData(
        {
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_API}/creditor`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Creditor Created Sucessfully",
            onSuccess: (data) => {
                if (data?.data?.creditors) setList(data?.data?.creditors);
            },
        }
    );
    function handleChange(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const { value, name } = e.target;
        setNewCreditor({
            ...newCreditor,
            [name]: value,
        });
    }

    function handleSelect(name: string, value: string) {
        setNewCreditor((...prevState) => {
            return {
                ...newCreditor,
                [name]: value,
            };
        });
    }

    useEffect(() => {
        console.log({ newCreditor });
    }, [newCreditor]);

    const stepProps = {
        handleChange,
        newCreditor,
        setNewCreditor,
        handleSelect,
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (activeStep < 2) {
            setActiveStep((prev) => prev + 1);
            setStepsCompleted((prev) =>
                prev.map((b, i) => (i === activeStep ? true : b))
            );
        }
        if (activeStep === 2) {
            createCreditor.fetchData(newCreditor);
        }
    };

    const closeHandler = () => {
        setCreateCreditorModal(false);
        if (createCreditor.isSuccess) navigate(`/creditor/creditors`);
    };

    return (
        <CreateCreditorModalWrapper
            show={open}
            className="create-creditor"
            width="600px"
            isSuccess={createCreditor.isSuccess}
        >
            <ModalHeader setIsOpen={closeHandler}>Create Creditor</ModalHeader>
            {createCreditor.isSuccess ? (
                <SuccessContent
                    message="Creditor sucessfully created."
                    setIsOpen={closeHandler}
                />
            ) : (
                <>
                    <StepTabs
                        selectedTabIndex={activeStep}
                        setSelectedTabIndex={setActiveStep}
                        stepsCompleted={stepsComplete}
                        tabs={steps}
                    ></StepTabs>
                    <form
                        className="create-creditor__form"
                        onSubmit={submitHandler}
                    >
                        <div className="create-creditor__content">
                            {activeStep === 0 && <StepOne {...stepProps} />}
                            {activeStep === 1 && <StepTwo {...stepProps} />}
                            {activeStep === 2 && <StepThree {...stepProps} />}
                        </div>
                        <ModalButtons>
                            {!!activeStep && (
                                <Button
                                    type="button"
                                    onClick={previousHandler}
                                    style="cancel"
                                    btnText="Previous"
                                />
                            )}
                            <Button
                                style="purple"
                                btnText={activeStep >= 2 ? "Submit" : "Next"}
                                type="submit"
                                loading={createCreditor.isLoading}
                                disabled={!isStepComplete()}
                            />
                        </ModalButtons>
                    </form>
                </>
            )}
        </CreateCreditorModalWrapper>
    );
};

export default CreateCreditorModal;
