import React, {
    useState,
    useEffect,
    useId,
    useMemo,
    SetStateAction,
} from "react";
import { icons } from "../../assets/icons";
import { SearchInputWrapper } from "./styles";

export interface SearchInputProps {
    className?: string;
    label?: string;
    value: string;
    setValue: React.Dispatch<SetStateAction<string>>;
    onSearch?: (value: string) => void;
    placeholder?: string;
    searchKey?: string;
    searchOnKeyDown?: boolean;
    searchOnDebounce?: boolean;
    searchOnChange?: boolean;
    debounceDelay?: number;
    minCharacters?: number;
}

const SearchInput = ({
    className = "",
    label = "",
    value,
    setValue,
    onSearch = () => {},
    placeholder = "Search...",
    searchKey = "Enter",
    searchOnChange = false,
    searchOnDebounce = false,
    searchOnKeyDown = false,
    debounceDelay = 250,
    minCharacters = 1,
}: SearchInputProps) => {
    const id = useId();
    const [isTouched, setIsTouched] = useState(false);
    const isValueValid = value.trim().length >= minCharacters && isTouched;

    useEffect(() => {
        if (searchOnDebounce) {
            const timer = setTimeout(() => {
                if (isValueValid) onSearch(value);
            }, debounceDelay);

            return () => clearTimeout(timer);
        }
    }, [searchOnDebounce, debounceDelay, value, isValueValid]);

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isTouched) setIsTouched(true);
        setValue(e.target.value);
        if (searchOnChange && e.target.value.trim().length > minCharacters)
            onSearch(value);
    };

    const keydownHandler = (e: React.KeyboardEvent) => {
        if (e.key === searchKey && searchOnKeyDown && isValueValid)
            onSearch(value);
    };

    return (
        <SearchInputWrapper className={`searchInput ${className}`}>
            <img src={icons?.search} alt="Search" />
            {label && <label htmlFor={`search-input-${id}`}>{label}</label>}
            <input
                id={`search-input-${id}`}
                type="text"
                placeholder={`${
                    searchOnKeyDown ? `Press ${searchKey} to ` : ""
                }${placeholder}`}
                onChange={changeHandler}
                onKeyDown={keydownHandler}
                value={value}
            />
        </SearchInputWrapper>
    );
};

export default SearchInput;
