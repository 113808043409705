import styled from "styled-components";
import { ResizableModalWrapper } from "../../styles";

export const StyledLeadStatusModal = styled(ResizableModalWrapper)`
    width: min(100%, 440px);

    & .modal-header__heading {
        & > button {
            padding-inline: 24px;
        }
    }

    & form {
        display: flex;
        flex-direction: column;
        gap: 12px;

        & > p {
            display: flex;
            justify-content: space-between;
        }
    }
`;
