import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export type PermissionsObject= {[key: string]: {[key: string]: Array<{[key: string]: any}>}}
export interface AccountsState {
    permissions: null | PermissionsObject;
    userData: null | {[key: string]: any};
    groupSettings: Array<{[key: string]: any}>
}

const initialAccountsState: AccountsState = {
    permissions: null,
    userData: null,
    groupSettings: [],
}

export const accountsSlice = createSlice({
    name: 'accounts',
    initialState: initialAccountsState,
    reducers: {
        setPermissions: (state, action: PayloadAction<PermissionsObject | null>) => {
            state.permissions = action.payload
        },
        setUserData: (state, action: PayloadAction<{[key: string]: any} | null>) => {
            state.userData = action.payload
        },
        setGroupSettings: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.groupSettings = action.payload
        },
    }
})

export const accountsActions = accountsSlice.actions
export default accountsSlice