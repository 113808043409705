import BasicModal from "../../BasicModal";
import { useParams } from "react-router-dom";
import useFetchData from "../../../../global/hooks/useFetchData";
import { CaseActionsModalProps } from "./types";

const RemoveFromCreditControl: React.FC<CaseActionsModalProps> = ({
    isOpen,
    onClose,
}) => {
    const { clientId } = useParams();
    const { fetchData, isSuccess, isLoading } = useFetchData({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_API}/client-case/remove-cc/${clientId}`,
    });

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData();
    };
    return (
        <BasicModal
            isOpen={isOpen}
            onClose={onClose}
            message="Do you want to remove this case from credit control?"
            submitBtnText="Remove"
            successMessage="The case has been removed from credit control."
            isLoading={isLoading}
            isSuccess={isSuccess}
            onSubmit={submitHandler}
        />
    );
};

export default RemoveFromCreditControl;
