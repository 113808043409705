import styled from "styled-components";

export const StyledPersonalBudgeting = styled.div`
    display: flex;
    flex: 1;
    gap: 32px;
    margin-top: -8px;

    & .table-col {
        flex: 1;
        display: flex;
        flex-direction: column;

        & > .header {
            width: 100%;
            height: 50px;
            margin-bottom: 8px;

            & > h3 {
                & > span {
                    margin-left: 8px;
                }
            }

            & > button {
                width: 100px;
            }
        }
    }
`;

export const StyledCreditorInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
    margin-top: -8px;
    & .data-table {
        flex-grow: 1;
    }

    & .creditor-info {
        &__row {
            display: flex;
            justify-content: space-between;

            & > button {
                padding-inline: 24px;
            }
        }
    }
`;

export const StyledPersonalAccountancy = styled.form`
    & .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px 16px;

        & .input--trading-name,
        & .input--sage-ref,
        & .input--address {
            grid-column: 1/-1;
        }
    }
`;

export const StyledBusinessAccountancy = styled.form`
    display: flex;
    column-gap: 32px;
    width: min(100%, 1600px);
    margin-inline: auto;

    & .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px 16px;
        &--business {
            & > :nth-child(3),
            & > :last-child {
                grid-column: 1/-1;
            }
        }

        &--account {
        }
    }
`;
