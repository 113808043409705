import styled from "styled-components";
const StyledAdditionalInfo = styled.form`
    width: min(100%, 640px);
    margin: 0 auto;

    & .header-container__ctn {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
`;

export default StyledAdditionalInfo;
