import { PayeRebateContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import DropdownSelect from "../../../../components/DropdownSelect";
import { yesNoValueOptions } from "../../../../components/DropdownSelect/options";

const Questionnaire: React.FC<PayeRebateContainerProps> = ({
    onInputChange,
    onSelect,
    payeRebate,
}) => {
    return (
        <HeaderContainer
            title="Rebate Questionnaire:"
            headerStyle="gradientBlue"
            ctnClassName="col-2"
        >
            <DropdownSelect
                label="Do you traveled between different sites in your workplace?"
                value={payeRebate?.["question-travel"] || null}
                options={yesNoValueOptions}
                onSelect={(option) =>
                    onSelect("question-travel", option?.value ?? null)
                }
                hasDefaultValue
            />
            <DropdownSelect
                label="Do you have a uniform for work and maintain it?"
                value={payeRebate?.["question-uniform"] || null}
                options={yesNoValueOptions}
                onSelect={(option) =>
                    onSelect("question-uniform", option?.value ?? null)
                }
                hasDefaultValue
            />
            <DropdownSelect
                label="Do you pay for any professional fees and subscriptions?"
                value={payeRebate?.["question-fees"] || null}
                options={yesNoValueOptions}
                onSelect={(option) =>
                    onSelect("question-fees", option?.value ?? null)
                }
                hasDefaultValue
            />
            <DropdownSelect
                label="Have you purchased any tools or equipment for your job?"
                value={payeRebate?.["question-equip"] || null}
                options={yesNoValueOptions}
                onSelect={(option) =>
                    onSelect("question-equip", option?.value ?? null)
                }
                hasDefaultValue
            />
            <DropdownSelect
                label="Do you or have you worked from home?"
                value={payeRebate?.["question-home"] || null}
                options={yesNoValueOptions}
                onSelect={(option) =>
                    onSelect("question-home", option?.value ?? null)
                }
                hasDefaultValue
            />
            <DropdownSelect
                label="Does your partner work?"
                value={payeRebate?.["question-partner"] || null}
                options={yesNoValueOptions}
                onSelect={(option) =>
                    onSelect("question-partner", option?.value ?? null)
                }
                hasDefaultValue
            />
            <Input
                type="text"
                label="What sector do you work in?"
                value={payeRebate?.["question-sector"] || ""}
                name="question-sector"
                onChange={onInputChange}
            />
            <Input
                type="text"
                label="Who is your employer?"
                value={payeRebate?.["question-employer"] || ""}
                name="question-employer"
                onChange={onInputChange}
            />
        </HeaderContainer>
    );
};

export default Questionnaire;
