import LineBreak from "../../../../atoms/LineBreak";
import { Row } from "../../../../global/theme/reuseable/components";
import Input from "../../../Input";
import TextArea from "../../../Input/TextArea";
import { ContactGrid, StepOneWrapper } from "./styles";
import { iStep } from "./types";

const StepOne = ({ handleChange, newCreditor, setNewCreditor }: iStep) => {
    return (
        <StepOneWrapper>
            <Row align-items="flex-start" gap="27px" className="row-1">
                <Input
                    name="companyName"
                    onChange={handleChange}
                    value={newCreditor?.companyName}
                    type="text"
                    label="Company Name(*)"
                    required
                />
                <TextArea
                    name="address"
                    onChange={handleChange}
                    value={newCreditor?.address}
                    label="Address"
                />
            </Row>
            {/* <LineBreak title="Opening Hours" /> */}
            {/* <Column>
                {days.map((day: DayT) => (
                    <DayOpeningHours
                        key={day}
                        day={day}
                        newCreditor={newCreditor}
                        setNewCreditor={setNewCreditor}
                    />
                ))}
            </Column> */}
            <LineBreak title="Contact Number" />
            <ContactGrid>
                {/* <p>Contact Number</p> */}
                <span>
                    <Input
                        label="Telephone 1"
                        name="phone"
                        value={newCreditor?.phone}
                        onChange={handleChange}
                        type="tel"
                    />
                    <Input
                        label="Telephone 2"
                        name="phoneTwo"
                        value={newCreditor?.phoneTwo}
                        onChange={handleChange}
                        type="tel"
                        autoComplete="tel-2"
                    />
                    <Input
                        label="Telephone 3"
                        name="phoneThree"
                        onChange={handleChange}
                        type="tel"
                        autoComplete="tel-3"
                    />
                </span>
            </ContactGrid>
            <LineBreak title="Email Address" />
            <ContactGrid>
                {/* <p>Email Address</p> */}
                <span>
                    <Input
                        label="Email 1"
                        name="email"
                        onChange={handleChange}
                        value={newCreditor?.email}
                        type="email"
                        autoComplete="email"
                    />
                    <Input
                        label="Email 2"
                        name="emailTwo"
                        value={newCreditor?.emailTwo}
                        onChange={handleChange}
                        type="email"
                        autoComplete="email-2"
                    />
                    <Input
                        label="Email 3"
                        name="emailThree"
                        value={newCreditor?.emailThree}
                        onChange={handleChange}
                        type="email"
                        autoComplete="email-3"
                    />
                </span>
            </ContactGrid>
        </StepOneWrapper>
    );
};

export default StepOne;
