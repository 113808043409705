import React, { useEffect, useState } from "react";
import { icons } from "../../../../assets/icons";
import { Button } from "../../../../components";
import Header from "../../../../layout/Header";
import DateButton from "../../../../components/Button/DateButton";
import SearchRow from "../../../../components/SearchRow";
import { TablePageContent } from "../../../../pages/styles";
import PageTabs from "../../../../components/PageTabs/PageTabs";
import SalesSummaryTables from "./SalesSummaryTables";
import useDateSearch from "../../../../layout/SlideInMenus/DateSearch/useDateSearch";
import {
    dateSearchOptions,
    tableDateSearchOptions,
} from "../../../../layout/SlideInMenus/DateSearch/data";
import useFetchData from "../../../../global/hooks/useFetchData";
import { exportCsv } from "../../../../global/utils/helpers";

const salesSummaryTabs = [
    "Incomplete Held Leads",
    "Packed Out",
    "Signable",
    "Pack Back",
    "IP1 Payment",
];

const SalesSummary = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today");

    const { data, isLoading, fetchData } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/sales-summary`,
        },
        {
            params: { from: dateRange.from, to: dateRange.to },
            onSuccess: onDateSearchSuccess,
            notifyOnSuccess: true,
        }
    );
    const salesSummaryTablesData: { [key: string]: any } = data?.data || {};
    const salesData = Object.values(salesSummaryTablesData);
    const salesBodies = salesData.map((obj) => obj?.data || {});
    const salesHeadings = salesData.map((obj) => obj?.headings || "");
    useEffect(() => {
        fetchData();
    }, [dateRange.from, dateRange.to]);
    const exportHandler = () => {
        exportCsv(
            salesBodies,
            salesHeadings,
            salesSummaryTabs,
            `Sales_Summary_${dateRange.from}-${dateRange.to}`
        );
    };

    return (
        <>
            <Header title="client sales summary" hasNavBarAbove hasPrevPath>
                <DateButton
                    isLoading={isLoading}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                    dateDisplay={dateDisplay}
                    options={tableDateSearchOptions}
                />
            </Header>
            <TablePageContent>
                <PageTabs
                    tabs={salesSummaryTabs}
                    setSearchTerm={setSearchTerm}
                    selectedTabIndex={selectedTabIndex}
                    setSelectedTabIndex={setSelectedTabIndex}
                    notifications={[
                        data?.data?.incomplete_leads.data.length || 0,
                        data?.data?.pack_sent.data.length || 0,
                        data?.data?.signable_leads.data.length || 0,
                        data?.data?.pack_back.data.length || 0,
                        data?.data?.ip1.data.length || 0,
                    ]}
                />
                <SearchRow value={searchTerm} setValue={setSearchTerm}>
                    {/* <Button
                        btnText="Filter"
                        onClick={() => alert("Filter")}
                        style="purple"
                        btnImg={icons.whiteDownArrow}
                    /> */}
                    <Button
                        btnText=""
                        onClick={exportHandler}
                        style="red"
                        btnImg={icons.exportIcon}
                    />
                </SearchRow>
                <SalesSummaryTables
                    selectedTableIndex={selectedTabIndex}
                    data={data?.data || []}
                    searchTerm={searchTerm}
                    isLoading={isLoading}
                />
            </TablePageContent>
        </>
    );
};

export default SalesSummary;
