import styled from "styled-components";
import {
    hideScrollbar,
    mainScrollbar,
} from "../../../global/theme/reuseable/mixins";

const EditIntroducerContent = styled.div`
    flex: 1;
    display: flex;

    & .page-tabs {
        &__btn {
            padding-inline: 24px;
        }
    }

    & .introducer-edit {
        &__left {
            flex: 1 1 0;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            ${mainScrollbar(12)};
        }

        &__right-wrapper {
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }

        &__right {
            flex: 1 1 0;
            width: 260px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            overflow-y: auto;
            ${hideScrollbar};

            & .detail-card {
                &__btns {
                    width: 100%;

                    & > button {
                        width: inherit;
                    }
                }

                &__content {
                    font-size: 14px;
                    font-weight: 300;
                    margin-block: 4px;
                }
            }
        }
    }
`;

export const StyledBasicInfo = styled.section`
    padding: 24px 0;
    margin-right: 8px;
    flex: 1 1 0;
    overflow-y: auto;
    ${mainScrollbar(12)};

    & h2 {
        margin-top: 8px;
        font-size: 16px;
    }

    & .basic-info {
        &__form {
            width: min(100%, 700px);

            & > :not(:first-child) {
                margin-top: 16px;
            }

            & > button {
                padding: 8px 24px;
                height: 40px;
            }
        }

        &__container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto auto;
            gap: 16px 32px;

            & > h2 {
                grid-area: 1/1 / span 1/-1;
            }
        }

        &__input {
            & input {
                width: 100%;
                height: 40px;
            }
        }

        &__select {
            & .dropdown-select__field {
                height: 40px;
            }
        }

        &__text-area {
            grid-area: 2/2/-1/-1;
            display: flex;
            flex-direction: column;

            & textarea {
                flex: 1;
            }
        }
    }
`;

export const EditIntroducerTable = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
    margin-right: 24px;

    & .data-table {
        flex: 1;
    }
`;

export default EditIntroducerContent;
