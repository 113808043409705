import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ClientBaseDetailsState {
   applicants: {[key: string]: any} | null;
   postalAddress: {[key: string]: any} | null;
   agreementDetails: {[key: string]: any} | null;
   keyEvents: {[key: string]: any} | null;
   loaIds: {[key: string]: any} | null;
   bankCard: {[key: string]: any} | null;
   misc: {[key: string]: any} | null;
   idAml: {[key: string]: any} | null;
}

export const initialClientBaseDetailsState: ClientBaseDetailsState = {
    applicants: null,
    postalAddress: null,
    agreementDetails: null,
    keyEvents: null,
    loaIds: null,
    bankCard: null,
    misc: null,
    idAml: null,
}

export const clientBaseDetailsSlice = createSlice({
    name: 'client-baseDetails',
    initialState: initialClientBaseDetailsState,
    reducers: {
        setBaseDetailsByKey: (state, action: PayloadAction<{key: keyof typeof initialClientBaseDetailsState, data: {[key: string]: any} | null}>) => {
            const {key, data} = action.payload
            state[key] = data
        },
        resetSlice: () => {
            return initialClientBaseDetailsState
        },
    }
})

export const clientBaseDetailsActions = clientBaseDetailsSlice.actions
export default clientBaseDetailsSlice