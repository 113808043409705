import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface ReportTemplate {
        name: string;
        type: string;
        columns: Array<{[key: string]: any}>;
}


export interface settingsState {
    templates: {
        sms:  Array<{[key: string]: any}>;
        email:  Array<{[key: string]: any}>;
        workflow:  Array<{[key: string]: any}>;
        document:  Array<{[key: string]: any}>;
    },
    report: ReportTemplate
    others: {
        leadStatus:  Array<{[key: string]: any}>;
        income:  Array<{[key: string]: any}>;
        expenditure:  Array<{[key: string]: any}>;
        tvDashboards: Array<{[key: string]: any}>;

    }

}

export const initialReportTemplate:ReportTemplate = {
    name: '',
    type: '',
    columns: []
}

export const initialSettingsState: settingsState = {
   templates: {
    sms: [],
    email: [],
    workflow: [],
    document: [],
   },
   report: initialReportTemplate,
   others: {
    leadStatus:  [],
    income:  [],
    expenditure: [],
    tvDashboards: [],

}
}

export const settingsSlice = createSlice({
    name: 'settingss',
    initialState: initialSettingsState,
    reducers: {
        setTemplate: (state, action: PayloadAction<{type: keyof typeof  initialSettingsState.templates, data: Array<{[key: string]: any}>}>) => {
           const {type, data} = action.payload
            state.templates[type] = data
        },
        setReport: (state, action: PayloadAction<ReportTemplate>) => {
             state.report = action.payload
        },
        setReportColumns: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.report.columns = action.payload
        },
        setOther: (state, action: PayloadAction<{type: keyof typeof  initialSettingsState.others, data: Array<{[key: string]: any}>}>) => {
            const {type, data} = action.payload
             state.others[type] = data
         },
        resetSlice: () => {
            return initialSettingsState
        },
        
    }
})

export const settingsActions = settingsSlice.actions
export default settingsSlice