import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ClientLedgerPaymentState {
    ledger: {[key: string]: any} | null;
    payments: {[key: string]: any} | null;
    paymentsSchedule: {[key: string]: any} | null;
}

export const initialClientLedgerPaymentState: ClientLedgerPaymentState = {
    ledger: null,
    payments: null,
    paymentsSchedule: null,
}


export const clientLedgerPaymentSlice = createSlice({
    name: 'client-ledgerPayment',
    initialState: initialClientLedgerPaymentState,
    reducers: {
        setDataByKey: (state, action: PayloadAction<{key: keyof typeof initialClientLedgerPaymentState, data: {[key: string]: any} | null}>) => {
            const {key, data} = action.payload
            state[key] = data
        },
        resetSlice: () => {
            return initialClientLedgerPaymentState
        },
    }
})

export const clientLedgerPaymentActions = clientLedgerPaymentSlice.actions
export default clientLedgerPaymentSlice