import { KeyEventsContainerProps } from ".";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import { formatDateString } from "../../../../global/utils/helpers";

const Sales: React.FC<KeyEventsContainerProps> = ({
    formData,
    onInputChange,
}) => {

    //formatDateString(formData?.courtesy_call || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')

    return (
        <HeaderContainer
            title="Sales"
            headerStyle="gradientBlue"
            className="sales"
        >
            <Input
                type="date"
                label="Contract Sent:"
                value={formatDateString(formData?.contract_sent || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD') || ""}
                onChange={onInputChange}
                name="contract_sent"
            />
            <Input
                type="date"
                label="Contract received:"
                value={formatDateString(formData?.contract_received || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="contract_received"
            />
            <Input
                type="date"
                label="Courtesy Call:"
                value={formatDateString(formData?.courtesy_call || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="courtesy_call"
            />
        </HeaderContainer>
    );
};

export default Sales;
