import styled, { css } from "styled-components";
import { theme } from "../../global/theme";
import {
    enterRight,
    leaveRight,
    rotate,
} from "../../global/theme/reuseable/animations";
import {
    hideScrollbar,
    mainScrollbar,
} from "../../global/theme/reuseable/mixins";
import { LabelMixin } from "../Label/styles";

import { iSCModal, iSCStepModal } from "./types";

export const BackDrop = styled.div`
    position: fixed;
    inset: 0;
    z-index: 990;
    background-color: rgba(100, 100, 100, 0.4);
    transition: all 0.3s ease;
`;

export const Modal = styled.div`
    height: 100%;
    z-index: 999;
    padding: 32px;
    opacity: 1 !important;
    color: ${theme?.colors?.white};
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    transition: all 0.3s ease;
    overflow-y: auto;
    ${hideScrollbar};
`;

export const innerModalMixin = `
  background: ${theme?.colors?.background};
  border-radius: 20px;
  position:relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #5e5e5e;
  padding: 20px;
  align-self: center;
  justify-self: center;

  p {
    font-size: 14px;
  }

  .heading {
    display: flex;
    padding-bottom: 17px;
    border-bottom: 1px solid #5e5e5e;

    img:last-child {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .content {
    flex: 1;
    overflow: auto;
    ${mainScrollbar(12)};
  }
  .nav,
  .btns {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    button {
      width: 140px;
    }
  }
  `;

interface iResizableBasicModal extends iSCModal {
    height?: string;
    width?: string;
    isSuccess?: boolean;
}

interface iResizableStepBasicModal extends iResizableBasicModal {
    step: number;
}
export const ResizableModalWrapper = styled.div<iResizableBasicModal>`
    ${innerModalMixin};
    width: ${({ width, isSuccess }) =>
        isSuccess ? "auto" : `min(100%, ${width})` || "auto"};
    height: ${({ height }) => `${height}` || "auto"};
    margin: auto;
    color: #5e5e5e;
    /* transition: all 0.4s; */

    /* animation: ${({ show }) =>
        show
            ? css`
                  ${enterRight} linear 0.3s forwards
              `
            : css`
                  ${leaveRight} linear 0.3s forwards
              `}; */
`;

export const ChangeUserPermissionModalWrapper = styled(ResizableModalWrapper)`
    /* padding: 19px 23.2px 41px 22px; */

    .content {
        > p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            margin-block: 26px;
            color: #5e5e5e;
        }
    }

    .nav {
        gap: 12px;
        button:first-child {
            width: 140px;
            height: 40px;
        }
        button:last-child {
            width: 120px;
            height: 40px;
        }
    }
`;

export const NewPermissionModalWrapper = styled(ResizableModalWrapper)`
    padding: 21px 37.2px 40px 37px;
    .content {
        padding-top: 33px;

        .top {
            > * {
                flex-grow: 1;
                > input {
                    width: 100%;
                }
            }
            .checkboxGroup {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 23px;

                label {
                    text-align: left;
                    ${LabelMixin}
                }
                > div {
                    label {
                        display: flex;
                        margin-right: 34px;
                        input {
                            margin-right: 7.21px;
                        }
                    }
                }
            }
        }

        .addedGroups {
            width: 40%;
            display: flex;
            flex-direction: column;
            gap: 18px;
        }
        .selectTab .selectWrapper {
            margin-bottom: 31px;
        }
    }
    .nav {
        gap: 25px;
        button {
            width: 140px;
            height: 40px;
        }
    }
`;
export const ResizableStepModalWrapper = styled.div<iResizableStepBasicModal>`
    ${innerModalMixin}
    width: ${({ width }) => `${width}`};
    height: ${({ height }) => `${height}`};
    padding: 20px;
    margin: auto;
    /* transition: all 0.4s; */

    color: #5e5e5e;

    /* animation: ${({ show }) =>
        show
            ? css`
                  ${enterRight} linear 0.3s forwards
              `
            : css`
                  ${leaveRight} linear 0.3s forwards
              `};

    .heading {
    }
    .content {
    }
    .nav {
    } */
`;
export const StepModalWrapper = styled.div<iSCStepModal>``;

export const StandardModalWrapper = styled.div<iSCModal>`
    ${innerModalMixin}
    width: 457px;
    margin: auto;

    /* padding: 19px 26.2px 31px 22px; */
    transition: all 0.4s;

    color: #5e5e5e;

    animation: ${({ show }) =>
        show
            ? css`
                  ${enterRight} linear 0.3s forwards
              `
            : css`
                  ${leaveRight} linear 0.3s forwards
              `};

    p {
        text-align: left;
        margin-block: 22px;
    }
    .nav {
        gap: 12px;
        button:first-child {
            width: 140px;
        }
        button:last-child {
            width: 120px;
        }
    }
`;
export const ExportModalWrapper = styled.div<iSCModal>`
    ${innerModalMixin}
    padding-top: 36px;
    padding-bottom: 52px;
    width: 457px;

    animation: ${({ show }) =>
        show
            ? css`
                  ${enterRight} linear 0.3s forwards
              `
            : css`
                  ${leaveRight} linear 0.3s forwards
              `};

    .close {
        position: absolute;
        top: 19px;
        right: 23.2px;
        padding: 11px;
        background: #f5f5f5;
        border-radius: 10.2344px;
        cursor: pointer;
    }

    h2 {
        margin-bottom: 39px;
    }

    img:not(.close img) {
        animation: ${rotate} 2s linear 0.5s infinite;
        max-width: 40%;
        margin: auto;
    }
`;

export const SetPaymentRunWrapper = styled.div<iSCStepModal>`
    ${innerModalMixin}

    ${({ stage }) =>
        !stage
            ? `
      width: 521px;
      height: 583px;
        `
            : `
      width: 457px;
      height: 266px;
        `};

    padding: 21px 26.2px 36px 24px;
    transition: all 0.6s;

    animation: ${({ show }) =>
        show
            ? css`
                  ${enterRight} linear 0.3s forwards
              `
            : css`
                  ${leaveRight} linear 0.3s forwards
              `};
    color: #5e5e5e;

    .heading {
        display: flex;
        padding-bottom: 17px;
        border-bottom: 1px solid #5e5e5e;

        img:last-child {
            margin-left: auto;
            cursor: pointer;
        }
    }

    .content {
        margin-top: 20px;
    }

    .nav {
        margin-bottom: 20px;
        button {
            width: 140px;
        }
    }
`;

export const PaymentRunDayWrapper = styled.div`
    display: flex;
    padding-right: 20px;
    padding-block: 10px;
    border-bottom: 0.7px solid #efefef;

    label,
    p {
        margin-block: auto;
    }

    label {
        margin-left: auto;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #5e5e5e;
        padding-right: 21px;
    }

    input:first-child {
        height: 19.79px;
        width: 19.79px;
        accent-color: purple;
    }
    input:last-child {
        width: 122px;
        height: 37px;
        text-align: center;
        border: 1px solid rgba(8, 42, 82, 0.3);
        border-radius: 5px;
    }
    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #5e5e5e;
        text-transform: capitalize;
    }
`;

export const AdminLedgerModalWrapper = styled.div<iSCStepModal>`
    width: 521px;
    overflow-y: hidden;

    ${({ stage }) =>
        !stage
            ? `
      height: 267px;
        `
            : stage === 1
            ? `
      height: 594px;
        `
            : `height: 266px;`};
    ${innerModalMixin}

    /* padding: 19px 26.2px 31px 22px; */

    transition: all 0.4s;

    animation: ${({ show }) =>
        show
            ? css`
                  ${enterRight} linear 0.3s forwards
              `
            : css`
                  ${leaveRight} linear 0.3s forwards
              `};
    color: #5e5e5e;

    .heading {
        display: flex;
        padding-bottom: 17px;
        border-bottom: 1px solid #5e5e5e;

        img:last-child {
            margin-left: auto;
            cursor: pointer;
        }
    }

    .content {
        margin-top: 20px;
        flex: 1;
        overflow-y: auto;
        input {
            width: 100%;
        }
        div {
            margin-bottom: 17px;
            width: 100%;
        }

        button {
            width: 194px;
            height: 40px;
            margin: auto;
        }
    }

    .nav {
        margin-top: auto;
        button {
            width: 140px;
        }
    }
`;

export const DeleteReportModalWrapper = styled.div<iSCStepModal>`
    ${innerModalMixin}
    width: 457px;
    margin: auto;

    /* padding: 19px 26.2px 31px 22px; */
    transition: all 0.4s;

    color: #5e5e5e;

    animation: ${({ show }) =>
        show
            ? css`
                  ${enterRight} linear 0.3s forwards
              `
            : css`
                  ${leaveRight} linear 0.3s forwards
              `};

    p {
        text-align: left;
        margin-block: 22px;
    }
    .nav {
        gap: 12px;
        button:first-child {
            width: 140px;
        }
        button:last-child {
            width: 120px;
        }
    }
`;

export const CreateTicketModalWrapper = styled(ResizableModalWrapper)`
    ${innerModalMixin}
    width: auto;
    max-width: ${({ width }) => `${width}`};
    height: ${({ height }) => `${height}`};
    overflow-y: hidden;
    animation: ${({ show }) =>
        show
            ? css`
                  ${enterRight} linear 0.3s forwards
              `
            : css`
                  ${leaveRight} linear 0.3s forwards
              `};

    /* padding: 19px 26.2px 31px 22px; */

    .content {
        margin-top: 17px;
        overflow-y: auto;
        ${mainScrollbar(12)}
        .row:first-child {
            gap: 24px;
            align-items: flex-end;
            margin-bottom: 13px;
            .selectWrapper,
            .selectWrapper .select,
            .inputWrapper {
                width: 100%;
                input {
                    width: 100%;
                }
            }
            .select p {
                height: 40px;
            }
        }
        .inputWrapper input {
            width: 100%;
        }
        .composition {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            label {
                margin-top: 13px;
                text-align: left;
            }
        }

        .inputRow {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 16px;

            label {
                text-align: left;
            }
            .inputWrapper,
            .inputWrapper input {
                width: 100%;
            }
        }
        .lineBreak {
            border: 0.3px solid rgba(8, 42, 82, 0.3);
            margin-block: 20px;
        }

        .selectWrapper {
            margin-bottom: 9px;
        }
    }

    .nav {
        margin-top: 23px;
        button {
            width: 140px;
        }
    }
`;

export const SuccessWrapper = styled.div`
    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #5e5e5e;
        margin-top: 22px;
    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #5e5e5e;
        margin-top: 17px;
        margin-bottom: 22px;
    }
    button {
        width: 194px;
        height: 40px;
        margin: auto;
    }
`;

export const ChangePasswordModalWrapper = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`;

export const ContinueClientModalWrapper = styled(ResizableModalWrapper)`
    & hr {
        margin-top: 24px;
    }
    animation: ${({ show }) =>
        show
            ? css`
                  ${enterRight} linear 0.3s forwards
              `
            : css`
                  ${leaveRight} linear 0.3s forwards
              `};
`;

export const StyledBasicModal = styled(ResizableModalWrapper)`
    width: min(100%, 460px);
    & > form {
        & > p {
            padding-inline: 8px;
            padding-bottom: 12px;
        }
    }
`;

export const StyledUploadModal = styled(ResizableModalWrapper)`
    position: relative;
    & .file-upload {
        margin-bottom: 12px;
    }

    & .re-upload {
        position: absolute;
        text-align: center;
        background-color: ${theme.colors.white};
        border-radius: 4px;
        border: 0.6px solid ${theme.colors.shade};
        padding: 4px 8px;
        font-weight: 400;
        font-size: 11px;
        z-index: 1;
        left: 50%;
        bottom: 4px;
        transform: translate(-50%, 0);
    }

    & .upload-modal {
        &__filename {
            width: 400px;
            text-align: left;
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: flex-end;
            background-color: ${theme.colors.white};
            border-radius: 6px;
            border: 0.6px solid ${theme.colors.shade};
            font-weight: 400;
            padding: 8px;
            font-size: 12px;

            & > p {
                font-size: 12px;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-clamp: 2;
                box-orient: vertical;
                text-overflow: ellipsis;
                flex: 1;
            }

            & > strong {
                font-weight: 300;
            }
        }
    }
`;
