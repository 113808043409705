import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../global/hooks/useFetchData";
import { TablePageContent } from "../styles";
import Header from "../../layout/Header";
import { Button, Modal } from "../../components";
import SearchRow from "../../components/SearchRow";
import DataTable from "../../components/DataTable";
import useReduxClientComplaints from "../../store/hooks/useReduxClientComplaints";
import ComplaintActions from "../../components/DataTable/actions/ComplaintActions";
import CreateComplaint from "../../components/Modals/ClientComplaints/CreateComplaint";

const Complaints: React.FC = () => {
    const [isCreating, setIsCreating] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const { complaints, setComplaints, resetSlice } =
        useReduxClientComplaints();
    const { clientId } = useParams();
    const getComplaints = useFetchData({});
    const getManagement = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/complaints/${clientId}`,
        },
        {   fetchOnLoad: true,
            onSuccess: (data) => {
                console.log(data?.data);
                if (data?.data) setComplaints(data.data?.complaints);
            },
        }
    );

    return (
        <>
            <Modal isVisible={isCreating}>
                <CreateComplaint
                    isOpen={isCreating}
                    onClose={() => setIsCreating(false)}
                />
            </Modal>
            <Header title="Complaints">
                <Button
                    btnText="Create Complaint"
                    type="button"
                    style="mint"
                    onClick={() => setIsCreating(true)}
                />
            </Header>
            <TablePageContent>
                <SearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by id, creator..."
                ></SearchRow>
                <DataTable
                    labels={[
                        "ID",
                        "Created By",
                        "Created At",
                        "Description",
                        "Action By",
                        "Action Taken",
                        "Updated By",
                    ]}
                    keys={[
                        "id",
                        "created_by",
                        "created_at",
                        "description",
                        "action_taken",
                        "action",
                        "updated_by",
                    ]}
                    data={complaints || []}
                    hasAction
                    searchTerm={searchTerm}
                    searchKeys={["id", "created_by"]}
                    formatKeys={{
                        created_at: "time",
                    }}
                    action={ComplaintActions}
                />
            </TablePageContent>
        </>
    );
};

export default Complaints;
