import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../../global/hooks/useFetchData";
import Header from "../../../layout/Header";
import { StyledPersonalBudgetingIEContent } from "./styles";
import SearchRow from "../../../components/SearchRow";
import DataTable from "../../../components/DataTable";
import { Button } from "../../../components";
import DropdownSelect from "../../../components/DropdownSelect";
import { generateTestOptions } from "../../../components/DropdownSelect/options";

const PersonalBudgetingIE = () => {
    const { clientId } = useParams();
    const [incomeSearch, setIncomeSearch] = useState("");
    const [expenseSearch, setExpenseSearch] = useState("");
    const [expenditureSearch, setExpenditureSearch] = useState("");
    const [viewMonth, setViewMonth] = useState<any>(null);

    const [incomeData, setIncomeData] = useState<
    Array<{ [key: string]: any }>>([]);
    const [expenditureData, setExpenditureData] = useState<
    Array<{ [key: string]: any }>>([]);


    const [amountData, setAmountData] = useState<
    { [key: string]: any }>({});


    const getIncomeExpense = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/personal-budgeting-ie/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {

                setIncomeData(data?.data?.personal_budgeting_ie?.income || []);
                setExpenditureData(data?.data?.personal_budgeting_ie?.expense  ||  []);
                setAmountData(data?.data?.personal_budgeting_ie);
            },
        }
    );

    const getMonthIncomeExpense = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/personal-budgeting-ie/${clientId}`,
        },
        {
            onSuccess: (data) => {

                setIncomeData(data?.data?.personal_budgeting_ie?.income || []);
                setExpenditureData(data?.data?.personal_budgeting_ie?.expense  ||  []);
                 setAmountData(data?.data?.personal_budgeting_ie);

            },
        }
    );



    const getSync = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/sync-pbs/${clientId}`,
        },
        {
            onSuccess: (data) => {

                setIncomeData(data?.data?.personal_budgeting_ie?.income || []);
                setExpenditureData(data?.data?.personal_budgeting_ie?.expense  ||  []);
                 setAmountData(data?.data?.personal_budgeting_ie);

            },
        }
    );



    //const incomeData = getIncomeExpense?.data?.data?.personal_budgeting_ie?.income  ||  [];

    // const expenditureData =
    //     getIncomeExpense?.data?.data?.personal_budgeting_ie?.expense  ||  [];

    const syncHandler = () => {
        //alert("sync");
        //sync-pbs
        getSync.fetchData();
    };

    const generateMonthListHandler = () => {
        getMonthIncomeExpense.fetchData({month:viewMonth});
    };

    return (
        <>
            <Header
                title="Personal Budgeting I.E"
                hasNavBarAbove
                isSubPath
                hasPrevPath
            />
            <StyledPersonalBudgetingIEContent>
                <div className="table-col">
                    <SearchRow
                        className="header"
                        value={incomeSearch}
                        setValue={setIncomeSearch}
                        placeholder="Search by values..."
                    >
                        <h2>Income</h2>
                    </SearchRow>
                    <DataTable
                        labels={[
                            "Source of Income",
                            "Amount",
                            "Often",
                            "Subtotal",
                        ]}
                        keys={["name", "amount", "frequency", "subtotal"]}
                        dataKey="id"
                        data={incomeData}
                        dataPerPage={99999}
                        searchKeys={["name", "amount", "frequency", "subtotal"]}
                        searchTerm={incomeSearch}
                        columnWidths={["", "80px", "80px", "80px"]}
                    />
                </div>
                <div className="table-col">
                    <SearchRow
                        className="header"
                        value={expenseSearch}
                        setValue={setExpenseSearch}
                        placeholder="Search by values..."
                    >
                        <h2>Expenditure</h2>
                    </SearchRow>
                    <DataTable
                        labels={[
                            "Source of Expenditure",
                            "Amount",
                            "Often",
                            "Subtotal",
                        ]}
                        keys={["name", "amount", "frequency", "subtotal"]}
                        dataKey="id"
                        data={expenditureData}
                        dataPerPage={99999}
                        searchKeys={["name", "amount", "frequency", "subtotal"]}
                        searchTerm={expenseSearch}
                        columnWidths={["", "80px", "80px", "80px"]}
                    />
                </div>
                <div className="right">
                    <div className="control">
                        <h3>PSB IE</h3>
                        <ul>
                            <li>
                                Income:<span>£{ amountData?.incomeAmount || 0 }</span>
                            </li>
                            <li>
                                Expenditure:<span>£{ getIncomeExpense?.data?.data?.personal_budgeting_ie?.expenseAmount || 0 }</span>
                            </li>
                            <li>
                                DI:<span>£{ getIncomeExpense?.data?.data?.personal_budgeting_ie?.diAmount || 0}</span>
                            </li>
                        </ul>
                        <DropdownSelect
                            label="View Month"
                            options={ getIncomeExpense?.data?.data?.personal_budgeting_ie?.month || []}
                            value={viewMonth}
                            optionKey="created_whn"
                            labelKeys={['whn_name']}
                            onSelect={(option) => setViewMonth(option.created_whn)}
                        />
                        <Button
                            className="sync-btn"
                            type="button"
                            btnText="Sync (One Time Only)"
                            style="purple"
                            onClick={syncHandler}
                        />
                        <Button
                            type="button"
                            btnText="Generate Month List"
                            style="purple"
                            onClick={generateMonthListHandler}
                        />
                    </div>
                </div>
            </StyledPersonalBudgetingIEContent>
        </>
    );
};

export default PersonalBudgetingIE;
