import React from "react";
import { useParams } from "react-router-dom";
import { TableRowProps } from "..";
import { StyledRequestsRow } from "./styles";
import { IconBlock } from "../styles";
import { ReactComponent as TickIcon } from "../../../../assets/icons/greenTick.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/redCross.svg";
import { formatTimeString } from "../../../../global/utils/helpers";
import DropdownMenu from "../../../DropdownMenu";
import DropdownMeunItem from "../../../DropdownMenu/DropdownMenuItem";
import useFetchData from "../../../../global/hooks/useFetchData";
import useReduxClientPortal from "../../../../store/hooks/useReduxClientPortal";

const RequestsRow: React.FC<TableRowProps> = ({ data }) => {
    const { setRequests } = useReduxClientPortal();
    const { clientId } = useParams();
    const markedIcon = (value: any) => {
        if (data?.marked === 1) return <TickIcon />;
        if (data?.marked === 0) return <CrossIcon />;
        return "-";
    };

    const markRequest = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/mark-requests/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setRequests(data.data?.requests);
            },
            notifyOnSuccess: true,
            notifySuccessMessage: "Request marked successfully",
        }
    );

    const acceptHandler = () => {
        markRequest.fetchData({ id: data.id, value: "accept" });
    };

    const rejectHandler = () => {
        markRequest.fetchData({ id: data.id, value: "reject" });
    };

    const requestHandler = () => {
        markRequest.fetchData({ id: data.id, value: "request" });
    };

    return (
        <>
            <StyledRequestsRow className="request-row">
                <td>{data?.information ?? "-"}</td>
                <IconBlock width="30px">{markedIcon(data?.active)}</IconBlock>
                <td>{formatTimeString(data?.time ?? "-")}</td>
                <td className="td--action">
                    {data?.marked ? null : (
                        <div style={{ minWidth: "max-content" }}>
                            <DropdownMenu
                                className="request-row__dropdown"
                                title="Actions"
                                color="purple"
                                isLoading={markRequest.isLoading}
                            >
                                <DropdownMeunItem
                                    title="Accept"
                                    colorMode="even"
                                    elementType="button"
                                    iconName="tick"
                                    onClick={acceptHandler}
                                />
                                <DropdownMeunItem
                                    title="Reject"
                                    colorMode="even"
                                    elementType="button"
                                    iconName="cancel"
                                    onClick={rejectHandler}
                                />
                                <DropdownMeunItem
                                    title="Request"
                                    colorMode="even"
                                    elementType="button"
                                    iconName="request"
                                    onClick={requestHandler}
                                />
                            </DropdownMenu>
                        </div>
                    )}
                </td>
            </StyledRequestsRow>
        </>
    );
};
export default RequestsRow;
