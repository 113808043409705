import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import { PageContent } from "../../../pages/styles";
import PageTabs from "../../../components/PageTabs/PageTabs";
import { Button, Modal } from "../../../components";
import EditIntroducerContent from "./styles";
import BasicInfo from "./BasicInfo";
import DetailCard from "../../../components/Cards/DetailCard";
import StatusReport from "./StatusReport";
import UserAccounts from "./UserAccounts";
import LeadReport from "./LeadReport";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxIntroducers from "../../../store/hooks/useReduxIntroducers";
import { LoadingSpinner } from "../../../components/Button/styles";
import IntroducerDeleteModal from "../../../components/Modals/IntroducerCreate/IntroducerDeleteModal";

const tabs = ["Details", "User Accounts", "Leads", "Status Report"];
const EditIntroducer = () => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const { introducerId } = useParams();
    const { setTypes } = useReduxIntroducers();

    const deleteHandler = () => {
        setIsDeleting(true);
    };
    const [introducer, setIntroducer] = useState<{ [key: string]: any }>({});
    const { data, isLoading } = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/introducer/${introducerId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                const introducerData = data?.data?.introducer[0];
                if (introducerData) setIntroducer(introducerData);
                if (data?.data?.type) setTypes(data?.data?.type || []);
            },
        }
    );
    return (
        <>
            <Modal isVisible={isDeleting}>
                <IntroducerDeleteModal
                    isOpen={isDeleting}
                    setIsOpen={setIsDeleting}
                />
            </Modal>
            <Header
                title="introducer settings"
                isSubPath
                prevPath="/introducer"
            ></Header>

            {!isLoading ? (
                <PageContent>
                    <EditIntroducerContent>
                        <div className="introducer-edit__left">
                            <PageTabs
                                className="introducer-tabs"
                                tabs={tabs}
                                selectedTabIndex={currentTabIndex}
                                setSelectedTabIndex={setCurrentTabIndex}
                            />
                            {currentTabIndex === 0 && (
                                <BasicInfo
                                    introducer={introducer}
                                    setIntroducer={setIntroducer}
                                    isLoading={isLoading}
                                />
                            )}
                            {currentTabIndex === 1 && (
                                <UserAccounts
                                    userData={data?.data?.users || []}
                                />
                            )}
                            {currentTabIndex === 2 && <LeadReport />}
                            {currentTabIndex === 3 && <StatusReport />}
                        </div>
                        <div className="introducer-edit__right-wrapper">
                            <div className="introducer-edit__right">
                                <DetailCard
                                    className="introducer-edit__card"
                                    title={
                                        introducer?.nme ||
                                        "Unknown Company Name"
                                    }
                                    buttons={
                                        !!introducerId ? (
                                            <Button
                                                btnText="Delete"
                                                onClick={deleteHandler}
                                                style="red"
                                            />
                                        ) : (
                                            <></>
                                        )
                                    }
                                >
                                    {introducer?.contact ?? "-"}
                                </DetailCard>
                                <hr />
                                <DetailCard
                                    className="introducer-edit__card"
                                    title="Leads this Month"
                                    count={introducer?.leads_month || 0}
                                />
                                <DetailCard
                                    className="introducer-edit__card"
                                    title="Leads this Week"
                                    count={introducer?.leads_week || 0}
                                />
                                <DetailCard
                                    className="introducer-edit__card"
                                    title="Leads Today"
                                    count={introducer?.leads_week || 0}
                                />
                                <hr />
                                <DetailCard
                                    className="introducer-edit__card"
                                    title="Number Of Accounts"
                                    count={introducer?.leads_total || 0}
                                />
                            </div>
                        </div>
                    </EditIntroducerContent>
                </PageContent>
            ) : (
                <LoadingSpinner size={40} />
            )}
        </>
    );
};

export default EditIntroducer;
