import React from "react";
import { TableRowProps } from "..";
import { Link } from "react-router-dom";
import { StyledLoginLogsRow } from "./styles";
import { ReactComponent as GlobeIcon } from "../../../../assets/icons/globe.svg";
import { ColoredBlock } from "../styles";

const LoginLogsRow: React.FC<TableRowProps> = ({ data }) => {
    return (
        <>
            <StyledLoginLogsRow status={data?.status}>
                <td>{data?.datetime ?? "-"}</td>
                <td datatype="ipAddress">
                    {data?.ip ?? "-"}
                    {data?.ip && (
                        <a
                            href={`https://www.ip2location.com/demo/${data?.ip}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <GlobeIcon />
                        </a>
                    )}
                </td>
                <td>{data?.status ?? "-"}</td>
            </StyledLoginLogsRow>
        </>
    );
};
export default LoginLogsRow;
