import React, { useState, useEffect, SetStateAction } from "react";
import { icons } from "../../../../assets/icons";
import { Button } from "../../../../components";
import { TablePageContent } from "../../../../pages/styles";
import Header from "../../../../layout/Header";
import DateButton from "../../../../components/Button/DateButton";
import useFetchData from "../../../../global/hooks/useFetchData";
import PageTabs from "../../../../components/PageTabs/PageTabs";
import SearchRow from "../../../../components/SearchRow";
import useDateSearch from "../../../../layout/SlideInMenus/DateSearch/useDateSearch";
import RefundsTables from "./RefundsTables";
import { exportCsv } from "../../../../global/utils/helpers";

export interface PaymentTableProps {
    data: { [key: string]: any };
    isLoading?: boolean;
    selectedIds?: number[];
    setSelectedIds?: React.Dispatch<SetStateAction<number[]>>;
    searchTerm?: string;
}

const refundTabs = ["Refund Requests", "Approved Requests"];

const Refunds: React.FC = () => {
    const [selectedTable, setSelectedTable] = useState(refundTabs[0]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today");

    const { data, isLoading, fetchData } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-refund-request`,
        },
        {
            params: { from: dateRange.from, to: dateRange.to },
            onSuccess: onDateSearchSuccess,
            notifyOnSuccess: true,
        }
    );
    const notificationCounts = [
        data?.data?.["Refund Requests"]?.data.length || 0,
        data?.data?.["Approved Requests"]?.data.length || 0,
    ];

    const refundTablesData: { [key: string]: any } = data?.data || {};
    const refundTabsList = Object.keys(refundTablesData);
    const refundData = Object.values(refundTablesData);

    const refundBodies = refundData.map((obj) => obj?.data || {});
    const refundHeadings = refundData.map((obj) => obj?.headings || "");

    useEffect(() => {
        fetchData();
    }, [dateRange]);

    useEffect(() => {
        setSearchTerm("");
        setSelectedIds([]);
    }, [selectedTable]);

    const exportHandler = () => {
        exportCsv(
            refundBodies,
            refundHeadings,
            refundTabsList,
            `Refund_${dateRange.from}-${dateRange.to}`
        );
    };

    return (
        <>
            <Header title="payment refund requests" hasNavBarAbove hasPrevPath>
                <DateButton
                    isLoading={isLoading}
                    dateDisplay={dateDisplay}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                />
            </Header>
            <TablePageContent>
                <PageTabs
                    tabs={refundTabs}
                    selectedTab={selectedTable}
                    setSelectedTab={setSelectedTable}
                    notifications={notificationCounts}
                ></PageTabs>
                <SearchRow
                    placeholder="Search by case id or customer name..."
                    value={searchTerm}
                    setValue={setSearchTerm}
                >
                    <Button
                        btnText=""
                        onClick={exportHandler}
                        style="red"
                        btnImg={icons?.exportIcon}
                    />
                </SearchRow>
                <RefundsTables
                    data={data?.data}
                    selectedTable={selectedTable}
                    searchTerm={searchTerm}
                    isLoading={isLoading}
                    reloadTables={fetchData}
                />
            </TablePageContent>
        </>
    );
};

export default Refunds;
