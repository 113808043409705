import React from "react";
import { useAppSelector, useAppDispatch } from ".";
import { diaryActions } from "../slices/diarySlice";

const useReduxDiary = () => {
    const { diary, creditors, users } = useAppSelector((state) => state.diary);
    const dispatch = useAppDispatch();

    const setDiary = (data: Array<{ [key: string]: any }>) => {
        dispatch(diaryActions.setDiary(data));
    };

    const setDiaryDayData = (
        data: {
            [key: string]: any;
        },
        date: string = "",
        oldDate: string = ""
    ) => {
        dispatch(diaryActions.setDiaryDayData({ date, data, oldDate }));
    };

    const setCreditors = (data: { [key: string]: any }[]) => {
        dispatch(diaryActions.setCreditors(data));
    };

    const setUsers = (data: { [key: string]: any }[]) => {
        dispatch(diaryActions.setUsers(data));
    };

    const createDiary = (data: { [key: string]: any }) => {
        dispatch(diaryActions.createDiary(data));
    };

    const updateDiary = (data: { [key: string]: any }) => {
        dispatch(diaryActions.updateDiary(data));
    };

    const deleteDiaryById = (id: number) => {
        dispatch(diaryActions.deleteDiaryById(id));
    };

    const getDayData = (date: Date = new Date()) => {
        // const dateString = moment(date).format("DD-MM-YYYY");
        // const dayData = diary?.dates?.[dateString]?.data;
        // if (!dayData || !Array.isArray(dayData)) return [];
        // return dayData;
    };
    return {
        diary,
        setDiary,
        setDiaryDayData,
        creditors,
        setCreditors,
        users,
        setUsers,
        getDayData,
        createDiary,
        updateDiary,
        deleteDiaryById,
    };
};

export default useReduxDiary;
