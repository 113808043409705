import styled from "styled-components";
import { ResizableModalWrapper } from "../../styles";

const StyledTVDasbboardModal = styled(ResizableModalWrapper)`
    width: min(100%, 460px);

    & h2 {
        text-transform: capitalize;
    }

    & > form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px 16px;

        & .modal-btns,
        & :nth-child(1),
        & :nth-child(2),
        & :nth-child(7) {
            grid-column: 1/-1;
        }
    }
`;

export default StyledTVDasbboardModal;
