import { SetStateAction, useState, useId } from "react";
import { StyledPreviewMail } from "./styles";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

interface PreviewMailProps {
    file?: string;
    currentPage: number;
    setTotalPages: React.Dispatch<SetStateAction<number>>;
}

const PreviewMail: React.FC<PreviewMailProps> = ({
    file,
    currentPage,
    setTotalPages,
}) => {
    const inputId = useId();
    const [scale, setScale] = useState(1);
    const loadSuccessHandler = ({ numPages }: { numPages: number }) => {
        setTotalPages(numPages);
    };

    const scaleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setScale(+e.target.value);
    };

    return (
        <StyledPreviewMail className="preview-mail">
            <div className="preview-mail__scale">
                <label htmlFor={inputId}>Zoom: </label>
                <input
                    id={inputId}
                    type="range"
                    min={0.5}
                    max={2.5}
                    step={0.25}
                    value={scale}
                    onChange={scaleHandler}
                ></input>
                <p>x{scale.toFixed(2)}</p>
            </div>
            <div className="preview-mail__wrapper">
                <Document file={file} onLoadSuccess={loadSuccessHandler}>
                    <Page
                        pageNumber={currentPage}
                        renderTextLayer={false}
                        scale={scale}
                    />
                </Document>
            </div>
        </StyledPreviewMail>
    );
};

export default PreviewMail;
