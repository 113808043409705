import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import StyledBaseDetailsLandingContent from "./styles";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import CircularMeter from "../../../components/CircularMeter";
import { Button, Modal } from "../../../components";
import useFetchData from "../../../global/hooks/useFetchData";
import CardDetails from "../../../components/CardDetails";
import RemoveService from "../../../components/Modals/BaseDetails/RemoveService";

const BaseDetailsLanding = () => {
    const [removeService, setRemoveService] = useState("");
    const { clientId } = useParams();
    const getBaseDetails = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/base-details/${clientId}`,
        },
        {
            fetchOnLoad: true,
        }
    );
    const baseDetails = getBaseDetails?.data?.data || {};

    const requestIdAmlCheck = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/id-aml-check/${clientId}`,
    });
    const testAllocatedServicesData = {
        pbs: 0,
        personal_accountancy: 1,
        business_accountancy: 0,
        tax_rebate: 0,
        settlements: 1,
    };
    const serviceNames = [
        "PBS",
        "Personal Accountancy",
        "Business Accountancy",
        "Tax Rebate",
        "Full and Final Settlements",
    ];

    const idAmlCheckHandler = () => {
        requestIdAmlCheck.fetchData();
    };

    const addServiceHandler = (type: string) => {
        alert("add service:" + type);
    };
    return (
        <>
            <Modal isVisible={!!removeService}>
                <RemoveService
                    isOpen={!!removeService}
                    onClose={() => setRemoveService("")}
                    serviceType={removeService}
                />
            </Modal>
            <Header title="base details" hasNavBarAbove />
            <StyledBaseDetailsLandingContent>
                <div className="containers">
                    <div className="container">
                        <div className="container__row">
                            <CalendarIcon /> <h2>Key Events</h2>
                            <Link to="key-events">View Details</Link>
                        </div>
                        <ul className="key-events">
                            <li>
                                Contract Sent:
                                <span>
                                    {baseDetails.baseDetails?.key_events?.[0]
                                        .contact_sent || "-"}
                                </span>
                            </li>
                            <li>
                                Contract Received:
                                <span>
                                    {baseDetails.baseDetails?.key_events?.[0]
                                        .contact_received || "-"}
                                </span>
                            </li>
                            <li>
                                Standing Order Sent:
                                <span>
                                    {baseDetails.baseDetails?.key_events?.[0]
                                        .standing_order_sent || "-"}
                                </span>
                            </li>
                            <li>
                                Scanned ID:
                                <span>
                                    {baseDetails.baseDetails?.key_events?.[0]
                                        .scanned_id || "-"}
                                </span>
                            </li>
                            <li>
                                Courtesy Call:
                                <span>
                                    {baseDetails.baseDetails?.key_events?.[0]
                                        .courtesy_call || "-"}
                                </span>
                            </li>
                            <li>
                                Welcome Call Due:
                                <span>
                                    {baseDetails.baseDetails?.key_events?.[0]
                                        .welcome_call_due || "-"}
                                </span>
                            </li>
                            <li>
                                Welcome Call Completed:
                                <span>
                                    {baseDetails.baseDetails?.key_events?.[0]
                                        .welcome_call_completed || "-"}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="container">
                        <div className="container__row">
                            <h2>ID Verification Check</h2>
                            <Link to="load-ids">View Details</Link>
                        </div>
                        <CircularMeter
                            percentage={
                                baseDetails.baseDetails
                                    ?.id_verification_check?.[0].percentage || 0
                            }
                        />
                        <Button
                            type="button"
                            btnText="Request ID & AML Check"
                            style="purple"
                            onClick={idAmlCheckHandler}
                            loading={requestIdAmlCheck.isLoading}
                        />
                    </div>
                    <div className="container">
                        <div className="container__row">
                            <h2>Bank & Card</h2>
                            <Link to="bank-card">View Details</Link>
                        </div>
                        <CardDetails />
                    </div>
                    <div className="container">
                        <div className="container__row">
                            <h2>Allocated Services</h2>
                        </div>
                        <ul className="allocated-services">
                            {Object.entries(testAllocatedServicesData).map(
                                ([key, value], i) => (
                                    <li key={key}>
                                        {serviceNames[i] || "-"}
                                        {value ? (
                                            <button
                                                className="allocated-services__remove"
                                                onClick={() =>
                                                    setRemoveService(key)
                                                }
                                                value={key}
                                            >
                                                Remove
                                            </button>
                                        ) : (
                                            <button
                                                className="allocated-services__add"
                                                onClick={() =>
                                                    addServiceHandler(key)
                                                }
                                                value={key}
                                            >
                                                Add
                                            </button>
                                        )}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </StyledBaseDetailsLandingContent>
        </>
    );
};

export default BaseDetailsLanding;
