import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../../../layout/Header";
import StyledDocumentTemplates from "./styles";
import SearchRow from "../../../../../components/SearchRow";
import { Button } from "../../../../../components";
import DataTable from "../../../../../components/DataTable";
import useFetchData from "../../../../../global/hooks/useFetchData";
import useReduxSettings from "../../../../../store/hooks/useReduxSettings";
const Document: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    const createHandler = () => {
        navigate("create");
    };

    const rowClickHandler = (data: { [key: string]: any }) => {
        if (data?.id) navigate(`${data.id}`);
    };
    const { templates, setTemplate } = useReduxSettings();
    const getDocumentTemplates = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/settings-template-document`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setTemplate("document", data?.data);
            },
        }
    );
    const emailTemplates = templates.document || [];

    return (
        <>
            <Header
                title="Document templates"
                prevPath="/settings"
                isSubPath
                hasNavBarAbove
            />
            <StyledDocumentTemplates>
                <SearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by name or id..."
                >
                    <Button
                        btnText="+ Create"
                        onClick={createHandler}
                        style="mint"
                        paddingInline="24px"
                    />
                </SearchRow>
                <DataTable
                    labels={["#", "Name", "Type", "Modfiy By", "Modify When"]}
                    keys={["id", "name", "type", "updated_by", "updated_at"]}
                    dataKey="id"
                    data={emailTemplates}
                    searchKeys={["name", "id"]}
                    searchTerm={searchTerm}
                    onRowClick={rowClickHandler}
                    isLoading={getDocumentTemplates.isLoading}
                    formatKeys={{
                        updated_at: "time",
                    }}
                    columnWidths={["60px", "", "80px", "200px", "80px"]}
                />
            </StyledDocumentTemplates>
        </>
    );
};

export default Document;
