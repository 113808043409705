import styled, { css } from "styled-components";
import { theme } from "../../global/theme";
import { rotate } from "../../global/theme/reuseable/animations";

import {
    iLoadingSpinner,
    iSCButton,
    iSCSelectButton,
    iSCTableButton,
} from "./types";

export const buttonMixin = css`
    height: 34px;
    position: relative;
    border: none;
    outline: none;
    border-radius: 5px;
    text-align: center;
    font-family: ${theme?.fontFamily?.primary}, Arial;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px 10px;

    font-weight: 400;
    font-size: 0.8rem;
    white-space: nowrap;

    &:disabled {
        opacity: 0.6;
    }

    :hover {
        cursor: pointer;
    }
`;

export const tableButtonMixin = css`
    height: 25px;
    border: none;
    outline: none;
    border-radius: 4px;
    text-align: center;
    font-family: ${theme?.fontFamily?.primary}, Arial;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: ${theme?.colors?.white};
    :hover {
        cursor: pointer;
    }
`;

export const ButtonWrapper = styled.button<iSCButton>`
    ${buttonMixin}
    box-sizing: border-box;
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
        filter: grayscale(20%);
    }

    background: ${({ color }) =>
        color === "purple"
            ? theme?.colors?.purple
            : color === "blue"
            ? theme?.gradients?.buttonBlue
            : color === "red"
            ? theme?.gradients?.dangerRed
            : color === "mint"
            ? theme?.gradients?.mintGreen
            : color === "white"
            ? "#FFFFFF"
            : color === "grey" || color === "cancel"
            ? theme?.colors?.cancel
            : color === "date"
            ? "transparent"
            : theme?.colors?.purple}; // Fallback as purple

    color: ${({ color }) =>
        color === "purple"
            ? theme?.colors?.background
            : color === "blue"
            ? theme?.colors?.white
            : color === "red"
            ? theme?.colors?.background
            : color === "mint"
            ? "#fafdff"
            : color === "white"
            ? "#583BD8"
            : color === "grey"
            ? theme?.colors?.darkText
            : color === "cancel"
            ? "#868686"
            : color === "date"
            ? "grey"
            : theme?.colors?.background}; // Fallback as purple

    display: ${({ color }) => (color === "mint" ? "flex" : null)};
    flex-direction: ${({ color }) => (color === "mint" ? "row-reverse" : null)};
    box-shadow: ${({ color }) =>
        color === "white" ? `0 0 0 1px inset ${theme.colors.purple}` : "none"};
    ${({ gap }) => (gap ? `gap: ${gap};}` : null)};
    padding-inline: ${(props) => props.paddingInline || ""};
    ${({ color }) =>
        color === "date"
            ? `
        :hover { 
          border: solid rgba(77, 158, 255, 1) 1px;
          background: white;
        }
      }`
            : `
        border: none;
      `}
    ${({ img }) =>
        img
            ? `
    justify-content: space-between;
    gap: 15px;
      }`
            : null};
    & > span {
        display: flex;
        align-items: center;
        gap: 8px;
        &[aria-hidden="true"] {
            opacity: 0;
            visibility: hidden;
        }
    }

    & svg {
        path {
            stroke: ${({ color }) =>
                color === "purple"
                    ? theme?.colors?.background
                    : color === "blue"
                    ? theme?.colors?.white
                    : color === "red"
                    ? theme?.colors?.background
                    : color === "mint"
                    ? "#fafdff"
                    : color === "white"
                    ? "#583BD8"
                    : color === "grey"
                    ? theme?.colors?.darkText
                    : color === "cancel"
                    ? "#868686"
                    : color === "date"
                    ? "grey"
                    : theme?.colors?.background}; // Fallback as purple
        }
    }
`;

export const LoadingSpinner = styled.div<iLoadingSpinner>`
    border: ${(props) => (props.size || 20) / 10}px solid grey;
    border-radius: 50%;
    border-top-color: ${theme?.colors.white};
    margin: auto;
    width: ${(props) => props.size || 18}px;
    height: ${(props) => props.size || 18}px;
    transition: opacity 200ms;
    animation: ${rotate} 1s linear infinite;
    transition-delay: "200ms";

    &.abs-center {
        position: absolute;
        left: 25%;
        right: 25%;
        top: 25%;
        bottom: 25%;
    }
`;

/// Select Button

export const SelectButtonWrapper = styled.button<iSCSelectButton>`
    ${buttonMixin}
    min-width: 75px;
    height: 50px;

    background: ${(props) =>
        props.selected ? `${theme?.gradients?.buttonBlue}` : " #EFEFEF;"};

    color: ${(props) =>
        props.selected
            ? `${theme?.colors?.white}`
            : `${theme?.colors?.darkText};`};
    border: solid #efefef 1px;

    :hover {
        background-color: ${theme?.colors?.white};
        border-color: ${(props) => !props.selected && "rgba(77, 158, 255, 1)"};
    }

    &[data-notifications] {
        position: relative;
    }

    &[data-notifications]:after {
        content: attr(data-notifications);
        position: absolute;

        height: 18px;
        min-width: 18px;
        padding: 4px;
        right: -8px;
        top: -8px;
        border-radius: 10px;
        border: 1px solid ${theme?.colors.white};
        background-image: ${theme?.gradients.dangerRed};
        /* background-position: center center;
        background-repeat: no; */

        display: flex;
        align-items: center;
        justify-content: center;

        color: #f2f2f2;

        font-size: 10px;
        font-weight: 600;
    }
`;

// Table Button
export const TableButtonWrapper = styled.button<iSCTableButton>`
    ${tableButtonMixin};
    font-weight: 500;

    border-radius: 4px;
    text-transform: ${(props) =>
        props.capitalise ? "uppercase;" : "capitalize;"};

    background: ${({ color }) =>
        color === "purple"
            ? theme?.colors?.purple
            : color === "orange"
            ? `${theme?.gradients?.burntOrange}`
            : ""};
`;

export const PurpleSelectButton = styled.button<iSCSelectButton>`
    ${buttonMixin}
    background: ${({ selected }) =>
        selected ? theme?.colors?.purple : theme?.colors?.white};

    color: ${({ selected }) =>
        selected ? theme?.colors?.white : theme?.colors?.purple};
    border: solid ${theme?.colors?.purple} 0.6px;
`;

export const StyledDateButtonCtn = styled.div<{ isColumn?: boolean }>`
    display: flex;
    flex-direction: ${(props) => props.isColumn && "column"};
    align-items: ${(props) => (props.isColumn ? "flex-start" : "center")};
    font-weight: 400;
    font-weight: 12px;
    gap: ${(props) => (props.isColumn ? "4px" : "12px")};

    & .date-button {
        &__date {
            margin-top: 4px;
            font-size: ${(props) => props.isColumn && "12px"};
            font-weight: ${(props) => props.isColumn && "300"};
            margin-top: ${(props) => props.isColumn && "0px"};
        }
        &__btn {
            border: none;
            padding: 8px 10px;
            font-size: 16px;
            font-weight: 300;
            display: flex;
            gap: 8px;
            align-items: center;
            background-color: transparent;
            border: 0.6px solid transparent;
            border-radius: ${(props) => (props.isColumn ? "6px" : "8px")};
            cursor: pointer;
            max-height: ${(props) => props.isColumn && "30px"};
            min-width: ${(props) => props.isColumn && "120px"};
            font-size: ${(props) => props.isColumn && "11px"};
            background-color: ${(props) =>
                props.isColumn && theme.colors.white};
            border-color: ${(props) => props.isColumn && theme.colors.shade};

            &:hover {
                background-color: ${theme?.colors.white};
                border-color: ${theme?.colors.purple};
            }
        }
    }
`;
