import React, { useState, useEffect, SetStateAction } from "react";
import { icons } from "../../../assets/icons";
import { Button } from "../../../components";
import useFetchData from "../../../global/hooks/useFetchData";
import SearchRow from "../../../components/SearchRow";
import { exportCsv } from "../../../global/utils/helpers";
import DataTable from "../../../components/DataTable";
import { EditIntroducerTable } from "./styles";
import { useParams } from "react-router-dom";

const StatusReport: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const { introducerId } = useParams();

    const { data, isLoading, error } = useFetchData(
        {
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_API}/introducer-status-report/${introducerId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {},
        }
    );
    const searchLeadsIntroducers = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/introducer-status-search`,
    });
    const statusData = data?.data.result || [];
    const statusHeadings = data?.data.headings || [];
    const searchedStausData = searchLeadsIntroducers?.data?.data?.result || [];
    const displayedData =
        searchTerm.length > 3 && !searchLeadsIntroducers.isLoading
            ? searchedStausData
            : statusData;

    const searchHandler = (value: string) => {
        if (value.length > 3) {
            searchLeadsIntroducers.fetchData({ key: value, id: introducerId });
        }
    };

    const exportHandler = () => {
        exportCsv(
            [statusData],
            [statusHeadings],
            ["Status Report"],
            `status_report_${introducerId}`
        );
    };

    return (
        <EditIntroducerTable>
            <SearchRow
                placeholder="Search by case id..."
                value={searchTerm}
                onSearch={searchHandler}
                setValue={setSearchTerm}
            >
                <Button
                    btnText=""
                    onClick={exportHandler}
                    style="red"
                    btnImg={icons?.exportIcon}
                />
            </SearchRow>
            <DataTable
                labels={statusHeadings}
                data={displayedData}
                isLoading={isLoading}
                searchKeys={["Case Id"]}
            />
        </EditIntroducerTable>
    );
};

export default StatusReport;
