import styled from "styled-components";
import { theme } from "../../global/theme";
import { roundedHeaderMixin } from "../../global/theme/reuseable/mixins";

export const FormStepHeaderWrapper = styled.span`
  ${roundedHeaderMixin};
  padding-block: 12px;

  h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${theme?.colors?.white};
  }
`;
