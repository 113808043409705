import { Notify } from "notiflix";
import React, { useState } from "react";
import CardEdit from "../../atoms/CardEdit";
import { LinearHeading } from "../../global/theme/reuseable/components";
import Button from "../Button";
import { EditContainer, RemovableCardWrapper } from "./styles";
import { useNavigate } from "react-router-dom";

interface RemovableCardProps {
    isEditing: boolean;
    title?: string;
    value?: number;
    path?: string;
}

const RemovableCard: React.FC<RemovableCardProps> = ({
    isEditing,
    title = "---",
    value = "-",
    path = "",
}) => {
    const [showEditButton, setShowEditButtons] = useState<boolean>(false);

    const handleButton = (
        e: React.MouseEventHandler<HTMLButtonElement> | any
    ): any => {
        if (isEditing) {
            Notify.info("Unavailable whilst editing.");
        } else {
            alert("CLICK");
            // return button.onClick;
        }
    };

    const removeOption = () => {
        // dispatch(updateOption(card));
    };
    return (
        <RemovableCardWrapper
            isEditing={isEditing}
            onMouseEnter={() => setShowEditButtons(true)}
            onMouseLeave={() => setShowEditButtons(false)}
        >
            {isEditing && showEditButton && (
                <EditContainer>
                    <CardEdit onClick={removeOption} type="delete" />
                    <CardEdit type="move" />
                </EditContainer>
            )}
            <h4>{title}</h4>
            <LinearHeading color="blue">{value}</LinearHeading>

            <Button
                btnText="Push To Report"
                style="purple"
                onClick={handleButton}
            />
        </RemovableCardWrapper>
    );
};

export default RemovableCard;
