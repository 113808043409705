import { Input } from "../../../index";
import { IntroducerCreateFormProps } from "../IntroducerCreate";

const BillingInfo: React.FC<IntroducerCreateFormProps> = ({
    introducerDetails,
    onChange,
    onSelect,
}) => {
    return (
        <div className="billing-info col-2">
            <Input
                name="act_name"
                type="text"
                label="Account Name:"
                placeholder="Account Name..."
                onChange={onChange}
                value={introducerDetails.act_name}
                autoComplete="off"
            />
            <Input
                name="act_no"
                type="text"
                inputType="numString"
                label="Account Number:"
                placeholder="e.g. 12341234"
                onChange={onChange}
                value={introducerDetails.act_no}
                autoComplete="off"
            />
            <Input
                name="sortcode"
                type="text"
                inputType="sortCode"
                label="Sort Code:"
                placeholder="e.g. 11-22-33"
                onChange={onChange}
                onBlur={onChange}
                value={introducerDetails.sortcode}
                autoComplete="off"
            />
            <hr />
        </div>
    );
};

export default BillingInfo;
