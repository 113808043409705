import { useState } from "react";
import { useParams } from "react-router-dom";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import useFetchData from "../../../../global/hooks/useFetchData";
import useReduxUser from "../../../../store/hooks/useReduxUser";
import FileUpload, { FileData } from "../../../FileUpload";
import { EditLiabilityFormProps } from ".";

const UploadCa: React.FC<EditLiabilityFormProps> = ({
    onClose,
    data,
    setCurrentForm,
    onSuccess = () => {},
    setLiabilityDetails,
}) => {
    const { clientId } = useParams();
    const { setClientCase } = useReduxUser();
    const [fileData, setFileData] = useState<FileData>({
        file: null,
        url: data?.ca_url ?? "",
        name: "",
    });
    const { fetchData, isSuccess, isLoading } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/upload-ca/${data?.id}`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Credit Arrangement File Uploaded.",
            onSuccess: (data) => {
                if (data?.data) setLiabilityDetails(data.data);
                setCurrentForm("");
            },
        }
    );

    const uploadHandler = (file: FileData) => {
        console.log(file);
        setFileData(file);
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (!!fileData.file) {
            const formData = new FormData();
            formData.append("file", fileData.file);
            fetchData(formData);
        }
    };

    return (
        <form onSubmit={submitHandler} className="upload-ca">
            <div className="upload-ctn">
                <div className="file-info">
                    <h3>Credit Arrangement File</h3>
                    <p>{fileData?.name || <span>No File Selected</span>}</p>
                </div>
                <FileUpload
                    accept="*"
                    onUploaded={uploadHandler}
                    showRequirement={false}
                >
                    {fileData.name ? "Re-Upload" : "Upload File"}
                </FileUpload>
            </div>

            <ModalButtons>
                <Button
                    type="submit"
                    style="purple"
                    btnText="Confirm"
                    loading={isLoading}
                    disabled={!fileData.file}
                />
                <Button
                    onClick={() => setCurrentForm("")}
                    type="button"
                    style="cancel"
                    btnText="Back"
                />
            </ModalButtons>
        </form>
    );
};

export default UploadCa;
