import styled from "styled-components";
import { centeredRowMixin } from "../../global/theme/reuseable/mixins";

export const AuthorisationRateWrapper = styled.div`
    ${centeredRowMixin};

    justify-content: space-between;
    background: #c5dbff;
    border-radius: 10px;
    padding: 6px;
    padding-left: 12px;
    width: 240px;

    span {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 6px;
        padding: 6px;
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            text-align: center;

            /* Button Blue */

            background: linear-gradient(
                123.76deg,
                #4d9eff 4.28%,
                #686fff 103.7%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
`;
