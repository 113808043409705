import React, { useState } from "react";
import { capitalize } from "lodash";
import { EditLiabilityFormProps } from ".";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../../Button";
import DropdownSelect from "../../../DropdownSelect";
import { generateTestOptions } from "../../../DropdownSelect/options";
import useFetchData from "../../../../global/hooks/useFetchData";
import Input from "../../../Input";

interface ChangeCreditorProps extends EditLiabilityFormProps {
    creditorType?: "current" | "original";
}

const ChangeCreditor: React.FC<ChangeCreditorProps> = ({
    onClose,
    data,
    setCurrentForm,
    onSuccess = () => {},
    setLiabilityDetails,
    creditorType = "current",
    liabilityList,
}) => {
    const creditor =
        creditorType === "current"
            ? data?.creditor || {}
            : data?.original_creditor || {};
    const [newCreditorId, setNewCreditorId] = useState<number | null>(
        creditor?.creditor_id ?? null
    );
    const [newRef, setnewRef] = useState(creditor?.ref ?? "");
    const isFormValid = !!newCreditorId;
    const updateCreditor = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/update-liability/${data.id}`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Liability has been updated",
            onSuccess: (data) => {
                // navigate to main form (LiabilityMain.tsx)
                setCurrentForm("");
                // update table data
                onSuccess();
                // udpate liability details in index.tsx
                if (data) setLiabilityDetails(data);
            },
        }
    );

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const creditorTypeKey =
            creditorType === "current" ? "creditor" : "original_creditor";
        const requestData = {
            [creditorTypeKey]: {
                creditor_id: newCreditorId,
                ref: newRef,
            },
        };
        if (data?.id) {
            return updateCreditor.fetchData({ data: requestData });
        }
    };

    return (
        <>
            <form onSubmit={submitHandler} className="change-creditor">
                <ul>
                    <li className="data-row">
                        {capitalize(creditorType)} Creditor:
                        <p>{creditor?.name || "-"}</p>
                    </li>
                    <li className="data-row">
                        {capitalize(creditorType)} Ref:
                        <p>{creditor?.ref || "-"}</p>
                    </li>
                </ul>

                <DropdownSelect
                    label="New Creditor"
                    options={liabilityList}
                    value={newCreditorId}
                    optionKey="id"
                    labelKeys={["name"]}
                    onSelect={(option) => setNewCreditorId(option.id)}
                    maxOptions={1000}
                    enableInput
                />
                <Input
                    label="New Ref:"
                    type="text"
                    value={newRef}
                    onChange={(e) => setnewRef(e.target.value)}
                />
                <ModalButtons>
                    <Button
                        type="submit"
                        style="purple"
                        btnText="Save Creditor"
                        disabled={!isFormValid}
                        loading={updateCreditor.isLoading}
                    />
                    <Button
                        type="button"
                        style="cancel"
                        btnText="Back"
                        onClick={() => setCurrentForm("")}
                    />
                </ModalButtons>
            </form>
        </>
    );
};

export default ChangeCreditor;
