import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { Modal } from "../..";
import { useState } from "react";
import { StyledActionIconBtn } from "../styles";
import CreateComplaint from "../../Modals/ClientComplaints/CreateComplaint";
import DeleteComplaint from "../../Modals/ClientComplaints/DeleteComplaint";

const ComplaintActions: React.FC<{ data: { [key: string]: any } }> = ({
    data,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    return (
        <>
            <Modal isVisible={isEditing}>
                <CreateComplaint
                    isOpen={isEditing}
                    mode="edit"
                    onClose={() => setIsEditing(false)}
                    data={data ?? undefined}
                />
            </Modal>
            <Modal isVisible={isDeleting}>
                <DeleteComplaint
                    isOpen={isDeleting}
                    onClose={() => setIsDeleting(false)}
                    id={data?.id ?? 0}
                />
            </Modal>
            <StyledActionIconBtn onClick={() => setIsEditing(true)}>
                <EditIcon />
            </StyledActionIconBtn>
            <StyledActionIconBtn onClick={() => setIsDeleting(true)}>
                <DeleteIcon />
            </StyledActionIconBtn>
        </>
    );
};

export default ComplaintActions;
