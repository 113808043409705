import { StyledMessageDetails } from "./styles";
import TextEditor from "../../components/TextEditor";
import { MessageViewerComponentProps } from ".";
import { formatTimeString } from "../../global/utils/helpers";

interface MessageDetailsProps extends MessageViewerComponentProps {
    selectedMessage: { [key: string]: any };
}

const MessageDetails: React.FC<MessageDetailsProps> = ({
    selectedMessage,
    messageType,
    senderKey = "",
    dateKey = "",
    dataKey = "",
    subjectKey = "",
    receiverKey = "",
    bodyKey = "",
}) => {
    return (
        <StyledMessageDetails className="message-viewer-details">
            {Object.keys(selectedMessage)?.length ? (
                <>
                    {" "}
                    <div className="message-viewer-details__header">
                        <p className="message-viewer-details__title">
                            {selectedMessage?.[senderKey] || (
                                <span className="message-viewer__unknown">
                                    Unknown Sender
                                </span>
                            )}
                            <span className="message-viewer-details__date">
                                {formatTimeString(
                                    selectedMessage?.[dateKey] || (
                                        <span className="message-viewer__unknown">
                                            Unknown Date
                                        </span>
                                    )
                                )}
                            </span>
                        </p>
                        {messageType === "email" && (
                            <>
                                <p className="message-viewer-details__subject">
                                    {selectedMessage?.[subjectKey] || (
                                        <span className="message-viewer__unknown">
                                            (No Subject)
                                        </span>
                                    )}
                                </p>
                                <p className="message-viewer-details__sent-to">
                                    Sent to:{" "}
                                    <span>
                                        {selectedMessage?.[receiverKey] || "-"}
                                    </span>
                                </p>
                            </>
                        )}
                    </div>
                    <hr />
                    <TextEditor
                        className="message-viewer-details__body"
                        previewMode
                        value={selectedMessage?.[bodyKey] || ""}
                        previewSelectPlaceholder="Select message to view"
                    />
                </>
            ) : (
                <p className="message-viewer-details__empty">
                    Select Message To View
                </p>
            )}
        </StyledMessageDetails>
    );
};

export default MessageDetails;
