import { ChangeEvent, SetStateAction, useState } from "react";
import Button from "../../../Button";
import Input from "../../../Input";
import StyledIncomeExpenditureModal from "./styles";
import useFetchData from "../../../../global/hooks/useFetchData";
import ModalHeader from "../../components/ModalHeader";
import SuccessContent from "../../components/SuccessContent";
import ModalButtons from "../../components/ModalButtons";
import {
    incomeExpenditureTypeOptions,
    pbsOptions,
    isActiveOptions,
    timePeriodOptions,
} from "../../../DropdownSelect/options";
import DropdownSelect from "../../../DropdownSelect";
import useReduxSettings from "../../../../store/hooks/useReduxSettings";

interface CreateIncomeExpenditureProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const CreateIncomeExpenditure = ({
    setIsOpen,
    isOpen,
}: CreateIncomeExpenditureProps) => {
    const { setOther } = useReduxSettings();
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        nme: "",
        type: incomeExpenditureTypeOptions[0].value,
        min: "",
        max: "",
        budgeting: pbsOptions[0].value,
        active: isActiveOptions[0].value,
        often: timePeriodOptions[0].value,
        amount: 0,
    });

    const { fetchData, isLoading, isSuccess } = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/other-income-expenditure`,
        },
        {
            onSuccess: (data) => {
                if (data?.data?.income) setOther("income", data.data.income);
                if (data?.data?.expenditure)
                    setOther("expenditure", data.data.expenditure);
            },
        }
    );

    const isFormValid = formData?.nme.trim() !== "";

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === "number" ? +value : value,
        });
    };

    const handleSelect = (key: string, value: any) => {
        setFormData({
            ...formData,
            [key]: value,
        });
    };

    const closeModal = () => setIsOpen(false);

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData({ IE: formData });
    };

    return (
        <StyledIncomeExpenditureModal
            show={isOpen}
            width="500px"
            isSuccess={isSuccess}
        >
            <ModalHeader onClose={closeModal}>
                New Income/Expenditure
            </ModalHeader>
            {isSuccess ? (
                <SuccessContent
                    message="New Income/Expenditure has been created."
                    onClose={closeModal}
                />
            ) : (
                <form onSubmit={submitHandler}>
                    <Input
                        type="text"
                        name="nme"
                        label="Name:"
                        value={formData?.nme || ""}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <DropdownSelect
                        label="Type:"
                        value={formData?.type}
                        options={incomeExpenditureTypeOptions}
                        onSelect={(option) =>
                            handleSelect("type", option.value)
                        }
                    />
                    <Input
                        type="number"
                        name="min"
                        label="Min Amount:"
                        value={`${formData?.min || ""}`}
                        onChange={handleChange}
                        min={0}
                        step={1}
                        autoComplete="off"
                    />
                    <Input
                        type="number"
                        name="max"
                        label="Max Amount"
                        value={`${formData?.max || ""}`}
                        onChange={handleChange}
                        min={formData?.min}
                        step={1}
                        autoComplete="off"
                    />
                    <DropdownSelect
                        label="PBS:"
                        value={formData?.budgeting}
                        options={pbsOptions}
                        onSelect={(option) =>
                            handleSelect("budgeting", option.value)
                        }
                    />
                    <DropdownSelect
                        label="Active:"
                        value={formData?.active}
                        options={isActiveOptions}
                        onSelect={(option) =>
                            handleSelect("active", option.value)
                        }
                    />
                    <DropdownSelect
                        label="Often:"
                        value={formData?.often}
                        options={timePeriodOptions}
                        onSelect={(option) =>
                            handleSelect("often", option.value)
                        }
                    />
                    <Input
                        type="number"
                        label="Amount:"
                        name="amount"
                        value={`${formData?.amount || ""}`}
                        onChange={handleChange}
                        min={formData?.min}
                        max={formData?.max}
                        step={1}
                        autoComplete="off"
                    />

                    <ModalButtons>
                        <Button
                            btnText="Create"
                            style="purple"
                            type="submit"
                            loading={isLoading}
                            disabled={!isFormValid}
                        />
                    </ModalButtons>
                </form>
            )}

            <></>
        </StyledIncomeExpenditureModal>
    );
};

export default CreateIncomeExpenditure;
