import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import { TablePageContent } from "../../../pages/styles";
import useFetchData from "../../../global/hooks/useFetchData";
import SearchRow from "../../../components/SearchRow";
import { Button } from "../../../components";
import { icons } from "../../../assets/icons";
import DataTable from "../../../components/DataTable";
import useDateSearch from "../../../layout/SlideInMenus/DateSearch/useDateSearch";
import DateButton from "../../../components/Button/DateButton";
import { exportCsv } from "../../../global/utils/helpers";
import { snakeCase } from "lodash";

const Report: React.FC = () => {
    const { reportId } = useParams();
    const [searchTerm, setSearchTerm] = useState("");
    const [id, name] = reportId?.split("_") || ["", ""];
    const { dateRange, dateDisplay, onDateSearchSuccess, setDateRange } =
        useDateSearch("today");

    const { data, fetchData, isLoading } = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/reports/${id}`,
        },
        {
            params: {
                offset: 0,
                limit: 99999,
                from: dateRange.from,
                to: dateRange.to,
            },
            onSuccess: onDateSearchSuccess,
        }
    );

    // console.log(Object.keys(data?.data?.result[0] ?? {}));
    const tableHeadings = data?.data?.headings || [];
    const tableData = data?.data?.result || [];
    const tableValues = tableData.map((obj: { [key: string]: any }) =>
        Object.values(obj ?? {})
    );
    const tableMinWidth = !!tableHeadings?.length
        ? `${(tableHeadings.length + 1) * 140}px`
        : undefined;

    useEffect(() => {
        if (id) fetchData();
    }, [id, dateRange]);

    const exportHandler = () => {
        exportCsv(
            [tableValues],
            [tableHeadings],
            [name],
            `${snakeCase(name || "")}${dateRange.from}-${dateRange.to}`
        );
    };

    if (!reportId) return <Navigate to="/reports" />;

    return (
        <>
            <Header prevPath="/reports" title={name}>
                <DateButton
                    isLoading={isLoading}
                    dateDisplay={dateDisplay}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                />
            </Header>
            <TablePageContent>
                <SearchRow
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="Search by any value..."
                >
                    <Button
                        btnText=""
                        onClick={exportHandler}
                        style="red"
                        btnImg={icons?.exportIcon}
                    />
                </SearchRow>
                <DataTable
                    data={tableData}
                    labels={tableHeadings}
                    isLoading={isLoading}
                    dataKey="Client ID"
                    searchKeys={tableHeadings}
                    searchTerm={searchTerm}
                    minWidth={tableMinWidth}
                    // enableSorting={false}
                />
            </TablePageContent>
        </>
    );
};

export default Report;
