import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { icons } from "../../assets/icons";
import { SearchInput } from "../../components";
import { Notifications, ToolBarCtn } from "./styles";
import User from "./DropdownMenu/User";
import useReduxUI from "../../store/hooks/useReduxUI";
import useReduxUser from "../../store/hooks/useReduxUser";
import useFetchData from "../../global/hooks/useFetchData";
import { LoadingSpinner } from "../../components/Button/styles";

const ToolBar: React.FC = () => {
    const { search, pathname } = useLocation();
    const searchParams = new URLSearchParams(search);
    const q = searchParams.get("q") || "";
    const navigate = useNavigate();
    const { setSideMenu, setNotifications } = useReduxUI();
    const [searchTerm, setSearchTerm] = useState(q);
    const { user } = useReduxUser();
    const notiData = useFetchData(
        { method: "GET", url: `${process.env.REACT_APP_SERVER_API}/tab1` },
        {
            fetchOnLoad: true,
            onSuccess: (data) => setNotifications(data.data),
        }
    );

    useEffect(() => {
        if (pathname !== "/search") setSearchTerm("");
    }, [pathname]);

    const notificationCount = () => {
        const countData = notiData?.data?.data?.count;
        if (!countData) return 0;
        const countDataValues = Object.values(countData) as number[];
        const count = countDataValues.reduce((a, c) => a + c);
        return count > 999 ? "999+" : count;
    };

    // On Enter we need to search
    const handleSearch = () => {
        if (searchTerm.trim() !== "") {
            const encodedSearchTerm = encodeURIComponent(searchTerm);
            navigate(`/search?q=${encodedSearchTerm}`);
        }
    };

    return (
        <ToolBarCtn className="toolbar">
            <SearchInput
                placeholder="Search..."
                onSearch={handleSearch}
                searchOnDebounce={false}
                searchOnKeyDown
                value={searchTerm}
                setValue={setSearchTerm}
            />
            <Notifications
                data-notifications={
                    notiData.isLoading ? "..." : notificationCount()
                }
                onClick={() => setSideMenu("notifications")}
                isLoading={notiData.isLoading}
            >
                <img
                    src={icons?.bellHead}
                    className="head"
                    alt="Notifications"
                />

                <img src={icons?.bellClapper} className="clapper" alt="" />
            </Notifications>

            <User username={user?.name || "User"} />
        </ToolBarCtn>
    );
};

export default ToolBar;
