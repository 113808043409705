import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from ".";
import { clientBaseDetailsActions } from "../slices/clientBaseDetailsSlice";
import { initialClientBaseDetailsState } from "../slices/clientBaseDetailsSlice";

const useReduxClientBaseDetails = () => {
    const {
        applicants,
        postalAddress,
        agreementDetails,
        keyEvents,
        loaIds,
        bankCard,
        misc,
        idAml,
    } = useAppSelector((state) => state["client-baseDetails"]);
    const dispatch = useAppDispatch();

    const setBaseDetailsbyKey = (
        key: keyof typeof initialClientBaseDetailsState,
        data: { [key: string]: any } | null
    ) => {
        dispatch(clientBaseDetailsActions.setBaseDetailsByKey({ key, data }));
    };

    const resetSlice = () => {
        dispatch(clientBaseDetailsActions.resetSlice());
    };

    return {
        applicants,
        postalAddress,
        agreementDetails,
        keyEvents,
        loaIds,
        bankCard,
        misc,
        idAml,
        setBaseDetailsbyKey,
        resetSlice,
    };
};

export default useReduxClientBaseDetails;
