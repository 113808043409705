import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal } from "../../../components";
import { StyledMessageContent } from "./styles";
import useFetchData from "../../../global/hooks/useFetchData";
import useReduxClientCorrespondence from "../../../store/hooks/useReduxClientCorrespondence";
import MessageViewer from "../../../containers/MessageViewer";
import CreateCorresEmail from "../../../components/Modals/Correspondence/CreateCorresEmail";

const Email = () => {
    const { email, setEmail } = useReduxClientCorrespondence();
    const { clientId } = useParams();
    const [isComposing, setIsComposing] = useState(false);
    const [selectedOption, setSelectedOption] = useState("inbox");
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const inboxData: Array<{ [key: string]: any }> = email?.inbox || [];
    const unreadData = inboxData.filter((data) => !data?.is_unread);
    const sentData: Array<{ [key: string]: any }> = email?.sent || [];

    const currentData = () => {
        if (selectedOption === "inbox") return inboxData;
        if (selectedOption === "unread") return unreadData;
        if (selectedOption === "sent") return sentData;
        return [];
    };

    const getEmailData = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/correspondence-email/${clientId}`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setEmail(data.data);
            },
        }
    );

    const markEmailsAsRead = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/mark-email-as-read/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) setEmail(data.data);
            },
        }
    );

    const deleteEmails = useFetchData(
        {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_API}/client-case/correspondence-email-delete/${clientId}`,
        },
        {
            onSuccess: (data) => {
                if (data?.data) {
                    setEmail(data.data);
                    setSelectedIds([]);
                }
            },
        }
    );

    const markEmailsAsReadHandler = () => {
        let temp = {
            email_ids: selectedIds,
        };
        markEmailsAsRead.fetchData({
            data: temp,
        });
    };

    const deleteEmailsHandler = () => {
        let temp = {
            email_ids: selectedIds,
        };
        deleteEmails.fetchData({ data: temp });
    };

    return (
        <>
            <Modal isVisible={isComposing}>
                <CreateCorresEmail
                    isOpen={isComposing}
                    onClose={() => setIsComposing(false)}
                    isCreatingOnList={false}
                />
            </Modal>
            <StyledMessageContent
                className="corres--email"
                panelCounts={[
                    inboxData.length,
                    unreadData.length,
                    sentData.length,
                ]}
                panelOptions={["inbox", "unread", "sent"]}
                panelElement={
                    <Button
                        style="purple"
                        onClick={() => setIsComposing(true)}
                        btnText="Compose"
                    />
                }
                headerTitle="Email"
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                isLoading={getEmailData.isLoading}
            >
                <MessageViewer
                    key={selectedOption}
                    data={currentData()}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    messageGroup={selectedOption}
                    dataPerPage={10}
                >
                    <div className="select-controls">
                        {!!selectedIds?.length && (
                            <p>
                                Selected: {selectedIds.length} item
                                {selectedIds.length > 1 ? "s" : ""}
                            </p>
                        )}
                        {selectedOption === "inbox" && (
                            <Button
                                style="purple"
                                btnText="Mark as Read"
                                disabled={!selectedIds?.length}
                                type="button"
                                onClick={markEmailsAsReadHandler}
                                loading={
                                    markEmailsAsRead.isLoading ||
                                    deleteEmails.isLoading
                                }
                            />
                        )}
                        <Button
                            type="button"
                            style="red"
                            btnText="Delete"
                            disabled={
                                !selectedIds?.length ||
                                markEmailsAsRead.isLoading
                            }
                            loading={deleteEmails.isLoading}
                            onClick={deleteEmailsHandler}
                        />
                    </div>
                </MessageViewer>
            </StyledMessageContent>
        </>
    );
};

export default Email;
