import { SettingNavT, iSettingSubNavTile } from "./types";
import NavigationTile from "../../../atoms/NavigationTile";
import {
    otherSubNavTiles,
    servicesSubNavTiles,
    templateSubNavTiles,
} from "./data";

export interface SubNavProps {
    settingsChoice: SettingNavT;
}

const SubNav: React.FC<SubNavProps> = ({ settingsChoice }) => {
    const subNavTiles = {
        Templates: templateSubNavTiles,
        Services: servicesSubNavTiles,
        Other: otherSubNavTiles,
    };

    return (
        <>
            {subNavTiles[settingsChoice].map((tile: iSettingSubNavTile) => (
                <NavigationTile
                    key={tile.title}
                    to={tile.path}
                    title={tile.title}
                    icon={tile.icon}
                />
            ))}
        </>
    );
};

export default SubNav;
