import React, { useState } from "react";
import { icons } from "../../../../assets/icons";
import { Button, Input } from "../../../../components";
import { Column } from "../../../../global/theme/reuseable/components";
import { BlueBox, EditableSettingsWrapper, StyledForms } from "./styles";
import useFetchData from "../../../../global/hooks/useFetchData";
import DropdownSelect from "../../../../components/DropdownSelect";
import { serviceActiveOptions } from "../../../../components/DropdownSelect/options";
import useReduxUser from "../../../../store/hooks/useReduxUser";

const Email = () => {
    const { user } = useReduxUser();
    const userId = user?.id;
    const [editedData, setEditedData] = useState({
        id: userId,
        username: "",
        password: "",
        active: null,
        apikey: "",
        apisecret: "",
        other_1: "",
        other_2: "",
    });
    const [testEmail, setTestEmail] = useState("");
    const getEmail = useFetchData(
        {
            method: "get",
            url: `${process.env.REACT_APP_SERVER_API}/service-email`,
        },
        {
            fetchOnLoad: true,
            onSuccess: (data) => {
                if (data?.data) setEditedData(data.data);
            },
        }
    );

    const saveEmail = useFetchData(
        {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_API}/service-email`,
        },
        {
            notifyOnSuccess: true,
            notifySuccessMessage: "Changes has been saved",
        }
    );

    const sendTestMessage = useFetchData({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_API}/services/payment-gateway`,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setEditedData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const selectHandler = (key: string, value: any) => {
        setEditedData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const handleSave = (e: React.FormEvent) => {
        e.preventDefault();
        saveEmail.fetchData({ email: editedData });
    };

    const handleTest = (e: React.FormEvent) => {
        e.preventDefault();
        const requestData = { email: editedData };
        sendTestMessage.fetchData(requestData);
    };

    return (
        <Column gap="24px">
            <BlueBox>
                <img src={icons?.twilio} alt="twilio" />
                <a
                    href="https://app.sendgrid.com/login"
                    target="_blank"
                    rel="noreferrer"
                >
                    Login
                </a>
            </BlueBox>
            <StyledForms className="forms">
                <EditableSettingsWrapper>
                    <div className="heading">
                        <h2>Settings</h2>
                    </div>
                    <form className="content">
                        <div className="row">
                            <Input
                                type="text"
                                name="username"
                                placeholder=""
                                value={editedData?.username || ""}
                                onChange={handleChange}
                                label="Username"
                            />
                            <Input
                                type="password"
                                name="password"
                                placeholder=""
                                value={editedData?.password || ""}
                                onChange={handleChange}
                                label="Password"
                                hasShowPassword
                            />
                            <DropdownSelect
                                label="Service:"
                                value={editedData?.active ?? null}
                                options={serviceActiveOptions}
                                onSelect={(option) =>
                                    selectHandler("active", option.value)
                                }
                            />
                        </div>
                        <hr />
                        <div className="row">
                            <h3 className="title">API Settings</h3>
                            <Input
                                className="col-3"
                                type="text"
                                name="apikey"
                                placeholder=""
                                value={editedData.apikey}
                                onChange={handleChange}
                                label="Key:"
                            />
                        </div>
                        <hr />
                        <div className="row">
                            <h3 className="title">Email Settings</h3>
                            <Input
                                type="email"
                                name="other_1"
                                placeholder="noreply@example.com"
                                value={editedData.other_1}
                                onChange={handleChange}
                                label="Client:"
                            />
                            <Input
                                type="email"
                                name="other_2"
                                placeholder="noreply@example.com"
                                value={editedData.other_2}
                                onChange={handleChange}
                                label="Creditor:"
                            />
                        </div>
                        <hr />
                        <Button
                            className="submit-btn"
                            btnText="Save"
                            onClick={handleSave}
                            style="purple"
                            loading={saveEmail.isLoading}
                        />
                    </form>
                </EditableSettingsWrapper>
                <EditableSettingsWrapper className="send-test">
                    <div className="heading">
                        <h2>Send Test Email</h2>
                    </div>
                    <form
                        className="content content--send-test"
                        onSubmit={handleTest}
                    >
                        <div className="row">
                            <Input
                                type="email"
                                name="testEmail"
                                placeholder="noreply@example.com"
                                value={testEmail}
                                onChange={(e) => setTestEmail(e.target.value)}
                                label="Creditor:"
                            />
                        </div>
                        <Button
                            className="submit-btn"
                            btnText="Send Test Message"
                            style="purple"
                            loading={sendTestMessage.isLoading}
                        />
                    </form>
                </EditableSettingsWrapper>
            </StyledForms>
        </Column>
    );
};

export default Email;
