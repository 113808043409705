import React from "react";
import { Route, Routes } from "react-router-dom";
import NavBar from "../../layout/Header/NavBar";
import CorrespondenceLanding from "../../subPages/ClientCase/Correspondence";
import Email from "../../subPages/ClientCase/Correspondence/Email";
import Files from "../../subPages/ClientCase/Correspondence/Files";
import List from "../../subPages/ClientCase/Correspondence/List";
import Signables from "../../subPages/ClientCase/Correspondence/Signables";
import Sms from "../../subPages/ClientCase/Correspondence/Sms";

const Correspondence: React.FC = () => {
    return (
        <>
            <NavBar subListName="correspondence" />
            <Routes>
                <Route index element={<CorrespondenceLanding />} />
                <Route path="list" element={<List />} />
                <Route path="timeline" element={<Sms />} />
                <Route path="email" element={<Email />} />
                <Route path="files" element={<Files />} />
                <Route path="signables" element={<Signables />} />
            </Routes>
        </>
    );
};

export default Correspondence;
