import { AgreementDetailsContainerProps } from ".";
import DropdownSelect from "../../../../components/DropdownSelect";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { Input } from "../../../../components";
import { formatDateString } from "../../../../global/utils/helpers";

const UtilityWarehouseRef: React.FC<AgreementDetailsContainerProps> = ({
    formData,
    onSelect,
    onInputChange,
    handlerList,
}) => {
    
    //formatDateString(formData?.uwr_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')
    return (
        <HeaderContainer
            title="Utility Warehouse Referral"
            headerStyle="gradientBlue"
            className="uwr col-2"
        >
            <DropdownSelect
                label="Agent:"
                options={handlerList?.case_handler}
                value={formData?.uwr_agent_id ?? null}
                onSelect={(option) => onSelect("uwr_agent_id", option.id)}
                optionKey="id"
                labelKeys={["name"]}
                enableInput
            />
            <Input
                type="date"
                label="When:"
                value={formatDateString(formData?.uwr_date || "", "YYYY-MM-DD HH:mm:ss", 'YYYY-MM-DD')}
                onChange={onInputChange}
                name="uwr_date"
            />
        </HeaderContainer>
    );
};

export default UtilityWarehouseRef;
