import { AgreementDetailsContainerProps } from ".";
import DropdownSelect from "../../../../components/DropdownSelect";
import {
    clientPlanOptions,
    clientServiceOptions,
} from "../../../../components/DropdownSelect/options";
import HeaderContainer from "../../../../containers/HeaderContainter";
import { ButtonInput, Input } from "../../../../components";

const PlanDetails: React.FC<AgreementDetailsContainerProps> = ({
    formData,
    onSelect,
    onInputChange,
}) => {
    const planDurationHandler = () => {
        alert(formData?.plan_duration);
    };
    
    return (
        <HeaderContainer
            title="Plan Details"
            headerStyle="gradientBlue"
            className="plan-details col-2"
        >
            <DropdownSelect
                label="Services:"
                options={clientServiceOptions}
                value={formData?.service_id ?? null}
                onSelect={(option) =>
                    onSelect("service_id", option?.value ?? null)
                }
                hasDefaultValue
            />
            <DropdownSelect
                label="Plan Type:"
                options={clientPlanOptions}
                value={formData?.plan_type_id ?? null}
                onSelect={(option) =>
                    onSelect("plan_type_id", option.value ?? null)
                }
                hasDefaultValue
            />
            <ButtonInput
                type="number"
                label="Plan Duration: (In Months):"
                value={`${formData?.plan_duration ?? ""}`}
                onClick={planDurationHandler}
                btnText="Submit"
                onChange={onInputChange}
                name="plan_duration"
                step={1}
            />
            <Input
                type="number"
                label="Re-Occurring Accountancy Fee:"
                value={`${formData?.accountancy_fee ?? ""}`}
                onChange={onInputChange}
                name="accountancy_fee"
                step={0.01}
            />
            <Input
                type="number"
                label="Payment In:"
                value={`${formData?.payment_in ?? ""}`}
                onChange={onInputChange}
                name="payment_in"
                step={0.01}
            />
            <ButtonInput
                type="number"
                label="Client Distributions: (To Liabilities)"
                value={`${formData?.client_distributions ?? ""}`}
                onClick={planDurationHandler}
                btnText="Submit"
                onChange={onInputChange}
                name="client_distributions"
                step={0.01}
            />
        </HeaderContainer>
    );
};

export default PlanDetails;
