import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CreditorsState {
   list: Array<{[key: string]: any}>;
}

const initialCreditorsState: CreditorsState = {
    list: [],
}

export const creditorsSlice = createSlice({
    name: 'creditors',
    initialState: initialCreditorsState,
    reducers: {
        setList: (state, action: PayloadAction<Array<{[key: string]: any}>>) => {
            state.list = action.payload
        },
    }
})

export const creditorsActions = creditorsSlice.actions
export default creditorsSlice