import styled from "styled-components";
import { ResizableModalWrapper } from "../../styles";
import { theme } from "../../../../global/theme";

const StyledGroupPermissionsSetting = styled(ResizableModalWrapper)`
    & .modal-header {
        & span {
            font-size: 12px;
            font-weight: 300;
        }
    }
    & .group-permissions-settings {
        &__form {
        }

        &__fields {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 12px 24px;
            margin-bottom: 12px;

            & > :nth-child(3) {
                grid-column: 1/-1;
            }
        }
    }
`;

export default StyledGroupPermissionsSetting;
