import styled, { css, keyframes } from "styled-components";

export const SettingsTemplateWrapper = styled.section`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;

    aside {
        width: 30%;

        display: flex;
        flex-direction: column;

        div {
            height: 100%;
            max-height: 60vh;
            overflow-y: auto;
        }

        button:first-child {
            width: 215px;
            height: 40px;
            margin-left: auto;
            margin-bottom: 26px;
        }
    }
`;
export const WorkflowWrapper = styled.section`
    .searchBar {
        margin-bottom: 20px;

        .searchInput {
            width: 303px;
            height: 40px;
        }
        button {
            height: 40px;
        }
        button:first-child {
            width: 86px;
        }
        button:last-child {
            width: 132px;
        }
    }

    .workflowTable {
        width: 100%;
        thead tr th,
        tbody tr td {
            padding: 10px;
            :last-child,
            td:last-child {
                width: 82px;
                span {
                    display: flex;
                    gap: 20px;
                    img:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
`;

export const ComposeReportWrapper = styled.section`
    display: flex;
    flex-direction: column;
    .nav {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        margin-bottom: 16px;
    }

    .step-control,
    .modal-controls {
        display: flex;
        align-items: center;
        gap: 8px;

        & > button {
            height: 40px;
        }
    }

    .step-control {
        & > button {
            width: 40px;
            padding: 0;

            & > span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: inherit;
            }
        }
    }

    .modal-controls {
        & > button {
            padding-inline: 24px;
        }
    }

    & .table-preview {
        flex: 1 1 0;
    }
`;
