import styled from "styled-components";
import { ResizableModalWrapper } from "../styles";
import BasicModal from "../BasicModal";
import { theme } from "../../../global/theme";

export const StyledCreateNote = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`;

export const StyledCreateLiability = styled(ResizableModalWrapper)`
    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    & .checkboxes {
        & p {
            font-weight: 300;
            font-size: 12px;
        }
        &__inputs {
            display: flex;
            align-items: center;
            gap: 24px;
            margin-top: 8px;
        }

        & .inputWrapper {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            & label {
                width: max-content;
                margin-left: 4px;
            }

            &__ctn {
                flex: initial;
                height: 18px;
                width: 18px;
                align-items: center;
            }
        }

        & input {
            height: 18px;
            width: 18px;
        }
    }
`;

export const StyledAssignNewCreditor = styled(ResizableModalWrapper)`
    width: min(100%, 400px);

    & > form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`;

export const StyledDeleteLiability = styled(BasicModal)`
    & p {
        font-size: 16px;
    }
    & ul {
        list-style: none;
        padding-bottom: 12px;
    }

    & li {
        display: flex;
        justify-content: space-between;
        font-weight: 300;
        font-size: 14px;

        & > span {
            font-weight: 400;
        }
    }
`;

export const StyledCreditorRating = styled(ResizableModalWrapper)`
    width: min(100%, 400px);

    & .content {
        & > p {
            font-size: 14px;
            color: ${theme.colors.primaryBlue};
            margin-bottom: 16px;
        }
    }

    & .info-ctn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 0.6px solid ${theme.colors.boxStroke};
        font-size: 14px;
        padding-block: 8px;

        & button {
            padding: 8px 16px;
            height: auto;
        }
    }
`;
