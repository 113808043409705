import styled from "styled-components";
import { theme } from "../../global/theme";

const StyledSwitch = styled.div`
    background-color: ${theme.colors.white};
    padding: 10px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 10px;
    /* border: 0.6px solid ${theme.colors.shade}; */
    & .switch {
        &__label {
            font-size: 12px;
        }

        &__btn {
            transition: all 0.2s ease;
            background-color: ${theme.colors.cancel};
            border: none;
            border-radius: 100px;
            width: 42px;
            height: 22px;
            padding: 4px;
            position: relative;

            &[data-checked="true"] {
                background-color: ${theme.colors.purple};
            }
        }

        &__thumb {
            transition: all 0.2s ease;
            height: 16px;
            width: 16px;
            border-radius: 50px;
            background-color: ${theme.colors.purple};
            position: absolute;
            top: 3px;
            transform: translateX(0px);

            &[aria-checked="true"] {
                transform: translateX(19px);
                background-color: ${theme.colors.white};
            }
        }
    }
`;

export default StyledSwitch;
