import React, { useId, useEffect } from "react";
import { StyledDayTimeSelect } from "./styles";

interface DayTimeSelectProps {
    className?: string;
    label?: string;
    name?: string;
    isSelected: boolean;
    onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
    time: string;
    onTimeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isTimeRequired?: boolean;
    defaultTime?: string;
    timeLabel?: string;
    time2Label?: string;
    hasTime2?: boolean;
}

const DayTimeSelect: React.FC<DayTimeSelectProps> = ({
    className = "",
    label = "",
    name = "",
    isSelected,
    onSelect,
    time,
    onTimeChange,
    isTimeRequired,
    defaultTime = "00:00",
    timeLabel = "Set Time:",
    time2Label = "",
    hasTime2 = false,
}) => {
    const id = useId();
    return (
        <StyledDayTimeSelect
            className={`day-time-select${className ? ` ${className}` : ""}`}
        >
            <div className="day-time-select__checkbox">
                <input
                    id={id}
                    className="day-time-select__checkbox__input"
                    name={name}
                    type="checkbox"
                    checked={isSelected}
                    onChange={onSelect}
                ></input>
                <label
                    htmlFor={id}
                    className="day-time-select__checkbox__label"
                >
                    {label}
                </label>
            </div>
            <div className="day-time-select__time">
                <label
                    htmlFor={`time-${id}`}
                    className="day-time-select__time__label"
                >
                    Set Time:
                </label>
                <input
                    id={`time-${id}`}
                    type="time"
                    name={name}
                    className="day-time-select__time__input"
                    value={time ? time : isTimeRequired ? defaultTime : ""}
                    onChange={onTimeChange}
                    disabled={!isSelected}
                ></input>
            </div>
            {hasTime2 && (
                <div className="day-time-select__time">
                    <label
                        htmlFor={`time2-${id}`}
                        className="day-time-select__time__label"
                    >
                        Set Time:
                    </label>
                    <input
                        id={`time-${id}`}
                        type="time"
                        name={name}
                        className="day-time-select__time__input"
                        value={time ? time : isTimeRequired ? defaultTime : ""}
                        onChange={onTimeChange}
                        disabled={!isSelected}
                    ></input>
                </div>
            )}
        </StyledDayTimeSelect>
    );
};

export default DayTimeSelect;
