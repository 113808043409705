import React, { useState } from "react";
import { TableRowProps } from "../";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../..";
import {
    extractTextFromHTML,
    formatTimeString,
} from "../../../../global/utils/helpers";
import { ReactComponent as InIcon } from "../../../../assets/icons/whiteRightArrow.svg";
import { ReactComponent as OutIcon } from "../../../../assets/icons/whiteLeftArrow.svg";
import { CorresTypeBlock, StyledListRow } from "./styles";
import CorresDetails from "../../../Modals/Correspondence/CorresDetails";

const ListRow: React.FC<TableRowProps> = ({ data }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const rowClickHandler = () => {
        setIsModalOpen(true);
    };

    const arrowIcon = () => {
        if (data?.drctn === "in") return <InIcon name="in" />;
        if (data?.drctn === "out") return <OutIcon name="out" />;
        return null;
    };

    return (
        <>
            <Modal isVisible={isModalOpen}>
                <CorresDetails
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    data={data ?? {}}
                />
            </Modal>
            <StyledListRow onClick={rowClickHandler} role="button">
                <td>{data?.id ?? "-"}</td>
                <CorresTypeBlock>
                    <div>
                        {data?.type ?? "-"}
                        {arrowIcon()}
                    </div>
                </CorresTypeBlock>
                <td>
                    <div>{extractTextFromHTML(data?.dscrptn ?? "")}</div>
                </td>
                <td>{data?.debt_name ?? "-"}</td>
                <td>{data?.created ?? "-"}</td>
                <td>{formatTimeString(data?.created_at ?? "")}</td>
            </StyledListRow>
        </>
    );
};
export default ListRow;
