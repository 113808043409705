import { TableRowProps } from "../";
import moment from "moment";

const APIKeysRow: React.FC<TableRowProps> = ({ data }) => {
    const hashedAPIkey = (data?.api_key || "").replace(/.(?=.{4})/g, "*");
    const formattedCreateAt = moment(data?.created_at).format(
        "DD-MM-YYYY HH:mm:ss"
    );

    return (
        <tr>
            <td>{data?.name ?? "-"}</td>
            <td>{hashedAPIkey ?? "-"}</td>
            <td>{formattedCreateAt ?? "-"}</td>
        </tr>
    );
};
export default APIKeysRow;
